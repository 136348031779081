import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import queryString from "query-string";

import {ReactComponent as ArrowRightCircle} from "../../assets/login/arrow-right-circle.svg";
import {ReactComponent as LikeIcon} from "../../assets/user-dashboard/like-icon.svg";
import {ReactComponent as ForkIcon} from "../../assets/user-dashboard/fork-icon.svg";
import {ReactComponent as MemberIcon} from "../../assets/mypage/member.svg";
import {ReactComponent as ImageIcon} from "../../assets/project/image-icon.svg";
import {ReactComponent as PublicIcon} from "../../assets/project/public-icon.svg";
import {ReactComponent as PrivateIcon} from "../../assets/project/private-icon.svg";
import {ReactComponent as LabelingIcon} from "../../assets/project/labeling-icon.svg";
import ProjectListLayout from "../../components/layout/ProjectListLayout";
import {CustomText} from "../../styles/theme/CustomStyle";
import {useDispatch, useSelector} from "react-redux";
import {GetProjectList} from "../../services/project-services";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ProjectListSkeleton from "../../styles/theme/ProjectListSkeleton";
import {Button, Pagination, Tooltip} from "antd";
import AlertModal from "../../components/modal/AlertModal";
import {deleteSelectedDatasetAction} from "../../store/reducer/SelectedDataset";
import numberFormatting from "../../common/numberFormatting";

const Wrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        padding-bottom: 10rem;
    }
`;

const ProjectSearchCreateWrapper = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 1rem;

    .input-section {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .create-btn {
        padding: 0 4rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .create-btn {
            padding: 0.7rem 4rem;
        }
    }
`;

const SearchInput = styled.input`
    background-color: #21272e !important;
    border-radius: 3px 0px 0px 3px;
    padding: 10px 10px;
    color: #fff;
    font-size: 1rem;
    font-family: "Pretendard";
    font-weight: 400;
    flex: 1;

    border: 1px solid #21272e;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: ${props => props.backcolor} !important;
    border-radius: 5px;
    height: auto;
    min-height: 0;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    gap: 10px;

    border-color: ${props => props.bc} !important;
`;

const ProjectItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
`;

const ProjectItem = styled.div`
    overflow: hidden;
    border-bottom: 1px solid #707070;
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
    }

    .contents-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        gap: 1rem;
    }

    .image-total-count {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: end;
    }

    .label-total-count {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: end;
    }

    .totalcount-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3rem;
    }

    .explanation-static-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .statics {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-top: 0.5rem;
    }

    .count-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .explanation {
        align-self: flex-start;
        width: 100%;
        font-family: Pretendard;
        font-weight: 400;
        font-size: 0.875rem;
        color: #fff;
        /* height: 2.75rem; */
        white-space: pre-line;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 1024px) {
            height: auto;
        }
    }

    .recent-model {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.125rem;
    }

    .image-labeling-count {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .image-labeling-count .count-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .image-labeling-count .count-divider {
        height: 70%;
        border-right: 1px solid #707070;
    }

    .representative-section {
        background-color: #21272e;
        border-radius: 5px;
        padding: 0.5rem 0.7rem;
        gap: 5px;
    }

    .representative-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        gap: 10px;

        .representative {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }

    @media screen and (min-width: 1024px) {
        .mobile-datacount-section {
            display: none;
        }

        .mobile-image-total-count {
            display: none;
        }

        .mobile-totalcount-item {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        .title-section {
            gap: 0.7rem;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .contents-section {
            flex-direction: column;
        }
        .datacount-section {
            display: none;
        }
        .image-total-count {
            display: none;
        }

        .label-total-count {
            display: none;
        }

        .mobile-image-total-count {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .mobile-label-total-count {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .mobile-totalcount-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            gap: 0.3rem;
        }
    }
`;

const CustomPagination = styled(Pagination)`
    margin-top: 3rem;
    margin-bottom: 10rem;
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

function ProjectList(props) {
    const {Token, UserInfo} = useSelector(state => state);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const crntPage = queryString.parse(location.search).page;

    const [searchStr, setSearchStr] = useState("");
    const [projectLoading, setProjectLoading] = useState(false);
    const [projectList, setProjectList] = useState({
        isMore: false,
        crntPage: 1,
        totalPage: 1,
        data: [],
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (crntPage === undefined) {
            navigate({
                pathname: "/project/list",
                search: "?page=1",
            });
            return;
        }

        fetchProjectList(crntPage);
        dispatch(deleteSelectedDatasetAction());
    }, [crntPage]);

    const fetchProjectList = useCallback(
        async page => {
            setProjectLoading(true);
            try {
                const {data: data} = await GetProjectList(
                    Token.data.access,
                    UserInfo.data.id,
                    searchStr,
                    page
                );

                setProjectList({
                    crntPage: page,
                    totalPage: data.count,
                    data: data.results,
                });
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setProjectLoading(false);
            }
        },
        [Token, UserInfo, searchStr, projectList.crntPage]
    );

    const handleSearchInputOnChange = useCallback(e => {
        setSearchStr(e.target.value);
    }, []);

    const handleSearchBtnClick = useCallback(() => {
        fetchProjectList(1);
    }, [searchStr]);

    const handleKeyDown = useCallback(
        e => {
            if (e.key === "Enter") {
                handleSearchBtnClick();
            }
        },
        [searchStr]
    );

    return (
        <ProjectListLayout>
            <Wrap>
                <CustomText fs="1.125rem" fw="600" wd="100%">
                    프로젝트 목록
                </CustomText>

                <ProjectSearchCreateWrapper>
                    <div className="input-section">
                        <SearchInput
                            value={searchStr}
                            onKeyDown={handleKeyDown}
                            onChange={handleSearchInputOnChange}
                            placeholder="프로젝트 검색"
                        />
                        <CustomBtn
                            backcolor="#303A45"
                            bc="#303A45"
                            onClick={handleSearchBtnClick}
                            style={{
                                borderRadius: "0px 3px 3px 0px",
                                padding: "0 3rem",
                            }}>
                            <CustomText fs="0.875rem" fw="500" cl="#fff">
                                검색
                            </CustomText>
                        </CustomBtn>
                    </div>

                    <CustomBtn
                        className="create-btn"
                        backcolor="rgba(0, 0, 0, 0)"
                        bc="#717070"
                        onClick={() => {
                            navigate("/project/create");
                        }}>
                        <CustomText fs="0.875rem" fw="600" cl="#fff">
                            프로젝트 생성
                        </CustomText>
                        <ArrowRightCircle stroke="#fff" />
                    </CustomBtn>
                </ProjectSearchCreateWrapper>

                <ProjectItemWrapper>
                    {projectLoading ? (
                        <>
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                        </>
                    ) : projectList.data.length === 0 ? (
                        <CustomText
                            ta="center"
                            fs="1.125rem"
                            style={{
                                width: "100%",
                                marginTop: "2rem",
                            }}>
                            등록된 프로젝트가 없습니다.
                        </CustomText>
                    ) : (
                        projectList.data.map(project => {
                            return (
                                <ProjectItem key={project.id}>
                                    <div className="title-section">
                                        <Link
                                            to={`/project/${project.id}?tab=dataset`}
                                            style={{
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: 10,
                                            }}>
                                            {project.is_public ? (
                                                <PublicIcon
                                                    fill="white"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                />
                                            ) : (
                                                <PrivateIcon
                                                    fill="white"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                />
                                            )}
                                            <CustomText
                                                fs="1.25rem"
                                                fw="500"
                                                style={{
                                                    flex: 1,
                                                    whiteSpace: "nowrap",
                                                    wordBreak: "break-all",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}>
                                                {project.manager.login_id} / {project.title}
                                            </CustomText>
                                        </Link>
                                        <div className="recent-model">
                                            <CustomText fs="0.875rem">최근 학습 모델</CustomText>
                                            <CustomText
                                                fs="0.875rem"
                                                fw="500"
                                                style={{
                                                    flex: 1,
                                                    whiteSpace: "nowrap",
                                                    wordBreak: "break-all",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}>
                                                {project.recent_trained_model}
                                            </CustomText>
                                        </div>
                                    </div>

                                    <div className="image-total-count">
                                        <div className="totalcount-item">
                                            <ImageIcon width="0.875rem" height="0.875rem" />
                                            <CustomText fs="0.875rem">총 데이터 수</CustomText>
                                            <Tooltip title={project.total_data_count}>
                                                <CustomText
                                                    fs="0.875rem"
                                                    fw="500"
                                                    style={{
                                                        flex: 1,
                                                        whiteSpace: "nowrap",
                                                        wordBreak: "break-all",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}>
                                                    {numberFormatting(project.total_data_count)}
                                                </CustomText>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className="label-total-count">
                                        <div className="totalcount-item">
                                            <LabelingIcon width="0.875rem" height="0.875rem" />
                                            <CustomText fs="0.875rem">
                                                총 라벨링 데이터 수
                                            </CustomText>
                                            <Tooltip title={project.total_label_count}>
                                                <CustomText
                                                    fs="0.875rem"
                                                    fw="500"
                                                    style={{
                                                        flex: 1,
                                                        whiteSpace: "nowrap",
                                                        wordBreak: "break-all",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}>
                                                    {numberFormatting(project.total_label_count)}
                                                </CustomText>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="contents-section">
                                        <div className="explanation-static-section">
                                            <div
                                                className="explanation"
                                                dangerouslySetInnerHTML={{
                                                    __html: project.explanation,
                                                }}
                                            />
                                            <div className="statics">
                                                <div className="count-section">
                                                    <LikeIcon
                                                        width="0.875rem"
                                                        height="0.875rem"
                                                        fill="#FF6433"
                                                    />
                                                    <CustomText fs="0.875rem" fw="400" cl="#FF6433">
                                                        {project.like_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <ForkIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" fw="400" cl="#3BD779">
                                                        {project.fork_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <MemberIcon
                                                        width="0.875rem"
                                                        height="0.875rem"
                                                        fill="#FDFFA9"
                                                    />
                                                    <CustomText fs="0.875rem" fw="400" cl="#FDFFA9">
                                                        {project.collaborator_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <div className="mobile-image-total-count">
                                                        <div className="mobile-totalcount-item">
                                                            <ImageIcon
                                                                width="0.875rem"
                                                                height="0.875rem"
                                                            />
                                                            <CustomText fs="0.875rem">
                                                                총 데이터 수
                                                            </CustomText>
                                                            <Tooltip
                                                                title={project.total_data_count}>
                                                                <CustomText
                                                                    fs="0.875rem"
                                                                    fw="500"
                                                                    style={{
                                                                        flex: 1,
                                                                        whiteSpace: "nowrap",
                                                                        wordBreak: "break-all",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                    }}>
                                                                    {numberFormatting(
                                                                        project.total_data_count
                                                                    )}
                                                                </CustomText>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="count-section">
                                                    <div className="mobile-label-total-count">
                                                        <div className="mobile-totalcount-item">
                                                            <LabelingIcon
                                                                width="0.875rem"
                                                                height="0.875rem"
                                                            />
                                                            <CustomText
                                                                fs="0.875rem"
                                                                style={{width: "fit-content"}}>
                                                                총 라벨링 데이터 수
                                                            </CustomText>
                                                            <Tooltip
                                                                title={project.total_label_count}>
                                                                <CustomText fs="0.875rem" fw="500">
                                                                    {numberFormatting(
                                                                        project.total_label_count
                                                                    )}
                                                                </CustomText>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="representative-section">
                                            <div className="representative-data">
                                                <div className="representative">
                                                    <CustomText fs="0.875rem">
                                                        대표 데이터셋
                                                    </CustomText>
                                                    <CustomText
                                                        fs="0.875rem"
                                                        fw="500"
                                                        cl="#E2FF9B"
                                                        style={{
                                                            flex: 1,
                                                            whiteSpace: "nowrap",
                                                            wordBreak: "break-all",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}>
                                                        {project.representative_data?.data_name}
                                                    </CustomText>
                                                </div>
                                            </div>
                                            <div className="image-labeling-count">
                                                <div className="count-item">
                                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                        이미지
                                                    </CustomText>
                                                    <CustomText fs="0.875rem" fw="500">
                                                        {project.representative_data?.total_image}
                                                    </CustomText>
                                                </div>
                                                <div className="count-divider" />
                                                <div className="count-item">
                                                    <LabelingIcon
                                                        width="0.875rem"
                                                        height="0.875rem"
                                                    />
                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                        라벨링 데이터
                                                    </CustomText>
                                                    <CustomText fs="0.875rem" fw="500">
                                                        {
                                                            project.representative_data
                                                                ?.annotation_count
                                                        }
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ProjectItem>
                            );
                        })
                    )}
                </ProjectItemWrapper>
                {projectList.data.length !== 0 && (
                    <CustomPagination
                        defaultCurrent={parseInt(crntPage)}
                        current={parseInt(crntPage)}
                        total={projectList.totalPage}
                        pageSize={10}
                        onChange={page => {
                            navigate({
                                pathname: "/project/list",
                                search: `?page=${page}`,
                            });
                        }}
                    />
                )}
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </ProjectListLayout>
    );
}

export default ProjectList;
