import React from "react";
import styled from "styled-components";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {CustomText} from "../../styles/theme/CustomStyle";
import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";

const SideScrollSection = styled.div`
    flex: 1;

    overflow-y: auto;
    padding: 25px;
    .top-menu {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const SiderWrap = styled.div`
    width: 100%;
    max-width: 360px;
    min-height: 100vh;
    background-color: #0d1117;
    box-shadow: inset -1px 0px 0px #21262d;

    @media screen and (max-height: 670px) {
        height: 50rem;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const MenuItem = styled.div`
    /* width: 100%; */
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 300ms;
    margin-top: 5px;
    /* margin-bottom: 10px; */

    .menu-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

function GuideLayout() {
    const [startMenu, setStartMenu] = useState(false);
    const [terminologyMenu, setTerminologyMenu] = useState(false);
    const [algorithmMenu, setAlgorithmMenu] = useState(false);
    const [step, setStep] = useState("");
    const [terminology, setTerminology] = useState("");
    const [algorithm, setAlgorithm] = useState("");
    const {search} = useLocation();
    useEffect(() => {
        const parseURL = window.location.href.split("/");
        const parseQuery = parseURL[parseURL.length - 1].split("?");
        const queryTitle = parseQuery[0];
        let queryNumber = "";

        if (parseQuery[1] !== undefined) {
            queryNumber = parseInt(parseQuery[1].split("=")[1]);
        } else {
            queryNumber = parseQuery[1];
        }

        if (queryTitle === "getting-started") {
            setStartMenu(true);
            setTerminologyMenu(false);
            setAlgorithmMenu(false);
            setStep(queryNumber);
            setTerminology("");
            setAlgorithm("");
        } else if (queryTitle === "terminology-guide") {
            setStartMenu(false);
            setTerminologyMenu(true);
            setAlgorithmMenu(false);
            setStep("");
            setTerminology(queryNumber);
            setAlgorithm("");
        } else if (queryTitle === "algorithm-guide") {
            setStartMenu(false);
            setTerminologyMenu(false);
            setAlgorithmMenu(true);
            setStep("");
            setTerminology("");
            setAlgorithm(queryNumber);
        }
    }, [search]);

    const clickGettingStarted = () => {
        if (startMenu === true) {
            setStartMenu(false);
        } else {
            setStartMenu(true);
        }
    };

    const clickTerminologyMenu = () => {
        if (terminologyMenu === true) {
            setTerminologyMenu(false);
        } else {
            setTerminologyMenu(true);
        }
    };

    const clickAlgorithmMenu = () => {
        if (algorithmMenu === true) {
            setAlgorithmMenu(false);
        } else {
            setAlgorithmMenu(true);
        }
    };
    return (
        <SiderWrap>
            <SideScrollSection>
                <div className="top-menu" onClick={() => clickGettingStarted()}>
                    <CustomText fs="1rem" fw="normal">
                        시작하기
                    </CustomText>
                    {startMenu === true ? (
                        <UpOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    ) : (
                        <DownOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    )}
                </div>
                {startMenu === true && (
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 2}}>
                        <Link to="/document/getting-started">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={step === undefined ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        개요
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/getting-started?step=1">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={step === 1 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        프로젝트 생성
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/getting-started?step=2">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={step === 2 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        학습데이터 업로드
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/getting-started?step=3">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={step === 3 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        데이터 전처리
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/getting-started?step=4">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={step === 4 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        데이터 라벨링
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/getting-started?step=5">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="600"
                                        wd="100%"
                                        cl={step === 5 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        모델 학습
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                    </div>
                )}

                <div
                    className="top-menu"
                    style={{paddingTop: "15px"}}
                    onClick={() => clickTerminologyMenu()}>
                    <CustomText fs="1rem" fw="normal">
                        기능 및 용어 안내
                    </CustomText>
                    {terminologyMenu === true ? (
                        <UpOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    ) : (
                        <DownOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    )}
                </div>
                {terminologyMenu === true && (
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 2}}>
                        <Link to="/document/terminology-guide">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === undefined ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        개요
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=1">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === 1 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        회원 권한
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=2">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === 2 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        프로젝트 생성
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=3">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === 3 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        데이터셋
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=4">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === 4 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        데이터 전처리
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=5">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={terminology === 5 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        데이터 라벨링
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=6">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="600"
                                        wd="100%"
                                        cl={terminology === 6 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        모델 학습
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/terminology-guide?terminology=7">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="600"
                                        wd="100%"
                                        cl={terminology === 7 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        오토 라벨링
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                    </div>
                )}
                <div
                    className="top-menu"
                    style={{paddingTop: "15px"}}
                    onClick={() => clickAlgorithmMenu()}>
                    <CustomText fs="1rem" fw="normal">
                        알고리즘 안내
                    </CustomText>
                    {algorithmMenu === true ? (
                        <UpOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    ) : (
                        <DownOutlined
                            style={{color: "#fff", fontSize: "1rem", fontWeight: "normal"}}
                        />
                    )}
                </div>
                {algorithmMenu === true && (
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 2}}>
                        <Link to="/document/algorithm-guide">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === undefined ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        개요
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=1">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === 1 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        SSD-MobileNet
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=2">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === 2 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        Faster R-CNN
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=3">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === 3 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        YOLOv4
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=4">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === 4 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        YOLOv5
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=7">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="normal"
                                        wd="100%"
                                        cl={algorithm === 7 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        YOLOv7
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=5">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="600"
                                        wd="100%"
                                        cl={algorithm === 5 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        Mask R-CNN
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                        <Link to="/document/algorithm-guide?algorithm=6">
                            <MenuItem>
                                <div className="menu-title">
                                    <CustomText
                                        fs="1rem"
                                        fw="600"
                                        wd="100%"
                                        cl={algorithm === 6 ? "#fff" : "#999999"}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}>
                                        YOLACT
                                    </CustomText>
                                </div>
                            </MenuItem>
                        </Link>
                    </div>
                )}
            </SideScrollSection>
        </SiderWrap>
    );
}

export default GuideLayout;
