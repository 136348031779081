import React, { useState } from "react";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import CountUp from "react-countup";
import { Controller, Scene } from "react-scrollmagic";

import { ReactComponent as MOLIT } from "../../assets/main/MOLIT.svg";
import { ReactComponent as ImageSet } from "../../assets/user-dashboard/image-set.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";

import { CustomText } from "../../styles/theme/CustomStyle";
import HomeLayout from "../../components/layout/HomeLayout";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { GetMainStaticApi } from "../../services/account-services";
import AlertModal from "../../components/modal/AlertModal";
import { GetMainDataSet } from "../../services/datacenter-services";
import MainNewDataSkeleton from "../../styles/theme/MainNewDataSkeleton";

const Wrap = styled.div`
    width: 100%;
    min-height: inherit;
    background-color: #161b22;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 3rem 0rem 0rem 0rem;
    }
`;

const Top = styled.div`
    width: 90%;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    gap: 8rem;
    padding-bottom: 2rem;

    .main-title {
        width: 100%;
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 10rem;
    }

    .main-title .logo-title-section {
        width: 100%;

        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
    }

    .main-title .logo-title-section .bottom-title-text {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
    }

    .main-title .center-section {
        position: relative;
        border-radius: 5px;
        border: 1px solid #707070;
        padding: 1.5rem 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .partner-notice-section {
        margin-top: 6rem;
        width: 100%;
        max-width: 1500px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        gap: 1rem;
    }

    .partner-notice-section .partner-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5rem;
    }

    .partner-notice-section .notice-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 0.8rem 0rem 0.8rem 5px;
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        width: 100%;
        max-width: 45rem;
    }

    .partner-notice-section .notice-section .notice-list {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;

        width: 100%;
        max-width: 35rem;
    }

    .import-project-section {
        width: 100%;
        max-width: 1500px;
        border-radius: 20px;
        padding: 4rem 6rem;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .import-project-section .left-section {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .import-project-section .right-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* gap: 2rem; */
    }

    .import-project-section .right-section .br-div {
        height: 1px;
        background-color: #535353;
        width: 100%;
    }

    .main-title .logo-title-section .title-text {
        line-height: "4.5rem";
    }

    @media screen and (max-width: 1024px) {
        min-height: 0;
        justify-content: flex-start;

        .empty-section {
            display: none;
        }

        .main-title {
            flex-direction: column;
            margin-top: 5rem;
        }

        .main-title .logo-title-section {
            /* align-items: center; */

            p {
                /* text-align: center; */
                width: 100%;
            }
        }

        .main-title .logo-title-section .top-title-text {
            margin-top: 3rem;
            p {
                font-size: 1.5rem;
            }
        }

        .main-title .logo-title-section .title-text {
            p {
                margin-top: 0.8rem;
                line-height: 3.3rem;
                font-size: 2.6rem;
            }
        }

        .main-title .logo-title-section .bottom-title-text {
            gap: 0.3rem;
            margin-top: 1.5rem;
            p {
                font-size: 1.3rem;
            }
        }

        .main-title .center-section {
            width: 100vw;
            border-radius: 0;
            padding: 3rem;
            margin-top: 5rem;
        }

        .partner-notice-section {
            flex-direction: column;
            margin-top: 2rem;
        }

        .partner-notice-section .partner-section {
            margin-top: 5rem;
            /* justify-content: center; */
            width: 100%;
            gap: 3rem;
        }

        .partner-notice-section .notice-section {
            width: 100%;
            margin-top: 1rem;
            max-width: none;
        }

        .partner-notice-section .notice-section .notice-list {
            max-width: none;
            width: 70%;
        }

        .import-project-section {
            margin-top: 3.5rem;
            border-radius: 5px;
            padding: 2.5rem 2rem;
            flex-direction: column;
            gap: 2.5rem;
        }

        .import-project-section .left-section {
            gap: 1.5rem;
        }

        .import-project-section .left-section .title-section {
            p {
                font-size: 1.8rem;
                line-height: 2.7rem;
            }
        }

        .import-project-section .left-section .text-section {
            p {
                font-size: 1.25rem;
            }
        }

        .import-project-section .right-section {
            flex-direction: row;
            justify-content: space-evenly;
            /* gap: 3rem; */
        }

        .import-project-section .right-section .br-div {
            display: none;
        }
    }
`;

const TrainDataShare = styled.div`
    width: 90%;
    padding: 230px 0px 0 0px;
    margin-bottom: 10rem;
    max-width: 1500px;

    .trainDataSection {
        display: flex;
        margin-top: 200px;
    }

    .topRightSection {
        min-width: 30rem;
        margin: 30px 0px 0px 60px;
    }

    .bottomLeftSection {
        min-width: 30rem;

        margin: 30px 0px 0px 0px;
        padding-right: 50px;
        width: 40%;
    }

    .bottomRightSection {
        width: 60%;
    }

    @media screen and (max-width: 1024px) {
        padding: 150px 0px 0px 0px;

        .title-section {
            p {
                font-size: 3rem;
            }
        }

        .trainDataSection {
            display: flex;
            flex-direction: column;
            margin-top: 150px;
        }

        .trainDataSection.reverse {
            flex-direction: column-reverse;
        }

        .topRightSection {
            margin: 0px 0px 40px 0px;
        }

        .bottomLeftSection {
            margin: 0px 0px 40px 0px;
            width: 100%;
        }

        .bottomRightSection {
            width: 100%;
        }
    }
`;

function DataCenterMain(props) {
    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: mainDataSet, isLoading } = useQuery(
        ["main", "dataset"],
        async () => {
            const data = GetMainDataSet();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            // onSuccess: (res) => {
            //     setMainStatic(res.data);
            // },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "사이트 정보 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: mainStatic } = useQuery(
        ["main", "static"],
        async () => {
            const data = GetMainStaticApi();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {},
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "사이트 정보 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <HomeLayout>
            <Wrap>
                <Top>
                    {/* <div className="empty-section" /> */}
                    <div className="main-title">
                        <div className="logo-title-section">
                            <div className="top-title-text">
                                <CustomText fs="2rem" fw="600">
                                    드론항공 데이터센터
                                </CustomText>
                            </div>
                            <div className="title-text">
                                <CustomText fs="3.5rem" fw="700" style={{ whiteSpace: "pre-wrap" }}>
                                    AI 학습에 필요한 {"\n"}다양한 데이터를 제공합니다.
                                </CustomText>
                            </div>
                            <Link to="/data-center/list" className="bottom-title-text">
                                <CustomText fs="1.5rem" fw="600">
                                    데이터 찾아보기
                                </CustomText>
                                <ArrowRightCircle stroke="white" width="1.5rem" height="1.5rem" />
                            </Link>
                        </div>
                        <div className="partner-notice-section">
                            <div className="partner-section">
                                <MOLIT width="11rem" height="3.9rem" />
                                <CustomText fs="1.5rem" fw="600">
                                    드론기술개발지원센터
                                </CustomText>
                            </div>
                            <div className="notice-section">
                                <CustomText fs="1.25rem">새로운 데이터</CustomText>
                                <div className="notice-list">
                                    {isLoading ? (
                                        <div style={{ width: "100%" }}>
                                            <MainNewDataSkeleton />
                                        </div>
                                    ) : mainDataSet?.data.length === 0 ? (
                                        <CustomText fs="1.125rem" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            새로운 데이터가 없습니다
                                        </CustomText>
                                    ) : (
                                        <>
                                            <Link key={mainDataSet?.data[0].id} to={`/data-center/${mainDataSet?.data[0].id}`} style={{ width: "95%" }}>
                                                <CustomText fs="1.25rem" fw={600} cl="#E2FF9B" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    <span style={{ fontSize: "1.6rem", fontWeight: "1000", marginRight: "0.8rem", color: "#ffffff" }}>·</span>
                                                    {mainDataSet?.data[0].data_name}
                                                </CustomText>
                                            </Link>
                                            <ArrowRightCircle stroke="white" width="1.2rem" height="1.2rem" />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="import-project-section">
                        <div className="left-section">
                            <div className="title-section">
                                <CustomText fs="2rem" fw="600" cl="#000" style={{ whiteSpace: "pre-wrap" }}>
                                    공개된 데이터를 {"\n"}내 프로젝트로 가져오세요.
                                </CustomText>
                            </div>
                            <div className="text-section">
                                <CustomText fs="1.5rem" cl="#000" style={{ whiteSpace: "pre-wrap" }}>
                                    공개된 데이터를 내 프로젝트로 가져와 {"\n"}새로운 프로젝트를 만들거나 데이터를 보강해보세요.
                                </CustomText>
                            </div>
                        </div>
                        <div className="right-section">
                            <ImageSet width="3rem" height="3rem" />
                            <div className="br-div" />
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <CustomText fs="1.5rem" fw="500" cl="#000">
                                    <CountUp
                                        end={mainStatic?.data.media >= 1000000 ? (mainStatic?.data.media / 1000000.0).toFixed(2) : mainStatic?.data.media >= 1000 ? (mainStatic?.data.media / 1000.0).toFixed(2) : mainStatic?.data.media}
                                        suffix={mainStatic?.data.media >= 1000000 ? " M" : mainStatic?.data.media >= 1000 ? " K" : ""}
                                        decimals={mainStatic?.data.media >= 1000000 ? 2 : mainStatic?.data.media >= 1000 ? 2 : 0}
                                        duration={0.5}
                                        style={{ color: "#000", fontSize: "1.5rem", fontWeight: 500, fontFamily: "Pretendard" }}
                                    />
                                </CustomText>
                                <CustomText fs="1rem" cl="#BABABA">
                                    공개 이미지
                                </CustomText>
                            </div>
                        </div>
                    </div>
                </Top>

                <TrainDataShare>
                    <div className="title-section">
                        <CustomText fw={700} fs="3.3rem" style={{ whiteSpace: "pre-wrap", lineHeight: "5rem" }}>
                            공개된 데이터를 활용한 {"\n"}프로젝트를 확인해보세요.
                        </CustomText>
                    </div>
                    <div className="trainDataSection reverse">
                        <Controller>
                            <Scene triggerElement="#add_team">
                                {(progress, event) => {
                                    if (document.getElementById("add_team_video") !== null) {
                                        if (progress === 0) {
                                            document.getElementById("add_team_video").currentTime = 0;
                                            document.getElementById("add_team_video").pause();
                                        } else {
                                            document.getElementById("add_team_video").play();
                                            document.getElementById("add_team_video").playbackRate = 2.0;
                                        }
                                    }

                                    return (
                                        <video id="add_team_video" width="100%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                                            <source src="videos/main/utilization-project.mp4" />
                                        </video>
                                    );
                                }}
                            </Scene>
                        </Controller>
                        <div id="add_team" className="topRightSection">
                            <CustomText fs="1.7rem" mg="0px 0px 20px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                활용 프로젝트
                            </CustomText>
                            <CustomText fw={600} fs="2rem" cl="#8C8C8C" style={{ whiteSpace: "pre-wrap" }}>
                                공개된 데이터로 어떻게 활용되는지 {"\n"}확인해보세요.
                            </CustomText>
                            <CustomText fw={600} fs="2rem" style={{ whiteSpace: "pre-wrap" }}>
                                활용 프로젝트에서 학습 완료된 모델을 {"\n"}확인하고 모델 검증을 진행해보세요.
                            </CustomText>
                        </div>
                    </div>
                    <div id="upload" className="trainDataSection">
                        <div className="bottomLeftSection">
                            <CustomText fs="1.7rem" mg="0px 0px 20px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                데이터 가져오기
                            </CustomText>
                            <CustomText fw={600} fs="2rem" cl="#8C8C8C" style={{ whiteSpace: "pre-wrap" }}>
                                공개된 데이터가 마음에 드시나요?
                            </CustomText>
                            <CustomText fw={600} fs="2rem" style={{ whiteSpace: "pre-wrap" }}>
                                내 프로젝트로 가져와 프로젝트를 {"\n"}진행해보세요.
                            </CustomText>
                        </div>
                        <Controller>
                            <Scene triggerElement="#upload">
                                {(progress, event) => {
                                    if (document.getElementById("upload_video") !== null) {
                                        if (progress === 0) {
                                            document.getElementById("upload_video").currentTime = 0;
                                            document.getElementById("upload_video").pause();
                                        } else {
                                            document.getElementById("upload_video").play();
                                            document.getElementById("upload_video").playbackRate = 2.0;
                                        }
                                    }

                                    return (
                                        <video id="upload_video" width="100%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                                            <source src="videos/main/datacenter-fork.mp4" />
                                        </video>
                                    );
                                }}
                            </Scene>
                        </Controller>
                    </div>
                </TrainDataShare>

                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </HomeLayout>
    );
}

export default DataCenterMain;
