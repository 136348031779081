import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { GetComment, GetIssueDetail, RegistrationComment, UpdateComment, UpdateIssue } from "../../../services/board-services";
import { CustomText } from "../../../styles/theme/CustomStyle";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button, Avatar } from "antd";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as EditIcon } from "../../../assets/project/editicon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/project/deleteicon.svg";
import { Colors } from "../../../styles/theme/Colors";
import { UserOutlined } from "@ant-design/icons";
import CommentListSkeleton from "../../../styles/theme/CommentListSkeleton";
import IssueDetailSkeleton from "../../../styles/theme/IssueDetailSkeleton";
import AlertModal from "../../../components/modal/AlertModal";
import Lottie from "react-lottie";
import * as loadingLottie from "../../../assets/project/loading.json";
import ConfirmModal from "../../../components/modal/ConfirmModal";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;

    padding: 0.06rem 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;

    @media screen and (max-width: 1024px) {
        padding: 0 1.5rem;
    }
`;

const CommentInput = styled.textarea`
    width: 100%;
    background-color: #21272e;
    /* padding: 1.25rem 1.875rem; */
    padding: 1.3rem 1.5rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 6rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }
`;

const CommentBox = styled.div`
    border-bottom: 1px solid #707070;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 7px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

function IssueDetail({ issueId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { UserInfo, Token } = useSelector((state) => state);
    const [issue, setIssue] = useState("");
    const [addComment, setAddComment] = useState("");
    const [comments, setComments] = useState([]);
    const [commentCount, setCommentCount] = useState(0);
    const [isHasMore, setIsHasMore] = useState(true);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [editComment, setEditComment] = useState({ id: 0, edit: false, comment: "" });
    const [editIssue, setEditIssue] = useState({ edit: false, content: "" });

    const crntIssue = queryString.parse(location.search).id;

    const commentChange = useCallback(
        (e) => {
            setAddComment(e.target.value);
        },
        [comments]
    );

    useEffect(() => {
        if (crntIssue === undefined) {
            navigate({
                search: `?tab=issue&page=1&id=${issueId}`,
            });
        }
    }, [crntIssue]);

    useEffect(() => {
        GetData(1);
    }, []);

    const GetData = useCallback(async (page) => {
        try {
            const issueData = await GetIssueDetail(issueId);
            const commentData = await GetComment(issueId, page);
            setComments(commentData.data.results);
            setCommentCount(commentData.data.count);
            setIssue(issueData.data[0]);
            setIsHasMore(true);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    }, []);

    const GetComments = useCallback(async () => {
        try {
            if (commentCount === comments.length) {
                setIsHasMore(false);
                return;
            }

            let nextPage;
            if (commentCount - comments.length < 10) {
                nextPage = Math.floor(commentCount / 10 + 1);
            } else {
                nextPage = Math.floor((commentCount - comments.length) / 10) + 1;
            }

            const commentData = await GetComment(issueId, nextPage);
            setComments(comments.concat(commentData.data.results));
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const CommentRegistration = useCallback(async () => {
        try {
            const formData = new FormData();

            formData.append("writer_id", UserInfo.data.id);
            formData.append("issue_id", issueId);
            formData.append("content", addComment);

            const commentData = await RegistrationComment(formData, Token.data.access);
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "댓글 등록 완료",
                text: "댓글 등록이 완료되었습니다.",
                afterFunction: () => {
                    GetData(1);
                    setAddComment("");
                    setIsHasMore(true);
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "댓글 등록 오류",
                text: "댓글 등록에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    }, [addComment]);

    const editIssueOnClick = useCallback(async (id, type) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));

        try {
            if (type === "edit") {
                const changeIssue = await UpdateIssue(id, { content: editIssue.content }, Token.data.access);

                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "이슈 수정 완료",
                    text: "이슈 수정이 완료되었습니다.",
                    afterFunction: () => {
                        setEditIssue((prev) => ({
                            ...prev,
                            ["edit"]: false,
                        }));
                        GetData(1);
                    },
                });
            } else {
                const data = await UpdateIssue(id, { is_del: true }, Token.data.access);
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "이슈 삭제 완료",
                    text: "이슈 삭제가 완료되었습니다.",
                    afterFunction: () => {
                        navigate({
                            search: `?tab=issue`,
                        });
                    },
                });
            }
        } catch (err) {
            if (type === "edit") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이슈 수정 완료",
                    text: "이슈 수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이슈 삭제 오류",
                    text: "이슈 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        }

        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const editCommentOnClick = useCallback(async (id, type) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));

        try {
            if (type === "edit") {
                const changeComment = await UpdateComment(id, { content: editComment.comment }, Token.data.access);

                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "댓글 수정 완료",
                    text: "댓글 수정이 완료되었습니다.",
                    afterFunction: () => {
                        setEditComment((prev) => ({
                            ...prev,
                            ["edit"]: false,
                        }));
                        GetData(1);
                    },
                });
            } else {
                const data = await UpdateComment(id, { is_del: true }, Token.data.access);
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "댓글 삭제 완료",
                    text: "댓글 삭제가 완료되었습니다.",
                    afterFunction: () => {
                        setEditComment((prev) => ({
                            ...prev,
                            ["edit"]: false,
                        }));
                        GetData(1);
                    },
                });
            }
        } catch (err) {
            if (type === "edit") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "댓글 수정 완료",
                    text: "댓글 수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "댓글 삭제 오류",
                    text: "댓글 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        }

        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    return (
        <>
            {!issue ? (
                <IssueDetailSkeleton />
            ) : (
                <>
                    <div style={{ borderBottom: "1px solid #707070", paddingBottom: "0.8rem", marginTop: "1.5rem" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <CustomText fw={600} fs="1.563rem">
                                {issue.title}
                            </CustomText>
                            <CustomText mg="0 0 5px 0">{dayjs(issue.created_at).locale("ko").format(`YYYY.MM.DD A h:mm`)}</CustomText>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "7px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Avatar size={24} src={issue.writer.image_path ? process.env.REACT_APP_END_POINT + issue.writer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[issue.writer.id % 10] }} />
                                <CustomText fs="0.875rem" mg="0 0 0 10px">
                                    {issue.writer.login_id}
                                </CustomText>
                            </div>
                            {issue.writer.login_id === UserInfo.data.login_id &&
                                (editIssue.edit === true ? (
                                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                        <CustomBtn
                                            bc="#b2b1b1"
                                            onClick={() => {
                                                setEditIssue({ edit: false });
                                            }}
                                        >
                                            <CustomText cl="#b1b1b1">취소</CustomText>
                                        </CustomBtn>
                                        <CustomBtn
                                            disabled={editIssue.content === "" ? true : false}
                                            bc={editIssue.content === "" ? "#b2b1b1" : "#E2FF9B"}
                                            onClick={() => {
                                                editIssueOnClick(issue.id, "edit");
                                            }}
                                        >
                                            <CustomText cl={editIssue.content === "" ? "#b1b1b1" : "#E2FF9B"}>수정 완료</CustomText>
                                        </CustomBtn>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                            onClick={() => {
                                                setEditIssue({ edit: true, content: issue.content });
                                            }}
                                        >
                                            <EditIcon style={{ width: "1.4rem", height: "1.4rem", marginTop: "7px" }} />
                                            <CustomText cl="#B1B1B1" mg="0 0 0 5px">
                                                수정
                                            </CustomText>
                                        </div>
                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                            onClick={() => {
                                                setConfirmModalOpen({
                                                    open: true,
                                                    title: "이슈 삭제",
                                                    text: `이슈를 삭제하시겠습니까?`,
                                                    afterFunction: () => {
                                                        editIssueOnClick(issue.id, "delete");
                                                    },
                                                });
                                            }}
                                        >
                                            <DeleteIcon style={{ width: "1rem", height: "1rem" }} />
                                            <CustomText cl="#FF614B" mg="0 0 0 5px">
                                                삭제
                                            </CustomText>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {editIssue.edit === true ? (
                        <CommentInput
                            placeholder="이슈 입력"
                            value={editIssue.content}
                            onChange={(e) => {
                                setEditIssue((prev) => ({
                                    ...prev,
                                    ["content"]: e.target.value,
                                }));
                            }}
                            style={{ minHeight: "16.313rem" }}
                            autoFocus
                            onFocus={(e) => e.currentTarget.select()}
                        />
                    ) : (
                        <div style={{ width: "100%", minHeight: "16.313rem", backgroundColor: "#21272E", borderRadius: "5px", padding: "1.3rem 1.5rem", border: "1px solid #707070" }}>
                            <CustomText cl="#D4D4D4" style={{ wordBreak: "break-all", whiteSpace: "pre-line" }}>
                                {issue.content}
                            </CustomText>
                        </div>
                    )}
                </>
            )}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem", paddingBottom: "0.625rem", borderBottom: "1px solid #707070" }}>
                <CustomText fw={600} fs="1.125rem">
                    댓글
                </CustomText>
                <CustomBtn disabled={addComment === "" ? true : false} bc={addComment === "" ? "#b2b1b1" : "#E2FF9B"} onClick={CommentRegistration}>
                    <CustomText cl={addComment === "" ? "#b1b1b1" : "#E2FF9B"}>등록</CustomText>
                </CustomBtn>
            </div>
            <div>
                <div style={{ borderBottom: "1px solid #707070", paddingBottom: "0.8rem" }}>
                    <CommentInput placeholder="댓글 입력" value={addComment} onChange={commentChange} />
                </div>

                {!issue ? (
                    <>
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                        <CommentListSkeleton />
                    </>
                ) : comments.length === 0 ? (
                    <CustomText ta="center" mg="2rem 0" fs="1.125rem" style={{ width: "100%" }}>
                        등록된 댓글이 없습니다.
                    </CustomText>
                ) : (
                    <InfiniteScroll
                        loader={
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: loadingLottie,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width="4rem"
                                height="4rem"
                                style={{
                                    marginTop: "1rem",
                                    marginBottom: "1rem",
                                }}
                            />
                        }
                        dataLength={comments.length}
                        next={GetComments}
                        hasMore={isHasMore}
                        style={{ marginBottom: "7rem" }}
                    >
                        {comments.map((comment, index) => {
                            return (
                                <CommentBox key={comment.id}>
                                    {(editComment.id === comment.id) & (editComment.edit === true) ? (
                                        <>
                                            <CommentInput
                                                placeholder="댓글 입력"
                                                value={editComment.comment}
                                                onChange={(e) => {
                                                    setEditComment((prev) => ({
                                                        ...prev,
                                                        ["comment"]: e.target.value,
                                                    }));
                                                }}
                                                style={{ marginBottom: "0.5rem" }}
                                                autoFocus
                                                onFocus={(e) => e.currentTarget.select()}
                                            />
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Avatar size={24} src={comment.writer.image_path ? process.env.REACT_APP_END_POINT + comment.writer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[comment.writer.id % 10] }} />

                                                    <CustomText fs="0.875rem" mg="0 0 0 9px" cl="#D4D4D4">
                                                        {comment.writer.login_id}
                                                    </CustomText>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                    <CustomBtn
                                                        bc="#b2b1b1"
                                                        onClick={() => {
                                                            setEditComment({ edit: false });
                                                        }}
                                                    >
                                                        <CustomText cl="#b1b1b1">취소</CustomText>
                                                    </CustomBtn>
                                                    <CustomBtn
                                                        disabled={editComment.comment === "" ? true : false}
                                                        bc={editComment.comment === "" ? "#b2b1b1" : "#E2FF9B"}
                                                        onClick={() => {
                                                            editCommentOnClick(comment.id, "edit");
                                                        }}
                                                    >
                                                        <CustomText cl={editComment.comment === "" ? "#b1b1b1" : "#E2FF9B"}>수정 완료</CustomText>
                                                    </CustomBtn>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <CustomText style={{ wordBreak: "break-all", whiteSpace: "pre-line" }}>{comment.content}</CustomText>
                                            </div>

                                            <div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Avatar size={24} src={comment.writer.image_path ? process.env.REACT_APP_END_POINT + comment.writer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[comment.writer.id % 10] }} />
                                                    <CustomText fs="0.875rem" mg="0 0 0 9px" cl="#D4D4D4">
                                                        {comment.writer.login_id}
                                                    </CustomText>
                                                    <CustomText fs="0.875rem" cl="#D4D4D4" mg="0 0 0 25px">
                                                        {dayjs(comment.created_at).locale("ko").format(`YYYY.MM.DD A h:mm`)}
                                                    </CustomText>
                                                </div>

                                                {comment.writer.login_id === UserInfo.data.login_id && (
                                                    <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setEditComment((prev) => ({
                                                                    ...prev,
                                                                    ["id"]: comment.id,
                                                                    ["edit"]: true,
                                                                    ["comment"]: comment.content,
                                                                }));
                                                            }}
                                                        >
                                                            <EditIcon style={{ width: "1.4rem", height: "1.4rem", marginTop: "7px" }} />
                                                            <CustomText cl="#B1B1B1" mg="0 0 0 5px">
                                                                수정
                                                            </CustomText>
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                            onClick={() => {
                                                                setConfirmModalOpen({
                                                                    open: true,
                                                                    title: "댓글 삭제",
                                                                    text: `댓글을 삭제하시겠습니까?`,
                                                                    afterFunction: () => {
                                                                        editCommentOnClick(comment.id, "delete");
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon style={{ width: "1rem", height: "1rem" }} />
                                                            <CustomText cl="#FF614B" mg="0 0 0 5px">
                                                                삭제
                                                            </CustomText>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </CommentBox>
                            );
                        })}
                    </InfiniteScroll>
                )}
            </div>
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />

            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default IssueDetail;
