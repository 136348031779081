import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";
import { useQuery } from "react-query";
import Select from "react-select";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    margin-top: 2.5rem;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    background-color: #21272e;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
`;

const EditMember = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

const StyledSelect = styled(Select)`
    width: 90%;
    background-color: #21272e !important;
    font-size: 1.05rem;
    font-family: Pretendard;
    font-weight: 400;
    color: white;

    input {
        color: white !important;
        font-size: 1.05rem !important;
        font-family: Pretendard !important;
        font-weight: 400 !important;
    }

    .css-1okebmr-indicatorSeparator {
        background: none;
    }
`;

function EditDataSetModal({ editDataSetModalOpen, setEditDataSetModalOpen, dataName, setDataName, EditDataset }) {
    const { SelectedDataset } = useSelector((state) => state);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    return (
        <Modal
            isOpen={editDataSetModalOpen}
            onRequestClose={() => {
                setEditDataSetModalOpen(false);
            }}
            style={customStyles}
            contentLabel="Alert Modal"
        >
            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                데이터셋 이름 변경
            </CustomText>
            <EditMember>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        데이터셋 명
                    </CustomText>
                    <InputBox>
                        {dataName === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input
                            name="data_name"
                            value={dataName}
                            onChange={(e) => {
                                setDataName(e.target.value);
                            }}
                            autoComplete="off"
                        />
                    </InputBox>
                </div>
            </EditMember>

            <BtnWrapper>
                <CustomBtn
                    bc={"#B2B1B1"}
                    onClick={() => {
                        setEditDataSetModalOpen(false);
                    }}
                >
                    <CustomText fs="0.9rem" cl={"#B2B1B1"}>
                        취소
                    </CustomText>
                </CustomBtn>
                <CustomBtn
                    bc={dataName !== "" ? "#E2FF9B" : "#B1B1B1"}
                    onClick={() => {
                        EditDataset(0, SelectedDataset.data.id, "title", dataName);
                    }}
                    disabled={dataName !== "" ? false : true}
                >
                    <CustomText fs="0.9rem" cl={dataName !== "" ? "#E2FF9B" : "#B1B1B1"}>
                        수정
                    </CustomText>
                </CustomBtn>
            </BtnWrapper>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default EditDataSetModal;
