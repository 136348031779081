import React, {useState, useEffect, useMemo, useRef} from "react";

import {useSelector} from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import * as tf from "@tensorflow/tfjs";

const Image = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    transition: width 200ms;
    position: absolute;
    border-radius: 10px;
    z-index: 99;
`;

const Video = styled.video`
    display: none;
    position: absolute;
    z-index: 99;
`;

const Canvas = styled.canvas`
    width: ${props => props.wid + "px"};
    height: ${props => props.hei + "px"};
    transition: width 200ms, height 200ms;
    position: absolute;
    border-radius: 10px;
    z-index: 99;
`;

function DisplayVideo(props) {
    const [imageSrc, setImageSrc] = useState("");
    const {SelectedTrainModel, SelectedVideo} = useSelector(state => state);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    const color = [
        "#00FFFF",
        "#ff0000",
        "#1E90FF",
        "#9932CC",
        "#228B22",
        "#FF8C00",
        "#483D8B",
        "#DEB887",
        "#C71585",
        "#00CED1",
        "#8FBC8F",
        "#FF69B4",
        "#008080",
        "#BC8F8F",
        "#DAA520",
        "#191970",
        "#EEE8AA",
        "#8B4513",
        "#2E8B57",
        "#708090",
        "#00FF7F",
        "#9ACD32",
        "#EE82EE",
        "#D8BFD8",
        "#4682B4",
        "#708090",
        "#FA8072",
        "#F4A460",
        "#BC8F8F",
        "#FF4500",
        "#808000",
    ];
    let tfModel = "";
    let predictions = "";
    let batched = "";
    const valid_url = "https://validation.newlearn.ai";

    useEffect(() => {
        if (
            !_.isEmpty(SelectedTrainModel.data) !== undefined &&
            SelectedTrainModel.data != undefined
        ) {
            if (SelectedTrainModel.data.training_algorithm.id === 1) {
                if (props.validationType.type === 2) {
                    play_yolov5_detection();
                } else {
                    setImageSrc(valid_url + "/api/project/detect/yolov5/" + 1);
                }
                return () => {
                    if (props.validationType.type === 1) {
                        props.clickPauseButton(props.data);
                        return;
                    } else {
                        if (predictions !== "") {
                            try {
                                tfModel.dispose();
                                tf.dispose(tfModel);
                                tf.dispose(predictions);
                                tf.dispose(batched);
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                };
            } else if (SelectedTrainModel.data.training_algorithm.id === 5) {
                setImageSrc(valid_url + "/api/project/detect/yolov4/" + 1);
            } else if (SelectedTrainModel.data.training_algorithm.id === 2) {
                setImageSrc(valid_url + "/api/project/detect/mask/" + 1);
            } else if (SelectedTrainModel.data.training_algorithm.id === 4) {
                if (props.validationType.type === 2) {
                    play_ssd_detection();
                    return () => {
                        try {
                            tfModel.dispose();
                            tf.dispose(tfModel);
                            tf.dispose(predictions);
                            tf.dispose(batched);
                        } catch (error) {
                            console.log(error);
                        }
                    };
                } else {
                    setImageSrc(valid_url + "/api/project/detect/mask/" + 1);
                }
            } else if (SelectedTrainModel.data.training_algorithm.id === 3) {
                if (props.validationType.type === 2) {
                    play_faster_detection();
                    return () => {
                        try {
                            tfModel.dispose();
                            tf.dispose(tfModel);
                            tf.dispose(predictions);
                            tf.dispose(batched);
                        } catch (error) {
                            console.log(error);
                        }
                    };
                } else {
                    setImageSrc(valid_url + "/api/project/detect/mask/" + 1);
                }
            } else if (SelectedTrainModel.data.training_algorithm.id === 6) {
                setImageSrc(valid_url + "/api/project/detect/yolact/" + 1);
            } else if (SelectedTrainModel.data.training_algorithm.id === 7) {
                setImageSrc(valid_url + "/api/project/detect/yolov7/" + 1);
            }
        }
    }, [props.validationType]);

    useEffect(() => {
        function checkSize() {
            const trainsition = document.querySelector(".display_video");
            const container = document.querySelector(".video-detection-section");
            if (props.fullScreenEvent === undefined) {
                setSize({
                    width: container.offsetWidth,
                    height: container.offsetHeight,
                });
            }
            setSize({
                width: container.offsetWidth,
                height: container.offsetHeight,
            });
            trainsition.ontransitionend = () => {
                setSize({
                    width: container.offsetWidth,
                    height: container.offsetHeight,
                });
            };
        }
        checkSize();
    }, [props.fullScreenEvent]);

    const load_model = async () => {
        // It's possible to load the model locally or from a repo
        // You can choose whatever IP and PORT you want in the "http://127.0.0.1:8080/model.json" just set it before in your https server
        //const model = await loadGraphModel("http://127.0.0.1:8080/model.json");
        tfModel = await tf.loadGraphModel(
            process.env.REACT_APP_END_POINT + SelectedTrainModel.data.mobile_model_json,
            {
                weightUrlConverter: weightFileName => {
                    return (
                        process.env.REACT_APP_END_POINT + SelectedTrainModel.data.mobile_model_bin
                    );
                },
            }
        );

        if (SelectedTrainModel.data.training_algorithm.id === 1) {
            const zeroTensor = tf.zeros([1, 640, 640, 3], "float32");
            const result = await tfModel.executeAsync(zeroTensor);
            tf.dispose(result);
            tf.dispose(zeroTensor);
        } else if (SelectedTrainModel.data.training_algorithm.id === 4) {
            const zeroTensor = tf.zeros([1, 300, 300, 3], "int32");
            const result = await tfModel.executeAsync(zeroTensor);
            tf.dispose(result);
            tf.dispose(zeroTensor);
        }
        return tfModel;
    };

    const play_yolov5_detection = () => {
        const videoPromise = new Promise((resolve, reject) => {
            let parseData = SelectedVideo.data.file_path.split("/");
            let file_path =
                parseData[3] +
                "/" +
                parseData[4] +
                "/" +
                parseData[5] +
                "/" +
                SelectedVideo.data.file_name;

            videoRef.current.src = process.env.REACT_APP_END_POINT + "/" + file_path;
            videoRef.current.style.display = "none";

            videoRef.current.onloadeddata = e => {
                if (videoRef.current !== null) {
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.canvas.width = videoRef.current.videoWidth;
                    ctx.canvas.height = videoRef.current.videoHeight;
                    videoRef.current.play();
                    videoRef.current.playbackRate = 1;
                    console.log("video loaded");
                    resolve();
                }
            };
        });

        const modelPromise = load_model();

        Promise.all([modelPromise, videoPromise])
            .then(values => {
                detectYOLOV5Frame(videoRef.current, values[0]);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const play_ssd_detection = () => {
        const videoPromise = new Promise((resolve, reject) => {
            let parseData = SelectedVideo.data.file_path.split("/");
            let file_path =
                parseData[3] +
                "/" +
                parseData[4] +
                "/" +
                parseData[5] +
                "/" +
                SelectedVideo.data.file_name;
            videoRef.current.src = process.env.REACT_APP_END_POINT + "/" + file_path;
            videoRef.current.style.display = "none";

            videoRef.current.onloadeddata = e => {
                if (videoRef.current !== null) {
                    console.log("video loaded");
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.canvas.width = videoRef.current.videoWidth;
                    ctx.canvas.height = videoRef.current.videoHeight;
                    videoRef.current.play();
                    videoRef.current.playbackRate = 1;
                    resolve();
                }
            };
        });

        const modelPromise = load_model();

        Promise.all([modelPromise, videoPromise])
            .then(values => {
                detectSSDFrame(videoRef.current, values[0]);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const play_faster_detection = async () => {
        const videoPromise = new Promise((resolve, reject) => {
            let parseData = SelectedVideo.data.file_path.split("/");
            let file_path =
                parseData[3] +
                "/" +
                parseData[4] +
                "/" +
                parseData[5] +
                "/" +
                SelectedVideo.data.file_name;
            videoRef.current.src = process.env.REACT_APP_END_POINT + "/" + file_path;
            videoRef.current.style.display = "none";

            videoRef.current.onloadeddata = e => {
                if (videoRef.current !== null) {
                    console.log("video loaded");
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.canvas.width = videoRef.current.videoWidth;
                    ctx.canvas.height = videoRef.current.videoHeight;
                    videoRef.current.play();
                    videoRef.current.playbackRate = 0.3;
                    resolve();
                }
            };
        });

        const modelPromise = load_model();

        await Promise.all([modelPromise, videoPromise])
            .then(values => {
                detectFasterRCNNFrame(videoRef.current, values[0]);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const process_yolov5_input = video_frame => {
        batched = tf.image
            .resizeBilinear(tf.browser.fromPixels(video_frame), [640, 640])
            .div(255.0)
            .expandDims(0);

        return batched;
    };

    const process_input = video_frame => {
        batched = tf.tidy(() => {
            if (!(video_frame instanceof tf.Tensor)) {
                video_frame = tf.browser.fromPixels(video_frame);
            }
            // Reshape to a single-element batch so we can pass it to executeAsync.
            return tf.expandDims(video_frame);
        });
        return batched;
    };

    const detectYOLOV5Frame = async (video, model) => {
        tf.setBackend("webgl");
        tf.engine().startScope();

        if (video !== null) {
            try {
                predictions = await model.executeAsync(process_yolov5_input(video));
                renderYOLOV5Predictions(predictions, video);
                if (props.isPlayButton === true) {
                    requestAnimationFrame(() => {
                        tf.dispose(batched);
                        tf.dispose(predictions);
                        detectYOLOV5Frame(video, model);
                    });
                    tf.engine().endScope();
                }
            } catch (error) {
                console.log(error);
                try {
                    tf.dispose(video);
                } catch (error) {
                    return;
                }
                return;
            }
        }
    };

    const detectSSDFrame = async (video, model) => {
        tf.setBackend("webgl");
        tf.engine().startScope();

        if (video !== null) {
            try {
                predictions = await model.executeAsync(process_input(video));
                renderSSDPredictions(predictions, video);
                if (props.isPlayButton === true) {
                    requestAnimationFrame(() => {
                        tf.dispose(batched);
                        tf.dispose(predictions);
                        detectSSDFrame(video, model);
                    });
                    tf.engine().endScope();
                }
            } catch (error) {
                try {
                    tf.dispose(video);
                } catch (error) {
                    return;
                }
                return;
            }
        }
    };

    const detectFasterRCNNFrame = async (video, model) => {
        tf.setBackend("webgl");
        tf.engine().startScope();

        if (video !== null) {
            try {
                predictions = await model.executeAsync(process_input(video));
                renderFasterRCNNPredictions(predictions, video);
                if (props.isPlayButton === true) {
                    requestAnimationFrame(() => {
                        tf.dispose(batched);
                        tf.dispose(predictions);
                        detectFasterRCNNFrame(video, model);
                    });
                    tf.engine().endScope();
                }
            } catch (error) {
                try {
                    tf.dispose(video);
                } catch (error) {
                    return;
                }
            }
        }
    };

    const buildDetectedObjects = (scores, boxes, classes) => {
        const detectionObjects = [];
        const ctx = canvasRef.current.getContext("2d");

        scores[0].forEach((score, i) => {
            if (score > 0.55 && classes !== undefined) {
                const bbox = [];
                const minY = boxes[0][i][0] * ctx.canvas.height;
                const minX = boxes[0][i][1] * ctx.canvas.width;
                const maxY = boxes[0][i][2] * ctx.canvas.height;
                const maxX = boxes[0][i][3] * ctx.canvas.width;

                bbox[0] = minX;
                bbox[1] = minY;
                bbox[2] = maxX - minX;
                bbox[3] = maxY - minY;

                detectionObjects.push({
                    class: parseInt(classes[i]),
                    label: SelectedTrainModel.data.label_list[parseInt(classes[i])],
                    score: score.toFixed(2),
                    bbox: bbox,
                });
            }
        });

        return detectionObjects;
    };

    const buildDetectedYOLOv5Objects = (scores, boxes, classes, validation_data) => {
        const detectionObjects = [];
        const ctx = canvasRef.current.getContext("2d");

        let i;

        if (scores !== undefined) {
            for (i = 0; i < validation_data; ++i) {
                if (scores[0][i] > 0.55) {
                    const bbox = [];

                    const minY = boxes[0][i][1] * ctx.canvas.height;
                    const minX = boxes[0][i][0] * ctx.canvas.width;
                    const maxY = boxes[0][i][3] * ctx.canvas.height;
                    const maxX = boxes[0][i][2] * ctx.canvas.width;

                    bbox[0] = minX;
                    bbox[1] = minY;
                    bbox[2] = maxX - minX;
                    bbox[3] = maxY - minY;

                    detectionObjects.push({
                        class: parseInt(classes[0][i]),
                        label: SelectedTrainModel.data.label_list[parseInt(classes[0][i])],
                        score: scores[0][i].toFixed(2),
                        bbox: bbox,
                    });
                }
            }
        }
        return detectionObjects;
    };

    const renderYOLOv5 = predictions => {
        let boxes;
        let classes;
        let scores;
        let i;

        let label_length = SelectedTrainModel.data.label_list.length;
        let validation_data;

        for (i = 0; i < 4; ++i) {
            if (JSON.stringify(predictions[i].shape) === JSON.stringify([1, 100, 4])) {
                boxes = predictions[i].arraySync();
            }

            if (JSON.stringify(predictions[i].shape) === JSON.stringify([1, 100])) {
                if (predictions[i].arraySync()[0][0] % 1 !== 0) {
                    scores = predictions[i].arraySync();
                } else if (predictions[i].arraySync()[0][0] < label_length) {
                    classes = predictions[i].arraySync();
                }
            }

            if (JSON.stringify(predictions[i].shape) == JSON.stringify([1])) {
                validation_data = predictions[i].arraySync()[0];
            }
        }
        return [boxes, scores, classes, validation_data];
    };

    const renderSSD = predictions => {
        let boxes;
        let classes;
        let scores;
        let i;
        let label_length = SelectedTrainModel.data.label_list.length;

        for (i = 0; i < 8; ++i) {
            if (JSON.stringify(predictions[i].shape) === JSON.stringify([1, 100, 4])) {
                boxes = predictions[i].arraySync();
            }

            if (JSON.stringify(predictions[i].shape) === JSON.stringify([1, 100])) {
                if (predictions[i].arraySync()[0][0] % 1 !== 0) {
                    scores = predictions[i].arraySync();
                } else if (predictions[i].arraySync()[0][0] < label_length) {
                    classes = predictions[i].dataSync();
                }
            }
        }

        return [boxes, scores, classes];
    };

    const renderFasterRCNN = predictions => {
        let boxes;
        let classes;
        let scores;
        let i;
        let label_length = SelectedTrainModel.data.label_data.length;

        for (i = 0; i < 8; ++i) {
            if (JSON.stringify(predictions[i].shape) == JSON.stringify([1, 300, 4])) {
                boxes = predictions[i].arraySync();
            }

            if (JSON.stringify(predictions[i].shape) == JSON.stringify([1, 300])) {
                if (predictions[i].arraySync()[0][0] % 1 !== 0) {
                    scores = predictions[i].arraySync();
                } else if (predictions[i].arraySync()[0][0] < label_length) {
                    classes = predictions[i].dataSync();
                }
            }
        }

        return [boxes, scores, classes];
    };

    const renderYOLOV5Predictions = (predictions, video) => {
        tf.setBackend("cpu");
        const ctx = canvasRef.current.getContext("2d");

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(video, 0, 0, ctx.canvas.width, ctx.canvas.height);

        //video.style.display = "block";

        // Font options.
        const font = "25px sans-serif";
        ctx.font = font;
        ctx.textBaseline = "top";

        //Getting predictions
        const renderPredictions = renderYOLOv5(predictions);
        const detections = buildDetectedYOLOv5Objects(
            renderPredictions[1],
            renderPredictions[0],
            renderPredictions[2],
            renderPredictions[3],
            video
        );

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            const width = item["bbox"][2];
            const height = item["bbox"][3];

            // Draw the bounding box.
            ctx.strokeStyle = color[item["class"]];
            ctx.lineWidth = 6;

            ctx.strokeRect(x, y, width, height);
            // Draw the label background.
            ctx.fillStyle = color[item["class"]];
            const textWidth = ctx.measureText(item["label"] + " " + item["score"]).width;
            let textHeight = parseInt(font, 10); // base 10
            ctx.fillRect(x - 2, y - textHeight, textWidth + 4, textHeight + 2);
        });

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            let textHeight = parseInt(font, 10); // base 10
            // Draw the text last to ensure it's on top.
            ctx.fillStyle = "#000000";
            ctx.fillText(item["label"] + " " + item["score"], x, y - textHeight);
        });

        tf.dispose(renderPredictions[0]);
        tf.dispose(renderPredictions[1]);
        tf.dispose(renderPredictions[2]);
        tf.dispose(renderPredictions[3]);
    };

    const renderSSDPredictions = (predictions, video) => {
        tf.setBackend("cpu");
        const ctx = canvasRef.current.getContext("2d");

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(video, 0, 0, ctx.canvas.width, ctx.canvas.height);

        //video.style.display = "block";
        // Font options.
        const font = "25px sans-serif";
        ctx.font = font;
        ctx.textBaseline = "top";

        const renderPredictions = renderSSD(predictions);

        //Getting predictions
        const detections = buildDetectedObjects(
            renderPredictions[1],
            renderPredictions[0],
            renderPredictions[2]
        );

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            const width = item["bbox"][2];
            const height = item["bbox"][3];

            // Draw the bounding box.
            ctx.strokeStyle = color[item["class"] - 1];
            ctx.lineWidth = 6;

            ctx.strokeRect(x, y, width, height);
            // Draw the label background.
            ctx.fillStyle = color[item["class"] - 1];
            const textWidth = ctx.measureText(item["label"] + " " + item["score"]).width;
            let textHeight = parseInt(font, 10); // base 10
            ctx.fillRect(x - 1, y - textHeight, textWidth + 4, textHeight + 2);
        });

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            let textHeight = parseInt(font, 10); // base 10
            // Draw the text last to ensure it's on top.
            ctx.fillStyle = "#000000";
            ctx.fillText(item["label"] + " " + item["score"], x, y - textHeight);
        });

        tf.dispose(renderPredictions[0]);
        tf.dispose(renderPredictions[1]);
        tf.dispose(renderPredictions[2]);
    };

    const renderFasterRCNNPredictions = (predictions, video) => {
        tf.setBackend("cpu");
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(video, 0, 0, ctx.canvas.width, ctx.canvas.height);
        //video.style.display = "block";

        // Font options.
        const font = "25px sans-serif";
        ctx.font = font;
        ctx.textBaseline = "top";

        const renderPredictions = renderFasterRCNN(predictions);
        //Getting predictions
        const detections = buildDetectedObjects(
            renderPredictions[1],
            renderPredictions[0],
            renderPredictions[2]
        );

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            const width = item["bbox"][2];
            const height = item["bbox"][3];

            // Draw the bounding box.
            ctx.strokeStyle = color[item["class"] - 1];
            ctx.lineWidth = 6;

            ctx.strokeRect(x, y, width, height);
            // Draw the label background.
            ctx.fillStyle = color[item["class"] - 1];
            const textWidth = ctx.measureText(item["label"] + " " + item["score"]).width;
            let textHeight = parseInt(font, 10); // base 10
            ctx.fillRect(x - 1, y - textHeight, textWidth + 4, textHeight + 2);
        });

        detections.forEach(item => {
            const x = item["bbox"][0];
            const y = item["bbox"][1];
            let textHeight = parseInt(font, 10); // base 10
            // Draw the text last to ensure it's on top.
            ctx.fillStyle = "#000000";
            ctx.fillText(item["label"] + " " + item["score"], x, y - textHeight);
        });

        tf.dispose(renderPredictions[0]);
        tf.dispose(renderPredictions[1]);
        tf.dispose(renderPredictions[2]);
    };

    const videoUrl = useMemo(() => `${imageSrc}?${Date.now()}`, [imageSrc]);

    return (
        <>
            {props.validationType.type === 2 ? (
                <>
                    <Video
                        selected={props.fullScreenEvent === true ? true : false}
                        muted
                        id="frame"
                        ref={videoRef}
                        crossOrigin="anonymous"></Video>
                    <Canvas
                        selected={props.fullScreenEvent === true ? true : false}
                        ref={canvasRef}
                        id="canvas"
                        wid={size.width}
                        hei={size.height}></Canvas>
                </>
            ) : (
                <Image
                    selected={props.fullScreenEvent === true ? true : false}
                    id="video"
                    width={size.width}
                    height={size.height}
                    src={videoUrl}
                    loading="lazy"></Image>
            )}
        </>
    );
}

export default DisplayVideo;
