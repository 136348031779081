import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Layout } from "antd";
import styled from "styled-components";
import Lottie from "react-lottie";
import { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { GetSideProjectList } from "../../services/project-services";

import { ReactComponent as DataCenterIcon } from "../../assets/main/data-center-icon.svg";
import { ReactComponent as AngleRightIcon } from "../../assets/user-dashboard/angle-right.svg";
import { ReactComponent as LikeIcon } from "../../assets/user-dashboard/like-icon.svg";
import { ReactComponent as ForkIcon } from "../../assets/user-dashboard/fork-icon.svg";
import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as PlusIcon } from "../../assets/user-dashboard/plus-circle.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import SideProjectSkeleton from "../../styles/theme/SideProjectSkeleton";
import AlertModal from "../modal/AlertModal";

const Wrap = styled(Layout)`
    background-color: rgba(0, 0, 0, 0);
    padding-bottom: 4rem;
    width: 100%;
    max-width: 360px;

    /* @media screen and (min-width: 1920px) {
        max-width: 400px;
    } */

    ${(props) =>
        props.page === "/schedule" || props.page === "/project/list"
            ? css`
                  @media screen and (max-width: 1024px) {
                      display: none;
                  }
              `
            : css`
                  @media screen and (max-width: 1700px) {
                      display: none;
                  }
              `}
`;

const DataCenterSection = styled(Link)`
    /* background-color: #fff; */
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SearchWrapper = styled.div`
    background-color: #303a45;
    padding: 1.4rem 24px;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;

    .search-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        list-style: none;
        gap: 13px;

        width: 100%;
    }
`;

const SearchInput = styled.input`
    background-color: #000 !important;
    border-radius: 5px;
    padding: 5px 15px;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    flex: 1;

    border: 1px solid #000;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const SearchBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;
    padding: 0 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    ${(props) =>
        props.isready === "true"
            ? css`
                  border-color: #e2ff9b !important;
                  .search-btn-text {
                      color: #e2ff9b;
                  }
              `
            : css`
                  border-color: #b1b1b1 !important;
                  .search-btn-text {
                      color: #b1b1b1;
                  }
              `}
`;

const ListWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .more-btn-section {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
    }
`;

const DataCenterItem = styled(Link)`
    border-bottom: 1px solid #707070;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .statics {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    }

    .count-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
`;

function SideDataCenterList() {
    const { Token } = useSelector((state) => state);
    const location = useLocation();

    const [projectLoading, setProjectLoading] = useState(false);
    const [searchStr, setSearchStr] = useState("");
    const [projectList, setProjectList] = useState({
        isMore: false,
        crntPage: 1,
        nextPage: null,
        data: [],
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        fetchProjectList(1, true);
    }, []);

    const fetchProjectList = useCallback(
        async (page, refresh = false) => {
            if (refresh) {
                setProjectLoading(true);
            }
            try {
                const { data: data } = await GetSideProjectList(Token.data.access, null, searchStr, page);

                setProjectList((prev) => ({
                    crntPage: page,
                    nextPage: data.next ? page + 1 : null,
                    data: page === 1 ? data.results : [...prev.data, ...data.results],
                }));
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setProjectLoading(false);
            }
        },
        [Token, searchStr, projectList.crntPage]
    );

    const handleSearchInputOnChange = useCallback((e) => {
        setSearchStr(e.target.value);
    }, []);

    const handleSearchBtnClick = useCallback(
        (e) => {
            e.preventDefault();
            fetchProjectList(1, true);
        },
        [searchStr]
    );

    return (
        <Wrap page={location.pathname}>
            <DataCenterSection to="/data-center">
                <DataCenterIcon width="5.5rem" height="5.5rem" />
                <CustomText fs="1rem" mg="1rem 0 0 0">
                    데이터센터
                </CustomText>
                <CustomText fs="0.875rem" cl="#909090" ta="center" mg="5px 0px 0px 0px">
                    다른 사용자들이 공개한 데이터를
                    <br />
                    공유하는 곳입니다.
                </CustomText>
            </DataCenterSection>
            {location.pathname !== "/data-center" && (
                <>
                    <SearchWrapper>
                        <CustomText fs="1.12rem" fw="600">
                            데이터센터
                        </CustomText>
                        <form className="search-section" onSubmit={handleSearchBtnClick}>
                            <SearchInput value={searchStr} onChange={handleSearchInputOnChange} placeholder="데이터센터 내 데이터 검색" />
                            <SearchBtn isready={searchStr !== "" ? "true" : "false"} type="submit" onClick={handleSearchBtnClick}>
                                <CustomText className="search-btn-text" fs="0.9rem" cl="#E2FF9B">
                                    검색
                                </CustomText>
                            </SearchBtn>
                        </form>
                    </SearchWrapper>

                    <ListWrapper>
                        {projectList.crntPage === 1 && projectLoading && searchStr === "" ? (
                            <>
                                <SideProjectSkeleton />
                                <SideProjectSkeleton />
                                <SideProjectSkeleton />
                                <SideProjectSkeleton />
                                <SideProjectSkeleton />
                            </>
                        ) : projectList.data.length === 0 ? (
                            <div>
                                <CustomText>프로젝트가 없습니다.</CustomText>
                            </div>
                        ) : (
                            projectList.data.map((project) => {
                                return (
                                    <DataCenterItem key={project.id} to={`/project/${project.id}?tab=dataset`}>
                                        <div>
                                            <CustomText fs="1.12rem" fw="500">
                                                {project.title}
                                            </CustomText>
                                            <div className="statics">
                                                <div className="count-section">
                                                    <LikeIcon width="0.9rem" height="0.9rem" fill="#FF6433" />
                                                    <CustomText fs="0.9rem" fw="400" cl="#FF6433">
                                                        {project.like_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <ForkIcon width="0.9rem" height="0.9rem" />
                                                    <CustomText fs="0.9rem" fw="400" cl="#3BD779">
                                                        {project.fork_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <MemberIcon width="0.9rem" height="0.9rem" fill="#FDFFA9" />
                                                    <CustomText fs="0.9rem" fw="400" cl="#FDFFA9">
                                                        {project.collaborator_count}
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                        <AngleRightIcon />
                                    </DataCenterItem>
                                );
                            })
                        )}
                        {projectList.nextPage && (
                            <div className="more-btn-section" onClick={() => fetchProjectList(projectList.nextPage)}>
                                <CustomText fs="1rem">더보기</CustomText>
                                <PlusIcon width="1rem" height="1rem" />
                            </div>
                        )}
                    </ListWrapper>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default SideDataCenterList;
