import React from "react";
import styled from "styled-components";

import HomeLayout from "../../../components/layout/HomeLayout";
import GuideLayout from "../../../components/layout/GuideLayout";
import Terminology from "../../../components/document/Terminology";
import MemberShipGuide from "../../../components/document/terminology-guide/membershipGuide";
import DataUpload from "../../../components/document/terminology-guide/dataUpload";
import DataPreProcessiong from "../../../components/document/terminology-guide/dataPreProcessiong";
import DataLabeling from "../../../components/document/terminology-guide/dataLabeling";
import TrainModel from "../../../components/document/terminology-guide/trainModel";
import ProjectTerminology from "../../../components/document/terminology-guide/ProjectTerminology";
import AutoLabel from "../../../components/document/terminology-guide/AutoLabel";

import queryString from "query-string";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
`;

const ContentWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #0c1117;
`;

function TerminologyGuide() {
    const { search } = useLocation();
    const query = queryString.parse(search);
    const [terminology, setTerminology] = useState(undefined);

    useEffect(() => {
        if (query.terminology !== undefined) {
            setTerminology(parseInt(query.terminology));
        } else {
            setTerminology(query.terminology);
        }
    }, [search]);

    return (
        <HomeLayout>
            <Wrap>
                <GuideLayout />
                <ContentWrap>
                    {terminology === undefined && <Terminology />}
                    {terminology === 1 && <MemberShipGuide />}
                    {terminology === 2 && <ProjectTerminology />}
                    {terminology === 3 && <DataUpload />}
                    {terminology === 4 && <DataPreProcessiong />}
                    {terminology === 5 && <DataLabeling />}
                    {terminology === 6 && <TrainModel />}
                    {terminology === 7 && <AutoLabel />}
                </ContentWrap>
            </Wrap>
        </HomeLayout>
    );
}

export default TerminologyGuide;
