import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button } from "antd";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as AddMemberIcon } from "../../assets/modal/add-member.svg";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import * as successLottie from "../../assets/modal/success-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import { InviteMemberInProject } from "../../services/project-services";
import { useMutation } from "react-query";
import AlertModal from "./AlertModal";
import { UpdateDataCenterDataSet } from "../../services/datacenter-services";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const CustomTextArea = styled.textarea`
    width: 100%;
    background-color: #21272e;
    padding: 10px 10px 10px 1rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 7rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 20rem;
    }
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

Modal.setAppElement("#root");

function UpdatedataSetExplanation({ modalIsOpen, setIsOpen, dataCenterData }) {
    const { Token } = useSelector((state) => state);
    const params = useParams();
    const dispatch = useDispatch();

    const updateMutation = useMutation(UpdateDataCenterDataSet);
    const [dataSetExplanation, setDataSetExplanation] = useState("");
    const [dataSetExplanationErr, setDataSetExplanationErr] = useState("");

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setDataSetExplanation(dataCenterData?.data_explanation);
    }, [dataCenterData]);

    const handleDataSetExplanationChange = useCallback(
        (e) => {
            if (dataSetExplanationErr !== "") {
                setDataSetExplanationErr("");
            }
            setDataSetExplanation(e.target.value);
        },
        [dataSetExplanationErr]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                handleUpdateBtnClick();
            }
        },
        [dataSetExplanation]
    );

    const handleUpdateBtnClick = useCallback(async () => {
        if (dataSetExplanation === "") {
            setDataSetExplanationErr("아이디를 입력하세요");
            return;
        }

        const formData = new FormData();
        formData.append("update_type", "explanation");
        formData.append("data_explanation", dataSetExplanation);

        await updateMutation.mutateAsync(
            { token: Token.data.access, dataCenterId: params.id, formData: formData },
            {
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, dataSetExplanation, dataSetExplanationErr]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterClose={() => {
                if (updateMutation.isSuccess) {
                    updateMutation.reset();
                }
            }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Member"
        >
            {updateMutation.isSuccess ? (
                <>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: successLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="8rem"
                        height="8rem"
                        style={{
                            marginBottom: "0.8rem",
                        }}
                    />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        데이터셋 설명 변경 완료
                    </CustomText>
                    {/* <CustomText fs="0.875rem" cl="#d4d4d4">
                        추가하는 팀원이 초대 메일 수락 후<br />
                        프로젝트에 참여할 수 있습니다.
                    </CustomText> */}

                    <CustomBtn
                        bc="#E2FF9B"
                        onClick={() => {
                            setIsOpen(false);
                            window.location.reload();
                        }}
                    >
                        <CustomText fs="0.9rem" cl="#E2FF9B">
                            확인
                        </CustomText>
                    </CustomBtn>
                </>
            ) : (
                <>
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        데이터셋 설명 변경
                    </CustomText>

                    <CustomTextArea name="explanation" value={dataSetExplanation} onKeyDown={handleKeyDown} onChange={handleDataSetExplanationChange} autoFocus autoComplete="off" placeholder="데이터셋 설명" />
                    {dataSetExplanationErr !== "" && <CustomText cl="#F8514A">{dataSetExplanationErr}</CustomText>}

                    <CustomBtn bc={dataSetExplanation !== "" ? "#e2ff9b" : "#b2b1b1"} onClick={handleUpdateBtnClick} disabled={dataSetExplanation !== "" ? false : true} loading={updateMutation.isLoading}>
                        <CustomText fs="0.9rem" cl={dataSetExplanation !== "" ? "#E2FF9B" : "#b2b1b1"}>
                            변경
                        </CustomText>
                    </CustomBtn>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default UpdatedataSetExplanation;
