import { Button, Menu, Dropdown } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";
import AlertModal from "./AlertModal";
import { useSelector } from "react-redux";
import { GetDataCenterCategory, RegistrationDataCenter } from "../../services/datacenter-services";
import { useNavigate, useParams } from "react-router-dom";
import { GetProjectExpanationMember } from "../../services/project-services";
import { useQuery } from "react-query";

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 100%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;
        text-align: center;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
    border-bottom: 1px dashed #b1b1b1;
`;

const CustomTextArea = styled.textarea`
    width: 100%;
    background-color: #21272e;
    padding: 10px 10px 10px 1rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 7rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 20rem;
    }
`;

const StyledDropDown = styled(Dropdown)`
    margin-top: 1rem;
    background-color: #21272e;
    border-radius: 5px;
    padding: 0.45rem 1.2rem;
    border: 1px solid #707070;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
    .ant-dropdown {
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        padding: 0.6rem 1.2rem;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function RegistrationDataCenterModal({ dataCenterModalOpen, setDataCenterModalOpen }) {
    const { SelectedDataset, Token } = useSelector((state) => state);

    const params = useParams();

    const [firstCategory, setFirstCategory] = useState({ id: "0", name: "" });
    const [secondCategory, setSecondCategory] = useState({ id: "0", name: "" });

    const [dataSetName, setDataSetName] = useState("");
    const [dataSetExplanation, setDataSetExplanation] = useState("");
    const [datasetNameErr, setDatasetNameErr] = useState("");
    const [datasetExplanationErr, setDatasetExplanationErr] = useState("");
    const [firstCategoryErr, setFirstCategoryErr] = useState("");
    const [secondCategoryErr, setSecondCategoryErr] = useState("");
    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const handleDataSetNameChange = useCallback(
        (e) => {
            setDatasetNameErr("");
            setDataSetName(e.target.value);
        },
        [dataSetName]
    );

    const handlesetDataSetExplanationChange = useCallback(
        (e) => {
            setDatasetExplanationErr("");
            setDataSetExplanation(e.target.value);
        },
        [dataSetExplanation]
    );

    const { data: projectData } = useQuery(
        ["project", "member", params.id],
        async (e) => {
            const data = await GetProjectExpanationMember(Token.data.access, params.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: dataSetFirstCategoryList } = useQuery(
        ["dataset", "firstCategory"],
        async (e) => {
            const data = await GetDataCenterCategory("0");
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: dataSetSecondCategoryList } = useQuery(
        ["dataset", "secondCategory", firstCategory],
        async (e) => {
            const data = await GetDataCenterCategory(firstCategory.id);
            return data;
        },
        {
            enabled: firstCategory.id !== "0",
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const closeModal = useCallback(async () => {
        if (firstCategory.name === "") {
            setFirstCategoryErr("대분류 카테고리를 선택해주세요");
            return;
        }

        if ((dataSetSecondCategoryList?.data !== undefined) & (dataSetSecondCategoryList?.data.length !== 0)) {
            if (secondCategory.name === "") {
                setSecondCategoryErr("중분류 카테고리를 선택해주세요");
                return;
            }
        }

        if (dataSetName === "") {
            setDatasetNameErr("데이터셋 명을 입력하세요");
            return;
        }

        if (dataSetExplanation === "") {
            setDatasetExplanationErr("데이터셋 구축 목적을 입력하세요");
            return;
        }

        try {
            const formData = new FormData();

            formData.append("data_name", dataSetName);
            formData.append("data_explanation", dataSetExplanation);
            formData.append("provider_id", projectData.data.manager.id);
            formData.append("dataset_id", SelectedDataset.data.id);

            if (dataSetSecondCategoryList.data.length === 0) {
                formData.append("category_id", firstCategory.id);
            } else {
                formData.append("category_id", secondCategory.id);
            }

            const res = await RegistrationDataCenter(Token.data.access, params.id, formData);

            navigate(`/data-center/${res.data.id}`);

            setDataCenterModalOpen({
                open: false,
                afterFunction: null,
            });
        } catch (err) {
            if (err.response.status === 409) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이름 오류",
                    text: "이미 사용중인 데이터셋 명 입니다.",
                    afterFunction: null,
                });
            }
        }
    }, [dataCenterModalOpen, dataSetName, dataSetExplanation, firstCategory, secondCategory, dataSetSecondCategoryList?.data]);

    const firstCategoryList = useMemo(
        () => (
            <StyledMenu>
                {dataSetFirstCategoryList?.data.map((category) => {
                    return (
                        <Menu.Item
                            className="menu-item"
                            onClick={() => {
                                setFirstCategory({ id: category.id, name: category.category_name });
                                setSecondCategory({ id: "0", name: "" });
                                setFirstCategoryErr("");
                                setSecondCategoryErr("");
                            }}
                        >
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {category.category_name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [dataSetFirstCategoryList]
    );

    const secondCategoryList = useMemo(
        () => (
            <StyledMenu>
                {dataSetSecondCategoryList?.data.map((category) => {
                    return (
                        <Menu.Item
                            className="menu-item"
                            onClick={() => {
                                setSecondCategory({ id: category.id, name: category.category_name });
                                setSecondCategoryErr("");
                            }}
                        >
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {category.category_name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [dataSetSecondCategoryList]
    );

    return (
        <Modal
            isOpen={dataCenterModalOpen.open}
            onRequestClose={() => {
                setDataCenterModalOpen({
                    open: false,
                    afterFunction: null,
                });
            }}
            style={customStyles}
            contentLabel="Alert Modal"
        >
            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                데이터센터 등록하기
            </CustomText>
            <CustomText fs="0.875rem" cl="#d4d4d4" ta="center" mg="0 0 1rem 0" style={{ whiteSpace: "pre-line" }}>
                현재 데이터셋의 이미지를 데이터센터에 등록합니다.
            </CustomText>

            <StyledDropDown overlay={firstCategoryList} placement="bottom" trigger={["click"]} getPopupContainer={(trigger) => trigger.parentNode}>
                <MenuItem>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
                        <CustomText fs="1.125rem" ta="center" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {firstCategory.name === "" ? <span style={{ color: "#B1B1B1" }}>대분류 선택</span> : firstCategory.name}
                        </CustomText>
                        <DownArrow width="1.125rem" height="1.125rem" />
                    </div>
                </MenuItem>
            </StyledDropDown>
            {firstCategoryErr !== "" && (
                <CustomText cl="#F8514A" mg="7px 0 0 0">
                    {firstCategoryErr}
                </CustomText>
            )}
            <StyledDropDown overlay={secondCategoryList} placement="bottom" trigger={["click"]} getPopupContainer={(trigger) => trigger.parentNode}>
                <MenuItem>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
                        <CustomText fs="1.125rem" ta="center" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {secondCategory.name === "" ? <span style={{ color: "#B1B1B1" }}>중분류 선택</span> : secondCategory.name}
                        </CustomText>
                        <DownArrow width="1.125rem" height="1.125rem" />
                    </div>
                </MenuItem>
            </StyledDropDown>
            {secondCategoryErr !== "" && (
                <CustomText cl="#F8514A" mg="7px 0 0 0">
                    {secondCategoryErr}
                </CustomText>
            )}

            <InputBox>
                {dataSetName === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                <input name="name" value={dataSetName} onChange={handleDataSetNameChange} maxLength="50" autoComplete="off" placeholder="데이터셋 이름 입력" />
            </InputBox>
            {datasetNameErr !== "" && (
                <CustomText cl="#F8514A" mg="7px 0 0 0">
                    {datasetNameErr}
                </CustomText>
            )}

            <CustomText wd="100%" mg="2rem 0 0.3rem 0">
                데이터셋 구축 목적
            </CustomText>
            <CustomTextArea value={dataSetExplanation} onChange={handlesetDataSetExplanationChange} />
            {datasetExplanationErr !== "" && (
                <CustomText cl="#F8514A" mg="7px 0 0 0">
                    {datasetExplanationErr}
                </CustomText>
            )}

            <CustomBtn bc={"#E2FF9B"} onClick={closeModal}>
                <CustomText fs="0.9rem" cl={"#E2FF9B"}>
                    등록
                </CustomText>
            </CustomBtn>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default RegistrationDataCenterModal;
