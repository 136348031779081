import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { CompanyRegistrationNumberDupCheck, GetCompany, UpdateCompany, UpdateCompanyInfo, UpdateUserInfo, UserEmailDupCheck } from "../../services/account-services";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";
import { useQuery } from "react-query";
import Select from "react-select";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    margin-top: 2.5rem;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    background-color: #21272e;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
`;

const EditMember = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

const StyledSelect = styled(Select)`
    width: 90%;
    background-color: #21272e !important;
    font-size: 1.05rem;
    font-family: Pretendard;
    font-weight: 400;
    color: white;

    input {
        color: white !important;
        font-size: 1.05rem !important;
        font-family: Pretendard !important;
        font-weight: 400 !important;
    }

    .css-1okebmr-indicatorSeparator {
        background: none;
    }
`;

function EditCompanyModal({ editCompanyModalOpen, setEditCompanyModalOpen, company }) {
    const { UserInfo, Token } = useSelector((state) => state);
    const [editCompany, setEditCompany] = useState({ company_name: company.company_name, company_registration_number: company.company_registration_number });
    const [companyRegistrationNumberCheck, setCompanyRegistrationNumberCheck] = useState(false);

    const [editComplete, setEditComplete] = useState(true);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { refetch: companyRegistrationNumberRefetch } = useQuery(
        ["companyRegistrationNumberRefetchCheck"],
        async (e) => {
            const data = await CompanyRegistrationNumberDupCheck(editCompany.company_registration_number.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3"));
            return data;
        },
        {
            retry: false,
            onSuccess: (res) => {
                setCompanyRegistrationNumberCheck(true);
            },
            onError: (err) => {
                if (company.company_registration_number === editCompany.company_registration_number) {
                    setCompanyRegistrationNumberCheck(true);
                } else {
                    setCompanyRegistrationNumberCheck(false);
                }
            },
        }
    );

    useEffect(() => {
        if (editCompany.company_registration_number !== "") {
            companyRegistrationNumberRefetch();
        }

        if (editCompany.company_registration_number.length === 10) {
            setEditCompany((prev) => ({ ...prev, ["company_registration_number"]: editCompany.company_registration_number.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3") }));
        }

        if (company.company_registration_number === editCompany.company_registration_number) {
            setCompanyRegistrationNumberCheck(true);
        } else {
            setCompanyRegistrationNumberCheck(false);
            companyRegistrationNumberRefetch();
        }

        if ((editCompany.company_name === "") | (editCompany.company_registration_number === "")) {
            setEditComplete(false);
        } else if (editCompany.company_registration_number.length < 11) {
            setEditComplete(false);
        } else if (companyRegistrationNumberCheck === false) {
            setEditComplete(true);
        } else {
            setEditComplete(true);
        }
    }, [editCompany]);

    const InputsChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "company_registration_number") {
                setEditCompany((prev) => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/g, ""),
                }));
            } else {
                setEditCompany((prev) => ({
                    ...prev,
                    [name]: value,
                }));
            }
        },
        [editCompany]
    );

    const editCompleteOnClick = useCallback(async () => {
        try {
            const formData = new FormData();

            formData.append("company_name", editCompany.company_name);
            formData.append("company_registration_number", editCompany.company_registration_number);

            const companyRes = await UpdateCompanyInfo(formData, Token.data.access);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "기업정보 변경 완료",
                text: "기업정보 변경이 완료되었습니다.",
                afterFunction: () => {
                    setEditCompanyModalOpen(false);
                    window.location.reload();
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "기업정보 변경 오류",
                text: "기업정보 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    return (
        <Modal
            isOpen={editCompanyModalOpen}
            onRequestClose={() => {
                setEditCompanyModalOpen(false);
            }}
            style={customStyles}
            contentLabel="Alert Modal"
        >
            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                기업 정보 수정
            </CustomText>
            <EditMember>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        기업 명
                    </CustomText>
                    <InputBox>
                        {editCompany.company_name === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="company_name" value={editCompany.company_name} onChange={InputsChange} autoComplete="off" />
                    </InputBox>
                </div>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        사업자 등록번호
                    </CustomText>
                    <InputBox>
                        {(editCompany.company_registration_number === "") | (editCompany.company_registration_number.length < 11) | (companyRegistrationNumberCheck === false) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="company_registration_number" value={editCompany.company_registration_number} onChange={InputsChange} maxLength={12} autoComplete="off" />
                    </InputBox>
                </div>
            </EditMember>

            <BtnWrapper>
                <CustomBtn
                    bc={"#B2B1B1"}
                    onClick={() => {
                        setEditCompanyModalOpen(false);
                    }}
                >
                    <CustomText fs="0.9rem" cl={"#B2B1B1"}>
                        취소
                    </CustomText>
                </CustomBtn>
                <CustomBtn bc={companyRegistrationNumberCheck & editComplete ? "#E2FF9B" : "#B1B1B1"} onClick={editCompleteOnClick} disabled={companyRegistrationNumberCheck & editComplete ? false : true}>
                    <CustomText fs="0.9rem" cl={companyRegistrationNumberCheck & editComplete ? "#E2FF9B" : "#B1B1B1"}>
                        수정
                    </CustomText>
                </CustomBtn>
            </BtnWrapper>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default EditCompanyModal;
