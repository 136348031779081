import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as DownArrow } from "../../../assets/project/down-arrow.svg";

import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";
import { GetProjectDataSetList } from "../../../services/project-services";
import { updateSelectedDatasetAction } from "../../../store/reducer/SelectedDataset";
import AlertModal from "../../modal/AlertModal";

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 3px;
    padding: 0.6rem 1.2rem;
    border: none;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function DataSetDropDown(props) {
    const { Token, SelectedDataset } = useSelector((state) => state);
    const dispatch = useDispatch();
    const params = useParams();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: datasetList, isLoading: loading } = useQuery(
        ["project", "dataset", params.id],
        async (e) => {
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onSuccess: (res) => {
                if (_.isEmpty(SelectedDataset.data) || !res.data.some((dataset) => dataset.id === SelectedDataset.data.id)) {
                    dispatch(updateSelectedDatasetAction(res.data[0]));
                }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const branchItems = useMemo(
        () => (
            <StyledMenu>
                {datasetList?.data.map((dataset, index) => {
                    return (
                        <Menu.Item key={dataset.id} className="menu-item" onClick={() => dispatch(updateSelectedDatasetAction(dataset))}>
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {dataset.data_name}
                                </CustomText>
                                {index === 0 && (
                                    <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#fff">
                                        <CustomText fs="1rem" fw="400" cl="#fff" mg="0">
                                            대표
                                        </CustomText>
                                    </CircleText>
                                )}
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [datasetList]
    );

    return (
        <>
            <StyledDropDown overlay={branchItems} placement="bottom" trigger={["click"]}>
                <MenuItem>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                        {SelectedDataset.data?.is_representative && (
                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#fff">
                                <CustomText fs="1rem" fw="400" cl="#fff" mg="0">
                                    대표
                                </CustomText>
                            </CircleText>
                        )}
                        <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {SelectedDataset.data?.data_name}
                        </CustomText>
                    </div>
                    <DownArrow width="1.125rem" height="1.125rem" />
                </MenuItem>
            </StyledDropDown>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default DataSetDropDown;
