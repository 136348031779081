import React, { useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";

import { CustomText } from "../../../styles/theme/CustomStyle";
import { ReactComponent as UploadIcon } from "../../../assets/project/upload-icon.svg";
import { useDropzone } from "react-dropzone";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { UploadValidationData, GetValidationData } from "../../../services/validation-service";
import { useParams } from "react-router-dom";
import { DeleteValidationData } from "../../../services/validation-service";
import VideoDeleteWarningModal from "../../modal/VideoDeleteWarningModal";
import { updateSelectedVideoAction } from "../../../store/reducer/SelectedVideo";
import { deleteSelectedVideoAction } from "../../../store/reducer/SelectedVideo";
import { SetVideo } from "../../../services/train-services";
import AlertModal from "../../modal/AlertModal";
import * as LoadingData from "../../../assets/loading/loaindg-lottie.json";
import Lottie from "react-lottie";

const CustomBtn = styled.div`
    width: 50px;
    height: 26px;

    background: #707070;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ValidationInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
    margin-bottom: 4rem;
    .line {
        display: flex;
        justify-content: space-between;
    }
    .model-param {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
    }
    /* height: 2.5rem; */
    .algorithm {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        padding-top: 5px;
    }
    .train-time {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        width: fit-content;
        justify-content: space-between;
    }
`;

const UploadSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #21272e;
    width: 100%;
    /* height: 100%; */
    height: 18rem;
    /* padding: 78px; */
    cursor: pointer;
`;
const VideoWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 20px;
    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
`;
const VideoList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    box-shadow: inset 0px 2px 0px #575757;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
`;

function ValidationList(props) {
    const { Token, SelectedDataset } = useSelector((state) => state);

    const uploadValidationData = useMutation(UploadValidationData);
    const deleteValidationData = useMutation(DeleteValidationData);
    const setVideo = useMutation(SetVideo);
    const [validationList, setValidationList] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [media_pk, setMiedaPk] = useState("");
    const dispatch = useDispatch();
    const params = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const LotteLoadingnOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    useEffect(() => {
        getValidationData();
        dispatch(deleteSelectedVideoAction());
    }, [SelectedDataset]);

    const { refetch: getValidationData } = useQuery(
        ["project", "validation", params.id],
        async (e) => {
            const data = await GetValidationData(params.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onSuccess: (res) => {
                setValidationList(res.data);
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const setValidVideo = useCallback(async (video) => {
        props.setIsVideo(true);
        const formData = new FormData();
        props.setIsVideo(true);
        let parseData = video.file_path.split("/");
        let file_path = parseData[3] + "/" + parseData[4] + "/" + parseData[5] + "/" + video.file_name;

        formData.append("file_path", file_path);
        formData.append("gpu_server", 1);

        await setVideo.mutateAsync(
            { pk: video.id, formData: formData },
            {
                onSuccess: (res) => {
                    props.setIsVideo(false);
                    dispatch(updateSelectedVideoAction(video));
                    props.setIsMenu("type");
                },
                onError: (error) => {
                    props.setIsVideo(false);
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "검증 서버를 초기화 중입니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, []);

    const onDrop = useCallback(
        async (file) => {
            setUploadLoading(true);

            if (file.length > 0) {
                const formData = new FormData();

                for (let i = 0; i < file.length; i++) {
                    let blank_pattern = /[\s()]/g;

                    if (blank_pattern.test(file[i].path)) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "영상 이름 오류",
                            text: "영상 이름에 괄호와 공백을 제거해주세요.",
                            afterFunction: null,
                        });
                        setUploadLoading(false);
                        return;
                    }
                    formData.append("project", params.id);
                    formData.append("file_path", file[i]);
                }

                await uploadValidationData.mutateAsync(
                    { token: Token.data.access, formData: formData },
                    {
                        onSuccess: (res) => {
                            setAlertModalOpen({
                                open: true,
                                iconType: "success",
                                title: "업로드 완료",
                                text: "업로드가 완료되었습니다.",
                                afterFunction: null,
                            });
                            setUploadLoading(false);
                            getValidationData();
                        },
                        onError: (error) => {
                            setAlertModalOpen({
                                open: true,
                                iconType: "error",
                                title: "",
                                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                                afterFunction: null,
                            });
                        },
                    }
                );
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "비디오 파일 형식 오류",
                    text: "mp4 형식의 파일만 지원합니다.",
                    afterFunction: null,
                });
                setUploadLoading(false);
                return;
            }
        },
        [Token, SelectedDataset]
    );

    const DeleteVideo = useCallback(
        async (media_pk) => {
            const formData = new FormData();
            formData.append("project", params.id);
            formData.append("media_pk", media_pk);

            await deleteValidationData.mutateAsync(
                { token: Token.data.access, formData: formData },
                {
                    onSuccess: (res) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "success",
                            title: "비디오 삭제 완료",
                            text: "비디오 삭제가 완료되었습니다.",
                            afterFunction: null,
                        });
                        getValidationData();
                        setIsOpen(false);
                    },
                    onError: (error) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        },
        [Token, SelectedDataset]
    );

    const clickDelete = (id) => {
        setMiedaPk(id);
        setIsOpen(true);
    };

    const seletctedVideo = (data) => {
        setValidVideo(data);

        if (props.carouselRef.current !== null) {
            props.carouselRef.current.next();
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ accept: "video/mp4", onDrop });
    return (
        <>
            <ValidationInfoSection>
                <UploadSection {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                    {uploadLoading === false ? (
                        <>
                            <input {...getInputProps()} />
                            <UploadIcon width="2rem" height="2rem" style={{ marginBottom: "10px" }} />
                            <CustomText fs="1rem">검증 데이터 업로드</CustomText>
                            <CustomText fs="0.9rem" cl="#909090">
                                파일 선택 또는 <br /> Drag {"&"} Drop
                            </CustomText>
                        </>
                    ) : (
                        <CustomText fs="0.9rem" cl="#909090">
                            <Lottie options={LotteLoadingnOptions} width="10rem" height="10rem" />
                        </CustomText>
                    )}
                </UploadSection>
                <VideoWrap>
                    {validationList.map((data) => {
                        return (
                            <VideoList>
                                <CustomText fs="0.9rem" cl="#FFFFFF" onClick={() => seletctedVideo(data)} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all", cursor: "pointer" }}>
                                    {data.file_name}
                                </CustomText>
                                <CustomBtn id={data.media_pk} onClick={(e) => clickDelete(data.id)}>
                                    <CustomText fs="0.9rem" cl="#FFFFFF">
                                        삭제
                                    </CustomText>
                                </CustomBtn>
                            </VideoList>
                        );
                    })}
                </VideoWrap>
                <VideoDeleteWarningModal isOpen={isOpen} setIsOpen={setIsOpen} id={media_pk} DeleteVideo={DeleteVideo} />
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </ValidationInfoSection>
        </>
    );
}

export default ValidationList;
