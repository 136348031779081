import React, { useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { deleteUserInfoAction } from "../../store/reducer/UserInfo";
import { deleteTokenAction } from "../../store/reducer/Token";

import { Header } from "antd/lib/layout/layout";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { CustomText } from "../../styles/theme/CustomStyle";

const Wrap = styled(Header)`
    background-color: #161b22;
    height: 62px;
    padding: 16px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const DesktopContents = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;

    .menu-items {
        /* flex: 1; */
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 1024px) {
            /* width: 100%; */
        }

        div {
            display: flex;
            flex-direction: row;
            /* justify-content: space-between; */
            align-items: center;
            gap: 10px;
        }
    }
`;

const logoLinkStyle = { display: "flex", flexDirection: "column", alignItems: "center" };

function MyPageHeader(props) {
    const dispatch = useDispatch();

    const handleLogoutBtnClick = useCallback(() => {
        dispatch(deleteUserInfoAction());
        dispatch(deleteTokenAction());
    }, []);

    return (
        <Wrap>
            <DesktopContents>
                <Link to="/" style={logoLinkStyle}>
                    <Logo width="10rem" height="100%" />
                </Link>

                <Link to="/" onClick={handleLogoutBtnClick}>
                    <CustomText fs="1rem" mg="0px 0px 0px 40px">
                        로그아웃
                    </CustomText>
                </Link>
            </DesktopContents>
        </Wrap>
    );
}

export default MyPageHeader;
