export const initialState = {
    data: {},
};

export const updateSelectedTrainModelAction = (data) => {
    return {
        type: "UPDATE_SELECTED_TRAINMODEL",
        data,
    };
};

export const deleteSelectedTrainModelAction = () => {
    return {
        type: "DELETE_SELECTED_TRAINMODE",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_TRAINMODEL":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_SELECTED_TRAINMODE":
            return {
                ...state,
                data: {},
            };

        default:
            return state;
    }
};

export default reducer;
