import { Button } from "antd";
import React, { useCallback } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import Lottie from "react-lottie";

import * as successLottie from "../../assets/modal/success-lottie.json";
import * as errorLottie from "../../assets/modal/error-lottie.json";
import * as warningLottie from "../../assets/modal/warning-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 1,
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

// iconType => "success", "error", "warning"

// modalOpen {
//     open: false,
//     iconType: null,
//     title: null,
//     text: null,
//     afterFunction: null
// }

// text new line 적용시 \n 사용

function AlertModal({ alertModalOpen, setAlertModalOpen }) {
    const closeModal = useCallback(() => {
        if (alertModalOpen.afterFunction !== null) {
            alertModalOpen.afterFunction();
        }
        setAlertModalOpen({
            open: false,
            iconType: null,
            title: null,
            text: null,
            afterFunction: null,
        });
    }, [alertModalOpen]);

    return (
        <Modal isOpen={alertModalOpen.open} onRequestClose={closeModal} style={customStyles} contentLabel="Alert Modal">
            {alertModalOpen.iconType === "success" && (
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: successLottie,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    width="8rem"
                    height="8rem"
                    style={{
                        marginBottom: "0.8rem",
                    }}
                />
            )}

            {alertModalOpen.iconType === "warning" && (
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: warningLottie,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    width="4rem"
                    height="4rem"
                    style={{
                        marginBottom: "0.8rem",
                    }}
                />
            )}

            {alertModalOpen.iconType === "error" && (
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: errorLottie,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    width="4rem"
                    height="4rem"
                    style={{
                        marginBottom: "0.8rem",
                    }}
                />
            )}

            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                {alertModalOpen.title}
            </CustomText>
            <CustomText fs="0.875rem" cl="#d4d4d4" ta="center" style={{ whiteSpace: "pre-line" }}>
                {alertModalOpen.text}
            </CustomText>

            <CustomBtn bc={"#E2FF9B"} onClick={closeModal}>
                <CustomText fs="0.9rem" cl={"#E2FF9B"}>
                    확인
                </CustomText>
            </CustomBtn>
        </Modal>
    );
}

export default AlertModal;
