export const initialState = {
    data: null,
};

export const updateImageCropperAction = (data) => {
    return {
        type: "UPDATE_IMAGE_CROPPER",
        data,
    };
};

export const deleteImageCropperAction = () => {
    return {
        type: "DELETE_IMAGE_CROPPER",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_IMAGE_CROPPER":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_IMAGE_CROPPER":
            return {
                ...state,
                data: null,
            };

        default:
            return state;
    }
};

export default reducer;
