import React from "react";
import styled from "styled-components";
import HomeLayout from "../components/layout/HomeLayout";
import { CustomText } from "../styles/theme/CustomStyle";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
    background-color: #161b22;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.div`
    margin-top: 1.3rem;
    width: 90%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 10rem;
`;

const PartWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .bullet {
        font-size: 0.8rem;
        font-family: "Pretendard";
        font-weight: 400;
        margin-top: 2px;
    }

    .secondWrap {
        margin-left: 20px;
    }

    .thirdWrap {
        margin-left: 40px;
    }

    div {
        display: flex;
        gap: 5px;
    }
`;

function PrivacyPolicy(props) {
    return (
        <HomeLayout>
            <Wrap>
                <Content>
                    <CustomText fs="2rem" fw="600" wd="100%" style={{ borderBottom: "2px solid #707070" }}>
                        개인정보 처리방침
                    </CustomText>
                    <br />
                    <div style={{ color: "white" }}>
                        <CustomText fs="1.2rem" fw={500}>
                            NewLearn에서 처리하는 모든 개인정보는 관련법령에 근거하거나 정보주체의 동의에 의하여 수집·보유 및 처리되고 있습니다.
                            <br />
                            <br />
                            <br />
                        </CustomText>
                        <PartWrap>
                            <CustomText className="title">제1조 (개인정보의 처리목적)</CustomText>
                            <div>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>1.</CustomText>
                                <CustomText>홈페이지 회원 가입 및 관리</CustomText>
                            </div>
                            <CustomText className="thirdWrap">회원 가입의사 확인, 회원제 서비스 제공에 따른 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</CustomText>
                            <div className="secondWrap">
                                <CustomText>2.</CustomText>
                                <CustomText>서비스 제공</CustomText>
                            </div>
                            <CustomText className="thirdWrap"> 자료 등 서비스 제공, 서비스 성능 향상과 관련한 목적으로 개인정보를 처리합니다. NewLearn은 서비스를 제공함에 따라 발생하는 로그정보를 해지신청 시점까지 보관할 수 있고, 운영기관은 그와 같이 보관하고 있는 사용자 로그정보를 NewLearn 서비스의 성능 향상을 위한 연구 용도를 위해 활용할 수 있습니다</CustomText>
                            <div className="secondWrap">
                                <CustomText>3.</CustomText>
                                <CustomText>민원사무 처리</CustomText>
                            </div>
                            <CustomText className="thirdWrap">민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</CustomText>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제2조 (개인정보의 처리 및 보유기간)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">②</CustomText>
                                <CustomText>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>1.</CustomText>
                                <CustomText>홈페이지 회원 가입 및 관리 : 회원가입기간(탈퇴후 즉시 파기)</CustomText>
                            </div>
                            <CustomText className="thirdWrap">다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지</CustomText>
                            <div className="thirdWrap">
                                <CustomText>1)</CustomText>
                                <CustomText>관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제3조 (정보주체의 권리의무 및 행사방법)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>정보주체는 NewLearn에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</CustomText>
                            </div>

                            <div className="secondWrap">
                                <CustomText>1.</CustomText>
                                <CustomText>개인정보 열람요구</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>2.</CustomText>
                                <CustomText>오류 등이 있을 경우 정정 요구</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>3.</CustomText>
                                <CustomText>삭제요구</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>4.</CustomText>
                                <CustomText>처리정지 요구</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">②</CustomText>
                                <CustomText>제1항에 따른 권리 행사는 NewLearn에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 NewLearn은 이에 대해 지체없이 조치하겠습니다.</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">③</CustomText>
                                <CustomText>정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 NewLearn은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">④</CustomText>
                                <CustomText>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">⑤</CustomText>
                                <CustomText>정보주체는 개인정보 보호법 등 관계법령을 위반하여 NewLearn이 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제4조 (처리하는 개인정보 항목)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 다음의 개인정보 항목을 처리하고 있습니다.</CustomText>
                            </div>

                            <CustomText className="secondWrap">1. 홈페이지 회원 가입 및 관리, 서비스 제공 등</CustomText>
                            <div className="thirdWrap">
                                <CustomText>·</CustomText>
                                <CustomText>필수항목 : 성명, 아이디, 이메일주소, 비밀번호, 휴대폰 전화번호</CustomText>
                            </div>
                            <div className="thirdWrap">
                                <CustomText>·</CustomText>
                                <CustomText>선택항목 : 소속</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>2.</CustomText>
                                <CustomText>인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. 회원이 이를 거부할 경우 서비스 이용에 불편이 있거나 서비스 제공에 어려움이 있을 수 있습니다.</CustomText>
                            </div>
                            <div className="thirdWrap">
                                <CustomText>·</CustomText>
                                <CustomText>IP주소, 서비스 이용기록, 방문기록, 쿠키</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제5조 (개인정보의 파기)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</CustomText>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">②</CustomText>
                                <CustomText>개인정보 파기의 절차 및 방법은 다음과 같습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>1.</CustomText>
                                <CustomText>파기절차</CustomText>
                            </div>
                            <div className="thirdWrap">
                                <CustomText>·</CustomText>
                                <CustomText>NewLearn은 파기하여야 하는 개인정보 DB레코드에 대해 자동적으로 파기(삭제)됩니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>2.</CustomText>
                                <CustomText>파기방법</CustomText>
                            </div>
                            <div className="thirdWrap">
                                <CustomText>·</CustomText>
                                <CustomText>NewLearn은 개인정보는 문서형태로 보관되지 않으며, DB상에서만 관리됩니다. 파기 사유(탈퇴)가 발생한 개인정보 DB레코드를 자동적으로 파기(삭제)합니다.</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제6조 (개인정보의 안전성 확보조치)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>1.</CustomText>
                                <CustomText>관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육, 정기적인 자체 감사 실시 등</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>2.</CustomText>
                                <CustomText>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등 해킹 등에 대비한 기술적 대책</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>3.</CustomText>
                                <CustomText>물리적 조치 : 전산실, 자료보관실 등의 접근통제</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제7조 (개인정보 보호책임자)</CustomText>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">①</CustomText>
                                <CustomText>NewLearn은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <CustomText>▶</CustomText>
                                <CustomText>개인정보 보호책임자</CustomText>
                            </div>
                            <CustomText className="thirdWrap">성명 : 문민종</CustomText>
                            <CustomText className="thirdWrap">직책 : 플랫폼개발팀장</CustomText>
                            <CustomText className="thirdWrap">연락처 : 063-211-0814, newlearn@viasoft.ai</CustomText>

                            <div style={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                                <CustomText className="bullet">②</CustomText>
                                <CustomText>정보주체는 NewLearn의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. NewLearn은 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</CustomText>
                            </div>
                        </PartWrap>
                    </div>
                </Content>
            </Wrap>
        </HomeLayout>
    );
}

export default PrivacyPolicy;
