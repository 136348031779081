import React from "react";
import styled, { css } from "styled-components";
import { Modal, Button } from "antd";
import { ReactComponent as LabelIcon } from "../../assets/modal/label-modal-icon.svg";

import { CustomText } from "../../styles/theme/CustomStyle";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

const CustomModal = styled(Modal)`
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #21272e;
        border: 1px solid #707070;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
    }

    .ant-modal-body {
        width: 100%;
    }
    width: 70%;
    @media screen and (max-width: 1024px) {
        width: 80%;
    }

    .complete {
        width: 60%;
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        :hover {
            background-color: rgba(0, 0, 0, 0.3);
            transition: background-color 300ms;
        }
    }
`;

const InputBox = styled.div`
    background-color: #21272e;
    width: 78%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 70%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }

    border-bottom: 1px dashed #b1b1b1;
`;

const CustomButton = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${(props) => props.bc} !important;
    padding: 10px 20px 10px 20px;
    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

function VideoDeleteWarningModal({ isOpen, setIsOpen, id, DeleteVideo }) {
    return (
        <>
            <CustomModal visible={isOpen} onCancel={() => setIsOpen(false)} closeIcon={<CloseOutlined style={{ color: "#fff" }} />}>
                <div style={{ color: "#fff", textAlign: "center", marginBottom: "15px" }}>
                    <LabelIcon />
                </div>
                <CustomText fs="1.1rem" wd="100%" fw="600" ta="center" mg="0 0 5px 0">
                    검증데이터 삭제
                </CustomText>
                <CustomText fs="0.9rem" wd="100%" fw="400" ta="center" cl="#D4D4D4" mg="0 0 5px 0">
                    삭제하면 되돌릴수 없습니다.
                    <br />
                    신중히 선택해주세요
                </CustomText>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <CustomButton bc="#B1B1B1" className="complete" onClick={() => DeleteVideo(id)}>
                        <CustomText fs="0.9rem" fw="400" wd="100%" ta="center" cl="#B1B1B1">
                            삭제
                        </CustomText>
                    </CustomButton>
                </div>
            </CustomModal>
        </>
    );
}

export default VideoDeleteWarningModal;
