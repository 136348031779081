import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { UpdateUserInfo, UserEmailDupCheck } from "../../services/account-services";

import { useDispatch, useSelector } from "react-redux";
import { updateUserInfoAction } from "../../store/reducer/UserInfo";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileEdit from "../../components/mypage/ProfileEdit";
import AlertModal from "../../components/modal/AlertModal";

const Wrap = styled.div`
    flex: 1;
    min-height: 74.75vh;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 6rem;

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        width: 30rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 60.8vh;
    }
`;

const MiddleFirstSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 1.5rem 2rem;
    background-color: #21272e;
    display: flex;

    @media screen and (max-width: 1024px) {
        padding: 0.8rem 2rem;
    }

    .rowDiv {
        display: flex;
    }
`;

const MiddleSecondSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 2.2rem 2rem;
    background-color: #21272e;

    @media screen and (max-width: 1024px) {
        padding: 2rem 2rem;
    }

    .infoRowTop {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .infoRowBottom {
        display: flex;
        margin-top: 3rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            margin-top: 1.5rem;
            gap: 1.5rem;
        }
    }

    .info {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
    }
`;

const InputBox = styled.div`
    background-color: #21272e;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 30px;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.25rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
`;

const CompleteButton = styled(Button)`
    background-color: #161b22 !important;
    margin-top: 1.2rem;
    border-radius: 5px;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.5rem;
    border-color: #70726e !important;
`;

function UserInfoEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { UserInfo, Token } = useSelector((state) => state);
    const [editComplete, setEditComplete] = useState(true);
    const [editUserInfo, setEditUserInfo] = useState({ name: UserInfo.data.name, phone_number: UserInfo.data.phone_number, email: UserInfo.data.email });
    const [emailCheck, setEmailCheck] = useState(false);
    const [nameErrormessage, setNameErrormessage] = useState("");
    const [phoneErrormessage, setPhoneErrormessage] = useState("");
    const [emailErrormessage, setEmailErrormessage] = useState("");
    const [editCheck, setEditCheck] = useState({ name: false, phone: false, email: false });
    let emailPattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const InputsChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "name") {
                setNameErrormessage("");
                setEditUserInfo((prev) => ({
                    ...prev,
                    [name]: value,
                }));
                setEditCheck((prev) => ({
                    ...prev,
                    ["name"]: false,
                }));
            } else if (name === "phone_number") {
                setPhoneErrormessage("");
                setEditUserInfo((prev) => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/g, ""),
                }));
                setEditCheck((prev) => ({
                    ...prev,
                    ["phone"]: false,
                }));
            } else {
                setEmailErrormessage("");
                setEditUserInfo((prev) => ({
                    ...prev,
                    [name]: value,
                }));
                setEditCheck((prev) => ({
                    ...prev,
                    ["email"]: false,
                }));
            }
        },
        [editUserInfo]
    );

    useEffect(() => {
        if (editUserInfo.phone_number.length === 10) {
            setEditUserInfo((prev) => ({ ...prev, ["phone_number"]: editUserInfo.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") }));
        }
        if (editUserInfo.phone_number.length === 11) {
            setEditUserInfo((prev) => ({ ...prev, ["phone_number"]: editUserInfo.phone_number.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") }));
        }

        if (UserInfo.data.email === editUserInfo.email) {
            setEmailCheck(true);
            setEmailErrormessage("");
        } else {
            emailDupCheck();
        }

        if ((editCheck.name === false) | (editCheck.phone === false) | (editCheck.email === false)) {
            if (editUserInfo.name === "") {
                setNameErrormessage("이름을 입력해주세요.");

                setEditComplete(false);
            } else {
                setEditCheck((prev) => ({
                    ...prev,
                    ["name"]: true,
                }));
            }

            if (editUserInfo.phone_number === "") {
                setPhoneErrormessage("연락처를 입력해주세요.");
                setEditComplete(false);
            } else if (editUserInfo.phone_number.length < 10) {
                setPhoneErrormessage("연락처는 10자리 이상 입력해주세요.");
                setEditComplete(false);
            } else {
                setEditCheck((prev) => ({
                    ...prev,
                    ["phone"]: true,
                }));
            }

            if (editUserInfo.email === "") {
                setEmailErrormessage("이메일을 입력해주세요.");
                setEditComplete(false);
            } else if (emailPattern.test(editUserInfo.email) === false) {
                setEmailErrormessage("이메일 형식에 맞게 입력해주세요.");
                setEditComplete(false);
            } else {
                setEditCheck((prev) => ({
                    ...prev,
                    ["email"]: true,
                }));
            }
        }
    }, [editUserInfo]);

    useEffect(() => {
        if ((editCheck.name === true) & (editCheck.phone === true) & (editCheck.email === true)) {
            setEditComplete(true);
        }
    }, [editCheck]);

    const emailDupCheck = useCallback(async () => {
        try {
            await UserEmailDupCheck(editUserInfo.email);
            setEmailCheck(true);

            if (emailPattern.test(editUserInfo.email) === true) {
                setEmailErrormessage("");
            }
        } catch (err) {
            if (editUserInfo.email === "") {
                setEmailErrormessage("이메일을 입력해주세요.");
            } else {
                setEmailErrormessage("중복된 이메일이 있습니다.");
            }
            setEmailCheck(false);
            // setEditComplete(false);
        }
    });

    const editCompleteOnClick = useCallback(async () => {
        try {
            const userRes = await UpdateUserInfo(UserInfo.data.id, { name: editUserInfo.name, phone_number: editUserInfo.phone_number, email: editUserInfo.email }, Token.data.access);
            dispatch(updateUserInfoAction(userRes.data));
            // goMyPageMain();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회원정보 변경 완료",
                text: "회원정보 변경이 완료되었습니다.",
                afterFunction: () => {
                    window.location.replace(`/mypage?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`);
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회원정보 변경 오류",
                text: "회원정보 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const goMyPageMain = () => {
        navigate({ search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=info` });
    };

    return (
        <Wrap>
            <div className="bottomMargin">
                <MiddleFirstSection>
                    <ProfileEdit />
                </MiddleFirstSection>
                <MiddleSecondSection>
                    <div className="infoRowTop">
                        <div>
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    이름
                                </CustomText>
                                <InputBox>
                                    {editUserInfo.name === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                    <input name="name" value={editUserInfo.name} onChange={InputsChange} autoComplete="off" />
                                </InputBox>
                            </div>
                            {nameErrormessage !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {nameErrormessage}
                                </CustomText>
                            )}
                        </div>
                        <div>
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    연락처
                                </CustomText>
                                <InputBox>
                                    {(editUserInfo.phone_number === "") | (editUserInfo.phone_number.length < 10) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                    <input name="phone_number" value={editUserInfo.phone_number} onChange={InputsChange} maxLength="13" autoComplete="off" />
                                </InputBox>
                            </div>
                            {phoneErrormessage !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {phoneErrormessage}
                                </CustomText>
                            )}
                        </div>
                        <div>
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    이메일
                                </CustomText>
                                <InputBox>
                                    {(editUserInfo.email === "") | (emailPattern.test(editUserInfo.email) === false) | (emailCheck === false) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                    <input name="email" value={editUserInfo.email} onChange={InputsChange} autoComplete="off" />
                                </InputBox>
                            </div>
                            {emailErrormessage !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {emailErrormessage}
                                </CustomText>
                            )}
                        </div>
                    </div>
                </MiddleSecondSection>
            </div>
            <CompleteButton onClick={editCompleteOnClick} disabled={emailCheck | editComplete ? false : true}>
                <CustomText fs="1.25rem" cl={editComplete ? "#E2FF9B" : "#B1B1B1"} className="btn-text">
                    변경완료
                </CustomText>
                <ArrowRightCircle stroke={editComplete ? "#E2FF9B" : "#B1B1B1"} width="1.2rem" height="1.2rem" />
            </CompleteButton>
            <CustomText cl="#9E9E9E" mg="2rem 0px 0px 0px" fs="1.125rem" style={{ cursor: "pointer" }} onClick={goMyPageMain}>
                변경 취소
            </CustomText>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default UserInfoEdit;
