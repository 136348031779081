import axios from "axios";

export const GetSideProjectList = (token, userPk, searchStr, page) => {
    // return axios.get(`http://localhost:8000/api/project/side/list?userPk=${userPk}&searchStr=${searchStr}&page=${page}`, {
    return axios.get(
        process.env.REACT_APP_END_POINT +
            `/api/project/side/list?userPk=${userPk}&searchStr=${searchStr}&page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetProjectList = (token, userPk, searchStr, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT +
            `/api/project/list?userPk=${userPk}&searchStr=${searchStr}&page=${page}`
        // , {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const CreateProjectAPI = ({token, formData}) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/project/list`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetProjectExpanationMember = (token, projectId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/${projectId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const CreateProjectLike = ({token, projectId}) => {
    return axios.post(
        process.env.REACT_APP_END_POINT + `/api/project/like/${projectId}`,
        {},
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};
export const DeleteProjectLike = ({token, projectId}) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/project/like/${projectId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateProject = (token, projectId, formData) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/project/${projectId}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteProject = (token, projectId) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/project/${projectId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetProjectDataSetList = (token, projectId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/dataset/${projectId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const ProjectFork = ({token, projectId}) => {
    return axios.post(
        process.env.REACT_APP_END_POINT + `/api/project/fork/${projectId}`,
        {},
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const CreateDataset = ({token, formData, projectId}) => {
    return axios.post(
        process.env.REACT_APP_END_POINT + `/api/project/dataset/${projectId}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const GetDataSetInfo = (token, datasetId) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/project/dataset/info/${datasetId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateDataSetInfo = (token, formData) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/project/dataset/info`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteDataSetInfo = (token, datasetId) => {
    return axios.delete(
        process.env.REACT_APP_END_POINT + `/api/project/dataset/info/${datasetId}`,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const PostMedia = ({token, formData}) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/storage/post`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetAnnotationImageList = (token, datasetId, page, dataAlignMethod, label = null) => {
    return axios.get(
        process.env.REACT_APP_END_POINT +
            `/api/project/annotation/list/${datasetId}?page=${page}&order=${dataAlignMethod}&label=${label}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetPreprocessAnnotationImageList = (token, datasetId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/preprocess/images/${datasetId}`,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const InviteMemberInProject = ({token, formData}) => {
    return axios.post(
        process.env.REACT_APP_END_POINT + `/api/project/invite/collaborator/mail/send`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const UpdateMember = (token, projectId, formData) => {
    return axios.put(
        process.env.REACT_APP_END_POINT + `/api/project/member/${projectId}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const MergeDataset = ({token, formData, datasetId}) => {
    return axios.post(
        process.env.REACT_APP_END_POINT + `/api/project/annotation/merge/${datasetId}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const GetDataCenterProjectFork = (token, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/datacenter_project_fork?page=${page}`,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};
