import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import styled, { css } from "styled-components";
import { Form, Button, Input } from "antd";

import { ReactComponent as ArrowRightCircle } from "../../../assets/login/arrow-right-circle.svg";
import { ReactComponent as ArrowRight } from "../../../assets/project/preprocess/right-arrow.svg";
import { ReactComponent as Arrowleft } from "../../../assets/project/preprocess/left-arrow.svg";
import { ReactComponent as RotateIcon } from "../../../assets/project/preprocess/rotate-icon.svg";
import { ReactComponent as GrayScaleIcon } from "../../../assets/project/preprocess/grayscale-icon.svg";
import { ReactComponent as ScaleUpIcon } from "../../../assets/project/preprocess/scale-up-icon.svg";
import { ReactComponent as ScaleDownIcon } from "../../../assets/project/preprocess/scale-down-icon.svg";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { GetPreprocessAnnotationImageList } from "../../../services/project-services";
import { updateCropIndexAction, loadCropIndexAction } from "../../../store/reducer/CropIndex";
import { PostMedia } from "../../../services/project-services";
import ConfirmModal from "../../modal/ConfirmModal";
import AlertModal from "../../modal/AlertModal";
import { useNavigate } from "react-router-dom";

const CropWrap = styled.div`
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .crop-controller {
        width: 100%;
        flex: 1;
        /* padding: 15px; */
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .drag-mode {
            width: 100%;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 1rem 10px;
                cursor: pointer;

                :nth-child(1) {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    :hover {
                        background-color: rgba(0, 0, 0, 0.3) !important;
                        transition: 300ms;
                    }
                }

                :nth-child(2) {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    :hover {
                        background-color: rgba(0, 0, 0, 0.3) !important;
                        transition: 300ms;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const ControllWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.8rem;
`;

const ControllItem = styled.div`
    height: 100%;
    background-color: #303a45;
    border-radius: 5px;
    padding: 30px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.grayscale &&
        css`
            background-color: #000;
        `}

    :hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.3);
        transition: background-color 300ms;
    }
`;

const ImageIndexWrapper = styled.div`
    margin-top: 1.4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .annotation-index-form {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        input {
            flex: 1;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            border-bottom: 1px solid #d4d4d4;
            color: #fff;
            font-size: 0.9rem;
            text-align: center;
        }
        p {
            flex: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`;

const CropBtn = styled(Button)`
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: #e2ff9b !important;
    margin-top: 2.6rem;
    padding: 1rem 5px;
    height: auto;
    gap: 5px;

    .ant-btn-loading-icon {
        color: #e2ff9b;
    }
`;

const StatusWrapper = styled.div`
    border: 1px solid #575757;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    margin-top: 2.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.7rem;
`;

function MobileCropController(props) {
    const { Token, ImageCropper, CropInfo, CropIndex, SelectedDataset } = useSelector((state) => state);

    const mediaPost = useMutation(PostMedia);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputIndex, setInputIndex] = useState(1);
    const [imageFilter, setImageFilter] = useState({
        grayscale: false,
    });
    const [dragMode, setDragMode] = useState("crop");
    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const rotateBtnClick = useCallback(() => {
        ImageCropper.data.rotate(45);
    }, [ImageCropper]);

    const imageCrop = useCallback(async () => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        ImageCropper.data.getCroppedCanvas().toBlob(async (blob) => {
            const preFileName = CropIndex.data.list[CropIndex.data.crnt].media_path.split("/").pop().split(".");
            const fileName = preFileName[0] + "_crop." + preFileName.pop();

            const formData = new FormData();

            formData.append("data_set", SelectedDataset.data.id);
            formData.append("media_type", "image");
            formData.append("file_name", fileName);
            formData.append("file_path", blob, fileName);

            if (imageFilter.grayscale) {
                formData.append("filter", "grayscale");
            }

            await mediaPost.mutateAsync(
                { token: Token.data.access, formData: formData },
                {
                    onSuccess: (res) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "success",
                            title: "이미지 저장 완료",
                            text: "전처리 이미지 저장이 완료되었습니다.",
                            afterFunction: () => {
                                setConfirmModalOpen({
                                    open: false,
                                    title: null,
                                    text: null,
                                    afterFunction: null,
                                });
                                refetch();
                            },
                        });
                    },
                    onError: (error) => {
                        if (error.response.status === 403) {
                            setAlertModalOpen({
                                open: true,
                                iconType: "error",
                                title: "회원 권한 오류",
                                text: "데이터 업로드 권한이 없습니다.",
                                afterFunction: null,
                            });
                        } else {
                            setAlertModalOpen({
                                open: true,
                                iconType: "error",
                                title: "이미지 저장 오류",
                                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                                afterFunction: null,
                            });
                        }
                    },
                }
            );
            setConfirmModalOpen((prev) => ({
                ...prev,
                loading: false,
            }));
        });
    }, [ImageCropper, CropIndex, SelectedDataset, Token, imageFilter]);

    const cropBtnClick = useCallback(() => {
        setConfirmModalOpen({
            open: true,
            title: "이미지 전처리",
            text: "선택된 영역을 저장하시겠습니까?",
            afterFunction: () => {
                imageCrop();
            },
        });
    }, [ImageCropper, CropIndex, SelectedDataset, Token, imageFilter]);

    // const cropBtnClick = useCallback(
    //     async (e) => {
    //         ImageCropper.data.getCroppedCanvas().toBlob(async (blob) => {
    //             if (!window.confirm("선택된 영역을 저장하시겠습니까?")) {
    //                 return;
    //             }

    //             const preFileName = CropIndex.data.list[CropIndex.data.crnt].media_path.split("/").pop().split(".");
    //             const fileName = preFileName[0] + "_crop." + preFileName.pop();

    //             const formData = new FormData();

    //             formData.append("data_set", SelectedDataset.data.id);
    //             formData.append("media_type", "image");
    //             formData.append("file_name", fileName);
    //             formData.append("file_path", blob, fileName);

    //             if (imageFilter.grayscale) {
    //                 formData.append("filter", "grayscale");
    //             }

    //             await mediaPost.mutateAsync(
    //                 { token: Token.data.access, formData: formData },
    //                 {
    //                     onSuccess: (res) => {
    //                         setAlertModalOpen({
    //                             open: true,
    //                             iconType: "success",
    //                             title: "이미지 저장 완료",
    //                             text: "전처리 이미지 저장이 완료되었습니다.",
    //                             afterFunction: () => {
    //                                 setConfirmModalOpen({
    //                                     open: false,
    //                                     title: null,
    //                                     text: null,
    //                                     afterFunction: null,
    //                                 });
    //                                 refetch();
    //                             },
    //                         });
    //                     },
    //                     onError: (error) => {
    //                         if (error.response.status === 403) {
    //                             setAlertModalOpen({
    //                                 open: true,
    //                                 iconType: "error",
    //                                 title: "회원 권한 오류",
    //                                 text: "데이터 업로드 권한이 없습니다.",
    //                                 afterFunction: null,
    //                             });
    //                         } else {
    //                             setAlertModalOpen({
    //                                 open: true,
    //                                 iconType: "error",
    //                                 title: "이미지 저장 오류",
    //                                 text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
    //                                 afterFunction: null,
    //                             });
    //                         }
    //                     },
    //                 }
    //             );
    //         });
    //     },
    //     [ImageCropper, CropIndex, SelectedDataset, Token, imageFilter]
    // );

    const { refetch } = useQuery(
        ["project", "preprocess", "images", SelectedDataset.data.id],
        async (e) => {
            const data = await GetPreprocessAnnotationImageList(Token.data.access, SelectedDataset.data.id);
            return data;
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                dispatch(
                    loadCropIndexAction({
                        crnt: 1,
                        max: res.data.count,
                        list: res.data.data,
                    })
                );
                setInputIndex(1);
                setImageFilter({
                    grayscale: false,
                });
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이미지 불러오기 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: () => {
                        navigate(-1);
                    },
                });
            },
        }
    );

    const scaleBtnClick = useCallback(
        (e, method) => {
            const scaleX = ImageCropper.data.getData().scaleX;
            const scaleY = ImageCropper.data.getData().scaleY;
            if (method === "up") {
                ImageCropper.data.scale(scaleX + 0.2, scaleY + 0.2);
            } else {
                ImageCropper.data.scale(scaleX - 0.2, scaleY - 0.2);
            }
        },
        [ImageCropper]
    );

    const setFilter = useCallback(() => {
        if (imageFilter.grayscale) {
            document.getElementById("cropImage").style.filter = "none";
            document.getElementsByClassName("cropper-crop-box")[0].children[0].style.filter = "none";
            document.getElementsByClassName("cropper-canvas")[0].style.filter = "none";
            setImageFilter({ grayscale: false });
        } else {
            document.getElementById("cropImage").style.filter = "grayscale()";
            document.getElementsByClassName("cropper-crop-box")[0].children[0].style.filter = "grayscale()";
            document.getElementsByClassName("cropper-canvas")[0].style.filter = "grayscale()";
            setImageFilter({ grayscale: true });
        }
    }, [imageFilter]);

    const handlePreIndex = useCallback(() => {
        // if (isLoading) {
        //     return;
        // }
        if (CropIndex.data.crnt - 1 > 0) {
            dispatch(updateCropIndexAction(CropIndex.data.crnt - 1));
            setInputIndex(CropIndex.data.crnt - 1);
            setImageFilter({ grayscale: false });
        }
    }, [CropIndex]);

    const handleNextIndex = useCallback(() => {
        // if (isLoading) {
        //     return;
        // }

        if (CropIndex.data.crnt + 1 <= CropIndex.data.max) {
            dispatch(updateCropIndexAction(CropIndex.data.crnt + 1));
            setInputIndex(CropIndex.data.crnt + 1);
            setImageFilter({ grayscale: false });
        }
    }, [CropIndex]);

    const checkKey = useCallback(
        (e) => {
            e = e || window.event;

            // if (isLoading) {
            //     return;
            // }

            if (e.keyCode === 37) {
                // 왼쪽 방향키
                handlePreIndex();
            } else if (e.keyCode === 39) {
                // 오른쪽 방향키
                handleNextIndex();
            } else if (e.keyCode === 77) {
                // m key
                setDragMode(dragMode !== "move" ? "move" : "crop");
                ImageCropper.data.setDragMode(dragMode !== "move" ? "move" : "crop");
            }
        },
        [dragMode, ImageCropper]
    );

    const changeCurrentIndex = useCallback(() => {
        if (inputIndex > CropIndex.data.max || inputIndex < 0) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "이미지 범위 오류",
                text: "업로드 된 이미지 수의 범위 내에서 입력해주세요.",
                afterFunction: () => {
                    setInputIndex(parseInt(CropIndex.data.crnt));
                },
            });
        } else {
            dispatch(updateCropIndexAction(inputIndex));
            setImageFilter({ grayscale: false });
        }
    }, [CropIndex, inputIndex]);

    if (window.innerWidth <= 1024) {
        document.onkeydown = checkKey;
    }

    return (
        <CropWrap>
            <div className="crop-controller">
                <div className="drag-mode">
                    <div
                        onClick={() => {
                            setDragMode("crop");
                            ImageCropper.data.setDragMode("crop");
                        }}
                        style={{ backgroundColor: dragMode === "crop" ? "#000000" : "#303A45" }}
                    >
                        {dragMode === "crop" && <ArrowRightCircle stroke="#fff" width="1.3rem" height="1.3rem" style={{ marginRight: 10 }} />}
                        <CustomText ta="center" fs="1rem" cl={dragMode === "crop" ? "#fff" : "#d4d4d4"}>
                            영역선택
                        </CustomText>
                    </div>
                    <div
                        onClick={() => {
                            setDragMode("move");
                            ImageCropper.data.setDragMode("move");
                        }}
                        style={{ backgroundColor: dragMode === "move" ? "#000000" : "#303A45" }}
                    >
                        {dragMode === "move" && <ArrowRightCircle stroke="#fff" width="1.3rem" height="1.3rem" style={{ marginRight: 10 }} />}
                        <CustomText ta="center" fs="1rem" cl={dragMode === "move" ? "#fff" : "#d4d4d4"}>
                            이미지이동
                        </CustomText>
                    </div>
                </div>
                <ControllWrapper>
                    <ControllItem onClick={rotateBtnClick}>
                        <RotateIcon width="100%" />
                        <CustomText fs="0.8rem" cl="#9c9c9c" mg="10px 0px 0px 0px">
                            회전
                        </CustomText>
                    </ControllItem>
                    <ControllItem onClick={(e) => scaleBtnClick(e, "down")}>
                        <ScaleDownIcon width="100%" />
                        <CustomText fs="0.8rem" cl="#9c9c9c" mg="10px 0px 0px 0px">
                            축소
                        </CustomText>
                    </ControllItem>
                    <ControllItem onClick={(e) => scaleBtnClick(e, "up")}>
                        <ScaleUpIcon width="100%" />
                        <CustomText fs="0.8rem" cl="#9c9c9c" mg="10px 0px 0px 0px">
                            확대
                        </CustomText>
                    </ControllItem>
                    <ControllItem onClick={setFilter} grayscale={imageFilter.grayscale}>
                        <GrayScaleIcon width="100%" />
                        <CustomText fs="0.8rem" cl="#9c9c9c" mg="10px 0px 0px 0px">
                            흑백
                        </CustomText>
                    </ControllItem>
                </ControllWrapper>

                <ImageIndexWrapper>
                    <Arrowleft style={{ cursor: "pointer", color: "#FFFFFF", marginRight: 10 }} onClick={handlePreIndex} />
                    <Form className="annotation-index-form" onFinish={changeCurrentIndex}>
                        <Input type="text" value={inputIndex} onChange={(e) => setInputIndex(e.target.value.replace(/ /g, ""))} />
                        <CustomText fs="1rem" cl="#fff" ta="center" wd="100%">
                            / {CropIndex.data.max}
                        </CustomText>
                    </Form>
                    <ArrowRight style={{ cursor: "pointer", color: "#FFFFFF", marginLeft: 10 }} onClick={handleNextIndex} />
                </ImageIndexWrapper>

                <CropBtn onClick={cropBtnClick} loading={mediaPost.isLoading}>
                    {!mediaPost.isLoading && (
                        <CustomText fs="1rem" fw="500" cl="#E2FF9B">
                            이미지 저장
                        </CustomText>
                    )}
                </CropBtn>
                <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </div>

            <StatusWrapper>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    회전각도: {CropInfo.data.rotateDegree}°
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    crop영역 가로길이: {CropInfo.data.cropWidth.toFixed(2)} px
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    crop영역 세로길이: {CropInfo.data.cropHeight.toFixed(2)} px
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    x좌표: {CropInfo.data.xPosition.toFixed(2)} px
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    y 좌표: {CropInfo.data.xPosition.toFixed(2)} px
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    scale X: {CropInfo.data.scaleX.toFixed(2)} px
                </CustomText>
                <CustomText fs="1rem" fw="400" cl="#9c9c9c">
                    scale Y: {CropInfo.data.scaleY.toFixed(2)} px
                </CustomText>
            </StatusWrapper>
        </CropWrap>
    );
}

export default MobileCropController;
