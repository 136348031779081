import React, { useCallback, useState } from "react";
import { CustomText } from "../../styles/theme/CustomStyle";

import SSDImage from "../../assets/main/ssd.jpg";
import FasterRCNNImage from "../../assets/main/FasterRCNN.jpg";
import YOLOv4Image from "../../assets/main/YOLOv4.jpg";
import YOLOv5Image from "../../assets/main/YOLOv5.jpg";
import MaskRCNNImage from "../../assets/main/MaskRCNN.jpg";
import YOLACTImage from "../../assets/main/YOLACT.jpg";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineChevronRight } from "react-icons/hi";
import styled from "styled-components";

const BoundinBoxSection = styled.div`
    height: 35rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .ssdSection {
        width: 60%;
        background-size: 100% 100%;
        border-radius: 30px;
    }

    .fasterRcnnSection {
        width: 38%;
        background-size: 100% 100%;
        border-radius: 30px;
    }

    .yolo4Section {
        width: 49%;
        background-size: 100% 100%;
        border-radius: 30px;
    }

    .yolo5Section {
        width: 49%;
        background-size: 100% 100%;
        border-radius: 30px;
    }

    .textSection {
        padding: 70px 0px 0px 80px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        height: 100%;

        .ssdSection {
            width: 100%;
            height: 35rem;
        }

        .fasterRcnnSection {
            margin-top: 10%;
            width: 100%;
            height: 35rem;
        }

        .yolo4Section {
            margin-top: 10%;
            width: 100%;
            height: 35rem;
        }

        .yolo5Section {
            margin-top: 10%;
            width: 100%;
            height: 35rem;
        }

        .maskRcnnSection {
            width: 100%;
            height: 35rem;
        }

        .yolactSection {
            margin-top: 10%;
            width: 100%;
            height: 35rem;
        }

        .textSection {
            padding: 40px 0px 0px 30px;
        }
    }
`;

const MaskRcnnSection = styled.div`
    width: 100%;
    background-size: 100% 100%;
    border-radius: 30px;
    height: 35rem;

    .textSection {
        padding: 70px 0px 0px 80px;
    }

    @media screen and (max-width: 1024px) {
        .textSection {
            padding: 40px 0px 0px 30px;
        }
    }
`;

const YolactSection = styled.div`
    width: 100%;
    background-size: 100% 100%;
    border-radius: 30px;
    height: 35rem;
    margin-top: 2%;

    .textSection {
        padding: 70px 0px 0px 80px;
    }

    @media screen and (max-width: 1024px) {
        margin-top: 10%;

        .textSection {
            padding: 40px 0px 0px 30px;
        }
    }
`;

function Algorithm({ selectedAlgorithm, windowWidth, props }) {
    const [clickSsd, setClickSsd] = useState(false);
    const [clickFasterRcnn, setClickFasterRcnn] = useState(false);
    const [clickYolo4, setClickYolo4] = useState(false);
    const [clickYolo5, setClickYolo5] = useState(false);
    const [clickMaskRcnn, setClickMaskRcnn] = useState(false);
    const [clickYolact, setClickYolact] = useState(false);

    const onClickSsd = useCallback(() => {
        setClickSsd(true);
        setClickFasterRcnn(false);
        setClickYolo4(false);
        setClickYolo5(false);
    });
    const onClickFasterRcnn = useCallback(() => {
        setClickSsd(false);
        setClickFasterRcnn(true);
        setClickYolo4(false);
        setClickYolo4(false);
    });
    const onClickYolo4 = useCallback(() => {
        setClickSsd(false);
        setClickFasterRcnn(false);
        setClickYolo4(true);
        setClickYolo5(false);
    });
    const onClickYolo5 = useCallback(() => {
        setClickSsd(false);
        setClickFasterRcnn(false);
        setClickYolo4(false);
        setClickYolo5(true);
    });
    const onClickMaskRcnn = useCallback(() => {
        setClickMaskRcnn(true);
        setClickYolact(false);
    });
    const onClickYolact = useCallback(() => {
        setClickMaskRcnn(false);
        setClickYolact(true);
    });

    return selectedAlgorithm === 0 ? (
        <div style={{ width: "80%", margin: "55px 0px 0px 0px", maxWidth: "1500px" }}>
            <BoundinBoxSection>
                <div className="ssdSection" style={{ backgroundImage: `url(${SSDImage})` }}>
                    {!clickSsd ? (
                        <div className="textSection">
                            <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                                SSD-MobileNet
                            </CustomText>
                            <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickSsd} />
                        </div>
                    ) : (
                        <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(5px)" }}>
                            <CustomText>Bounding Bocx Algorithm</CustomText>
                            <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                                SSD-MobileNet
                            </CustomText>
                            <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                                SSD-MobileNet은 사전 훈련된 가중치를 포함하여 {"\n"}TensorFlow Object Detection API에서{"\n"}
                                기본적으로 제공하는 알고리즘 중 하나입니다. {"\n"}
                                {"\n"}스마트폰과 같은 컴퓨팅 제약 조건에서 {"\n"}사용이 가능하고 속도가 빠르며 정확도는 {"\n"}다른 알고리즘에 비해 떨어진다는 단점이 있습니다.
                            </CustomText>
                        </div>
                    )}
                </div>
                <div className="fasterRcnnSection" style={{ backgroundImage: `url(${FasterRCNNImage})` }}>
                    {!clickFasterRcnn ? (
                        <div className="textSection">
                            <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                                Faster-RCNN
                            </CustomText>
                            <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickFasterRcnn} />
                        </div>
                    ) : (
                        <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(7px)" }}>
                            <CustomText>Bounding Bocx Algorithm</CustomText>
                            <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                                Faster-RCNN
                            </CustomText>
                            <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                                Faster R-CNN은 사전 훈련된 가중치를{"\n"}
                                포함하여 TensorFlow Object{"\n"}
                                Detection API에서 기본적으로 제공하는{"\n"}
                                알고리즘 중 하나입니다{"\n"}
                                {"\n"}
                                다른 알고리즘(YOLOv4, YOLOv5,{"\n"}
                                SSD-MobileNet) 보다 느린 모델로{"\n"}
                                알려져 있지만 정확도는 더 높습니다.
                            </CustomText>
                        </div>
                    )}
                </div>
            </BoundinBoxSection>
            <BoundinBoxSection style={{ marginTop: windowWidth > 1024 ? "2%" : "0px" }}>
                <div className="yolo4Section" style={{ backgroundImage: `url(${YOLOv4Image})` }}>
                    {!clickYolo4 ? (
                        <div className="textSection">
                            <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                                YOLOv4
                            </CustomText>
                            <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickYolo4} />
                        </div>
                    ) : (
                        <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(7px)" }}>
                            <CustomText>Bounding Bocx Algorithm</CustomText>
                            <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                                YOLOv4
                            </CustomText>
                            <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                                2020년 4월에 발표된 {"\n"}실시간 객체 감지 알고리즘입니다. {"\n"}
                                {"\n"}
                                Darknet 프레임워크를 기본적으로 사용하며 {"\n"}높은 정확도와 추론속도의 두마리 토끼를 동시에 {"\n"}잡을수 있는 알고리즘입니다.
                            </CustomText>
                        </div>
                    )}
                </div>
                <div className="yolo5Section" style={{ backgroundImage: `url(${YOLOv5Image})` }}>
                    {!clickYolo5 ? (
                        <div className="textSection">
                            <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                                YOLOv5
                            </CustomText>
                            <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickYolo5} />
                        </div>
                    ) : (
                        <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(7px)" }}>
                            <CustomText>Bounding Bocx Algorithm</CustomText>
                            <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                                YOLOv5
                            </CustomText>
                            <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                                YOLOv5는 2020년 6월에 발표된 {"\n"}실시간 객체 감지 알고리즘입니다.{"\n"}
                                {"\n"}
                                PyTorch에서 기본적으로 구현되었으며, {"\n"}프로덕션에서 사용하기 쉽습니다.{"\n"}
                                {"\n"}
                                높은 정확도와 추론속도의 두마리 토끼를 {"\n"}동시에 잡을수 있는 알고리즘입니다.
                            </CustomText>
                        </div>
                    )}
                </div>
            </BoundinBoxSection>
        </div>
    ) : (
        <div style={{ width: "80%", margin: "55px 0px 0px 0px", maxWidth: "1500px" }}>
            <MaskRcnnSection style={{ backgroundImage: `url(${MaskRCNNImage})` }}>
                {!clickMaskRcnn ? (
                    <div className="textSection">
                        <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                            Mask-RCNN
                        </CustomText>
                        <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickMaskRcnn} />
                    </div>
                ) : (
                    <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(7px)" }}>
                        <CustomText>Polygon Algorithm</CustomText>
                        <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                            Mask-RCNN
                        </CustomText>
                        <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                            Mask-RCNN은 사전 훈련된 가중치를 포함하여{"\n"}
                            TensorFlow Object Detection API에서 {"\n"}
                            기본적으로 제공하는 알고리즘 중 하나입니다.{"\n"}
                            Faster-RCNN에서 Masking을 하는 레이어가 {"\n"}
                            하나 추가된 형태로, Instance Segmentation 까지{"\n"}
                            다루는 알고리즘입니다.
                        </CustomText>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "40px" }}>
                            <CustomText fw={600} fs="2.1rem" style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                추론 정확도
                            </CustomText>
                            <HiOutlineChevronRight size="1.9rem" color="#fff" style={{ margin: "0px 7px" }} />
                            <CustomText fw={600} fs="2.1rem">
                                속도
                            </CustomText>
                        </div>
                    </div>
                )}
            </MaskRcnnSection>
            <YolactSection style={{ backgroundImage: `url(${YOLACTImage})`, backgroundRepeat: "no-repeat" }}>
                {!clickYolact ? (
                    <div className="textSection">
                        <CustomText fw={600} fs="3.1rem" mg="0px 0px 10px 0px">
                            YOLACT
                        </CustomText>
                        <AiOutlinePlusCircle size="2rem" color="#fff" style={{ cursor: "pointer" }} onClick={onClickYolact} />
                    </div>
                ) : (
                    <div className="textSection" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: 30, backdropFilter: "blur(7px)" }}>
                        <CustomText>Polygon Algorithm</CustomText>
                        <CustomText fw={600} fs="2.4rem" mg="10px 0px 25px 0px">
                            YOLACT
                        </CustomText>
                        <CustomText fs="1.3rem" style={{ whiteSpace: "pre-wrap" }}>
                            YOLACT는 상대적으로 속도가 느린 Mask-RCNN을{"\n"}
                            개선하기 위해 출시된 알고리즘 입니다.{"\n"}
                            Pytorch에서 구현되었으며 실시간{"\n"}
                            instance segmentation 추론을 진행 할수 있습니다.
                        </CustomText>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "105px" }}>
                            <CustomText fw={600} fs="2.1rem" style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                추론 속도
                            </CustomText>
                            <HiOutlineChevronRight size="1.9rem" color="#fff" style={{ margin: "0px 7px" }} />
                            <CustomText fw={600} fs="2.1rem">
                                정확도
                            </CustomText>
                        </div>
                    </div>
                )}
            </YolactSection>
        </div>
    );
}

export default Algorithm;
