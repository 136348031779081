export const initialState = {
    data: "viewer",
};

export const updateProjectViewerAction = (data) => {
    return {
        type: "UPDATE_PROJECT_VIEWER",
        data,
    };
};

export const deleteProjectViewerAction = () => {
    return {
        type: "DELETE_PROJECT_VIEWER",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PROJECT_VIEWER":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_PROJECT_VIEWER":
            return {
                ...state,
                data: "viewer",
            };

        default:
            return state;
    }
};

export default reducer;
