import React, { useEffect, useState } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis } from "recharts";

function TrainResultLineChart(props) {
    const [data, setData] = useState([]);
    const [dataKey, setDataKey] = useState([]);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setData(props.data);
        setDataKey(props.dataKey);
    }, [props.data]);

    useEffect(() => {
        if (window.innerWidth < 1025) {
            setHeight(140);
        } else {
            setHeight(200);
        }
    }, [window.innerWidth]);

    return (
        <>
            <ResponsiveContainer width="100%" height={height}>
                <LineChart data={data}>
                    <XAxis dataKey="steps" />
                    <YAxis domain={["dataMin", "dataMax"]} />
                    <Line type="monotone" dataKey={dataKey} stroke="#8884d8" fill="#8884d8" strokeWidth={3} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}

export default TrainResultLineChart;
