import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined, GithubOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import yolAct from "../../../assets/document/images/yolact.png";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 110px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }
    .ant-btn-primary {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function YOLACT() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;YOLACT</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    YOLACT
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    YOLACT는 2019년 ICCV에서 발표되었으며 Instance segmentation을 실시간으로 수행하기 위해 개발되었습니다.
                    <br />
                    <br />
                    Mask R-CNN 같은 경우, 기본적으로 ROI(관심 영역)을 찾은 다음 Class, Bounding Box, Mask 등을 예측하기 때문에 시간이 오래 걸린다는 단점이 있습니다.
                    <br />
                    <br />
                    YOLACT는 이런한 문제를 해결하기 위해 개발 되었으며 기본 프로세스는 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Backbone은 ResNet-101이며 C1, C2, C3, C4, C5 Feature map을 생성합니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>FPN을 통해 이전에 생성된 Feature map에서 P3, P4, P5, P7, P7 Feature map을 생성합니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Protonet은 Input으로 Feature Pyramid Network의 P3가 들어가면, Output으로 k개의 Prototype Mask가 만들어지게 됩니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Prediction Head은 Mask의 Coefficient의 계수를 예측하는데 사용하는 Network입니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>NMS는 Object Detection 분야에서 동일 객체에 대해 여러 Bounding Box 중 가장 score가 높은 Box만 남겨두기 위해 사용하는 알고리즘 입니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Crop은 Instance마다의 Bounding Box를 나타내기 위해 거치는 단계입니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>최종적으로 Threshold보다 크게 confidence가 예측이 되는 Bounding Box 및 Mask들만 이미지에 출력을 할 수 있게 됩니다.</li>
                    <br />
                    <br />
                    YOLACT 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <img width="60%" height="100%" src={yolAct} style={{ borderRadius: "10px" }} />
            </div>
            <div className="button-section">
                <a href="https://github.com/dbolya/yolact" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined style={{ color: "#fff", fontSize: "1rem", marginRight: "10px" }} />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/project/list">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            프로젝트 시작하기
                            <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default YOLACT;
