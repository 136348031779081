import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import queryString from "query-string";

import { CustomText } from "../../styles/theme/CustomStyle";
import { useSelector } from "react-redux";

const Wrap = styled(ScrollContainer)`
    background-color: #161b22;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    box-shadow: inset 0 -2px 0 0 #575757;
    overflow-x: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const TabItem = styled.div`
    flex: 0 0 auto;
    width: 8rem;
    padding: 1rem 0;
    /* padding: 1rem 1.5rem; */
    cursor: pointer;
    ${(props) =>
        props.selected &&
        css`
            background-color: #21272e;
            border-bottom: 2px solid transparent;
            border-image: linear-gradient(0.25turn, #2cf2ff, #fbff2c);
            border-image-slice: 2;
        `}
`;

function MypageTab(props) {
    const { UserInfo } = useSelector((state) => state);
    const navigate = useNavigate();
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;

    useEffect(() => {
        if (crntTab === undefined) {
            navigate({
                search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`,
            });
        }
    }, []);

    const handleTabItemClick = useCallback((tab) => {
        if (tab === "memberInfo") {
            navigate({
                search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`,
            });
        } else {
            navigate({
                search: `?user=${UserInfo.data.login_id}&tab=${tab}`,
            });
        }
    }, []);

    return (
        <Wrap>
            <TabItem selected={crntTab === "memberInfo" ? true : false} onClick={() => handleTabItemClick("memberInfo")}>
                <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                    회원 정보
                </CustomText>
            </TabItem>
            {UserInfo.data.is_manager | UserInfo.data.is_admin && (
                <>
                    <TabItem selected={crntTab === "companyInfo" ? true : false} onClick={() => handleTabItemClick("companyInfo")}>
                        <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                            기업 정보
                        </CustomText>
                    </TabItem>
                    <TabItem selected={crntTab === "memberManagement" ? true : false} onClick={() => handleTabItemClick("memberManagement")}>
                        <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                            회원 관리
                        </CustomText>
                    </TabItem>
                </>
            )}
            {UserInfo.data.is_admin && (
                <>
                    <TabItem selected={crntTab === "categoryManagement" ? true : false} onClick={() => handleTabItemClick("categoryManagement")}>
                        <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                            카테고리 관리
                        </CustomText>
                    </TabItem>
                    <TabItem selected={crntTab === "dashboard" ? true : false} onClick={() => handleTabItemClick("dashboard")}>
                        <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                            대시보드
                        </CustomText>
                    </TabItem>
                </>
            )}
        </Wrap>
    );
}

export default MypageTab;
