import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Provider } from "react-redux";
import initStore from "./store";
import rootReducer from "./store/reducer";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { QueryClient, QueryClientProvider } from "react-query";

import LoginRequiredRoute from "./routes/LoginRequiredRoute";

import HomeMain from "./pages/home";
import Login from "./pages/account/Login";
import SignUp from "./pages/account/SignUp";
import UserDashboard from "./pages/dashboard";
import ProjectList from "./pages/project";
import Documentatioin from "./pages/document/";
import GettingStartedGuide from "./pages/document/getting-started";
import TerminologyGuide from "./pages/document/terminology-guide";
import AlgorithmGuide from "./pages/document/algorithm-guide";
import EmailApprove from "./pages/account/EmailApprove";
import ProjectDetail from "./pages/project/ProjectDetail";
// import AddTeamMemberToProject from "./pages/account/AddTeamMemberToProject";
import DataCenterMain from "./pages/datacenter";
import ScheduleMain from "./pages/project/schedule";
import MypageDetail from "./pages/mypage/MypageDetail";
import CreateProject from "./pages/project/CreateProject";
import ScrollToTop from "./routes/ScrollToTop";
import PageNotFound from "./pages/error";
import NoticeMain from "./pages/notice";
import NoticeDetail from "./pages/notice/NoticeDetail";
import PasswordSearch from "./pages/account/PasswordSearch";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import NoticeRegistration from "./pages/notice/NoticeRegistration";
import DataCenterList from "./pages/datacenter/DataCenterList";
import DataCenterDetail from "./pages/datacenter/DataCenterDetail";
import ForkData from "./pages/datacenter/ForkData";

const store = initStore(rootReducer);
const persistor = persistStore(store);

const queryClient = new QueryClient();

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <ScrollToTop>
                            <Routes>
                                <Route path="/" element={<HomeMain />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/signup/email" element={<SignUp />} />
                                <Route path="/find-password" element={<PasswordSearch />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />
                                <Route path="/policy/terms" element={<TermsOfService />} />

                                <Route path="/accounts/activate/:uidb64/:token" element={<EmailApprove />} />
                                {/* <Route path="/invite/collaborator/:uidb64_invitee/:uidb64_project/:token" element={<AddTeamMemberToProject />} /> */}

                                <Route path="/document/" element={<Documentatioin />} />
                                <Route path="/document/getting-started" element={<GettingStartedGuide />} />
                                <Route path="/document/terminology-guide" element={<TerminologyGuide />} />
                                <Route path="/document/algorithm-guide" element={<AlgorithmGuide />} />

                                <Route path="/notice" element={<NoticeMain />} />
                                <Route path="/notice/:id" element={<NoticeDetail />} />

                                {/* Login require services */}
                                <Route element={<LoginRequiredRoute />}>
                                    <Route path="/mypage" element={<MypageDetail />} />

                                    <Route path="/dashboard" element={<UserDashboard />} />
                                    <Route path="/project/list" element={<ProjectList />} />
                                    <Route path="/project/create" element={<CreateProject />} />

                                    <Route path="/notice/crate" element={<NoticeRegistration />} />
                                </Route>
                                <Route path="/project/:id" element={<ProjectDetail />} />
                                <Route path="/data-center" element={<DataCenterMain />} />
                                <Route path="/data-center/list" element={<DataCenterList />} />
                                <Route path="/data-center/:id" element={<DataCenterDetail />} />
                                <Route path="/data-center/:id/fork" element={<ForkData />} />

                                {/* <Route path="/schedule" element={<ScheduleMain />} /> */}

                                <Route path="/*" element={<PageNotFound />} />
                            </Routes>
                        </ScrollToTop>
                    </BrowserRouter>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
