import _ from "lodash";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const useAuth = () => {
    const { UserInfo, Token } = useSelector((state) => state);

    return !_.isEmpty(UserInfo.data) && !_.isEmpty(Token.data);
};

const LoginRequiredRoute = () => {
    const prevRoute = useLocation();
    const isAuth = useAuth();

    return isAuth ? <Outlet /> : <Navigate to="/login" state={{ prevRoute }} />;
};

export default LoginRequiredRoute;
