import React from "react";
import styled from "styled-components";
import { Header } from "antd/lib/layout/layout";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";

const Wrap = styled(Header)`
    background-color: #0c1117;
    height: 62px;
    padding: 16px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const DesktopContents = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;

    .menu-items {
        /* flex: 1; */
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 1024px) {
            /* width: 100%; */
        }

        div {
            display: flex;
            flex-direction: row;
            /* justify-content: space-between; */
            align-items: center;
            gap: 10px;
        }

        @media screen and (max-width: 499px) {
            flex-direction: column;
            height: 100%;

            .qtext {
                font-size: 0.5rem;
                margin-block-start: 0;
                margin-block-end: 0;
                line-height: 1.3rem;
            }

            .loginText {
                line-height: 1.3rem;
            }
        }
    }
`;

const logoLinkStyle = { display: "flex", flexDirection: "column", alignItems: "center" };

function SignUpHeader(props) {
    return (
        <Wrap>
            <DesktopContents>
                <Link to="/" style={logoLinkStyle}>
                    <Logo width="10rem" height="100%" />
                </Link>

                <div className="menu-items">
                    <CustomText fs="1rem" className="qtext">
                        이미 계정이 있으신가요?
                    </CustomText>
                    <Link to="/login" className="loginSection">
                        <div>
                            <CustomText fs="1rem" mg="0px 0px 0px 40px" className="loginText">
                                로그인
                            </CustomText>
                            <ArrowRightCircle stroke="#fff" width="1.2rem" height="1.2rem" />
                        </div>
                    </Link>
                </div>
            </DesktopContents>
        </Wrap>
    );
}

export default SignUpHeader;
