import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { ReactComponent as UpArrow } from "../../assets/project/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";
import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as PlusIcon } from "../../assets/user-dashboard/plus-circle.svg";
import ProjectDetailLayout from "../../components/layout/ProjectDetailLayout";
import DataSet from "../../components/project/dataset";
import ModelTrainMain from "../../components/project/model-train";
import { GetProjectExpanationMember } from "../../services/project-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import { Colors } from "../../styles/theme/Colors";
import IssueMain from "./issue";
import SettingMain from "./setting";
import ValidationMain from "../../components/project/validation";
import ModelMain from "../../components/project/model";
import AddMember from "../../components/modal/AddMember";
import AlertModal from "../../components/modal/AlertModal";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as ImageIcon } from "../../assets/project/image-icon.svg";
import numberFormatting from "../../common/numberFormatting";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        /* padding-bottom: 10rem; */
    }
`;

const ProjectInfoWrapper = styled.div`
    width: 100%;
    background-color: #303a45;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 13px;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const DataSetWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.5rem;

    .image-total-count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .totalcount-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3rem;
    }
`;

const MemberWrapper = styled.div`
    width: 100%;
    padding: 0 1.5rem 24px 1.5rem;
    border-bottom: 1px solid #707070;
    margin-top: 1.3rem;
    margin-bottom: 1rem;

    .title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title-section div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MemberList = styled(ScrollContainer)`
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 22px;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MemberItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

const InfoToggle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.3rem;
    margin-bottom: 3rem;
    cursor: pointer;
    gap: 5px;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

function ProjectDetail(props) {
    const { Token } = useSelector((state) => state);
    const params = useParams();
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;
    const crntPg = queryString.parse(location.search).pg;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [infoShowMore, setInfoShowMore] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        setInfoShowMore(false);
        projectDetailRefetch();
    }, [crntTab]);

    const {
        data: projectData,
        isLoading: loading,
        refetch: projectDetailRefetch,
    } = useQuery(
        ["project", "explanation", "member", params.id],
        async (e) => {
            const data = await GetProjectExpanationMember(Token.data.access, params.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <ProjectDetailLayout page={crntPg}>
            {crntTab !== "setting" ? (
                infoShowMore ? (
                    <>
                        {/* <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                <CustomText fs="0.875rem" fw="400" cl="#E2FF9B" mg="3px 0px" style={{ lineHeight: "0.875rem" }}>
                                {projectData?.data.is_public ? "공개" : "비공개"}
                                </CustomText>
                                </CircleText>
                                
                                <div className="title-section">
                                <PrivateIcon width="1.25rem" height="1.25rem" />
                                <CustomText fs="1.25rem" fw="600" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {projectData?.data.title}
                                </CustomText>
                            </div> */}
                        {projectData?.data.explanation && (
                            <>
                                {/* <CustomText fs="1.125rem" fw="600" mg="18px 0px 10px 0px">
                                        프로젝트 설명
                                    </CustomText> */}

                                <ProjectInfoWrapper>
                                    <CustomText fs="1rem" fw="400" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: projectData?.data.explanation }} />
                                </ProjectInfoWrapper>
                            </>
                        )}
                        <DataSetWrapper>
                            <div className="image-total-count">
                                <div className="totalcount-item">
                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                    <CustomText>총 데이터 수</CustomText>
                                    <Tooltip title={projectData?.data.total_data_count}>
                                        <CustomText fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {numberFormatting(projectData?.data.total_data_count)}
                                        </CustomText>
                                    </Tooltip>
                                </div>
                            </div>
                        </DataSetWrapper>
                        <MemberWrapper>
                            <div className="title-section">
                                <div>
                                    <MemberIcon width="1.3rem" height="1.3rem" fill="#fff" />
                                    <CustomText mg="0 0 0 10px">팀원</CustomText>
                                </div>
                                <div style={{ marginRight: 10, cursor: "pointer" }} onClick={() => setIsOpen(true)}>
                                    <CustomText mg="0 5px 0 0">팀원 추가</CustomText>
                                    <PlusIcon width="1.125rem" height="1.125rem" />
                                </div>
                            </div>

                            <MemberList>
                                <MemberItem>
                                    <Avatar size="default" src={projectData?.data.manager.image_path ? process.env.REACT_APP_END_POINT + projectData?.data.manager.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[projectData?.data.manager.id % 10] }} />
                                    <CustomText>{projectData?.data.manager.name}</CustomText>
                                </MemberItem>
                                {projectData?.data.collaborators?.map((user) => {
                                    return (
                                        <MemberItem key={user.id}>
                                            <Avatar size="default" src={user.image_path ? process.env.REACT_APP_END_POINT + user.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[user.id % 10] }} />
                                            <CustomText>{user.name}</CustomText>
                                        </MemberItem>
                                    );
                                })}
                            </MemberList>
                        </MemberWrapper>
                        <InfoToggle onClick={() => setInfoShowMore(false)}>
                            <UpArrow width="1.4rem" height="1.4rem" />
                            <CustomText fs="1rem">접기</CustomText>
                        </InfoToggle>
                    </>
                ) : (
                    <>
                        {/* {projectData?.data.explanation && (
                            <ProjectInfoWrapper>
                                <PrivateIcon width="1.25rem" height="1.25rem" />
                                <CustomText fs="1.25rem" fw="600" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {projectData?.data.title}
                                </CustomText>
                                <div className="title-section" style={{ marginTop: 0 }}>
                                    <CustomText fs="1rem" fw="400" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: projectData?.data.explanation }} />
                                </div>
                            </ProjectInfoWrapper>
                        )} */}
                        <InfoToggle onClick={() => setInfoShowMore(true)}>
                            <DownArrow width="1.4rem" height="1.4rem" />
                            <CustomText fs="1rem">프로젝트 내용 열기</CustomText>
                        </InfoToggle>
                    </>
                )
            ) : null}

            <Wrap>
                {crntTab === "dataset" && <DataSet page={crntPg} projectData={projectData?.data} />}
                {crntTab === "training" && <ModelTrainMain />}
                {crntTab === "validation" && <ValidationMain />}
                {crntTab === "model" && <ModelMain />}
                {crntTab === "issue" && <IssueMain />}
                {crntTab === "setting" && <SettingMain />}

                <AddMember modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </ProjectDetailLayout>
    );
}

export default ProjectDetail;
