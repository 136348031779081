import React, {useEffect, useState, useCallback, useMemo} from "react";
import {Stage} from "react-konva";

import Regions from "./Regions";
import BoundingBoxBaseImage from "./BoundingBoxBaseImage";
import {Menu, Button} from "antd";
import useStore from "./Store";

import AnnotationModal from "../modal/AnnotationModal";
import {CustomText} from "../../styles/theme/CustomStyle";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useMutation} from "react-query";
import {UpdateAnnotation} from "../../services/labeling-services";
import AlertModal from "../modal/AlertModal";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

const SettingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
`;

const ZoomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 10px;
    flex-direction: row;
    gap: 5px;
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${props => props.bc} !important;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

function limitAttributes(stage, newAttrs) {
    const box = stage.findOne("Image").getClientRect();
    const minX = -box.width + stage.width() / 2;
    const maxX = stage.width() / 2;

    const x = Math.max(minX, Math.min(newAttrs.x, maxX));

    const minY = -box.height + stage.height() / 2;
    const maxY = stage.height() / 2;

    const y = Math.max(minY, Math.min(newAttrs.y, maxY));

    const scale = Math.max(0.05, newAttrs.scale);

    return {x, y, scale};
}

function getRelativePointerPosition(node) {
    const transform = node.getAbsoluteTransform().copy();

    transform.invert();

    const pos = node.getStage().getPointerPosition();

    return transform.point(pos);
}

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function DrawBBox({imageId, annotationData, image_url, datasetId}) {
    const {width, height} = useStore(s => ({
        width: s.width,
        height: s.height,
    }));

    const stageRef = React.useRef();
    const {Token, UserInfo, SelectedDataset} = useSelector(state => state);
    const IMAGE_URL = image_url;
    const image = useStore(state => state.image);
    const annotationList = useStore(state => state.annotationList);
    const setAnnotationList = useStore(state => state.setAnnotationList);
    const setSize = useStore(s => s.setSize);
    const scale = useStore(state => state.scale);
    const toggleDrawing = useStore(state => state.toggleIsDrawing);

    const isDrawing = useStore(state => state.isDrawing);
    const setIsDrag = useStore(state => state.setIsDrag);
    const isDrag = useStore(state => state.isDrag);
    const regions = useStore(state => state.regions);
    const setRegions = useStore(state => state.setRegions);
    const selectedId = useStore(s => s.selectedRigionId);
    const selectRegion = useStore(s => s.selectRegion);
    const annotationPk = useStore(s => s.annotationPk);
    const isMosaic = useStore(s => s.isMosaic);
    const setMosaics = useStore(s => s.setMosaics);
    const mosaics = useStore(s => s.mosaics);

    const [annotations, setAnnotations] = useState([]);

    const [newAnnotation, setNewAnnotation] = useState([]);
    const [selectedLabelId, setDropDownSelected] = useState("");
    const [selectedLabelName, setSelectedLabelName] = useState("");

    const isOpen = useStore(s => s.isOpen);
    const setModalOpen = useStore(s => s.setModalOpen);
    const labelOptions = useStore(s => s.labelOptions);

    const [xaxis, setXaxis] = useState([0, 0, 0, 0]);
    const [yaxis, setYaxis] = useState([0, 0, 0, 0]);
    const clickDrawBox = useStore(state => state.clickDrawBox);

    const imageWidth = useStore(state => state.imageWidth);
    const imageHeight = useStore(state => state.imageHeight);

    const location = useLocation();
    const crntTab = queryString.parse(location.search).pg;

    const isLabelPage = useStore(state => state.isLabelPage);
    const setIsLabelPage = useStore(state => state.setIsLabelPage);
    const [rectWidth, setRectWidth] = useState(0);
    const labelModal = useStore(state => state.labelModal);
    const isInputIndex = useStore(state => state.isInputIndex);
    const [opacity, setOpacity] = useState(0.5);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const updateAnnotation = useMutation(UpdateAnnotation);
    const setIsMosaic = useStore(state => state.setIsMosaic);

    const setLabelModal = useStore(state => state.setLabelModal);

    const zoomStage = (stage, scaleBy) => {
        if (isInputIndex === true) {
            return;
        }
        const oldScale = stage.scaleX();

        const pos = {
            x: stage.width() / 2,
            y: stage.height() / 2,
        };
        const mousePointTo = {
            x: pos.x / oldScale - stage.x() / oldScale,
            y: pos.y / oldScale - stage.y() / oldScale,
        };

        const newScale = Math.max(0.05, oldScale * scaleBy);

        const newPos = {
            x: -(mousePointTo.x - pos.x / newScale) * newScale,
            y: -(mousePointTo.y - pos.y / newScale) * newScale,
        };

        const newAttrs = limitAttributes(stage, {...newPos, scale: newScale});
        setRectWidth(prev => prev / scaleBy);
        stage.to({
            x: newAttrs.x,
            y: newAttrs.y,
            scaleX: newAttrs.scale,
            scaleY: newAttrs.scale,
            duration: 0.1,
        });

        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "crosshair";
    };

    const clickBoxMosaic = () => {
        if (labelModal === true) {
            return;
        }
        if (isInputIndex === true) {
            return;
        }
        if (SelectedDataset.data.annotation_type === "polygon") {
            toggleDrawing(false);
        }
        setIsMosaic(true);
        setLabelModal(false);
    };

    const labelModalOpen = () => {
        if (isInputIndex === true) {
            return;
        }
        if (isMosaic === true) {
            return;
        }
        if (isLabelPage === true) {
            setLabelModal(true);
            setIsMosaic(false);
            setMosaics([]);
        }
    };

    const handleMouseDown = event => {
        if (isDrag === true) {
            return;
        }

        const point = getRelativePointerPosition(event.target.getStage());

        if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨링 영역 오류",
                text: "라벨링 이미지 영역 밖입니다.",
                afterFunction: null,
            });
            return;
        }
        const annotationToAdd = {x: point.x, y: point.y, width: 0, height: 0, key: "0"};
        setNewAnnotation([annotationToAdd]);
        let id = 0;

        if (isMosaic === false) {
            if (labelOptions.length < 1) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨 오류",
                    text: "등록된 라벨이 없습니다.\n라벨을 등록해주세요.",
                    afterFunction: null,
                });

                return;
            }
            if (isDrawing) {
                setModalOpen(true);
                return;
            }

            toggleDrawing(true);

            if (regions.length > 0) {
                id = regions[regions.length - 1].id + 1;
            } else {
                id = 0;
            }

            let color = "";
            color = "black";

            const region = {
                id: id++,
                points: annotationToAdd,
                color: color,
            };

            setRegions(regions.concat([region]));
        } else {
            toggleDrawing(true);
            if (mosaics.length > 0) {
                id = mosaics[mosaics.length - 1].id + 1;
            } else {
                id = 0;
            }
            let color = "";
            color = "white";
            const mosaic = {
                id: id++,
                points: annotationToAdd,
                color: color,
            };
            setMosaics(mosaics.concat([mosaic]));
        }
    };

    const handleMouseMove = event => {
        if (isDrag === true) {
            setYaxis([0, 0, 0, 0]);
            setXaxis([0, 0, 0, 0]);

            return;
        }
        const point = getRelativePointerPosition(event.target.getStage());
        if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
            setYaxis([0, 0, 0, 0]);
            setXaxis([0, 0, 0, 0]);

            return;
        }

        if (!isDrawing && scale != 1) {
            const container = document.querySelector("canvas");
            // alert(p.x);
            setYaxis([point.x, 0, point.x, imageHeight]);
            setXaxis([0, point.y, imageWidth, point.y]);
            return;
        }

        if (isMosaic === false) {
            if (selectedId === null) {
                setYaxis([0, 0, 0, 0]);
                setXaxis([0, 0, 0, 0]);
                const sx = newAnnotation[0].x;
                const sy = newAnnotation[0].y;
                const point = getRelativePointerPosition(event.target.getStage());
                let lastRegion = {...regions[regions.length - 1]};

                const annotationToAdd = {
                    x: sx,
                    y: sy,
                    width: point.x - sx,
                    height: point.y - sy,
                    annotation: "",
                    key: annotations.length + 1,
                };

                setNewAnnotation([annotationToAdd]);
                lastRegion.points = annotationToAdd;
                regions.splice(regions.length - 1, 1);
                selectRegion(null);
                setRegions(regions.concat([lastRegion]));
            }
        } else {
            setYaxis([0, 0, 0, 0]);
            setXaxis([0, 0, 0, 0]);
            const sx = newAnnotation[0].x;
            const sy = newAnnotation[0].y;
            const point = getRelativePointerPosition(event.target.getStage());
            let lastRegion = {...mosaics[mosaics.length - 1]};

            const annotationToAdd = {
                x: sx,
                y: sy,
                width: point.x - sx,
                height: point.y - sy,
                annotation: "",
                key: annotations.length + 1,
            };

            setNewAnnotation([annotationToAdd]);
            lastRegion.points = annotationToAdd;
            mosaics.splice(mosaics.length - 1, 1);
            //selectRegion(null);
            setMosaics(mosaics.concat([lastRegion]));
        }
    };

    const handleMouseUp = event => {
        if (isDrag === true) {
            return;
        }
        const point = getRelativePointerPosition(event.target.getStage());

        if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨링 영역 오류",
                text: "라벨링 이미지 영역 밖입니다.",
                afterFunction: null,
            });
            return;
        }

        if (!isDrawing) {
            return;
        }

        if (isMosaic === false) {
            const lastRegion = regions[regions.length - 1];
            if (isOpen === true) {
                return;
            }

            if (
                Math.abs(lastRegion.points.width) === 0 ||
                Math.abs(lastRegion.points.height) === 0
            ) {
                let garbage_index = regions.findIndex(data => data.color === "black");
                regions.splice(garbage_index, 1);
                setRegions(regions.concat());
                toggleDrawing(false);

                return;
            }

            if (Math.abs(lastRegion.points.width) < 4 || Math.abs(lastRegion.points.height) < 4) {
                let garbage_index = regions.findIndex(data => data.color === "black");
                regions.splice(garbage_index, 1);
                setRegions(regions.concat());
                toggleDrawing(false);

                return;
            }

            if (selectedId === null) {
                setModalOpen(true);
            }
        } else {
            const lastRegion = mosaics[mosaics.length - 1];

            if (
                Math.abs(lastRegion.points.width) === 0 &&
                Math.abs(lastRegion.points.height) === 0
            ) {
                mosaics.splice(mosaics.length - 1, 1);
                setMosaics(mosaics.concat());
                toggleDrawing(false);
                return;
            }

            if (Math.abs(lastRegion.points.width) < 4 && Math.abs(lastRegion.points.height) < 4) {
                mosaics.splice(mosaics.length - 1, 1);
                setMosaics(mosaics.concat());
                toggleDrawing(false);
                return;
            }
            toggleDrawing(false);
        }
    };

    const handleMouseOut = event => {
        setYaxis([0, 0, 0, 0]);
        setXaxis([0, 0, 0, 0]);
    };

    const handleClick = event => {
        const clickedNotOnRegion = event.target.name() !== "region";

        if (clickDrawBox) {
            return;
        }
        if (isMosaic === false) {
            if (clickedNotOnRegion) {
                selectRegion(null);
            } else {
                setModalOpen(true);
            }
        }
    };

    const handleCancleClick = () => {
        if (selectedId === null) {
            toggleDrawing(false);
            setModalOpen(false);
            regions.splice(regions.length - 1, 1);
            setRegions(regions.concat());

            return;
        }

        let tempRegion = regions;

        let index = tempRegion.findIndex(data => data.id === selectedId);

        tempRegion.splice(index, 1);

        setRegions(tempRegion.concat());

        selectRegion(null);
        toggleDrawing(false);
        setAnnotationData(tempRegion.concat(), annotationPk);
        setRegions(regions.concat());
        setModalOpen(false);
    };

    const onCancelEvent = () => {
        if (selectedId === null) {
            toggleDrawing(false);
            setModalOpen(false);
            regions.splice(regions.length - 1, 1);
            setRegions(regions.concat());
            return;
        } else {
            setModalOpen(false);
            selectRegion(null);
        }
    };

    const handleAnnotationStateChange = () => {
        let regionId = 0;

        if (selectedId === null) {
            regionId = regions[regions.length - 1].id;
        } else {
            regionId = selectedId;
        }

        if (selectedLabelId === "") {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨 선택 오류",
                text: "선택된 라벨이 없습니다.\n라벨을 선택해주세요.",
                afterFunction: null,
            });
            setDropDownSelected("");
            return;
        }

        setModalOpen(false);

        if (regions.length > 0) {
            let selRegion = regions.filter(data => data.id === regionId);
            let index = regions.findIndex(data => data.id === regionId);

            let label_name = labelOptions.filter(data => data.key === selectedLabelId)[0].text;

            let color = labelOptions.filter(data => data.key === selectedLabelId)[0].color;

            let changeRegion = "";
            changeRegion = selRegion[0];

            const annotationToAdd = {
                x: changeRegion.points.x,
                y: changeRegion.points.y,
                width: changeRegion.points.width,
                height: changeRegion.points.height,
                annotation: label_name,
                key: changeRegion.points.key,
            };

            changeRegion.points = annotationToAdd;
            changeRegion.color = color;
            regions.splice(index, 1, changeRegion);

            setAnnotationData(regions.concat(), annotationPk);
            setRegions(regions.concat());
            selectRegion(null);
            toggleDrawing(false);
        }
    };

    const setAnnotationData = useCallback(
        async (regions, annotationPk) => {
            let annotationData = "";

            for (let i = 0; i < regions.length; i++) {
                let x = regions[i].points.x;
                let y = regions[i].points.y;
                let width = regions[i].points.width;
                let height = regions[i].points.height;

                if (width > 0 && height > 0) {
                    x = x;
                    y = y;
                } else if (width > 0 && height < 0) {
                    x = x;
                    y = y + height;
                } else if (width < 0 && height < 0) {
                    x = x + width;
                    y = y + height;
                } else if (width < 0 && height > 0) {
                    x = x + width;
                    y = y;
                }
                if (regions[i].color === "black") {
                    console.log("bug");
                } else {
                    annotationData +=
                        Math.floor(x * 100) / 100 +
                        "," +
                        Math.floor(y * 100) / 100 +
                        "," +
                        Math.floor(Math.abs(regions[i].points.width * 100) / 100) +
                        "," +
                        Math.floor(Math.abs(regions[i].points.height * 100) / 100) +
                        "," +
                        labelOptions.filter(data => data.text === regions[i].points.annotation)[0]
                            .key +
                        "||";
                }
            }

            const formData = new FormData();
            formData.append("update_user", UserInfo.data.id);
            formData.append("annotation_data", annotationData);

            await updateAnnotation.mutateAsync(
                {token: Token.data.access, pk: parseInt(annotationPk), formData: formData},
                {
                    onSuccess: res => {
                        let index = annotationList.findIndex(data => data.id == annotationPk);
                        annotationList[index] = res.data;
                        setAnnotationList(annotationList);
                        setModalOpen(false);
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        },
        [annotationPk, labelOptions]
    );

    const getAnnotationData = (labelOptions, annotation) => {
        let id = 0;
        let annotationData = [];
        let annotationToAdd = {};
        let regionList = [];
        let key = 0;
        let filterData = [];
        let annotationName = "";
        let color = "";

        try {
            if (annotation !== null) {
                annotationData = annotation.split("||");
            } else {
                setRegions([]);
            }

            if (labelOptions.length > 0) {
                for (let i = 0; i < annotationData.length - 1; i++) {
                    annotationToAdd["id"] = id++;
                    annotationToAdd["points"] = [];
                    key = parseInt(annotationData[i].split(",")[4]);
                    filterData = labelOptions.filter(data => data.key === key);
                    annotationName = filterData[0].text;
                    color = filterData[0].color;

                    let box = {
                        x: Math.floor(annotationData[i].split(",")[0] * 100) / 100,
                        y: Math.floor(annotationData[i].split(",")[1] * 100) / 100,
                        width: Math.floor(annotationData[i].split(",")[2] * 100) / 100,
                        height: Math.floor(annotationData[i].split(",")[3] * 100) / 100,
                        annotation: annotationName,
                    };

                    annotationToAdd["points"] = box;
                    annotationToAdd["color"] = color;
                    regionList.push(annotationToAdd);
                    annotationToAdd = {};
                }
                setRegions(regionList);
            }
        } catch {
            return;
        }
    };

    document.onkeyup = checkKey;

    function checkKey(e) {
        if (e.keyCode === 16) {
            setIsDrag(false);
        }
        if (isLabelPage === true) {
            if (isOpen === true) {
                if (e.keyCode === 49) {
                    pressKeyLabel(0);
                } else if (e.keyCode === 50) {
                    pressKeyLabel(1);
                } else if (e.keyCode === 51) {
                    pressKeyLabel(2);
                } else if (e.keyCode === 52) {
                    pressKeyLabel(3);
                } else if (e.keyCode === 53) {
                    pressKeyLabel(4);
                } else if (e.keyCode === 54) {
                    pressKeyLabel(5);
                } else if (e.keyCode === 55) {
                    pressKeyLabel(6);
                } else if (e.keyCode === 56) {
                    pressKeyLabel(7);
                } else if (e.keyCode === 57) {
                    pressKeyLabel(8);
                } else if (e.keyCode === 48) {
                    pressKeyLabel(9);
                }
                let canvas = document.querySelector(".canvas");
                canvas.style.cursor = "crosshair";
            } else {
                if (e.keyCode === 49) {
                    labelModalOpen();
                }
                if (e.keyCode === 50) {
                    clickBoxMosaic();
                }
            }
            if (e.keyCode === 13) {
                if (isOpen === true) {
                    handleAnnotationStateChange();
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "crosshair";
                }
            }

            if (e.keyCode === 187) {
                zoomStage(stageRef.current, 1.2);
            }
            if (e.keyCode === 189) {
                zoomStage(stageRef.current, 0.8);
            }

            if (e.keyCode === 16) {
                setIsDrag(false);
                let canvas = document.querySelector(".canvas");
                canvas.style.cursor = "crosshair";
            }
        } else {
            if (e.keyCode === 16) {
                setIsDrag(false);
            }
        }
    }

    const onLabelMenuClick = useCallback(
        e => {
            let label = labelOptions.filter(data => data.key === parseInt(e.key))[0];
            setDropDownSelected(label.key);
            setSelectedLabelName(label.text);
        },
        [labelOptions]
    );

    const pressKeyLabel = key => {
        let index = labelOptions.findIndex(data => data.key === key);

        if (index >= 0) {
            let label = labelOptions.filter(data => data.key === parseInt(key))[0];
            setDropDownSelected(label.key);
            setSelectedLabelName(label.text);
        }
    };

    const labelItems = useMemo(
        () => (
            <StyledMenu onClick={onLabelMenuClick}>
                {labelOptions.map(label => {
                    return (
                        <Menu.Item className="menu-item" key={label.key}>
                            <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                {label.text}
                            </CustomText>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [labelOptions]
    );

    useEffect(() => {
        function checkSize() {
            const container = document.querySelector(".right-panel");

            setSize({
                width: container.offsetWidth,
                height,
            });
        }
        checkSize();
        window.addEventListener("resize", checkSize);

        return () => window.removeEventListener("resize", checkSize);
    }, [imageId]);

    useEffect(() => {
        if (crntTab === "labeling") {
            setIsLabelPage(true);
        }
        return () => {
            setIsLabelPage(false);
            document.onkeyup = checkKey;
        };
    }, [crntTab]);

    const initStage = image => {
        if (image !== null) {
            const scale = Math.min(width / image.width, height / image.height);
            setRectWidth(4 * (image.width / width));
            stageRef.current.to({
                x: 0,
                y: 0,
                scaleX: scale,
                scaleY: scale,
                duration: 0.1,
            });
        }
    };

    return (
        <>
            <SettingContainer>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        paddingBottom: "10px",
                    }}>
                    <CustomText cl="#FFFFFF">라벨 투명도 조절</CustomText>
                    <input
                        id="slider"
                        type="range"
                        min="0.15"
                        max="1"
                        step="0.05"
                        defaultValue="0.5"
                        onChange={e => {
                            setOpacity(parseFloat(e.target.value));
                        }}></input>
                </div>

                <ZoomContainer>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{cursor: "zoom-in", paddingLeft: "10px", paddingRight: "10px"}}
                        onClick={() => {
                            zoomStage(stageRef.current, 1.2);
                        }}>
                        <CustomText cl="#FFFFFF">+</CustomText>
                    </CustomBtn>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{paddingLeft: "12px", paddingRight: "12px", cursor: "zoom-out"}}
                        onClick={() => {
                            zoomStage(stageRef.current, 0.8);
                        }}>
                        <CustomText cl="#FFFFFF">-</CustomText>
                    </CustomBtn>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{paddingLeft: "8px", paddingRight: "8px", cursor: "pointer"}}
                        onClick={() => {
                            initStage(image);
                        }}>
                        <CustomText cl="#FFFFFF">reset</CustomText>
                    </CustomBtn>
                </ZoomContainer>
            </SettingContainer>

            <Stage
                style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    cursor: "crosshair",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
                ref={stageRef}
                width={width}
                height={height}
                scaleX={scale}
                scaleY={scale}
                className="canvas"
                onClick={handleClick}
                onMouseOut={handleMouseOut}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchstart={handleMouseDown}
                onTouchmove={handleMouseMove}
                onTouchEnd={handleMouseUp}
                opacity={1}
                draggable={isDrag}>
                <BoundingBoxBaseImage
                    image_url={IMAGE_URL}
                    datasetId={datasetId}
                    regions={regions}
                    annotationData={annotationData}
                    getAnnotationData={getAnnotationData}
                    initStage={initStage}
                    setRectWidth={setRectWidth}
                />
                {image !== null && (
                    <Regions
                        clickDrawBox={clickDrawBox}
                        isDrawing={isDrawing}
                        xaxis={xaxis}
                        yaxis={yaxis}
                        setAnnotationData={setAnnotationData}
                        setModalOpen={setModalOpen}
                        setAlertModalOpen={setAlertModalOpen}
                        opacity={opacity}
                        rectWidth={rectWidth}
                    />
                )}
            </Stage>

            {labelOptions !== undefined && (
                <AnnotationModal
                    isOpen={isOpen}
                    labelItems={labelItems}
                    selectedLabelName={selectedLabelName}
                    toggleDrawing={toggleDrawing}
                    handleAnnotationStateChange={handleAnnotationStateChange}
                    handleCancleClick={handleCancleClick}
                    isCancleClick={onCancelEvent}
                    setModalOpen={setModalOpen}
                    annotationType={"boundingbox"}
                />
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default DrawBBox;
