import React from "react";
import styled from "styled-components";
import UserDashboardLayout from "../../components/layout/UserDashboardLayout";
import MyTrainSchedule from "../../components/use-dashboard/reserve-list/MyTrainSchedule";
import TrainingHistory from "../../components/use-dashboard/TrainingHistory";
import TrainingProgress from "../../components/use-dashboard/TrainingProgress";

const Wrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        padding-bottom: 10rem;
    }
`;

function UserDashboard(props) {
    return (
        <UserDashboardLayout>
            <Wrap>
                <TrainingProgress />
                <MyTrainSchedule />
                <TrainingHistory />
            </Wrap>
        </UserDashboardLayout>
    );
}

export default UserDashboard;
