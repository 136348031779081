import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import {Button, Checkbox} from "antd";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {ReactComponent as BetaIcon} from "../../../assets/project/beta.svg";
import {ReactComponent as AutoLabelIcon} from "../../../assets/project/auto-label.svg";
import {CustomText} from "../../../styles/theme/CustomStyle";
import ModelDropDown from "./ModelDropDown";

import AddDataset from "../../modal/AddDataset";

import _ from "lodash";

import AlertModal from "../../modal/AlertModal";
import AutoLabelingList from "./AutoLabelingList";
import {SetAutoLabel} from "../../../services/train-services";
import {useMutation} from "react-query";
import NoLabelList from "./NoLabelList";

const TitleSection = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .auto-label-beta {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const DateSelectWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    min-width: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .btn-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: auto;
        min-height: 0;
        align-self: stretch;
        gap: 15px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .btn-section {
            width: 100%;
            height: 2.5rem;
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;
    flex: 1;

    padding: 0 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    padding: 20px;
    border-color: ${props => props.bc} !important;
`;

const TabBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;

    height: auto;
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    padding: 20px;
    border-color: ${props => props.bc} !important;
`;

const LabelWrapper = styled.div`
    margin-top: 0.5rem;
    border-bottom: 1px solid #707070;

    width: 100%;
    .title-section {
        margin-bottom: 10px;
    }

    .label-check {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: fit-content;
        gap: 10px;
    }
`;

const TabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1rem;
`;

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        border: 1px solid #636363;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #161b22;
    }
`;

const LabelListWrap = styled.div`
    width: 100%;
    display: flex;
`;

const LabelList = styled.div`
    width: 89%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 1rem;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 20px;
`;

function AutoLabelMain() {
    const {SelectedDataset, Token} = useSelector(state => state);
    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [AutoLabelModalOpen, setAutoLabelModelOpen] = useState(false);
    const setAutoLabel = useMutation(SetAutoLabel);
    const [imageList, setImageList] = useState([]);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [modelData, setModelData] = useState({
        id: 0,
        model_name: "모델 선택",
        algorithm_name: "",
        label_data: "",
    });

    const [uploadColor, setUploadColor] = useState("#E2FF9B");
    const [selectColor, setSelectColor] = useState("#b2b1b1");
    const [isLoading, setIsLoading] = useState(false);
    const [isImageUpload, setIsImageUpload] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        resetCheck();
    }, [modelData]);

    const resetCheck = () => {
        let labels = document.querySelectorAll(".ant-checkbox-input");
        labels.forEach(item => {
            if (item.checked === true) {
                item.checked = false;
            }
        });
    };

    const onClickAutoLabelButton = useCallback(async imageList => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("dataset", SelectedDataset.data.id);
        formData.append("model_id", modelData.id);
        const images = imageList.filter(s => s.type.includes("image"));
        let labels = document.querySelectorAll(".ant-checkbox-input");
        let is_label = false;
        if (modelData.algorithm_name == "") {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "모델 선택 오류",
                text: "모델을 선택해주세요.",
                afterFunction: null,
            });
            setIsLoading(false);
            return;
        }

        if (labels.length > 0) {
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].checked == true) {
                    formData.append("label_list", labels[i].value);
                    is_label = true;
                }
            }
        }

        if (SelectedDataset.data.annotation_type === "bbox") {
            if (modelData.algorithm_name !== "YOLOv5" && modelData.algorithm_name !== "YOLOv7") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "오토라베링 지원 모델 아님",
                    text: "오토라벨링은 현재 배타버전 YOLOv5, YOLACT 알고리즘만 지원합니다.",
                    afterFunction: null,
                });
                setIsLoading(false);
                return;
            }
        } else {
            if (modelData.algorithm_name !== "YOLACT") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "오토라베링 지원 모델 아님",
                    text: "오토라벨링은 현재 배타버전 YOLOv5, YOLACT 알고리즘만 지원합니다.",
                    afterFunction: null,
                });
                setIsLoading(false);
                return;
            }
        }

        for (let i = 0; i < images.length; i++) {
            formData.append("file_path", images[i]);
        }

        if (is_label === false) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨 선택 안함",
                text: "라벨 선택을 해주세요",
                afterFunction: null,
            });
            setIsLoading(false);
            return;
        }

        resetCheck();

        if (isImageUpload === true) {
            formData.append("datatype", "upload");
            if (images.length > 500) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이미지 개수 초과",
                    text: "오토라벨링 이미지는 최대 500개 까지만 가능합니다.",
                    afterFunction: null,
                });
                setIsLoading(false);
                return;
            }
        } else {
            formData.append("datatype", "previous");
            if (selectedImages.length > 0) {
                for (let i = 0; i < selectedImages.length; i++) {
                    formData.append("id", selectedImages[i].id);
                }
            }
        }

        await setAutoLabel.mutateAsync(
            {token: Token.data.access, formData: formData},
            {
                onSuccess: res => {
                    if (res.data.msg === 6) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 개수 초과",
                            text: "오토라벨링 이미지는 최대 500개 까지만 가능합니다.",
                            afterFunction: null,
                        });
                        setIsLoading(false);
                        return;
                    }

                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "오토라벨링 예약 완료",
                        text: "오토라벨링 예약이 완료되었습니다.",
                        afterFunction: null,
                    });
                    setIsLoading(false);
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "예약이 존재합니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    setIsLoading(false);
                },
            }
        );
    });

    const clickImageUpload = () => {
        setUploadColor("#E2FF9B");
        setSelectColor("#b1b1b1");
        setIsImageUpload(true);
    };

    const clickSelectImage = () => {
        setUploadColor("#b1b1b1");
        setSelectColor("#E2FF9B");
        setIsImageUpload(false);
    };

    return (
        <>
            <TitleSection>
                <div className="auto-label-beta">
                    <BetaIcon />
                    <CustomText fs="1rem" fw="600" style={{marginLeft: "7px"}}>
                        오토라벨링 βeta
                    </CustomText>
                </div>
                <CustomText fs="0.875rem" cl="#b1b1b1">
                    βeta 버전 이므로 불안정 할 수 있습니다.
                </CustomText>
            </TitleSection>
            <DateSelectWrapper>
                <ModelDropDown modelData={modelData} setModelData={setModelData} />
            </DateSelectWrapper>
            <LabelWrapper>
                <div className="title-section">
                    <CustomText fs="1.125rem" fw="500">
                        라벨 선택
                    </CustomText>
                </div>
                <LabelListWrap>
                    {modelData.label_data !== "" ? (
                        <LabelList>
                            {modelData.label_data.map(info => {
                                return (
                                    <div className="label-check">
                                        <CustomCheckbox
                                            className="labels"
                                            value={info.label_name}
                                            defaultChecked={false}
                                        />
                                        <CustomText fs="0.750rem" fw="400">
                                            {info.label_name}
                                        </CustomText>
                                    </div>
                                );
                            })}
                        </LabelList>
                    ) : (
                        <CustomText fs="0.9rem" fw="400" style={{marginBottom: "20px"}}>
                            모델을 선택해주세요
                        </CustomText>
                    )}
                </LabelListWrap>
            </LabelWrapper>
            <TabWrapper>
                <TabBtn bc={uploadColor} onClick={() => clickImageUpload()}>
                    <AutoLabelIcon style={{marginRight: "10px"}} />
                    <CustomText fs="0.875rem" cl={uploadColor}>
                        이미지 업로드
                    </CustomText>
                </TabBtn>
                <TabBtn bc={selectColor} onClick={() => clickSelectImage()}>
                    <AutoLabelIcon style={{marginRight: "10px"}} />
                    <CustomText fs="0.875rem" cl={selectColor}>
                        기존 이미지
                    </CustomText>
                </TabBtn>
            </TabWrapper>
            <>
                {isImageUpload ? (
                    <AutoLabelingList imageList={imageList} setImageList={setImageList} />
                ) : (
                    <NoLabelList
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}></NoLabelList>
                )}
            </>

            <CustomBtn
                bc="#b2b1b1"
                onClick={() => onClickAutoLabelButton(imageList)}
                style={{marginBottom: "4rem"}}
                loading={isLoading}>
                <AutoLabelIcon style={{marginRight: "10px"}} />
                <CustomText fs="0.875rem" cl="#b1b1b1">
                    오토 라벨링 예약
                </CustomText>
            </CustomBtn>
            <AddDataset modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default AutoLabelMain;
