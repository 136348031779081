import React from "react";
import { Layer, Rect, Line } from "react-konva";

import useStore from "./Store";

function Regions(props) {
    const regions = useStore((s) => s.regions);
    const layerRef = React.useRef(null);

    const selectedId = useStore((s) => s.selectedRigionId);
    const selectRegion = useStore((s) => s.selectRegion);
    const image = useStore((state) => state.image);
    const toggleDrawing = useStore((state) => state.toggleIsDrawing);
    const isMosaic = useStore((state) => state.isMosaic);
    const mosaics = useStore((state) => state.mosaics);
    const setMosaics = useStore((state) => state.setMosaics);
    const width = useStore((state) => state.width);

    let strokeWidth = props.rectWidth;

    const deleteMosaic = (id) => {
        let index = mosaics.findIndex((data) => data.id === id);
        mosaics.splice(index, 1);
        setMosaics(mosaics.concat());
    };
    return (
        <Layer ref={layerRef}>
            <Line points={props.xaxis} stroke="white" strokeWidth={strokeWidth} dash={[strokeWidth, strokeWidth / 2]} />
            <Line points={props.yaxis} stroke="white" strokeWidth={strokeWidth} dash={[strokeWidth, strokeWidth / 2]} />
            {isMosaic === false ? (
                <>
                    {regions.map((region) => {
                        const isSelected = region.id === selectedId;
                        return (
                            <React.Fragment key={region.id}>
                                <Rect
                                    name="region"
                                    {...region.points}
                                    fill={region.color}
                                    opacity={isSelected ? 1 : props.opacity}
                                    stroke="white"
                                    strokeWidth={strokeWidth}
                                    onClick={() => {
                                        selectRegion(region.id);
                                        toggleDrawing(false);
                                        //props.setModalOpen(true);
                                    }}
                                    onTouchStart={() => {
                                        selectRegion(region.id);
                                        toggleDrawing(false);
                                        props.setModalOpen(true);
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </>
            ) : (
                <>
                    {mosaics.map((mosaic) => {
                        return (
                            <React.Fragment key={mosaic.id}>
                                <Rect
                                    name="mosaic"
                                    {...mosaic.points}
                                    fill={"white"}
                                    opacity={0.5}
                                    onClick={() => {
                                        props.setAlertModalOpen({
                                            open: true,
                                            iconType: "warning",
                                            title: "모자이크 지우기",
                                            text: "모자이크 영역 삭제",
                                            afterFunction: null,
                                        });
                                        deleteMosaic(mosaic.id);
                                        //props.setModalOpen(true);
                                    }}
                                    onTouchStart={() => {
                                        props.setAlertModalOpen({
                                            open: true,
                                            iconType: "warning",
                                            title: "모자이크 지우기",
                                            text: "모자이크 영역 삭제",
                                            afterFunction: null,
                                        });
                                        deleteMosaic(mosaic.id);
                                        //props.setModalOpen(true);
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </Layer>
    );
}

export default Regions;
