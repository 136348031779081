import React, { useCallback, useState } from "react";
import { Avatar } from "antd";

import { Colors } from "../../styles/theme/Colors";
import { UserOutlined } from "@ant-design/icons";
import { updateUserInfoAction } from "../../store/reducer/UserInfo";
import { UpdateUserInfo } from "../../services/account-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import AlertModal from "../modal/AlertModal";
import styled from "styled-components";

const UserIcon = styled(UserOutlined)`
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

function ProfileEdit() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { UserInfo, Token } = useSelector((state) => state);
    const location = useLocation();
    const crntType = queryString.parse(location.search).type;

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const profileImageChange = useCallback(async (e) => {
        try {
            const formData = new FormData();

            formData.append("image_name", e.target.files[0].name);
            formData.append("image_path", e.target.files[0]);

            const userRes = await UpdateUserInfo(UserInfo.data.id, formData, Token.data.access);
            dispatch(updateUserInfoAction(userRes.data));
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "프로필 변경 완료",
                text: "프로필 변경이 완료되었습니다.",
                afterFunction: () => {
                    window.location.replace(location.pathname + location.search);
                    // window.location.replace(`/mypage?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`);
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "프로필 변경 오류",
                text: "프로필 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    return (
        <>
            <div style={{ height: "4rem", width: "4rem", marginRight: "2rem" }}>
                <Avatar src={UserInfo.data.image_path ? process.env.REACT_APP_END_POINT + UserInfo.data.image_path : ""} icon={<UserIcon />} style={{ backgroundColor: Colors[UserInfo.data.id % 10], width: "4rem", height: "4rem", display: !UserInfo.data.image_path && "flex", justifyContent: !UserInfo.data.image_path && "space-around", padding: !UserInfo.data.image_path && "0.8rem" }} />
            </div>

            <CustomText style={{ height: "4rem", width: "4rem", display: "flex", alignItems: "flex-end", justifyContent: "center", position: "absolute", cursor: "pointer" }} onClick={() => document.getElementById("profileImageSelect").click()}>
                변경
            </CustomText>
            <input id="profileImageSelect" type="file" accept="image/*" onChange={profileImageChange} style={{ display: "none" }} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <CustomText fs="1.25rem" fw={500}>
                    {UserInfo.data.name}
                </CustomText>
                <div className="rowDiv">
                    <CustomText
                        cl={crntType === "passwordChange" ? "#F5F5F5" : "#9E9E9E"}
                        mg="0px 1.5rem 0px 0px"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate({ search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=passwordChange` });
                        }}
                    >
                        비밀번호 설정
                    </CustomText>
                    <CustomText
                        cl={crntType === "userInfoChange" ? "#F5F5F5" : "#9E9E9E"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate({ search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=userInfoChange` });
                        }}
                    >
                        회원정보 변경
                    </CustomText>
                </div>
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </div>
        </>
    );
}

export default ProfileEdit;
