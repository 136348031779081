import React from "react";
import styled from "styled-components";
import HomeLayout from "../components/layout/HomeLayout";
import { CustomText } from "../styles/theme/CustomStyle";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
    background-color: #161b22;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.div`
    margin-top: 1.3rem;
    width: 90%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 10rem;
`;

const PartWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .bullet {
        font-size: 0.8rem;
        font-family: "Pretendard";
        font-weight: 400;
        margin-top: 2px;
    }

    .secondWrap {
        flex-direction: column;
        margin-left: 20px;
    }

    .thirdWrap {
        flex-direction: column;
        margin-left: 40px;
    }

    div {
        display: flex;
        gap: 5px;
    }
`;

function TermsOfService(props) {
    return (
        <HomeLayout>
            <Wrap>
                <Content>
                    <CustomText fs="2rem" fw="600" wd="100%" style={{ borderBottom: "2px solid #707070" }}>
                        서비스 이용약관
                    </CustomText>
                    <br />
                    <div>
                        <PartWrap>
                            <CustomText className="title">제1조 목적</CustomText>
                            <CustomText>본 약관은 비아(이하 ‘운영기관’라 한다)에서 운영하는 NewLearn 서비스를 이용함에 있어 이용조건 및 절차, 운영기관과 이용자의 권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</CustomText>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제2조 용어의 정의</CustomText>

                            <CustomText>본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의하지 않은 것은 관련 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따른다.</CustomText>
                            <div>
                                <CustomText>·</CustomText>
                                <CustomText>공유서비스 : 공유서비스란 인공지능 서비스에 활용할 수 있는 데이터를 공공 및 민간, 법인 및 개인이 원활하게 활용할 수 있도록 다양한 형식과 방식으로 제공하는 것을 말한다.</CustomText>
                            </div>
                            <div>
                                <CustomText>·</CustomText>
                                <CustomText>제공기관 : 제공기관은 기관이 보유하고 있는 우수한 정보자원을 지정된 관리시스템을 통해 활용 가능한 공유서비스의 방식으로 개방, 제공하는 행정기관, 공공기 및 민간 개인과 법인 등을 말한다.</CustomText>
                            </div>
                            <div>
                                <CustomText>·</CustomText>
                                <CustomText>활용기관 : 활용기관은 제공된 공유서비스를 직접 이용하거나 새로운 창작물의 일부로 재가공하여 배포하는 행정기관 등과 개인 및 법인을 말한다.</CustomText>
                            </div>
                            <div>
                                <CustomText>·</CustomText>
                                <CustomText>회원 : NewLearn에 기관 및 기업, 개인 정보를 제공하여 제공기관 또는 활용기관으로 등록한 자로서 NewLearn에서 제공하는 서비스를 이용할 수 있는 자.</CustomText>
                            </div>
                            <div>
                                <CustomText>·</CustomText>
                                <CustomText>비밀번호 : 이용자와 회원ID가 일치하는지 확인하고 통신상의 자신의 비밀 보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제3조 약관의 효력과 변경
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn은 귀하가 본 약관 내용에 동의하는 경우, NewLearn의 서비스 제공 행위 및 귀하의 서비스 사용 행위에 본 약관이 우선적으로 적용됩니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>운영기관은 본 약관을 약관법 등 관련 법령에 위배되지 않는 범위 안에서 사전 고지 없이 변경할 수 있고, 변경된 약관은 NewLearn 내에 공지와 동시에 그 효력이 발생됩니다. 회원이 변경된 약관에 동의하지 않는 경우, 회원은 본인의 회원등록을 취소(회원탈퇴)할 수 있으며 계속 사용의 경우는 약관 변경에 대한 동의로 간주됩니다.</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제4조 약관외 준칙
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <CustomText>본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신에 관한 심의규정, 저작권법및 기타 관련 법령의 규정에 의합니다.</CustomText>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제5조 이용 계약의 성립
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>이용 계약은 법인 및 개인 신청자가 온라인으로 NewLearn에서 제공하는 소정의 회원가입 신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 다음 각 호에 해당하는 이용계약에 대하여는 회원 가입을 취소할 수 있습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <div>
                                    <CustomText>1)</CustomText>
                                    <CustomText>다른 사람의 명의를 사용하여 신청하였을 때</CustomText>
                                </div>
                                <div>
                                    <CustomText>2)</CustomText>
                                    <CustomText>회원 가입 신청서의 내용을 허위로 기재하였을 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>3)</CustomText>
                                    <CustomText>NewLearn을 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>4)</CustomText>
                                    <CustomText>기타 운영기관에서 정한 제공기관 및 활용기관에 대한 공유정책 및 업무처리 기준에 위배된 경우</CustomText>
                                </div>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제6조 회원정보 사용에 대한 동의
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>회원의 개인정보는 개인정보법에 의해 보호됩니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn의 회원정보는 다음과 같이 사용, 관리, 보호됩니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <div>
                                    <CustomText>1)</CustomText>
                                    <CustomText>
                                        개인정보의 사용 : NewLearn은 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 방송통신심의위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 귀하가 NewLearn에 제공한
                                        개인정보를 스스로 공개한 경우에는 그러하지 않습니다.
                                    </CustomText>
                                </div>
                                <div>
                                    <CustomText>2)</CustomText>
                                    <CustomText>개인정보의 관리 : 귀하는 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서 수시로 귀하의 개인정보를 수정/삭제할 수 있습니다.</CustomText>
                                </div>
                                <div>
                                    <CustomText>3)</CustomText>
                                    <CustomText>개인정보의 보호 : 귀하의 개인정보는 오직 귀하만이 열람/수정/삭제 할 수 있으며, 이는 전적으로 귀하의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 본인의 ID와 비밀번호를 알려주어서는 안되며, 작업 종료시에는 반드시 로그아웃 해 주시기 바랍니다.</CustomText>
                                </div>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>회원이 본 약관에 따라 이용신청을 하는 것은 NewLearn 회원가입 신청서에 기재된 회원정보를 ‘개인정보 취급방침’에 따라 수집, 이용하는 것에 동의하는 것으로 간주됩니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제7조 사용자의 정보 보안
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>가입 신청자가 NewLearn 회원가입 절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있으며, 회원의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용 되었다는 사실을 발견한 경우에는 즉시 운영기관에 신고하여야 합니다. 신고를 하지 않음으로 인한 모든 책임은 회원 본인에게 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>회원은 NewLearn 사용 종료 시마다 정확히 접속을 종료해야 하며, 정확히 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 운영기관은 책임을 부담하지 아니합니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제8조 포털 이용 시간
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>서비스 이용시간은 당 사이트의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>제1항의 이용시간은 정기점검 등의 필요로 인하여 당 사이트가 정한 날 또는 시간은 예외로 합니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">
                                제9조 포털의 중지 및 정보의 저장과 사용
                                {/* <br />
                                <br /> */}
                            </CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, NewLearn의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 운영기관은 관련 책임을 부담하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>
                                    NewLearn의 정상적인 서비스 제공이 불가하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전의 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 귀하가 고지내용을 인지하지 못한 데 대하여 운영기관은 책임을 부담하지 아니합니다. 부득이한 사정이 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에
                                    보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 운영기관은 책임을 부담하지 아니합니다.
                                </CustomText>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>NewLearn의 사정으로 서비스를 영구적으로 중단하여야 할 경우 제 2 항에 의거합니다. 다만, 이 경우 사전 고지기간은 1개월로 합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>4.</CustomText>
                                <CustomText>NewLearn은 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 귀하 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>5.</CustomText>
                                <CustomText>NewLearn은 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의적인 회원의 서비스 이용 중지시(회원등록 말소시) 회원에게 이 사실을 통지하고, 회원으로 하여금 소명할 수 있는 기회를 부여하고 소명기간(30일 이상)을 추가 표기합니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제10조 서비스의 변경 및 해지</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn은 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 서비스 이용과 관련하여 회원에게 발생한 손해 중 회원의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제11조 정보 제공 및 홍보물 게제</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn은 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 서비스에 적절하다고 판단되거나 활용 가능성 있는 홍보물을 게재할 수 있습니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제12조 게시물의 저작권</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>제공기관이 게시한 게시물의 내용에 대한 권리는 기본적으로 제공기관 본인에게 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <div>
                                    <CustomText>1)</CustomText>
                                    <CustomText>본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>2)</CustomText>
                                    <CustomText>다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>3)</CustomText>
                                    <CustomText>공공질서 및 미풍양속에 위반되는 내용인 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>4)</CustomText>
                                    <CustomText>범죄적 행위에 결부된다고 인정되는 내용일 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>5)</CustomText>
                                    <CustomText>제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</CustomText>
                                </div>
                                <div>
                                    <CustomText>6)</CustomText>
                                    <CustomText>기타 관계 법령에 위배되는 경우</CustomText>
                                </div>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>제공기관의 게시물이 타인의 저작권을 비롯한 기타 타인의 권리를 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 제공기관이 부담하여야 합니다.</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제13조 사용자의 행동규범 및 서비스의 이용제한</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할 경우 NewLearn은 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>회원이 NewLearn의 서비스를 통해 게시, 전송, 입수하였거나 전자메일 기타 다른 수단에 의하여 게시, 전송 또는 입수한 모든 형태의 정보에 대하여는 회원이 모든 책임을 부담하며 NewLearn은 어떠한 책임도 부담하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>NewLearn은 당 사이트가 제공한 서비스가 아닌 가입자 또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서 NewLearn은 회원이 위 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>4.</CustomText>
                                <CustomText>회원은 NewLearn에서 다운로드 받은 데이터 내에 개인정보 또는 개인정보로 우려되는 정보가 포함된 것을 발견한 경우 즉시 NewLearn 운영자에 신고하고 해당 데이터셋은 삭제를 하여야 합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>5.</CustomText>
                                <CustomText>회원은 NewLearn로부터 제공받은 비식별 정보를 인공지능 서비스 개발 등의 목적으로 안전하게 이용하여야 하며, 이를 이용해서 개인을 재식별하기 위한 어떠한 행위도 하여서는 안됩니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>6.</CustomText>
                                <CustomText>회원은 본 서비스를 통하여 다음과 같은 행동을 포함하여 서비스 운영정책에서 금지하는 행위를 하지 않는 것에 동의합니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <div>
                                    <CustomText>1)</CustomText>
                                    <CustomText>타인의 아이디(ID)와 비밀번호를 도용하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>2)</CustomText>
                                    <CustomText>저속, 음란, 모욕적, 위협적이거나 타인의 프라이버시를 침해할 수 있는 내용을 전송, 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>3)</CustomText>
                                    <CustomText>서비스를 통하여 전송된 내용의 출처를 위장하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>4)</CustomText>
                                    <CustomText>법률, 계약에 의하여 이용할 수 없는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>5)</CustomText>
                                    <CustomText>타인의 특허, 상표, 영업비밀, 저작권, 기타 지식재산권을 침해하는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>6)</CustomText>
                                    <CustomText>운영기관의 승인을 받지 아니한 광고, 판촉물, 정크메일, 스팸, 행운의 편지, 피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위.</CustomText>
                                </div>
                                <div>
                                    <CustomText>7)</CustomText>
                                    <CustomText>다른 사용자의 개인정보를 수집 또는 저장하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>8)</CustomText>
                                    <CustomText>다른 사용자의 NewLearn 서비스 이용을 방해하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>9)</CustomText>
                                    <CustomText>의도적으로 바이러스 프로그램 등 파괴적이거나 시스템에 손상을 줄 수 있는 아이템을 배포하는 행위</CustomText>
                                </div>
                            </div>
                            <CustomText>7. NewLearn은 회원이 본 약관을 위배했다고 판단되면 서비스와 관련된 모든 정보를 회원의 동의 없이 삭제할 수 있습니다.</CustomText>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제14조 포털의 의무</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn은 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적, 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 회원의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>NewLearn은 이용자가 안전하게 당 사이트 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추어야 합니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>4.</CustomText>
                                <CustomText>NewLearn은 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.</CustomText>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제15조 회원의 의무</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>회원 가입시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 회원 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>회원은 NewLearn의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>3.</CustomText>
                                <CustomText>회원은 일부 개인정보를 포함한 서비스 중 사전 승낙이 필요함을 별도로 명시한 경우 사전 승낙 없이 NewLearn의 서비스를 이용하여 얻은 정보를 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>4.</CustomText>
                                <CustomText>회원은 NewLearn의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.</CustomText>
                            </div>
                            <div className="secondWrap">
                                <div>
                                    <CustomText>1)</CustomText>
                                    <CustomText>다른 회원의 ID를 부정 사용하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>2)</CustomText>
                                    <CustomText>범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>3)</CustomText>
                                    <CustomText>선량한 풍속, 기타 사회질서를 해하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>4)</CustomText>
                                    <CustomText>타인의 명예를 훼손하거나 모욕하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>5)</CustomText>
                                    <CustomText>타인의 지식재산권 등의 권리를 침해하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>6)</CustomText>
                                    <CustomText>해킹행위 또는 컴퓨터바이러스의 유포행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>7)</CustomText>
                                    <CustomText>타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>8)</CustomText>
                                    <CustomText>서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</CustomText>
                                </div>
                                <div>
                                    <CustomText>9)</CustomText>
                                    <CustomText>NewLearn에 게시된 정보의 변경</CustomText>
                                </div>
                            </div>
                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제16조 포털의 소유권</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn이 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지식재산권 및 기타 권리는 운영기관(및 NewLearn 서비스 제공과 관련하여 운영기관과 계약을 체결한 기관)에 소유권이 있습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>귀하는 NewLearn에서 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제17조 양도금지</CustomText>
                            <CustomText>회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다. 단, 법인회원의 대표담당자 변경은 예외로 합니다.</CustomText>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제18조 손해조항</CustomText>
                            <CustomText>NewLearn은 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 NewLearn이 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니합니다.</CustomText>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제19조 면책조항</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>NewLearn은 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. NewLearn은 어떠한 경우라도 회원이 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.</CustomText>
                            </div>
                            <div>
                                <CustomText>2.</CustomText>
                                <CustomText>NewLearn은 회원간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.</CustomText>
                            </div>

                            <br />
                            <br />
                        </PartWrap>
                        <PartWrap>
                            <CustomText className="title">제20조 관할법원</CustomText>
                            <CustomText>본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우 서울중앙지방법원을 전속적 관할 법원으로 합니다.</CustomText>
                            <div>
                                <CustomText>1.</CustomText>
                                <CustomText>(시행일) 본 약관은 2018년 1월 1일부터 시행됩니다.</CustomText>
                            </div>
                        </PartWrap>
                    </div>
                </Content>
            </Wrap>
        </HomeLayout>
    );
}

export default TermsOfService;
