import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import Lottie from "react-lottie";

import * as TrainingData from "../../../assets/project/model-train/Training.json";
import * as TrainingData2 from "../../../assets/project/model-train/Training2.json";
import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";
import { GetCurrentTrain } from "../../../services/train-services";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import AlertModal from "../../modal/AlertModal";

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #575757;
    padding-bottom: 10px;

    /* height: 2.5rem; */
`;

const TrainInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 #575757;
    padding: 15px 0;
    /* height: 8.5rem; */
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const NoTrainModelWrap = styled.div`
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 -2px 0 0 #575757;
    padding-bottom: 20px;
    height: 8.5rem;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
const NoTrainModel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #21272e;
    padding: 30px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
const MobileTrainInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 #575757;
    padding: 10px 0;

    .model-name {
        display: flex;
        margin: 5px 0;
        align-items: center;
    }
    .dataset {
        display: flex;
        flex-direction: row;
    }

    .training-parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin: 3px 0;
    }
    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const TrainIconSection = styled.div`
    width: 8.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TrainInfoDetail = styled.div`
    flex: 1;
    padding-left: 15px;

    .first-line {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-right: 15px;
    }
    .second-line {
        margin: 10px 0 15.2px 0;
        width: 100%;
    }

    .third-line {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 100%;
        justify-content: space-between;
    }

    .dataset {
        display: flex;
        flex-direction: row;
    }

    .training-parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
    }
`;
const ProgressWrap = styled.div`
    margin-top: 15px;
    /* margin-left: 15px; */
    background-color: #303a45;

    @media screen and (max-width: 1024px) {
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

const CustomProgress = styled.div`
    background-color: #bfffa2;
    width: ${(props) => props.wd + "%"};
    height: 100%;
    @media screen and (max-width: 1024px) {
        height: 2rem;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;

    padding: 0 50px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${(props) => props.bc} !important;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }

    @media screen and (max-width: 1024px) {
        padding: 10px;
        margin-bottom: 10px;
    }
`;

function TrainState({ isGraph, setIsGraph }) {
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const lottieDefauiltOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: TrainingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const { data: TrainSchdule, isLoading: loading } = useQuery(
        ["project", "train"],
        async (e) => {
            const data = await GetCurrentTrain();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <>
            {TrainSchdule !== undefined && TrainSchdule.data.length !== 0 ? (
                <>
                    <TitleSection>
                        <CustomText fs="1.125rem" fw="600">
                            진행중 학습
                        </CustomText>
                    </TitleSection>
                    <TrainInfoSection>
                        <div style={{ display: "flex" }}>
                            <TrainIconSection>
                                <Lottie options={lottieDefauiltOptions} width="8rem" height="6rem" />
                            </TrainIconSection>
                            <TrainInfoDetail>
                                <div className="first-line">
                                    <div className="title">
                                        {/* <CustomText fs="1rem" fw="500">
                                        {TrainSchdule.data[0].data_set.project.title}
                                    </CustomText>
                                    <span style={{ fontWeight: 100, color: "#707070", margin: "0 2rem" }}>|</span>
                                    <CustomText fs="1rem" fw="500" style={{ marginRight: "1rem" }}>
                                        {TrainSchdule.data[0].model_name}
                                    </CustomText> */}
                                        <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                            <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                                {TrainSchdule.data[0].training_algorithm.algorithm_name}
                                            </CustomText>
                                        </CircleText>
                                    </div>
                                    {/* {isGraph === false ? (
                                    <CustomBtn bc="#b1b1b1" onClick={() => setIsGraph(true)}>
                                        <GraphIcon style={{ marginRight: "5px", paddingRight: "5px" }} />
                                        <CustomText fs="0.875rem" cl="#b1b1b1">
                                            학습 그래프
                                        </CustomText>
                                    </CustomBtn>
                                ) : (
                                    <CustomBtn bc="#b1b1b1" onClick={() => setIsGraph(false)}>
                                        <GraphIcon style={{ marginRight: "5px", paddingRight: "5px" }} />
                                        <CustomText fs="0.875rem" cl="#b1b1b1">
                                            그래프 닫기
                                        </CustomText>
                                    </CustomBtn>
                                )} */}
                                </div>
                                <div className="second-line">
                                    {TrainSchdule.data[0].type === "train" ? (
                                        <>
                                            <CustomText fs="1.125rem" cl="#fff">
                                                학습 시작일:
                                            </CustomText>
                                            <CustomText fs="1.125rem" cl="#fff" fw={500} style={{ lineHeight: "1.3rem" }}>
                                                {dayjs(TrainSchdule.data[0].create_at).locale("ko").format("YYYY.MM.DD A HH:mm")}
                                            </CustomText>
                                        </>
                                    ) : (
                                        <>
                                            <CustomText fs="1.125rem" cl="#fff">
                                                오토라벨링 시작일:
                                            </CustomText>
                                            <CustomText fs="1.125rem" cl="#fff" fw={500} style={{ lineHeight: "1.3rem" }}>
                                                {dayjs(TrainSchdule.data[0].create_at).locale("ko").format("YYYY.MM.DD A HH:mm")}
                                            </CustomText>
                                        </>
                                    )}
                                </div>
                                <div className="third-line">
                                    {/* <div className="dataset">
                                    <CustomText fs="0.9rem" fw="400">
                                        학습데이터
                                    </CustomText>
                                    <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}></span>
                                    <CustomText fs="0.9rem" fw="400" style={{ marginRight: "1rem" }}>
                                        {TrainSchdule.data[0].data_set.data_name}
                                    </CustomText>
                                </div> */}
                                    {TrainSchdule.data[0].type === "train" && (
                                        <div className="training-parameter">
                                            <CustomText fs="0.875rem" fw="400">
                                                에포크 <span style={{ fontWeight: 600, marginLeft: 3 }}>{TrainSchdule.data[0].epoch}</span>
                                            </CustomText>
                                            <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                            <CustomText fs="0.875rem" fw="400">
                                                배치사이즈 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].batch_size}</span>
                                            </CustomText>
                                            <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                            <CustomText fs="0.875rem" fw="400">
                                                학습률 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].learning_rate}</span>
                                            </CustomText>
                                        </div>
                                    )}
                                </div>
                            </TrainInfoDetail>
                        </div>

                        <ProgressWrap>
                            <CustomProgress wd={(TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100}>
                                {(TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100 === 0 ? (
                                    <CustomText fs="0.8rem" fw="500" cl="#fff" style={{ padding: "5px", paddingLeft: "10px" }}>
                                        {(TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100}%
                                    </CustomText>
                                ) : (
                                    <CustomText fs="0.8rem" fw="500" cl="#000000" style={{ padding: "5px", paddingLeft: "10px" }}>
                                        {Math.floor((TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100)}%
                                    </CustomText>
                                )}
                            </CustomProgress>
                        </ProgressWrap>
                    </TrainInfoSection>
                    <MobileTrainInfoSection>
                        {/* <div className="title">
                            <CustomText fs="1rem" fw="500">
                                {TrainSchdule.data[0].data_set.project.title}
                            </CustomText>
                        </div> */}
                        <div className="model-name">
                            {/* <CustomText fs="1rem" fw="500" style={{ marginRight: "1rem" }}>
                                {TrainSchdule.data[0].model_name}
                            </CustomText> */}
                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                    {TrainSchdule.data[0].training_algorithm.algorithm_name}
                                </CustomText>
                            </CircleText>
                        </div>
                        {/* {isGraph === false ? (
                            <CustomBtn bc="#b1b1b1" onClick={() => setIsGraph(true)}>
                                <GraphIcon style={{ marginRight: "5px", paddingRight: "5px" }} />
                                <CustomText fs="0.875rem" cl="#b1b1b1">
                                    학습그래프
                                </CustomText>
                            </CustomBtn>
                        ) : (
                            <CustomBtn bc="#b1b1b1" onClick={() => setIsGraph(false)}>
                                <GraphIcon style={{ marginRight: "5px", paddingRight: "5px" }} />
                                <CustomText fs="0.875rem" cl="#b1b1b1">
                                    그래프 닫기
                                </CustomText>
                            </CustomBtn>
                        )} */}
                        <ProgressWrap>
                            <CustomProgress wd={(TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100}>
                                {(TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100 === 0 ? (
                                    <CustomText fs="0.8rem" fw="500" cl="#fff" style={{ padding: "5px", paddingLeft: "10px" }}>
                                        {Math.floor((TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100)}%
                                    </CustomText>
                                ) : (
                                    <CustomText fs="0.8rem" fw="500" cl="#000000" style={{ padding: "5px", paddingLeft: "10px" }}>
                                        {Math.floor((TrainSchdule.data[0].current_epoch / TrainSchdule.data[0].epoch) * 100)}%
                                    </CustomText>
                                )}
                            </CustomProgress>
                        </ProgressWrap>
                        {/* <div className="dataset">
                            <CustomText fs="0.9rem" fw="400">
                                학습데이터
                            </CustomText>
                            <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}></span>
                            <CustomText fs="0.9rem" fw="400" style={{ marginRight: "1rem" }}></CustomText>
                        </div> */}
                        <div className="training-parameter">
                            <CustomText fs="0.875rem" fw="400">
                                에포크 <span style={{ fontWeight: 600, marginLeft: 3 }}>{TrainSchdule.data[0].epoch}</span>
                            </CustomText>
                            <span style={{ fontWeight: 100, color: "#707070" }}>|</span>
                            <CustomText fs="0.875rem" fw="400">
                                배치사이즈 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].batch_size}</span>
                            </CustomText>
                            <span style={{ fontWeight: 100, color: "#707070" }}>|</span>
                            <CustomText fs="0.875rem" fw="400">
                                학습률 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].learning_rate}</span>
                            </CustomText>
                        </div>
                    </MobileTrainInfoSection>
                </>
            ) : (
                <>
                    <TitleSection>
                        <CustomText fs="1.125rem" fw="600">
                            진행중 학습
                        </CustomText>
                    </TitleSection>
                    <TrainInfoSection>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "5rem 0", backgroundColor: "#21272E" }}>
                            <CustomText>진행중인 학습이 존재하지 않습니다</CustomText>
                            <CustomText>예약을 통해 학습을 바로 진행할 수 있습니다</CustomText>
                        </div>
                    </TrainInfoSection>
                    <MobileTrainInfoSection>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "5rem 0", backgroundColor: "#21272E" }}>
                            <CustomText>진행중인 학습이 존재하지 않습니다</CustomText>
                            <CustomText>예약을 통해 학습을 바로 진행할 수 있습니다</CustomText>
                        </div>
                    </MobileTrainInfoSection>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default TrainState;
