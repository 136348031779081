import React from "react";
import styled from "styled-components";
import { Modal, Button } from "antd";
import { ReactComponent as LabelIcon } from "../../assets/modal/label-modal-icon.svg";
import { ReactComponent as InputIcon } from "../../assets/modal/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/modal/check.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useCallback } from "react";
import { useMutation } from "react-query";
import { SetLabel } from "../../services/labeling-services";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";
import useStore from "../../components/annotator/Store";

const CustomModal = styled(Modal)`
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #21272e;
        border: 1px solid #707070;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
    }

    .ant-modal-body {
        width: 100%;
    }
    width: 70%;
    @media screen and (max-width: 1024px) {
        width: 80%;
    }

    .complete {
        width: 100%;
        margin-top: 30px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #383838;
        border: none;
        cursor: pointer;

        :hover {
            background-color: rgba(0, 0, 0, 0.3);
            transition: background-color 300ms;
        }
    }

    .delete {
        width: 100%;
        margin-top: 30px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #383838;
        border: none;
        cursor: pointer;
        margin-left: 10px;
        :hover {
            background-color: rgba(0, 0, 0, 0.3);
            transition: background-color 300ms;
        }
    }
`;

const InputBox = styled.div`
    background-color: #21272e;
    width: 78%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 70%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }

    border-bottom: 1px dashed #b1b1b1;
`;

const CreateButton = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${(props) => props.bc} !important;
    padding: 1px 20px 1px 20px;
    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

function CreatLabelModal({ isOpen, setIsOpen, getLabelList }) {
    const [labelName, setLabelName] = useState("");
    const { Token, SelectedDataset } = useSelector((state) => state);
    const setLabel = useMutation(SetLabel);
    const labelModal = useStore((state) => state.labelModal);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const setLabelData = useCallback(async (labelName) => {
        const formData = new FormData();
        formData.append("data_set", SelectedDataset.data.id);
        formData.append("annotation_type", SelectedDataset.data.annotation_type);
        formData.append("label_name", labelName);
        let pattenr_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자

        if (pattenr_kor.test(labelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "라벨 이름 오류",
                text: "한글은 등록할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (pattern_spc.test(labelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "라벨 이름 오류",
                text: "특수 문자를 제거 해주세요.",
                afterFunction: null,
            });
            return;
        }

        await setLabel.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onSuccess: (res) => {
                    setIsOpen(false);
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "라벨 생성 완료",
                        text: "라벨 생성이 완료되었습니다.",
                        afterFunction: () => {
                            getLabelList(SelectedDataset.data);
                            setLabelName("");
                        },
                    });
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    setLabelName("");
                },
            }
        );
    }, []);

    return (
        <>
            <CustomModal visible={isOpen} onCancel={() => setIsOpen(false)} closeIcon={<CloseOutlined style={{ color: "#fff" }} />}>
                <div style={{ color: "#fff", textAlign: "center", marginBottom: "15px" }}>
                    <LabelIcon />
                </div>
                <CustomText fs="1.1rem" wd="100%" fw="600" ta="center" mg="0 0 5px 0">
                    라벨 생성
                </CustomText>
                <CustomText fs="0.9rem" wd="100%" fw="400" ta="center" cl="#D4D4D4" mg="0 0 5px 0">
                    생성하고자 하는 라벨명을 입력하세요 <br /> (영문 입력)
                </CustomText>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <InputBox>
                        {labelName === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input
                            name="label_name"
                            onFocus={(e) => setLabelName(e.target.value)}
                            value={labelName}
                            onChange={(e) => {
                                setLabelName(e.target.value);
                            }}
                            autoComplete="off"
                            autoFocus
                        />
                    </InputBox>
                    <CreateButton bc={labelName === "" ? "#B1B1B1" : "#E2FF9B"} onClick={() => setLabelData(labelName)}>
                        <CustomText cl={labelName === "" ? "#B1B1B1" : "#E2FF9B"}>생성</CustomText>
                    </CreateButton>
                </div>
            </CustomModal>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default CreatLabelModal;
