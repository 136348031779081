import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";

import { useSelector } from "react-redux";

import { Button } from "antd";
import { UserPasswordChange, UserPasswordMatch } from "../../services/account-services";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { ReactComponent as PasswordCloseIcon } from "../../assets/signup/passwordclose.svg";
import { ReactComponent as PasswordOpenIcon } from "../../assets/signup/passwordopen.svg";
import { useNavigate } from "react-router-dom";
import ProfileEdit from "../../components/mypage/ProfileEdit";
import AlertModal from "../../components/modal/AlertModal";
import { useMutation } from "react-query";
import { GetToken } from "../../services/auth-services";

const Wrap = styled.div`
    flex: 1;
    min-height: 74.75vh;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 6rem;

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        width: 30rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 60.8vh;
    }
`;

const MiddleFirstSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 1.5rem 2rem;
    background-color: #21272e;
    display: flex;

    @media screen and (max-width: 1024px) {
        padding: 0.8rem 2rem;
    }

    .rowDiv {
        display: flex;
    }
`;

const MiddleSecondSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 2.2rem 2rem;
    background-color: #21272e;

    @media screen and (max-width: 1024px) {
        padding: 2rem 2rem;
    }

    .infoRowTop {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .info {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
    }
`;

const InputBox = styled.div`
    background-color: #21272e;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 30px;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.25rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }

    /* ${(props) =>
        props.inputFocus
            ? css`
                  border-bottom: 1px dashed #b1b1b1;
              `
            : css`
                  border-bottom: none;
                  margin: 5.5px 0px;
              `} */
`;

const NextButton = styled(Button)`
    background-color: #161b22 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #e2ff9b !important;
    height: 100%;

    ${(props) =>
        props.nextFoucs
            ? css`
                  border-color: #e2ff9b !important;
                  .btn-text {
                      color: #e2ff9b;
                  }
              `
            : css`
                  border-color: #b1b1b1 !important;
                  .btn-text {
                      color: #b1b1b1;
                  }
              `}

    ${(props) =>
        props.inputFocus
            ? css`
                  display: flex;
              `
            : css`
                  display: none;
              `}
`;

const CompleteButton = styled(Button)`
    background-color: #161b22 !important;
    margin-top: 1.2rem;
    border-radius: 5px;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.5rem;
    border-color: #70726e !important;
`;

function EditPassword() {
    const { UserInfo, Token } = useSelector((state) => state);
    const navigate = useNavigate();
    const [currentPasswordType, setCurrentPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [checkPasswordType, setCheckPasswordType] = useState("password");
    const [editComplete, setEditComplete] = useState(false);
    const [currentPasswordMatch, setCurrentPasswordMatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [currentPwErrMsg, setCurrentPwErrMsg] = useState("");
    const [newPwErrMsg, setNewPwErrMsg] = useState("");
    const [checkPwErrMsg, setCheckPwErrMsg] = useState("");
    const [editCheck, setEditCheck] = useState({ current: false, new: false, check: false });

    const [editPassword, setEditPassword] = useState({ currentPassword: "", changePassword: "", checkPassword: "" });
    let passwordPattern = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/i;
    const [inputFocus, setInputFocus] = useState("currentPassword");
    const [inputShow, setInputShow] = useState({
        currentPassword: true,
        changePassword: false,
        checkPassword: false,
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const InputsChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "currentPassword") {
                setCurrentPasswordMatch(true);
                setEditCheck((prev) => ({
                    ...prev,
                    ["current"]: false,
                }));
            } else if (name === "changePassword") {
                setEditCheck((prev) => ({
                    ...prev,
                    ["new"]: false,
                }));
            } else {
                setEditCheck((prev) => ({
                    ...prev,
                    ["check"]: false,
                }));
            }

            setEditPassword((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [editPassword]
    );

    const passwordShow = useCallback((passwordType) => {
        if (passwordType === "current") {
            if (currentPasswordType === "password") {
                setCurrentPasswordType("text");
            } else {
                setCurrentPasswordType("password");
            }
        } else if (passwordType === "new") {
            if (newPasswordType === "password") {
                setNewPasswordType("text");
            } else {
                setNewPasswordType("password");
            }
        } else {
            if (checkPasswordType === "password") {
                setCheckPasswordType("text");
            } else {
                setCheckPasswordType("password");
            }
        }
    });

    useEffect(() => {
        if ((editCheck.current === false) | (editCheck.new === false) | (editCheck.check === false)) {
            if (editPassword.currentPassword === "") {
                setCurrentPwErrMsg("현재 비밀번호를 입력해주세요.");
                setEditComplete(false);
                setCurrentPasswordMatch(false);
                // } (currentPasswordMatch === false) {
                //     setCurrentPwErrMsg("현재 비밀번호와 일치하지 않습니다.");
                //     setEditComplete(false);
            } else {
                setCurrentPwErrMsg("");

                setEditCheck((prev) => ({
                    ...prev,
                    ["current"]: true,
                }));
            }

            if (editPassword.changePassword === "") {
                setNewPwErrMsg("변경 비밀번호를 입력해주세요.");
                setEditComplete(false);
            } else if (passwordPattern.test(editPassword.changePassword) === false) {
                setNewPwErrMsg("비밀번호는 영문자, 숫자, 특수문자를 포함하여 8자리 이상 작성해주세요.");
                setEditComplete(false);
            } else {
                setNewPwErrMsg("");

                setEditCheck((prev) => ({
                    ...prev,
                    ["new"]: true,
                }));
            }

            if (editPassword.checkPassword === "") {
                setCheckPwErrMsg("비밀번호를 확인해주세요.");
                setEditComplete(false);
            } else if (editPassword.changePassword !== editPassword.checkPassword) {
                setCheckPwErrMsg("비밀번호가 일치하지 않습니다.");
                setEditComplete(false);
            } else {
                setCheckPwErrMsg("");

                setEditCheck((prev) => ({
                    ...prev,
                    ["check"]: true,
                }));
            }
        }
    }, [editPassword]);

    useEffect(() => {
        if ((editCheck.current === true) & (editCheck.new === true) & (editCheck.check === true)) {
            setEditComplete(true);
        }
    }, [editCheck]);

    useEffect(() => {
        setInputShow((prev) => ({ ...prev, [inputFocus]: true }));
        // setPasswordType("password");
    }, [inputFocus]);

    // useEffect(() => {
    //     UserPasswordMatchCheck();
    // }, [editPassword.currentPassword]);

    const tokenMutation = useMutation(GetToken);

    const UserPasswordMatchCheck = useCallback(async () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append("login_id", UserInfo.data.login_id);
        formData.append("password", editPassword.currentPassword);

        await tokenMutation.mutateAsync(formData, {
            onSuccess: (data) => {
                setCurrentPasswordMatch(true);
                setCurrentPwErrMsg("");
                editCompleteOnClick();
            },
            onError: (error) => {
                setIsLoading(false);
                if (editPassword.currentPassword === "") {
                    setCurrentPwErrMsg("현재 비밀번호를 입력해주세요.");
                } else {
                    setEditComplete(false);
                    setCurrentPwErrMsg("현재 비밀번호와 일치하지 않습니다.");
                }
                setCurrentPasswordMatch(false);
            },
        });

        // try {
        //     const data = await UserPasswordMatch(UserInfo.data.login_id, editPassword.currentPassword, Token.data.access);
        //     setCurrentPasswordMatch(true);
        //     setCurrentPwErrMsg("");
        // } catch (err) {
        //     if (editPassword.currentPassword === "") {
        //         setCurrentPwErrMsg("현재 비밀번호를 입력해주세요.");
        //     } else {
        //         setCurrentPwErrMsg("현재 비밀번호와 일치하지 않습니다.");
        //     }
        //     setCurrentPasswordMatch(false);
        // }
    });

    const editCompleteOnClick = useCallback(async () => {
        try {
            await UserPasswordChange(UserInfo.data.login_id, { currentPassword: editPassword.currentPassword, changePassword: editPassword.changePassword }, Token.data.access);
            // goMyPageMain();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "비밀번호 변경 완료",
                text: "비밀번호 변경이 완료되었습니다.",
                afterFunction: () => {
                    window.location.replace(`/mypage?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`);
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "비밀번호 변경 오류",
                text: "비밀번호 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        setIsLoading(false);
    });

    const onKeyPress = useCallback((e) => {
        if (e.key === "Enter") {
            if (e.target.name === "currentPassword") {
                if ((editPassword.currentPassword !== "") & (currentPasswordMatch === true)) {
                    setInputFocus("changePassword");
                }
            } else if (e.target.name === "changePassword") {
                if ((editPassword.changePassword !== "") & (passwordPattern.test(editPassword.changePassword) === true)) {
                    setInputFocus("checkPassword");
                }
            } else {
                if ((editPassword.checkPassword !== "") & (editPassword.changePassword === editPassword.checkPassword)) {
                    setInputFocus("0");
                    setEditComplete(true);
                }
            }
        }
    });

    const goMyPageMain = useCallback(() => {
        navigate({ search: `?user=${UserInfo.data.login_id}&tab=memberInfo&type=info` });
    });

    return (
        <Wrap>
            <div className="bottomMargin">
                <MiddleFirstSection>
                    <ProfileEdit />
                </MiddleFirstSection>
                <MiddleSecondSection>
                    <div className="infoRowTop">
                        {/* {inputShow.currentPassword && ( */}
                        <div>
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    현재 비밀번호
                                </CustomText>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <InputBox inputFocus={inputFocus === "currentPassword" ? true : false}>
                                        {/* {inputFocus === "currentPassword" ? (
                                        <> */}
                                        {currentPasswordMatch === false ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                        <div style={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
                                            <input type={currentPasswordType} name="currentPassword" value={editPassword.currentPassword} onChange={InputsChange} onKeyPress={onKeyPress} autoComplete="off" />
                                            {currentPasswordType === "password" ? (
                                                <PasswordCloseIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("current");
                                                    }}
                                                />
                                            ) : (
                                                <PasswordOpenIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("current");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {/* </>
                                    ) : (
                                        <>
                                            <CheckIcon className="prefix" />
                                            <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                {"•".repeat(editPassword.currentPassword.length)}
                                            </CustomText>
                                        </>
                                    )} */}
                                    </InputBox>
                                    {/* <NextButton
                                    disabled={(editPassword.currentPassword === "") | (currentPasswordMatch === false) ? true : false}
                                    onClick={() => {
                                        setInputFocus("changePassword");
                                    }}
                                    nextFoucs={(editPassword.currentPassword === "") | (currentPasswordMatch === false) ? false : true}
                                    inputFocus={inputFocus === "currentPassword" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                                </div>
                            </div>
                            {currentPwErrMsg !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {currentPwErrMsg}
                                </CustomText>
                            )}
                        </div>

                        <div>
                            {/* )} */}
                            {/* {inputShow.changePassword && ( */}
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    변경 비밀번호
                                </CustomText>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <InputBox inputFocus={inputFocus === "changePassword" ? true : false}>
                                        {/* {inputFocus === "changePassword" ? (
                                        <> */}
                                        {passwordPattern.test(editPassword.changePassword) === false ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                        <div style={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
                                            <input type={newPasswordType} name="changePassword" value={editPassword.changePassword} onChange={InputsChange} onKeyPress={onKeyPress} autoComplete="off" />
                                            {newPasswordType === "password" ? (
                                                <PasswordCloseIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("new");
                                                    }}
                                                />
                                            ) : (
                                                <PasswordOpenIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("new");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {/* </>
                                    ) : (
                                        <>
                                            <CheckIcon className="prefix" />
                                            <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                {"•".repeat(editPassword.changePassword.length)}
                                            </CustomText>
                                        </>
                                    )} */}
                                    </InputBox>
                                    {/* <NextButton
                                    disabled={(editPassword.changePassword === "") | (passwordPattern.test(editPassword.changePassword) === false) ? true : false}
                                    onClick={() => {
                                        setInputFocus("checkPassword");
                                    }}
                                    nextFoucs={(editPassword.changePassword === "") | (passwordPattern.test(editPassword.changePassword) === false) ? false : true}
                                    inputFocus={inputFocus === "changePassword" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                                </div>
                            </div>
                            {newPwErrMsg !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {newPwErrMsg}
                                </CustomText>
                            )}
                        </div>
                        {/* )}
                        {inputShow.checkPassword && ( */}
                        <div>
                            <div className="info">
                                <CustomText cl="#D4D4D4" fs="1.125rem">
                                    비밀번호 확인
                                </CustomText>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <InputBox inputFocus={inputFocus === "checkPassword" ? true : false}>
                                        {/* {inputFocus === "checkPassword" ? (
                                        <> */}
                                        {(editPassword.checkPassword === "") | (editPassword.changePassword !== editPassword.checkPassword) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                        <div style={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
                                            <input type={checkPasswordType} name="checkPassword" value={editPassword.checkPassword} onChange={InputsChange} onKeyPress={onKeyPress} autoComplete="off" />
                                            {checkPasswordType === "password" ? (
                                                <PasswordCloseIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("check");
                                                    }}
                                                />
                                            ) : (
                                                <PasswordOpenIcon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        passwordShow("check");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {/* </>
                                    ) : (
                                        <>
                                            <CheckIcon className="prefix" />
                                            <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                {"•".repeat(editPassword.checkPassword.length)}
                                            </CustomText>
                                        </>
                                    )} */}
                                    </InputBox>
                                    {/* <NextButton
                                    disabled={(editPassword.checkPassword === "") | (editPassword.changePassword !== editPassword.checkPassword) ? true : false}
                                    onClick={() => {
                                        setInputFocus(0);
                                        setEditComplete(true);
                                    }}
                                    nextFoucs={(editPassword.checkPassword === "") | (editPassword.changePassword !== editPassword.checkPassword) ? false : true}
                                    inputFocus={inputFocus === "checkPassword" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                                </div>
                            </div>
                            {checkPwErrMsg !== "" && (
                                <CustomText ta="center" fs="0.875rem" cl="#FF6F6F" mg="7px 0 0 0" style={{ width: "100%" }}>
                                    {checkPwErrMsg}
                                </CustomText>
                            )}
                        </div>
                        {/* )} */}
                    </div>
                </MiddleSecondSection>
            </div>
            <CompleteButton disabled={editComplete ? false : true} onClick={UserPasswordMatchCheck} loading={isLoading}>
                <CustomText fs="1.25rem" cl={editComplete ? "#E2FF9B" : "#B1B1B1"} className="btn-text">
                    변경완료
                </CustomText>
                <ArrowRightCircle stroke={editComplete ? "#E2FF9B" : "#B1B1B1"} width="1.2rem" height="1.2rem" />
            </CompleteButton>
            <CustomText cl="#9E9E9E" mg="2rem 0px 0px 0px" fs="1.125rem" style={{ cursor: "pointer" }} onClick={goMyPageMain}>
                변경 취소
            </CustomText>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default EditPassword;
