import React, { useCallback, useState } from "react";
import { Layout, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled, { css } from "styled-components";

import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataCenterSide from "../datacenter/DataCenterSide";
import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import { ReactComponent as LikeIcon } from "../../assets/user-dashboard/like-icon.svg";
import { ReactComponent as ForkIcon } from "../../assets/user-dashboard/fork-icon.svg";
import { ReactComponent as UpdateIcon } from "../../assets/datacenter/dataset-update-icon.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useMutation, useQuery } from "react-query";
import { CreateProjectLike, DeleteProjectLike, ProjectFork } from "../../services/project-services";
import _ from "lodash";
import { useSelector } from "react-redux";
import AlertModal from "../modal/AlertModal";
import { CreateDataCenterLike, DeleteDataCenterLike, GetDataCenterDetail } from "../../services/datacenter-services";
import UpdateDataSetName from "../modal/UpdateDataSetName";

const Wrap = styled(Layout)`
    min-height: 100vh;
    background-color: #161b22;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const TitleWrap = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding: 0.5rem 2rem 1rem 2rem;
    border-bottom: 2px solid #575757;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @media screen and (max-width: 1024px) {
        padding: 0.5rem 1.5rem 1rem 1.5rem;
        gap: 1rem;
        align-items: flex-start;
        flex-direction: column;
    }
`;

const ContentWrap = styled(Layout)`
    background-color: #161b22;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4rem;
`;

const SideContentWrap = styled.div`
    width: 100%;
    padding: 0 2rem;
    /* background-color: #161b22; */
    display: flex;
    flex-direction: row;
    /* align-items: stretch; */
    align-items: stretch;
    justify-content: center;
    gap: 3rem;

    @media screen and (max-width: 1024px) {
        padding: 0;
    }
`;

// const StyledContent = styled(Content)`
const StyledContent = styled.div`
    width: 100%;
    max-width: 1100px;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;

    overflow: hidden;

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 0px;
    }
`;

const LikeForkBtn = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #21262c !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    /* padding: 10px 25px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #b2b1b1 !important;
`;

function DataCenterDetailLayout({ children }) {
    const { Token, UserInfo } = useSelector((state) => state);

    const location = useLocation();

    const navigate = useNavigate();
    const params = useParams();
    const [isLiked, setIsLiked] = useState(false);
    const likeMutation = useMutation(CreateDataCenterLike);
    const unLikeMutation = useMutation(DeleteDataCenterLike);
    const forkMutation = useMutation(ProjectFork);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const {
        data: dataCenterData,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["datacenter", params.id],
        async (e) => {
            const data = await GetDataCenterDetail(Token.data.access, params.id);
            return data;
        },
        {
            // staleTime: 60 * 1000, // 1분
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                if (res.data.like_users.some((like) => like.user === UserInfo.data?.id)) {
                    setIsLiked(true);
                } else {
                    setIsLiked(false);
                }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const handleLikeBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        }

        // 이미 좋아요 누른 상태일때
        if (isLiked) {
            await unLikeMutation.mutateAsync(
                {
                    token: Token.data.access,
                    dataCenterId: params.id,
                },
                {
                    onSuccess: (res) => {
                        refetch();
                    },
                    onError: (error) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        } else {
            await likeMutation.mutateAsync(
                { token: Token.data.access, dataCenterId: params.id, userId: UserInfo.data.id },
                {
                    onSuccess: (res) => {
                        refetch();
                    },
                    onError: (error) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        }
    }, [isLiked, Token, UserInfo]);

    const handleForkBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        } else {
            navigate(`/data-center/${params.id}/fork`);
        }
    }, [Token, UserInfo]);

    return (
        <Wrap>
            <AppHeader />

            <TitleWrap>
                <div style={{ width: "100%", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                    {dataCenterData?.data.category.upper_category === null ? (
                        <CustomText>{dataCenterData?.data.category.category_name}</CustomText>
                    ) : (
                        <CustomText>
                            {dataCenterData?.data.category.upper_category.category_name} <span style={{ fontSize: "1.125rem" }}>&gt;</span> {dataCenterData?.data.category.category_name}
                        </CustomText>
                    )}
                    <div>
                        <CustomText fs="1.5rem" fw="600">
                            {dataCenterData?.data.data_name}
                        </CustomText>
                        {UserInfo.data.id === dataCenterData?.data.provider.id && (
                            <UpdateIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            />
                        )}
                    </div>
                </div>
                <div style={{ alignItems: "flex-end" }}>
                    <LikeForkBtn onClick={handleLikeBtnClick}>
                        <LikeIcon width="0.875rem" height="0.875rem" fill={isLiked ? "#FF6433" : "#fff"} />
                        <CustomText fs="0.875rem">{dataCenterData?.data.like_count}</CustomText>
                    </LikeForkBtn>
                    <LikeForkBtn onClick={handleForkBtnClick} loading={forkMutation.isLoading}>
                        <ForkIcon width="0.875rem" height="0.875rem" />
                        <CustomText fs="0.875rem">{dataCenterData?.data.fork_count}</CustomText>
                    </LikeForkBtn>
                </div>
            </TitleWrap>
            <SideContentWrap>
                <StyledContent>{children}</StyledContent>
                <DataCenterSide />
            </SideContentWrap>
            <AppFooter />
            <UpdateDataSetName modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} dataCenterData={dataCenterData?.data} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default DataCenterDetailLayout;
