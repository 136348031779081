import axios from "axios";

export const GetToken = (formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/token/", formData);
};

export const VerifyToken = (formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/token/verify/", formData);
};

export const GetUserInfo = (token) => {
    return axios.get(process.env.REACT_APP_END_POINT + "/api/account/userinfo", {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};
