import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { GetCompanyInfo } from "../../services/account-services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { DatePicker, Radio, Button } from "antd";
import { useQuery } from "react-query";
import AlertModal from "../../components/modal/AlertModal";
import ConfirmModal from "../../components/modal/ConfirmModal";
import EditCompanyModal from "../../components/modal/EditCompanyModal";
import { DeleteCategory, GetDataCenterCategory, GetDataCenterSecondCategory } from "../../services/datacenter-services";
import AddSecondCategoryModal from "../../components/modal/AddSecondCategoryModal";
import AddFirstCategoryModal from "../../components/modal/AddFirstCategoryModal";

const Wrap = styled.div`
    flex: 1;
    min-height: 83vh;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        margin-bottom: 10rem;
        width: 60rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
            width: 95%;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0.5rem 0px;
        min-height: 67.25vh;
    }
`;

const Section = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 0.5rem;
    margin-top: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CategoryListWrap = styled(ScrollContainer)`
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    @media screen and (min-width: 1024px) {
        /* display: none; */
    }
`;

const CustomRadioGrup = styled(Radio.Group)`
    width: 100%;
    display: flex;
    gap: 1rem;

    .ant-radio-button-wrapper ant-radio-button-wrapper-checked {
        color: #000000 !important;
        background: #ffffff !important;
        border-color: #ffffff !important;
    }

    .ant-radio-button-wrapper {
        border-radius: 1rem;
        background: #393939;
        background-color: #393939;
        border-color: #393939;
        color: #d4d4d4;
        box-shadow: none;

        font-size: 0.875rem;
        font-weight: 400;
        font-family: Pretendard;
    }

    .ant-radio-button-wrapper:not(:first-child):before {
        background: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: #000000;
        background: #ffffff;
        border-color: #ffffff;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #000000;
        background: #ffffff;
        border-color: #ffffff;
    }

    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-radius: 1rem;
        border-right-color: #ffffff;
    }

    .ant-radio-button-wrapper:first-child:last-child {
        border-radius: 1rem;
    }
`;

const CustomBtn = styled(Button)`
    background-color: #6f6e6e !important;
    border-radius: 2px;
    /* height: auto; */

    padding: 0rem 0.8rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #6f6e6e !important;
`;

function CategoryManagement(props) {
    const { UserInfo, Token } = useSelector((state) => state);
    const [company, setCompany] = useState("");
    const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);

    const [addFirstIsOpen, setAddFirstIsOpen] = useState({ open: false, afterFunction: null });
    const [addSecondIsOpen, setAddSecondIsOpen] = useState({ open: false, afterFunction: null });

    const [firstCategory, setFirstCategory] = useState({ id: "0", name: "전체" });
    const [secondCategory, setSecondCategory] = useState({ id: "0", name: "전체" });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    useEffect(() => {
        GetData();
    }, []);

    const GetData = useCallback(async () => {
        try {
            const data = await GetCompanyInfo(UserInfo.data.id, Token.data.access);
            setCompany(data.data[0]);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const {
        data: dataSetCategoryList,
        isLoading: loading,
        refetch: firstCategoryRefetch,
    } = useQuery(
        ["dataset", "category"],
        async (e) => {
            const data = await GetDataCenterCategory("0");
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: dataSetSecondCategoryList, refetch: secondCategoryRefetch } = useQuery(
        ["dataset", "secondCategory", firstCategory, secondCategory],
        async (e) => {
            const data = await GetDataCenterSecondCategory(Token.data.access, firstCategory.id, secondCategory.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const UpdateCategory = useCallback(async (deleteId) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const data = await DeleteCategory(Token.data.access, deleteId);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "카테고리 삭제 완료",
                text: "카테고리 삭제가 완료되었습니다.",
                afterFunction: () => {
                    firstCategoryRefetch();
                    secondCategoryRefetch();
                    setFirstCategory({ id: "0", name: "전체" });
                    setSecondCategory({ id: "0", name: "전체" });
                },
            });
        } catch (err) {
            if (err.response.status === 406) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "카테고리 삭제 오류",
                    text: "해당 카테고리에 데이터셋이 있습니다.",
                    afterFunction: null,
                });
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "카테고리 삭제 오류",
                    text: "카테고리 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const firstCategoryChange = (e) => {
        setFirstCategory({ id: e.target.value, name: e.target.label });
        setSecondCategory({ id: "0", name: "전체" });
    };

    const secondCategoryChange = (e) => {
        setSecondCategory({ id: e.target.value, name: e.target.label });
    };

    return (
        <Wrap>
            <div className="bottomMargin">
                <div>
                    <Section style={{ marginTop: "1.3rem" }}>
                        <CustomText fw={600} fs="1.12rem">
                            대분류
                        </CustomText>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <CustomBtn
                                onClick={() => {
                                    setAddFirstIsOpen({
                                        open: true,
                                        afterFunction: () => {
                                            firstCategoryRefetch();
                                            secondCategoryRefetch();
                                        },
                                    });
                                }}
                            >
                                <CustomText fs="0.875rem">추가</CustomText>
                            </CustomBtn>
                            <CustomBtn
                                onClick={() => {
                                    if (firstCategory.id === "0") {
                                        setConfirmModalOpen({
                                            open: true,
                                            text: "삭제할 대분류 카테고리를 선택해주세요",
                                            afterFunction: null,
                                        });
                                    } else {
                                        setConfirmModalOpen({
                                            open: true,
                                            title: "대분류 카테고리 삭제",
                                            text: `${firstCategory.name} 을(를) 삭제하시겠습니까?`,
                                            afterFunction: () => {
                                                UpdateCategory(firstCategory.id);
                                            },
                                        });
                                    }
                                }}
                            >
                                <CustomText fs="0.875rem">삭제</CustomText>
                            </CustomBtn>
                        </div>
                    </Section>
                    <CategoryListWrap>
                        <CustomRadioGrup value={firstCategory.id} buttonStyle="solid" onChange={firstCategoryChange}>
                            <Radio.Button value="0" label="전체">
                                전체
                            </Radio.Button>
                            {dataSetCategoryList?.data.map((category) => {
                                return (
                                    <Radio.Button value={category.id} label={category.category_name}>
                                        {category.category_name}
                                    </Radio.Button>
                                );
                            })}
                        </CustomRadioGrup>
                    </CategoryListWrap>
                </div>
                <div>
                    <Section>
                        <CustomText fw={600} fs="1.12rem">
                            중분류
                        </CustomText>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <CustomBtn
                                onClick={() => {
                                    setAddSecondIsOpen({
                                        open: true,
                                        afterFunction: () => {
                                            firstCategoryRefetch();
                                            secondCategoryRefetch();
                                        },
                                    });
                                }}
                            >
                                <CustomText fs="0.875rem">추가</CustomText>
                            </CustomBtn>
                            <CustomBtn
                                onClick={() => {
                                    if (secondCategory.id === "0") {
                                        setConfirmModalOpen({
                                            open: true,
                                            text: "삭제할 중분류 카테고리를 선택해주세요",
                                            afterFunction: null,
                                        });
                                    } else {
                                        setConfirmModalOpen({
                                            open: true,
                                            title: "중분류 카테고리 삭제",
                                            text: `${secondCategory.name} 을(를) 삭제하시겠습니까?`,
                                            afterFunction: () => {
                                                UpdateCategory(secondCategory.id);
                                            },
                                        });
                                    }
                                }}
                            >
                                <CustomText fs="0.875rem">삭제</CustomText>
                            </CustomBtn>
                        </div>
                    </Section>
                    <CategoryListWrap>
                        <CustomRadioGrup value={secondCategory.id} buttonStyle="solid" onChange={secondCategoryChange}>
                            <Radio.Button value="0" label="전체">
                                전체
                            </Radio.Button>
                            {dataSetSecondCategoryList?.data.map((category) => {
                                return (
                                    <Radio.Button value={category.id} label={category.category_name}>
                                        {category.category_name}
                                    </Radio.Button>
                                );
                            })}
                        </CustomRadioGrup>
                    </CategoryListWrap>
                </div>
            </div>
            {company !== "" && <EditCompanyModal editCompanyModalOpen={editCompanyModalOpen} setEditCompanyModalOpen={setEditCompanyModalOpen} company={company.company} />}
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />

            <AddFirstCategoryModal modalIsOpen={addFirstIsOpen} setIsOpen={setAddFirstIsOpen} />
            <AddSecondCategoryModal modalIsOpen={addSecondIsOpen} setIsOpen={setAddSecondIsOpen} />
        </Wrap>
    );
}

export default CategoryManagement;
