import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button, Radio } from "antd";
import Lottie from "react-lottie";

import { ReactComponent as AddDatasetIcon } from "../../assets/modal/add-dataset-icon.svg";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import * as successLottie from "../../assets/modal/success-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import CopyDatasetDropdown from "./CopyDatasetDropdown";
import { useMutation, useQuery } from "react-query";
import { CreateDataset, GetProjectDataSetList } from "../../services/project-services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSelectedDatasetAction } from "../../store/reducer/SelectedDataset";
import AlertModal from "./AlertModal";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 90%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;
        text-align: center;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
    border-bottom: 1px dashed #b1b1b1;
`;

const StyledRadisGroup = styled(Radio.Group)`
    margin-top: 1.5rem;
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    /* flex: 1; */

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

Modal.setAppElement("#root");

function AddDataset({ modalIsOpen, setIsOpen }) {
    const { Token } = useSelector((state) => state);
    const params = useParams();
    const dispatch = useDispatch();

    const [selectedType, setSelectedType] = useState("bbox");
    const [newDatasetName, setNewDatasetName] = useState("");
    const [newDatasetNameErr, setNewDatasetNameErr] = useState("");
    const [copyDataset, setCopyDataset] = useState({
        id: 0,
        data_name: "복사 데이터셋 선택",
    });
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const createDataset = useMutation(CreateDataset);

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (!modalIsOpen) {
            setCopyDataset({
                id: 0,
                data_name: "복사 데이터셋 선택",
            });
            setNewDatasetName("");
            setSelectedType("bbox");
        }
    }, [modalIsOpen]);

    const handleDatasetNameChange = useCallback(
        (e) => {
            if (newDatasetNameErr !== "") {
                setNewDatasetNameErr("");
            }

            setNewDatasetName(e.target.value);
        },
        [newDatasetNameErr]
    );

    const handleCreateBtnClick = useCallback(async () => {
        if (newDatasetName === "") {
            setNewDatasetNameErr("데이터셋 명을 입력하세요");
            return;
        }

        const formData = new FormData();

        formData.append("data_name", newDatasetName);
        formData.append("annotation_type", selectedType);
        formData.append("copy_dataset", copyDataset.id);

        await createDataset.mutateAsync(
            { token: Token.data.access, formData: formData, projectId: params.id },
            {
                onSuccess: (res) => {
                    refetch();
                },
                onError: (error) => {
                    if (error.response.status === 401) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "권한 오류",
                            text: "데이터셋 생성권한이 없습니다.",
                            afterFunction: null,
                        });
                        return;
                    } else if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "권한 오류",
                            text: "업로드 권한이 없습니다.",
                            afterFunction: null,
                        });
                    } else if (error.response.status === 409) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이름 오류",
                            text: "이미 사용중인 데이터셋 명 입니다.",
                            afterFunction: null,
                        });
                    } else {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    }
                },
            }
        );
    }, [Token, selectedType, newDatasetName, newDatasetNameErr, copyDataset]);

    const { refetch } = useQuery(
        ["project", "dataset", params.id],
        async (e) => {
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            return data;
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                if (createDataset.isSuccess) {
                    dispatch(updateSelectedDatasetAction(createDataset.data.data));
                }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterClose={() => {
                if (createDataset.isSuccess) {
                    createDataset.reset();
                }
            }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Dataset"
        >
            {createDataset.isSuccess ? (
                <>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: successLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="8rem"
                        height="8rem"
                        style={{
                            marginBottom: "0.8rem",
                        }}
                    />
                    <CustomText fs="1.125rem" fw="600">
                        데이터셋 생성 완료
                    </CustomText>

                    <CustomBtn
                        bc="#b2b1b1"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        <CustomText fs="0.9rem" cl="#b2b1b1">
                            확인
                        </CustomText>
                    </CustomBtn>
                </>
            ) : (
                <>
                    <AddDatasetIcon width="2rem" height="2rem" style={{ marginBottom: "0.8rem" }} />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 0.8rem 0">
                        데이터셋 생성
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#d4d4d4" ta="center">
                        선택한 데이터셋의 모든 데이터를 복사하거나
                        <br />
                        비어있는 새로운 데이터셋을 만들 수 있습니다.
                    </CustomText>

                    <StyledRadisGroup
                        onChange={(e) => {
                            e.preventDefault();
                            setSelectedType(e.target.value);
                        }}
                        value={selectedType}
                    >
                        <Radio value="bbox">
                            <CustomText>Bounding Box</CustomText>
                        </Radio>
                        <Radio value="polygon">
                            <CustomText>Polygon</CustomText>
                        </Radio>
                    </StyledRadisGroup>

                    <InputBox>
                        {newDatasetName.length === 0 ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="id" value={newDatasetName} onChange={handleDatasetNameChange} maxLength="20" autoComplete="off" placeholder="데이터셋 명 입력" />
                    </InputBox>
                    {newDatasetNameErr !== "" && <CustomText cl="#F8514A">{newDatasetNameErr}</CustomText>}

                    <div style={{ width: "90%", marginBottom: "1.5rem" }}>
                        <CopyDatasetDropdown copyDataset={copyDataset} setCopyDataset={setCopyDataset} />
                    </div>

                    <CustomText fs="0.875rem" cl="#d4d4d4" ta="center">
                        타입이 다른 라벨링 데이터를 선택할 경우
                        <br />
                        학습 데이터는 복사되지만 라벨링 데이터는 복사되지 않습니다.
                    </CustomText>

                    <CustomBtn bc={newDatasetName !== "" ? "#e2ff9b" : "#b2b1b1"} onClick={handleCreateBtnClick} disabled={newDatasetName !== "" ? false : true}>
                        <CustomText fs="0.9rem" cl={newDatasetName !== "" ? "#E2FF9B" : "#b2b1b1"}>
                            생성
                        </CustomText>
                    </CustomBtn>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default AddDataset;
