import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RegistrationIssue } from "../../../services/board-services";
import { CustomText } from "../../../styles/theme/CustomStyle";
import styled from "styled-components";
import { Button } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import AlertModal from "../../../components/modal/AlertModal";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;

    padding: 13px 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;

    @media screen and (max-width: 1024px) {
        padding: 0 1.5rem;
    }
`;

const InputBox = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 1.5rem;

    input {
        width: 90%;
        background-color: #21272e;
        padding: 13px 2rem;
        border-radius: 5px !important;
        border: 1px solid #707070 !important;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: 1px solid #707070 !important;
            }
            to {
                border: 1px solid #707070 !important;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 1rem 1.5rem;
        }
    }
`;

const IssueInput = styled.textarea`
    width: 100%;
    background-color: #21272e;
    padding: 1.5rem 2rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 34rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 20rem;
    }
`;

function IssueRegistration(props) {
    const { UserInfo, Token } = useSelector((state) => state);
    const location = useLocation();
    const match = useParams();
    const crntType = queryString.parse(location.search).type;
    const navigate = useNavigate();
    const [addIssue, setAddIssue] = useState("");
    const [title, setTitle] = useState("");

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (crntType === undefined) {
            navigate({
                search: "?tab=issue&type=registration",
            });
        }
    }, [crntType]);

    const titleChange = useCallback(
        (e) => {
            setTitle(e.target.value);
        },
        [title]
    );

    const issueChange = useCallback(
        (e) => {
            setAddIssue(e.target.value);
        },
        [addIssue]
    );

    const IssueRegistration = useCallback(async () => {
        try {
            const formData = new FormData();

            formData.append("writer_id", UserInfo.data.id);
            formData.append("project_id", match.id);
            formData.append("title", title);
            formData.append("content", addIssue);

            const issueData = await RegistrationIssue(formData, Token.data.access);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "이슈 등록 완료",
                text: "이슈 등록이 완료되었습니다.",
                afterFunction: () => {
                    navigate({
                        search: `?tab=issue&page=1`,
                    });
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "이슈 등록 오류",
                text: "이슈 등록에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    }, [title, addIssue]);

    return (
        <>
            <div style={{ borderBottom: "1px solid #707070", paddingBottom: "1.15rem", marginTop: "1.5rem" }}>
                <CustomText fw={600} fs="1.125rem">
                    이슈 등록
                </CustomText>
                <InputBox>
                    <input value={title} onChange={titleChange} autoComplete="off" placeholder="이슈 제목 입력" />
                    <CustomBtn disabled={(title === "") | (addIssue === "") ? true : false} bc={(title === "") | (addIssue === "") ? "#b2b1b1" : "#E2FF9B"} onClick={IssueRegistration}>
                        <CustomText cl={(title === "") | (addIssue === "") ? "#b1b1b1" : "#E2FF9B"}>등록</CustomText>
                    </CustomBtn>
                </InputBox>
            </div>
            <IssueInput placeholder="이슈 내용 입력" value={addIssue} onChange={issueChange} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default IssueRegistration;
