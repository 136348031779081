import React, {useEffect} from "react";
import styled from "styled-components";

import {CustomText} from "../../../styles/theme/CustomStyle";

import {useSelector} from "react-redux";
import DataSetDropDown from "../dataset/DataSetDropDown";
import ModelInfo from "../validation/ModelInfo";
import queryString from "query-string";
import {useNavigate, useLocation} from "react-router-dom";

const DropDownSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    /* height: 2.5rem; */
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #575757;

    padding-bottom: 10px;
`;

function ModelMain(props) {
    const {SelectedDataset} = useSelector(state => state);
    const location = useLocation();
    const navigate = useNavigate();

    const crntPage = queryString.parse(location.search).page;

    // useEffect(() => {
    //     // if (crntPage === undefined) {
    //     //     navigate({
    //     //         search: `?tab=model&page=1`,
    //     //     });
    //     // } else {
    //     navigate({
    //         search: `?tab=model&page=${crntPage}`,
    //     });
    //     // }
    // }, [crntPage]);

    return (
        <>
            <DropDownSection>
                <DataSetDropDown />
            </DropDownSection>
            <TitleSection>
                <CustomText fs="1.125rem" fw="600">
                    모델 목록
                </CustomText>
            </TitleSection>
            {"id" in SelectedDataset.data && (
                <ModelInfo
                    type={"model"}
                    dataSet={SelectedDataset.data}
                    page={crntPage === undefined ? 1 : crntPage}
                />
            )}
        </>
    );
}

export default ModelMain;
