import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {ReactComponent as ArrowRightCircle} from "../../assets/login/arrow-right-circle.svg";
import {ReactComponent as SignupCompleteIcon} from "../../assets/signup/signupcomplete.svg";

import {Link} from "react-router-dom";
import {CustomText} from "../../styles/theme/CustomStyle";
import {useMutation} from "react-query";
import {CreateCompany, CreateUser} from "../../services/account-services";
import AlertModal from "../../components/modal/AlertModal";

const Wrap = styled.div`
    min-height: inherit;
    background-color: #0c1117;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    /* justify-content: center; */
    padding-bottom: 10rem;
`;

const SignUpBox = styled.div`
    width: 30rem;
    margin-top: 60px;
    background-color: #21272e;
    padding: 40px 0px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .assentSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .scrollDiv {
        background-color: #181f27;
        overflow: auto;
        width: 100%;
        border-radius: 5px;
        height: 10rem;
        padding: 1rem;

        ::-webkit-scrollbar {
            background-color: #181f27;
            width: 20px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #707070;
            background-clip: padding-box;
            border: 9px solid transparent;
        }
    }
`;

function SignupComplete({type, inputs}) {
    const userMutation = useMutation(CreateUser);
    const companyMutation = useMutation(CreateCompany);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(async () => {
        if (type === "company") {
            const formData = new FormData();

            formData.append("company_name", inputs.companyName);
            formData.append("company_registration_number", inputs.companyRegistrationNumber);

            await companyMutation.mutateAsync(formData, {
                onSuccess: data => {
                    userSignUp(data.data.id);
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            });
        } else {
            userSignUp(0);
        }
    }, []);

    const userSignUp = useCallback(async companyId => {
        const formData = new FormData();

        if (type === "company") {
            formData.append("login_id", inputs.id);
            formData.append("name", inputs.name);
            formData.append("email", inputs.email);
            formData.append("phone_number", inputs.phone);
            formData.append("company_id", companyId);
            formData.append("password", inputs.password);
            formData.append("is_manager", true);
            formData.append("type", type);
        } else {
            formData.append("login_id", inputs.id);
            formData.append("name", inputs.name);
            formData.append("email", inputs.email);
            formData.append("phone_number", inputs.phone);
            formData.append("company_id", inputs.companyId);
            formData.append("password", inputs.password);
            formData.append("type", type);
        }

        await userMutation.mutateAsync(formData, {
            onSuccess: data => {},
            onError: error => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        });
    }, []);

    return (
        <HomeLayout headerShown={false} signUpHeader={true}>
            <Wrap>
                <Link to="/">
                    <Logo width="16rem" height="100%" />
                </Link>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <SignUpBox>
                        <SignupCompleteIcon style={{margin: "30px 0px 50px 0px"}} />
                        <CustomText fs="1.05rem" fw={500}>
                            NewLearn 회원가입이 완료되었습니다
                        </CustomText>
                        <CustomText fs="1.05rem" cl="#9B9B9B">
                            이메일 인증 및 관리자 승인 후 이용가능합니다
                        </CustomText>
                    </SignUpBox>

                    <Link to="/login">
                        <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                            <CustomText fs="1rem" cl="#7CBEFF">
                                로그인
                            </CustomText>
                            <ArrowRightCircle stroke="#7CBEFF" width="1.2rem" height="1.2rem" />
                        </div>
                    </Link>
                </div>
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </HomeLayout>
    );
}

export default SignupComplete;
