import React, { useMemo, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as DownArrow } from "../../../assets/project/down-arrow.svg";

import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";
import { GetTrainedModel } from "../../../services/train-services";
import AlertModal from "../../modal/AlertModal";

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 3px;
    padding: 0.6rem 1.2rem;
    border: none;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;
function ModelDropDown({ modelData, setModelData }) {
    const { SelectedDataset } = useSelector((state) => state);
    const [modelList, setModelList] = useState([]);
    const params = useParams();
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const getTraindModel = useMutation(GetTrainedModel);
    const getTrainModel = useCallback(async (dataSet, project_id) => {
        const formData = new FormData();

        formData.append("project_id", project_id);
        formData.append("annotation_type", dataSet.annotation_type);
        await getTraindModel.mutateAsync(
            { formData: formData },
            {
                onSuccess: (res) => {
                    setModelList(res.data);
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, []);

    const set_label_data = (label_data) => {
        let parseData = label_data.split(",");
        let label_list = [];

        for (let i = 0; i < parseData.length; ++i) {
            if (i % 2 === 1) {
                let label_data = parseData[i].split(":")[1];

                let process_label_data = "";
                if (i === parseData.length - 1) {
                    process_label_data = label_data.slice(2, label_data.length - 3);
                } else {
                    process_label_data = label_data.slice(2, label_data.length - 2);
                }

                let label_object = { label_name: process_label_data, id: i };

                label_list.push(label_object);
            }
        }

        return label_list;
    };

    useEffect(() => {
        getTrainModel(SelectedDataset.data, params.id);
    }, [SelectedDataset.data]);

    const modelItems = useMemo(
        () => (
            <StyledMenu>
                {modelList.map((model, index) => {
                    return (
                        <Menu.Item
                            key={model.id}
                            className="menu-item"
                            onClick={() =>
                                setModelData({
                                    id: model.id,
                                    model_name: model.model_name,
                                    algorithm_name: model.training_algorithm.algorithm_name,
                                    label_data: set_label_data(model.label_data),
                                })
                            }
                        >
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {model.model_name}{" "}
                                </CustomText>

                                <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#fff">
                                    <CustomText fs="0.8rem" fw="400" cl="#fff" mg="0">
                                        {model.training_algorithm.algorithm_name}
                                    </CustomText>
                                </CircleText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [modelList]
    );

    return (
        <>
            <StyledDropDown overlay={modelItems} placement="bottom" trigger={["click"]}>
                <MenuItem>
                    <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {modelData.model_name}
                    </CustomText>
                    <DownArrow width="1rem" height="1rem" />
                </MenuItem>
            </StyledDropDown>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default ModelDropDown;
