import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import UserInfo from "./UserInfo";
import Token from "./Token";
import SelectedDataset from "./SelectedDataset";
import SelectedDataCenterDataset from "./SelectedDataCenterDataset";
import RepresentativeDataset from "./RepresentativeDataset";
import SelectedManager from "./SelectedManager";
import ImageCropper from "./ImageCropper";
import CropInfo from "./CropInfo.js";
import CropIndex from "./CropIndex.js";
import ProjectViewer from "./ProjectViewer.js";
import SelectedVideo from "./SelectedVideo";
import SelectedTrainModel from "./SelectedTrainModel";
const persistConfig = {
    key: "persist",
    storage: storageSession,
    whitelist: ["UserInfo", "Token", "SelectedDataset"],
};

const rootReducer = combineReducers({
    UserInfo,
    Token,
    SelectedDataset,
    SelectedDataCenterDataset,
    RepresentativeDataset,
    SelectedManager,
    ImageCropper,
    CropInfo,
    CropIndex,
    ProjectViewer,
    SelectedVideo,
    SelectedTrainModel,
});

export default persistReducer(persistConfig, rootReducer);
