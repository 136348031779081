import React from "react";
import styled from "styled-components";
import { CustomText } from "./CustomStyle";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }

    border-bottom: 2px solid #575757;
    padding: 0.1rem 0;

    overflow: hidden;
    /* padding-bottom: 1rem; */

    display: flex;
    flex-direction: column;
    /* gap: 1.1rem; */
    gap:7px;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 1.1rem; */
        gap:7px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 60%;
        height: 1.2rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 40%;
        height: 0.8rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-content {
        border-radius: 5px;
        width: 100%;
        height: 5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-items {
        border-radius: 5px;
        width: 2rem;
        height: 1.414rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }
    
    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-profile {
        border-radius: 100px;
        width: 1.5rem;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-profile::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
    `;

const ModelWrap = styled.div`
display: flex;
flex-direction: column;


padding-right: 10px;

.line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
}
.model-param {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20rem;

    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
/* height: 2.5rem; */
.algorithm {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin-top: 5px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        justify-content: space-between;
        width: 100%;
        margin-top: 5px;
    }
}
.mobile-model-param {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.train-time {
    display: flex;
    flex-direction: row;
    width: 20rem;
    justify-content: space-between;
    margin-top: 5px;
    @media screen and (max-width: 1024px) {
        width: 100%;
        justify-content: space-between;
        width: 100%;
        margin-top: 5px;
    }
}
`;

function ModelListSkeleton(props) {
    return (
        <Wrap>
            <ModelWrap>
                                <div className="line">
                                    <div className="skeleton-title" />
                                    <div className="model-param">
                                        <CustomText fs="0.875rem" fw="400">
                                            에포크 
                                        </CustomText>
                                        <div className="info-items" />
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 0.8rem" }}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            배치사이즈 
                                        </CustomText>
                                        <div className="info-items" />
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 0.8rem" }}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            학습률 
                                        </CustomText>
                                        <div className="info-items" />
                                    </div>
                                </div>
                                <div className="line">
                                    <div className="algorithm" style={{gap:"10px"}}>
                                        <div className="info-items" style={{width:"4rem"}}/>
                                        <div className="info-items" style={{width:"4rem"}} />


                                        {/* <CircleText backgroundColor={modelId === model.id ? "rgba(0,0,0,0)" : "#393939"} borderColor={modelId === model.id ? "#E2FF9B" : "#393939"}>
                                            <CustomText fs="0.875rem" fw="400" cl={modelId === model.id ? "#E2FF9B" : "#D4D4D4"}>
                                                {model.training_algorithm.algorithm_name}
                                            </CustomText>
                                        </CircleText> */}
                                    </div>
                                    <div className="mobile-model-param">
                                        <CustomText fs="0.875rem" fw="400">
                                            에포크 <div className="info-items" />
                                        </CustomText>
                                        <span style={{ fontWeight: 100, color: "#707070" }}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            배치사이즈 <div className="info-items" />
                                        </CustomText>
                                        <span style={{ fontWeight: 100, color: "#707070" }}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            학습률 <div className="info-items" />
                                        </CustomText>
                                    </div>
                                    <div className="train-time" style={{gap:"10px"}}>
                                        <div className="skeleton-title" />
                                        <div className="skeleton-title" />
                                    </div>
                                </div>
                            </ModelWrap>
        </Wrap>
    );
}

export default ModelListSkeleton;
