import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }

    margin-top: 1rem;
    margin-bottom: 5px;

    .skeleton-title {
        border-radius: 5px;
        width: 40%;
        height: 1.7rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
`;

function CustomSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-title" />
        </Wrap>
    );
}

export default CustomSkeleton;
