import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as LikeIcon } from "../../assets/user-dashboard/like-icon.svg";
import { ReactComponent as ForkIcon } from "../../assets/user-dashboard/fork-icon.svg";
import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as ImageIcon } from "../../assets/project/image-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as LabelingIcon } from "../../assets/project/labeling-icon.svg";
import { ReactComponent as UpdateIcon } from "../../assets/datacenter/dataset-update-icon.svg";

import { CustomText } from "../../styles/theme/CustomStyle";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProjectListSkeleton from "../../styles/theme/ProjectListSkeleton";
import { Pagination, Avatar } from "antd";
import AlertModal from "../../components/modal/AlertModal";
import { Colors } from "../../styles/theme/Colors";
import { useQuery } from "react-query";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as UpArrow } from "../../assets/project/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";
import DataCenterDetailLayout from "../../components/layout/DataCenterDetailLayout";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as NoDataSetIcon } from "../../assets/project/nodataset.svg";
import { GetDataCenterDataSetHistory, GetDataCenterExplanationMember, GetDataCenterForkProject, GetDataCenterImage } from "../../services/datacenter-services";
import dayjs from "dayjs";
import DataCenterHistorySkeleton from "../../styles/theme/DataCenterHistorySkeleton";
import Lottie from "react-lottie";
import * as LoadingData from "../../assets/loading/loaindg-lottie.json";
import UpdateDataSetExplanation from "../../components/modal/UpdateDataSetExplanation";

const Wrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        /* padding-bottom: 10rem; */
    }
`;

const ProjectInfoWrapper = styled.div`
    width: 100%;
    background-color: #303a45;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 13px;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MemberWrapper = styled.div`
    width: 100%;
    padding: 0 1.5rem 24px 1.5rem;
    border-bottom: 1px solid #707070;
    margin-top: 1.3rem;
    margin-bottom: 1rem;

    .title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title-section div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MemberList = styled.div`
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 22px;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MemberItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

const InfoToggle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.3rem;
    margin-bottom: 3rem;
    cursor: pointer;
    gap: 5px;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const DataCenterItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
`;

const DataCenterItem = styled.div`
    overflow: hidden;
    border-bottom: 1px solid #707070;
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .contents-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        gap: 1rem;
    }

    .explanation-static-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .statics {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-top: 0.5rem;
    }

    .count-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .explanation {
        align-self: flex-start;
        width: 100%;
        font-family: Pretendard;
        font-weight: 400;
        font-size: 0.875rem;
        color: #fff;
        /* height: 2.75rem; */
        white-space: pre-line;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 1024px) {
            height: auto;
        }
    }

    .recent-model {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.125rem;
    }

    .image-labeling-count {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .image-labeling-count .count-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .image-labeling-count .count-divider {
        height: 70%;
        border-right: 1px solid #707070;
    }

    .representative-section {
        background-color: #21272e;
        border-radius: 5px;
        padding: 0.5rem 0.7rem;
        gap: 5px;
    }

    .representative-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        gap: 10px;

        .representative {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }

    @media screen and (max-width: 1024px) {
        .title-section {
            gap: 0.7rem;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .contents-section {
            flex-direction: column;
        }
    }
`;

const CustomPagination = styled(Pagination)`
    margin-top: 3rem;
    margin-bottom: 10rem;
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

const ImageListWrap = styled.div`
    width: 100%;
    margin: 1rem 0 3rem 0;
    overflow: auto;
    /* margin-bottom: 4rem; */
    height: 25rem;
    background-color: #21272e;
    padding: 0.8rem;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const ImageList = styled(InfiniteScroll)`
    width: 100%;
    /* max-height: inherit; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px;
    grid-gap: 1rem;
    padding-right: 0.5rem;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100px;
    }
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    max-height: 200px;
    border: ${(props) => props.bd} !important;
`;

const NoModelSection = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 19rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0 3rem 0;
`;

const DataHistorySection = styled.div`
    padding: 1rem 0;
    border-bottom: 1px solid #575757;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;

function DataCenterDetail(props) {
    const { Token, UserInfo } = useSelector((state) => state);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    const scrollParentRef = useRef(null);

    const [projectLoading, setProjectLoading] = useState(false);
    const [projectList, setProjectList] = useState({
        isMore: false,
        crntPage: 1,
        totalPage: 1,
        data: [],
    });

    const [dataCenterHistoryLoading, setDataCenterHistoryLoading] = useState(false);
    const [dataCenterHistoryList, setDataCenterHistoryList] = useState({
        isMore: false,
        crntPage: 1,
        totalPage: 1,
        data: [],
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const params = useParams();

    const [infoShowMore, setInfoShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageDataList, setImageDataList] = useState({
        count: 0,
        crntPage: 1,
        hasMore: false,
        scrollY: 0,
        data: [],
    });

    const getImageData = useCallback(
        async (page, refresh) => {
            if (page === null) {
                return;
            }

            try {
                setIsLoading(true);
                const listRes = await GetDataCenterImage(Token.data.access, params.id, page);

                setImageDataList((prev) => ({
                    ...prev,
                    count: listRes.data.count,
                    hasMore: listRes.data.next !== null ? true : false,
                    crntPage: listRes.data.next !== null ? page + 1 : null,
                    scrollY: page !== 1 && imageDataList.scrollY + 9500,
                    data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
                }));
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [Token]
    );

    const { data: dataCenterData, refetch } = useQuery(
        ["datacenter", "explanation", "member", params.id],
        async (e) => {
            const data = await GetDataCenterExplanationMember(Token.data.access, params.id);
            return data;
        },
        {
            // staleTime: 60 * 1000, // 1분
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {},
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        fetchProjectList(1);
        dataCenterHistoryRefetch(1);
        getImageData(1, true);
    }, []);

    const fetchProjectList = useCallback(
        async (page) => {
            setProjectLoading(true);
            try {
                const { data: data } = await GetDataCenterForkProject(Token.data.access, params.id, page);

                setProjectList({
                    crntPage: page,
                    totalPage: data.count,
                    data: data.results,
                });
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setProjectLoading(false);
            }
        },
        [Token, UserInfo, projectList.crntPage]
    );

    const dataCenterHistoryRefetch = useCallback(
        async (page) => {
            setDataCenterHistoryLoading(true);
            try {
                const { data: data } = await GetDataCenterDataSetHistory(Token.data.access, params.id, page);

                setDataCenterHistoryList({
                    crntPage: page,
                    totalPage: data.count,
                    data: data.results,
                });
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setDataCenterHistoryLoading(false);
            }
        },
        [Token, dataCenterHistoryList.crntPage]
    );

    const LottieLoadingOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    return (
        <DataCenterDetailLayout>
            {infoShowMore ? (
                <>
                    {dataCenterData.data.data_explanation && (
                        <ProjectInfoWrapper>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingBottom: "0.5rem" }}>
                                <CustomText fs="1.125rem" fw="600" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>
                                    데이터셋 설명
                                </CustomText>
                                {UserInfo.data.id === dataCenterData?.data.provider.id && (
                                    <UpdateIcon
                                        width="1rem"
                                        height="1rem"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                    />
                                )}
                            </div>
                            <CustomText fs="1rem" fw="400" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: dataCenterData?.data.data_explanation }} />
                        </ProjectInfoWrapper>
                    )}
                    <MemberWrapper>
                        <div className="title-section">
                            <div>
                                <MemberIcon width="1.3rem" height="1.3rem" fill="#fff" />
                                <CustomText mg="0 0 0 10px">데이터 제공</CustomText>
                            </div>
                        </div>

                        <MemberList>
                            <MemberItem>
                                <Avatar size="default" src={dataCenterData?.data.provider.image_path ? process.env.REACT_APP_END_POINT + dataCenterData?.data.provider.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[dataCenterData?.data.provider.id % 10] }} />
                                <CustomText>{dataCenterData?.data.provider.name}</CustomText>
                            </MemberItem>
                        </MemberList>
                    </MemberWrapper>
                    <InfoToggle onClick={() => setInfoShowMore(false)}>
                        <UpArrow width="1.4rem" height="1.4rem" />
                        <CustomText fs="1rem">접기</CustomText>
                    </InfoToggle>
                </>
            ) : (
                <InfoToggle onClick={() => setInfoShowMore(true)}>
                    <DownArrow width="1.4rem" height="1.4rem" />
                    <CustomText fs="1rem">데이터셋 설명 열기</CustomText>
                </InfoToggle>
            )}
            <Wrap>
                <CustomText fs="1.125rem" fw="600" wd="100%" style={{ paddingBottom: "0.5rem", borderBottom: "1px solid #707070" }}>
                    데이터
                </CustomText>
                <ImageListWrap ref={scrollParentRef}>
                    {isLoading ? (
                        <Lottie options={LottieLoadingOptions} width="10rem" height="10rem" />
                    ) : imageDataList.data.length === 0 ? (
                        <NoModelSection>
                            <NoDataSetIcon width="2rem" height="2rem" fill="white" />
                            <CustomText fs="1rem" fw="400">
                                업로드된 데이터가 없습니다.
                            </CustomText>
                        </NoModelSection>
                    ) : (
                        <ImageList dataLength={imageDataList.count} next={() => getImageData(imageDataList.crntPage, false)} useWindow={false} hasMore={imageDataList.hasMore} scrollableTarget={scrollParentRef.current} initialScrollY={imageDataList.scrollY}>
                            {imageDataList.data.map((annotation) => {
                                return <StyledImage key={annotation.id} src={process.env.REACT_APP_END_POINT + "/" + annotation.media_path} alt="annotation_image" bd="0px;" />;
                            })}
                        </ImageList>
                    )}
                </ImageListWrap>

                <CustomText fs="1.125rem" fw="600" wd="100%" style={{ paddingBottom: "0.5rem", borderBottom: "1px solid #707070" }}>
                    데이터 변경 이력
                </CustomText>
                <DataHistorySection>
                    {dataCenterHistoryLoading ? (
                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                            <DataCenterHistorySkeleton />
                        </div>
                    ) : dataCenterHistoryList.data.length === 0 ? (
                        <CustomText ta="center" fs="1.125rem" style={{ width: "100%", margin: "0.5rem 0" }}>
                            데이터 변경 이력이 없습니다.
                        </CustomText>
                    ) : (
                        dataCenterHistoryList.data.map((dataSetHistory, index) => {
                            return (
                                <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <CustomText>{dataSetHistory.content}</CustomText>
                                    <CustomText>{dayjs(dataSetHistory.created_at).locale("ko").format("YYYY.MM.DD")}</CustomText>
                                </div>
                            );
                        })
                    )}
                </DataHistorySection>

                <CustomPagination
                    style={{ margin: "1.5rem 0 3rem 0" }}
                    defaultCurrent={dataCenterHistoryList.crntPage}
                    current={dataCenterHistoryList.crntPage}
                    total={dataCenterHistoryList.totalPage}
                    pageSize={10}
                    onChange={(page) => {
                        dataCenterHistoryRefetch(page);
                    }}
                />

                <CustomText fs="1.125rem" fw="600" wd="100%" style={{ paddingBottom: "0.5rem", borderBottom: "1px solid #707070" }}>
                    활용 프로젝트
                </CustomText>
                <DataCenterItemWrapper>
                    {projectLoading ? (
                        <>
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                            <ProjectListSkeleton />
                        </>
                    ) : projectList.data.length === 0 ? (
                        <CustomText ta="center" fs="1.125rem" style={{ width: "100%", marginTop: "1.5rem", marginBottom: "10rem" }}>
                            해당 데이터를 사용한 프로젝트가 없습니다.
                        </CustomText>
                    ) : (
                        projectList.data.map((project) => {
                            return (
                                <DataCenterItem key={project.id}>
                                    <div className="title-section">
                                        <Link to={`/project/${project.id}?tab=dataset`} style={{ overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                            {project.is_public ? <PublicIcon fill="white" width="1.5rem" height="1.5rem" /> : <PrivateIcon fill="white" width="1.5rem" height="1.5rem" />}
                                            <CustomText fs="1.25rem" fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {project.manager.login_id} / {project.title}
                                            </CustomText>
                                        </Link>
                                        <div className="recent-model">
                                            <CustomText fs="0.875rem">최근 학습 모델</CustomText>
                                            <CustomText fs="0.875rem" fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {project.recent_trained_model}
                                            </CustomText>
                                        </div>
                                    </div>

                                    <div className="contents-section">
                                        <div className="explanation-static-section">
                                            <div className="explanation" dangerouslySetInnerHTML={{ __html: project.explanation }} />
                                            <div className="statics">
                                                <div className="count-section">
                                                    <LikeIcon width="0.875rem" height="0.875rem" fill="#FF6433" />
                                                    <CustomText fs="0.875rem" fw="400" cl="#FF6433">
                                                        {project.like_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <ForkIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" fw="400" cl="#3BD779">
                                                        {project.fork_count}
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <MemberIcon width="0.875rem" height="0.875rem" fill="#FDFFA9" />
                                                    <CustomText fs="0.875rem" fw="400" cl="#FDFFA9">
                                                        {project.collaborator_count}
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="representative-section">
                                            <div className="representative-data">
                                                <div className="representative">
                                                    <CustomText fs="0.875rem">대표 데이터셋</CustomText>
                                                    <CustomText fs="0.875rem" fw="500" cl="#E2FF9B" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {project.representative_data?.data_name}
                                                    </CustomText>
                                                </div>
                                            </div>
                                            <div className="image-labeling-count">
                                                <div className="count-item">
                                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                        이미지
                                                    </CustomText>
                                                    <CustomText fs="0.875rem" fw="500">
                                                        {project.representative_data?.total_image}
                                                    </CustomText>
                                                </div>
                                                <div className="count-divider" />
                                                <div className="count-item">
                                                    <LabelingIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                        라벨링데이터
                                                    </CustomText>
                                                    <CustomText fs="0.875rem" fw="500">
                                                        {project.representative_data?.annotation_count}
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DataCenterItem>
                            );
                        })
                    )}
                </DataCenterItemWrapper>
                {projectList.data.length !== 0 && (
                    <CustomPagination
                        defaultCurrent={projectList.crntPage}
                        current={projectList.crntPage}
                        total={projectList.totalPage}
                        pageSize={10}
                        onChange={(page) => {
                            fetchProjectList(page);
                        }}
                    />
                )}
            </Wrap>
            <UpdateDataSetExplanation modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} dataCenterData={dataCenterData?.data} />

            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </DataCenterDetailLayout>
    );
}

export default DataCenterDetail;
