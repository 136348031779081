import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import dayjs from "dayjs";

import { ReactComponent as HistoryNodataIcon } from "../../../assets/user-dashboard/history-nodata-icon.svg";
import { ReactComponent as PublicIcon } from "../../../assets/project/public-icon.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/project/private-icon.svg";
import { GetMyTrainScheduleApi } from "../../../services/train-services";
import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";
import { Link } from "react-router-dom";
import { DeleteSchedule } from "../../../services/train-services";
import { useMutation } from "react-query";
import AlertModal from "../../modal/AlertModal";
import ConfirmModal from "../../modal/ConfirmModal";

const Wrap = styled.div`
    .my-schedule {
        border-bottom: 1px solid #707070;
        padding-bottom: 0.3rem;
    }
`;

const ScheduleItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 0;
    min-width: 0;
    margin-top: 1rem;
    gap: 3rem;

    .waiting-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    .schedule-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        gap: 1rem;
        overflow: hidden;
    }

    .schedule-info .project-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    .schedule-info .project-info .project-title {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        overflow: hidden;
    }

    .schedule-info .project-info .model-dataset-info {
        display: flex;

        flex-direction: column;
        overflow: hidden;
    }

    .schedule-info .training-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .schedule-info .training-info .training-parameter {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .schedule-info .training-info .delete {
        display: flex;
    }

    @media screen and (max-width: 1024px) {
        gap: 1rem;
        flex-direction: column;

        .waiting-section {
            flex-direction: row;
            align-items: center;

            div {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .schedule-info {
            flex-direction: column;
        }

        .schedule-info .training-info {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
        }

        .schedule-info .training-info .training-parameter {
            width: fit-content;
        }
    }
`;

const NoHistoryWrapper = styled.div`
    margin-top: 1rem;
    background-color: #21272e;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 3rem 0;
`;

const CustomBtn = styled.div`
    width: 89px;
    height: 26px;

    background: ${(props) => props.bc};
    border-radius: 2px;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
`;

function MyTrainSchedule(props) {
    const { Token, UserInfo } = useSelector((state) => state);
    const deleteSchedule = useMutation(DeleteSchedule);
    const { refetch: getMySchedule, data: scheduleData } = useQuery(
        ["my", "train", "schedule"],
        async (e) => {
            const data = await GetMyTrainScheduleApi(Token.data.access);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onError: (err) => {
                alert("오류가 발생했습니다\n잠시 후 다시 시도해주세요");
            },
        }
    );

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const onScheduleDeleteClick = useCallback(async (id) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        await deleteSchedule.mutateAsync(
            { token: Token.data.access, pk: id },
            {
                onSuccess: (res) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "예약 취소 완료",
                        text: "학습예약이 취소되었습니다",
                        afterFunction: null,
                    });
                    getMySchedule();
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    return (
        <Wrap>
            <div className="my-schedule">
                <CustomText fs="1.125rem" fw="600">
                    예약 내역
                </CustomText>
            </div>
            {scheduleData?.data?.schedules.length === 0 && (
                <NoHistoryWrapper>
                    <HistoryNodataIcon width="2rem" height="2rem" />
                    <CustomText>예약 내역이 없습니다.</CustomText>
                </NoHistoryWrapper>
            )}

            {scheduleData?.data?.schedules.map((schedule) => {
                return (
                    <ScheduleItem key={schedule.index}>
                        <div className="waiting-section">
                            <CustomText>대기 {schedule.index + 1}</CustomText>
                            <div>
                                <CustomText cl="#b1b1b1">{dayjs(schedule.data.created_at).locale("ko").format("YYYY.MM.DD")}</CustomText>
                                <CustomText cl="#b1b1b1">{dayjs(schedule.data.created_at).locale("ko").format("A h:mm")}</CustomText>
                            </div>
                        </div>

                        <div className="schedule-info">
                            <div className="project-info">
                                <div className="project-title">
                                    {schedule.data.data_set.project.is_public ? <PublicIcon fill="white" width="1.2rem" height="1.2rem" /> : <PrivateIcon fill="white" width="1.2rem" height="1.2rem" />}
                                    <Link to={`/project/${schedule.data.data_set.project.id}?tab=dataset`} style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                        <CustomText fs="1rem" fw="500" style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            {schedule.data.data_set.project.title}
                                        </CustomText>
                                    </Link>
                                </div>
                                {schedule.data.type === "train" ? (
                                    <div className="model-dataset-info">
                                        <CustomText style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{schedule.data.model_name}</CustomText>
                                        <CustomText fs="1rem" fw="500" style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            <span style={{ color: "#d4d4d4", marginRight: 10 }}>학습 데이터</span> {schedule.data.data_set.data_name}
                                        </CustomText>
                                    </div>
                                ) : (
                                    <div className="model-dataset-info">
                                        <CustomText style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            <span style={{ color: "#d4d4d4", marginRight: 10 }}>라벨링 모델</span> {schedule.data.model_name}
                                        </CustomText>
                                        <CustomText fs="1rem" fw="500" style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            <span style={{ color: "#d4d4d4", marginRight: 10 }}>라벨링 데이터셋</span> {schedule.data.data_set.data_name}
                                        </CustomText>
                                    </div>
                                )}
                            </div>
                            <div className="training-info">
                                <div className="delete">
                                    <CircleText backgroundColor="#393939" borderColor="#393939">
                                        <CustomText fs="0.875rem" fw="400" cl="#d4d4d4">
                                            {schedule.data.training_algorithm.algorithm_name}
                                        </CustomText>
                                    </CircleText>
                                    {UserInfo.data.id === schedule.data.trainer && schedule.data.id !== undefined && (
                                        <CustomBtn
                                            bc="#707070"
                                            onClick={() =>
                                                setConfirmModalOpen({
                                                    open: true,
                                                    title: "예약 취소",
                                                    text: `예약을 취소하시겠습니까?`,
                                                    afterFunction: () => {
                                                        onScheduleDeleteClick(schedule.data.id);
                                                    },
                                                })
                                            }
                                        >
                                            <CustomText fs="0.8rem" fw="400">
                                                예약 취소
                                            </CustomText>
                                        </CustomBtn>
                                    )}
                                </div>

                                {schedule.data.type === "train" ? (
                                    <div className="training-parameter">
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                에포크 <span style={{ fontWeight: 600, marginLeft: 5 }}>{schedule.data.epoch}</span>
                                            </CustomText>
                                        </div>
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                배치사이즈 <span style={{ fontWeight: 600, marginLeft: 5 }}>{schedule.data.batch_size}</span>
                                            </CustomText>
                                        </div>
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                학습률 <span style={{ fontWeight: 600, marginLeft: 5 }}>{schedule.data.learning_rate}</span>
                                            </CustomText>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="training-parameter">
                                        <div>
                                            <CustomText fs="0.875rem" fw="400" style={{ paddingLeft: "10px" }}>
                                                오토 라벨링
                                            </CustomText>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </ScheduleItem>
                );
            })}
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default MyTrainSchedule;
