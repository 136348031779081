// Home, Login 페이지 Layout
import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import { Content } from "antd/lib/layout/layout";
import SignUpHeader from "./SignUpHeader";
import MyPageHeader from "./MyPageHeader";

const Wrap = styled(Layout)`
    min-height: 100vh;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const StyledContent = styled(Content)`
    min-height: 100vh;
    height: 100%;

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }
`;

function HomeLayout({ children, headerShown = true, signUpHeader = false, myPageHeader = false }) {
    return (
        <Wrap>
            {headerShown && <AppHeader />}
            {signUpHeader && <SignUpHeader />}
            {myPageHeader && <MyPageHeader />}
            <StyledContent>{children}</StyledContent>
            <AppFooter />
        </Wrap>
    );
}

export default HomeLayout;
