import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined, GithubOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { ReactComponent as MobileNetSSDSVG } from "../../../assets/document/mobileNetSSD.svg";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 40px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }
    .ant-btn-primary {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function SSDMobileNet() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;SSD-MobileNet</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    SSD-MobileNet
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    싱글 샷 멀티 박스 감지기 (SSD)는 주로 실시간 물체 감지를 위해 설계된 단일 컨볼 루션 신경망입니다.
                    <br />
                    <br />
                    Region Proposal Network 사용하여 나중에 객체를 분류하는데 사용되는 경계 상자를 만드는 R-CNN과
                    <br />
                    <br />
                    달리 단 한번의 샷으로 객체를 분류합니다. MobileNet은 구글에서 모바일 딥러닝 서비스를 위해 개발한
                    <br />
                    <br />
                    경량 딥러닝 모델이며 Depthwise Separable Convolution(DSC)을 활용하여 연산을 효율화 합니다.
                    <br />
                    <br />
                    SSD-MobileNet 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <MobileNetSSDSVG style={{ width: "60%", height: "auto", borderRadius: 10, cursor: "pointer" }} />
            </div>
            <div className="button-section">
                <a href="https://github.com/tensorflow/models/tree/master/research/object_detection" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined style={{ color: "#fff", fontSize: "1rem", marginRight: "10px" }} />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/document/algorithm-guide?algorithm=2">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            Faster-RCNN
                            <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default SSDMobileNet;
