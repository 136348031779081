import React from "react";
import styled from "styled-components";

import HomeLayout from "../../../components/layout/HomeLayout";
import GuideLayout from "../../../components/layout/GuideLayout";
import Algorithm from "../../../components/document/Algorithm";
import queryString from "query-string";
import {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import SSDMobileNet from "../../../components/document/algorithm/ssdMobileNet";
import FasterRCNN from "../../../components/document/algorithm/fasterRCNN";
import YOLOv4 from "../../../components/document/algorithm/yoloV4";
import YOLOv5 from "../../../components/document/algorithm/yoloV5";
import MaskRCNN from "../../../components/document/algorithm/maskRCNN";
import YOLACT from "../../../components/document/algorithm/yolact";
import YOLOv7 from "../../../components/document/algorithm/yoloV7";
const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
`;

const ContentWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #0c1117;
`;

function AlgorithmGuide() {
    const {search} = useLocation();
    const query = queryString.parse(search);
    const [algorithm, setAlgorithm] = useState(undefined);

    useEffect(() => {
        if (query.algorithm !== undefined) {
            setAlgorithm(parseInt(query.algorithm));
        } else {
            setAlgorithm(query.algorithm);
        }
    }, [search]);

    return (
        <HomeLayout>
            <Wrap>
                <GuideLayout />
                <ContentWrap>
                    {algorithm === undefined && <Algorithm />}
                    {algorithm === 1 && <SSDMobileNet />}
                    {algorithm === 2 && <FasterRCNN />}
                    {algorithm === 3 && <YOLOv4 />}
                    {algorithm === 4 && <YOLOv5 />}
                    {algorithm === 5 && <MaskRCNN />}
                    {algorithm === 6 && <YOLACT />}
                    {algorithm === 7 && <YOLOv7 />}
                </ContentWrap>
            </Wrap>
        </HomeLayout>
    );
}

export default AlgorithmGuide;
