import React from "react";
import styled from "styled-components";
import {CustomSpan} from "./main";
import {Link} from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .menu-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5;
        margin-top: 40px;
    }
`;

function Algorithm(props) {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{cursor: "pointer"}}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;알고리즘 안내</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF">
                    알고리즘 안내
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    해당 가이드에서는 NewLearn에서 제공하는 알고리즘을 소개합니다.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="menu-section">
                <Link to="/document/algorithm-guide?algorithm=1">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        SSD-MoblieNet
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=1">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        Faster-RCNN
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=3">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        YOLOv4
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=4">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        YOLOv5
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=7">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        YOLOv7
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=5">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        Mask-RCNN
                    </li>
                </Link>
                <Link to="/document/algorithm-guide?algorithm=6">
                    <li
                        style={{
                            color: "#58A6FF",
                            fontSize: "1rem",
                            fontWeight: "normal",
                            cursor: "pointer",
                        }}>
                        YOLACT
                    </li>
                </Link>
            </div>
        </Wrap>
    );
}

export default Algorithm;
