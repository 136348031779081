export const initialState = {
    data: {},
};

export const updateRepresentativeDatasetAction = (data) => {
    return {
        type: "UPDATE_REPRESENTATIVE_DATASET",
        data,
    };
};

export const deleteRepresentativeatasetAction = () => {
    return {
        type: "DELETE_REPRESENTATIVE_DATASET",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_REPRESENTATIVE_DATASET":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_REPRESENTATIVE_DATASET":
            return {
                ...state,
                data: {},
            };

        default:
            return state;
    }
};

export default reducer;
