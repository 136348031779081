import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";

import { ReactComponent as DownArrow } from "../../../assets/project/down-arrow.svg";

import { CustomText } from "../../../styles/theme/CustomStyle";
import { useQuery } from "react-query";

import { GetGpuServer } from "../../../services/train-services";
import AlertModal from "../../modal/AlertModal";

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 3px;
    width: 100%;
    border: none;
    /* width: 100%; */
    padding: 0.7rem 1rem 0.7rem 0rem;
    flex: 1;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    /* width: 100%; */
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function GpuSeverDropDown({ gpuServerData, setGpuServerData }) {
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: gpuList, isLoading: loading } = useQuery(
        ["project", "gpuserver"],
        async (e) => {
            const data = await GetGpuServer();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const gpuItems = useMemo(
        () => (
            <StyledMenu>
                {gpuList?.data.map((gpu, index) => {
                    return (
                        <Menu.Item className="menu-item" onClick={() => setGpuServerData(gpu)}>
                            <MenuItem>
                                <CustomText fs="0.875rem" ta="center" style={{ whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis", width: "94%" }}>
                                    {gpu.server_name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [gpuList]
    );
    return (
        <>
            <StyledDropDown overlay={gpuItems} placement="bottom" trigger={["click"]}>
                <MenuItem>
                    {gpuServerData !== "" ? (
                        <CustomText fs="0.875rem" ta="center" style={{ whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis", width: "98%" }}>
                            {gpuServerData.server_name}
                        </CustomText>
                    ) : (
                        <CustomText fs="0.875rem" ta="center" style={{ whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis", width: "98%" }}>
                            {"학습 서버를 선택해주세요"}
                        </CustomText>
                    )}
                    <DownArrow width="0.8rem" height="0.8rem" />
                </MenuItem>
            </StyledDropDown>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default GpuSeverDropDown;
