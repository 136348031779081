import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as LikeIcon } from "../../assets/user-dashboard/like-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/user-dashboard/copy-icon.svg";
import { ReactComponent as ForkIcon } from "../../assets/user-dashboard/fork-icon.svg";
import { ReactComponent as ImageIcon } from "../../assets/project/image-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import DataCenterListLayout from "../../components/layout/DataCenterListLayout";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Pagination, Radio } from "antd";
import AlertModal from "../../components/modal/AlertModal";
import { GetDataCenterCategory, GetDataSetList } from "../../services/datacenter-services";
import DataCenterListSkeleton from "../../styles/theme/DataCenterListSkeleton";
import ScrollContainer from "react-indiana-drag-scroll";
import { useQuery } from "react-query";

const Wrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        /* padding-bottom: 10rem; */
    }
`;

const DataCenterSearchCreateWrapper = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 1rem;

    .input-section {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .create-btn {
        padding: 0 4rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .create-btn {
            padding: 0.7rem 4rem;
        }
    }
`;

const SearchInput = styled.input`
    background-color: #21272e !important;
    border-radius: 3px 0px 0px 3px;
    padding: 10px 10px;
    color: #fff;
    font-size: 1rem;
    font-family: "Pretendard";
    font-weight: 400;
    flex: 1;

    border: 1px solid #21272e;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: ${(props) => props.backcolor} !important;
    border-radius: 5px;
    height: auto;
    min-height: 0;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    gap: 10px;

    border-color: ${(props) => props.bc} !important;
`;

const DataCenterItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
`;

const DataCenterItem = styled.div`
    overflow: hidden;
    border-bottom: 1px solid #707070;
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .contents-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        gap: 1rem;
    }

    .explanation-static-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .statics {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
    }

    .count-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .explanation {
        padding: 0.5rem 0;
        height: 100%;
        align-self: flex-start;
        width: 100%;
        font-family: Pretendard;
        font-weight: 400;
        font-size: 1rem;
        color: #fff;
        /* height: 2.75rem; */
        white-space: pre-line;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 1024px) {
            height: auto;
        }
    }

    .recent-model {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.125rem;
    }

    .image-labeling-count {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .image-labeling-count .count-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .image-labeling-count .count-divider {
        height: 70%;
        border-right: 1px solid #707070;
    }

    .representative-section {
        background-color: #21272e;
        border-radius: 5px;
        padding: 1rem 2.5rem;
        gap: 5px;
    }

    .mobile-representative-section {
        background-color: #21272e;
        border-radius: 5px;
        padding: 1rem 2.5rem;
        gap: 5px;
        display: none;
    }

    .representative-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        gap: 10px;

        .representative {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }

    @media screen and (max-width: 1024px) {
        gap: 1rem;

        .title-section {
            gap: 0.7rem;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .contents-section {
            flex-direction: column;
        }

        .statics {
            width: 100%;
            justify-content: space-around;
        }

        .explanation {
            padding: 0;
            font-weight: 300;
        }

        .representative-section {
            display: none;
        }

        .mobile-representative-section {
            display: block;
        }
    }
`;

const CustomPagination = styled(Pagination)`
    margin-top: 3rem;
    margin-bottom: 6rem;
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

const CustomRadioGrup = styled(Radio.Group)`
    width: 100%;
    display: flex;
    gap: 1rem;

    .ant-radio-button-wrapper ant-radio-button-wrapper-checked {
        color: #000000 !important;
        background: #ffffff !important;
        border-color: #ffffff !important;
    }

    .ant-radio-button-wrapper {
        border-radius: 1rem;
        background: #393939;
        background-color: #393939;
        border-color: #393939;
        color: #d4d4d4;
        box-shadow: none;

        font-size: 0.875rem;
        font-weight: 400;
        font-family: Pretendard;
    }

    .ant-radio-button-wrapper:not(:first-child):before {
        background: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: #000000;
        background: #ffffff;
        border-color: #ffffff;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #000000;
        background: #ffffff;
        border-color: #ffffff;
    }

    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-radius: 1rem;
        border-right-color: #ffffff;
    }

    .ant-radio-button-wrapper:first-child:last-child {
        border-radius: 1rem;
    }
`;

const CategoryListWrap = styled(ScrollContainer)`
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    @media screen and (min-width: 1024px) {
        /* display: none; */
    }
`;

function DataCenterList(props) {
    const { Token, UserInfo } = useSelector((state) => state);

    const [searchStr, setSearchStr] = useState("");
    const [dataSetLoading, setDataSetLoading] = useState(false);

    const [firstCategory, setFirstCategory] = useState("0");
    const [secondCategory, setSecondCategory] = useState("0");

    const [dataSetList, setDataSetList] = useState({
        isMore: false,
        crntPage: 1,
        totalPage: 1,
        data: [],
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        fetchDataSetList(firstCategory, secondCategory, 1);
    }, []);

    const fetchDataSetList = useCallback(
        async (firstCategory, secondCategory, page) => {
            setDataSetLoading(true);
            try {
                const { data: data } = await GetDataSetList(Token.data.access, searchStr, firstCategory, secondCategory, page);

                setDataSetList({
                    crntPage: page,
                    totalPage: data.count,
                    data: data.results,
                });
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setDataSetLoading(false);
            }
        },
        [Token, searchStr, dataSetList.crntPage, firstCategory, secondCategory]
    );

    const {
        data: dataSetCategoryList,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["dataset", "category"],
        async (e) => {
            const data = await GetDataCenterCategory("0");
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: dataSetSecondCategoryList } = useQuery(
        ["dataset", "secondCategory", firstCategory],
        async (e) => {
            const data = await GetDataCenterCategory(firstCategory);
            return data;
        },
        {
            enabled: firstCategory !== "0",
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const handleSearchInputOnChange = useCallback(
        (e) => {
            setSearchStr(e.target.value);
        },
        [searchStr]
    );

    const handleSearchBtnClick = useCallback(() => {
        fetchDataSetList(firstCategory, secondCategory, 1);
    }, [searchStr]);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                handleSearchBtnClick();
            }
        },
        [searchStr]
    );

    const firstCategoryChange = (e) => {
        setFirstCategory(e.target.value);
        setSecondCategory("0");
        fetchDataSetList(e.target.value, secondCategory, 1);
    };

    const secondCategoryChange = (e) => {
        setSecondCategory(e.target.value);
        fetchDataSetList(firstCategory, e.target.value, 1);
    };

    return (
        <DataCenterListLayout>
            <Wrap>
                <CustomText fs="1.125rem" fw="600" wd="100%">
                    드론항공데이터센터
                </CustomText>

                <CustomText wd="100%">필요한 이미지 데이터를 찾고 해당 이미지로 진행한 프로젝트를 확인해보세요.</CustomText>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <CategoryListWrap>
                        <CustomRadioGrup value={firstCategory} buttonStyle="solid" onChange={firstCategoryChange}>
                            <Radio.Button value="0">전체</Radio.Button>
                            {dataSetCategoryList?.data.map((category) => {
                                return <Radio.Button value={category.id}>{category.category_name}</Radio.Button>;
                            })}
                        </CustomRadioGrup>
                    </CategoryListWrap>
                    <CategoryListWrap style={{ visibility: firstCategory === "0" && "hidden" }}>
                        <CustomRadioGrup value={secondCategory} buttonStyle="solid" onChange={secondCategoryChange}>
                            <Radio.Button value="0">전체</Radio.Button>
                            {dataSetSecondCategoryList?.data.map((category) => {
                                return <Radio.Button value={category.id}>{category.category_name}</Radio.Button>;
                            })}
                        </CustomRadioGrup>
                    </CategoryListWrap>
                </div>
                <DataCenterSearchCreateWrapper>
                    <div className="input-section">
                        <SearchInput value={searchStr} onKeyDown={handleKeyDown} onChange={handleSearchInputOnChange} placeholder="데이터 검색" />
                        <CustomBtn backcolor="#303A45" bc="#303A45" onClick={handleSearchBtnClick} style={{ borderRadius: "0px 3px 3px 0px", padding: "0 3rem" }}>
                            <CustomText fs="0.875rem" fw="500" cl="#fff">
                                검색
                            </CustomText>
                        </CustomBtn>
                    </div>
                </DataCenterSearchCreateWrapper>

                <DataCenterItemWrapper>
                    {dataSetLoading ? (
                        <>
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                            <DataCenterListSkeleton />
                        </>
                    ) : dataSetList.data?.length === 0 ? (
                        <CustomText ta="center" fs="1.125rem" style={{ width: "100%", marginTop: "2rem" }}>
                            등록된 프로젝트가 없습니다.
                        </CustomText>
                    ) : (
                        dataSetList?.data?.map((dataSet) => {
                            return (
                                <DataCenterItem key={dataSet.id}>
                                    {dataSet.category.upper_category === null ? (
                                        <CustomText>{dataSet.category.category_name}</CustomText>
                                    ) : (
                                        <CustomText>
                                            {dataSet.category.upper_category.category_name} <span style={{ fontSize: "1.125rem" }}>&gt;</span> {dataSet.category.category_name}
                                        </CustomText>
                                    )}
                                    <div className="title-section">
                                        <Link to={`/data-center/${dataSet.id}`} style={{ overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", gap: 10, width: "100%" }}>
                                            {/* {dataSet.is_public ? <PublicIcon fill="white" width="1.5rem" height="1.5rem" /> : <PrivateIcon fill="white" width="1.5rem" height="1.5rem" />} */}
                                            <PublicIcon fill="white" width="1.5rem" height="1.5rem" />
                                            <CustomText fs="1.25rem" fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {dataSet.data_name}
                                            </CustomText>
                                        </Link>
                                    </div>

                                    <div className="contents-section">
                                        <div className="explanation-static-section">
                                            <div className="explanation" dangerouslySetInnerHTML={{ __html: dataSet.data_explanation }} />
                                        </div>

                                        <div className="representative-section">
                                            <div className="statics">
                                                <div className="count-section">
                                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" fw="400">
                                                        이미지 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.total_image}</span>
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <LikeIcon width="0.875rem" height="0.875rem" fill="#FF6433" />
                                                    <CustomText fs="0.875rem" fw="400">
                                                        좋아요 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.like_count}</span>
                                                    </CustomText>
                                                </div>
                                                <div className="count-section">
                                                    <ForkIcon width="0.875rem" height="0.875rem" />
                                                    <CustomText fs="0.875rem" fw="400">
                                                        복사 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.fork_count}</span>
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mobile-representative-section">
                                        <div className="statics">
                                            <div className="count-section">
                                                <ImageIcon width="0.875rem" height="0.875rem" />
                                                <CustomText fs="0.875rem" fw="400">
                                                    이미지 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.total_image}</span>
                                                </CustomText>
                                            </div>
                                            <div className="count-section">
                                                <LikeIcon width="0.875rem" height="0.875rem" fill="#FF6433" />
                                                <CustomText fs="0.875rem" fw="400">
                                                    좋아요 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.like_count}</span>
                                                </CustomText>
                                            </div>
                                            <div className="count-section">
                                                <CopyIcon width="1rem" height="1rem" />
                                                <CustomText fs="0.875rem" fw="400">
                                                    복사 &nbsp;<span style={{ fontWeight: "500" }}>{dataSet.fork_count}</span>
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </DataCenterItem>
                            );
                        })
                    )}
                </DataCenterItemWrapper>
                {dataSetList.data.length !== 0 && (
                    <CustomPagination
                        defaultCurrent={dataSetList.crntPage}
                        current={dataSetList.crntPage}
                        total={dataSetList.totalPage}
                        pageSize={10}
                        onChange={(page) => {
                            fetchDataSetList(firstCategory, secondCategory, page);
                        }}
                    />
                )}
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </DataCenterListLayout>
    );
}

export default DataCenterList;
