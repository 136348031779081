import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button } from "antd";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as AddMemberIcon } from "../../assets/modal/add-member.svg";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import * as successLottie from "../../assets/modal/success-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import { InviteMemberInProject } from "../../services/project-services";
import { useMutation } from "react-query";
import AlertModal from "./AlertModal";
import { GetCompanyMember } from "../../services/account-services";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 90%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;
        text-align: center;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
    border-bottom: 1px dashed #b1b1b1;
`;

const MemberListBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 90%;
    display: flex;
    flex-direction: column;

    gap: 1rem;
    height: 200px;
    overflow-y: auto;
    padding-left: 5rem;

    .items {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;
    }

    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(196, 196, 196, 0.2);
        padding: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
    .prefix {
        width: 10%;
    }
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

Modal.setAppElement("#root");

function AddMember({ modalIsOpen, setIsOpen, collaborators }) {
    const { Token, UserInfo } = useSelector((state) => state);
    const params = useParams();
    const navigate = useNavigate();

    const inviteMutation = useMutation(InviteMemberInProject);

    const [memberUserId, setMemberUserId] = useState("");
    const [memberUserIdErr, setMemberUserIdErr] = useState("");

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [companyMemberList, setCompanyMemberList] = useState("");
    const [isCompayMember, setIsCompayMember] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (!modalIsOpen) {
            setMemberUserId("");
            setIsCompayMember(false);
        }
    }, [modalIsOpen]);

    const handleMemberUserIdChange = useCallback(
        (e) => {
            if (memberUserIdErr !== "") {
                setMemberUserIdErr("");
            }
            setMemberUserId(e.target.value);
        },
        [memberUserIdErr]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                if (isCompayMember === false) {
                    handleInviteBtnClick();
                } else {
                    handleInviteCompanyMemberClick();
                }
            }
        },
        [memberUserId, isCompayMember]
    );

    const handleInviteBtnClick = useCallback(async () => {
        if (memberUserId === "") {
            setMemberUserIdErr("아이디를 입력하세요");
            return;
        }

        const formData = new FormData();
        formData.append("member_id", memberUserId);
        formData.append("project_id", params.id);
        formData.append("company", 0);
        await inviteMutation.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onError: (error) => {
                    if (error.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "회원 추가 오류",
                            text: "이미 참여중인 회원입니다.",
                            afterFunction: null,
                        });
                        return;
                    } else if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "회원 추가 오류",
                            text: "등록되지 않은 회원입니다.\n아이디를 확인해주세요.",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, memberUserId, memberUserIdErr, isCompayMember]);

    const handleInviteCompanyMemberClick = useCallback(async () => {
        let members = document.querySelectorAll(".checkMember");
        let formData = new FormData();

        formData.append("project_id", params.id);
        formData.append("company", 1);

        if (members.length > 0) {
            for (let i = 0; i < members.length; i++) {
                if (members[i].checked == true) {
                    formData.append("member_id", members[i].value);
                }
            }
        }

        await inviteMutation.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onError: (error) => {
                    if (error.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "회원 추가 오류",
                            text: "이미 참여중인 회원이 존재.",
                            afterFunction: null,
                        });
                        return;
                    } else if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "회원 추가 오류",
                            text: "등록되지 않은 회원입니다.\n아이디를 확인해주세요.",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, memberUserId, memberUserIdErr, isCompayMember]);

    const GetCompanyMemberData = useCallback(async () => {
        try {
            const data = await GetCompanyMember(UserInfo.data.company.id, Token.data.access);
            setCompanyMemberList(data.data);
            setIsCompayMember(true);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
            setIsCompayMember(false);
        }
    });

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterClose={() => {
                if (inviteMutation.isSuccess) {
                    inviteMutation.reset();
                }
            }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Member"
        >
            {inviteMutation.isSuccess ? (
                <>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: successLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="8rem"
                        height="8rem"
                        style={{
                            marginBottom: "0.8rem",
                        }}
                    />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        팀원 추가 완료
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#d4d4d4">
                        팀원 추가가 완료되었습니다.
                        {/* 추가하는 팀원이 초대 메일 수락 후<br />
                        프로젝트에 참여할 수 있습니다. */}
                    </CustomText>

                    <CustomBtn
                        bc="#b2b1b1"
                        onClick={() => {
                            navigate(0);
                            setIsOpen(false);
                        }}
                    >
                        <CustomText fs="0.9rem" cl="#b2b1b1">
                            확인
                        </CustomText>
                    </CustomBtn>
                </>
            ) : (
                <>
                    <AddMemberIcon width="2rem" height="2rem" style={{ marginBottom: "0.8rem" }} />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        팀원 추가
                    </CustomText>
                    {isCompayMember === false ? (
                        <CustomText fs="0.875rem" cl="#d4d4d4">
                            추가할 팀원의 아이디를 입력하세요
                        </CustomText>
                    ) : (
                        <CustomText fs="0.875rem" cl="#d4d4d4">
                            추가할 팀원의 아이디를 선택해주세요
                        </CustomText>
                    )}
                    {isCompayMember === false ? (
                        <InputBox>
                            {memberUserId.length === 0 ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                            <input name="id" value={memberUserId} onKeyDown={handleKeyDown} onChange={handleMemberUserIdChange} maxLength="20" autoFocus autoComplete="off" placeholder="팀원 아이디" />
                        </InputBox>
                    ) : (
                        <MemberListBox>
                            {companyMemberList.member.map((info) => {
                                if (info.id !== UserInfo.data.id && collaborators.findIndex((data) => data.id == info.id) < 0) {
                                    return (
                                        <div className="items">
                                            <input className="checkMember" key={info.login_id} id={info.login_id} type="checkbox" name="checked_id" value={info.login_id} maxLength="20" autoFocus autoComplete="off" placeholder="팀원 아이디" />
                                            <CustomText>{info.login_id}</CustomText>
                                        </div>
                                    );
                                }
                            })}
                        </MemberListBox>
                    )}
                    {memberUserIdErr !== "" && <CustomText cl="#F8514A">{memberUserIdErr}</CustomText>}
                    {isCompayMember === false ? (
                        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                            <CustomBtn bc={"#b2b1b1"} onClick={() => GetCompanyMemberData()}>
                                <CustomText fs="0.9rem" cl={"#b2b1b1"}>
                                    소속 기업 맴버 조회
                                </CustomText>
                            </CustomBtn>

                            <CustomBtn bc={memberUserId !== "" ? "#e2ff9b" : "#b2b1b1"} onClick={handleInviteBtnClick} disabled={memberUserId !== "" ? false : true} loading={inviteMutation.isLoading}>
                                <CustomText fs="0.9rem" cl={memberUserId !== "" ? "#E2FF9B" : "#b2b1b1"}>
                                    추가
                                </CustomText>
                            </CustomBtn>
                        </div>
                    ) : (
                        <CustomBtn bc={"#b2b1b1"} onClick={handleInviteCompanyMemberClick} loading={inviteMutation.isLoading}>
                            <CustomText fs="0.9rem" cl={"#b2b1b1"}>
                                추가
                            </CustomText>
                        </CustomBtn>
                    )}
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default AddMember;
