import axios from "axios";

export const GetDataSetList = (token, searchStr, firstCategory, secondCategory, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/list?searchStr=${searchStr}&firstCategory=${firstCategory}&secondCategory=${secondCategory}&page=${page}`
        // , {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const CreateDataCenterLike = ({ token, dataCenterId, userId }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/dataset/like/${dataCenterId}?userId=${userId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteDataCenterLike = ({ token, dataCenterId }) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/dataset/like/${dataCenterId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetDataCenterDetail = (token, dataCenterId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/${dataCenterId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const UpdateDataCenterDataSet = ({ token, dataCenterId, formData }) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/dataset/${dataCenterId}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetDataCenterExplanationMember = (token, dataCenterId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/exlanation_member/${dataCenterId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetDataCenterDataSetHistory = (token, dataCenterId, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/history/${dataCenterId}?page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetDataCenterForkProject = (token, dataCenterId, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/fork_project/${dataCenterId}?page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const ProjectDataSetCheck = (token, dataSetId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/dataset_check/${dataSetId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const RegistrationDataCenter = (token, dataSetId, formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/dataset/registration_datacenter/${dataSetId}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetDataCenterImage = (token, dataSetId, page) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/datacenter_image/${dataSetId}?page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const UpdateDataCenterImage = (token, dataSetId, formData) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/dataset/datacenter_image/${dataSetId}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const ForkDataSetNewProject = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/dataset/fork/new`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const ForkDataSetAddProject = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/dataset/fork/addProject`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const ForkDataSetAddDataSet = ({ token, formData }) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/dataset/fork/addProject`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetMainDataSet = () => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/main`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetDataCenterCategory = (firstCategory) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/dataset/category/${firstCategory}`
        // , {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const DeleteCategory = (token, dataSetId) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/dataset/category/${dataSetId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetDataCenterSecondCategory = (token, firstCategory, secondCategory) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/dataset/category/${firstCategory}/${secondCategory}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const CreateCategory = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/dataset/category/create`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};
