import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox } from "antd";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";
import HomeLayout from "../../components/layout/HomeLayout";
import { CustomText } from "../../styles/theme/CustomStyle";
import { GetToken, GetUserInfo } from "../../services/auth-services";
import { deleteTokenAction, updateTokenAction } from "../../store/reducer/Token";
import { deleteUserInfoAction, updateUserInfoAction } from "../../store/reducer/UserInfo";
import AlertModal from "../../components/modal/AlertModal";
import ConfirmModal from "../../components/modal/ConfirmModal";

const Wrap = styled.div`
    min-height: inherit;
    background-color: #0c1117;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10rem;

    @media screen and (max-width: 768px) {
        .contents {
            width: 90%;
        }
    }
`;

const LoginForm = styled.form`
    width: 100%;
    background-color: #21272e;
    padding: 30px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoginInput = styled.input`
    background-color: #000 !important;
    border-radius: 5px;
    width: 100%;
    padding: 10px 30px;
    color: #fff;
    font-size: 1.125rem;
    font-family: Pretendard;
    font-weight: 400;

    border: 1px solid #000;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const SelectedSection = styled.div`
    width: 100%;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 10px;

        a {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
`;

const StyledCheckBox = styled(Checkbox)`
    .ant-checkbox .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0);
        border-color: #9b9b9b;
    }

    .ant-checkbox-inner::after {
        width: 8px;
        height: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #000;
        border-color: #000;
    }
`;

const SubmitBtn = styled(Button)`
    background-color: #e2ff9b !important;
    border: none;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 0px;
    border-color: #e2ff9b !important;
`;

const SignupWrap = styled.div`
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 15px 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 60px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const TermsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    margin-top: 60px;
`;

function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [inputs, setInputs] = useState({
        login_id: "",
        password: "",
        rememberId: false,
    });
    const [errors, setErrors] = useState({
        login_id: "",
        password: "",
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    useEffect(() => {
        if (localStorage.getItem("ri") !== null) {
            setInputs({
                login_id: localStorage.getItem("ri"),
                password: "",
                rememberId: true,
            });
        }
    }, []);

    const tokenMutation = useMutation(GetToken);

    const userInfoMutation = useMutation(GetUserInfo);

    const handleInputsChange = useCallback(
        (e) => {
            const { name, value, checked } = e.target;

            if (errors.login_id !== "" || errors.password !== "") {
                setErrors({
                    login_id: "",
                    password: "",
                });
            }

            setInputs((prev) => ({
                ...prev,
                [name]: name !== "rememberId" ? value : checked,
            }));
        },
        [inputs, errors]
    );

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            if (inputs.login_id === "") {
                setErrors((prev) => ({
                    ...prev,
                    login_id: "아이디를 입력해주세요.",
                }));
                return;
            } else if (inputs.password === "") {
                setErrors((prev) => ({
                    ...prev,
                    password: "비밀번호를 입력해주세요.",
                }));
                return;
            }

            const formData = new FormData();

            formData.append("login_id", inputs.login_id);
            formData.append("password", inputs.password);

            let tokenData;

            await tokenMutation.mutateAsync(formData, {
                onSuccess: (data) => {
                    tokenData = data.data;
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "로그인 오류",
                        text: "아이디, 비밀번호를 확인해주세요.",
                        afterFunction: null,
                    });
                    handleLoginFail();
                },
            });

            await userInfoMutation.mutateAsync(tokenData.access, {
                onSuccess: (data) => {
                    // Company가 null이 아니면(회사 소속일시) company의 is_approve, is_del false 이면 로그인 불가 + user의 is_del, is_mail_approve, is_manager_approve false이면 로그인 불가
                    // Company가 null이면(개인일시) user의 is_del, is_mail_approve, is_manager_approve false이면 로그인 불가
                    if (data.data.company !== null) {
                        if (!data.data.company.is_approve) {
                            setAlertModalOpen({
                                open: true,
                                iconType: "warning",
                                title: "관리자 기업 승인 필요",
                                text: "관리자 기업 승인 후 이용 가능합니다.",
                                afterFunction: null,
                            });
                            handleLoginFail();
                            return;
                        } else if (data.data.company.is_del) {
                            setAlertModalOpen({
                                open: true,
                                iconType: "warning",
                                title: "탈퇴한 기업",
                                text: "탈퇴한 기업입니다.\n회원가입을 진행해주세요.",
                                afterFunction: null,
                            });
                            handleLoginFail();
                            return;
                        }
                    }
                    if (data.data.is_del) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "탈퇴한 회원",
                            text: "탈퇴한 회원입니다.\n회원가입을 진행해주세요.",
                            afterFunction: null,
                        });
                        handleLoginFail();
                        return;
                    } else if (!data.data.is_mail_approve) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "이메일 인증 필요",
                            text: "이메일 인증 후 이용 가능합니다.",
                            afterFunction: null,
                        });
                        handleLoginFail();
                        return;
                    } else if (!data.data.is_manager_approve) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "소속 기업 담당자 승인 필요",
                            text: "소속 기업 담당자 승인 후 이용 가능합니다.",
                            afterFunction: null,
                        });
                        handleLoginFail();
                        return;
                    }

                    if (inputs.rememberId) {
                        localStorage.setItem("ri", data.data.login_id);
                    } else {
                        localStorage.clear();
                    }
                    dispatch(updateTokenAction(tokenData));
                    dispatch(updateUserInfoAction(data.data));

                    if (location.state !== null) {
                        navigate(location.state.prevRoute.pathname, {
                            replace: true,
                        });
                    } else {
                        navigate("/dashboard", {
                            replace: true,
                        });
                    }
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    handleLoginFail();
                },
            });
        },
        [inputs, tokenMutation, userInfoMutation]
    );

    const handleLoginFail = useCallback(() => {
        localStorage.clear();
        dispatch(deleteTokenAction());
        dispatch(deleteUserInfoAction());
    }, []);

    return (
        <HomeLayout headerShown={false}>
            <Wrap>
                <Link to="/">
                    <Logo width="16rem" height="100%" />
                </Link>
                <div className="contents">
                    <LoginForm onSubmit={handleSubmit} autoComplete="off">
                        <label style={{ width: "100%" }}>
                            <CustomText fs="1.125rem" mg="0px 0px 5px 0px">
                                아이디
                            </CustomText>
                            <LoginInput autoFocus name="login_id" value={inputs.login_id} onChange={handleInputsChange} />
                            <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A">
                                {errors.login_id}
                            </CustomText>
                        </label>
                        <label style={{ marginTop: 20, marginBottom: 33, width: "100%" }}>
                            <CustomText fs="1.125rem" mg="0px 0px 5px 0px">
                                비밀번호
                            </CustomText>
                            <LoginInput name="password" type="password" value={inputs.password} onChange={handleInputsChange} />
                            <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A">
                                {errors.password}
                            </CustomText>
                        </label>

                        <SelectedSection>
                            <label style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
                                <StyledCheckBox name="rememberId" checked={inputs.rememberId} onChange={handleInputsChange} />
                                <CustomText fs="1.125rem" mg="0px 0px 0px 5px">
                                    아이디 저장
                                </CustomText>
                            </label>

                            {/* <Link to="/find-password" onClick={() => alert("비밀번호 찾기 구현")}> */}
                            <Link to="/find-password">
                                <CustomText fs="1.125rem" fw="500" cl="#7cbeff">
                                    비밀번호를 잊어버리셨나요?
                                </CustomText>
                            </Link>
                        </SelectedSection>
                        {/* TODO: Loading 중일때 로딩 표시하기 */}
                        {/* <SubmitBtn htmlType="submit" color="red" type="primary" loading={tokenMutation.isLoading || userInfoMutation.isLoading}> */}
                        <SubmitBtn htmlType="submit" color="red" type="primary">
                            <CustomText fs="1.125rem" cl="#000">
                                로그인
                            </CustomText>
                        </SubmitBtn>
                    </LoginForm>

                    <SignupWrap>
                        <CustomText fs="1.125rem">NewLearn이 처음이신가요?</CustomText>
                        {/* <div to="/signup/email" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> */}
                        <div
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}
                            onClick={() => {
                                setConfirmModalOpen({
                                    open: true,
                                    title: "만 14세 미만 가입 불가",
                                    text: "만 14세 미만의 회원은 회원가입이 불가능합니다.\n회원가입을 진행하시겠습니까?",
                                    afterFunction: () => {
                                        navigate("/signup/email");
                                    },
                                });
                            }}
                        >
                            <CustomText fs="1.125rem" cl="#7CBEFF" mg="0px 8px 0px 0px">
                                회원가입
                            </CustomText>
                            <ArrowRightCircle stroke="#7CBEFF" />
                        </div>
                    </SignupWrap>

                    <TermsWrap>
                        <Link to="/privacy">
                            <CustomText fs="1.125rem" cl="#D4D4D4">
                                개인정보 처리방침
                            </CustomText>
                        </Link>
                        <Link to="/policy/terms">
                            <CustomText fs="1.125rem" cl="#D4D4D4">
                                서비스 이용약관
                            </CustomText>
                        </Link>
                    </TermsWrap>
                </div>
            </Wrap>
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />

            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </HomeLayout>
    );
}

export default Login;
