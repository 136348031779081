import React, {useCallback, useEffect, useState, useMemo, useRef} from "react";
import styled, {css} from "styled-components";

import {useMutation, useQuery} from "react-query";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import _ from "lodash";

import {ReactComponent as NoDataSetIcon} from "../../../assets/project/nodataset.svg";
import {CustomText} from "../../../styles/theme/CustomStyle";
import {GetAnnotationImageList, GetDataSetInfo} from "../../../services/project-services";
import {updateSelectedDatasetAction} from "../../../store/reducer/SelectedDataset";
import styles from "../../../components/project/dataset/DataSet.module.css";
import {DeleteImages} from "../../../services/labeling-services";
import Lottie from "react-lottie";

import * as LoadingData from "../../../assets/loading/loaindg-lottie.json";
import {ReactComponent as ListViewMethodIcon} from "../../../assets/project/list-view-method-icon.svg";
import {ReactComponent as PictureViewMethodIcon} from "../../../assets/project/picture-view-method-icon.svg";

import useStore from "../../annotator/Store";

const Wrap = styled.div`
    padding-bottom: 1rem;

    .cursor-pointer {
        cursor: pointer;
    }
`;

const AlignWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view-method-wrap {
        background-color: #21272d;
        border-radius: 5px;
        padding: 0.8rem;
        display: flex;
        gap: 15px;
    }

    .data-align-method-wrap {
        background-color: #21272d;
        border-radius: 5px;
        padding: 0.8rem;

        select {
            background-color: inherit;
            border: none;
            font-size: 0.9rem;
            color: #fff;
            outline: none;
            /* :focus {
                border: none;
            } */
        }
    }
`;

const DataListItem = styled.div`
    p {
        color: #fff;
        font-size: 1rem;
    }

    ${props =>
        props.deleteSelected &&
        css`
            p {
                color: #bfffa1;
            }
        `}
`;

const DropzoneContainer = styled.div`
    flex: 0.73;
    display: flex;
    background-color: #21272e;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    gap: 1.5rem;
    padding: 1.5rem;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #b2b1b1 !important;

    @media screen and (max-width: 1024px) {
        padding: 1.5rem 0;
    }
`;

const ImageListWrap = styled.div`
    width: 100%;
    margin-top: 10px;
    overflow-y: auto;
    /* margin-bottom: 4rem; */
    max-height: 25rem;
    border-radius: 3px;
    background-color: #21272e;
    padding: 0.8rem;

    ::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const ImageList = styled(InfiniteScroll)`
    width: 100%;
    max-height: inherit;

    ${props =>
        props.viewMethod === "list"
            ? css`
                  /* background-color: ; */
              `
            : css`
                  display: grid;
                  grid-template-columns: repeat(3, minmax(0, 1fr));
                  grid-template-rows: 230px;
                  grid-gap: 1rem;
                  padding-right: 0.5rem;

                  .items {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 0.5rem;

                      .text {
                          width: 280px;
                      }
                  }
              `}

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: 230px;
        .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            .text {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: 230px;
        .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            .text {
                width: 150px;
            }
        }
    }
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    max-height: 200px;
    border: ${props => props.bd} !important;
`;

const NoModelSection = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
`;

function NoLabelList({selectedImages, setSelectedImages}) {
    const {Token, SelectedDataset} = useSelector(state => state);
    //const [videoFile, setVideoFile] = useState("");
    //const [videoModal, setVideoModal] = useState(false);

    const [viewMethod, setViewMethod] = useState("list"); // list: 글씨 목록으로 보기, picture: 사진 목록으로 보기
    const [dataAlignMethod, setDataAlignMethod] = useState("recent"); // recent: 최신순, old: 오래된순, nameAsce: 이름 오름차순, nameDesc: 이름 내림차순

    const scrollParentRef = useRef(null);
    const isDrag = useStore(state => state.isDrag);
    const setIsDrag = useStore(state => state.setIsDrag);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [annotationList, setAnnotationList] = useState({
        count: 0,
        crntPage: 1,
        hasMore: false,
        scrollY: 0,
        data: [],
    });

    const [isUploading, setIsUploading] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [loadingOptions, setLoadingOptions] = useState({
        loop: true,
        autoplay: true,
        animationData: LoadingData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    });

    useEffect(() => {
        if (!_.isEmpty(SelectedDataset.data)) {
            getAnnotationData(1, true);
            setSelectedImages([]);
        }
    }, [SelectedDataset, dataAlignMethod]);

    const getAnnotationData = useCallback(
        async (page, refresh) => {
            let datatype = "";

            if (page === null) {
                return;
            }
            if (SelectedDataset.data.annotation_type == "bbox") {
                datatype = "boundingboxNone";
            } else {
                datatype = "polygonNone";
            }

            try {
                setIsLoading(true);

                const listRes = await GetAnnotationImageList(
                    Token.data.access,
                    SelectedDataset.data.id,
                    page,
                    "recent",
                    datatype
                );

                setAnnotationList(prev => ({
                    ...prev,
                    count: listRes.data.count,
                    hasMore: listRes.data.next !== null ? true : false,
                    crntPage: listRes.data.next !== null ? page + 1 : null,
                    // scrollY: page !== 1 && annotationList.scrollY + 9500,

                    data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
                }));
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [Token, SelectedDataset]
    );

    const checkedImges = (id, selectedImages, drag) => {
        let temp = selectedImages;
        let index = selectedImages.findIndex(data => data.id == parseInt(id));
        let image = {id: parseInt(id)};
        let annotation_data = annotationList.data;

        if (drag === false) {
            if (index < 0) {
                setSelectedImages(temp.concat([image]));
            } else {
                temp.splice(index, 1);
                setSelectedImages(temp.concat());
            }
        } else {
            const maxValueOfY = Math.max(...temp.map(o => o.id), 0);
            const minValueOfY = Math.min(...temp.map(o => o.id), 9999999999999);

            if (maxValueOfY === 0) {
                setSelectedImages(temp.concat([image]));
            } else {
                let maxIndex = annotationList.data.findIndex(
                    data => data.id == parseInt(maxValueOfY)
                );
                let currentIndex = annotationList.data.findIndex(data => data.id == parseInt(id));
                let minIndex = annotationList.data.findIndex(
                    data => data.id == parseInt(minValueOfY)
                );

                if (maxValueOfY === id || minValueOfY === id) {
                    temp = [];
                    let image = {id: parseInt(id)};
                    temp = temp.concat([image]);
                    setSelectedImages(temp);
                    return;
                }

                if (dataAlignMethod === "recent") {
                    if (maxValueOfY > id) {
                        temp = [];
                        for (let i = maxIndex; i <= currentIndex; i++) {
                            let image = {id: parseInt(annotation_data[i].id)};
                            let index = temp.findIndex(
                                data => data.id == parseInt(annotation_data[i].id)
                            );
                            if (index < 0) {
                                temp = temp.concat([image]);
                            }
                        }
                        setSelectedImages(temp);
                    } else if (maxValueOfY < id) {
                        temp = [];
                        for (let i = minIndex; i >= currentIndex; i--) {
                            let image = {id: parseInt(annotation_data[i].id)};
                            let index = temp.findIndex(
                                data => data.id == parseInt(annotation_data[i].id)
                            );

                            if (index < 0) {
                                temp = temp.concat([image]);
                            }
                        }
                        setSelectedImages(temp);
                    }
                } else {
                    if (maxValueOfY < id) {
                        temp = [];
                        for (let i = maxIndex; i <= currentIndex; i++) {
                            let image = {id: parseInt(annotation_data[i].id)};
                            let index = temp.findIndex(
                                data => data.id == parseInt(annotation_data[i].id)
                            );
                            if (index < 0) {
                                temp = temp.concat([image]);
                            }
                        }
                        setSelectedImages(temp);
                    } else if (maxValueOfY > id) {
                        temp = [];
                        for (let i = minIndex; i >= currentIndex; i--) {
                            let image = {id: parseInt(annotation_data[i].id)};
                            let index = temp.findIndex(
                                data => data.id == parseInt(annotation_data[i].id)
                            );

                            if (index < 0) {
                                temp = temp.concat([image]);
                            }
                        }
                        setSelectedImages(temp);
                    }
                }
            }
        }

        return;
    };

    document.onkeydown = checkKeyDown;

    function checkKeyDown(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(true);
        }
    }

    document.onkeyup = checkKeyup;

    function checkKeyup(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(false);
        }
    }

    const haneldViewMethodBtnClick = useCallback(value => {
        setViewMethod(value);
    }, []);

    const handleDataAlignMethodChange = useCallback(e => {
        setDataAlignMethod(e.target.value);
    }, []);

    return (
        <Wrap>
            <AlignWrap>
                <div className="view-method-wrap">
                    <ListViewMethodIcon
                        className="cursor-pointer"
                        width="1rem"
                        height="1rem"
                        fill={viewMethod === "list" ? "#fff" : "#999"}
                        onClick={() => haneldViewMethodBtnClick("list")}
                    />
                    <PictureViewMethodIcon
                        className="cursor-pointer"
                        width="1rem"
                        height="1rem"
                        fill={viewMethod === "picture" ? "#fff" : "#999"}
                        onClick={() => haneldViewMethodBtnClick("picture")}
                    />
                </div>
            </AlignWrap>

            <ImageListWrap ref={scrollParentRef}>
                {annotationList.data.length === 0 && isLoading === false ? (
                    <NoModelSection>
                        <NoDataSetIcon width="2rem" height="2rem" fill="white" />
                        <CustomText fs="1rem" fw="400">
                            업로드된 데이터가 없습니다.
                        </CustomText>
                    </NoModelSection>
                ) : (
                    // {/* <ImageList viewMethod={viewMethod} dataLength={annotationList.count} next={() => getAnnotationData(annotationList.crntPage, false)} useWindow={false} hasMore={annotationList.hasMore} scrollableTarget={scrollParentRef.current} initialScrollY={annotationList.scrollY}> */}

                    <ImageList
                        viewMethod={viewMethod}
                        dataLength={annotationList.data.length}
                        next={() =>
                            setTimeout(() => {
                                getAnnotationData(annotationList.crntPage, false);
                            }, 200)
                        }
                        useWindow={false}
                        hasMore={annotationList.hasMore}
                        scrollableTarget={scrollParentRef.current}
                        loader={<Lottie options={loadingOptions} width="10rem" height="10rem" />}>
                        {viewMethod === "list"
                            ? annotationList.data.map((annotation, index) => {
                                  return (
                                      <DataListItem
                                          className="cursor-pointer"
                                          key={annotation.id}
                                          deleteSelected={
                                              selectedImages.findIndex(
                                                  data => data.id == annotation.id
                                              ) >= 0
                                          }
                                          onClick={e =>
                                              checkedImges(annotation.id, selectedImages, isDrag)
                                          }>
                                          <p>
                                              {(index + 1).toString() +
                                                  ". " +
                                                  annotation.media_path.split("/")[3]}
                                          </p>
                                      </DataListItem>
                                  );
                              })
                            : annotationList.data.map((annotation, index) => {
                                  return (
                                      <>
                                          {selectedImages.findIndex(
                                              data => data.id == annotation.id
                                          ) >= 0 ? (
                                              <>
                                                  <label
                                                      className={styles.image_label}
                                                      onClick={e =>
                                                          checkedImges(
                                                              annotation.id,
                                                              selectedImages,
                                                              isDrag
                                                          )
                                                      }>
                                                      <StyledImage
                                                          key={annotation.id}
                                                          src={
                                                              process.env.REACT_APP_END_POINT +
                                                              "/" +
                                                              annotation.media_path
                                                          }
                                                          alt="annotation_image"
                                                          bd="0px;"
                                                      />
                                                      <div className="items">
                                                          <CustomText
                                                              className="text"
                                                              style={{
                                                                  whiteSpace: "nowrap",
                                                                  wordBreak: "break-all",
                                                                  overflow: "hidden",
                                                                  textOverflow: "ellipsis",
                                                                  paddingTop: "8px",
                                                              }}>
                                                              {(index + 1).toString() +
                                                                  ". " +
                                                                  annotation.media_path.split(
                                                                      "/"
                                                                  )[3]}
                                                          </CustomText>
                                                      </div>
                                                  </label>
                                              </>
                                          ) : (
                                              <div className="items">
                                                  <StyledImage
                                                      style={{cursor: "pointer"}}
                                                      src={
                                                          process.env.REACT_APP_END_POINT +
                                                          "/" +
                                                          annotation.media_path
                                                      }
                                                      alt="annotation_image"
                                                      bd="0px; "
                                                      onClick={e =>
                                                          checkedImges(
                                                              annotation.id,
                                                              selectedImages,
                                                              isDrag
                                                          )
                                                      }
                                                  />
                                                  <CustomText
                                                      className="text"
                                                      style={{
                                                          whiteSpace: "nowrap",
                                                          wordBreak: "break-all",
                                                          overflow: "hidden",
                                                          textOverflow: "ellipsis",
                                                      }}>
                                                      {(index + 1).toString() +
                                                          ". " +
                                                          annotation.media_path.split("/")[3]}
                                                  </CustomText>
                                              </div>
                                          )}
                                      </>
                                  );
                              })}
                    </ImageList>
                )}
                {/* || isUploading */}
            </ImageListWrap>
        </Wrap>
    );
}

export default NoLabelList;
