import React, {useState, useCallback, useEffect} from "react";
import styled from "styled-components";
import {Button, Input, Slider} from "antd";

import {ReactComponent as ReservationIcon} from "../../../assets/project/reservation-icon.svg";
import {CustomText} from "../../../styles/theme/CustomStyle";
import GpuSeverDropDown from "./GpuSeverDropDown";
import LabelDataDropDown from "./LabelDataDropDown";
import AlgorithmDropDown from "./AlgorithmDropDown";

import TrainState from "./TrainState";
import {useSelector} from "react-redux";
import {SetTrain} from "../../../services/train-services";
import {useMutation} from "react-query";
import {GetCurrentTrain} from "../../../services/train-services";
import {useQuery} from "react-query";
import {GetTrainSchedule} from "../../../services/train-services";
import AlertModal from "../../modal/AlertModal";

const TrainReserVationSection = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 40px;

    width: 100%;
    height: 100%;
    margin-bottom: 10rem;

    .train-list-section {
        /* width: 55%; */
        height: 100%;

        justify-content: center;
        border-radius: 3px;
        /* padding: 20px; */
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .train-no-list-section {
        background-color: #21272e;
        /* width: 60%; */
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        padding: 20px;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    .train-reservation-section {
        /* width: 40%; */
        height: 100%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .train-resrvation-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: inset 0 -1px 0 0 #575757;
        padding-bottom: 10px;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const TrainInputWraaper = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .wrap {
        display: flex;
        flex-direction: row;
        gap: 10px;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }
    .epoch {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    .batch-size {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .learning-rate {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .train-test-ratio {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;

    padding: 0 50px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${props => props.bc} !important;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }

    @media screen and (max-width: 1024px) {
        padding: 10px;
        margin-bottom: 10px;
    }
`;

const StyledInput = styled(Input)`
    background-color: #21272e;
    border-radius: 3px;
    width: 100%;
    border: none;
    /* width: 100%; */
    padding: 0.5rem 1rem 0.5rem 0rem;
    flex: 1;
    color: #fff;
    padding: 10px;
    text-align: center;
    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }

    .ant-input-prefix {
        font-family: "Pretendard";
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0em;
        text-align: center;
        width: 100%;
    }

    .ant-input {
        text-align: center;
        font-family: "Pretendard";
        font-size: 0.9rem;
        font-weight: normal;
        color: #fff;
        text-align: center;
        letter-spacing: 0em;
        background-color: rgba(0, 0, 0, 0);
        color: #9c9c9c;
    }
`;

function ModelTrainMain(props) {
    const [gpuServerData, setGpuServerData] = useState("");
    const [algorithmData, setAlgoritmData] = useState("");
    const [isGraph, setIsGraph] = useState(false);

    const [epoch, setEpoch] = useState(50);
    const [modelName, setModelName] = useState("");
    const [batchSize, setBatchSize] = useState(8);
    const [learningRate, setLearningRate] = useState(0.001);
    const [trainingTestRate, setTrainingTestRate] = useState(70);
    const setTrain = useMutation(SetTrain);
    const [dataSetId, setDataSetId] = useState("");
    const [imageSize, setImageSize] = useState(640);
    const {Token, UserInfo} = useSelector(state => state);
    const [trainGraph, setTrainGraph] = useState(false);
    const [graphSrc, setGraphSrc] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [trainScheduleData, setTrainScheduleData] = useState({
        crntPage: 1,
        hasMore: false,
        data: [],
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const {data: TrainSchdule, isLoading: loading} = useQuery(
        ["project", "train"],
        async e => {
            const data = await GetCurrentTrain();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: err => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        if (TrainSchdule !== undefined && TrainSchdule.data.length !== 0) {
            if (TrainSchdule.data[0].current_epoch > 0) {
                setTrainGraph(true);
            } else {
                setTrainGraph(false);
            }
            if (
                TrainSchdule.data[0].training_algorithm.id === 1 ||
                TrainSchdule.data[0].training_algorithm.id === 5
            ) {
                setGraphSrc(
                    process.env.REACT_APP_END_POINT +
                        `/api/project/train/torch/${TrainSchdule.data[0].id}/${1}`
                );
            } else {
                setGraphSrc(
                    process.env.REACT_APP_END_POINT +
                        `/api/project/train/tensorflow/${TrainSchdule.data[0].id}/${1}`
                );
            }
        }
        getScheduleData(1, true);
    }, [TrainSchdule]);

    const getScheduleData = useCallback(async (page, refresh) => {
        if (page === null) {
            return;
        }

        try {
            setIsLoading(true);
            const listRes = await GetTrainSchedule(page);

            setTrainScheduleData(prev => ({
                ...prev,
                hasMore: listRes.data.next !== null ? true : false,
                crntPage: listRes.data.next !== null ? page + 1 : null,
                data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
            }));
        } catch {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        if (name == "modelName") {
            setModelName(value);
        }

        if (name == "epoch") {
            setEpoch(value);
        }

        if (name == "batchSize") {
            setBatchSize(value);
        }

        if (name == "learning_rate") {
            setLearningRate(value);
        }

        if (name == "imageSize") {
            setImageSize(value);
        }
    }, []);

    const onSliderAfterChange = useCallback(value => {
        setTrainingTestRate(value);
    }, []);

    const onModelTrainingBtnClick = useCallback(async () => {
        const formData = new FormData();

        if (modelName === "") {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델명 필요",
                text: "모델명을 입력해주세요.",
                afterFunction: null,
            });
            return;
        }

        let pattenr_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let blank_pattern = /[\s]/g;
        let special_pattern = /[`~!@#$%^&*|\\\'\";:\/?<>+-,]/gi;
        let comma_pattern = /[\[\]\(\)\{\}\']+/g;

        if (blank_pattern.test(modelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 공백을 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (special_pattern.test(modelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 특수문자를 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (comma_pattern.test(modelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 괄호를 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (pattenr_kor.test(modelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름을 영어로 작성해주세요",
                afterFunction: null,
            });
            return;
        }

        if (algorithmData === "") {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "알고리즘 필요",
                text: "알고리즘을 선택해주세요.",
                afterFunction: null,
            });
            return;
        }

        if (gpuServerData === "") {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "gpu서버 필요",
                text: "gpu서버를 선택해주세요.",
                afterFunction: null,
            });

            return;
        }

        if (parseFloat(learningRate) > 0.001 || parseFloat(learningRate) < 0.0001) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "학습률 조정 필요",
                text: "학습률을 0.0001이상 0.001이하로 설정해주세요.",
                afterFunction: null,
            });
            return;
        }

        if (epoch > 300 || epoch < 50) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "에포크 조정 필요",
                text: "에포크는 50이상 300이하로 설정해주세요.",
                afterFunction: null,
            });
            return;
        }

        if (batchSize < 8 || batchSize > 32 || batchSize % 8 !== 0) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "배치 사이즈 조정 필요",
                text: "배치 사이즈 8 이상 32이하 8의 배수로 설정해주세요.",
                afterFunction: null,
            });
            return;
        }

        if (imageSize % 640 !== 0 || imageSize < 640 || imageSize > 3840) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "이미지 사이즈 조정 필요",
                text: "배치 사이즈 640 이상 3840이하 640의 배수로 설정해주세요.",
                afterFunction: null,
            });
            return;
        }

        formData.append("data_set_id", dataSetId.id);
        formData.append("batch_size", batchSize);
        formData.append("epochs", epoch);
        formData.append("name", modelName);
        formData.append("training_algorithm", algorithmData.id);
        formData.append("train_ratio", trainingTestRate);
        formData.append("trainer_id", UserInfo.data.id);
        formData.append("gpu_server_id", gpuServerData.id);
        formData.append("learning_rate", learningRate);
        formData.append("image_size", imageSize);
        formData.append("annotation_type", dataSetId.annotation_type);

        await setTrain.mutateAsync(
            {token: Token.data.access, formData: formData},
            {
                onSuccess: res => {
                    if (res.data.msg === 1) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "에포크 설정 에러",
                            text: "에포크를 50~300 사이로 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 2) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "배치사이즈 설정 에러",
                            text: "배치 사이즈를 8~32 사이로 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 3) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "배치사이즈 설정 에러",
                            text: "배치 사이즈를 8의 배수로 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 4) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "학습률 에러",
                            text: "학습률을 0.0001이상 0.001이하로 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 5) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "학습 예약 에러",
                            text: "학습 예약이 이미 존재 합니다.",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 6) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 사이즈 에러",
                            text: "이미지 사이즈를 640-3840 범위에서 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 7) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 사이즈 에러",
                            text: "이미지 사이즈를 640의 배수로 설정해주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    if (res.data.msg === 8) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "최소 이미지 개수 에러",
                            text: "데이터셋에 최소 20개 이미지를 업로드해 주세요",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "학습 예약 완료",
                        text: "학습예약이 완료되었습니다.",
                        afterFunction: null,
                    });
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    });

    return (
        <>
            {/* {isGraph === true && (
                <TrainGraphSection>
                    {trainGraph === false ? (
                        <CustomText fs="1rem" fw="400">
                            학습 그래프 없음
                        </CustomText>
                    ) : (
                        <img style={{ width: "100%", height: "100%" }} src={graphSrc}></img>
                    )}
                </TrainGraphSection>
            )} */}
            <TrainReserVationSection>
                {/* {trainScheduleData.data.length > 0 ? (
                    <div className="train-list-section">
                        <Schedule trainScheduleData={trainScheduleData} getScheduleData={getScheduleData} isLoading={isLoading} />
                    </div>
                ) : (
                    <div className="train-no-list-section">
                        <CustomText fs="1rem" fw="400">
                            예약 리스트 없음
                        </CustomText>
                    </div>
                )} */}
                <div className="train-list-section">
                    <TrainState isGraph={isGraph} setIsGraph={setIsGraph} />
                </div>
                <div className="train-reservation-section">
                    <div className="train-resrvation-title">
                        <CustomText fs="1.125rem" fw="600">
                            학습 예약
                        </CustomText>
                    </div>
                    <TrainInputWraaper>
                        <CustomText fs="0.9rem" fw="600">
                            모델이름
                        </CustomText>
                        <StyledInput name="modelName" onChange={onChange} />
                        <CustomText fs="0.9rem" fw="600">
                            학습 서버 선택
                        </CustomText>
                        <GpuSeverDropDown
                            gpuServerData={gpuServerData}
                            setGpuServerData={setGpuServerData}
                        />
                        <CustomText fs="0.9rem" fw="600">
                            데이터셋 선택
                        </CustomText>
                        <LabelDataDropDown dataSetId={dataSetId} setDataSetId={setDataSetId} />
                        <CustomText
                            fs="0.9rem"
                            fw="600"
                            algorithmData={algorithmData}
                            setAlgoritmData={algorithmData}>
                            알고리즘 선택
                        </CustomText>
                        <AlgorithmDropDown
                            dataSetId={dataSetId}
                            algorithmData={algorithmData}
                            setAlgoritmData={setAlgoritmData}
                        />
                        <CustomText fs="0.9rem" fw="600">
                            에포크
                        </CustomText>
                        <StyledInput
                            name="epoch"
                            type="number"
                            onChange={onChange}
                            defaultValue={epoch}
                        />
                        <CustomText fs="0.9rem" fw="600">
                            배치사이즈
                        </CustomText>
                        <StyledInput
                            name="batchSize"
                            type="number"
                            onChange={onChange}
                            defaultValue={batchSize}
                        />
                        <CustomText fs="0.9rem" fw="600">
                            학습률
                        </CustomText>
                        <StyledInput
                            name="learning_rate"
                            type="number"
                            step={0.001}
                            onChange={onChange}
                            defaultValue={learningRate}
                        />

                        {algorithmData !== "" && algorithmData.id === 1 && (
                            <>
                                <CustomText fs="0.9rem" fw="600">
                                    이미지 사이즈
                                </CustomText>
                                <StyledInput
                                    name="imageSize"
                                    type="number"
                                    onChange={onChange}
                                    defaultValue={imageSize}
                                />
                            </>
                        )}
                        <div>
                            <CustomText fs="0.9rem" fw="600">
                                학습 및 테스트 데이터 비율
                            </CustomText>
                            <Slider
                                trackStyle={{background: "#BFFFA1"}}
                                max={99}
                                min={70}
                                defaultValue={70}
                                onAfterChange={onSliderAfterChange}
                            />
                            <div className="train-test-ratio">
                                <CustomText fs="0.875rem" fw="400">
                                    학습{" "}
                                    <span style={{fontWeight: 600, marginLeft: 3}}>
                                        {trainingTestRate}%
                                    </span>
                                </CustomText>
                                <span style={{fontWeight: 100, color: "#707070", margin: "0 2rem"}}>
                                    |
                                </span>
                                <CustomText fs="0.875rem" fw="400">
                                    테스트{" "}
                                    <span style={{fontWeight: 600, marginLeft: 5}}>
                                        {100 - trainingTestRate}%
                                    </span>
                                </CustomText>
                            </div>
                        </div>
                        {TrainSchdule !== undefined && (
                            <div
                                style={{
                                    backgroundColor: "#303A45",
                                    borderRadius: "3px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "2rem 0",
                                    gap: "10px",
                                }}>
                                {TrainSchdule.data.length === 0 &&
                                TrainSchdule.data.length === 0 ? (
                                    <>
                                        <CustomText>진행 중인 학습이 없습니다</CustomText>
                                        <CustomText fs="1.125rem" cl="#BFFFA1">
                                            지금 바로 학습을 시작할 수 있습니다
                                        </CustomText>
                                    </>
                                ) : trainScheduleData.data.length === 0 ? (
                                    <>
                                        <CustomText>
                                            진행 중인 학습 외의 대기 중인 학습이 없습니다
                                        </CustomText>
                                        <CustomText fs="1.125rem" cl="#BFFFA1">
                                            예상 학습 순번 &nbsp;1
                                        </CustomText>
                                    </>
                                ) : (
                                    <>
                                        <CustomText>
                                            {trainScheduleData.data.length}개의 대기중인 학습이
                                            있습니다
                                        </CustomText>
                                        <CustomText fs="1.125rem" cl="#BFFFA1">
                                            예상 학습 순번 &nbsp;{trainScheduleData.data.length + 1}
                                        </CustomText>
                                    </>
                                )}
                            </div>
                        )}
                        <CustomBtn bc="#b1b1b1" onClick={() => onModelTrainingBtnClick()}>
                            <ReservationIcon />
                            <CustomText
                                fs="0.875rem"
                                cl="#FFFFFF"
                                fw="400"
                                style={{padding: "8px"}}>
                                학습 예약
                            </CustomText>
                        </CustomBtn>
                    </TrainInputWraaper>
                </div>
            </TrainReserVationSection>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default ModelTrainMain;
