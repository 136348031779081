import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .image-section {
        width: 45;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function DataPreProcessiong() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/terminology-guide" style={{ cursor: "pointer" }}>
                    <CustomSpan cl="#58A6FF">&nbsp;기능 및 용어 안내&nbsp;</CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;데이터 전처리</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    데이터 전처리
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    NewLearn에서는 각종 이미지 데이터를 전처리하여 데이터 양을 늘릴 수 있는 데이터 증강기법을 제공합니다.
                    <br />
                    <br />
                    NewLearn에서 제공하는 데이터 증강기법은 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>
                        이미지에서 특정 부분을 자르는 <CustomSpan cl="#58A6FF">자르기</CustomSpan> 기능
                    </li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>
                        {" "}
                        현재 이미지의 크기를 조정하는 <CustomSpan cl="#58A6FF">확대, 축소</CustomSpan>기능{" "}
                    </li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>
                        이미지를 다양한 각도로 회전 시키는 <CustomSpan cl="#58A6FF">이미지 회전</CustomSpan> 기능{" "}
                    </li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>
                        {" "}
                        현재 이미지를 흑백으로 변환시키는 <CustomSpan cl="#58A6FF">GrayScale</CustomSpan> 기능{" "}
                    </li>
                    <br />
                    데이터 증강 기법을 적용하여 학습 데이터의 양을 늘려 보다 좋은 인공지능 모델을 만들 수 있습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>

            <Link to="/document/terminology-guide?terminology=5">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        데이터 라벨링
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default DataPreProcessiong;
