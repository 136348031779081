import React, {useCallback, useState} from "react";
import {Avatar, Button, Layout} from "antd";
import styled, {css} from "styled-components";
import {useSelector} from "react-redux";
import {useMutation, useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";
import _ from "lodash";

import {ReactComponent as MemberIcon} from "../../assets/mypage/member.svg";
import {ReactComponent as PlusIcon} from "../../assets/user-dashboard/plus-circle.svg";
import {ReactComponent as ArrowIcon} from "../../assets/project/arrow.svg";
import {ReactComponent as DeleteIcon} from "../../assets/project/delete.svg";
import {ReactComponent as EnterIcon} from "../../assets/project/enter.svg";
import {ReactComponent as LabelIcon} from "../../assets/project/label-icon.svg";
import {ReactComponent as PlusKeyIcon} from "../../assets/project/plus.svg";
import {ReactComponent as MinusIcon} from "../../assets/project/minus.svg";
import {ReactComponent as ShiftIcon} from "../../assets/project/shift.svg";
import {ReactComponent as LabelingIcon} from "../../assets/project/labeling-icon.svg";
import {
    CreateProjectLike,
    DeleteProjectLike,
    GetProjectExpanationMember,
    ProjectFork,
} from "../../services/project-services";
import {CircleText, CustomText} from "../../styles/theme/CustomStyle";
import {Colors} from "../../styles/theme/Colors";
import AddMember from "../modal/AddMember";
import AlertModal from "../modal/AlertModal";
import {ReactComponent as ImageIcon} from "../../assets/project/image-icon.svg";
import numberFormatting from "../../common/numberFormatting";

import {Tooltip} from "antd";

const Wrap = styled(Layout)`
    background-color: rgba(0, 0, 0, 0);
    max-width: 360px;
    min-width: 360px;
    margin-top: 1.3rem;

    ${props =>
        props.page === "preprocess" || props.page === "labeling"
            ? css`
                  @media screen and (max-width: 1700px) {
                      display: none;
                  }

                  @media screen and (min-width: 1920px) {
                      max-width: 400px;
                  }
              `
            : css`
                  @media screen and (max-width: 1024px) {
                      display: none;
                  }

                  @media screen and (min-width: 1920px) {
                      max-width: 400px;
                  }
              `}
`;

const ProjectInfoWrapper = styled.div`
    background-color: #303a45;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .public-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .public-section .like-fork-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 13px;
    }
`;

const LikeForkBtn = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #21262c !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    /* padding: 10px 25px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #b2b1b1 !important;
`;

const MemberWrapper = styled.div`
    flex: 1;
    margin-bottom: 4rem;
    padding: 0px 1rem 0 1rem;

    .title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title-section div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .member-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .shortcut-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .shortcut-title-section {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .shortcut-title-section div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .shortcut-items {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 10px;
    }

    .shortcut-items-wrap {
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
`;

const MemberItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

const DataSetWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    .image-total-count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .label-total-count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .totalcount-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
`;

const LabelSetWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    .image-total-count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .label-total-count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .totalcount-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
`;

function ProjectSide({page}) {
    const {Token, UserInfo} = useSelector(state => state);
    const params = useParams();
    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [isLiked, setIsLiked] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const {
        data: projectData,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["project", "explanation", "member", params.id],
        async e => {
            const data = await GetProjectExpanationMember(Token.data.access, params.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: res => {
                if (res.data.like_users.some(like => like.user === UserInfo.data?.id)) {
                    setIsLiked(true);
                } else {
                    setIsLiked(false);
                }
            },
            onError: err => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const likeMutation = useMutation(CreateProjectLike);
    const unLikeMutation = useMutation(DeleteProjectLike);
    const forkMutation = useMutation(ProjectFork);

    const handleLikeBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        }

        // 이미 좋아요 누른 상태일때
        if (isLiked) {
            await unLikeMutation.mutateAsync(
                {
                    token: Token.data.access,
                    projectId: params.id,
                },
                {
                    onSuccess: res => {
                        refetch();
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        } else {
            const formData = new FormData();

            formData.append("project_id", params.id);

            await likeMutation.mutateAsync(
                {token: Token.data.access, projectId: params.id},
                {
                    onSuccess: res => {
                        refetch();
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        }
    }, [isLiked, Token, UserInfo]);

    const handleForkBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        }

        await forkMutation.mutateAsync(
            {
                token: Token.data.access,
                projectId: params.id,
            },
            {
                onSuccess: res => {
                    navigate(`/project/${res.data.id}?tab=dataset`);
                },
                onError: err => {
                    if (err.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "프로젝트 이름 오류",
                            text: "동일한 이름의 프로젝트가 이미 존재합니다.",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, UserInfo]);

    return page !== "setting" ? (
        <Wrap page={page}>
            {/* TODO: skelton 추가하기 */}
            {!loading && (
                <>
                    {/* <ProjectInfoWrapper> */}
                    {/* <div className="public-section">
                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                <CustomText fs="0.875rem" fw="400" cl="#E2FF9B" mg="3px 0px" style={{ lineHeight: "0.875rem" }}>
                                    {projectData.data.is_public ? "공개" : "비공개"}
                                </CustomText>
                            </CircleText>
                            <div className="like-fork-section">
                                <LikeForkBtn onClick={handleLikeBtnClick}>
                                    <LikeIcon width="0.875rem" height="0.875rem" fill={isLiked ? "#8DFFF8" : "#fff"} />
                                    <CustomText fs="0.875rem">{projectData?.data?.like_users?.length}</CustomText>
                                </LikeForkBtn>
                                <LikeForkBtn onClick={handleForkBtnClick} loading={forkMutation.isLoading}>
                                    <ForkIcon width="0.875rem" height="0.875rem" />
                                    <CustomText fs="0.875rem">{projectData?.data?.fork_count}</CustomText>
                                </LikeForkBtn>
                            </div>
                        </div>

                        <div className="title-section">
                            {projectData.data.is_public ? <PublicIcon width="1.25rem" height="1.25rem" /> : <PrivateIcon width="1.25rem" height="1.25rem" />}
                            <CustomText fs="1.25rem" fw="600" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {projectData?.data?.manager?.login_id} / {projectData?.data?.title}
                            </CustomText>
                        </div> */}

                    {projectData.data.explanation && (
                        <ProjectInfoWrapper>
                            {/* <CustomText fs="1.125rem" fw="600" mg="0px 0px 10px 0px">
                                프로젝트 설명
                            </CustomText> */}

                            <CustomText
                                fs="1rem"
                                fw="400"
                                style={{whiteSpace: "pre-line", wordBreak: "break-all"}}
                                dangerouslySetInnerHTML={{__html: projectData.data.explanation}}
                            />
                        </ProjectInfoWrapper>
                    )}
                    {/* </ProjectInfoWrapper> */}

                    <DataSetWrapper>
                        <div className="image-total-count">
                            <div className="totalcount-item">
                                <ImageIcon width="0.875rem" height="0.875rem" />
                                <CustomText>총 데이터 수</CustomText>
                                <Tooltip title={projectData?.data.total_data_count}>
                                    <CustomText
                                        fw="500"
                                        style={{
                                            flex: 1,
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-all",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                        {numberFormatting(projectData?.data.total_data_count)}
                                    </CustomText>
                                </Tooltip>
                            </div>
                        </div>
                    </DataSetWrapper>
                    <LabelSetWrapper>
                        <div className="label-total-count">
                            <div className="totalcount-item">
                                <LabelingIcon width="0.875rem" height="0.875rem" />
                                <CustomText>총 라벨링데이터 수</CustomText>
                                <Tooltip title={projectData?.data.total_label_count}>
                                    <CustomText
                                        fw="500"
                                        style={{
                                            flex: 1,
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-all",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                        {numberFormatting(projectData?.data.total_label_count)}
                                    </CustomText>
                                </Tooltip>
                            </div>
                        </div>
                    </LabelSetWrapper>

                    <MemberWrapper>
                        <div className="title-section">
                            <div>
                                <MemberIcon width="1.3rem" height="1.3rem" fill="#fff" />
                                <CustomText mg="0 0 0 10px">팀원</CustomText>
                            </div>
                            {UserInfo.data.id === projectData.data.manager.id && (
                                <div
                                    style={{marginRight: 10, cursor: "pointer"}}
                                    onClick={() => setIsOpen(true)}>
                                    <CustomText mg="0 5px 0 0">팀원 추가</CustomText>
                                    <PlusIcon width="1.125rem" height="1.125rem" />
                                </div>
                            )}
                        </div>

                        <div className="member-list">
                            <MemberItem>
                                <Avatar
                                    size="default"
                                    src={
                                        projectData.data.manager.image_path
                                            ? process.env.REACT_APP_END_POINT +
                                              projectData.data.manager.image_path
                                            : ""
                                    }
                                    icon={<UserOutlined />}
                                    style={{
                                        backgroundColor: Colors[projectData.data.manager.id % 10],
                                    }}
                                />
                                <CustomText
                                    style={{
                                        width: "80%",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}>
                                    {projectData.data.manager.login_id}
                                </CustomText>
                            </MemberItem>
                            {projectData.data.collaborators?.map(user => {
                                return (
                                    <MemberItem key={user.id}>
                                        <Avatar
                                            size="default"
                                            src={
                                                user.image_path
                                                    ? process.env.REACT_APP_END_POINT +
                                                      user.image_path
                                                    : ""
                                            }
                                            icon={<UserOutlined />}
                                            style={{backgroundColor: Colors[user.id % 10]}}
                                        />
                                        <CustomText
                                            style={{
                                                width: "80%",
                                                whiteSpace: "nowrap",
                                                wordBreak: "break-all",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>
                                            {user.login_id}
                                        </CustomText>
                                    </MemberItem>
                                );
                            })}
                        </div>
                        {page === "labeling" ? (
                            <>
                                <div className="shortcut-title-section">
                                    <div>
                                        <LabelIcon width="1.125rem" height="1.125rem" fill="#fff" />
                                        <CustomText mg="0 0 0 10px">라벨링 단축키</CustomText>
                                    </div>
                                </div>

                                <div className="shortcut-list">
                                    <div className="shortcut-items-wrap">
                                        <div className="shortcut-items">
                                            <ArrowIcon width="7rem" height="5rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                이미지 이동
                                            </CustomText>
                                        </div>
                                        <div className="shortcut-items">
                                            <DeleteIcon width="4rem" height="5rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                폴리곤 되돌리기
                                            </CustomText>
                                        </div>
                                    </div>
                                    <div className="shortcut-items-wrap">
                                        <div className="shortcut-items">
                                            <EnterIcon width="7rem" height="3rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                폴리곤 완성
                                            </CustomText>
                                        </div>
                                        <div className="shortcut-items">
                                            <ShiftIcon width="7rem" height="3rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                이미지 드래그 모드
                                            </CustomText>
                                        </div>
                                    </div>
                                    <div className="shortcut-items-wrap">
                                        <div className="shortcut-items">
                                            <PlusKeyIcon width="7rem" height="3rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                줌 인
                                            </CustomText>
                                        </div>
                                        <div className="shortcut-items">
                                            <MinusIcon width="7rem" height="3rem" />
                                            <CustomText style={{textAlign: "center"}}>
                                                줌 아웃
                                            </CustomText>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            page === "preprocess" && (
                                <>
                                    <div className="shortcut-title-section">
                                        <div>
                                            <LabelIcon
                                                width="1.125rem"
                                                height="1.125rem"
                                                fill="#fff"
                                            />
                                            <CustomText mg="0 0 0 10px">전처리 단축키</CustomText>
                                        </div>
                                    </div>

                                    <div className="shortcut-list">
                                        <div className="shortcut-items">
                                            <ArrowIcon />
                                            <CustomText style={{textAlign: "center"}}>
                                                이미지 이동
                                            </CustomText>
                                        </div>
                                        <div className="shortcut-items">
                                            <CustomText
                                                fs="2rem"
                                                fw="700"
                                                cl="#000000"
                                                style={{
                                                    backgroundColor: "#ffffff",
                                                    borderRadius: "6px",
                                                    width: "5.8rem",
                                                    height: "5.8rem",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                M
                                            </CustomText>
                                            <CustomText style={{textAlign: "center"}}>
                                                영역선택 / 이미지이동
                                            </CustomText>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </MemberWrapper>
                </>
            )}
            {projectData !== undefined && (
                <AddMember
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    collaborators={projectData.data.collaborators}
                />
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    ) : null;
}

export default ProjectSide;
