import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { CustomText } from "../../styles/theme/CustomStyle";
import { CreateProjectLike, DeleteProjectLike, GetProjectExpanationMember, ProjectFork } from "../../services/project-services";
import { updateProjectViewerAction } from "../../store/reducer/ProjectViewer";
import { deleteSelectedDatasetAction } from "../../store/reducer/SelectedDataset";
import AlertModal from "../modal/AlertModal";
import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import { ReactComponent as LikeIcon } from "../../assets/user-dashboard/like-icon.svg";
import { ReactComponent as ForkIcon } from "../../assets/user-dashboard/fork-icon.svg";
import { Button } from "antd";

const Wrap = styled(ScrollContainer)`
    /* flex: 1; */
    width: 100%;
    /* max-width: 1100px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    box-shadow: inset 0 -2px 0 0 #575757;
    overflow-x: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem 1rem 2rem;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @media screen and (max-width: 1024px) {
        padding: 0.5rem 1.5rem 1rem 1.5rem;
    }

    @media screen and (max-width: 499px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`;

const LikeForkBtn = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #21262c !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    /* padding: 10px 25px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #b2b1b1 !important;
`;

const TabItem = styled.div`
    flex: 0 0 auto;
    width: 8rem;
    padding: 1rem 0;
    /* padding: 1rem 1.5rem; */
    cursor: pointer;
    ${(props) =>
        props.selected &&
        css`
            background-color: #21272e;
            border-bottom: 2px solid transparent;
            border-image: linear-gradient(0.25turn, #2cf2ff, #fbff2c);
            border-image-slice: 2;
        `}
`;

function ProjectTab(props) {
    const { Token, UserInfo, ProjectViewer } = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const crntTab = queryString.parse(location.search).tab;
    const crntPage = queryString.parse(location.search).page === undefined ? 1 : queryString.parse(location.search).page;
    // const [isSettingShow, setIsSettingShow] = useState(false);

    const [isLiked, setIsLiked] = useState(false);

    const likeMutation = useMutation(CreateProjectLike);
    const unLikeMutation = useMutation(DeleteProjectLike);
    const forkMutation = useMutation(ProjectFork);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (crntTab === undefined) {
            navigate({
                search: "?tab=dataset",
            });
        }
    }, [crntTab]);

    const handleTabItemClick = useCallback(
        (tab) => {
            if ((tab === "model") | (tab === "validation") | (tab === "issue")) {
                navigate({
                    search: `?tab=${tab}&page=1`,
                });
            } else {
                navigate({
                    search: `?tab=${tab}`,
                });
            }
        },
        [params]
    );

    const {
        data: projectData,
        isFetching: loading,
        refetch,
    } = useQuery(
        ["project", "explanation", "member", params.id],
        async (e) => {
            const data = await GetProjectExpanationMember(Token.data.access, params.id);
            return data;
        },
        {
            // staleTime: 60 * 1000, // 1분
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                // 프로젝트가 비공개프로젝트일 경우
                if (!res.data.is_public) {
                    if (UserInfo.data?.id !== res.data.manager.id) {
                        if (!UserInfo.data?.is_admin | !UserInfo.data?.is_manager) {
                            if (!res.data.collaborators.some((member) => member.id === UserInfo.data?.id)) {
                                setAlertModalOpen({
                                    open: true,
                                    iconType: "warning",
                                    title: "권한 오류",
                                    text: "접근 권한이 없는 프로젝트입니다.",
                                    afterFunction: () => {
                                        navigate(-1);
                                    },
                                });
                            } else {
                                dispatch(updateProjectViewerAction("member"));
                            }
                        } else {
                            dispatch(updateProjectViewerAction("member"));
                        }
                    } else {
                        dispatch(updateProjectViewerAction("manager"));
                    }
                } else {
                    if (UserInfo.data?.id !== res.data.manager.id) {
                        if (UserInfo.data?.id === undefined || res.data.collaborators.some((member) => member.id !== UserInfo.data?.id)) {
                            dispatch(updateProjectViewerAction("viewer"));
                        } else {
                            dispatch(updateProjectViewerAction("member"));
                        }
                    } else {
                        dispatch(updateProjectViewerAction("manager"));
                    }
                }

                if (res.data.like_users.some((like) => like.user === UserInfo.data?.id)) {
                    setIsLiked(true);
                } else {
                    setIsLiked(false);
                }
            },
            onError: (err) => {
                if (err.response.status === 404) {
                    dispatch(deleteSelectedDatasetAction());
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "프로젝트 오류",
                        text: "존재하지 않는 프로젝트입니다.",
                        afterFunction: () => {
                            navigate("/not-found", {
                                replace: true,
                            });
                            window.location.reload();
                        },
                    });

                    return;
                }
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const handleLikeBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        }

        // 이미 좋아요 누른 상태일때
        if (isLiked) {
            await unLikeMutation.mutateAsync(
                {
                    token: Token.data.access,
                    projectId: params.id,
                },
                {
                    onSuccess: (res) => {
                        refetch();
                    },
                    onError: (error) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        } else {
            const formData = new FormData();

            formData.append("project_id", params.id);

            await likeMutation.mutateAsync(
                { token: Token.data.access, projectId: params.id },
                {
                    onSuccess: (res) => {
                        refetch();
                    },
                    onError: (error) => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        }
    }, [isLiked, Token, UserInfo]);

    const handleForkBtnClick = useCallback(async () => {
        if (_.isEmpty(UserInfo.data)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "로그인 필요",
                text: "로그인 후 이용가능합니다.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
            return;
        }

        await forkMutation.mutateAsync(
            {
                token: Token.data.access,
                projectId: params.id,
            },
            {
                onSuccess: (res) => {
                    navigate(`/project/${res.data.id}?tab=dataset`);
                },
                onError: (err) => {
                    if (err.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "프로젝트 이름 오류",
                            text: "동일한 이름의 프로젝트가 이미 존재합니다.",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, UserInfo]);

    return (
        <>
            <TitleWrap>
                <div style={{ width: "100%" }}>
                    {projectData?.data.is_public ? <PublicIcon fill="white" width="1.5rem" height="1.5rem" /> : <PrivateIcon fill="white" width="1.5rem" height="1.5rem" />}
                    <CustomText fs="1.5rem" fw="600" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {projectData?.data.title}
                    </CustomText>
                </div>
                <div>
                    <LikeForkBtn onClick={handleLikeBtnClick}>
                        <LikeIcon width="0.875rem" height="0.875rem" fill={isLiked ? "#FF6433" : "#fff"} />
                        <CustomText fs="0.875rem">{projectData?.data?.like_users?.length}</CustomText>
                    </LikeForkBtn>
                    <LikeForkBtn onClick={handleForkBtnClick} loading={forkMutation.isLoading}>
                        <ForkIcon width="0.875rem" height="0.875rem" />
                        <CustomText fs="0.875rem">{projectData?.data?.fork_count}</CustomText>
                    </LikeForkBtn>
                </div>
            </TitleWrap>
            <Wrap>
                <TabItem selected={crntTab === "dataset" ? true : false} onClick={() => handleTabItemClick("dataset")}>
                    <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                        데이터셋
                    </CustomText>
                </TabItem>

                <TabItem selected={crntTab === "training" ? true : false} onClick={() => handleTabItemClick("training")}>
                    <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                        모델 학습
                    </CustomText>
                </TabItem>

                <TabItem selected={crntTab === "model" ? true : false} onClick={() => handleTabItemClick("model")}>
                    <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                        모델
                    </CustomText>
                </TabItem>
                <TabItem selected={crntTab === "validation" ? true : false} onClick={() => handleTabItemClick("validation")}>
                    <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                        검증
                    </CustomText>
                </TabItem>
                <TabItem selected={crntTab === "issue" ? true : false} onClick={() => handleTabItemClick("issue")}>
                    <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                        이슈
                    </CustomText>
                </TabItem>
                {(ProjectViewer.data === "manager" || UserInfo.data.authorization_code === "99" || UserInfo.data?.company.id === projectData?.data.manager.company) && (
                    <TabItem selected={crntTab === "setting" ? true : false} onClick={() => handleTabItemClick("setting")}>
                        <CustomText fs="1rem" fw="500" ta="center" wd="100%">
                            설정
                        </CustomText>
                    </TabItem>
                )}
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </>
    );
}

export default ProjectTab;
