export const initialState = {
    data: {},
};

export const updateSelectedVideoAction = (data) => {
    return {
        type: "UPDATE_SELECTED_VIDEO",
        data,
    };
};

export const deleteSelectedVideoAction = () => {
    return {
        type: "DELETE_SELECTED_VIDEO",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_VIDEO":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_SELECTED_VIDEO":
            return {
                ...state,
                data: {},
            };

        default:
            return state;
    }
};

export default reducer;
