import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .image-section {
        width: 45;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function AutoLabel() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/terminology-guide" style={{ cursor: "pointer" }}>
                    <CustomSpan cl="#58A6FF">&nbsp;기능 및 용어 안내&nbsp;</CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;오토 라벨링</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    오토 라벨링
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    NewLearn에서는 라벨링 작업의 효율성을 위해 오토 라벨링 기능을 지원합니다.
                    <br />
                    <br />
                    오토 라벨링을 하기전 설정해야할 옵션은 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>오토라벨링할 인공지능 모델 선택</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>라벨링 하고자하는 클래스를 선택</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>라벨링 할 이미지를 Drag and Drop</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>오토라벨링 예약버튼 클릭</li>
                    <br />
                </CustomSpan>
            </div>

            <Link to="/project/list">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        프로젝트 시작하기
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default AutoLabel;
