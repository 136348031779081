import axios from "axios";

export const GetCompany = () => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/company`);
};

export const GetCompanyInfo = (userId, token) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/user/${userId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetCompanyMember = (companyId, token) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/company/${companyId}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateCompany = (companyId, formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/account/company/${companyId}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const CreateUser = (formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/account/user", formData);
};

export const UserIdDupCheck = (login_id) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/user/id_dup_check/${login_id}`);
};

export const UserEmailDupCheck = (email) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/user/email_dup_check/${email}`);
};

export const CompanyRegistrationNumberDupCheck = (company_registration_number) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/company/registration_number_dup_check/${company_registration_number}`);
};

export const CreateCompany = (formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/account/company", formData);
};

export const UpdateCompanyInfo = (formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + "/api/account/company", formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const ApproveEmail = (uidb64, token) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/signup/activate/${uidb64}/${token}`);
};

export const UpdateUserInfo = (id, formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/account/user/${id}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UserPasswordMatch = (login_id, currentPassword, token) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/account/user/password/${login_id}/${currentPassword}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UserPasswordChange = (login_id, formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/account/user/password/${login_id}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteUser = (id, token) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/account/user/${id}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const AcceptMemberInvite = ({ loginToken, token, uidb64_invitee, uidb64_project }) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/project/invite/collaborator?uidb64_invitee=${uidb64_invitee}&uidb64_project=${uidb64_project}&token=${token}`, {
        headers: {
            Authorization: `JWT ${loginToken}`,
        },
    });
};

export const GetTotalCount = (token) => {
    return axios.get(process.env.REACT_APP_END_POINT + "/api/account/totalcount", {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetMainStaticApi = () => {
    return axios.get(process.env.REACT_APP_END_POINT + "/api/account/main");
};

export const EditPassword = (formData) => {
    return axios.put(process.env.REACT_APP_END_POINT + "/api/account/change_password", formData);
};
