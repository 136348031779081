import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }
    /* padding: 1.4rem 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */

    overflow: hidden;
    border-bottom: 1px solid #707070;
    padding: 1rem 0;
    /* padding-bottom: 1rem; */

    display: flex;
    flex-direction: column;
    /* gap: 1.1rem; */
    gap:7px;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 1.1rem; */
        gap:7px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 100%;
        height: 1rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 40%;
        height: 0.8rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-content {
        border-radius: 5px;
        width: 100%;
        height: 5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-items {
        border-radius: 5px;
        width: 3.125rem;
        height: 1.414rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }
    
    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-profile {
        border-radius: 100px;
        width: 1.5rem;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-profile::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
    `;

function CommentListSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-info">
                <div className="title-section">
                    <div className="skeleton-title" />
                </div>
                <div style={{display:"flex", gap:"1rem", alignItems:"center", width:"40%", marginTop:"10px"}}>
                    <div className="info-profile" />
                    <div className="skeleton-explanation" style={{width:"20%"}} />
                    <div className="skeleton-explanation" style={{marginLeft:"2rem"}}/>
                </div>
            </div>
        </Wrap>
    );
}

export default CommentListSkeleton;
