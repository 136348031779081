import axios from "axios";

export const GetLabelList = (token, dataset_id, annotation_type) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/get/label/${dataset_id}/${annotation_type}`,
        {}
    );
};

export const SetLabel = ({token, formData}) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/project/create/label/`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateLabel = ({token, pk, formData}) => {
    return axios.put(
        process.env.REACT_APP_END_POINT + `/api/project/update/label/${pk}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const UpdateAnnotation = ({token, pk, formData}) => {
    return axios.put(
        process.env.REACT_APP_END_POINT + `/api/project/update/annotation/${pk}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const UpdateMosaic = ({token, pk, formData}) => {
    return axios.put(
        process.env.REACT_APP_END_POINT + `/api/project/update/mosaic/${pk}`,
        formData,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const GetAnnotationAllData = (token, dataset_id, annotation_type, label) => {
    return axios.get(
        process.env.REACT_APP_END_POINT +
            `/api/project/get/annotation_data/${dataset_id}/${annotation_type}/${label}`,
        {
            headers: {
                Authorization: `JWT ${token}`,
            },
        }
    );
};

export const GetTrainingAlgorithm = annotation_type => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/project/get/training_algorithm/${annotation_type}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const ExportDataSet = ({token, formData}) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/storage/export/data/`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteImages = ({token, formData}) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/storage/delete/images/`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};
