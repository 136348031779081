export const initialState = {
    data: {},
};

export const updateUserInfoAction = data => {
    return {
        type: "UPDATE_USER_INFO",
        data: data,
    };
};

export const deleteUserInfoAction = () => {
    return {
        type: "DELETE_USER_INFO",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_USER_INFO":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_USER_INFO":
            return {
                ...state,
                data: {},
            };

        default:
            return state;
    }
};

export default reducer;
