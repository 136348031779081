import React from "react";
import styled from "styled-components";
import { Footer } from "antd/lib/layout/layout";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { Link } from "react-router-dom";

const Wrap = styled(Footer)`
    background-color: #161b22;
    border-top: 1px solid #707070;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .logo-privacy-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .privacy-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        div {
            height: 1rem;
            border-right: 1px solid #b1b1b1;
        }
    }

    .info-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }

    @media screen and (max-width: 1024px) {
        .logo-privacy-section {
            flex-direction: column;
            gap: 2rem;
        }

        .info-section {
            align-items: center;

            p {
                width: 100%;
                text-align: center;
            }
        }
    }
`;

function AppFooter(props) {
    return (
        <Wrap>
            <div className="logo-privacy-section">
                <Logo width="10rem" height="2rem" />

                <div className="privacy-section">
                    <Link to="/privacy">
                        <CustomText>개인정보처리방침</CustomText>
                    </Link>
                    <div />
                    <Link to="/policy/terms">
                        <CustomText>서비스이용약관</CustomText>
                    </Link>
                </div>
            </div>
            <div className="info-section">
                <div>
                    <CustomText fs="0.875rem" cl="#b1b1b1">
                        (주)비아
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#b1b1b1">
                        전라북도 전주시 덕진구 유상로 67, 전주혁신창업허브 513호
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#b1b1b1">
                        T. 063 211 0814 | E. newlearn@viasoft.ai
                    </CustomText>
                </div>
                <div>
                    <CustomText fs="0.875rem" cl="#b1b1b1">
                        Copyright ⓒ2022 lnc.
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#b1b1b1">
                        All Rights Reserved.
                    </CustomText>
                </div>
            </div>
        </Wrap>
    );
}

export default AppFooter;
