import React from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { CustomText } from "../../../styles/theme/CustomStyle";
import queryString from "query-string";

const DateWrapper = styled.div`
    display: flex;
    flex-direction: row;

    gap: 0.7rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

const YearWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    @media screen and (max-width: 1024px) {
        flex-direction: row;
        overflow-x: auto;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const YearItem = styled.div`
    background-color: #21272e;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.3rem 2.5rem;
    border: 1px solid #21272e;

    p {
        color: #9b9b9b;
    }
    ${(props) =>
        props.isclicked === "true" &&
        css`
            border-color: #707070;

            p {
                color: #fff;
            }
        `}
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    min-width: 4.8rem;

    @media screen and (max-width: 1024px) {
        flex-direction: row;
        overflow-x: auto;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const MonthItem = styled.div`
    background-color: #21272e;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.3rem 1.5rem;
    border: 1px solid #21272e;
    flex-shrink: 0;
    p {
        color: #9b9b9b;
    }

    ${(props) =>
        props.isclicked === "true" &&
        css`
            border-color: #707070;

            p {
                color: #fff;
            }
        `}
`;

function DateWrap({ yearList, monthList, selectedYear, setSelectedYear, selectedMonth, setSelectedMonth, historyData, isLoading }) {
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;

    return crntTab === "memberManagement" ? (
        <DateWrapper style={{ flexDirection: "column" }}>
            <YearWrapper style={{ flexDirection: "row" }}>
                {yearList.map((year, index) => {
                    return (
                        <YearItem key={index} isclicked={selectedYear === year ? "true" : "false"} onClick={() => setSelectedYear(year)}>
                            <CustomText>{year}</CustomText>
                        </YearItem>
                    );
                })}
            </YearWrapper>
            <MonthWrapper style={{ flexDirection: "row" }}>
                {monthList.map((month, index) => {
                    return (
                        <MonthItem key={index} isclicked={selectedMonth === month ? "true" : "false"} onClick={() => setSelectedMonth(month)}>
                            <CustomText>{month}월</CustomText>
                        </MonthItem>
                    );
                })}
            </MonthWrapper>
        </DateWrapper>
    ) : (
        <DateWrapper>
            <YearWrapper>
                {yearList.map((year, index) => {
                    return (
                        <YearItem key={index} isclicked={selectedYear === year ? "true" : "false"} onClick={() => setSelectedYear(year)}>
                            <CustomText>{year}</CustomText>
                        </YearItem>
                    );
                })}
            </YearWrapper>
            <MonthWrapper>
                {monthList.map((month, index) => {
                    return (
                        <MonthItem key={index} isclicked={selectedMonth === month ? "true" : "false"} onClick={() => setSelectedMonth(month)}>
                            <CustomText>{month}월</CustomText>
                        </MonthItem>
                    );
                })}
            </MonthWrapper>
        </DateWrapper>
    );
}

export default DateWrap;
