import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

import { updateImageCropperAction } from "../../../store/reducer/ImageCropper";
import { GetPreprocessAnnotationImageList } from "../../../services/project-services";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { updateCropInfoAction } from "../../../store/reducer/CropInfo";
import { loadCropIndexAction } from "../../../store/reducer/CropIndex";
import MobileCropController from "./MobileCropController";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../modal/AlertModal";

const Wrap = styled.div`
    min-width: 0;
    box-sizing: border-box;
    padding-bottom: 4rem;

    @media screen and (max-width: 1700px) {
        margin-right: 4rem;
    }

    @media screen and (max-width: 1024px) {
        margin-right: 0;
    }
`;

const TitleWrapper = styled.div`
    width: 100%;
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;
`;

const CropWrap = styled.div`
    margin-top: 1.2rem;
    width: 100%;
    border-radius: 3px;
    border: none;

    .crop-image {
        border-radius: 10px;
        border: none;
        width: 100%;
    }

    div {
        border-radius: 3px;
    }

    .cropper-canvas {
        border-radius: 0;
    }
`;

let cropper = null;

function PreprocessMain(props) {
    const { Token, SelectedDataset, CropIndex } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: imageList, isLoading: loading } = useQuery(
        ["project", "preprocess", "images", SelectedDataset.data.id],
        async (e) => {
            const data = await GetPreprocessAnnotationImageList(Token.data.access, SelectedDataset.data.id);
            return data;
        },
        {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                dispatch(
                    loadCropIndexAction({
                        crnt: 1,
                        max: res.data.count,
                        list: res.data.data,
                    })
                );
            },
            onError: (err) => {
                if (err.response.status === 404) {
                    navigate(-1);
                    return;
                }

                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        if (imageList?.status === 200) {
            if (imageList.data.count > 0) {
                const image = document.getElementById("cropImage");
                image.crossOrigin = "Anonymous";
                image.src = process.env.REACT_APP_END_POINT + `/${imageList.data.data[CropIndex.data.crnt - 1].media_path}`;

                cropper = new Cropper(image, {
                    checkCrossOrigin: false,
                    checkOrientation: false,
                    aspectRatio: NaN,
                    responsive: true,
                    restore: false,
                    crop(event) {
                        dispatch(
                            updateCropInfoAction({
                                xPosition: event.detail.x,
                                yPosition: event.detail.y,
                                cropWidth: event.detail.width,
                                cropHeight: event.detail.height,
                                rotateDegree: event.detail.rotate,
                                scaleX: event.detail.scaleX,
                                scaleY: event.detail.scaleY,
                            })
                        );
                    },
                });

                dispatch(updateImageCropperAction(cropper));
            }
        }

        return () => {
            try {
                cropper.clear();
                cropper.reset();
                cropper.destroy();
            } catch {
                return;
            }
        };
    }, [imageList, CropIndex, SelectedDataset]);

    return (
        <Wrap>
            <TitleWrapper>
                <CustomText fs="1.125rem" fw="600">
                    데이터 전처리
                </CustomText>
            </TitleWrapper>
            {imageList?.data.count === 0 ? (
                <CropWrap>
                    <CustomText>데이터를 업로드 해주세요</CustomText>
                </CropWrap>
            ) : (
                <CropWrap>
                    <img id="cropImage" className="crop-image" />
                </CropWrap>
            )}

            <MobileCropController />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default PreprocessMain;
