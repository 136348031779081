import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function DataLabelingGuide() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>

                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/getting-started">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;시작하기&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;데이터 라벨링</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    데이터 라벨링
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    데이터셋 탭의 라벨링 버튼을 클릭하여 데이터 라벨링을 진행할 수 있습니다.
                    <br />
                    <br />
                    NewLearn에서는 <span style={{ color: "#58A6FF" }}>학습 알고리즘</span>에 따라 다른 라벨링 방식을 사용하며 크게 2가지 방식으로 나뉩니다.
                    <br />
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Bounding Box</li>
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Polygon</li>
                    <br />
                    해당 튜토리얼에서는 YOLOv5 알고리즘을 사용하기 때문에 Bounding Box 알고리즘을 사용하여 라벨링을 진행합니다
                    <br />
                    <br />
                    먼저 데이터에 저장할 라벨 목록을 생성합니다.
                    <br />
                    <br />
                    이후 데이터에 라벨링을 진행할 부분을 선택합니다.
                    <br />
                    <br />
                    나타나는 창에서 저장할 라벨을 선택 후 라벨링 완료 버튼을 클릭합니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <video width="100%" height="100%" controls muted playsInline autoPlay={false} style={{ borderRadius: 10, maxWidth: 1000, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                    <source src="/videos/main/main_labeling.mp4" type="video/mp4" />
                </video>
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF" pd="20px 0px 0px 0px">
                    저장된 라벨 데이터를 확인합니다.
                    <br />
                </CustomSpan>
            </div>
            <Link to="/document/getting-started?step=5">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        다음 단계
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default DataLabelingGuide;
