import React from "react";
import styled, { css } from "styled-components";
import { Modal, Dropdown, Button, Menu, Slider } from "antd";

import { CustomText } from "../../styles/theme/CustomStyle";
import { CloseOutlined, DownOutlined, DownloadOutlined } from "@ant-design/icons";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GetTrainingAlgorithm } from "../../services/labeling-services";
import { ExportDataSet } from "../../services/labeling-services";
import AlertModal from "./AlertModal";

const CustomModal = styled(Modal)`
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #21272e;
        border: 1px solid #707070;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
    }

    .ant-modal-body {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        width: 80%;
    }

    .complete {
        width: 40%;
        margin-top: 30px;

        cursor: pointer;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const StyledDropDown = styled(Dropdown)`
    background-color: #303a45;
    border-radius: 3px;
    width: 80%;
    margin-top: 10px;
    border: none;
    /* width: 100%; */
    padding: 0.7rem 1rem 0.7rem 0rem;
    flex: 1;
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    cursor: pointer;
`;

function ExportDataModal({ isExportModal, setIsExportModal }) {
    const { Token, SelectedDataset, UserInfo } = useSelector((state) => state);
    const [algorithms, setAlgorithms] = useState([]);
    const [trainingTestRate, setTrainingTestRate] = useState(70);
    const [selectedTrainAlgorithm, setSelectedTrainAlgorithm] = useState("");
    const [selectedTrainAlgorithmName, setSelectedTrainAlgorithmName] = useState("");
    const [isExport, setIsExport] = useState(false);
    const exportDataSet = useMutation(ExportDataSet);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const onAlgorithmMenuClick = useCallback(
        (e) => {
            let algorithm = algorithms.filter((data) => data.id === parseInt(e.key))[0];

            setSelectedTrainAlgorithm(algorithm.id);
            setSelectedTrainAlgorithmName(algorithm.algorithm_name);
        },
        [algorithms]
    );

    useEffect(() => {
        getAlgorithm();
    }, [SelectedDataset.data.annotation_type]);

    const algorithmItems = useMemo(
        () => (
            <StyledMenu onClick={onAlgorithmMenuClick}>
                {algorithms.map((algorithm) => {
                    return (
                        <Menu.Item className="menu-item" key={algorithm.id}>
                            {algorithm.id === 1 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(YOLO TXT)"}
                                </CustomText>
                            )}
                            {algorithm.id === 2 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(COCO JSON)"}
                                </CustomText>
                            )}
                            {algorithm.id === 3 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(Pascal VOC XML)"}
                                </CustomText>
                            )}
                            {algorithm.id === 4 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(Pascal VOC XML)"}
                                </CustomText>
                            )}
                            {algorithm.id === 5 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(YOLO TXT)"}
                                </CustomText>
                            )}
                            {algorithm.id === 6 && (
                                <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                    {algorithm.algorithm_name}
                                    {"(COCO JSON)"}
                                </CustomText>
                            )}
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [algorithms]
    );

    const getAlgorithm = useCallback(async () => {
        try {
            const res = await GetTrainingAlgorithm(SelectedDataset.data.annotation_type);

            setAlgorithms(res.data);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    }, [SelectedDataset.data.annotation_type]);

    const downLoadData = () => {
        let a = document.createElement("a");
        a.href = process.env.REACT_APP_END_POINT + "/media/export_data/" + UserInfo.data.id + "/" + SelectedDataset.data.data_name + ".zip";
        a.download = process.env.REACT_APP_END_POINT + "/media/export_data/" + UserInfo.data.id + "/" + SelectedDataset.data.data_name + ".zip";
        document.body.appendChild(a);
        a.click();
    };

    const onExportBtnClick = useCallback(async () => {
        const formData = new FormData();
        if (selectedTrainAlgorithm === "") {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "알고리즘 선택 필요",
                text: "알고리즘을 선택해주세요",
                afterFunction: null,
            });
        }

        formData.append("data_set", SelectedDataset.data.id);
        formData.append("project_name", SelectedDataset.data.data_name);
        formData.append("annotation_type", SelectedDataset.data.annotation_type);
        formData.append("training_algorithm", selectedTrainAlgorithm);
        formData.append("train_ratio", trainingTestRate);
        formData.append("profile_pk", UserInfo.data.id);

        setIsExport(true);

        await exportDataSet.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onSuccess: (res) => {
                    setIsExport(false);
                    setIsExportModal(false);
                    downLoadData();
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: () => {
                            setIsExport(false);
                            setIsExportModal(false);
                        },
                    });
                },
            }
        );
    });

    const onSliderAfterChange = useCallback((value) => {
        setTrainingTestRate(value);
    }, []);

    return (
        <>
            <CustomModal visible={isExportModal} onCancel={() => setIsExportModal(false)} closeIcon={<CloseOutlined style={{ color: "#fff" }} />}>
                <div style={{ color: "#fff", textAlign: "center", marginBottom: "15px" }}>
                    <DownloadOutlined style={{ fontSize: "1.8rem" }} />
                </div>
                <CustomText fs="1.1rem" wd="100%" fw="600" ta="center" mg="0 0 5px 0">
                    데이터 내보내기
                </CustomText>
                <CustomText fs="0.9rem" wd="100%" fw="400" ta="center" cl="#D4D4D4" mg="0 0 5px 0">
                    알고리즘에 따라 데이터를 다운로드 받을수 있습니다.
                </CustomText>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <StyledDropDown overlay={algorithmItems} placement="bottomCenter" trigger={["click"]} overlayStyle={{ overflowY: "scroll", maxHeight: 250 }}>
                        <MenuItem>
                            {selectedTrainAlgorithmName === "" && (
                                <CustomText ff="Pretendard" fw="400" fs="0.9rem" cl="#FFFFFF" ta="center" style={{ flex: 1 }}>
                                    데이터 형식
                                </CustomText>
                            )}
                            {selectedTrainAlgorithmName !== "" && (
                                <CustomText ff="Pretendard" fw="400" fs="0.9rem" cl="#FFFFFF" ta="center" wd="25%" style={{ flex: 1 }}>
                                    {selectedTrainAlgorithmName}
                                </CustomText>
                            )}
                            <DownOutlined style={{ color: "#fff", fontSize: "0.6rem" }} />
                        </MenuItem>
                    </StyledDropDown>
                </div>
                <div style={{ paddingTop: "20px" }}>
                    <Slider style={{ marginBottom: "20px" }} trackStyle={{ background: "#BFFFA1" }} max={99} min={70} defaultValue={70} onAfterChange={onSliderAfterChange} />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px", paddingLeft: "7px" }}>
                        <CustomText fw="400" fs="0.8rem" cl="#9C9C9C" ta="left">
                            학습: <span style={{ fontWeight: "normal", fontSize: "0.9rem", color: "#FFFFFF" }}>{trainingTestRate}%</span>
                        </CustomText>
                        <CustomText fw="400" fs="0.8rem" cl="#9C9C9C" ta="left" style={{ paddingLeft: "20px" }}>
                            테스트: <span style={{ fontWeight: "normal", fontSize: "0.9rem", color: "#FFFFFF" }}>{100 - trainingTestRate}%</span>
                        </CustomText>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <CustomBtn bc={selectedTrainAlgorithm === "" ? "#B1B1B1" : "#E2FF9B"} className="complete" onClick={onExportBtnClick} loading={isExport}>
                        <CustomText fs="0.9rem" fw="400" wd="100%" ta="center" cl={selectedTrainAlgorithm === "" ? "#B1B1B1" : "#E2FF9B"}>
                            내보내기
                        </CustomText>
                    </CustomBtn>
                </div>
            </CustomModal>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default ExportDataModal;
