import React, {useCallback, useEffect, useState, useMemo, useRef} from "react";
import styled, {css} from "styled-components";
import {useDropzone} from "react-dropzone";
import {useMutation, useQuery} from "react-query";
import {Button, Checkbox} from "antd";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import _ from "lodash";

import {ReactComponent as UploadIcon} from "../../../assets/project/upload-icon.svg";
import {ReactComponent as NoDataSetIcon} from "../../../assets/project/nodataset.svg";
import {CustomText} from "../../../styles/theme/CustomStyle";
import {
    GetAnnotationImageList,
    GetDataSetInfo,
    PostMedia,
} from "../../../services/project-services";
import {updateSelectedDatasetAction} from "../../../store/reducer/SelectedDataset";
import ExportDataModal from "../../modal/ExportDataModal";
import styles from "./DataSet.module.css";
import {DeleteImages} from "../../../services/labeling-services";
import AlertModal from "../../modal/AlertModal";
import Lottie from "react-lottie";

import * as LoadingData from "../../../assets/loading/loaindg-lottie.json";
import {ReactComponent as ListViewMethodIcon} from "../../../assets/project/list-view-method-icon.svg";
import {ReactComponent as PictureViewMethodIcon} from "../../../assets/project/picture-view-method-icon.svg";
import ConfirmModal from "../../modal/ConfirmModal";
import VideoModal from "../../modal/VideoModal";
import SkeletonImage from "antd/lib/skeleton/Image";
import useStore from "../../annotator/Store";
import CommentListSkeleton from "../../../styles/theme/CommentListSkeleton";

const Wrap = styled.div`
    padding-bottom: 10rem;

    .cursor-pointer {
        cursor: pointer;
    }
`;

const UploadWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1rem;

    .button-section {
        flex: 0.27;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: stretch;
        height: auto;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .button-section {
            flex-direction: row;
            margin-top: 0.5rem;
        }
    }
`;

const AlignWrap = styled.div`
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-section {
        display: flex;
        gap: 15px;
    }
    .view-method-wrap {
        background-color: #21272d;
        border-radius: 5px;
        padding: 0.8rem;
        display: flex;
        gap: 15px;
    }

    .non-labeling-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: fit-content;
        gap: 10px;
    }

    .data-align-method-wrap {
        background-color: #21272d;
        border-radius: 5px;
        padding: 0.8rem;

        select {
            background-color: inherit;
            border: none;
            font-size: 0.9rem;
            color: #fff;
            outline: none;
            /* :focus {
                border: none;
            } */
        }
    }
`;

const DataListItem = styled.div`
    p {
        color: #fff;
        font-size: 1rem;
    }

    ${props =>
        props.deleteSelected &&
        css`
            p {
                color: #bfffa1;
            }
        `}
`;

const DropzoneContainer = styled.div`
    flex: 0.73;
    display: flex;
    background-color: #21272e;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    gap: 1.5rem;
    padding: 1.5rem;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #b2b1b1 !important;

    @media screen and (max-width: 1024px) {
        padding: 1.5rem 0;
    }
`;

const ImageListWrap = styled.div`
    width: 100%;
    margin-top: 10px;
    overflow-y: auto;
    /* margin-bottom: 4rem; */
    max-height: 30rem;
    border-radius: 3px;
    background-color: #21272e;
    padding: 0.8rem;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    ::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const ImageList = styled(InfiniteScroll)`
    width: 100%;
    max-height: inherit;

    ${props =>
        props.viewMethod === "list"
            ? css`
                  /* background-color: ; */
              `
            : css`
                  display: grid;
                  grid-template-columns: repeat(3, minmax(0, 1fr));
                  grid-template-rows: 230px;
                  grid-gap: 1rem;
                  padding-right: 0.5rem;

                  .items {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 0.5rem;

                      .text {
                          width: 280px;
                      }
                  }
              `}

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: 230px;
        .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            .text {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: 230px;
        .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            .text {
                width: 150px;
            }
        }
    }
`;

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        border: 1px solid #636363;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #161b22;
    }
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    max-height: 200px;
    border: ${props => props.bd} !important;
`;

const NoModelSection = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
`;

function DataUploadList(props) {
    const {Token, SelectedDataset, ProjectViewer} = useSelector(state => state);
    //const [videoFile, setVideoFile] = useState("");
    //const [videoModal, setVideoModal] = useState(false);
    const [isExportModal, setIsExportModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [viewMethod, setViewMethod] = useState("list"); // list: 글씨 목록으로 보기, picture: 사진 목록으로 보기
    const [dataAlignMethod, setDataAlignMethod] = useState("recent"); // recent: 최신순, old: 오래된순, nameAsce: 이름 오름차순, nameDesc: 이름 내림차순

    const scrollParentRef = useRef(null);
    const isDrag = useStore(state => state.isDrag);
    const setIsDrag = useStore(state => state.setIsDrag);
    const dispatch = useDispatch();
    const [labelMethod, setLabelMethod] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [annotationList, setAnnotationList] = useState({
        count: 0,
        crntPage: 1,
        hasMore: false,
        scrollY: 0,
        data: [],
    });
    const [isUploading, setIsUploading] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [lastIndex, setLastIndex] = useState({index: 0, id: 0});

    useEffect(() => {
        if (!_.isEmpty(SelectedDataset.data)) {
            getAnnotationData(1, true);
            setSelectedImages([]);
        }
    }, [SelectedDataset, dataAlignMethod, labelMethod]);

    const mediaPost = useMutation(PostMedia);
    const deletImages = useMutation(DeleteImages);

    const LottieLoadingOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const onDrop = useCallback(
        async file => {
            setIsUploading(true);
            const images = file.filter(s => s.type.includes("image"));
            const video = file.filter(s => s.type.includes("video"));

            const xml_files = file.filter(s => s.type.includes("text"));
            const json_files = file.filter(s => s.type.includes("application"));

            let xml_type = ".xml";
            let json_type = ".json";
            setSelectedImages([]);
            if (file.length > 0) {
                if (images.length > 0 && video.length > 0) {
                    setAlertModalOpen({
                        open: true,
                        iconType: "warning",
                        title: "업로드 오류",
                        text: "비디오와 이미지는 동시에 업로드할 수 없습니다.",
                        afterFunction: null,
                    });
                    setIsUploading(false);
                    return;
                }

                //if (video.length > 1) {
                //    setAlertModalOpen({
                //        open: true,
                //        iconType: "warning",
                //        title: "업로드 오류",
                //        text: "비디오 파일은 한번에 1개씩 업로드할 수 있습니다.",
                //        afterFunction: null,
                //    });
                //    return;
                //}

                if (images.length > 2000) {
                    setAlertModalOpen({
                        open: true,
                        iconType: "warning",
                        title: "업로드 오류",
                        text: "이미지는 한번에 2000장 이상 업로드 할 수 없습니다.",
                        afterFunction: null,
                    });
                    setIsUploading(false);
                    return;
                }

                const formData = new FormData();

                formData.append("data_set", SelectedDataset.data.id);
                formData.append("annotation_type", SelectedDataset.data.annotation_type);
                formData.append("media_type", "image");
                formData.append(
                    "total_image",
                    parseInt(SelectedDataset.data.total_image) + images.length
                );

                if (SelectedDataset.data.annotation_type === "bbox") {
                    if (images.length > 0) {
                        for (let i = 0; i < images.length; i++) {
                            let file_name = "";
                            let parse = images[i].path.split(".");

                            if (parse.length === 1) {
                                file_name = parse[0];
                            } else if (parse.length > 1) {
                                for (let i = 0; i < parse.length - 1; i++) {
                                    if (i == 0) {
                                        file_name += parse[i];
                                    } else {
                                        file_name += "." + parse[i];
                                    }
                                }
                            }

                            let xml_file_name = file_name + xml_type;

                            let xml_file = xml_files.filter(data => data.path === xml_file_name);

                            formData.append("file_path", images[i]);

                            if (xml_file.length === 0) {
                                formData.append("annotation_file", "");
                            } else {
                                formData.append("annotation_file", xml_file[0]);
                            }
                        }
                    }
                    //if (video.length > 0) {
                    //    setVideoFile(video[0]);
                    //    //setVideoModal(true);
                    //    return;
                    //}
                } else {
                    if (images.length > 0) {
                        for (let i = 0; i < images.length; i++) {
                            let file_name = images[i].path.split(".")[0];
                            let json_file_name = file_name + json_type;

                            let json_file = json_files.filter(data => data.path === json_file_name);

                            formData.append("file_path", images[i]);
                            if (json_file.length === 0) {
                                formData.append("annotation_file", "");
                            } else {
                                formData.append("annotation_file", json_file[0]);
                            }
                        }
                    }

                    //if (video.length > 0) {
                    //    setVideoFile(video[0]);
                    //    //setVideoModal(true);
                    //    return;
                    //}
                }

                await mediaPost.mutateAsync(
                    {token: Token.data.access, formData: formData},
                    {
                        onSuccess: res => {
                            datasetInfoRefetch();
                            setIsUploading(false);
                            getAnnotationData(1, true);
                        },
                        onError: error => {
                            if (error.response.status === 403) {
                                setAlertModalOpen({
                                    open: true,
                                    iconType: "error",
                                    title: "권한 오류",
                                    text: "업로드 권한이 없습니다.",
                                    afterFunction: null,
                                });
                            } else {
                                setAlertModalOpen({
                                    open: true,
                                    iconType: "error",
                                    title: "",
                                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                                    afterFunction: null,
                                });
                            }
                            setIsUploading(false);
                        },
                    }
                );
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "파일 포맷 오류",
                    text: "업로드 가능한 파일 포맷이 아닙니다.",
                    afterFunction: null,
                });
                setIsUploading(false);
                return;
            }
        },
        [Token, SelectedDataset]
    );

    const {refetch: datasetInfoRefetch} = useQuery(
        ["project", "dataset", "refresh", SelectedDataset.data.id],
        async e => {
            const data = await GetDataSetInfo(Token.data.access, SelectedDataset.data?.id);
            return data;
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: res => {
                dispatch(updateSelectedDatasetAction(res.data));
            },
            onError: err => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const getAnnotationData = useCallback(
        async (page, refresh) => {
            if (page === null) {
                return;
            }

            try {
                setIsLoading(true);
                const listRes = await GetAnnotationImageList(
                    Token.data.access,
                    SelectedDataset.data.id,
                    page,
                    dataAlignMethod,
                    labelMethod
                );

                setAnnotationList(prev => ({
                    ...prev,
                    count: listRes.data.count,
                    hasMore: listRes.data.next !== null ? true : false,
                    crntPage: listRes.data.next !== null ? page + 1 : null,
                    // scrollY: page !== 1 && annotationList.scrollY + 9500,
                    scrollY: 100,
                    data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
                }));
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [Token, SelectedDataset, dataAlignMethod, labelMethod]
    );

    const onClickDelete = click => {
        setIsDelete(click);
    };

    const handleDeleteAllImages = async all => {
        setConfirmModalOpen(prev => ({
            ...prev,
            loading: true,
        }));

        setIsUploading(true);
        const formData = new FormData();
        formData.append("data_set", SelectedDataset.data.id);
        formData.append("all", all);
        formData.append("labelMethod", labelMethod);

        if (selectedImages.length > 0) {
            for (let i = 0; i < selectedImages.length; i++) {
                formData.append("media_pk", selectedImages[i].id);
            }
        }

        await deletImages.mutateAsync(
            {token: Token.data.access, formData: formData},
            {
                onSuccess: res => {
                    setIsDelete(false);
                    getAnnotationData(1, true);

                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "이미지 제거 완료",
                        text: "이미지 제거가 완료되었습니다.",
                        afterFunction: null,
                    });
                    setIsUploading(false);
                    datasetInfoRefetch();
                },
                onError: error => {
                    if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 제거 권한 오류",
                            text: "이미지 제거 권한이 없습니다.",
                            afterFunction: null,
                        });
                    } else {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 제거 오류",
                            text: "이미지 제거에 실패하였습니다.",
                            afterFunction: null,
                        });
                    }
                    setIsUploading(false);
                    setConfirmModalOpen(prev => ({
                        ...prev,
                        open: false,
                        loading: false,
                    }));
                },
            }
        );

        setConfirmModalOpen(prev => ({
            ...prev,
            open: false,
            loading: false,
        }));
    };

    const handleDeleteImages = async () => {
        setConfirmModalOpen(prev => ({
            ...prev,
            loading: true,
        }));

        setIsUploading(true);
        const formData = new FormData();

        formData.append("data_set", SelectedDataset.data.id);

        if (selectedImages.length > 0) {
            for (let i = 0; i < selectedImages.length; i++) {
                formData.append("media_pk", selectedImages[i].id);
            }
        }

        await deletImages.mutateAsync(
            {token: Token.data.access, formData: formData},
            {
                onSuccess: res => {
                    setIsDelete(false);
                    getAnnotationData(1, true);
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "선택한 이미지 제거 완료",
                        text: "선택한 이미지 제거가 완료되었습니다.",
                        afterFunction: null,
                    });
                    setIsUploading(false);
                    datasetInfoRefetch();
                },
                onError: error => {
                    if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "이미지 제거 권한 오류",
                            text: "이미지 제거 권한이 없습니다.",
                            afterFunction: null,
                        });
                    } else {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "선택한 이미지 제거 오류",
                            text: "선택한 이미지 제거에 실패하였습니다.",
                            afterFunction: null,
                        });
                    }
                    setIsUploading(false);
                    setConfirmModalOpen(prev => ({
                        ...prev,
                        open: false,
                        loading: false,
                    }));
                },
            }
        );
        setConfirmModalOpen(prev => ({
            ...prev,
            open: false,
            loading: false,
        }));
    };

    const checkedImges = (imageIndex, media_pk, selectedImages, drag, lastIndex) => {
        let temp = selectedImages;
        let index = selectedImages.findIndex(data => data.index == parseInt(imageIndex));
        let image = {index: imageIndex, id: parseInt(media_pk)};
        let annotation_data = annotationList.data;

        if (drag === false) {
            if (index < 0) {
                setSelectedImages(temp.concat([image]));
                setLastIndex(image);
            } else {
                temp.splice(index, 1);
                setSelectedImages(temp.concat());
            }
        } else {
            const maxValueOfY = Math.max(...temp.map(o => o.index), 0);
            const minValueOfY = Math.min(...temp.map(o => o.index), 9999999999999);

            if (maxValueOfY === 0) {
                setSelectedImages(temp.concat([image]));
            } else {
                let maxIndex = maxValueOfY;
                let currentIndex = annotationList.data.findIndex(
                    data => data.media_pk == parseInt(media_pk)
                );
                let minIndex = minValueOfY;

                if (maxValueOfY === imageIndex || minValueOfY === imageIndex) {
                    temp = [];
                    let image = {index: imageIndex, id: parseInt(media_pk)};
                    temp = temp.concat([image]);
                    setSelectedImages(temp);
                    return;
                }

                if (lastIndex.index < imageIndex) {
                    temp = [];
                    console.log("--------------1");
                    let last = {index: 0, id: 0};
                    for (let i = minIndex; i <= currentIndex; i++) {
                        let image = {index: i, id: parseInt(annotation_data[i].media_pk)};
                        let index = temp.findIndex(
                            data => data.id == parseInt(annotation_data[i].media_pk)
                        );

                        if (index < 0) {
                            temp = temp.concat([image]);
                            last = image;
                        }
                    }
                    setLastIndex(last);
                    setSelectedImages(temp);
                } else if (lastIndex.index > imageIndex) {
                    temp = [];
                    let last = {index: 0, id: 0};
                    console.log("--------------2");
                    for (let i = maxIndex; i >= currentIndex; i--) {
                        let image = {index: i, id: parseInt(annotation_data[i].media_pk)};
                        let index = temp.findIndex(
                            data => data.id == parseInt(annotation_data[i].media_pk)
                        );

                        if (index < 0) {
                            temp = temp.concat([image]);
                            last = image;
                        }
                    }
                    setLastIndex(last);
                    setSelectedImages(temp);
                }
            }
        }

        return;
    };

    document.onkeydown = checkKeyDown;

    function checkKeyDown(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(true);
        }
    }

    document.onkeyup = checkKeyup;

    function checkKeyup(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(false);
        }
    }

    const haneldViewMethodBtnClick = useCallback(value => {
        setViewMethod(value);
    }, []);

    const handleDataAlignMethodChange = useCallback(e => {
        setDataAlignMethod(e.target.value);
    }, []);

    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
        accept: "image/*, .xml, .json, video/*",
        onDrop,
    });

    const clickCheckbox = useCallback(
        e => {
            if (e.target.checked === true) {
                if (SelectedDataset.data.annotation_type === "polygon") {
                    setLabelMethod("polygonNone");
                } else {
                    setLabelMethod("boundingboxNone");
                }
            } else {
                setLabelMethod("");
            }
        },
        [SelectedDataset]
    );

    return (
        <Wrap>
            <UploadWrapper>
                {ProjectViewer.data !== "viewer" && (
                    <DropzoneContainer
                        {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                        {isUploading ? (
                            <Lottie options={LottieLoadingOptions} width="3rem" height="3rem" />
                        ) : (
                            <>
                                <input {...getInputProps()} />
                                <UploadIcon width="2rem" height="2rem" />
                                <div>
                                    <CustomText fs="1rem">데이터 업로드</CustomText>
                                    <CustomText fs="0.875rem" cl="#909090">
                                        파일 선택 또는 Drag & Drop
                                    </CustomText>
                                </div>
                            </>
                        )}
                    </DropzoneContainer>
                )}
                <div className="button-section">
                    {isDelete === false ? (
                        <>
                            <CustomBtn onClick={() => setIsExportModal(true)}>
                                <CustomText>데이터 내보내기</CustomText>
                            </CustomBtn>
                            {ProjectViewer.data !== "viewer" && (
                                <CustomBtn onClick={e => onClickDelete(true)}>
                                    <CustomText>삭제</CustomText>
                                </CustomBtn>
                            )}
                        </>
                    ) : (
                        <>
                            {/* <CustomBtn onClick={() => handleDeleteImages()}> */}
                            <CustomBtn
                                onClick={() => {
                                    setConfirmModalOpen({
                                        open: true,
                                        title: "데이터 선택 삭제",
                                        text: `선택한 데이터를 삭제하시겠습니까?`,
                                        afterFunction: () => {
                                            handleDeleteImages();
                                        },
                                    });
                                }}>
                                <CustomText>선택 삭제 ({selectedImages.length})</CustomText>
                            </CustomBtn>
                            {/* <CustomBtn onClick={() => handleDeleteAllImages()}> */}
                            <CustomBtn
                                onClick={() => {
                                    setConfirmModalOpen({
                                        open: true,
                                        title: "데이터 선택 외 삭제",
                                        text: `선택한 이미지를 제외하고 모두 삭제하시겠습니까?`,
                                        afterFunction: () => {
                                            handleDeleteAllImages(2);
                                        },
                                    });
                                }}>
                                <CustomText>선택 외 삭제</CustomText>
                            </CustomBtn>
                            <CustomBtn
                                onClick={() => {
                                    setConfirmModalOpen({
                                        open: true,
                                        title: "데이터 전체 삭제",
                                        text: `전체 데이터를 삭제하시겠습니까?`,
                                        afterFunction: () => {
                                            handleDeleteAllImages(1);
                                        },
                                    });
                                }}>
                                <CustomText>전체 삭제</CustomText>
                            </CustomBtn>
                            <CustomBtn
                                onClick={() => {
                                    onClickDelete(false);
                                    setSelectedImages([]);
                                }}>
                                <CustomText>되돌아가기</CustomText>
                            </CustomBtn>
                        </>
                    )}
                </div>
            </UploadWrapper>

            <AlignWrap>
                <div className="left-section">
                    <div className="view-method-wrap">
                        <ListViewMethodIcon
                            className="cursor-pointer"
                            width="1rem"
                            height="1rem"
                            fill={viewMethod === "list" ? "#fff" : "#999"}
                            onClick={() => haneldViewMethodBtnClick("list")}
                        />
                        <PictureViewMethodIcon
                            className="cursor-pointer"
                            width="1rem"
                            height="1rem"
                            fill={viewMethod === "picture" ? "#fff" : "#999"}
                            onClick={() => haneldViewMethodBtnClick("picture")}
                        />
                    </div>
                    <div className="non-labeling-checkbox">
                        <CustomCheckbox
                            className="display-check"
                            defaultChecked={false}
                            onChange={clickCheckbox}
                        />
                        <CustomText fs="0.850rem" fw="400">
                            라벨링 데이터 제외
                        </CustomText>
                    </div>
                </div>

                <div className="data-align-method-wrap">
                    <select onChange={handleDataAlignMethodChange}>
                        <option value="recent">최신순</option>
                        <option value="old">오래된순</option>
                        <option value="nameAsce">이름 오름차순</option>
                        <option value="nameDesc">이름 내림차순</option>
                    </select>
                </div>
            </AlignWrap>

            <ImageListWrap ref={scrollParentRef}>
                {annotationList.data.length === 0 ? (
                    <NoModelSection>
                        <NoDataSetIcon width="2rem" height="2rem" fill="white" />
                        <CustomText fs="1rem" fw="400">
                            업로드된 데이터가 없습니다.
                        </CustomText>
                    </NoModelSection>
                ) : (
                    // {/* <ImageList viewMethod={viewMethod} dataLength={annotationList.count} next={() => getAnnotationData(annotationList.crntPage, false)} useWindow={false} hasMore={annotationList.hasMore} scrollableTarget={scrollParentRef.current} initialScrollY={annotationList.scrollY}> */}
                    <ImageList
                        viewMethod={viewMethod}
                        dataLength={annotationList.data.length}
                        next={() => getAnnotationData(annotationList.crntPage, false)}
                        useWindow={false}
                        hasMore={annotationList.hasMore}
                        scrollableTarget={scrollParentRef.current}
                        initialScrollY={annotationList.scrollY}>
                        {isDelete === false ? (
                            <>
                                {viewMethod === "list"
                                    ? annotationList.data.map((annotation, index) => {
                                          return (
                                              <DataListItem key={annotation.key}>
                                                  <p>
                                                      {(index + 1).toString() +
                                                          ". " +
                                                          annotation.media_path.split("/")[3]}
                                                  </p>
                                              </DataListItem>
                                          );
                                      })
                                    : annotationList.data.map((annotation, index) => {
                                          if (
                                              SelectedDataset.data.annotation_type === "bbox" &&
                                              annotation.annotation_data !== null &&
                                              annotation.annotation_data !== ""
                                          ) {
                                              return (
                                                  <div className="items">
                                                      <StyledImage
                                                          key={annotation.id}
                                                          src={
                                                              process.env.REACT_APP_END_POINT +
                                                              "/" +
                                                              annotation.media_path
                                                          }
                                                          alt="annotation_image"
                                                          bd="1.5px solid greenyellow;"
                                                      />
                                                      <CustomText
                                                          className="text"
                                                          style={{
                                                              whiteSpace: "nowrap",
                                                              wordBreak: "break-all",
                                                              overflow: "hidden",
                                                              textOverflow: "ellipsis",
                                                          }}>
                                                          {(index + 1).toString() +
                                                              ". " +
                                                              annotation.media_path.split("/")[3]}
                                                      </CustomText>
                                                  </div>
                                              );
                                          } else if (
                                              SelectedDataset.data.annotation_type === "bbox" &&
                                              (annotation.annotation_data === null ||
                                                  annotation.annotation_data === "")
                                          ) {
                                              return (
                                                  <div className="items">
                                                      <StyledImage
                                                          key={annotation.id}
                                                          src={
                                                              process.env.REACT_APP_END_POINT +
                                                              "/" +
                                                              annotation.media_path
                                                          }
                                                          alt="annotation_image"
                                                          bd="0px;"
                                                          del={isDelete}
                                                      />
                                                      <CustomText
                                                          className="text"
                                                          style={{
                                                              whiteSpace: "nowrap",
                                                              wordBreak: "break-all",
                                                              overflow: "hidden",
                                                              textOverflow: "ellipsis",
                                                          }}>
                                                          {(index + 1).toString() +
                                                              ". " +
                                                              annotation.media_path.split("/")[3]}
                                                      </CustomText>
                                                  </div>
                                              );
                                          }

                                          if (
                                              SelectedDataset.data.annotation_type === "polygon" &&
                                              annotation.polygon_annotation_data !== null &&
                                              annotation.polygon_annotation_data !== ""
                                          ) {
                                              return (
                                                  <div className="items">
                                                      <StyledImage
                                                          key={annotation.id}
                                                          src={
                                                              process.env.REACT_APP_END_POINT +
                                                              "/" +
                                                              annotation.media_path
                                                          }
                                                          alt="annotation_image"
                                                          bd="1.5px solid greenyellow;"
                                                      />
                                                      <CustomText
                                                          className="text"
                                                          style={{
                                                              whiteSpace: "nowrap",
                                                              wordBreak: "break-all",
                                                              overflow: "hidden",
                                                              textOverflow: "ellipsis",
                                                          }}>
                                                          {(index + 1).toString() +
                                                              ". " +
                                                              annotation.media_path.split("/")[3]}
                                                      </CustomText>
                                                  </div>
                                              );
                                          } else if (
                                              SelectedDataset.data.annotation_type === "polygon" &&
                                              (annotation.polygon_annotation_data === null ||
                                                  annotation.polygon_annotation_data === "")
                                          ) {
                                              return (
                                                  <div className="items">
                                                      <StyledImage
                                                          key={annotation.id}
                                                          src={
                                                              process.env.REACT_APP_END_POINT +
                                                              "/" +
                                                              annotation.media_path
                                                          }
                                                          alt="annotation_image"
                                                          bd="0px;"
                                                      />
                                                      <CustomText
                                                          className="text"
                                                          style={{
                                                              whiteSpace: "nowrap",
                                                              wordBreak: "break-all",
                                                              overflow: "hidden",
                                                              textOverflow: "ellipsis",
                                                          }}>
                                                          {(index + 1).toString() +
                                                              ". " +
                                                              annotation.media_path.split("/")[3]}
                                                      </CustomText>
                                                  </div>
                                              );
                                          }
                                      })}
                            </>
                        ) : (
                            <>
                                {viewMethod === "list"
                                    ? annotationList.data.map((annotation, index) => {
                                          return (
                                              <DataListItem
                                                  className="cursor-pointer"
                                                  key={annotation.id}
                                                  deleteSelected={
                                                      selectedImages.findIndex(
                                                          data => data.id == annotation.media_pk
                                                      ) >= 0
                                                  }
                                                  onClick={e =>
                                                      checkedImges(
                                                          index,
                                                          annotation.media_pk,
                                                          selectedImages,
                                                          isDrag,
                                                          lastIndex
                                                      )
                                                  }>
                                                  <p>
                                                      {(index + 1).toString() +
                                                          ". " +
                                                          annotation.media_path.split("/")[3]}
                                                  </p>
                                              </DataListItem>
                                          );
                                      })
                                    : annotationList.data.map((annotation, index) => {
                                          if (
                                              SelectedDataset.data.annotation_type === "bbox" &&
                                              annotation.annotation_data !== null &&
                                              annotation.annotation_data !== ""
                                          ) {
                                              return (
                                                  <>
                                                      {selectedImages.findIndex(
                                                          data => data.id == annotation.media_pk
                                                      ) >= 0 ? (
                                                          <label
                                                              className={styles.image_label}
                                                              onClick={e =>
                                                                  checkedImges(
                                                                      index,
                                                                      annotation.media_pk,
                                                                      selectedImages,
                                                                      isDrag,
                                                                      lastIndex
                                                                  )
                                                              }>
                                                              <StyledImage
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="1.5px solid greenyellow;"
                                                              />
                                                              <div className="items">
                                                                  <CustomText
                                                                      className="text"
                                                                      style={{
                                                                          whiteSpace: "nowrap",
                                                                          wordBreak: "break-all",
                                                                          overflow: "hidden",
                                                                          textOverflow: "ellipsis",
                                                                          paddingTop: "8px",
                                                                      }}>
                                                                      {(index + 1).toString() +
                                                                          ". " +
                                                                          annotation.media_path.split(
                                                                              "/"
                                                                          )[3]}
                                                                  </CustomText>
                                                              </div>
                                                          </label>
                                                      ) : (
                                                          <div className="items">
                                                              <StyledImage
                                                                  style={{cursor: "pointer"}}
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="1.5px solid greenyellow;"
                                                                  onClick={e =>
                                                                      checkedImges(
                                                                          index,
                                                                          annotation.media_pk,
                                                                          selectedImages,
                                                                          isDrag,
                                                                          lastIndex
                                                                      )
                                                                  }
                                                              />
                                                              <CustomText
                                                                  className="text"
                                                                  style={{
                                                                      whiteSpace: "nowrap",
                                                                      wordBreak: "break-all",
                                                                      overflow: "hidden",
                                                                      textOverflow: "ellipsis",
                                                                  }}>
                                                                  {(index + 1).toString() +
                                                                      ". " +
                                                                      annotation.media_path.split(
                                                                          "/"
                                                                      )[3]}
                                                              </CustomText>
                                                          </div>
                                                      )}
                                                  </>
                                              );
                                          } else if (
                                              SelectedDataset.data.annotation_type === "bbox" &&
                                              (annotation.annotation_data === null ||
                                                  annotation.annotation_data === "")
                                          ) {
                                              return (
                                                  <>
                                                      {selectedImages.findIndex(
                                                          data => data.id == annotation.media_pk
                                                      ) >= 0 ? (
                                                          <label
                                                              className={styles.image_label}
                                                              onClick={e =>
                                                                  checkedImges(
                                                                      index,
                                                                      annotation.media_pk,
                                                                      selectedImages,
                                                                      isDrag,
                                                                      lastIndex
                                                                  )
                                                              }>
                                                              <StyledImage
                                                                  key={annotation.id}
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="0px;"
                                                              />
                                                              <div className="items">
                                                                  <CustomText
                                                                      className="text"
                                                                      style={{
                                                                          whiteSpace: "nowrap",
                                                                          wordBreak: "break-all",
                                                                          overflow: "hidden",
                                                                          textOverflow: "ellipsis",
                                                                          paddingTop: "8px",
                                                                      }}>
                                                                      {(index + 1).toString() +
                                                                          ". " +
                                                                          annotation.media_path.split(
                                                                              "/"
                                                                          )[3]}
                                                                  </CustomText>
                                                              </div>
                                                          </label>
                                                      ) : (
                                                          <div className="items">
                                                              <StyledImage
                                                                  style={{cursor: "pointer"}}
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="0px; "
                                                                  onClick={e =>
                                                                      checkedImges(
                                                                          index,
                                                                          annotation.media_pk,
                                                                          selectedImages,
                                                                          isDrag,
                                                                          lastIndex
                                                                      )
                                                                  }
                                                              />
                                                              <CustomText
                                                                  className="text"
                                                                  style={{
                                                                      whiteSpace: "nowrap",
                                                                      wordBreak: "break-all",
                                                                      overflow: "hidden",
                                                                      textOverflow: "ellipsis",
                                                                  }}>
                                                                  {(index + 1).toString() +
                                                                      ". " +
                                                                      annotation.media_path.split(
                                                                          "/"
                                                                      )[3]}
                                                              </CustomText>
                                                          </div>
                                                      )}
                                                  </>
                                              );
                                          }

                                          if (
                                              SelectedDataset.data.annotation_type === "polygon" &&
                                              annotation.polygon_annotation_data !== null &&
                                              annotation.polygon_annotation_data !== ""
                                          ) {
                                              return (
                                                  <>
                                                      {selectedImages.findIndex(
                                                          data => data.id == annotation.media_pk
                                                      ) >= 0 ? (
                                                          <label
                                                              className={styles.image_label}
                                                              onClick={e =>
                                                                  checkedImges(
                                                                      index,
                                                                      annotation.media_pk,
                                                                      selectedImages,
                                                                      isDrag,
                                                                      lastIndex
                                                                  )
                                                              }>
                                                              <StyledImage
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="1.5px solid greenyellow;"
                                                              />
                                                              <div className="items">
                                                                  <CustomText
                                                                      className="text"
                                                                      style={{
                                                                          whiteSpace: "nowrap",
                                                                          wordBreak: "break-all",
                                                                          overflow: "hidden",
                                                                          textOverflow: "ellipsis",
                                                                          paddingTop: "8px",
                                                                      }}>
                                                                      {(index + 1).toString() +
                                                                          ". " +
                                                                          annotation.media_path.split(
                                                                              "/"
                                                                          )[3]}
                                                                  </CustomText>
                                                              </div>
                                                          </label>
                                                      ) : (
                                                          <div className="items">
                                                              <StyledImage
                                                                  style={{cursor: "pointer"}}
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="1.5px solid greenyellow;"
                                                                  onClick={e =>
                                                                      checkedImges(
                                                                          index,
                                                                          annotation.media_pk,
                                                                          selectedImages,
                                                                          isDrag,
                                                                          lastIndex
                                                                      )
                                                                  }
                                                              />
                                                              <CustomText
                                                                  className="text"
                                                                  style={{
                                                                      whiteSpace: "nowrap",
                                                                      wordBreak: "break-all",
                                                                      overflow: "hidden",
                                                                      textOverflow: "ellipsis",
                                                                  }}>
                                                                  {" "}
                                                                  {index.toString() +
                                                                      ". " +
                                                                      annotation.media_path.split(
                                                                          "/"
                                                                      )[3]}
                                                              </CustomText>
                                                          </div>
                                                      )}
                                                  </>
                                              );
                                          } else if (
                                              SelectedDataset.data.annotation_type === "polygon" &&
                                              (annotation.polygon_annotation_data === null ||
                                                  annotation.polygon_annotation_data === "")
                                          ) {
                                              return (
                                                  <>
                                                      {selectedImages.findIndex(
                                                          data => data.id == annotation.media_pk
                                                      ) >= 0 ? (
                                                          <label
                                                              className={styles.image_label}
                                                              onClick={e =>
                                                                  checkedImges(
                                                                      index,
                                                                      annotation.media_pk,
                                                                      selectedImages,
                                                                      isDrag,
                                                                      lastIndex
                                                                  )
                                                              }>
                                                              <StyledImage
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="0px;"
                                                              />
                                                              <div className="items">
                                                                  <CustomText
                                                                      className="text"
                                                                      style={{
                                                                          whiteSpace: "nowrap",
                                                                          wordBreak: "break-all",
                                                                          overflow: "hidden",
                                                                          textOverflow: "ellipsis",
                                                                          paddingTop: "8px",
                                                                      }}>
                                                                      {(index + 1).toString() +
                                                                          ". " +
                                                                          annotation.media_path.split(
                                                                              "/"
                                                                          )[3]}
                                                                  </CustomText>
                                                              </div>
                                                          </label>
                                                      ) : (
                                                          <div className="items">
                                                              <StyledImage
                                                                  style={{cursor: "pointer"}}
                                                                  src={
                                                                      process.env
                                                                          .REACT_APP_END_POINT +
                                                                      "/" +
                                                                      annotation.media_path
                                                                  }
                                                                  alt="annotation_image"
                                                                  bd="0px;"
                                                                  onClick={e =>
                                                                      checkedImges(
                                                                          index,
                                                                          annotation.media_pk,
                                                                          selectedImages,
                                                                          isDrag,
                                                                          lastIndex
                                                                      )
                                                                  }
                                                              />
                                                              <CustomText
                                                                  className="text"
                                                                  style={{
                                                                      whiteSpace: "nowrap",
                                                                      wordBreak: "break-all",
                                                                      overflow: "hidden",
                                                                      textOverflow: "ellipsis",
                                                                  }}>
                                                                  {" "}
                                                                  {(index + 1).toString() +
                                                                      ". " +
                                                                      annotation.media_path.split(
                                                                          "/"
                                                                      )[3]}
                                                              </CustomText>
                                                          </div>
                                                      )}
                                                  </>
                                              );
                                          }
                                      })}
                            </>
                        )}
                    </ImageList>
                )}
                {/* || isUploading */}
                {isLoading && (
                    <Lottie options={LottieLoadingOptions} width="10rem" height="10rem" />
                )}
            </ImageListWrap>
            <ExportDataModal isExportModal={isExportModal} setIsExportModal={setIsExportModal} />
            <ConfirmModal
                confirmModalOpen={confirmModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
            />
            {/*<VideoModal videoModal={videoModal} setVideoModalOpen={setVideoModal}></VideoModal>*/}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default DataUploadList;
