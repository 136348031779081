import React, {useEffect} from "react";
import {Layer, Line, Rect} from "react-konva";

import useStore from "./Store";

function getRelativePointerPosition(node) {
    const transform = node.getAbsoluteTransform().copy();

    transform.invert();

    const pos = node.getStage().getPointerPosition();

    return transform.point(pos);
}

function ClickPolygon(props) {
    const polygons = useStore(s => s.polygons);
    const selectedPolygonId = useStore(s => s.selectedPolygonId);
    const selectPolygon = useStore(s => s.selectPolygon);

    const setPolygons = useStore(s => s.setPolygons);
    const layerRef = React.useRef(null);
    const image = useStore(state => state.image);
    const isMosaic = useStore(state => state.isMosaic);
    const mosaics = useStore(state => state.mosaics);
    const setMosaics = useStore(state => state.setMosaics);
    const toggleDrawing = useStore(state => state.toggleIsDrawing);
    const setPointIndex = useStore(state => state.setPointIndex);
    const pointIndex = useStore(state => state.setPointIndex);
    const width = useStore(state => state.width);

    let startPointAttr = "";
    let ratio = image.width / width;

    let Rectwidth = props.rectWidth;
    let strokeWidth = props.rectWidth;

    let mosaicStrokeWidth = 5 * ratio;

    const getMousePos = stage => {
        return [stage.x, stage.y];
    };

    const deleteMosaic = id => {
        let index = mosaics.findIndex(data => data.id === id);
        mosaics.splice(index, 1);
        setMosaics(mosaics.concat());
    };

    const getAngle = (x1, y1, x2, y2, x3, y3) => {
        let rad = Math.atan2(y1 - y2, x1 - x2);
        let rad2 = Math.atan2(y3 - y2, x3 - x2);
        return Math.abs((rad - rad2) * (180 / Math.PI));
    };

    const findNearIndex = (arr, point) => {
        let angle = 0;

        let max_angle = 0;
        let index = 0;

        for (let i = 0; i < arr.length; i++) {
            if (i === arr.length - 1) {
                angle = getAngle(arr[i][0], arr[i][1], point[0], point[1], arr[0][0], arr[0][1]);
            } else {
                angle = getAngle(
                    arr[i][0],
                    arr[i][1],
                    point[0],
                    point[1],
                    arr[i + 1][0],
                    arr[i + 1][1]
                );
            }

            if (angle < 220 && angle >= max_angle) {
                index = i;
                max_angle = angle;
                //console.log(max_angle);
                //console.log(i);
            }
        }
        return index;
    };

    return (
        <Layer ref={layerRef}>
            {isMosaic === false ? (
                <>
                    {polygons.map(item => {
                        const isSelected = item.id === selectedPolygonId;
                        return (
                            <>
                                <Line
                                    name="region"
                                    points={item.flattenedPoints}
                                    stroke="black"
                                    strokeWidth={strokeWidth}
                                    closed={item.isFinished}
                                    fill={item.color}
                                    opacity={props.opacity}
                                    onMouseOver={() => {
                                        if (props.editPolygon === true) {
                                            let rect = document.querySelector(".canvas");
                                            rect.style.cursor = "pointer";
                                        }
                                    }}
                                    onMouseOut={() => {
                                        if (props.editPolygon === true) {
                                            let rect = document.querySelector(".canvas");
                                            rect.style.cursor = "default";
                                        }
                                    }}
                                    onClick={event => {
                                        if (
                                            props.polygonButtonClick === false &&
                                            isSelected === true
                                        ) {
                                            let temp = polygons;
                                            let polygonId = polygons.findIndex(
                                                data => data.id === selectedPolygonId
                                            );
                                            const stage = getRelativePointerPosition(
                                                event.target.getStage()
                                            );
                                            const mousePos = getMousePos(stage);
                                            let nearIndex = findNearIndex(
                                                temp[polygonId].points,
                                                mousePos
                                            );
                                            temp[polygonId].points.splice(
                                                nearIndex + 1,
                                                0,
                                                mousePos
                                            );
                                            setPolygons(temp);
                                            return;
                                        }

                                        if (
                                            props.polygonButtonClick === false &&
                                            (props.editPolygon === true) & (pointIndex !== "") &&
                                            isSelected === false
                                        ) {
                                            selectPolygon(item.id);
                                            props.setSelectedLabelName("");
                                            props.setDropDownSelected("");
                                            props.setModalOpen(true);
                                            let temp = polygons;
                                            let polygonId = polygons.findIndex(
                                                data => data.id === item.id
                                            );
                                            let tempArr = temp[polygonId];
                                            temp.splice(polygonId, 1);
                                            setPolygons(temp.concat(tempArr));
                                        }
                                    }}
                                    onTouchEnd={event => {
                                        if (
                                            props.polygonButtonClick === false &&
                                            isSelected === true
                                        ) {
                                            let temp = polygons;
                                            let polygonId = polygons.findIndex(
                                                data => data.id === selectedPolygonId
                                            );
                                            const stage = getRelativePointerPosition(
                                                event.target.getStage()
                                            );
                                            const mousePos = getMousePos(stage);
                                            let nearIndex = findNearIndex(
                                                temp[polygonId].points,
                                                mousePos
                                            );
                                            temp[polygonId].points.splice(
                                                nearIndex + 1,
                                                0,
                                                mousePos
                                            );
                                            const flattenedPoints = temp[polygonId].points.reduce(
                                                (a, b) => a.concat(b),
                                                []
                                            );
                                            temp[polygonId].flattenedPoints = flattenedPoints;
                                            temp[polygonId].curMousePos = [mousePos];

                                            setPolygons(temp);
                                            return;
                                        }

                                        if (
                                            props.polygonButtonClick === false &&
                                            (props.editPolygon === true) & (pointIndex !== "") &&
                                            isSelected === false
                                        ) {
                                            selectPolygon(item.id);
                                            props.setModalOpen(true);

                                            let temp = polygons;
                                            let polygonId = polygons.findIndex(
                                                data => data.id === item.id
                                            );
                                            let tempArr = temp[polygonId];
                                            temp.splice(polygonId, 1);
                                            setPolygons(temp.concat(tempArr));
                                        }
                                    }}
                                />

                                {props.editPolygon == true && (
                                    <>
                                        {item.points.map((point, index) => {
                                            const x = point[0] - Rectwidth / 2;
                                            const y = point[1] - Rectwidth / 2;

                                            if (item.isFinished === false) {
                                                startPointAttr =
                                                    index === 0
                                                        ? {
                                                              hitStrokeWidth: 4,
                                                              onMouseOver:
                                                                  props.handleMouseOverStartPoint,
                                                              onMouseOut:
                                                                  props.handleMouseOutStartPoint,
                                                              onTouchStart:
                                                                  props.handleMouseOverStartPoint,
                                                              onTouchEnd:
                                                                  props.handleMouseOverStartPoint,
                                                          }
                                                        : null;
                                            } else {
                                                startPointAttr = null;
                                            }

                                            if (isSelected === true && index !== 0) {
                                                return (
                                                    <>
                                                        <Rect
                                                            key={index}
                                                            x={x}
                                                            y={y}
                                                            width={Rectwidth}
                                                            height={Rectwidth}
                                                            stroke="black"
                                                            strokeWidth={Rectwidth}
                                                            {...startPointAttr}
                                                            draggable
                                                            className="rect"
                                                            onMouseOut={() => {
                                                                let rect =
                                                                    document.querySelector(
                                                                        ".canvas"
                                                                    );
                                                                rect.style.cursor = "default";
                                                            }}
                                                            onMouseOver={() => {
                                                                let rect =
                                                                    document.querySelector(
                                                                        ".canvas"
                                                                    );
                                                                rect.style.cursor = "move";
                                                            }}
                                                            onTouchEnd={() => {
                                                                setPointIndex(index);
                                                                props.setConfirmModalOpen({
                                                                    open: true,
                                                                    title: "점 삭제",
                                                                    text: "점을 삭제 하시겠습니까",
                                                                    afterFunction: () => {
                                                                        props.undoPolygon(
                                                                            item.id,
                                                                            index
                                                                        );
                                                                    },
                                                                });
                                                            }}
                                                            onClick={() => {
                                                                setPointIndex(index);
                                                                props.setConfirmModalOpen({
                                                                    open: true,
                                                                    title: "점 삭제",
                                                                    text: "점을 삭제 하시겠습니까",
                                                                    afterFunction: () => {
                                                                        props.undoPolygon(
                                                                            item.id,
                                                                            index
                                                                        );
                                                                    },
                                                                });
                                                            }}
                                                            onDragMove={event => {
                                                                if (props.editPolygon == true) {
                                                                    const stage =
                                                                        getRelativePointerPosition(
                                                                            event.target.getStage()
                                                                        );
                                                                    const mousePos =
                                                                        getMousePos(stage);
                                                                    let temp = polygons;
                                                                    const polygonId =
                                                                        polygons.findIndex(
                                                                            i => i.id == item.id
                                                                        );
                                                                    temp[polygonId].points[index] =
                                                                        mousePos;
                                                                    setPolygons(temp);
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                );
                                            }

                                            if (isSelected === true && index === 0) {
                                                return (
                                                    <Rect
                                                        key={index}
                                                        x={x}
                                                        y={y}
                                                        width={Rectwidth * 1.2}
                                                        height={Rectwidth * 1.2}
                                                        stroke="black"
                                                        strokeWidth={Rectwidth}
                                                        {...startPointAttr}
                                                        draggable
                                                        onMouseLeave={() => {
                                                            let rect =
                                                                document.querySelector(".canvas");
                                                            rect.style.cursor = "pointer";
                                                        }}
                                                        onMouseOver={() => {
                                                            let rect =
                                                                document.querySelector(".canvas");
                                                            rect.style.cursor = "move";
                                                        }}
                                                        onTouchEnd={() => {
                                                            setPointIndex(index);
                                                            props.setConfirmModalOpen({
                                                                open: true,
                                                                title: "점 삭제",
                                                                text: "점을 삭제 하시겠습니까",
                                                                afterFunction: () => {
                                                                    props.undoPolygon(
                                                                        item.id,
                                                                        index
                                                                    );
                                                                },
                                                            });
                                                        }}
                                                        onClick={() => {
                                                            setPointIndex(index);
                                                            props.setConfirmModalOpen({
                                                                open: true,
                                                                title: "점 삭제",
                                                                text: "점을 삭제 하시겠습니까",
                                                                afterFunction: () => {
                                                                    props.undoPolygon(
                                                                        item.id,
                                                                        index
                                                                    );
                                                                },
                                                            });
                                                        }}
                                                        onDragMove={event => {
                                                            if (props.editPolygon == true) {
                                                                const stage =
                                                                    getRelativePointerPosition(
                                                                        event.target.getStage()
                                                                    );
                                                                const mousePos = getMousePos(stage);
                                                                let temp = polygons;
                                                                const polygonId =
                                                                    polygons.findIndex(
                                                                        i => i.id == item.id
                                                                    );
                                                                temp[polygonId].points[index] =
                                                                    mousePos;
                                                                setPolygons(temp);
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        );
                    })}
                </>
            ) : (
                <>
                    <Line
                        points={props.xaxis}
                        stroke="white"
                        strokeWidth={mosaicStrokeWidth}
                        dash={[mosaicStrokeWidth, mosaicStrokeWidth / 2]}
                    />
                    <Line
                        points={props.yaxis}
                        stroke="white"
                        strokeWidth={mosaicStrokeWidth}
                        dash={[mosaicStrokeWidth, mosaicStrokeWidth / 2]}
                    />
                    {mosaics.map(mosaic => {
                        return (
                            <React.Fragment key={mosaic.id}>
                                <Rect
                                    name="mosaic"
                                    {...mosaic.points}
                                    fill={"white"}
                                    opacity={0.5}
                                    onClick={() => {
                                        props.setAlertModalOpen({
                                            open: true,
                                            iconType: "warning",
                                            title: "모자이크 지우기",
                                            text: "모자이크 영역 삭제",
                                            afterFunction: null,
                                        });
                                        deleteMosaic(mosaic.id);
                                        toggleDrawing(false);
                                        //props.setModalOpen(true);
                                    }}
                                    onTouchStart={() => {
                                        props.setAlertModalOpen({
                                            open: true,
                                            iconType: "warning",
                                            title: "모자이크 지우기",
                                            text: "모자이크 영역 삭제",
                                            afterFunction: null,
                                        });
                                        deleteMosaic(mosaic.id);
                                        toggleDrawing(false);
                                        //props.setModalOpen(true);
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </Layer>
    );
}

export default ClickPolygon;
