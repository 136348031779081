import React, {useEffect, useState, useCallback} from "react";
import styled, {css} from "styled-components";
import _ from "lodash";

import {ReactComponent as YellowStar} from "../../../assets/project/validation/yellow-star.svg";
import {ReactComponent as WhiteStart} from "../../../assets/project/validation/white-star.svg";
import {CircleText, CustomText} from "../../../styles/theme/CustomStyle";

import {useMutation} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {updateSelectedTrainModelAction} from "../../../store/reducer/SelectedTrainModel";
import {deleteSelectedTrainModelAction} from "../../../store/reducer/SelectedTrainModel";
import TrainingResult from "./TrainingResult";
import {SetTrainModel} from "../../../services/train-services";
import ModelGradeModal from "../../modal/ModelGradeModal";
import {ReactComponent as NoModelIcon} from "../../../assets/project/noModel.svg";
import AlertModal from "../../modal/AlertModal";
import {GetTrainedModelPage} from "../../../services/train-services";
import {Pagination, Input} from "antd";
import ModelListSkeleton from "../../../styles/theme/ModelListSkeleton";
import {UpdateTrainModel} from "../../../services/train-services";

const ModelWrap = styled.div`
    display: flex;
    flex-direction: column;

    box-shadow: ${props => props.bs};

    padding-right: 10px;

    .line {
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 12px; */
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }
    .model-param {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 20rem;

        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    /* height: 2.5rem; */
    .algorithm {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        margin-top: 5px;

        @media screen and (max-width: 1024px) {
            width: 100%;
            justify-content: space-between;
            width: 100%;
            margin-top: 5px;
        }
    }
    .mobile-model-param {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }

    .train-time {
        display: flex;
        flex-direction: row;
        width: 20rem;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        @media screen and (max-width: 1024px) {
            width: 100%;
            justify-content: space-between;
            width: 100%;
            margin-top: 5px;
        }
    }

    .button-div {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
`;
const CustomBtn = styled.div`
    width: ${props => props.wd};
    border-radius: 2px;
    height: 26px;

    background: ${props => props.bc};
    border-radius: 2px;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const GraphSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #21272e;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow-y: auto;
    padding: 40px;
`;

const NoModelSection = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
`;

const CustomPagination = styled(Pagination)`
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

const EditModelnameInput = styled(Input)`
    font-family: "Pretendard";
    text-align: left;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #636363;
    color: #fff;
    width: 60%;
    height: 80%;
    font-size: 0.9rem;
    ::placeholder {
        color: #9c9c9c;
    }
`;

function ModelInfo(props) {
    const params = useParams();
    const [modelList, setModelList] = useState("");
    const dispatch = useDispatch();
    const {SelectedTrainModel, UserInfo, Token, ProjectViewer} = useSelector(state => state);

    const setTrainModel = useMutation(SetTrainModel);
    const updateTrainModel = useMutation(UpdateTrainModel);

    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState({
        open: false,
        userId: null,
        modelId: null,
    });
    const [modelId, setModelId] = useState(undefined);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });
    const [crntPage, setCrntPage] = useState(props.page);
    const [editModelName, setEditModelName] = useState(false);
    const [changedModelName, setChangedModelName] = useState("");
    // const getTrainModel = useCallback(async (dataSet) => {

    const getTrainModelPage = useCallback(
        async (page, dataset, projectId) => {
            try {
                if (props.type === "validation") {
                    if (projectId !== undefined) {
                        const TrainData = await GetTrainedModelPage(page, projectId, 0);
                        setModelList(TrainData.data);
                    }
                } else if (props.type === "model") {
                    if (dataset !== undefined) {
                        const TrainData = await GetTrainedModelPage(page, 0, dataset.id);
                        setModelList(TrainData.data);
                    }
                }
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        },
        [props.type]
    );

    const setValidModel = useCallback(
        async (model, valid) => {
            const formData = new FormData();
            props.setIsModel(true);
            formData.append("file_path", model.file_path);
            formData.append("label_data", model.label_data);
            formData.append("cfg_file", model.cfg_file);
            formData.append("training_algorithm", model.training_algorithm.id);
            formData.append("gpu_server", model.id);

            await setTrainModel.mutateAsync(
                {pk: model.id, formData: formData},
                {
                    onSuccess: res => {
                        let label_list = set_label_data(model.label_data);
                        model.label_list = label_list;
                        props.setIsModel(false);
                        if (valid === true) {
                            props.setIsMenu("data");
                        }
                        dispatch(updateSelectedTrainModelAction(model));
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "검증 서버를 초기화 중입니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                        props.setIsModel(false);
                    },
                }
            );
        },
        [SelectedTrainModel]
    );
    useEffect(() => {
        getTrainModelPage(crntPage, props.dataSet, params.id);
        dispatch(deleteSelectedTrainModelAction());
    }, [props.dataSet, props.page, props.type]);

    const set_label_data = label_data => {
        let parseData = label_data.split(",");
        let label_list = [];

        for (let i = 0; i < parseData.length; ++i) {
            if (i % 2 === 1) {
                let label_data = parseData[i].split(":")[1];
                let process_label_data = "";
                if (i === parseData.length - 1) {
                    process_label_data = label_data.slice(2, label_data.length - 3);
                } else {
                    process_label_data = label_data.slice(2, label_data.length - 2);
                }
                label_list.push(process_label_data);
            }
        }

        return label_list;
    };

    const clickModel = useCallback(
        (model, type) => {
            if (model.id === SelectedTrainModel.data.id) {
                dispatch(deleteSelectedTrainModelAction());
                return;
            }
            setModelId(model.id);
            if (type === "validation") {
                setValidModel(model, false);
            } else {
                dispatch(updateSelectedTrainModelAction(model));
            }
        },
        [SelectedTrainModel]
    );

    const clickValidButton = model => {
        if (props.type === "validation") {
            if (props.carouselRef.current !== null) {
                props.carouselRef.current.next();
            }
        }
        let label_list = set_label_data(model.label_data);
        model.label_list = label_list;
        setModelId(model.id);
        setValidModel(model, true);
    };

    const downLoadData = useCallback(
        async model => {
            let a = document.createElement("a");
            a.href = process.env.REACT_APP_END_POINT + model.file_path;
            a.download = process.env.REACT_APP_END_POINT + model.file_path;
            document.body.appendChild(a);
            a.click();
        },
        [SelectedTrainModel]
    );

    const modalRefetch = useCallback(() => {
        getTrainModelPage(props.page, props.dataSet, 0);
    }, []);

    const handleClickModel = id => {
        setModelId(id);
        setEditModelName(true);
    };

    const updateTrainedModel = useCallback(async (id, changedModelName) => {
        const formData = new FormData();
        formData.append("id", parseInt(id));
        formData.append("model_name", changedModelName);

        let pattenr_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let blank_pattern = /[\s]/g;
        let special_pattern = /[`~!@#$%^&*|\\\'\";:\/?<>+-,]/gi;
        let comma_pattern = /[\[\]\(\)\{\}\']+/g;

        if (blank_pattern.test(changedModelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 공백을 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (special_pattern.test(changedModelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 특수문자를 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (comma_pattern.test(changedModelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름에 괄호를 포함할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (pattenr_kor.test(changedModelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "모델 이름 오류",
                text: "모델 이름을 영어로 작성해주세요",
                afterFunction: null,
            });
            return;
        }
        await updateTrainModel.mutateAsync(
            {token: Token.data.access, pk: parseInt(id), formData: formData},
            {
                onSuccess: res => {
                    if (res.status === 200) {
                        getTrainModelPage(crntPage, props.dataSet, params.id);
                        setChangedModelName("");
                        setModelId("");
                        setAlertModalOpen({
                            open: true,
                            iconType: "success",
                            title: "모델 이름 변경",
                            text: "모델 이름 변경이 완료 되었습니다.",
                            afterFunction: null,
                        });
                    } else {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "권한 오류",
                            text: "모델 이름은 프로젝트 관리자만 수정 할 수 있습니다.",
                            afterFunction: null,
                        });
                    }
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, []);
    return modelList === "" ? (
        <>
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
            <ModelListSkeleton />
        </>
    ) : (
        <>
            {modelList.results.length > 0 ? (
                <>
                    {modelList.results.map(model => {
                        let aleadyCheck = false;

                        if (
                            !_.isEmpty(UserInfo.data) &&
                            model.model_grade_ave.users.includes(UserInfo.data.id)
                        ) {
                            aleadyCheck = true;
                        }

                        return (
                            <ModelWrap bs="0px 1px 0px #575757;">
                                <div className="line">
                                    {modelId === model.id && editModelName == true ? (
                                        <EditModelnameInput
                                            autoFocus
                                            onFocus={e => setChangedModelName(e.target.value)}
                                            defaultValue={model.model_name}
                                            onChange={e => {
                                                setChangedModelName(e.target.value);
                                            }}
                                        />
                                    ) : (
                                        <CustomText
                                            fs="1rem"
                                            onClick={() => clickModel(model, props.type)}
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                wordBreak: "break-all",
                                                cursor: "pointer",
                                            }}>
                                            {model.model_name}
                                        </CustomText>
                                    )}

                                    <div className="model-param">
                                        <CustomText fs="0.875rem" fw="400">
                                            에포크{" "}
                                            <span style={{fontWeight: 600, marginLeft: 3}}>
                                                {model.epoch}
                                            </span>
                                        </CustomText>
                                        <span
                                            style={{
                                                fontWeight: 100,
                                                color: "#707070",
                                                margin: "0 0.8rem",
                                            }}>
                                            |
                                        </span>
                                        <CustomText fs="0.875rem" fw="400">
                                            배치사이즈{" "}
                                            <span style={{fontWeight: 600, marginLeft: 5}}>
                                                {model.batch_size}
                                            </span>
                                        </CustomText>
                                        <span
                                            style={{
                                                fontWeight: 100,
                                                color: "#707070",
                                                margin: "0 0.8rem",
                                            }}>
                                            |
                                        </span>
                                        <CustomText fs="0.875rem" fw="400">
                                            학습률{" "}
                                            <span style={{fontWeight: 600, marginLeft: 5}}>
                                                {model.learning_rate}
                                            </span>
                                        </CustomText>
                                    </div>
                                </div>
                                <div className="line" style={{marginBottom: "1rem"}}>
                                    <div className="algorithm">
                                        <div
                                            style={{display: "flex", cursor: "pointer"}}
                                            onClick={() => {
                                                if (!_.isEmpty(UserInfo.data)) {
                                                    setIsOpen({
                                                        open: true,
                                                        modelId: model.id,
                                                        userId: aleadyCheck
                                                            ? UserInfo.data.id
                                                            : null,
                                                    });
                                                } else {
                                                    setAlertModalOpen({
                                                        open: true,
                                                        iconType: "warning",
                                                        title: "로그인 필요",
                                                        text: "로그인 후 이용가능합니다.",
                                                        afterFunction: () => {
                                                            navigate("/login");
                                                        },
                                                    });
                                                }
                                            }}>
                                            {aleadyCheck ? <YellowStar /> : <WhiteStart />}
                                            {model.model_grade_ave.value !== null ? (
                                                <CustomText
                                                    fs="0.875rem"
                                                    fw="400"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "15px",
                                                    }}>
                                                    {model.model_grade_ave.value.toFixed(1)}
                                                </CustomText>
                                            ) : (
                                                <CustomText
                                                    fs="0.875rem"
                                                    fw="400"
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "15px",
                                                    }}>
                                                    0.0
                                                </CustomText>
                                            )}
                                        </div>

                                        <CircleText backgroundColor="#393939" borderColor="#393939">
                                            <CustomText fs="0.875rem" fw="400" cl="#D4D4D4">
                                                {model.training_algorithm.algorithm_name}
                                            </CustomText>
                                        </CircleText>
                                    </div>
                                    <div className="mobile-model-param">
                                        <CustomText fs="0.875rem" fw="400">
                                            에포크{" "}
                                            <span style={{fontWeight: 600, marginLeft: 3}}>
                                                {model.epoch}
                                            </span>
                                        </CustomText>
                                        <span style={{fontWeight: 100, color: "#707070"}}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            배치사이즈{" "}
                                            <span style={{fontWeight: 600, marginLeft: 5}}>
                                                {model.batch_size}
                                            </span>
                                        </CustomText>
                                        <span style={{fontWeight: 100, color: "#707070"}}>|</span>
                                        <CustomText fs="0.875rem" fw="400">
                                            학습률{" "}
                                            <span style={{fontWeight: 600, marginLeft: 5}}>
                                                {model.learning_rate}
                                            </span>
                                        </CustomText>
                                    </div>
                                    <div className="train-time">
                                        <CustomText fs="0.875rem" fw="400">
                                            학습소요 시간 &nbsp; {model.training_time}
                                        </CustomText>
                                        {props.type === "validation" && (
                                            <CustomBtn
                                                bc="#707070"
                                                wd="89px"
                                                onClick={() => clickValidButton(model)}>
                                                <CustomText fs="0.8rem" fw="400">
                                                    검증 하기
                                                </CustomText>
                                            </CustomBtn>
                                        )}
                                        {props.type === "model" && (
                                            <>
                                                {ProjectViewer.data === "manager" ? (
                                                    <div className="button-div">
                                                        {modelId === model.id &&
                                                        editModelName === true ? (
                                                            <CustomBtn
                                                                bc="#707070"
                                                                wd="70px"
                                                                onClick={() =>
                                                                    updateTrainedModel(
                                                                        model.id,
                                                                        changedModelName
                                                                    )
                                                                }>
                                                                <CustomText fs="0.8rem" fw="400">
                                                                    수정하기
                                                                </CustomText>
                                                            </CustomBtn>
                                                        ) : (
                                                            <CustomBtn
                                                                bc="#707070"
                                                                wd="70px"
                                                                onClick={() =>
                                                                    handleClickModel(model.id)
                                                                }>
                                                                <CustomText fs="0.8rem" fw="400">
                                                                    이름변경
                                                                </CustomText>
                                                            </CustomBtn>
                                                        )}
                                                        <CustomBtn
                                                            bc="#707070"
                                                            wd="70px"
                                                            onClick={() => downLoadData(model)}>
                                                            <CustomText fs="0.8rem" fw="400">
                                                                다운로드
                                                            </CustomText>
                                                        </CustomBtn>
                                                    </div>
                                                ) : (
                                                    <CustomBtn
                                                        bc="#707070"
                                                        wd="89px"
                                                        onClick={() => downLoadData(model)}>
                                                        <CustomText fs="0.8rem" fw="400">
                                                            다운로드
                                                        </CustomText>
                                                    </CustomBtn>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {SelectedTrainModel.data.id === model.id && (
                                    <GraphSection>
                                        <TrainingResult />
                                    </GraphSection>
                                )}
                            </ModelWrap>
                        );
                    })}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "2rem 0 10rem 0",
                        }}>
                        <CustomPagination
                            defaultCurrent={crntPage}
                            current={crntPage}
                            total={modelList.count}
                            onChange={page => {
                                setCrntPage(page);
                                getTrainModelPage(page);
                                navigate({
                                    search: `?tab=${props.type}&page=${page}`,
                                });
                            }}
                        />
                    </div>
                    {/* <ModelGradeModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} refetch={getTrainModelPage(props.page)} /> */}
                    <ModelGradeModal
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        refetch={modalRefetch}
                    />
                    {/* <ModelGradeModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} refetch={() => getTrainModelPage(props.page)} /> */}
                </>
            ) : (
                <NoModelSection>
                    <NoModelIcon />
                    <CustomText fs="1rem" fw="400">
                        학습완료한 모델이 존재하지 않습니다.
                    </CustomText>
                </NoModelSection>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default ModelInfo;
