import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Avatar, Button, Radio } from "antd";
import queryString from "query-string";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ArrowRightCircle } from "../../../assets/login/arrow-right-circle.svg";
import { ReactComponent as ProjectDeleteIcon } from "../../../assets/project/project-delete-icon.svg";
import { ReactComponent as PrivateIcon } from "../../../assets/project/private.svg";
import { ReactComponent as PublicIcon } from "../../../assets/project/public-icon.svg";
import { ReactComponent as NoTeamMemberIcon } from "../../../assets/project/noteammember.svg";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import RepLabelingDataDropDown from "../../../components/project/setting/RepLabelingDataDropDown";
import { UpdateProject, GetProjectExpanationMember, GetProjectDataSetList, UpdateDataSetInfo, UpdateMember, DeleteProject } from "../../../services/project-services";
import CollaboratorsDropDown from "../../../components/project/setting/CollaboratorsDropDown";
import AddMember from "../../../components/modal/AddMember";
import { Colors } from "../../../styles/theme/Colors";
import AlertModal from "../../../components/modal/AlertModal";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import SettingSkeleton from "../../../styles/theme/SettingSkeleton";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as WarningIcon } from "../../../assets/mypage/warning.svg";
import DataSetDropdown from "../../../components/project/setting/DataSetDropdown";
import EditDataSetModal from "../../../components/modal/EditDataSetModal";
import { DeleteDataSetInfo } from "../../../services/project-services";
import { updateSelectedDatasetAction } from "../../../store/reducer/SelectedDataset";
import { updateRepresentativeDatasetAction } from "../../../store/reducer/RepresentativeDataset";

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    /* margin-bottom: 4rem; */

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        /* gap: 1rem; */
    }
`;

const ListWrap = styled(ScrollContainer)`
    /* width: 100%; */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem 7rem;
    /* margin: 2rem 0 1rem 0; */
    margin-top: 1rem;

    height: 7rem;
    overflow-y: auto;

    .member {
        display: flex;
        justify-content: space-between;
        width: 20%;
    }

    @media screen and (max-width: 1024px) {
        height: 10rem;
        gap: 1.2rem;
        .member {
            justify-content: space-between;
            width: 99%;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const Section = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 0.5rem;
    margin-top: 1.3rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;

    padding: 0.1rem 1.12rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const MemberDeleteBtn = styled(Button)`
    background-color: #6f6e6e !important;
    border-radius: 2px;
    height: auto;
    margin-left: 40px;

    padding: 0rem 0.8rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #6f6e6e !important;
`;

const InputBox = styled.div`
    display: flex;
    align-items: center;

    input {
        width: 100%;
        background-color: #21272e;
        padding: 10px 1.5rem;
        border-radius: 5px !important;
        border: 1px solid #707070 !important;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: 1px solid #707070 !important;
            }
            to {
                border: 1px solid #707070 !important;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 1rem 1.5rem;
        }
    }
`;

const CustomTextArea = styled.textarea`
    width: 100%;
    background-color: #21272e;
    padding: 10px 1.5rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 10.313rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 20rem;
    }
`;

const NoDataWrap = styled.div`
    margin-top: 0.5rem;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

const StyledRadisGroup = styled(Radio.Group)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
    justify-content: center;
    gap: 10rem;
    margin-top: 2rem;

    .ant-radio-wrapper {
        margin: 0;
    }

    .ant-radio-inner {
        background-color: transparent;
        border-color: #b1b1b1;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #707070 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #e2ff9b;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #ffffff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio-checked:after {
        border-color: #707070;
    }

    .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 13px;
    }

    @media screen and (max-width: 1024px) {
        .icon {
            gap: 9px;
        }
    }
`;

const BottomMargin = styled.div`
    width: 100%;
    margin-bottom: 10rem;

    @media screen and (max-width: 1024px) {
        /* margin-bottom: 5rem; */
    }
`;

const BottomSectoin = styled.div`
    width: 100%;
    border-top: 2px solid #575757;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 3rem 4rem 3rem;
`;

const SecessionButton = styled(Button)`
    background-color: #161b22 !important;
    /* border: 1px solid #707070; */
    border-radius: 5px;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.5rem;
    border-color: #70726e !important;
`;

function SettingMain(props) {
    const { UserInfo, Token, RepresentativeDataset, SelectedManager, SelectedDataset } = useSelector((state) => state);
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const crntTab = queryString.parse(location.search).tab;
    const [project, setProject] = useState("");
    const [title, setTitle] = useState("");
    const [explanation, setExplanation] = useState("");
    const [dataset, setDataset] = useState("");
    const [deleteId, setDeleteId] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPublic, setSelectedPublic] = useState(true);
    const [dataName, setDataName] = useState("");
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const dispatch = useDispatch();

    const [editDataSetModalOpen, setEditDataSetModalOpen] = useState(false);

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (crntTab === undefined) {
            navigate({
                search: `?tab=setting`,
            });
        }
    }, [crntTab]);

    useEffect(() => {
        GetProject();
    }, []);

    const GetProject = useCallback(async () => {
        try {
            const projectData = await GetProjectExpanationMember(Token.data.access, params.id);
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            setDataset(data.data);
            setProject(projectData.data);
            setTitle(projectData.data.title);
            setExplanation(projectData.data.explanation);
            setSelectedPublic(projectData.data.is_public);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const {
        refetch: getDataSets,
        data: datasetList,
        isLoading: loading,
    } = useQuery(
        ["project", "dataset", params.id],
        async (e) => {
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onSuccess: (res) => {
                // if (_.isEmpty(RepresentativeDataset.data)) {
                dispatch(updateSelectedDatasetAction(res.data[0]));
                dispatch(updateRepresentativeDatasetAction(res.data[0]));
                // }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const EditProject = useCallback(async (name, value) => {
        try {
            const formData = new FormData();

            formData.append("type", name);
            formData.append("title", title);
            formData.append(name, value);

            const data = await UpdateProject(Token.data.access, params.id, formData);
            GetProject();

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "설정 변경 완료",
                text: "프로젝트 설정 변경이 완료되었습니다.",
                afterFunction: null,
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "설정 변경 오류",
                text: "설정 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const EditDataset = useCallback(async (prevDatasetId, datasetId, type, dataName) => {
        try {
            const formData = new FormData();
            if (type === "representive") {
                formData.append("prevDatasetId", prevDatasetId);
            }

            if (type === "title") {
                formData.append("data_name", dataName);
            }

            formData.append("datasetId", datasetId);
            formData.append("type", type);
            const data = await UpdateDataSetInfo(Token.data.access, formData);

            if (type === "representive") {
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "대표 라벨링 데이터 변경 완료",
                    text: "대표 라벨링 데이터 변경이 완료되었습니다.",
                    afterFunction: () => {
                        getDataSets();
                    },
                });
            }

            if (type === "title") {
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "데이터셋 이름 변경 완료",
                    text: "데이터셋 이름 변경이 완료되었습니다.",
                    afterFunction: () => {
                        getDataSets();
                    },
                });
                setEditDataSetModalOpen(false);
            }
        } catch (err) {
            if (type === "representive") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "대표 라벨링 데이터 변경 오류",
                    text: "대표 라벨링 데이터 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }

            if (type === "title") {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "데이터셋 이름 변경 오류",
                    text: "데이터셋 이름 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        }
    });

    const DeleteDataset = useCallback(async (deleteId) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        setDeleteLoading(true);
        try {
            const data = await DeleteDataSetInfo(Token.data.access, deleteId);
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "데이터셋 삭제 완료",
                text: "데이터셋 삭제가 완료되었습니다.",
                afterFunction: () => {
                    getDataSets();
                },
            });
            setDeleteLoading(false);
        } catch (err) {
            if (err.response.status === 400) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "데이터셋 삭제 오류",
                    text: "대표 데이터셋은 삭제가 불가능합니다. \n삭제를 원하실 경우 대표 데이터셋을 변경해주세요.",
                    afterFunction: null,
                });
            } else {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "데이터셋 삭제 오류",
                    text: "데이터셋 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }

            setDeleteLoading(false);
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const EditManager = useCallback(async () => {
        try {
            const formData = new FormData();

            formData.append("new_manager", SelectedManager.data.id);
            formData.append("prev_manager", project.manager.id);

            const data = await UpdateMember(Token.data.access, params.id, formData);
            GetProject();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "프로젝트 관리자 변경 완료",
                text: "프로젝트 관리자 변경이 완료되었습니다.",
                afterFunction: null,
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "프로젝트 관리자 변경 오류",
                text: "프로젝트 관리자 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const DeleteMember = useCallback(async (deleteId) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const data = await UpdateMember(Token.data.access, params.id, { removeUserId: deleteId });
            GetProject();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회원 삭제 완료",
                text: "회원 삭제가 완료되었습니다.",
                afterFunction: null,
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회원 삭제 오류",
                text: "회원 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const DeleteOnClick = useCallback(async (name, value) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const formData = new FormData();

            formData.append("type", name);
            formData.append("title", title);
            formData.append(name, value);

            const data = await UpdateProject(Token.data.access, params.id, formData);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "프로젝트 삭제 완료",
                text: "프로젝트 삭제가 완료되었습니다.",
                afterFunction: () => {
                    navigate("/project/list");
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "프로젝트 삭제 오류",
                text: "프로젝트 삭제에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    return !project ? (
        <SettingSkeleton />
    ) : (
        <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        프로젝트 이름
                    </CustomText>
                    <CustomBtn
                        disabled={(title === "") | (title === project.title) ? true : false}
                        bc={(title === "") | (title === project.title) ? "#B1B1B1" : "#E2FF9B"}
                        onClick={() => {
                            EditProject("title", title);
                        }}
                    >
                        <CustomText cl={(title === "") | (title === project.title) ? "#B1B1B1" : "#E2FF9B"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <InputBox>
                    <input
                        autoComplete="off"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                </InputBox>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        프로젝트 설명
                    </CustomText>
                    <CustomBtn
                        disabled={(explanation === "") | (explanation === project.explanation) ? true : false}
                        bc={(explanation === "") | (explanation === project.explanation) ? "#B1B1B1" : "#E2FF9B"}
                        onClick={() => {
                            EditProject("explanation", explanation);
                        }}
                    >
                        <CustomText cl={(explanation === "") | (explanation === project.explanation) ? "#B1B1B1" : "#E2FF9B"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <CustomTextArea
                    value={explanation}
                    onChange={(e) => {
                        setExplanation(e.target.value);
                    }}
                />
            </div>
            {/* <Wrap> */}
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        대표 라벨링 데이터 변경
                    </CustomText>
                    <CustomBtn
                        disabled={RepresentativeDataset.data.data_name === dataset[0].data_name ? true : false}
                        bc={RepresentativeDataset.data.data_name === dataset[0].data_name ? "#B1B1B1" : "#E2FF9B"}
                        onClick={() => {
                            EditDataset(dataset[0].id, RepresentativeDataset.data.id, "representive", dataName);
                        }}
                    >
                        <CustomText cl={RepresentativeDataset.data.data_name === dataset[0].data_name ? "#B1B1B1" : "#E2FF9B"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <RepLabelingDataDropDown datasetList={datasetList} />
            </div>
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        데이터셋 관리
                    </CustomText>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <CustomBtn bc="#B1B1B1" onClick={() => setEditDataSetModalOpen(true)}>
                            <CustomText cl="#B1B1B1">이름 변경</CustomText>
                        </CustomBtn>
                        <CustomBtn
                            bc="#B1B1B1"
                            loading={deleteLoading}
                            onClick={() => {
                                setConfirmModalOpen({
                                    open: true,
                                    title: "데이터셋 삭제",
                                    text: `데이터셋을 삭제하면 이미지, 라벨링 데이터, 모델이 전부 삭제됩니다.`,
                                    afterFunction: () => {
                                        DeleteDataset(SelectedDataset.data.id);
                                    },
                                });
                            }}
                        >
                            <CustomText cl="#B1B1B1">삭제</CustomText>
                        </CustomBtn>
                    </div>
                </Section>
                <DataSetDropdown datasetList={datasetList} />
            </div>
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        프로젝트 관리자 변경
                    </CustomText>
                    <CustomBtn disabled={SelectedManager.data.name === dataset[0].data_name ? true : false} bc={SelectedManager.data.name === project.manager.name ? "#B1B1B1" : "#E2FF9B"} onClick={EditManager}>
                        <CustomText cl={SelectedManager.data.name === project.manager.name ? "#B1B1B1" : "#E2FF9B"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <CollaboratorsDropDown />
            </div>
            {/* </Wrap> */}
            {/* <Wrap> */}
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem" mg={project.collaborators.length === 0 && " 0.05rem 0"}>
                        팀원
                    </CustomText>
                    <CustomBtn bc="#E2FF9B" onClick={() => setModalIsOpen(true)} style={{ display: project.collaborators.length === 0 && "none" }}>
                        <CustomText cl="#E2FF9B">초대</CustomText>
                    </CustomBtn>
                </Section>
                <ListWrap hideScrollbars={false}>
                    {project.collaborators.length === 0 ? (
                        <NoDataWrap>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                                <NoTeamMemberIcon />
                                <CustomText>팀원이 없습니다</CustomText>
                            </div>
                            <CustomBtn bc="#E2FF9B" onClick={() => setModalIsOpen(true)}>
                                <CustomText cl="#E2FF9B" mg="0 2rem">
                                    팀원 추가
                                </CustomText>
                            </CustomBtn>
                        </NoDataWrap>
                    ) : (
                        project.collaborators.map((member) => {
                            return (
                                <div className="member">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Avatar size={32} src={member.image_path ? process.env.REACT_APP_END_POINT + member.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[member.id % 10] }} />

                                        <CustomText fs="1.125rem" mg="0 0 0 15px">
                                            {member.name}
                                        </CustomText>
                                    </div>

                                    <MemberDeleteBtn
                                        onClick={() => {
                                            setConfirmModalOpen({
                                                open: true,
                                                title: "팀원 삭제",
                                                text: `팀원을 삭제하시겠습니까?`,
                                                afterFunction: () => {
                                                    DeleteMember(member.id);
                                                },
                                            });
                                        }}
                                    >
                                        <CustomText fs="0.875rem">삭제</CustomText>
                                    </MemberDeleteBtn>
                                </div>
                            );
                        })
                    )}
                </ListWrap>
            </div>
            <BottomMargin>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        프로젝트 공개 설정
                    </CustomText>
                    <CustomBtn
                        disabled={selectedPublic === project.is_public ? true : false}
                        bc={selectedPublic === project.is_public ? "#B1B1B1" : "#E2FF9B"}
                        onClick={() => {
                            EditProject("is_public", selectedPublic);
                        }}
                    >
                        <CustomText cl={selectedPublic === project.is_public ? "#B1B1B1" : "#E2FF9B"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <StyledRadisGroup
                    onChange={(e) => {
                        setSelectedPublic(e.target.value);
                    }}
                    value={selectedPublic}
                >
                    <div className="icon">
                        <PublicIcon width="30" height="30" fill={selectedPublic === true ? "#ffffff" : "#707070"} />

                        <CustomText cl={selectedPublic === true ? "#ffffff" : "#707070"}>공개</CustomText>
                        <Radio value={true} />
                    </div>
                    <div className="icon">
                        <PrivateIcon fill={selectedPublic === false ? "#ffffff" : "#707070"} />

                        <CustomText cl={selectedPublic === false ? "#ffffff" : "#707070"}>비공개</CustomText>
                        <Radio value={false} />
                    </div>
                </StyledRadisGroup>
            </BottomMargin>
            <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "center" }}>
                <BottomSectoin>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CustomText fs="1.05rem" cl="#FF6F6F">
                            프로젝트 삭제
                        </CustomText>
                        <WarningIcon />
                    </div>
                    <CustomText fs="0.95rem" ta="center" cl="#D4D4D4" mg="1rem 0px 2rem 0px" style={{ whiteSpace: "pre-wrap", lineHeight: "1.4rem" }}>
                        프로젝트 삭제시 되돌릴 수 없으며{"\n"}등록된 모든 데이터는 더이상 이용 불가능합니다.
                    </CustomText>
                    <SecessionButton
                        onClick={() => {
                            setConfirmModalOpen({
                                open: true,
                                title: "프로젝트 삭제",
                                text: "정말 삭제하시겠습니까?",
                                afterFunction: () => {
                                    DeleteOnClick("is_del", true);
                                },
                            });
                        }}
                    >
                        <CustomText fs="1.1rem">프로젝트 삭제</CustomText>
                        <ArrowRightCircle stroke="#ffffff" width="1.2rem" height="1.2rem" />
                    </SecessionButton>
                </BottomSectoin>
            </div>
            {/* </Wrap> */}
            <AddMember modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            <EditDataSetModal editDataSetModalOpen={editDataSetModalOpen} setEditDataSetModalOpen={setEditDataSetModalOpen} dataName={dataName} setDataName={setDataName} EditDataset={EditDataset} />
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </div>
    );
}

export default SettingMain;
