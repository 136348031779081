import { Button } from "antd";
import React, { useCallback } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import Lottie from "react-lottie";

import * as warningLottie from "../../assets/modal/warning-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    margin-top: 2.5rem;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 1,
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

function ConfirmModal({ confirmModalOpen, setConfirmModalOpen }) {
    const confirmModal = useCallback(() => {
        if (confirmModalOpen.afterFunction !== null) {
            confirmModalOpen.afterFunction();
        } else {
            setConfirmModalOpen((prev) => ({
                ...prev,
                open: false,
            }));
        }
    }, [confirmModalOpen]);

    const cancelConfirmModal = useCallback(() => {
        setConfirmModalOpen({
            open: false,
            title: null,
            text: null,
            afterFunction: null,
        });
    }, [confirmModalOpen]);

    return (
        <Modal isOpen={confirmModalOpen.open} onRequestClose={cancelConfirmModal} style={customStyles} contentLabel="Alert Modal">
            <Lottie
                options={{
                    loop: false,
                    autoplay: true,
                    animationData: warningLottie,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                    },
                }}
                width="4rem"
                height="4rem"
                style={{
                    marginBottom: "0.8rem",
                }}
            />

            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                {confirmModalOpen.title}
            </CustomText>
            <CustomText fs="0.875rem" cl="#d4d4d4" ta="center" style={{ whiteSpace: "pre-line" }}>
                {confirmModalOpen.text}
            </CustomText>

            <BtnWrapper>
                <CustomBtn bc={"#B2B1B1"} onClick={cancelConfirmModal}>
                    <CustomText fs="0.9rem" cl={"#B2B1B1"}>
                        취소
                    </CustomText>
                </CustomBtn>
                <CustomBtn bc={"#E2FF9B"} onClick={confirmModal} loading={confirmModalOpen.loading}>
                    <CustomText fs="0.9rem" cl={"#E2FF9B"}>
                        확인
                    </CustomText>
                </CustomBtn>
            </BtnWrapper>
        </Modal>
    );
}

export default ConfirmModal;
