import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as PlusIcon } from "../../../assets/project/plus-icon.svg";
import { ReactComponent as LabelingIcon } from "../../../assets/project/labeling-icon.svg";
import { ReactComponent as PreProcessIcon } from "../../../assets/project/preprocess-icon.svg";
import { CustomText } from "../../../styles/theme/CustomStyle";
import DataSetDropDown from "./DataSetDropDown";
import DataGraph from "./DataGraph";
import DataUploadList from "./DataUploadList";
import PreprocessMain from "../preprocess";
import LabelingMain from "../labeling";
import MoblieLabel from "../labeling/MoblieLabel";
import AutoLabelMain from "../auto-label";
import AddDataset from "../../modal/AddDataset";
import numberFormatting from "../../../common/numberFormatting";
import _ from "lodash";

import AlertModal from "../../modal/AlertModal";
import MergeDatasetModal from "../../modal/MergeDatasetModal";
import { ProjectDataSetCheck, UpdateDataCenterImage } from "../../../services/datacenter-services";
import RegistrationDataCenterModal from "../../modal/RegistrationDataCenterModal";
import ConfirmModal from "../../modal/ConfirmModal";
import { Tooltip } from "antd";

const DateSelectWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    min-width: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .btn-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: auto;
        min-height: 0;
        align-self: stretch;
        gap: 15px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .btn-section {
            width: 100%;
            height: 2.5rem;
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;
    flex: 1;

    padding: 0 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const DataWrapper = styled.div`
    margin-top: 0.5rem;
    border-bottom: 1px solid #707070;

    .title-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #707070;
        padding-bottom: 10px;
    }

    .dataset-section {
        margin-top: 1.3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -15px;
    }

    .dataset-section .count-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .preprocess-section {
        margin-top: 0.4rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    @media screen and (max-width: 1024px) {
        .dataset-section {
            flex-direction: column;
            align-items: flex-start;
        }

        .dataset-section .count-section {
            width: 100%;
            margin-top: 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            .border {
                display: None;
            }
        }

        .preprocess-section {
            margin-bottom: 1.6rem;
        }
    }
`;

const LabelingWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

function DataSetMain({ page = "dataset", projectData }) {
    const { SelectedDataset, ProjectViewer, Token, UserInfo } = useSelector((state) => state);
    const navigate = useNavigate();
    const params = useParams();

    const [isDataSetCheck, setIsDataSetCheck] = useState(false);
    const [dataCenterId, setDataCenterId] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [mergeModalOpen, setMergeModalOpen] = useState(false);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const clickDataSetMerge = () => {
        if (ProjectViewer.data !== "manager") {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "권환 오류",
                text: "데이터 병합 권한이 없습니다.",
                afterFunction: null,
            });
            return;
        }
        setMergeModalOpen(true);
    };
    const [dataCenterModalOpen, setDataCenterModalOpen] = useState({
        open: false,
        afterFunction: null,
    });

    useEffect(() => {
        dataCenterCheck();
    }, []);

    const dataCenterCheck = useCallback(async () => {
        try {
            const data = await ProjectDataSetCheck(Token.data.access, params.id);
            setIsDataSetCheck(true);
            setDataCenterId(data.data.dataset_id);
        } catch (err) {
            setIsDataSetCheck(false);
        }
    });

    const updateDataCenter = useCallback(async () => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const formData = new FormData();

            formData.append("project_dataset_id", SelectedDataset.data.id);

            const data = await UpdateDataCenterImage(Token.data.access, dataCenterId, formData);
            navigate(`/data-center/${dataCenterId}`);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "데이터센터 업데이트 오류",
                text: "데이터셋을 업데이트 하는데 실패하였습니다. \n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: false,
        }));
    });

    const autoLabelingClick = useCallback(() => {
        navigate({
            search: `?tab=dataset&pg=autolabel`,
        });
    });

    return page === "dataset" ? (
        <>
            <DateSelectWrapper>
                <DataSetDropDown />
                {ProjectViewer.data !== "viewer" && (
                    <>
                        <div className="btn-section">
                            <CustomBtn bc="#b2b1b1" onClick={autoLabelingClick}>
                                <CustomText fs="0.875rem" cl="#b1b1b1">
                                    오토 라벨링
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn bc="#b2b1b1" onClick={() => clickDataSetMerge()}>
                                <CustomText fs="0.875rem" cl="#b1b1b1">
                                    데이터셋 병합
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn bc="#E2FF9B" onClick={() => setIsOpen(true)}>
                                <CustomText fs="0.875rem" cl="#E2FF9B">
                                    새 데이터셋
                                </CustomText>
                                <PlusIcon width="1rem" height="1rem" fill="#E2FF9B" />
                            </CustomBtn>
                        </div>
                    </>
                )}
            </DateSelectWrapper>
            <DataWrapper>
                <div className="title-section">
                    <CustomText fs="1.125rem" fw="600" style={{ flex: 6 }}>
                        데이터셋 정보
                    </CustomText>
                    {projectData?.manager.id === UserInfo.data.id &&
                        (isDataSetCheck === true ? (
                            <CustomBtn
                                bc="#b2b1b1"
                                onClick={() => {
                                    setConfirmModalOpen({
                                        open: true,
                                        iconType: null,
                                        title: "데이터센터 업데이트",
                                        text: "기존 데이터센터의 등록되어있는 이미지를\n현재 데이터셋의 이미지로 변경합니다.",
                                        afterFunction: () => {
                                            updateDataCenter();
                                        },
                                    });
                                }}
                            >
                                <CustomText fs="0.875rem">데이터센터 업데이트</CustomText>
                            </CustomBtn>
                        ) : (
                            <CustomBtn
                                bc="#b2b1b1"
                                onClick={() => {
                                    setDataCenterModalOpen({
                                        open: true,
                                        afterFunction: null,
                                    });
                                }}
                            >
                                <CustomText fs="0.875rem">데이터센터 등록하기</CustomText>
                            </CustomBtn>
                        ))}
                </div>

                <div className="dataset-section">
                    <CustomText fs="1.25rem" fw="500">
                        {SelectedDataset?.data?.annotation_type === "bbox" ? "Bounding box" : SelectedDataset?.data?.annotation_type}
                    </CustomText>

                    <div className="count-section">
                        <div>
                            <CustomText fs="1.125rem" mg="0 0.7rem 0 0">
                                업로드 데이터
                            </CustomText>
                            <Tooltip title={SelectedDataset?.data?.total_image}>
                                <CustomText fs="1.125rem" fw="500">
                                    {numberFormatting(SelectedDataset?.data?.total_image)}
                                </CustomText>
                            </Tooltip>
                        </div>
                        <div className="border" style={{ borderRight: "2px solid #707070", height: "1.125rem", margin: "0 2rem" }} />
                        <div>
                            <CustomText fs="1.125rem" mg="0 0.7rem 0 0">
                                라벨링 데이터
                            </CustomText>
                            <Tooltip title={SelectedDataset?.data?.labeling_data?.label_data_count}>
                                <CustomText fs="1.125rem" fw="500">
                                    {numberFormatting(SelectedDataset?.data?.labeling_data?.label_data_count)}
                                </CustomText>
                            </Tooltip>
                        </div>
                        <div className="border" style={{ borderRight: "2px solid #707070", height: "1.125rem", margin: "0 2rem" }} />
                        <div>
                            <CustomText fs="1.125rem" mg="0 0.7rem 0 0">
                                라벨 수
                            </CustomText>
                            <Tooltip title={SelectedDataset?.data?.labeling_data?.count}>
                                <CustomText fs="1.125rem" fw="500">
                                    {numberFormatting(SelectedDataset?.data?.labeling_data?.count)}
                                </CustomText>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <DataGraph />

                {ProjectViewer.data !== "viewer" && (
                    <div className="preprocess-section">
                        <CustomBtn
                            bc="#b2b1b1"
                            style={{ flex: 1, padding: "0.7rem 0" }}
                            onClick={() => {
                                if (SelectedDataset?.data.total_image !== 0) {
                                    navigate({
                                        search: `?tab=dataset&pg=preprocess`,
                                    });
                                } else {
                                    setAlertModalOpen({
                                        open: true,
                                        iconType: "warning",
                                        title: "데이터 오류",
                                        text: "업로드 된 데이터가 없습니다.",
                                        afterFunction: null,
                                    });
                                }
                            }}
                        >
                            <PreProcessIcon width="1.5rem" height="1.5rem" />
                            <CustomText fs="1rem" mg="0px 0px 0px 1rem">
                                데이터 전처리
                            </CustomText>
                        </CustomBtn>
                        <CustomBtn
                            bc="#b2b1b1"
                            style={{ flex: 1, padding: "0.7rem 0" }}
                            onClick={() => {
                                if (SelectedDataset?.data.total_image !== 0) {
                                    navigate({
                                        search: `?tab=dataset&pg=labeling`,
                                    });
                                } else {
                                    setAlertModalOpen({
                                        open: true,
                                        iconType: "warning",
                                        title: "데이터 오류",
                                        text: "업로드 된 데이터가 없습니다.",
                                        afterFunction: null,
                                    });
                                }
                            }}
                        >
                            <LabelingIcon width="1.5rem" height="1.5rem" />
                            <CustomText fs="1rem" mg="0px 0px 0px 1rem">
                                데이터 라벨링
                            </CustomText>
                        </CustomBtn>
                    </div>
                )}
            </DataWrapper>
            <DataUploadList />
            <RegistrationDataCenterModal dataCenterModalOpen={dataCenterModalOpen} setDataCenterModalOpen={setDataCenterModalOpen} />
            <AddDataset modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            <MergeDatasetModal modalIsOpen={mergeModalOpen} setIsOpen={setMergeModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
        </>
    ) : page === "preprocess" ? (
        <PreprocessMain />
    ) : page === "labeling" ? (
        <LabelingWrap>
            <LabelingMain />
            <MoblieLabel />
        </LabelingWrap>
    ) : (
        page === "autolabel" && <AutoLabelMain />
    );
}

export default DataSetMain;
