import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import _ from "lodash";

function DataGraph(props) {
    const { SelectedDataset } = useSelector((state) => state);

    const series = useMemo(() => {
        const array = [];
        if (!_.isEmpty(SelectedDataset.data)) {
            Object.keys(SelectedDataset.data.labeling_data.data).forEach(function (k) {
                array.push({ name: k, data: [SelectedDataset.data.labeling_data.data[k]] });
            });
        }
        if (array.length !== 0) {
            return array;
        } else {
            return [
                {
                    name: "라벨링 데이터가 없습니다",
                    data: [1],
                },
            ];
        }
    }, [SelectedDataset]);

    const options = {
        chart: {
            type: "bar",
            // height: 50,
            stacked: true,
            stackType: "100%",
            toolbar: {
                show: true,
                tools: {
                    download: false,
                },
            },
            fontFamily: "Pretendard",
            foreColor: "#fff",
        },
        grid: {
            show: false,
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 0,
            colors: ["#fff"],
        },
        xaxis: {
            // categories: [1],
            show: false,
            float: true,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            show: false,
            floating: true,

            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },

            crosshairs: {
                show: false,
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "bottom",
            horizontalAlign: "left",
            offsetX: -40,
            floating: false,
            fontSize: "14px",
            fontFamily: "Pretendard",
            fontColor: "red",
            fontWeight: 400,
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: "16px",
                fontFamily: "Pretendard",
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter: (val) => {
                        return val;
                    },
                },
            },
            marker: {
                show: true,
            },
        },
    };

    return <ReactApexChart options={options} series={series} type="bar" height="140" />;
}

export default DataGraph;
