import React, { useState } from "react";
import { Avatar, Button, Layout } from "antd";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import _ from "lodash";

import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as UpdateIcon } from "../../assets/datacenter/dataset-update-icon.svg";
import { CreateProjectLike, DeleteProjectLike, GetProjectExpanationMember, ProjectFork } from "../../services/project-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import { Colors } from "../../styles/theme/Colors";
import AddMember from "../modal/AddMember";
import AlertModal from "../modal/AlertModal";
import { GetDataCenterExplanationMember } from "../../services/datacenter-services";
import UpdateDataSetExplanation from "../modal/UpdateDataSetExplanation";

const Wrap = styled(Layout)`
    background-color: rgba(0, 0, 0, 0);
    max-width: 360px;
    min-width: 360px;
    margin-top: 1.3rem;

    ${(props) =>
        props.page === "preprocess" || props.page === "labeling"
            ? css`
                  @media screen and (max-width: 1700px) {
                      display: none;
                  }

                  @media screen and (min-width: 1920px) {
                      max-width: 400px;
                  }
              `
            : css`
                  @media screen and (max-width: 1024px) {
                      display: none;
                  }

                  @media screen and (min-width: 1920px) {
                      max-width: 400px;
                  }
              `}
`;

const DataCenterInfoWrapper = styled.div`
    background-color: #303a45;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;

    .public-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .public-section .like-fork-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 13px;
    }
`;

const MemberWrapper = styled.div`
    flex: 1;
    margin-bottom: 4rem;
    padding: 0px 1rem 0 1rem;

    .title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title-section div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .member-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

const MemberItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

function DataCenterSide({ page }) {
    const { Token, UserInfo } = useSelector((state) => state);
    const params = useParams();

    const [modalIsOpen, setIsOpen] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const {
        data: dataCenterData,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["datacenter", "explanation", "member", params.id],
        async (e) => {
            const data = await GetDataCenterExplanationMember(Token.data.access, params.id);
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {},
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <Wrap page={page}>
            {!loading && (
                <>
                    {dataCenterData?.data.data_explanation && (
                        <DataCenterInfoWrapper>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <CustomText fs="1.125rem" fw="600" mg="0px 0px 10px 0px">
                                    데이터셋 설명
                                </CustomText>
                                {UserInfo.data.id === dataCenterData?.data.provider.id && (
                                    <UpdateIcon
                                        width="1rem"
                                        height="1rem"
                                        style={{ marginBottom: "10px", cursor: "pointer" }}
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                    />
                                )}
                            </div>

                            <CustomText fs="1rem" fw="400" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: dataCenterData?.data.data_explanation }} />
                        </DataCenterInfoWrapper>
                    )}
                    {/* </ProjectInfoWrapper> */}

                    <MemberWrapper>
                        <div className="title-section">
                            <div>
                                <MemberIcon width="1.3rem" height="1.3rem" fill="#fff" />
                                <CustomText mg="0 0 0 10px">데이터 제공</CustomText>
                            </div>
                        </div>

                        <div className="member-list">
                            <MemberItem>
                                <Avatar size="default" src={dataCenterData?.data.provider.image_path ? process.env.REACT_APP_END_POINT + dataCenterData?.data.provider.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[dataCenterData?.data.provider.id % 10] }} />
                                <CustomText style={{ width: "80%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>{dataCenterData?.data.provider.login_id}</CustomText>
                            </MemberItem>
                        </div>
                    </MemberWrapper>
                </>
            )}
            <UpdateDataSetExplanation modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} dataCenterData={dataCenterData?.data} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default DataCenterSide;
