import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { Button } from "antd";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import _, { times } from "lodash";

import { ReactComponent as UploadIcon } from "../../../assets/project/upload-icon.svg";
import { CustomText } from "../../../styles/theme/CustomStyle";
import ExportDataModal from "../../modal/ExportDataModal";

import AlertModal from "../../modal/AlertModal";
import Lottie from "react-lottie";

import * as LoadingData from "../../../assets/loading/loaindg-lottie.json";
import ConfirmModal from "../../modal/ConfirmModal";

const Wrap = styled.div`
    padding-bottom: 10px;
`;

const UploadWrapper = styled.div`
    flex-direction: row;
    align-items: stretch;
    gap: 1rem;

    .button-section {
        flex: 0.27;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: stretch;
        height: auto;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        .button-section {
            flex-direction: row;
            margin-top: 0.5rem;
        }
    }
`;

const DropzoneContainer = styled.div`
    flex: 0.73;
    display: flex;
    background-color: #21272e;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    gap: 1.5rem;
    padding: 1.5rem;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #b2b1b1 !important;

    @media screen and (max-width: 1024px) {
        padding: 1.5rem 0;
    }
`;

const ImageListWrap = styled.div`
    width: 100%;
    margin-top: 1.5rem;
    overflow-y: auto;

    max-height: 50vh;
    border-radius: 3px;
    background-color: #21272e;
    padding: 0.8rem;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const NoImageList = styled.div`
    width: 100%;
    margin-top: 1.5rem;
    overflow-y: auto;
    max-height: 50vh;
    border-radius: 3px;
    background-color: #21272e;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
`;

const ImageList = styled(InfiniteScroll)`
    width: 100%;
    max-height: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px;
    grid-gap: 1rem;
    padding-right: 0.5rem;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100px;
    }
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    border: ${(props) => props.bd} !important;
`;

function AutoLabelingList({ imageList, setImageList }) {
    const { Token, SelectedDataset, ProjectViewer } = useSelector((state) => state);

    const [isExportModal, setIsExportModal] = useState(false);

    const [fileArray, setFileArray] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [isUploading, setIsUploading] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    //useEffect(() => {
    //    if (!_.isEmpty(SelectedDataset.data)) {
    //        getAnnotationData(1, true);
    //    }
    //}, [SelectedDataset]);

    const LotteLoadingnOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const onDrop = useCallback(
        async (file) => {
            setIsUploading(true);
            setIsLoading(true);
            let fileobj = [];
            let fileArray = [];

            fileobj.push(file);

            if (file.length > 500) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "이미지 개수 초과",
                    text: "오토라벨링 이미지는 최대 500개 까지만 가능합니다.",
                    afterFunction: null,
                });
                setIsUploading(false);
                setIsLoading(false);
                return;
            }

            for (let i = 0; i < fileobj[0].length; i++) {
                fileArray.push(URL.createObjectURL(fileobj[0][i]));
            }
            setFileArray(fileArray);
            setImageList(file);
            setIsUploading(false);
            setIsLoading(false);
        },
        [Token, SelectedDataset]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ accept: "image/*", onDrop });

    return (
        <Wrap>
            <UploadWrapper>
                {ProjectViewer.data !== "viewer" && (
                    <DropzoneContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />
                        <UploadIcon width="2rem" height="2rem" />
                        <div>
                            <CustomText fs="1rem">이미지 업로드</CustomText>
                            <CustomText fs="0.875rem" cl="#909090">
                                파일 선택 또는 Drag & Drop
                            </CustomText>
                        </div>
                    </DropzoneContainer>
                )}
            </UploadWrapper>
            {imageList.length === 0 ? (
                <NoImageList>
                    <CustomText>이미지를 업로드해주세요</CustomText>
                </NoImageList>
            ) : (
                <ImageListWrap>
                    {isLoading || isUploading ? (
                        <Lottie options={LotteLoadingnOptions} width="10rem" height="10rem" />
                    ) : (
                        <ImageList pageStart={1} useWindow={false} load="true" initialLoad={false}>
                            {fileArray.map((url) => {
                                return <StyledImage src={url} />;
                            })}
                        </ImageList>
                    )}
                </ImageListWrap>
            )}
            <ExportDataModal isExportModal={isExportModal} setIsExportModal={setIsExportModal} />
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default AutoLabelingList;
