export const initialState = {
    data: {
        xPosition: 0,
        yPosition: 0,
        cropWidth: 0,
        cropHeight: 0,
        rotateDegree: 0,
        scaleX: 0,
        scaleY: 0,
    },
};

export const updateCropInfoAction = (data) => {
    return {
        type: "UPDATE_CROP_INFO",
        data,
    };
};

export const deleteCropInfoAction = () => {
    return {
        type: "DELETE_CROP_INFO",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_CROP_INFO":
            return {
                ...state,
                data: action.data,
            };

        case "DELETE_CROP_INFO":
            return {
                ...state,
                data: initialState,
            };

        default:
            return state;
    }
};

export default reducer;
