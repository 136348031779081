import React, {useEffect, useCallback, useState} from "react";
import styled from "styled-components";

import {CustomText} from "../../../styles/theme/CustomStyle";

import {useSelector} from "react-redux";

import Annotator from "../../../components/annotator/Annotator";
import useStore from "../../../components/annotator/Store";
import {GetAnnotationAllData} from "../../../services/labeling-services";
import AlertModal from "../../modal/AlertModal";
import {Checkbox} from "antd";
const Wrap = styled.div`
    @media screen and (max-width: 1700px) {
        margin-right: 3rem;
    }

    @media screen and (max-width: 1024px) {
        margin-right: 0;
    }

    .title {
        width: 97%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: inset 0 -1px 0 0 #575757;
        margin-top: 20px;
        padding-bottom: 10px;
    }

    .image-section {
        height: 100%;

        margin-top: 15px;
        /* height: 70%; */
        padding-bottom: 20px;

        width: 97%;
        overflow-x: hidden;
        @media screen and (max-width: 1024px) {
            max-width: 100%;
            height: fit-content;
        }
    }

    .no-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        /* height: 70%; */
        background-color: #21272e;
    }
    .filename {
        display: flex;
        justify-content: start;
        padding-top: 10px;
    }
    .non-labeling-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: fit-content;
        gap: 10px;
    }
`;

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        border: 1px solid #636363;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #161b22;
    }
`;

function LabelingMain() {
    const {Token, SelectedDataset} = useSelector(state => state);
    const annotationList = useStore(state => state.annotationList);
    const setAnnotationList = useStore(state => state.setAnnotationList);
    const setPolygons = useStore(state => state.setPolygons);
    const selectPolygon = useStore(state => state.selectPolygon);
    const polygons = useStore(state => state.polygons);
    const selectedPolygonId = useStore(state => state.selectedPolygonId);
    const setImageInputIndex = useStore(state => state.setImageInputIndex);
    const setPolygonButtonClick = useStore(state => state.setPolygonButtonClick);
    const setAnnotationPk = useStore(state => state.setAnnotationPk);
    const setMediaPk = useStore(state => state.setMediaPk);
    const setBBox = useStore(state => state.setBBox);
    const setPoints = useStore(state => state.setPoints);
    const setImageURL = useStore(state => state.setImageURL);
    const setIsDrag = useStore(state => state.setIsDrag);
    const annotationPk = useStore(state => state.annotationPk);
    const mediaPk = useStore(state => state.mediaPk);
    const points = useStore(state => state.points);
    const bbox = useStore(state => state.bbox);
    const image_url = useStore(state => state.image_url);
    const setEditPolygon = useStore(state => state.setEditPolygon);
    const setIsMosaic = useStore(state => state.setIsMosaic);
    const imageInputIndex = useStore(state => state.imageInputIndex);
    const editPolygon = useStore(state => state.editPolygon);
    const setMosaics = useStore(state => state.setMosaics);
    const pointIndex = useStore(state => state.pointIndex);
    const setIsInputIndex = useStore(state => state.setIsInputIndex);
    const isLabelPage = useStore(state => state.isLabelPage);
    const setFileName = useStore(state => state.setFileName);
    const filename = useStore(state => state.filename);
    const [labelMethod, setLabelMethod] = useState("null");
    const isOpen = useStore(s => s.isOpen);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (SelectedDataset.data.id !== undefined) {
            getAnnotationAllData(SelectedDataset.data);
        }
        if (annotationList.length > 0) {
            setAnnotationPk(annotationList[imageInputIndex - 1].id);
            setMediaPk(annotationList[imageInputIndex - 1].media_pk);
            setFileName(annotationList[imageInputIndex - 1].media_path.split("/")[3]);
            if (SelectedDataset.data.annotation_type === "bbox") {
                setBBox(annotationList[imageInputIndex - 1].annotation_data);
            } else {
                setPoints(annotationList[imageInputIndex - 1].polygon_annotation_data);
            }

            setImageURL(
                process.env.REACT_APP_END_POINT +
                    "/" +
                    annotationList[imageInputIndex - 1].media_path
            );
            setIsInputIndex(false);
        }
    }, [SelectedDataset, labelMethod]);

    useEffect(() => {
        if (annotationList.length > 0) {
            setAnnotationPk(annotationList[imageInputIndex - 1].id);
            setMediaPk(annotationList[imageInputIndex - 1].media_pk);
            setFileName(annotationList[imageInputIndex - 1].media_path.split("/")[3]);
            if (SelectedDataset.data.annotation_type === "bbox") {
                setBBox(annotationList[imageInputIndex - 1].annotation_data);
            } else {
                setPoints(annotationList[imageInputIndex - 1].polygon_annotation_data);
            }

            setImageURL(
                process.env.REACT_APP_END_POINT +
                    "/" +
                    annotationList[imageInputIndex - 1].media_path
            );
            setIsInputIndex(false);
        } else {
            setFileName("");
        }
    }, [annotationList, labelMethod]);

    const getAnnotationAllData = useCallback(
        async dataset => {
            try {
                const res = await GetAnnotationAllData(
                    Token.data.access,
                    parseInt(dataset.id),
                    dataset.annotation_type,
                    labelMethod
                );
                setAnnotationList(res.data);
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        },
        [SelectedDataset, labelMethod]
    );

    const clickCheckbox = useCallback(
        e => {
            if (e.target.checked === true) {
                if (SelectedDataset.data.annotation_type === "polygon") {
                    setLabelMethod("polygonNone");
                } else {
                    setLabelMethod("boundingboxNone");
                }
            } else {
                setLabelMethod("null");
            }
        },
        [SelectedDataset]
    );

    document.onkeydown = checkKey;

    function checkKey(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(true);
        }

        if (isLabelPage === true && isOpen == false) {
            let canvas = document.querySelector(".canvas");
            if (canvas !== null) {
                //canvas.style.cursor = "default";
                if (e.keyCode == "37") {
                    if (imageInputIndex - 1 > 0) {
                        setAnnotationPk(annotationList[parseInt(imageInputIndex) - 2].id);
                        setMediaPk(annotationList[parseInt(imageInputIndex) - 2].media_pk);
                        setFileName(
                            annotationList[parseInt(imageInputIndex) - 2].media_path.split("/")[3]
                        );
                        if (SelectedDataset.data.annotation_type === "bbox") {
                            setBBox(annotationList[parseInt(imageInputIndex) - 2].annotation_data);
                            setIsMosaic(false);
                            setMosaics([]);
                        } else {
                            setPoints(
                                annotationList[parseInt(imageInputIndex) - 2]
                                    .polygon_annotation_data
                            );
                            setPolygonButtonClick(false);
                            setEditPolygon(false);
                            setIsMosaic(false);
                            setMosaics([]);
                        }
                        setImageURL(
                            process.env.REACT_APP_END_POINT +
                                "/" +
                                annotationList[parseInt(imageInputIndex) - 2].media_path
                        );
                        setImageInputIndex(parseInt(imageInputIndex) - 1);
                    }
                } else if (e.keyCode == "39") {
                    if (imageInputIndex < SelectedDataset.data.total_image) {
                        setAnnotationPk(annotationList[imageInputIndex].id);
                        setMediaPk(annotationList[imageInputIndex].media_pk);
                        setFileName(annotationList[imageInputIndex].media_path.split("/")[3]);
                        if (SelectedDataset.data.annotation_type === "bbox") {
                            setBBox(annotationList[imageInputIndex].annotation_data);
                            setIsMosaic(false);
                            setMosaics([]);
                        } else {
                            setPoints(annotationList[imageInputIndex].polygon_annotation_data);
                            setPolygonButtonClick(false);
                            setEditPolygon(false);
                            setIsMosaic(false);
                            setMosaics([]);
                        }

                        setImageURL(
                            process.env.REACT_APP_END_POINT +
                                "/" +
                                annotationList[imageInputIndex].media_path
                        );
                        setImageInputIndex(parseInt(imageInputIndex) + 1);
                    }
                } else if (e.keyCode == "46") {
                    undoPolygon();
                } else if (e.keyCode == "45") {
                    addpolygon();
                }

                if (e.keyCode === 16) {
                    setIsDrag(true);
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "grab";
                }
            }
        }
    }

    const addpolygon = () => {
        let id = 0;
        let temp = polygons;

        if (editPolygon === true) {
            if (selectedPolygonId !== null) {
                setAlertModalOpen({
                    open: true,
                    iconType: "warning",
                    title: "폴리곤 수정 저장 오류",
                    text: "저장 버튼을 누른후 그리기 버튼을 눌러 주세요",
                    afterFunction: null,
                });
                return;
            }
        }

        setPolygonButtonClick(true);
        setIsMosaic(false);
        setEditPolygon(false);

        let index = temp.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            return;
        }

        if (selectedPolygonId !== null) {
            return;
        }

        if (polygons.length > 0) {
            temp.sort((a, b) => a.id - b.id);
            id = temp[temp.length - 1].id + 1;
        } else {
            id = 0;
        }

        const polygon = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: false,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        temp = temp.concat([polygon]);
        setPolygons(temp);
        selectPolygon(id);
    };

    const undoPolygon = () => {
        let polygonId = polygons.findIndex(data => data.id === selectedPolygonId);
        if (selectedPolygonId === null) {
            return;
        }

        if (editPolygon === false) {
            if (polygons[polygonId].points.length > 0) {
                if (polygons[polygonId].points.length === 1) {
                    setPolygonButtonClick(false);
                }
                polygons[polygonId].points.pop();
                return;
            }
        } else {
            if (polygons[polygonId].points.length > 0) {
                if (polygons[polygonId].points.length === 1) {
                    setPolygonButtonClick(false);
                }
                if (editPolygon === false) {
                    polygons[polygonId].points.pop();
                } else {
                    if (pointIndex !== "") {
                        polygons[polygonId].points.splice(pointIndex, 1);
                        setPolygons(polygons.concat());
                    }
                }
                return;
            }
        }
    };

    return (
        <>
            <Wrap className="image-wrap" onClick={() => setIsInputIndex(false)}>
                <div className="title">
                    <CustomText fs="1.125rem" fw="600">
                        데이터 라벨링
                    </CustomText>
                    <div className="non-labeling-checkbox">
                        <CustomCheckbox
                            className="display-check"
                            defaultChecked={false}
                            onChange={clickCheckbox}
                        />
                        <CustomText fs="0.850rem" fw="400">
                            라벨링 데이터 제외
                        </CustomText>
                    </div>
                </div>
                <div className="filename">
                    <CustomText
                        className="text"
                        style={{
                            whiteSpace: "nowrap",
                            wordBreak: "break-all",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                        {filename}
                    </CustomText>
                </div>
                {annotationList.length > 0 ? (
                    <div className="image-section">
                        <Annotator
                            image_url={image_url}
                            annotationPk={annotationPk}
                            imageId={mediaPk}
                            bbox={bbox}
                            points={points}
                            datasetId={SelectedDataset.data.id}
                        />
                    </div>
                ) : (
                    <div className="no-image">
                        <CustomText fs="1rem">이미지를 업로드해주세요</CustomText>
                    </div>
                )}
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </>
    );
}

export default LabelingMain;
