import React, { useState } from "react";
import styled from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";
import { ReactComponent as EmailApproveIcon } from "../../assets/signup/emailapprove.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ApproveEmail } from "../../services/account-services";
import AlertModal from "../../components/modal/AlertModal";

const Wrap = styled.div`
    min-height: inherit;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7.2rem;
    /* justify-content: center; */
    padding-bottom: 10rem;

    @media screen and (max-width: 768px) {
        padding-top: 9.45rem;
    }
`;

const SignUpBox = styled.div`
    width: 30rem;
    margin-top: 50px;
    background-color: #21272e;
    padding: 40px 0px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .assentSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .scrollDiv {
        background-color: #181f27;
        overflow: auto;
        width: 100%;
        border-radius: 5px;
        height: 10rem;
        padding: 1rem;

        ::-webkit-scrollbar {
            background-color: #181f27;
            width: 20px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #707070;
            background-clip: padding-box;
            border: 9px solid transparent;
        }
    }
`;

function EmailApprove() {
    const { uidb64, token } = useParams();

    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data, isLoading, error } = useQuery(
        ["emailApprove"],
        async (e) => {
            const data = await ApproveEmail(uidb64, token);
            return data;
        },
        {
            retry: false,
            onSuccess: (res) => {},
            onError: (err) => {
                if (err.response.data?.msg) {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        text: err.response.data?.msg,
                        afterFunction: null,
                    });
                } else {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                }

                navigate("/", {
                    replace: true,
                });
            },
        }
    );

    return (
        <HomeLayout headerShown={false}>
            <Wrap>
                <Link to="/">
                    <Logo width="16rem" height="100%" />
                </Link>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <SignUpBox>
                        <EmailApproveIcon style={{ margin: "20px 0px 50px 0px" }} />
                        <CustomText fs="1.05rem" fw={500}>
                            이메일 인증이 완료되었습니다
                        </CustomText>
                    </SignUpBox>

                    <Link to="/login">
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <CustomText fs="1rem" cl="#7CBEFF">
                                로그인
                            </CustomText>
                            <ArrowRightCircle stroke="#7CBEFF" width="1.2rem" height="1.2rem" />
                        </div>
                    </Link>
                </div>
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </HomeLayout>
    );
}

export default EmailApprove;
