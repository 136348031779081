import { Button, Dropdown, Layout, Menu, Radio } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import AppFooter from "../../components/layout/AppFooter";
import AppHeader from "../../components/layout/AppHeader";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateProjectAPI } from "../../services/project-services";
import AlertModal from "../../components/modal/AlertModal";

const Wrap = styled(Layout)`
    min-height: 100vh;
    background-color: #161b22;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0px 4rem 0;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const Contents = styled(Content)`
    width: 90%;
    max-width: 1100px;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -2px 0 0 #575757;
    padding-bottom: 10px;
    margin-top: 2.3rem;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
    }
`;

const TitleInput = styled.input`
    width: 100%;
    background-color: #21272e !important;
    border-radius: 3px 0px 0px 3px;
    padding: 10px 10px;
    color: #fff;
    font-size: 1rem;
    font-family: "Pretendard";
    font-weight: 400;

    border: 1px solid #21272e;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const ExplanationInput = styled.textarea`
    width: 100%;
    background-color: #21272e !important;
    border-radius: 3px 0px 0px 3px;
    padding: 10px 10px;
    color: #fff;
    font-size: 1rem;
    font-family: "Pretendard";
    font-weight: 400;
    resize: none;
    border: 1px solid #21272e;

    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 3px;
    padding: 0.6rem 1.2rem;
    border: none;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const DataSetWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
`;

const StyledRadisGroup = styled(Radio.Group)`
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
    justify-content: center;
    gap: 3rem;
    padding: 0 3rem;

    .ant-radio-wrapper {
        margin: 0;
    }

    .ant-radio-inner {
        background-color: transparent;
        border-color: #b1b1b1;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #707070 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #e2ff9b;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #ffffff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio-checked:after {
        border-color: #707070;
    }

    .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 13px;
    }

    @media screen and (max-width: 1024px) {
        .icon {
            gap: 9px;
        }
    }
`;

const PublicWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 3rem; */

    .toggle-section {
        /* flex: 1; */
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
    }

    .public-explanation {
        flex: 1;
        border-radius: 5px;
        background-color: #303a45;
        padding: 1.2rem 3rem;
        padding-right: 10rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding-left: 0;
        padding-top: 2rem;
        gap: 3rem;

        .toggle-section {
            flex-direction: column;
            gap: 2rem;
        }

        .public-explanation {
            padding: 1.2rem 3rem;

            p > br {
                display: none;
            }
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: ${(props) => props.backcolor} !important;
    border-radius: 5px;
    height: auto;
    min-height: 0;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    gap: 10px;

    border-color: ${(props) => props.bc} !important;
`;

function CreateProject(props) {
    const { Token } = useSelector((state) => state);
    const navigate = useNavigate();
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [inputs, setInputs] = useState({
        title: "",
        dataset: "",
        explanation: "",
    });
    const [errors, setErrors] = useState({
        title: "",
        dataset: "",
    });
    const [datasetType, setDatasetType] = useState({
        text: "Bounding Box",
        value: "bbox",
    });
    const [isPublic, setIsPublic] = useState(true);

    const createMutation = useMutation(CreateProjectAPI);

    const handleInputChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "title" && errors.title !== "") {
                setErrors((prev) => ({
                    ...prev,
                    title: "",
                }));
            } else if (name === "dataset" && errors.dataset !== "") {
                setErrors((prev) => ({
                    ...prev,
                    dataset: "",
                }));
            }

            setInputs((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [inputs, errors]
    );

    const handlePublicBtnClick = useCallback(() => {
        setIsPublic(!isPublic);
    }, [isPublic]);

    const handleCreateBtnClick = useCallback(async () => {
        if (inputs.title === "") {
            setErrors((prev) => ({
                ...prev,
                title: "프로젝트 이름을 입력해주세요",
            }));
            window.scrollTo(0, 0);
            return;
        }

        if (inputs.dataset === "") {
            setErrors((prev) => ({
                ...prev,
                dataset: "대표 데이터셋 이름을 입력해주세요",
            }));
            window.scrollTo(0, 0);
            return;
        }

        const formData = new FormData();
        formData.append("title", inputs.title);
        formData.append("explanation", inputs.explanation);
        formData.append("dataset", inputs.dataset);
        formData.append("is_public", isPublic);
        formData.append("annotation_type", datasetType.value);

        await createMutation.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onSuccess: (res) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "프로젝트 생성 완료",
                        text: "프로젝트 생성이 완료되었습니다.\n학습 데이터 업로드 후 학습을 진행해보세요.",
                        afterFunction: () => {
                            navigate(`/project/${res.data.id}?tab=dataset`, {
                                replace: true,
                            });
                        },
                    });
                },
                onError: (error) => {
                    if (error.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "프로젝트 생성 오류",
                            text: "이미 존재하는 프로젝트 이름입니다.\n다른 프로젝트 이름을 입력해주세요.",
                            afterFunction: null,
                        });
                        setErrors((prev) => ({
                            ...prev,
                            title: "이미 존재하는 프로젝트 이름입니다",
                        }));
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "프로젝트 생성 오류",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, inputs, isPublic, datasetType]);

    const DatasetTypeList = useMemo(
        () => (
            <StyledMenu>
                <Menu.Item
                    className="menu-item"
                    onClick={() =>
                        setDatasetType({
                            text: "Bounding Box",
                            value: "bbox",
                        })
                    }
                >
                    <MenuItem>
                        <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            Bounding Box
                        </CustomText>
                    </MenuItem>
                </Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() =>
                        setDatasetType({
                            text: "Polygon",
                            value: "polygon",
                        })
                    }
                >
                    <MenuItem>
                        <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            Polygon
                        </CustomText>
                    </MenuItem>
                </Menu.Item>
            </StyledMenu>
        ),
        []
    );

    return (
        <>
            <AppHeader />
            <Wrap>
                <Contents>
                    <CustomText fs="1.563rem" fw="600" wd="100%">
                        프로젝트 생성
                    </CustomText>

                    <TitleSection style={{ marginTop: "1.3rem" }}>
                        <CustomText fs="1.25rem" fw="600">
                            프로젝트 이름
                        </CustomText>
                    </TitleSection>
                    <TitleInput name="title" value={inputs.title} maxLength="20" onChange={handleInputChange} autoComplete="off" placeholder="프로젝트 이름을 입력해주세요" />

                    {errors.title !== "" && (
                        <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A" wd="100%">
                            {errors.title}
                        </CustomText>
                    )}
                    <TitleSection>
                        <CustomText fs="1.25rem" fw="600">
                            프로젝트 설명 (선택)
                        </CustomText>
                    </TitleSection>
                    <TitleInput name="explanation" rows={5} maxLength="20" value={inputs.explanation} onChange={handleInputChange} autoComplete="off" placeholder="(선택) 프로젝트 설명을 입력해주세요" />

                    <TitleSection>
                        <CustomText fs="1.25rem" fw="600">
                            대표 데이터셋 생성
                        </CustomText>
                    </TitleSection>
                    <DataSetWrapper>
                        <div>
                            <StyledDropDown overlay={DatasetTypeList} placement="bottom" trigger={["click"]}>
                                <MenuItem>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                                        <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {datasetType.text}
                                        </CustomText>
                                        <DownArrow width="1.125rem" height="1.125rem" />
                                    </div>
                                </MenuItem>
                            </StyledDropDown>
                        </div>
                        <div style={{ width: "100%" }}>
                            <TitleInput name="dataset" value={inputs.dataset} maxLength="20" onChange={handleInputChange} autoComplete="off" placeholder="대표 데이터셋 이름을 입력해주세요" />
                            {errors.dataset !== "" && (
                                <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A" wd="100%">
                                    {errors.dataset}
                                </CustomText>
                            )}
                        </div>
                    </DataSetWrapper>

                    <TitleSection>
                        <CustomText fs="1.25rem" fw="600">
                            프로젝트 공개 설정
                        </CustomText>
                    </TitleSection>
                    <PublicWrapper>
                        {/* <div className="toggle-section">
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 13 }}>
                                {isPublic ? <PublicIcon fill="white" width="2rem" height="2rem" /> : <PrivateIcon width="2rem" height="2rem" />}

                                <CustomText fs="1.25rem" fw="600">
                                    {isPublic ? "공개" : "비공개"}
                                </CustomText>
                            </div>
                            <CustomBtn backcolor="rgba(0,0,0,0)" bc="#b2b1b1" onClick={handlePublicBtnClick}>
                                <CustomText fs="1rem" cl="#b1b1b1">
                                    {isPublic ? "비공개" : "공개"} 프로젝트로 변경
                                </CustomText>
                            </CustomBtn>
                        </div> */}
                        <StyledRadisGroup
                            onChange={(e) => {
                                setIsPublic(e.target.value);
                            }}
                            value={isPublic}
                        >
                            <div className="icon">
                                <PublicIcon width="2rem" height="2rem" fill={isPublic === true ? "#ffffff" : "#707070"} />

                                <CustomText cl={isPublic === true ? "#ffffff" : "#707070"}>공개</CustomText>
                                <Radio value={true} />
                            </div>
                            <div className="icon">
                                <PrivateIcon width="2rem" height="2rem" fill={isPublic === false ? "#ffffff" : "#707070"} />

                                <CustomText cl={isPublic === false ? "#ffffff" : "#707070"}>비공개</CustomText>
                                <Radio value={false} />
                            </div>
                        </StyledRadisGroup>
                        <div className="public-explanation">
                            <CustomText fs="0.875rem" mg="0 0 1rem 0">
                                프로젝트 공개 설정시 프로젝트가 데이터센터에 노출되며 다른 이용자들이 해당 프로젝트를
                                <br /> 열람 및 복사가 가능합니다.
                            </CustomText>
                            <CustomText fs="0.875rem">
                                단, 공개 프로젝트의 경우 해당 프로젝트의 팀원이 아닐 경우
                                <br /> 업로드, 학습 예약 등은 이용할 수 없습니다.
                            </CustomText>
                        </div>
                    </PublicWrapper>

                    <CustomBtn backcolor="rgba(0,0,0,0)" bc="#b2b1b1" onClick={handleCreateBtnClick} style={{ marginTop: "5rem", padding: "1rem 6rem" }}>
                        <CustomText fs="1rem" cl="#fff">
                            프로젝트 생성
                        </CustomText>
                    </CustomBtn>
                </Contents>
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
            <AppFooter />
        </>
    );
}

export default CreateProject;
