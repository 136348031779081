import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { Pagination, Button } from "antd";
import queryString from "query-string";

import HomeLayout from "../../components/layout/HomeLayout";
import AlertModal from "../../components/modal/AlertModal";
import { GetNoticeListApi } from "../../services/board-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import NoticeListSkeleton from "../../styles/theme/NoticeListSkeleton";
import { useSelector } from "react-redux";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
    background-color: #161b22;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.div`
    margin-top: 1.3rem;
    width: 90%;
    max-width: 1100px;
`;

const StyledTable = styled.table`
    width: 100%;
    margin-top: 2rem;

    .text-align-left {
        text-align: left;
    }

    .text-align-right {
        text-align: right;
    }

    .text-align-center {
        text-align: center;
    }

    thead {
        border-bottom: 1px solid #707070;
    }

    tbody {
        border-collapse: separate !important;
        border-spacing: 0 10px !important;
    }

    @media screen and (max-width: 1024px) {
        .mobile-hidden {
            display: none;
        }
    }
`;

const NoDataWrap = styled.div`
    margin-top: 10px;
    background-color: #21272e;
    width: 100%;
    height: 17rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const CustomPagination = styled(Pagination)`
    margin-top: 3rem;
    margin-bottom: 10rem;
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    /* flex: 1; */

    padding: 5px 25px;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

function NoticeMain(props) {
    const { UserInfo, Token } = useSelector((state) => state);

    const location = useLocation();
    const navigate = useNavigate();

    const crntPage = queryString.parse(location.search).page;

    const [isLoading, setIsLoaindg] = useState(false);
    const [noticeData, setNoticeData] = useState({
        totalPage: 1,
        list: [],
    });
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (crntPage === undefined) {
            navigate({
                pathname: "/notice",
                search: "?page=1",
            });
            return;
        }

        getNoticeList(crntPage);
    }, [crntPage]);

    const getNoticeList = useCallback(async (page) => {
        setIsLoaindg(true);

        try {
            if (page === 1) {
                // setIsLoaindg(true);
            }
            const { data: data } = await GetNoticeListApi(page);

            setNoticeData({
                totalPage: data.count,
                list: data.results,
            });
        } catch (e) {
            if (e.response.status === 404) {
                navigate({
                    pathname: "/notice",
                    search: "?page=1",
                });
                return;
            }

            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "Network 오류",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        } finally {
            // if (page === 1) {
            setIsLoaindg(false);
            // }
        }
    }, []);

    return (
        <HomeLayout>
            <Wrap>
                <Content>
                    <CustomText fs="2rem" fw="600" wd="100%" ta="center">
                        공지사항
                    </CustomText>

                    <StyledTable>
                        <colgroup>
                            <col className="mobile-hidden" style={{ width: "5%" }} />
                            <col style={{ width: "auto" }} />
                            <col style={{ width: "12rem" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="mobile-hidden text-align-left">
                                    <CustomText fs="1.125rem" fw="600" mg="0 0 10px 0">
                                        No.
                                    </CustomText>
                                </th>
                                <th className="text-align-left">
                                    <CustomText fs="1.125rem" fw="600" mg="0 0 10px 0">
                                        제목
                                    </CustomText>
                                </th>
                                <th className="text-align-right">
                                    <CustomText fs="1.125rem" fw="600" mg="0 0 10px 0" wd="100%" ta="right">
                                        작성일
                                    </CustomText>
                                </th>
                            </tr>
                        </thead>
                        {isLoading ? (
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                        <NoticeListSkeleton />
                                    </td>
                                </tr>
                            </tbody>
                        ) : !isLoading && noticeData.list.length === 0 ? (
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <NoDataWrap>
                                            <CustomText>공지사항이 없습니다.</CustomText>
                                        </NoDataWrap>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {noticeData.list.map((notice) => {
                                    return (
                                        <tr key={notice.id} style={{ borderBottom: "1px solid #707070" }}>
                                            <td className="mobile-hidden">
                                                <CustomText wd="100%" ta="left" mg="10px 0">
                                                    {notice.id}
                                                </CustomText>
                                            </td>
                                            <td className="text-align-left">
                                                <Link to={`/notice/${notice.id}`}>
                                                    <CustomText wd="100%" mg="10px 0 " style={{ wordBreak: "break-all" }}>
                                                        {notice.title}
                                                    </CustomText>
                                                </Link>
                                            </td>
                                            <td>
                                                <CustomText fs="0.875rem" wd="100%" ta="right" mg="10px 0" cl="#D4D4D4">
                                                    {dayjs(notice.created_at).locale("ko").format("YYYY-MM-DD A h:mm")}
                                                </CustomText>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </StyledTable>
                </Content>

                {UserInfo.data.is_admin && (
                    <div style={{ marginTop: "1rem", width: "90%", maxWidth: "1100px", display: "flex", justifyContent: "flex-end" }}>
                        <CustomBtn
                            bc="#b2b1b1"
                            onClick={() => {
                                navigate("/notice/crate");
                            }}
                        >
                            <CustomText fs="0.9rem" cl="#b2b1b1">
                                글쓰기
                            </CustomText>
                        </CustomBtn>
                    </div>
                )}
                <CustomPagination
                    defaultCurrent={parseInt(crntPage)}
                    current={parseInt(crntPage)}
                    total={noticeData.totalPage}
                    pageSize={10}
                    onChange={(page) => {
                        navigate({
                            pathname: "/notice",
                            search: `?page=${page}`,
                        });
                    }}
                />
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </HomeLayout>
    );
}

export default NoticeMain;
