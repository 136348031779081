import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as UpIcon } from "../../assets/mypage/up.svg";
import { ReactComponent as DownIcon } from "../../assets/mypage/down.svg";
import { ReactComponent as DataCenterIcon } from "../../assets/mypage/datacenter.svg";
import { ReactComponent as NewLearnProjectIcon } from "../../assets/mypage/newlearnproject.svg";
import { ReactComponent as TrainDataIcon } from "../../assets/mypage/traindata.svg";
import { ReactComponent as CompanyIcon } from "../../assets/mypage/company.svg";
import { useSelector } from "react-redux";

import ReservedTrain from "./ReservedTrain";
import ProgressTrain from "./ProgressTrain";
import { GetTotalCount } from "../../services/account-services";
import { useQuery } from "react-query";
import AlertModal from "../../components/modal/AlertModal";
import { GetCurrentTrain } from "../../services/train-services";

const Box = styled.div`
    background-color: #21272e;
    border: 1px solid #707070;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1.3rem 0;
    flex: 1;

    div {
        display: flex;
        align-items: center;

        .comparison {
            flex-direction: column;
        }
    }
`;

function DashBoard() {
    const { UserInfo, Token } = useSelector((state) => state);
    const [totalCount, setTotalCount] = useState("");

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data, isLoading, refetch } = useQuery(
        ["totalCount"],
        async (e) => {
            const data = await GetTotalCount(Token.data.access);
            setTotalCount(data.data[0]);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: TrainSchdule, isLoading: loading } = useQuery(
        ["project", "train"],
        async (e) => {
            const data = await GetCurrentTrain();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        setTimeout(() => {
            refetch();
        }, 60000);
    }, [totalCount]);

    return (
        totalCount && (
            <>
                <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                    <div style={{ flex: 1.2 }}>
                        <Box>
                            <CustomText fw={600} fs="1.4rem">
                                NewLearn 가입 회원
                            </CustomText>
                            <div style={{ justifyContent: "space-evenly", width: "100%" }}>
                                <div className="comparison">
                                    <CompanyIcon />
                                    <CustomText fw={600} mg="10px 0 0 0">
                                        기업 회원
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.company_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">한달전 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.company_count) - parseInt(totalCount.month_company_count) < 0 ? <DownIcon /> : <UpIcon />}
                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.company_count) - parseInt(totalCount.month_company_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comparison">
                                    <MemberIcon style={{ width: "3.5rem", height: "3.5rem" }} fill="white" />
                                    <CustomText fw={600} mg="5px 0 0 0">
                                        개인 회원
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.member_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">한달전 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.member_count) - parseInt(totalCount.month_member_count) < 0 ? <DownIcon /> : <UpIcon />}

                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.member_count) - parseInt(totalCount.month_member_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                    <div style={{ flex: 2 }}>
                        <Box style={{ alignItems: "flex-start", padding: "1.3rem 3rem", height: "100%" }}>
                            <CustomText fw={600} fs="1.4rem">
                                현재 진행중인 학습
                            </CustomText>
                            <ProgressTrain totalCount={totalCount} />
                        </Box>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "1rem", marginBottom: "10rem" }}>
                    <div style={{ flex: 1.2, display: "flex", flexDirection: "column", gap: "1.1rem" }}>
                        <Box>
                            <CustomText fw={600} fs="1.4rem">
                                프로젝트 현황
                            </CustomText>
                            <div style={{ justifyContent: "space-evenly", width: "100%" }}>
                                <div className="comparison">
                                    <NewLearnProjectIcon />
                                    <CustomText fw={600} mg="10px 0 0 0">
                                        NewLearn 프로젝트
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.project_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">한달전 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.project_count) - parseInt(totalCount.month_project_count) < 0 ? <DownIcon /> : <UpIcon />}

                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.project_count) - parseInt(totalCount.month_project_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comparison">
                                    <DataCenterIcon />
                                    <CustomText fw={600} mg="5px 0 0 0">
                                        Data Center
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.data_center_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">한달전 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.data_center_count) - parseInt(totalCount.month_data_center_count) < 0 ? <DownIcon /> : <UpIcon />}

                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.data_center_count) - parseInt(totalCount.month_data_center_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <Box>
                            <CustomText fw={600} fs="1.4rem">
                                NewLearn 학습 데이터
                            </CustomText>
                            <div style={{ justifyContent: "space-evenly", width: "100%" }}>
                                <div className="comparison">
                                    <TrainDataIcon />
                                    <CustomText fw={600} mg="10px 0 0 0">
                                        학습 데이터
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.train_data_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">한달전 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.train_data_count) - parseInt(totalCount.month_train_data_count) < 0 ? <DownIcon /> : <UpIcon />}

                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.train_data_count) - parseInt(totalCount.month_train_data_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comparison">
                                    <TrainDataIcon />
                                    <CustomText fw={600} mg="10px 0 0 0">
                                        금일 학습 데이터
                                    </CustomText>
                                    <div style={{ gap: "1.5rem" }}>
                                        <CustomText fw={500} fs="3.125rem">
                                            {totalCount.today_train_data_count}
                                        </CustomText>
                                        <div className="comparison" style={{ alignItems: "flex-start" }}>
                                            <CustomText cl="#9C9C9C">어제 보다</CustomText>
                                            <div style={{ gap: "5px" }}>
                                                {parseInt(totalCount.today_train_data_count) - parseInt(totalCount.yesterday_train_data_count) < 0 ? <DownIcon /> : <UpIcon />}

                                                <CustomText fw={700} cl="#C1C1C1" fs="1.3rem" style={{ lineHeight: "1.3rem" }}>
                                                    {Math.abs(parseInt(totalCount.today_train_data_count) - parseInt(totalCount.yesterday_train_data_count))}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                    <div style={{ flex: 2 }}>
                        <Box style={{ alignItems: "flex-start", padding: "1.3rem 3rem 4.5rem 3rem", height: "35rem" }}>
                            <CustomText fw={600} fs="1.4rem" ta="left" style={{ boxShadow: "inset 0 -2px 0 0 #575757", width: "100%", paddingBottom: "10px" }}>
                                예약된 학습
                            </CustomText>
                            <ReservedTrain totalCount={totalCount} />
                        </Box>
                    </div>
                </div>
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </>
        )
    );
}

export default DashBoard;
