import React from "react";
import styled from "styled-components";
import HomeLayout from "../../../components/layout/HomeLayout";
import GuideLayout from "../../../components/layout/GuideLayout";
import GettingStarted from "../../../components/document/GettingStarted";
import queryString from "query-string";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProjectGuide from "../../../components/document/getting-started/projectGuide";
import TrainDataGuide from "../../../components/document/getting-started/trainDataGuide";
import DataPreprocessingGuide from "../../../components/document/getting-started/dataPreprocessingGuide";
import DataLabelingGuide from "../../../components/document/getting-started/dataLabelingGuide";
import TrainReservationGuide from "../../../components/document/getting-started/trainReservationGuide";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
`;

const ContentWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #0c1117;
`;

function GettingStartedGuide(props) {
    const { search } = useLocation();
    const query = queryString.parse(search);
    const [step, setStep] = useState(undefined);

    useEffect(() => {
        if (query.step !== undefined) {
            setStep(parseInt(query.step));
        } else {
            setStep(query.step);
        }
    }, [search]);

    return (
        <HomeLayout>
            <Wrap>
                <GuideLayout />
                <ContentWrap>
                    {step === undefined && <GettingStarted />}
                    {step === 1 && <ProjectGuide />}
                    {step === 2 && <TrainDataGuide />}
                    {step === 3 && <DataPreprocessingGuide />}
                    {step === 4 && <DataLabelingGuide />}
                    {step === 5 && <TrainReservationGuide />}
                </ContentWrap>
            </Wrap>
        </HomeLayout>
    );
}

export default GettingStartedGuide;
