import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button, Radio } from "antd";
import Lottie from "react-lottie";

import { ReactComponent as AddDatasetIcon } from "../../assets/modal/add-dataset-icon.svg";

import * as successLottie from "../../assets/modal/success-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import MergeDatasetDropDown from "./MergeDatasetDropDown";
import { useMutation, useQuery } from "react-query";
import { CreateDataset, GetProjectDataSetList } from "../../services/project-services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSelectedDatasetAction } from "../../store/reducer/SelectedDataset";
import AlertModal from "./AlertModal";
import { MergeDataset } from "../../services/project-services";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 90%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;
        text-align: center;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
    border-bottom: 1px dashed #b1b1b1;
`;

const StyledRadisGroup = styled(Radio.Group)`
    margin-top: 1.5rem;
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    /* flex: 1; */

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

Modal.setAppElement("#root");

function MergeDatasetModal({ modalIsOpen, setIsOpen }) {
    const { Token, SelectedDataset, ProjectViewer } = useSelector((state) => state);
    const params = useParams();
    const dispatch = useDispatch();

    const [selectedType, setSelectedType] = useState("bbox");
    const [newDatasetName, setNewDatasetName] = useState("");
    const [newDatasetNameErr, setNewDatasetNameErr] = useState("");
    const [copyDataset, setCopyDataset] = useState({
        id: 0,
        data_name: "병합 데이터셋 선택",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const mergeDataset = useMutation(MergeDataset);

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (!modalIsOpen) {
            setCopyDataset({
                id: 0,
                data_name: "병합 데이터셋 선택",
            });
            setNewDatasetName("");
            setSelectedType("bbox");
        }
    }, [modalIsOpen]);

    const handleDatasetNameChange = useCallback(
        (e) => {
            if (newDatasetNameErr !== "") {
                setNewDatasetNameErr("");
            }

            setNewDatasetName(e.target.value);
        },
        [newDatasetNameErr]
    );

    const handleMergeBtnClick = useCallback(async () => {
        const formData = new FormData();

        formData.append("merge_dataset", copyDataset.id);
        formData.append("auth", ProjectViewer.data);
        setIsLoading(true);
        await mergeDataset.mutateAsync(
            { token: Token.data.access, formData: formData, datasetId: SelectedDataset.data.id },
            {
                onSuccess: (res) => {
                    refetch();
                    setIsLoading(false);
                },
                onError: (error) => {
                    if (error.response.status === 401) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "권한 오류",
                            text: "데이터셋 병합 권한이 없습니다.",
                            afterFunction: null,
                        });
                        return;
                    } else if (error.response.status === 403) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "권한 오류",
                            text: "데이터셋 병합 권한이 없습니다.",
                            afterFunction: null,
                        });
                    } else {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    }
                    setIsLoading(false);
                },
            }
        );
    }, [Token, selectedType, newDatasetName, newDatasetNameErr, copyDataset]);

    const { refetch } = useQuery(
        ["project", "dataset", params.id],
        async (e) => {
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            return data;
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                if (mergeDataset.isSuccess) {
                    dispatch(updateSelectedDatasetAction(mergeDataset.data.data));
                }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterClose={() => {
                if (mergeDataset.isSuccess) {
                    mergeDataset.reset();
                }
            }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Merge Dataset"
        >
            {mergeDataset.isSuccess ? (
                <>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: successLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="8rem"
                        height="8rem"
                        style={{
                            marginBottom: "0.8rem",
                        }}
                    />
                    <CustomText fs="1.125rem" fw="600">
                        데이터셋 병합 완료
                    </CustomText>

                    <CustomBtn
                        bc="#b2b1b1"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        <CustomText fs="0.9rem" cl="#b2b1b1">
                            확인
                        </CustomText>
                    </CustomBtn>
                </>
            ) : (
                <>
                    <AddDatasetIcon width="2rem" height="2rem" style={{ marginBottom: "0.8rem" }} />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 0.8rem 0">
                        데이터셋 병합
                    </CustomText>
                    <CustomText fs="0.875rem" cl="#d4d4d4" ta="center">
                        {SelectedDataset.data.data_name} 데이터셋에
                        <br />
                        병합할 데이터셋을 선택해주세요.
                    </CustomText>

                    <div style={{ width: "90%", marginBottom: "1.5rem" }}>
                        <MergeDatasetDropDown copyDataset={copyDataset} setCopyDataset={setCopyDataset} />
                    </div>

                    <CustomText fs="0.875rem" cl="#d4d4d4" ta="center">
                        타입이 다른 라벨링 데이터를 선택할 경우
                        <br />
                        라벨링 데이터를 제외한 이미지 데이터만 병합 됩니다.
                    </CustomText>

                    <CustomBtn bc={copyDataset.id !== 0 ? "#e2ff9b" : "#b2b1b1"} onClick={handleMergeBtnClick} disabled={copyDataset.id !== 0 ? false : true} loading={isLoading}>
                        <CustomText fs="0.9rem" cl={copyDataset.id !== 0 ? "#E2FF9B" : "#b2b1b1"}>
                            병합
                        </CustomText>
                    </CustomBtn>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default MergeDatasetModal;
