import React from "react";
import styled from "styled-components";
import { CustomSpan } from "./main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .menu-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5;
        margin-top: 40px;
    }
`;

function Terminology(props) {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;기능 및 용어 안내</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF">
                    기능 및 용어 안내
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    해당 가이드에서는 NewLearn에서 이용할 수 있는 기능과 용어에 대한 안내를 제공합니다.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="menu-section">
                <Link to="/document/terminology-guide?terminology=1">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>회원 권한</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=2">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>프로젝트 생성</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=3">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>데이터셋</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=4">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>데이터 전처리</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=5">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>데이터 라벨링</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=6">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>모델 학습</li>
                </Link>
                <Link to="/document/terminology-guide?terminology=7">
                    <li style={{ color: "#58A6FF", fontSize: "1rem", fontWeight: "normal", cursor: "pointer" }}>오토 라벨링</li>
                </Link>
            </div>
        </Wrap>
    );
}

export default Terminology;
