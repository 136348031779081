import { applyMiddleware, createStore } from "redux";
import reducer from "./reducer";

const initStore = () => {
    const store = createStore(reducer, applyMiddleware());

    return store;
};

export default initStore;
