import React from "react";
import styled from "styled-components";
import { CustomSpan } from "./main";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .menu-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5;
        margin-top: 40px;
    }
`;

function GettingStarted() {
    const navigate = useNavigate();
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;시작하기</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF">
                    시작하기
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    이 튜토리얼은 프로젝트 생성부터 학습 예약까지 NewLearn의 기본적인 사용법을 소개합니다.
                    <br />
                    제공되는 브랜치, 알고리즘 등의 개념은 이해하고 있다고 가정합니다.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                        ❗&nbsp;튜토리얼을 진행하기 위해서는 "트레이닝"&nbsp;<span style={{ color: "#58A6FF" }}>회원권한</span>이 필요합니다.&nbsp; 권한 확인 후 진행해주세요
                    </span>
                    <br /> <br />
                    자세한 개념을 먼저 알고 싶다면 <span style={{ color: "#58A6FF" }}>기능 및 용어 안내</span>를 참고해주세요.
                </CustomSpan>
            </div>
            <div className="menu-section">
                <Link to="/document/getting-started?step=1">
                    <CustomSpan fw="normal" fs="1rem" cl="#58A6FF" pd="0px 0px 5px 0px" style={{ cursor: "pointer" }} onClick={() => navigate({ pathname: `/document/getting-started`, search: `?step=${1}` })}>
                        1.&nbsp; 프로젝트 생성
                    </CustomSpan>
                </Link>
                <Link to="/document/getting-started?step=2">
                    <CustomSpan fw="normal" fs="1rem" cl="#58A6FF" pd="0px 0px 5px 0px" style={{ cursor: "pointer" }} onClick={() => navigate({ pathname: `/document/getting-started`, search: `?step=${2}` })}>
                        2.&nbsp; 학습데이터 업로드
                    </CustomSpan>
                </Link>
                <Link to="/document/getting-started?step=3">
                    <CustomSpan fw="normal" fs="1rem" cl="#58A6FF" pd="0px 0px 5px 0px" style={{ cursor: "pointer" }} onClick={() => navigate({ pathname: `/document/getting-started`, search: `?step=${3}` })}>
                        3.&nbsp; 데이터 전처리
                    </CustomSpan>
                </Link>
                <Link to="/document/getting-started?step=4">
                    <CustomSpan fw="normal" fs="1rem" cl="#58A6FF" pd="0px 0px 5px 0px" style={{ cursor: "pointer" }} onClick={() => navigate({ pathname: `/document/getting-started`, search: `?step=${4}` })}>
                        4.&nbsp; 데이터 라벨링
                    </CustomSpan>
                </Link>
                <Link to="/document/getting-started?step=5">
                    <CustomSpan fw="normal" fs="1rem" cl="#58A6FF" pd="0px 0px 5px 0px" style={{ cursor: "pointer" }} onClick={() => navigate({ pathname: `/document/getting-started`, search: `?step=${5}` })}>
                        5.&nbsp; 학습 예약
                    </CustomSpan>
                </Link>
            </div>
        </Wrap>
    );
}

export default GettingStarted;
