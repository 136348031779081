import React, { useCallback, useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button, Menu, Dropdown } from "antd";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";

import * as successLottie from "../../assets/modal/success-lottie.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useMutation, useQuery } from "react-query";
import AlertModal from "./AlertModal";
import { GetDataCenterCategory, UpdateDataCenterDataSet } from "../../services/datacenter-services";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    margin-top: 2rem;
    background-color: #21272e;
    width: 100%;
    display: flex;
    align-items: center;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;
        text-align: center;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
    border-bottom: 1px dashed #b1b1b1;
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const StyledDropDown = styled(Dropdown)`
    margin-top: 1rem;
    background-color: #21272e;
    border-radius: 5px;
    padding: 0.45rem 1.2rem;
    border: 1px solid #707070;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
    .ant-dropdown {
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        padding: 0.6rem 1.2rem;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

Modal.setAppElement("#root");

function UpdateDataSetName({ modalIsOpen, setIsOpen, dataCenterData }) {
    const { Token } = useSelector((state) => state);
    const params = useParams();
    const dispatch = useDispatch();

    const [firstCategory, setFirstCategory] = useState({ id: "0", name: "" });
    const [secondCategory, setSecondCategory] = useState({ id: "0", name: "" });

    const updateMutation = useMutation(UpdateDataCenterDataSet);
    const [dataSetName, setDataSetName] = useState("");
    const [dataSetNameErr, setDataSetNameErr] = useState("");
    const [firstCategoryErr, setFirstCategoryErr] = useState("");
    const [secondCategoryErr, setSecondCategoryErr] = useState("");

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setDataSetName(dataCenterData?.data_name);

        if (dataCenterData?.category.upper_category === null) {
            setFirstCategory({ id: dataCenterData?.category.id, name: dataCenterData?.category.category_name });
        } else {
            setFirstCategory({ id: dataCenterData?.category.upper_category.id, name: dataCenterData?.category.upper_category.category_name });
            setSecondCategory({ id: dataCenterData?.category.id, name: dataCenterData?.category.category_name });
        }
    }, [dataCenterData]);

    const handleDataSetNameChange = useCallback(
        (e) => {
            if (dataSetNameErr !== "") {
                setDataSetNameErr("");
            }
            setDataSetName(e.target.value);
        },
        [dataSetNameErr]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                handleUpdateBtnClick();
            }
        },
        [dataSetName]
    );

    const { data: dataSetFirstCategoryList } = useQuery(
        ["dataset", "firstCategory"],
        async (e) => {
            const data = await GetDataCenterCategory("0");
            return data;
        },
        {
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: dataSetSecondCategoryList } = useQuery(
        ["dataset", "secondCategory", firstCategory],
        async (e) => {
            const data = await GetDataCenterCategory(firstCategory.id);
            return data;
        },
        {
            enabled: firstCategory.id !== "0",
            staleTime: 60 * 1000, // 1분
            retry: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const firstCategoryList = useMemo(
        () => (
            <StyledMenu>
                {dataSetFirstCategoryList?.data.map((category) => {
                    return (
                        <Menu.Item
                            className="menu-item"
                            onClick={() => {
                                setFirstCategory({ id: category.id, name: category.category_name });
                                setSecondCategory({ id: "0", name: "" });
                                setFirstCategoryErr("");
                                setSecondCategoryErr("");
                            }}
                        >
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {category.category_name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [dataSetFirstCategoryList]
    );

    const secondCategoryList = useMemo(
        () => (
            <StyledMenu>
                {dataSetSecondCategoryList?.data.map((category) => {
                    return (
                        <Menu.Item
                            className="menu-item"
                            onClick={() => {
                                setSecondCategory({ id: category.id, name: category.category_name });
                                setSecondCategoryErr("");
                            }}
                        >
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {category.category_name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [dataSetSecondCategoryList]
    );

    const handleUpdateBtnClick = useCallback(async () => {
        if (firstCategory.name === "") {
            setFirstCategoryErr("대분류 카테고리를 선택해주세요");
            return;
        }

        if ((dataSetSecondCategoryList?.data !== undefined) & (dataSetSecondCategoryList?.data.length !== 0)) {
            if (secondCategory.name === "") {
                setSecondCategoryErr("중분류 카테고리를 선택해주세요");
                return;
            }
        }

        if (dataSetName === "") {
            setDataSetNameErr("아이디를 입력하세요");
            return;
        }

        const formData = new FormData();
        formData.append("update_type", "name");
        formData.append("pre_data_name", dataCenterData?.data_name);
        formData.append("data_name", dataSetName);

        if (dataSetSecondCategoryList?.data.length === 0) {
            formData.append("category_id", firstCategory.id);
        } else {
            formData.append("category_id", secondCategory.id);
        }

        await updateMutation.mutateAsync(
            { token: Token.data.access, dataCenterId: params.id, formData: formData },
            {
                onError: (error) => {
                    if (error.response.status === 409) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "데이터셋 이름 오류",
                            text: "이미 사용중인 데이터셋 명 입니다.",
                            afterFunction: null,
                        });
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, [Token, dataSetName, dataSetNameErr, firstCategory, secondCategory, dataSetSecondCategoryList?.data]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterClose={() => {
                if (updateMutation.isSuccess) {
                    updateMutation.reset();
                }
            }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Member"
        >
            {updateMutation.isSuccess ? (
                <>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: successLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="8rem"
                        height="8rem"
                        style={{
                            marginBottom: "0.8rem",
                        }}
                    />
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        데이터셋 이름 변경 완료
                    </CustomText>

                    <CustomBtn
                        bc="#E2FF9B"
                        onClick={() => {
                            setIsOpen(false);
                            window.location.reload();
                        }}
                    >
                        <CustomText fs="0.9rem" cl="#E2FF9B">
                            확인
                        </CustomText>
                    </CustomBtn>
                </>
            ) : (
                <>
                    <CustomText fs="1.125rem" fw="600" mg="0 0 11px 0">
                        데이터셋 카테고리 및 이름 변경
                    </CustomText>
                    <StyledDropDown overlay={firstCategoryList} placement="bottom" trigger={["click"]} getPopupContainer={(trigger) => trigger.parentNode}>
                        <MenuItem>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
                                <CustomText fs="1.125rem" ta="center" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {firstCategory.name === "" ? <span style={{ color: "#B1B1B1" }}>대분류 선택</span> : firstCategory.name}
                                </CustomText>
                                <DownArrow width="1.125rem" height="1.125rem" />
                            </div>
                        </MenuItem>
                    </StyledDropDown>
                    {firstCategoryErr !== "" && (
                        <CustomText cl="#F8514A" mg="7px 0 0 0">
                            {firstCategoryErr}
                        </CustomText>
                    )}
                    <StyledDropDown overlay={secondCategoryList} placement="bottom" trigger={["click"]} getPopupContainer={(trigger) => trigger.parentNode}>
                        <MenuItem>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
                                <CustomText fs="1.125rem" ta="center" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {(secondCategory.name === "") | (secondCategory.name === undefined) ? <span style={{ color: "#B1B1B1" }}>중분류 선택</span> : secondCategory.name}
                                </CustomText>
                                <DownArrow width="1.125rem" height="1.125rem" />
                            </div>
                        </MenuItem>
                    </StyledDropDown>
                    {secondCategoryErr !== "" && (
                        <CustomText cl="#F8514A" mg="7px 0 0 0">
                            {secondCategoryErr}
                        </CustomText>
                    )}
                    <InputBox>
                        {dataSetName?.length === 0 ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="id" value={dataSetName} onKeyDown={handleKeyDown} onChange={handleDataSetNameChange} maxLength="50" autoFocus autoComplete="off" placeholder="데이터셋 이름" />
                    </InputBox>
                    {dataSetNameErr !== "" && <CustomText cl="#F8514A">{dataSetNameErr}</CustomText>}

                    <CustomBtn bc={dataSetName !== "" ? "#e2ff9b" : "#b2b1b1"} onClick={handleUpdateBtnClick} disabled={dataSetName !== "" ? false : true} loading={updateMutation.isLoading}>
                        <CustomText fs="0.9rem" cl={dataSetName !== "" ? "#E2FF9B" : "#b2b1b1"}>
                            변경
                        </CustomText>
                    </CustomBtn>
                </>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default UpdateDataSetName;
