import React, { useCallback } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as HistoryNodataIcon } from "../../../assets/user-dashboard/history-nodata-icon.svg";
import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import TrainingResult from "../../project/validation/TrainingResult";
import { updateSelectedTrainModelAction } from "../../../store/reducer/SelectedTrainModel";
import { deleteSelectedTrainModelAction } from "../../../store/reducer/SelectedTrainModel";
import { useSelector, useDispatch } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    height: 21.2rem;

    /* margin-bottom: 4rem; */

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        /* margin-bottom: 5rem; */
    }
`;

const DateWrapper = styled.div`
    display: flex;
    flex-direction: row;

    gap: 0.7rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

const YearWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    @media screen and (max-width: 1024px) {
        flex-direction: row;
        overflow-x: auto;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const YearItem = styled.div`
    background-color: #21272e;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.3rem 2.5rem;
    border: 1px solid #21272e;

    p {
        color: #9b9b9b;
    }
    ${(props) =>
        props.isclicked === "true" &&
        css`
            border-color: #707070;

            p {
                color: #fff;
            }
        `}
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    @media screen and (max-width: 1024px) {
        flex-direction: row;
        overflow-x: auto;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const MonthItem = styled.div`
    background-color: #21272e;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.3rem 1.5rem;
    border: 1px solid #21272e;
    flex-shrink: 0;
    p {
        color: #9b9b9b;
    }

    ${(props) =>
        props.isclicked === "true" &&
        css`
            border-color: #707070;

            p {
                color: #fff;
            }
        `}
`;

const ModelWrapper = styled(ScrollContainer)`
    flex: 1;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const ModelItem = styled.div`
    padding: 0.8rem 10px 0.8rem 0;
    border-bottom: 1px solid #707070;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .model-contents {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .model-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
    }

    .model-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
    }

    .model-detail .training-parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .model-detail .training-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        .model-contents {
            flex-direction: column;
        }

        .model-name {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .model-detail {
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .model-detail .training-parameter {
            justify-content: space-between;
            width: 100%;
        }

        .model-detail .training-time {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

const ValidationBtn = styled(Button)`
    background-color: #707070 !important;
    border-radius: 2px;
    align-self: stretch;
    height: auto;
    padding: 0.2rem 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #70726e !important;

    ${(props) =>
        props.isclicked === "true" &&
        css`
            border-color: #fff1ff !important;
            background-color: #fff !important;
            p {
                color: #000;
            }
        `}
`;

const NoHistoryWrapper = styled.div`
    background-color: #21272e;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 7rem 0;
`;

const GraphSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 260px;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow-y: auto;
    padding: 40px;
    gap: 0.4rem;
    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(196, 196, 196, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }
    @media screen and (max-width: 1024px) {
        max-height: 230px;
    }
`;

function TrainHistoryMain({ yearList, monthList, selectedYear, setSelectedYear, selectedMonth, setSelectedMonth, historyData, isLoading }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { SelectedTrainModel } = useSelector((state) => state);
    const handleModelNameClick = useCallback(
        (model) => {
            if (SelectedTrainModel.data.id === model.id) {
                dispatch(deleteSelectedTrainModelAction());
                return;
            }
            dispatch(updateSelectedTrainModelAction(model));
        },
        [SelectedTrainModel]
    );

    const handleValidateBtnClick = useCallback((selectedModel) => {
        // TODO: 선택한 모델 redux 추가해서 검증페이지에서 해당 모델 선택되어있도록
        navigate(`/project/${selectedModel.data_set.project}?tab=validation`);
    }, []);

    return (
        <Wrap>
            {/* <DateWrapper>
                <YearWrapper>
                    {yearList.map((year, index) => {
                        return (
                            <YearItem key={index} isclicked={selectedYear === year ? "true" : "false"} onClick={() => setSelectedYear(year)}>
                                <CustomText>{year}</CustomText>
                            </YearItem>
                        );
                    })}
                </YearWrapper>
                <MonthWrapper>
                    {monthList.map((month, index) => {
                        return (
                            <MonthItem key={index} isclicked={selectedMonth === month ? "true" : "false"} onClick={() => setSelectedMonth(month)}>
                                <CustomText>{month}월</CustomText>
                            </MonthItem>
                        );
                    })}
                </MonthWrapper>
            </DateWrapper> */}

            <ModelWrapper hideScrollbars={false}>
                {historyData?.data?.data.length === 0 && (
                    <NoHistoryWrapper>
                        <HistoryNodataIcon width="2rem" height="2rem" />
                        <CustomText>학습 내역이 없습니다.</CustomText>
                    </NoHistoryWrapper>
                )}
                {historyData?.data?.data.map((model, index) => {
                    return (
                        <ModelItem key={model.id} style={index === 0 ? { paddingTop: 0 } : {}}>
                            <div className="model-contents">
                                <div className="model-name">
                                    <CustomText fs="1.25rem" fw="500" style={{ cursor: "pointer" }} onClick={() => handleModelNameClick(model)}>
                                        {model.model_name}
                                    </CustomText>

                                    <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                        <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                            {model.training_algorithm.algorithm_name}
                                        </CustomText>
                                    </CircleText>
                                </div>
                                <div className="model-detail">
                                    <div className="training-parameter">
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                에포크 <span style={{ fontWeight: 600, marginLeft: 5 }}>{model.epoch}</span>
                                            </CustomText>
                                        </div>
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                배치사이즈 <span style={{ fontWeight: 600, marginLeft: 5 }}>{model.batch_size}</span>
                                            </CustomText>
                                        </div>
                                        <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                        <div>
                                            <CustomText fs="0.875rem" fw="400">
                                                학습률 <span style={{ fontWeight: 600, marginLeft: 5 }}>{model.learning_rate}</span>
                                            </CustomText>
                                        </div>
                                    </div>
                                    <div className="training-time">
                                        <CustomText fs="0.875rem" fw="400">
                                            학습소요시간 {model.training_time}
                                        </CustomText>
                                        <ValidationBtn onClick={() => handleValidateBtnClick(model)} isclicked={SelectedTrainModel.data.id === model.id ? "true" : "false"}>
                                            <CustomText fs="0.875rem" fw="400">
                                                검증하기
                                            </CustomText>
                                        </ValidationBtn>
                                    </div>
                                </div>
                            </div>

                            {SelectedTrainModel.data.id === model.id && (
                                <GraphSection>
                                    <TrainingResult />
                                </GraphSection>
                            )}
                        </ModelItem>
                    );
                })}
            </ModelWrapper>
        </Wrap>
    );
}

export default TrainHistoryMain;
