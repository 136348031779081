import React, {useEffect, useState, useCallback} from "react";
import styled, {css} from "styled-components";
import {Avatar, Button, Pagination} from "antd";
import queryString from "query-string";

import {CustomText} from "../../../styles/theme/CustomStyle";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {GetIssue} from "../../../services/board-services";
import {useSelector} from "react-redux";
import {ReactComponent as CommentIcon} from "../../../assets/project/comment.svg";
import {ReactComponent as IssueRegistrationIcon} from "../../../assets/project/issueregistration.svg";
import dayjs from "dayjs";
import IssueRegistration from "./IssueRegistration";
import {Colors} from "../../../styles/theme/Colors";
import {UserOutlined} from "@ant-design/icons";
import IssueListSkeleton from "../../../styles/theme/IssueListSkeleton";
import AlertModal from "../../../components/modal/AlertModal";

const TopWrap = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;

    padding: 0 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${props => props.bc} !important;

    @media screen and (max-width: 1024px) {
        padding: 0 1.5rem;
    }
`;

const IssueRegistrationBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    align-self: stretch;
    height: auto;

    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: #b0afaf !important;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const TopIssueRegistrationBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    width: 100%;

    padding: 0.7rem 2rem;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #b0afaf !important;

    margin-bottom: 1.2rem;

    @media screen and (max-width: 1024px) {
        display: flex;
    }
`;

const InputBox = styled.div`
    /* background-color: #21272e; */
    width: 70%;
    display: flex;
    align-items: center;
    gap: 14px;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 10px 1.875rem;
        border-radius: 5px !important;
        border: none;
        color: #fff;
        font-size: 1rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 0.7rem 1.5rem;
        }
    }
`;

const IssueBox = styled.div`
    border-bottom: 1px solid #707070;
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 7px;
    cursor: pointer;

    div {
        display: flex;
        justify-content: space-between;
    }
`;

const CustomPagination = styled(Pagination)`
    .ant-pagination-item-link {
        background-color: #161b22;
        border-color: #161b22;
        color: #c4c4c4;
    }

    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        background-color: #161b22;
        color: #ffffff;
        border-color: #161b22;
    }

    .ant-pagination-item {
        border-color: #161b22;
        background-color: #161b22;

        a {
            color: #c4c4c4;

            :hover {
                color: #ffffff;
            }
        }
    }

    .ant-pagination-item-active a {
        color: #ffffff;
    }
`;

const NoDataWrap = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

function IssueList(page) {
    const {UserInfo, Token} = useSelector(state => state);
    const location = useLocation();
    const navigate = useNavigate();
    const crntType = queryString.parse(location.search).type;
    const [search, setSearch] = useState("");
    const [issues, setIssues] = useState("");
    const [crntPage, setCrntPage] = useState(page.page);
    const params = useParams();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (crntType === "registration") {
            return <IssueRegistration />;
        }
    }, [location]);

    const searchChange = useCallback(
        e => {
            setSearch(e.target.value);
        },
        [search]
    );

    useEffect(() => {
        getIssueData(crntPage);
    }, []);

    const getIssueData = useCallback(
        async page => {
            try {
                if (search === "") {
                    const issueData = await GetIssue(params.id, page, "none");
                    setIssues(issueData.data);
                } else {
                    const issueData = await GetIssue(params.id, page, search);
                    setIssues(issueData.data);
                }
            } catch (err) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        },
        [search]
    );

    return (
        <>
            <TopWrap>
                <TopIssueRegistrationBtn
                    onClick={() => navigate({search: `?tab=issue&type=registration`})}>
                    <IssueRegistrationIcon />
                    <CustomText mg="0 0 0 10px">이슈 등록</CustomText>
                </TopIssueRegistrationBtn>
                <InputBox>
                    <input
                        value={search}
                        onChange={searchChange}
                        placeholder="이슈 검색"
                        autoComplete="off"
                        onKeyPress={e => {
                            e.key === "Enter" && getIssueData(crntPage);
                        }}
                    />
                    <CustomBtn
                        disabled={search === "" ? true : false}
                        bc={search === "" ? "#b1b1b1" : "#E2FF9B"}
                        onClick={() => {
                            getIssueData(crntPage);
                        }}>
                        <CustomText cl={search === "" ? "#b1b1b1" : "#E2FF9B"}>검색</CustomText>
                    </CustomBtn>
                </InputBox>
                {/* <Link to={`${location.pathname}?tab=issue&type=registration`}> */}
                <IssueRegistrationBtn
                    onClick={() => navigate({search: `?tab=issue&type=registration`})}>
                    <IssueRegistrationIcon />
                    <CustomText mg="0 0 0 10px">이슈 등록</CustomText>
                </IssueRegistrationBtn>
                {/* </Link> */}
            </TopWrap>

            <CustomText
                fs="1.125rem"
                fw="600"
                style={{
                    width: "100%",
                    borderBottom: "1px solid #707070",
                    paddingBottom: "10px",
                    marginTop: "0.5rem",
                }}>
                이슈 목록
            </CustomText>
            {issues === "" ? (
                <>
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                    <IssueListSkeleton />
                </>
            ) : issues.count === 0 ? (
                <NoDataWrap>
                    <CommentIcon width="2rem" height="2rem" />
                    <CustomText>등록된 이슈가 없습니다</CustomText>
                </NoDataWrap>
            ) : (
                issues.results.map((issue, index) => {
                    return (
                        <IssueBox
                            key={issue.id}
                            onClick={() => {
                                navigate({search: `?tab=issue&page=${crntPage}&id=${issue.id}`});
                            }}>
                            <div>
                                <CustomText>{issue.title}</CustomText>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <CommentIcon />
                                    <CustomText mg="0 0 0 9px" fw={500}>
                                        {issue.count_comment}
                                    </CustomText>
                                </div>
                            </div>
                            <div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        size={24}
                                        src={
                                            issue.writer.image_path
                                                ? process.env.REACT_APP_END_POINT +
                                                  issue.writer.image_path
                                                : ""
                                        }
                                        icon={<UserOutlined />}
                                        style={{backgroundColor: Colors[issue.writer.id % 10]}}
                                    />
                                    <CustomText fs="0.875rem" mg="0 0 0 9px" cl="#D4D4D4">
                                        {issue.writer.login_id}
                                    </CustomText>
                                </div>
                                <CustomText fs="0.875rem" cl="#D4D4D4">
                                    {dayjs(issue.created_at)
                                        .locale("ko")
                                        .format(`YYYY.MM.DD A h:mm`)}
                                </CustomText>
                            </div>
                        </IssueBox>
                    );
                })
            )}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "3rem 0 10rem 0",
                }}>
                <CustomPagination
                    defaultCurrent={crntPage}
                    current={crntPage}
                    total={issues.count}
                    onChange={page => {
                        setCrntPage(page);
                        getIssueData(page);
                        navigate({
                            search: `?tab=issue&page=${page}`,
                        });
                    }}
                    style={{display: issues?.count === 0 && "none"}}
                />
            </div>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default IssueList;
