import React from "react";
import ReactApexChart from "react-apexcharts";

function TrainTimeGraph({ trainTimeData, dateList, startDay, endDay }) {
    const options = {
        series: [
            {
                name: "학습시간(분)",
                data: trainTimeData,
            },
        ],
        options: {
            chart: {
                width: "100%",
                height: 350,
                type: "line",
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                    },
                },
                fontFamily: "Pretendard",
                foreColor: "#fff",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            colors: ["#E2FF9B"],
            xaxis: {
                categories: dateList,
            },

            legend: {
                show: false,
            },
        },
    };

    return <ReactApexChart options={options.options} series={options.series} type="line" height={350} width={"100%"} />;
}

export default TrainTimeGraph;
