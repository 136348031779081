import axios from "axios";

export const GetTrainSchedule = (page) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/schedule?page=${page}`);
};

export const GetModelTrainingHistory = (token, id, year, month) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/history?id=${id}&year=${year}&month=${month}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetTrainTime = (token, id, start_day, end_day) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/traintime?id=${id}&start_day=${start_day}&end_day=${end_day}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetGpuServer = () => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/get/gpu_server`);
};

export const GetTrainedModel = ({ formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/trained_model/get/trained_model`, formData);
};

export const SetTrain = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/trained_model/train`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetCurrentTrain = () => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/get/current/train`);
};

export const GetTrainState = () => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/project/get/train_state/`);
};

export const GetTrainGraph = ({ formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/trained_model/get/train_result_images/`, formData);
};

export const SetTrainModel = ({ pk, formData }) => {
    return axios.put("https://validation.newlearn.ai" + `/api/trained_model/set/trained_model/${pk}`, formData);
};

export const SetVideo = ({ pk, formData }) => {
    return axios.put("https://validation.newlearn.ai" + `/api/trained_model/set/video/${pk}`, formData);
};

export const SetAutoLabel = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/trained_model/set/auto_label`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateTrainModel = ({ token, pk, formData }) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/trained_model/update/trained_model/${pk}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetTrainedModelGradeApi = (modelId, userId) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/grade/${modelId}/${userId}`);
};

export const UpdateTrainedModelGradeApi = (gradeId, formData) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/trained_model/grade/update/${gradeId}`, formData);
};

export const CreateTrainedModelGradeApi = (formData) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/trained_model/grade/create`, formData);
};

export const GetTrainedModelPage = (page, project_id, dataset_id) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/get/train_model/list/${project_id}/${dataset_id}?page=${page}`);
};

export const GetMyTrainScheduleApi = (token) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/trained_model/get/my/schedule`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteSchedule = ({ token, pk }) => {
    return axios.delete(process.env.REACT_APP_END_POINT + `/api/trained_model/delete/train_schedule/${pk}`, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};
