function numberFormatting(number) {
    const number_int = parseInt(number);

    if (number_int >= 1000000) {
        return (number_int / 1000000.0).toFixed(2) + "M";
    } else {
        if (number_int >= 1000) {
            return (number_int / 1000.0).toFixed(2) + "k";
        } else {
            return number_int;
        }
    }
}

export default numberFormatting;
