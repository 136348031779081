import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";

import Lottie from "react-lottie";
import * as DocumentationData from "../../../assets/project/validation/document.json";
import * as VideoData from "../../../assets/project/validation/video.json";
import * as PresentationData from "../../../assets/project/validation/presentation.json";
import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";

import { ReactComponent as ValidationStartIcon } from "../../../assets/project/validation/validation-start-icon.svg";
import { ReactComponent as VideoIcon } from "../../../assets/project/validation/video.svg";
import { ReactComponent as PresentationIcon } from "../../../assets/project/validation/presentation.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/project/validation/document.svg";

import ModelInfo from "./ModelInfo";
import ValidationList from "./ValidationList";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "antd";
import { ReactComponent as YellowStar } from "../../../assets/project/validation/yellow-star.svg";
import { Colors } from "../../../styles/theme/Colors";
import { Avatar, Menu, Dropdown } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { SetTrainModel } from "../../../services/train-services";
import { useMutation } from "react-query";
import DisplayVideo from "./DisplayVideo";
import AlertModal from "../../modal/AlertModal";
import * as LoadingData from "../../../assets/loading/loaindg-lottie.json";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { FullscreenExitOutlined } from "@ant-design/icons";
import { deleteSelectedTrainModelAction } from "../../../store/reducer/SelectedTrainModel";
import { deleteSelectedVideoAction } from "../../../store/reducer/SelectedVideo";

const MenuSelectSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    min-height: 9.5rem;

    /* height: 2.5rem; */
`;

const ValidationModel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.bc};

    box-sizing: border-box;
    border-radius: 5px;
    width: 30%;
    padding: 30px;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: ${(props) => (props.select === true ? "30px" : "38px")};
    }
`;

const SelectedModel = styled.div`
    background: ${(props) => props.bc};
    width: 30%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 30%;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: 20px;
    }
    .algorithm {
        display: flex;
        margin-top: 5px;
        justify-content: space-between;
    }
    .star {
        display: flex;
    }
    .trainer-time {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .trainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    .time {
        display: flex;
    }
`;

const ValidationVideo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    border-radius: 5px;

    background: ${(props) => props.bc};
    width: 30%;
    padding: 30px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: ${(props) => (props.select === true ? "30px" : "38px")};
    }
`;

const ValidationType = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 5px;
    background: ${(props) => props.bc};
    width: 30%;
    padding: 30px;
    .validation-type {
        display: flex;
    }
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: ${(props) => (props.select === true ? "30px" : "38px")};
    }
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #575757;
    padding-bottom: 0.3rem;
    /* height: 2.5rem; */
`;

const DisplayWrap = styled.div`
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    transition: width 300ms;
    z-index: 99;

    .video-detection-section {
        width: 100%;
        height: 100%;
        /* height: 70%; */
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    position: ${(props) => (props.selected ? "absolute" : "static")};
    top: ${(props) => (props.selected ? "0%" : "none")};
    left: ${(props) => (props.selected ? "0%" : "none")};
    width: 100%;
    height: ${(props) => (props.selected ? "100%" : "500px")};
    padding: 10px 10px 10px 10px;

    .full-screen {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 500px;
        border: none;
    }
`;

const DisplayLoading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #21272e;
    height: 500px;
    margin-bottom: 4rem;
    gap: 1rem;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 500px;
        border: none;
    }
`;

const CustomBtn = styled.div`
    background: #21272e;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: fit-content;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .menu-item {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    cursor: pointer;
`;

const CustomCarousel = styled(Carousel)`
    .item {
        padding: 0 10px;
    }
`;

function ValidationMain(props) {
    const [isMenu, setIsMenu] = useState("model");
    const [carouselSize, setCarouselSize] = useState({
        width: 0,
        height: 0,
    });

    const [validationType, setValidationType] = useState({
        type: 1,
        name: "서버",
    });
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isVideo, setIsVideo] = useState(false);
    const [isModel, setIsModel] = useState(false);

    const crntPage = queryString.parse(location.search).page;
    const [isPlayButton, setIsPlayButton] = useState(false);
    const setTrainModel = useMutation(SetTrainModel);
    const { SelectedTrainModel, SelectedVideo } = useSelector((state) => state);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const menuRef = useRef(null);
    const carouselRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const LotteLoadingnOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const LotteDocumenationOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: DocumentationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const LottePresentationOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: PresentationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const LotteVideoOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: VideoData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const [fullScreenEvent, setFullScreenEvent] = useState(undefined);

    const changeEvent = () => {
        if (window.innerWidth > 1024) {
            if (fullScreenEvent === undefined) {
                setFullScreenEvent(true);
            } else if (fullScreenEvent === true) {
                setFullScreenEvent(false);
            } else {
                setFullScreenEvent(true);
            }
        } else {
            if (window.innerWidth / window.innerHeight > 1) {
                if (fullScreenEvent === undefined) {
                    setFullScreenEvent(true);
                } else if (fullScreenEvent === true) {
                    setFullScreenEvent(false);
                } else {
                    setFullScreenEvent(true);
                }
            }
        }
    };

    useEffect(() => {
        setCarouselSize({
            width: menuRef.current?.clientWidth,
            height: menuRef.current?.clientHeight,
        });
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    // useEffect(() => {
    //     if (crntPage === undefined) {
    //         navigate({
    //             search: `?tab=validation&page=1`,
    //         });
    //     } else {
    //         navigate({
    //             search: `?tab=validation&page=${crntPage}`,
    //         });
    //     }
    // }, [crntPage]);

    const resizeWindow = useCallback(() => {
        setCarouselSize({
            width: menuRef.current?.clientWidth,
            height: menuRef.current?.clientHeight,
        });
    }, []);

    const handleTypeClick = useCallback((e) => {
        if (parseInt(e.key) === 1) {
            setValidationType({
                type: 1,
                name: "서버",
            });
        } else {
            setValidationType({
                type: 2,
                name: "클라이언트",
            });
        }
    }, []);

    const types = useMemo(
        () => (
            <StyledMenu onClick={handleTypeClick}>
                <Menu.Item key={1} className="menu-item">
                    <CustomText fw="400" fs="1rem" cl="#fff" style={{ padding: "10px" }}>
                        서버
                    </CustomText>
                </Menu.Item>
                <Menu.Item key={2} className="menu-item">
                    <CustomText fw="400" fs="1rem" cl="#fff" style={{ padding: "10px" }}>
                        클라이언트
                    </CustomText>
                </Menu.Item>
            </StyledMenu>
        ),
        []
    );

    const setValidModel = useCallback(async (model) => {
        const formData = new FormData();

        formData.append("file_path", model.file_path);
        formData.append("label_data", model.label_data);
        formData.append("cfg_file", model.cfg_file);
        formData.append("training_algorithm", model.training_algorithm.id);
        formData.append("gpu_server", 1);

        await setTrainModel.mutateAsync(
            { pk: model.id, formData: formData },
            {
                onSuccess: (res) => {
                    if ((res.data.is_use === true) === true) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "다른 사용자가 사용 중",
                            text: "다른 사용자가 검증 서버를 사용중입니다.",
                            afterFunction: null,
                        });
                        return;
                    } else {
                        setIsPlayButton(true);
                    }
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "warning",
                        title: "다른 사용자가 사용 중",
                        text: "다른 사용자가 검증 서버를 사용중입니다.",
                        afterFunction: null,
                    });
                    return;
                },
            }
        );
    }, []);

    const setMenu = (menu) => {
        if (menu === "data") {
            if (Object.keys(SelectedTrainModel.data).length !== 0) {
                clickPauseButton(SelectedTrainModel.data);
            }
            setIsPlayButton(false);
        }
        if (menu == "model") {
            if (Object.keys(SelectedTrainModel.data).length !== 0) {
                clickPauseButton(SelectedTrainModel.data);
            }
            setIsPlayButton(false);
        }

        setIsMenu(menu);
    };

    const clickPauseButton = useCallback(async (model) => {
        const formData = new FormData();
        if (validationType.type === 2) {
            setIsPlayButton(false);
            return;
        }
        setIsPlayButton(false);

        dispatch(deleteSelectedTrainModelAction());
        dispatch(deleteSelectedVideoAction());

        formData.append("cancle", 1);

        await setTrainModel.mutateAsync(
            { pk: model.id, formData: formData },
            {
                onSuccess: (res) => {
                    setIsPlayButton(false);
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, []);

    const ClickPlayButton = (model) => {
        if (isVideo === true) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "비디오 로딩 중",
                text: "비디오가 로딩중입니다. 로딩 완료후 시작해주세요 ",
                afterFunction: null,
            });
            return;
        }

        if (isModel === true) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "모델 로딩 중",
                text: "모델 로딩중입니다. 로딩 완료후 시작해주세요 ",
                afterFunction: null,
            });
            return;
        }

        if (Object.keys(SelectedTrainModel.data).length === 0) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "모델 선택",
                text: "모델 센택을 해주세요",
                afterFunction: null,
            });
            return;
        }

        if (Object.keys(SelectedVideo.data).length === 0) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "비디오 선택",
                text: "비디오 센택을 해주세요",
                afterFunction: null,
            });
            return;
        }

        if (validationType.type === 1) {
            setValidModel(model);
        } else {
            if (model.training_algorithm.id === 2 || model.training_algorithm.id === 5 || model.training_algorithm.id === 6 || model.training_algorithm.id === 3) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "클라이언트 검증 오류",
                    text: "YOLACT, MASK R-CNN, YOLOv4, Faster-RCNN는 클라이언트 검증이 불가능 합니다.\n 서버 자원을 이용해주세요 ",
                    afterFunction: null,
                });

                return;
            }
            setIsPlayButton(true);
        }
    };

    const setValidMenu = () => {
        setIsMenu("type");
    };

    return (
        <>
            <MenuSelectSection ref={menuRef}>
                {window.innerWidth > 1024 && (
                    <>
                        {isMenu === "model" ? (
                            <>
                                {isModel === true ? (
                                    <ValidationModel onClick={() => setMenu("model")} bc="#ffffff">
                                        <Lottie options={LotteLoadingnOptions} width="5rem" height="5rem" />
                                    </ValidationModel>
                                ) : (
                                    <ValidationModel onClick={() => setMenu("model")} bc="#ffffff">
                                        <Lottie options={LotteDocumenationOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                        <CustomText fs="1rem" cl="#000000" fw="400">
                                            검증 모델 선택
                                        </CustomText>
                                    </ValidationModel>
                                )}
                            </>
                        ) : (
                            <>
                                {"id" in SelectedTrainModel.data ? (
                                    <SelectedModel onClick={() => setMenu("model")} bc="#21272e">
                                        <CustomText fs="1rem" fw="400" bc="#21272e">
                                            {SelectedTrainModel.data.model_name}
                                        </CustomText>
                                        <div className="algorithm">
                                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                                <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                                    {SelectedTrainModel.data.training_algorithm.algorithm_name}
                                                </CustomText>
                                            </CircleText>
                                            <div className="star">
                                                <YellowStar />
                                                {SelectedTrainModel.data.model_grade_ave.value !== null && SelectedTrainModel.data.model_grade_ave !== undefined ? (
                                                    <CustomText fs="0.875rem" fw="400" style={{ marginLeft: "5px", marginRight: "15px" }}>
                                                        {SelectedTrainModel.data.model_grade_ave.value.toFixed(1)}
                                                    </CustomText>
                                                ) : (
                                                    <CustomText fs="0.875rem" fw="400" style={{ marginLeft: "5px", marginRight: "15px" }}>
                                                        0.0
                                                    </CustomText>
                                                )}
                                            </div>
                                        </div>
                                        <div className="trainer-time">
                                            <div className="trainer">
                                                <Avatar size="default" src={SelectedTrainModel.data.trainer.image_path ? process.env.REACT_APP_END_POINT + SelectedTrainModel.data.trainer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[SelectedTrainModel.data.trainer.id % 10] }} />
                                                <CustomText style={{ width: "80%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>{SelectedTrainModel.data.trainer.login_id}</CustomText>
                                            </div>
                                        </div>
                                    </SelectedModel>
                                ) : (
                                    <ValidationModel onClick={() => setMenu("model")} bc="#21272e">
                                        <DocumentIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "10px" }} />
                                        <CustomText fs="1rem" fw="400">
                                            검증 모델 선택
                                        </CustomText>
                                    </ValidationModel>
                                )}
                            </>
                        )}
                        {isMenu === "data" ? (
                            <>
                                {isVideo === true ? (
                                    <ValidationVideo onClick={() => setMenu("data")} bc="#ffffff">
                                        <Lottie options={LotteLoadingnOptions} width="5rem" height="5rem" />
                                    </ValidationVideo>
                                ) : (
                                    <ValidationVideo onClick={() => setMenu("data")} bc="#ffffff">
                                        <Lottie options={LottePresentationOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                        <CustomText fs="1rem" cl="#000000" fw="400">
                                            검증 데이터 선택
                                        </CustomText>
                                    </ValidationVideo>
                                )}
                            </>
                        ) : (
                            <>
                                {isVideo === true ? (
                                    <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                        <Lottie options={LotteLoadingnOptions} width="5rem" height="5rem" />
                                    </ValidationVideo>
                                ) : (
                                    <>
                                        {"id" in SelectedVideo.data ? (
                                            <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                                <PresentationIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                                <CustomText fs="1rem" fw="400" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                                    {SelectedVideo.data.file_name}
                                                </CustomText>
                                            </ValidationVideo>
                                        ) : (
                                            <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                                <PresentationIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                                <CustomText fs="1rem" fw="400">
                                                    검증 데이터 선택
                                                </CustomText>
                                            </ValidationVideo>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {isMenu === "type" ? (
                            <ValidationType onClick={() => setMenu("type")} bc="#ffffff">
                                <div className="validation-type">
                                    <Lottie options={LotteVideoOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                    <div style={{ padding: "5px", marginLeft: "15px" }}>
                                        <CustomText fs="0.8rem" cl="#838383" fw="400">
                                            검증 방식 선택
                                        </CustomText>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Dropdown overlay={types} placement="bottomCenter" trigger={["click"]}>
                                                <MenuItem>
                                                    <CustomText fw="400" fs="0.8rem" cl="#000" ta="right" style={{ cursor: "pointer" }}>
                                                        {validationType.name}
                                                    </CustomText>
                                                    <DownOutlined style={{ fontSize: "0.7rem", color: "#000" }} />
                                                </MenuItem>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>

                                {isPlayButton === false ? (
                                    <CustomBtn onClick={() => ClickPlayButton(SelectedTrainModel.data)}>
                                        <CustomText fs="0.8rem" cl="#fff" fw="500">
                                            검증 시작
                                        </CustomText>
                                    </CustomBtn>
                                ) : (
                                    <CustomBtn onClick={() => clickPauseButton(SelectedTrainModel.data)}>
                                        <CustomText fs="0.8rem" cl="#fff" fw="500">
                                            중지
                                        </CustomText>
                                    </CustomBtn>
                                )}
                            </ValidationType>
                        ) : (
                            <ValidationType onClick={() => setValidMenu()} bc="#21272e">
                                <VideoIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                <CustomText fs="1rem" fw="400">
                                    검증 방식 선택
                                </CustomText>
                            </ValidationType>
                        )}
                    </>
                )}
                {window.innerWidth < 1024 && (
                    <CustomCarousel ref={carouselRef} style={{ width: carouselSize.width }} centerMode={true} draggable {...settings}>
                        {isMenu === "model" ? (
                            <div className="item">
                                <ValidationModel onClick={() => setMenu("model")} bc="#ffffff" select={true}>
                                    <Lottie options={LotteDocumenationOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                    <CustomText fs="1rem" cl="#000000" fw="400">
                                        검증 모델 선택
                                    </CustomText>
                                </ValidationModel>
                            </div>
                        ) : (
                            <div className="item">
                                {"id" in SelectedTrainModel.data ? (
                                    <SelectedModel onClick={() => setMenu("model")} bc="#21272e" select={false}>
                                        <CustomText fs="1rem" fw="400" bc="#21272e" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                            {SelectedTrainModel.data.model_name}
                                        </CustomText>
                                        <div className="algorithm">
                                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                                <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                                    {SelectedTrainModel.data.training_algorithm.algorithm_name}
                                                </CustomText>
                                            </CircleText>
                                            <div className="star">
                                                <YellowStar />
                                                <CustomText fs="0.875rem" fw="400" style={{ marginLeft: "5px", marginRight: "15px" }}>
                                                    3.5
                                                </CustomText>
                                            </div>
                                        </div>
                                        <div className="trainer-time">
                                            <div className="trainer">
                                                <Avatar size="default" src={SelectedTrainModel.data.trainer.image_path ? process.env.REACT_APP_END_POINT + SelectedTrainModel.data.trainer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[SelectedTrainModel.data.trainer.id % 10] }} />
                                                <CustomText style={{ width: "80%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>{SelectedTrainModel.data.trainer.login_id}</CustomText>
                                            </div>
                                        </div>
                                    </SelectedModel>
                                ) : (
                                    <ValidationModel onClick={() => setMenu("model")} bc="#21272e" select={false}>
                                        <DocumentIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                        <CustomText fs="1rem" fw="400">
                                            검증 모델 선택
                                        </CustomText>
                                    </ValidationModel>
                                )}
                            </div>
                        )}
                        {isMenu === "data" ? (
                            <>
                                {isVideo === true ? (
                                    <ValidationVideo onClick={() => setMenu("data")} bc="#ffffff" select={true}>
                                        <Lottie options={LotteLoadingnOptions} width="6rem" height="6rem" />
                                    </ValidationVideo>
                                ) : (
                                    <ValidationVideo onClick={() => setMenu("data")} bc="#ffffff" select={true}>
                                        <Lottie options={LottePresentationOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                        <CustomText fs="1rem" cl="#000000" fw="400">
                                            검증 데이터 선택
                                        </CustomText>
                                    </ValidationVideo>
                                )}
                            </>
                        ) : (
                            <div className="item">
                                <>
                                    {isVideo === true ? (
                                        <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                            <Lottie options={LotteLoadingnOptions} width="6rem" height="6rem" />
                                        </ValidationVideo>
                                    ) : (
                                        <>
                                            {"id" in SelectedVideo.data ? (
                                                <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                                    <PresentationIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                                    <CustomText fs="1rem" fw="400" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                                        {SelectedVideo.data.file_name}
                                                    </CustomText>
                                                </ValidationVideo>
                                            ) : (
                                                <ValidationVideo onClick={() => setMenu("data")} bc="#21272e" select={false}>
                                                    <PresentationIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                                    <CustomText fs="1rem" fw="400">
                                                        검증 데이터 선택
                                                    </CustomText>
                                                </ValidationVideo>
                                            )}
                                        </>
                                    )}
                                </>
                            </div>
                        )}
                        {isMenu === "type" ? (
                            <div className="item">
                                <ValidationType onClick={() => setMenu("type")} bc="#ffffff" select={true}>
                                    <div className="validation-type">
                                        <Lottie options={LotteVideoOptions} width="3rem" height="3rem" style={{ marginBottom: "10px" }} />
                                        <div style={{ padding: "5px", marginLeft: "15px" }}>
                                            <CustomText fs="0.8rem" cl="#838383" fw="400">
                                                검증 방식 선택
                                            </CustomText>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Dropdown overlay={types} placement="bottomCenter" trigger={["click"]}>
                                                    <MenuItem>
                                                        <CustomText fw="400" fs="0.8rem" cl="#000" ta="right" style={{ cursor: "pointer" }}>
                                                            {validationType.name}
                                                        </CustomText>
                                                        <DownOutlined style={{ fontSize: "0.7rem", color: "#000" }} />
                                                    </MenuItem>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    {isPlayButton === false ? (
                                        <CustomBtn onClick={() => ClickPlayButton(SelectedTrainModel.data)}>
                                            <CustomText fs="0.8rem" cl="#fff" fw="500">
                                                검증 시작
                                            </CustomText>
                                        </CustomBtn>
                                    ) : (
                                        <CustomBtn onClick={() => clickPauseButton(SelectedTrainModel.data)}>
                                            <CustomText fs="0.8rem" cl="#fff" fw="500">
                                                중지
                                            </CustomText>
                                        </CustomBtn>
                                    )}
                                </ValidationType>
                            </div>
                        ) : (
                            <div className="item">
                                <ValidationType onClick={() => setValidMenu()} bc="#21272e">
                                    <VideoIcon style={{ width: "2.5rem", height: "2.5rem", marginBottom: "10px" }} />
                                    <CustomText fs="1rem" fw="400">
                                        검증 방식 선택
                                    </CustomText>
                                </ValidationType>
                            </div>
                        )}
                    </CustomCarousel>
                )}
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </MenuSelectSection>

            <TitleSection>
                {isMenu === "model" && (
                    <CustomText fs="1.125rem" fw="600">
                        모델 목록
                    </CustomText>
                )}
                {isMenu === "data" && (
                    <CustomText fs="1.125rem" fw="600">
                        검증 데이터 업로드 목록
                    </CustomText>
                )}
                {isMenu === "type" && (
                    <>
                        <CustomText fs="1.125rem" fw="600">
                            검증 비디오 출력
                        </CustomText>
                    </>
                )}
            </TitleSection>
            {isMenu === "model" && <ModelInfo carouselRef={carouselRef} setIsMenu={setIsMenu} validationType={validationType} setIsModel={setIsModel} type={"validation"} page={crntPage === undefined ? 1 : crntPage} />}
            {isMenu === "data" && <ValidationList setIsMenu={setIsMenu} carouselRef={carouselRef} setIsVideo={setIsVideo} />}
            {isMenu === "type" && (
                <>
                    {isPlayButton === false ? (
                        <DisplayLoading>
                            <ValidationStartIcon width="3rem" height="3rem" />
                            <CustomText fs="1rem">검증 시작 버튼을 클릭해주세요</CustomText>
                            <CustomBtn style={{ width: "fit-content", padding: "0.5rem 1rem", border: "1px solid #d1d1d1", marginTop: 20 }} onClick={() => ClickPlayButton(SelectedTrainModel.data)}>
                                <CustomText fs="1rem">검증 시작</CustomText>
                            </CustomBtn>
                        </DisplayLoading>
                    ) : (
                        <DisplayWrap className="display_video" selected={fullScreenEvent === true ? true : false}>
                            <div className="full-screen" style={{ display: "flex", justifyContent: "space-between" }}>
                                <CustomText fs="1.1rem" fw="600"></CustomText>
                                <FullscreenExitOutlined style={{ color: "#fff", fontSize: "1rem" }} onClick={() => changeEvent()} />
                            </div>
                            <div className="video-detection-section">
                                <Lottie options={LotteLoadingnOptions} width="10rem" height="10rem" />
                                <DisplayVideo validationType={validationType} isPlayButton={isPlayButton} fullScreenEvent={fullScreenEvent} clickPauseButton={clickPauseButton} data={SelectedTrainModel.data} />
                            </div>
                        </DisplayWrap>
                    )}
                </>
            )}
        </>
    );
}

export default ValidationMain;
