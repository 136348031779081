import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import EmptyLeftSide from "../use-dashboard/EmptyLeftSide";
import SideDataCenterList from "../use-dashboard/SideDataCenterList";
import SideProjectList from "../use-dashboard/SideProjectList";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";

const Wrap = styled(Layout)`
    min-height: 100vh;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const SideContentWrap = styled.div`
    width: 100%;
    padding: 0 2rem;
    background-color: #161b22;
    display: flex;
    flex-direction: row;
    /* align-items: stretch; */
    align-items: stretch;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        padding: 0;
    }
`;

// const StyledContent = styled(Content)`
const StyledContent = styled.div`
    width: 100%;
    max-width: 1500px;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;

    overflow: hidden;

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }
`;

function UserDashboardLayout({ children }) {
    const { UserInfo, Token } = useSelector((state) => state);

    return (
        <Wrap>
            <AppHeader />
            <SideContentWrap>
                {!_.isEmpty(UserInfo.data) && !_.isEmpty(Token.data) ? <SideProjectList /> : <EmptyLeftSide />}
                <StyledContent>{children}</StyledContent>
                {/* <SideDataCenterList /> */}
            </SideContentWrap>
            <AppFooter />
        </Wrap>
    );
}

export default UserDashboardLayout;
