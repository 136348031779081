import React from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled, { css } from "styled-components";

import AppHeader from "./AppHeader";
import ProjectTab from "../project/ProjectTab";
import ProjectSide from "../project/ProjectSide";
import AppFooter from "./AppFooter";
import PreprocessLeftSide from "../project/preprocess/PreprocessLeftSide";
import LabelingLeftSide from "../project/labeling/LabelingLeftSide";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useStore from "../annotator/Store";

const Wrap = styled(Layout)`
    min-height: 100vh;
    background-color: #161b22;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const ContentWrap = styled(Layout)`
    background-color: #161b22;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4rem;
`;

const SideContentWrap = styled.div`
    width: 100%;
    padding: 0 2rem;
    /* background-color: #161b22; */
    display: flex;
    flex-direction: row;
    /* align-items: stretch; */
    align-items: stretch;
    justify-content: center;
    gap: 3rem;

    @media screen and (max-width: 1024px) {
        padding: 0;
    }
`;

// const StyledContent = styled(Content)`
const StyledContent = styled.div`
    width: 100%;
    max-width: 1100px;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;

    overflow: hidden;

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 0px;
    }

    /* min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.3rem;

    width: 100%;

    @media screen and (max-width: 1024px) {
        align-items: center;
        justify-content: flex-start;
        margin-left: 0px;
        padding-top: 0;
    }

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }

    ${(props) =>
        props.tab === "setting"
            ? css`
                  margin: 0 10rem;
                  @media screen and (max-width: 1024px) {
                      margin: 0;
                  }
              `
            : css`
                  margin-left: 4rem;
              `} */
`;

function ProjectDetailLayout({ children, page = "dataset" }) {
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;
    const setIsInputIndex = useStore((state) => state.setIsInputIndex);
    return (
        <Wrap onClick={() => setIsInputIndex(false)}>
            <AppHeader />
            <ProjectTab />
            <SideContentWrap>
                {/* <div style={{ width: "100%", display: "flex", flexDirection: "column", minWidth: 0 }}>
                    <div style={{ display: "flex" }}> */}
                {/* {page === "dataset" && <EmptyLeftSide tab={crntTab} />} */}
                {page === "preprocess" && <PreprocessLeftSide />}
                {page === "labeling" && <LabelingLeftSide />}
                <StyledContent tab={crntTab}>{children}</StyledContent>
                {/* </div>
                </div> */}
                <ProjectSide page={crntTab === "setting" ? "setting" : page} />
            </SideContentWrap>
            <AppFooter />
        </Wrap>
    );
}

export default ProjectDetailLayout;
