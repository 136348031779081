import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }
    padding: 1.4rem 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 90%;
        height: 1.76rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-item-box {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    }

    .info-items {
        border-radius: 5px;
        width: 3.125rem;
        height: 1.414rem;
        background: #9b9b9b;
        margin-top: 3px;
        position: relative;
        overflow: hidden;
    }

    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
`;

function SideProjectSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-info">
                <div className="skeleton-title" />
                <div className="skeleton-item-box">
                    <div className="info-items" />
                    <div className="info-items" />
                    <div className="info-items" />
                </div>
            </div>
        </Wrap>
    );
}

export default SideProjectSkeleton;
