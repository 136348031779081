import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { CustomText } from "../../styles/theme/CustomStyle";
import { GetCompanyInfo, UpdateCompany } from "../../services/account-services";

import { ReactComponent as MemberIcon } from "../../assets/mypage/member.svg";
import { ReactComponent as ProjectIcon } from "../../assets/mypage/project.svg";
import { ReactComponent as WaitingIcon } from "../../assets/mypage/waiting.svg";
import { ReactComponent as NoWaitMemberIcon } from "../../assets/mypage/nowaitmember.svg";
import { ReactComponent as NoTrainIcon } from "../../assets/mypage/notrain.svg";

import TrainTimeGraph from "../../components/mypage/TrainTimeGraph";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { DatePicker, Radio, Button } from "antd";
import { GetTrainTime } from "../../services/train-services";
import { useQuery } from "react-query";
import Avatar from "antd/lib/avatar/avatar";
import { Colors } from "../../styles/theme/Colors";
import { UserOutlined } from "@ant-design/icons";
import CompanyListSkeleton from "../../styles/theme/CompanyListSkeleton";
import CustomSkeleton from "../../styles/theme/CustomSkeleton";
import AlertModal from "../../components/modal/AlertModal";
import ConfirmModal from "../../components/modal/ConfirmModal";
import EditCompanyModal from "../../components/modal/EditCompanyModal";

const { RangePicker } = DatePicker;

const Wrap = styled.div`
    flex: 1;
    min-height: inherit;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        margin-bottom: 10rem;
        width: 60rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
            width: 95%;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0.5rem 0px;
    }
`;

const Section = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 0.5rem;
    margin-top: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CompanyInfoDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-top: 0.5rem;
        gap: 0.3rem;
        .bar {
            display: none;
        }
    }
`;

const SelectSection = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1rem;

    .box {
        background-color: #21272e;
        flex: 1;

        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1.5rem 0;

        @media screen and (max-width: 1024px) {
            flex-direction: row;
            padding: 2rem 0;
            gap: 2.5rem;
            /* justify-content: space-around; */
        }

        div {
            display: flex;
            align-items: center;
            gap: 10px;

            @media screen and (max-width: 1024px) {
                width: 60%;
                justify-content: space-between;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const ListWrap = styled(ScrollContainer)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 3rem;
    margin: 1rem 0;
    height: 22rem;
    max-height: 20rem;
    overflow-y: auto;

    .member {
        display: flex;
        cursor: pointer;
        width: 30%;
    }

    @media screen and (max-width: 1024px) {
        /* gap: 1.125rem; */
        margin: 1rem 0;

        .member {
            justify-content: space-between;
            width: 45%;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const StyledRangePicker = styled(RangePicker)`
    flex: 1;
    background: #21272e !important;
    border-color: #21272e !important;
    box-shadow: none !important;
    border-radius: 5px;

    .ant-picker-separator {
        color: #fff !important;
    }
    .ant-picker-suffix {
        color: #fff !important;
    }
    .ant-picker-clear {
        color: #fff !important;
    }

    .ant-picker-input {
        input {
            /* background-color: #21272e !important; */
            padding: 5px;
            text-align: center;
            color: #fff !important;
        }
    }

    .ant-picker-clear {
        background-color: #161b22;
    }

    .ant-picker-active-bar {
        background: #e2ff9b !important;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const TrainTimeGraphWrap = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 1rem;

    .graph {
        flex: 2.7;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;

        .graph {
            width: 100%;
        }
    }
`;

const NoDataWrap = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

function CompanyInfo(props) {
    const { UserInfo, Token } = useSelector((state) => state);
    const navigate = useNavigate();
    const [company, setCompany] = useState("");
    const [selectDay, setSelectDay] = useState({ start: "", end: "" });
    const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    useEffect(() => {
        GetData();
    }, []);

    const GetData = useCallback(async () => {
        try {
            const data = await GetCompanyInfo(UserInfo.data.id, Token.data.access);
            setCompany(data.data[0]);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const goMemberManagerPage = useCallback(() => {
        navigate({
            search: `?user=${UserInfo.data.login_id}&tab=memberManagement`,
        });
    });

    const ApproveCompany = useCallback(async (approveSelected) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const data = await UpdateCompany(approveSelected.company.id, { is_approve: true, email: approveSelected.email, id: approveSelected.id }, Token.data.access);
            GetData();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회사 가입 승인 완료",
                text: "회사 가입 승인이 완료되었습니다.",
                afterFunction: null,
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회사 가입 승인 오류",
                text: "회사 가입 승인에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const onChangeDate = useCallback((e, value) => {
        setSelectDay({ start: value[0], end: value[1] });
    });

    const {
        data: trainTimeData,
        isFetching: isLoading,
        refetch,
    } = useQuery(
        ["training", "time", selectDay],
        async () => {
            const data = await GetTrainTime(Token.data.access, UserInfo.data.company.id, selectDay.start, selectDay.end);
            return data;
        },
        {
            enabled: !!selectDay.end,
            retry: false,
            refetchOnWindowFocus: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <Wrap>
            <div className="bottomMargin">
                {UserInfo.data.is_admin && (
                    <div>
                        <Section style={{ marginTop: "1.3rem" }}>
                            <CustomText fw={600} fs="1.12rem">
                                기업승인 대기 기업
                            </CustomText>
                            <div style={{ display: "flex", gap: "3px" }}>
                                <CustomText fs="1.12rem" fw={600} cl="#E2FF9B">
                                    {company.waiting_company_count ? company.waiting_company_count : <span style={{ color: "#ffffff" }}>-&nbsp;</span>}
                                </CustomText>
                                <CustomText fs="1.12rem">건</CustomText>
                            </div>
                        </Section>
                        <ListWrap hideScrollbars={false}>
                            {!company ? (
                                <>
                                    <CompanyListSkeleton />
                                    <CompanyListSkeleton />
                                    <CompanyListSkeleton />
                                    <CompanyListSkeleton />
                                    <CompanyListSkeleton />
                                    <CompanyListSkeleton />
                                </>
                            ) : company.waiting_company_count === 0 ? (
                                <NoDataWrap>
                                    <NoWaitMemberIcon />
                                    <CustomText>가입 승인 요청 기업이 없습니다</CustomText>
                                </NoDataWrap>
                            ) : (
                                company.waiting_company.map((member) => {
                                    return (
                                        <div
                                            className="member"
                                            onClick={() => {
                                                setConfirmModalOpen({
                                                    open: true,
                                                    title: "기업 가입 승인",
                                                    text: `${member.name}(${member.company.company_name}) 님의 가입을 승인할까요?`,
                                                    afterFunction: () => {
                                                        ApproveCompany(member);
                                                    },
                                                });
                                            }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: Colors[member.id % 10] }} />

                                                <div style={{ marginLeft: "1.125rem" }}>
                                                    <div style={{ display: "flex", gap: "7px" }}>
                                                        <CustomText fw={500}>{member.name}</CustomText>
                                                        <CustomText>|</CustomText>
                                                        <CustomText>{member.authorization_code === "97" ? "라벨링" : member.authorization_code === "98" ? "스토리지" : "트레이닝"} 회원</CustomText>
                                                    </div>
                                                    <CustomText cl="#B1B1B1" mg="5px 0 0 0" fs="0.875rem">
                                                        {member.phone_number}
                                                    </CustomText>
                                                    <CustomText cl="#B1B1B1" fs="0.875rem">
                                                        {member.email}
                                                    </CustomText>
                                                    <CustomText cl="#B1B1B1" fs="0.875rem">
                                                        {member.company.company_name}
                                                    </CustomText>
                                                    <CustomText cl="#B1B1B1" fs="0.875rem">
                                                        {member.company.company_registration_number}
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </ListWrap>
                    </div>
                )}
                <div>
                    <Section>
                        <CustomText fw={600} fs="1.12rem">
                            기업정보
                        </CustomText>
                        <CustomText
                            cl="#B1B1B1"
                            fs="1.12rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setEditCompanyModalOpen(true);
                            }}
                        >
                            수정
                        </CustomText>
                    </Section>
                    {!company ? (
                        <CustomSkeleton />
                    ) : (
                        <CompanyInfoDiv>
                            <CustomText fw={500} fs="1.3rem">
                                {company.company.company_name}
                            </CustomText>
                            <CustomText className="bar" cl="#707070" fw={700} fs="1.3rem">
                                |
                            </CustomText>
                            <div style={{ display: "flex", gap: "2rem" }}>
                                <CustomText fs="1.3rem">사업자 등록번호</CustomText>
                                <CustomText fs="1.3rem">{company.company.company_registration_number}</CustomText>
                            </div>
                        </CompanyInfoDiv>
                    )}
                    <SelectSection>
                        <div className="box" onClick={goMemberManagerPage} style={{ border: company.waiting_count !== 0 && "1px solid #707070", cursor: "pointer" }}>
                            <WaitingIcon fill={company.waiting_count === 0 ? "#707070" : "#ffffff"} />
                            <div>
                                <CustomText fs="1.125rem" cl={company.waiting_count === 0 ? "#707070" : "#ffffff"}>
                                    가입승인 대기 회원 수
                                </CustomText>
                                <CustomText fw={700} fs="1.125rem" cl="#E2FF9B">
                                    {company.waiting_count === 0 ? <span style={{ color: "#707070" }}>-&nbsp;</span> : company.waiting_count}
                                </CustomText>
                            </div>
                        </div>
                        <div className="box" onClick={goMemberManagerPage} style={{ border: company.member_count !== 0 && "1px solid #707070", cursor: "pointer" }}>
                            <MemberIcon fill={company.member_count === 0 ? "#707070" : "#ffffff"} />
                            <div>
                                <CustomText fs="1.125rem" cl={company.member_count === 0 ? "#707070" : "#ffffff"}>
                                    소속 회원 수
                                </CustomText>
                                <CustomText fw={700} fs="1.125rem" cl="#E2FF9B">
                                    {company.member_count === 0 ? <span style={{ color: "#707070" }}>-&nbsp;</span> : company.member_count}
                                </CustomText>
                            </div>
                        </div>
                        <div className="box" style={{ border: company.project_count !== 0 && "1px solid #707070" }}>
                            <ProjectIcon fill={company.project_count === 0 ? "#707070" : "#ffffff"} />
                            <div>
                                <CustomText fs="1.125rem" cl={company.project_count === 0 ? "#707070" : "#ffffff"}>
                                    소속 프로젝트 수
                                </CustomText>
                                <CustomText fw={700} fs="1.125rem" cl="#E2FF9B">
                                    {company.project_count === 0 ? <span style={{ color: "#707070" }}>-&nbsp;</span> : company.project_count}
                                </CustomText>
                            </div>
                        </div>
                    </SelectSection>
                </div>
                <div>
                    <Section style={{ marginTop: "2.3rem" }}>
                        <CustomText fw={600} fs="1.12rem">
                            학습 이용시간 그래프
                        </CustomText>
                    </Section>
                    <TrainTimeGraphWrap>
                        <StyledRangePicker onChange={onChangeDate} placeholder={["시작 날짜", "종료 날짜"]} />
                        <div className="graph">
                            {trainTimeData?.data.train_time_list === undefined ? (
                                <NoDataWrap style={{ height: 350 }}>
                                    <NoTrainIcon />
                                    <CustomText>학습 이용시간이 존재하지 않습니다</CustomText>
                                </NoDataWrap>
                            ) : (
                                <TrainTimeGraph
                                    trainTimeData={trainTimeData?.data.train_time_list}
                                    dateList={trainTimeData?.data.date_list.map((date) => {
                                        return date.split("T")[0];
                                    })}
                                />
                            )}
                        </div>
                    </TrainTimeGraphWrap>
                </div>
            </div>
            {company !== "" && <EditCompanyModal editCompanyModalOpen={editCompanyModalOpen} setEditCompanyModalOpen={setEditCompanyModalOpen} company={company.company} />}
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default CompanyInfo;
