import React from "react";
import EditPassword from "./PasswordEdit";
import UserInfoEdit from "./UserInfoEdit";
import { useSelector } from "react-redux";
import { CustomText } from "../../styles/theme/CustomStyle";
import styled from "styled-components";
import ProfileEdit from "../../components/mypage/ProfileEdit";

const Wrap = styled.div`
    flex: 1;
    min-height: inherit;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        margin-bottom: 6rem;
        width: 30rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }
`;

const MiddleFirstSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 1.5rem 2rem;
    background-color: #21272e;
    display: flex;

    @media screen and (max-width: 1024px) {
        padding: 0.8rem 2rem;
    }

    .rowDiv {
        display: flex;
    }
`;

const MiddleSecondSection = styled.div`
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 2.5rem 2rem;
    background-color: #21272e;

    @media screen and (max-width: 1024px) {
        padding: 2rem 2rem;
    }

    .infoRowTop {
        display: flex;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .infoRowBottom {
        display: flex;
        margin-top: 3rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            margin-top: 1.5rem;
            gap: 1.5rem;
        }
    }

    .info {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
    }
`;

function MyPageMain({ type = "info" }) {
    const { UserInfo } = useSelector((state) => state);

    return type === "info" ? (
        <Wrap>
            <div className="bottomMargin">
                <MiddleFirstSection>
                    <ProfileEdit />
                </MiddleFirstSection>
                <MiddleSecondSection>
                    <div className="infoRowTop">
                        <div className="info">
                            <CustomText cl="#D4D4D4" fs="1.125rem">
                                연락처
                            </CustomText>
                            <CustomText fs="1.25rem">{UserInfo.data.phone_number}</CustomText>
                        </div>
                        <div className="info">
                            <CustomText cl="#D4D4D4" fs="1.125rem">
                                이메일
                            </CustomText>
                            <CustomText fs="1.25rem">{UserInfo.data.email}</CustomText>
                        </div>
                    </div>
                    <div className="infoRowBottom">
                        <div className="info">
                            <CustomText cl="#D4D4D4" fs="1.125rem">
                                소속
                            </CustomText>
                            <CustomText fs="1.25rem">{UserInfo.data.company?.company_name}</CustomText>
                        </div>
                        <div className="info">
                            <CustomText cl="#D4D4D4" fs="1.125rem">
                                회원권한
                            </CustomText>
                            <CustomText fs="1.25rem">{UserInfo.data.authorization_code === "97" ? "라벨링" : UserInfo.data.authorization_code === "98" ? "스토리지" : "트레이닝"} 회원</CustomText>
                        </div>
                    </div>
                </MiddleSecondSection>
            </div>
        </Wrap>
    ) : type === "passwordChange" ? (
        <EditPassword />
    ) : (
        <UserInfoEdit />
    );
}

export default MyPageMain;
