import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import MypageDetailLayout from "../../components/layout/MypageDetailLayout";
import MyPageMain from ".";
import CompanyInfo from "./CompanyInfo";
import MemberManagement from "./MemberManagement";
import DashBoard from "./DashBoard";
import CategoryManagement from "./CategoryManagement";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        padding-bottom: 10rem;
    }
`;

function MypageDetail(props) {
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;
    const crntType = queryString.parse(location.search).type;

    return (
        <MypageDetailLayout>
            <Wrap>
                {crntTab === "memberInfo" && <MyPageMain type={crntType} />}
                {crntTab === "companyInfo" && <CompanyInfo />}
                {crntTab === "memberManagement" && <MemberManagement />}
                {crntTab === "categoryManagement" && <CategoryManagement />}
                {crntTab === "dashboard" && <DashBoard />}
            </Wrap>
        </MypageDetailLayout>
    );
}

export default MypageDetail;
