import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import { GetTrainSchedule } from "../../services/train-services";
import { CircleText, CustomText } from "../../styles/theme/CustomStyle";
import AlertModal from "../../components/modal/AlertModal";
import Lottie from "react-lottie";
import * as loadingLottie from "../../assets/project/loading.json";

const ScheduleWrapper = styled.div`
    width: 100%;
`;

const ScheduleList = styled(ScrollContainer)`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;

    /* flex-wrap: wrap; */
    overflow-y: auto;
    max-height: 27rem;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const ScheduleItem = styled.div`
    width: 100%;
    border-bottom: 1px solid #707070;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 3rem;

    .reserve-status {
        display: flex;
        flex-direction: column;
        padding-top: 0.2rem;
        gap: 1rem;
        align-items: flex-start;
    }

    .schedule-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.65rem;
    }

    .schedule-info .project-title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .project-public {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            overflow: hidden;

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .schedule-info .dataset-parameter {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .model-name-section {
            flex-direction: column;
            align-items: flex-start;
            span {
                color: #d4d4d4;
                margin-right: 10px;
            }
        }

        .train-parameters {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 1.25rem;

            span {
                font-weight: 500;
                margin-left: 7px;
            }

            div {
                height: 0.875rem;
                border-right: 1px solid #707070;
            }
        }
    }
`;

function ReservedTrain({ totalCount }) {
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [trainScheduleData, setTrainScheduleData] = useState({
        crntPage: 1,
        hasMore: false,
        data: [],
    });

    useEffect(() => {
        setTimeout(() => {
            // refetch();
            getScheduleData(1, true);
        }, 60000);
    }, [totalCount]);

    // const {
    //     data: trainScheduleData,
    //     isFetching: isLoading,
    //     refetch,
    // } = useQuery(
    //     ["trainschedule", page],
    //     async () => {
    //         const data = await GetTrainSchedule(page);
    //         return data;
    //     },
    //     {
    //         retry: false,
    //         refetchOnWindowFocus: false,
    //         onSuccess: (res) => {
    //             setPage(Math.ceil(res.data.count / 10));
    //         },
    //         onError: (err) => {
    //             setAlertModalOpen({
    //                 open: true,
    //                 iconType: "error",
    //                 title: "",
    //                 text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
    //                 afterFunction: null,
    //             });
    //         },
    //     }
    // );

    const getScheduleData = useCallback(async (page, refresh) => {
        if (page === null) {
            return;
        }

        try {
            setIsLoading(true);
            const listRes = await GetTrainSchedule(page);

            setTrainScheduleData((prev) => ({
                ...prev,
                hasMore: listRes.data.next !== null ? true : false,
                crntPage: listRes.data.next !== null ? page + 1 : null,
                data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
            }));
        } catch {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <ScheduleWrapper>
            <ScheduleList>
                {isLoading ? (
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: loadingLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        width="4rem"
                        height="4rem"
                        style={{
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    />
                ) : trainScheduleData.data.length === 0 ? (
                    <CustomText>예약된 학습이 없습니다.</CustomText>
                ) : (
                    trainScheduleData?.data.map((schedule, index) => {
                        return (
                            <ScheduleItem key={schedule.id}>
                                <div className="reserve-status">
                                    <CustomText fs="1rem">대기 {index + 1}</CustomText>
                                    <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                        <CustomText fs="1rem" cl="#b1b1b1">
                                            {dayjs(schedule.created_at).locale("ko").format("YYYY.MM.DD")}
                                        </CustomText>
                                        <CustomText fs="1rem" cl="#b1b1b1">
                                            {dayjs(schedule.created_at).locale("ko").format("A h:mm")}
                                        </CustomText>
                                    </div>
                                </div>
                                <div className="schedule-info">
                                    <div className="project-title-section">
                                        <div className="project-public">
                                            {schedule.data_set.project.is_public ? (
                                                <Link to={`/project/${schedule.data_set.project.id}?tab=dataset`}>
                                                    <PublicIcon fill="white" width="1.5rem" height="1.5rem" />
                                                    <CustomText fs="1.25rem" fw="500" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {schedule.data_set.project.title}
                                                    </CustomText>
                                                </Link>
                                            ) : (
                                                <>
                                                    <PrivateIcon fill="white" width="1rem" height="1rem" />
                                                    <CustomText fs="1.25rem" fw="500" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {schedule.data_set.project.title}
                                                    </CustomText>
                                                </>
                                            )}
                                        </div>
                                        <CircleText backgroundColor="#393939" borderColor="#393939">
                                            <CustomText fs="0.875rem" cl="#d4d4d4">
                                                {schedule.training_algorithm.algorithm_name}
                                            </CustomText>
                                        </CircleText>
                                    </div>
                                    <div className="dataset-parameter">
                                        <div className="model-name-section">
                                            <CustomText>{schedule.model_name}</CustomText>
                                            <CustomText fw="500">
                                                <span>학습 데이터</span>
                                                {schedule.data_set.data_name}
                                            </CustomText>
                                        </div>
                                        <div className="train-parameters">
                                            <CustomText fs="0.875rem">
                                                에포크<span>{schedule.epoch}</span>
                                            </CustomText>
                                            <div />
                                            <CustomText fs="0.875rem">
                                                배치사이즈<span>{schedule.batch_size}</span>
                                            </CustomText>
                                            <div />
                                            <CustomText fs="0.875rem">
                                                학습률<span>{schedule.learning_rate}</span>
                                            </CustomText>
                                        </div>
                                    </div>
                                </div>
                            </ScheduleItem>
                        );
                    })
                )}
            </ScheduleList>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </ScheduleWrapper>
    );
}

export default ReservedTrain;
