import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import { ReactComponent as DownArrow } from "../../../assets/project/down-arrow.svg";

import { CustomText } from "../../../styles/theme/CustomStyle";
import { GetProjectExpanationMember } from "../../../services/project-services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { updateSelectedManagerAction } from "../../../store/reducer/SelectedManager";
import AlertModal from "../../modal/AlertModal";

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 5px;
    padding: 10px 1.5rem;
    border: 1px solid #707070;
    margin-top: 10px;
    /* width: 100%; */
    flex: 1;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    /* width: 100%; */
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function CollaboratorsDropDown(props) {
    const { Token, SelectedManager } = useSelector((state) => state);
    const dispatch = useDispatch();
    const params = useParams();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        refetch();
    }, []);

    const {
        data: collaboratorList,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["project", "collaborators", params.id],
        async (e) => {
            const projectData = await GetProjectExpanationMember(Token.data.access, params.id);
            const data = await GetProjectExpanationMember(Token.data.access, params.id);
            return [projectData.data.manager].concat(data.data.collaborators);
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onSuccess: (res) => {
                // if (_.isEmpty(SelectedManager.data)) {
                dispatch(updateSelectedManagerAction(res[0]));
                // }
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const branchItems = useMemo(
        () => (
            <StyledMenu>
                {collaboratorList?.map((dataset, index) => {
                    return (
                        <Menu.Item key={dataset.id} className="menu-item" onClick={() => dispatch(updateSelectedManagerAction(dataset))}>
                            <MenuItem>
                                <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {dataset.name}
                                </CustomText>
                            </MenuItem>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [collaboratorList]
    );

    return (
        <>
            <StyledDropDown overlay={branchItems} placement="bottom" trigger={["click"]}>
                <MenuItem>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                        <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {SelectedManager.data?.name}
                        </CustomText>
                    </div>
                    <DownArrow width="1.125rem" height="1.125rem" />
                </MenuItem>
            </StyledDropDown>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default CollaboratorsDropDown;
