import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { GetCompany, UpdateUserInfo, UserEmailDupCheck } from "../../services/account-services";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";
import { useQuery } from "react-query";
import Select from "react-select";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    margin-top: 2.5rem;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const InputBox = styled.div`
    background-color: #21272e;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
`;

const EditMember = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

const StyledSelect = styled(Select)`
    width: 90%;
    background-color: #21272e !important;
    font-size: 1.05rem;
    font-family: Pretendard;
    font-weight: 400;
    color: white;

    input {
        color: white !important;
        font-size: 1.05rem !important;
        font-family: Pretendard !important;
        font-weight: 400 !important;
    }

    .css-1okebmr-indicatorSeparator {
        background: none;
    }
`;

function EditMemberModal({ editMemberModalOpen, setEditMemberModalOpen, member }) {
    const { UserInfo, Token } = useSelector((state) => state);
    const [companyList, setCompanyList] = useState([]);
    const [editComplete, setEditComplete] = useState(true);
    const [editUserInfo, setEditUserInfo] = useState({ name: member.name, phone_number: member.phone_number, email: member.email, authorization_code: member.authorization_code, company_id: member.company.id });
    const [emailCheck, setEmailCheck] = useState(false);
    let emailPattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const authorizationList = [
        { value: "97", label: "라벨링" },
        { value: "98", label: "스토리지" },
        { value: "99", label: "트레이닝" },
    ];

    const companySelect = (selectedCompany) => {
        setEditUserInfo((prev) => ({
            ...prev,
            ["company_id"]: selectedCompany.value,
        }));
    };

    const authorizationSelect = (selectedAuthorization) => {
        setEditUserInfo((prev) => ({
            ...prev,
            ["authorization_code"]: selectedAuthorization.value,
        }));
    };

    const selectCustom = {
        control: (base, state) => ({
            ...base,
            color: "white",
            background: "#21272e",
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                border: state.isFocused ? 0 : 0,
            },
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#e2ff9b" : "#fff",
            background: state.isSelected ? "#4C5158" : "#21272e",
            "&:hover": {
                background: state.isFocused ? "#2E3640" : "#21272e",
            },
        }),
        placeholder: (base) => {
            return {
                ...base,
                color: "#fff",
            };
        },
        singleValue: (base, state) => ({
            ...base,
            color: "#fff",
        }),
        menu: (base, state) => ({
            ...base,
            color: "#fff",
            background: "#21272e",
        }),
    };

    useEffect(() => {
        setEditUserInfo({
            name: member.name,
            phone_number: member.phone_number,
            email: member.email,
            authorization_code: member.authorization_code,
            company_id: member.company.id,
        });
    }, [member]);

    const InputsChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "phone_number") {
                setEditUserInfo((prev) => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/g, ""),
                }));
            } else {
                setEditUserInfo((prev) => ({
                    ...prev,
                    [name]: value,
                }));
            }
        },
        [editUserInfo]
    );

    const { data, isLoading, error } = useQuery(
        ["companys"],
        async (e) => {
            const data = await GetCompany();
            return data;
        },
        {
            retry: false,
            onSuccess: (res) => {
                setCompanyList(res.data);
            },
            onError: (err) => {
                if (err.response.statusText === "Not Found") {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    return;
                }
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        if (editUserInfo.phone_number.length === 10) {
            setEditUserInfo((prev) => ({ ...prev, ["phone_number"]: editUserInfo.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") }));
        }
        if (editUserInfo.phone_number.length === 11) {
            setEditUserInfo((prev) => ({ ...prev, ["phone_number"]: editUserInfo.phone_number.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") }));
        }

        if (member.email === editUserInfo.email) {
            setEmailCheck(true);
        } else {
            emailDupCheck();
        }

        if ((editUserInfo.name === "") | (editUserInfo.phone_number === "") | (editUserInfo.email === "")) {
            setEditComplete(false);
        } else if (editUserInfo.phone_number.length < 11) {
            setEditComplete(false);
        } else if (emailPattern.test(editUserInfo.email) === false) {
            setEditComplete(false);
        } else {
            setEditComplete(true);
        }
    }, [editUserInfo]);

    const emailDupCheck = useCallback(async () => {
        try {
            await UserEmailDupCheck(editUserInfo.email);
            setEmailCheck(true);
        } catch (err) {
            setEmailCheck(false);
            setEditComplete(false);
        }
    });

    const editCompleteOnClick = useCallback(async () => {
        try {
            const formData = new FormData();

            formData.append("name", editUserInfo.name);
            formData.append("email", editUserInfo.email);
            formData.append("phone_number", editUserInfo.phone_number);
            formData.append("company_id", editUserInfo.company_id);
            formData.append("authorization_code", editUserInfo.authorization_code);

            const userRes = await UpdateUserInfo(member.id, formData, Token.data.access);
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회원정보 변경 완료",
                text: "회원정보 변경이 완료되었습니다.",
                afterFunction: () => {
                    setEditMemberModalOpen(false);
                    window.location.reload();
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회원정보 변경 오류",
                text: "회원정보 변경에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    return (
        <Modal
            isOpen={editMemberModalOpen}
            onRequestClose={() => {
                setEditMemberModalOpen(false);
            }}
            style={customStyles}
            contentLabel="Alert Modal"
        >
            <CustomText fs="1.125rem" fw="600" mg="0 0 21px 0" ta="center">
                회원 정보 수정
            </CustomText>
            <EditMember>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        이름
                    </CustomText>
                    <InputBox>
                        {editUserInfo.name === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="name" value={editUserInfo.name} onChange={InputsChange} autoComplete="off" />
                    </InputBox>
                </div>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        연락처
                    </CustomText>
                    <InputBox>
                        {(editUserInfo.phone_number === "") | (editUserInfo.phone_number.length < 11) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="phone_number" value={editUserInfo.phone_number} onChange={InputsChange} maxLength="13" autoComplete="off" />
                    </InputBox>
                </div>
                <div>
                    <CustomText cl="#D4D4D4" fs="0.95rem">
                        이메일
                    </CustomText>
                    <InputBox>
                        {(editUserInfo.email === "") | (emailPattern.test(editUserInfo.email) === false) | (emailCheck === false) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                        <input name="email" value={editUserInfo.email} onChange={InputsChange} autoComplete="off" />
                    </InputBox>
                </div>
                {UserInfo.data.is_admin && (
                    <>
                        <div>
                            <CustomText cl="#D4D4D4" fs="0.95rem">
                                기업
                            </CustomText>
                            <InputBox>
                                <CheckIcon className="prefix" />
                                <StyledSelect options={companyList.map((company) => ({ key: company.id, value: company.id, label: company.company_name }))} onChange={companySelect} placeholder={"기업을 선택해주세요"} defaultValue={{ value: member.company.id, label: member.company.company_name }} clearable={false} styles={selectCustom} />
                            </InputBox>
                        </div>
                        <div>
                            <CustomText cl="#D4D4D4" fs="0.95rem">
                                회원권한
                            </CustomText>
                            <InputBox>
                                <CheckIcon className="prefix" />
                                <StyledSelect options={authorizationList} placeholder={"회원권한을 선택해주세요"} onChange={authorizationSelect} clearable={false} defaultValue={{ label: member.authorization_code === "97" ? "라벨링" : member.authorization_code === "98" ? "스토리지" : "트레이닝", value: member.authorization_code }} styles={selectCustom} />
                            </InputBox>
                        </div>
                    </>
                )}
            </EditMember>

            <BtnWrapper>
                <CustomBtn
                    bc={"#B2B1B1"}
                    onClick={() => {
                        setEditMemberModalOpen(false);
                    }}
                >
                    <CustomText fs="0.9rem" cl={"#B2B1B1"}>
                        취소
                    </CustomText>
                </CustomBtn>
                <CustomBtn bc={editComplete ? "#E2FF9B" : "#B1B1B1"} onClick={editCompleteOnClick} disabled={emailCheck | editComplete ? false : true}>
                    <CustomText fs="0.9rem" cl={editComplete ? "#E2FF9B" : "#B1B1B1"}>
                        수정
                    </CustomText>
                </CustomBtn>
            </BtnWrapper>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default EditMemberModal;
