import React from "react";
import styled from "styled-components";
import {Button} from "antd";
import {ArrowRightOutlined, GithubOutlined} from "@ant-design/icons";
import {CustomText} from "../../../styles/theme/CustomStyle";
import {CustomSpan} from "../main";
import {ReactComponent as YOLOv5SVG} from "../../../assets/document/yolov5.svg";
import {Link} from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 110px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    .ant-btn-primary {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function YOLOv5() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{cursor: "pointer"}}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{cursor: "pointer"}}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;YOLOv5</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    YOLOv5
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    YOLOv5는 2020년 6월 9일 Glenn Jocher에 의해 출시 되었습니다.
                    <br />
                    <br />
                    YOLOv5의 아키텍쳐 구조는 YOLOv4와 같이 Backbone, Neck, Head(Dense
                    Prediction)으로 구성된 One-Stage Detector입니다.
                    <br />
                    <br />
                    YOLOv4와 다른점은 Darknet이 아닌 Pytorch 구현이기 때문에 프로덕션에서 사용하기
                    쉬우며 iOS, Android, js환경에서도 사용 가능합니다..
                    <br />
                    <br />
                    Backbone은 CSPDarknet, Neck은 PANet, Head는 Yolo Layer을 사용합니다.
                    <br />
                    <br />
                    NewLearn에서는 YOLOv5를 커스텀으로 훈련할수 있는{" "}
                    <a href="https://github.com/viasofts/newlearn-algorithm" target="_blank">
                        소스코드
                    </a>
                    를 제공 합니다.
                    <br />
                    <br />
                    YOLOv5는 모델 학습할때 이미지 사이즈를 설정 할수 있습니다.
                    <br />
                    <br />
                    이미지 사이지를 크게하면 정확도는 올라가지만 검증 속도가 낮아지며 이미지
                    사이즈를 작게하면 정확도는 낮아지지만 검증속도가 올라갑니다.
                    <br />
                    <br />
                    YOLOv5 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                </CustomSpan>

                <br />
            </div>
            <div className="image-section">
                <YOLOv5SVG
                    style={{width: "60%", height: "auto", borderRadius: 10, cursor: "pointer"}}
                />
            </div>

            <div className="button-section">
                <a href="https://github.com/ultralytics/yolov5" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined
                                style={{color: "#fff", fontSize: "1rem", marginRight: "10px"}}
                            />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/document/algorithm-guide?algorithm=7">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            YOLOv7
                            <ArrowRightOutlined
                                style={{color: "#fff", fontSize: "1rem", marginLeft: "10px"}}
                            />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default YOLOv5;
