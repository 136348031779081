import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import CountUp from "react-countup";
import { Controller, Scene } from "react-scrollmagic";

import { ReactComponent as MOLIT } from "../../assets/main/MOLIT.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as DataCenterIcon } from "../../assets/main/data-center-icon.svg";
import { ReactComponent as SecondTabIcon } from "../../assets/main/second-tab-icon.svg";
import { ReactComponent as SecondTabIconOpacity } from "../../assets/main/second-tab-icon-opacity.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/project/preprocess/right-arrow.svg";
import { ReactComponent as PlusIcon } from "../../assets/project/plus-icon.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";

import { CustomText } from "../../styles/theme/CustomStyle";
import HomeLayout from "../../components/layout/HomeLayout";
import Algorithm from "../../components/main/Algorithm";
import { Link, useNavigate } from "react-router-dom";
import useScrollFadeIn from "../../hooks/useScrollFadeIn";
import { useQuery } from "react-query";
import { GetMainStaticApi } from "../../services/account-services";
import AlertModal from "../../components/modal/AlertModal";
import { Button } from "antd";
import { GetMainNoticeListApi } from "../../services/board-services";
import MainNewDataSkeleton from "../../styles/theme/MainNewDataSkeleton";

const Wrap = styled.div`
    width: 100%;
    min-height: inherit;
    background-color: #161b22;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 3rem 0rem 5rem 0rem;
    }
`;

const Top = styled.div`
    width: 90%;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;

    .main-title {
        width: 90%;
        max-width: 1500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    .main-title .logo-title-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .main-title .center-section {
        position: relative;
        border-radius: 5px;
        border: 1px solid #707070;
        padding: 1.5rem 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .partner-notice-section {
        width: 100%;
        max-width: 1500px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        gap: 1rem;
    }

    .partner-notice-section .partner-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5rem;
    }

    .partner-notice-section .notice-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 0.8rem 0rem 0.8rem 5px;
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        width: 100%;
        max-width: 45rem;
    }

    .partner-notice-section .notice-section .notice-list {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;

        width: 100%;
        max-width: 38rem;
    }

    @media screen and (max-width: 1024px) {
        min-height: 0;
        justify-content: flex-start;

        .empty-section {
            display: none;
        }

        .main-title {
            flex-direction: column;
        }

        .main-title .logo-title-section {
            align-items: center;

            p {
                text-align: center;
                width: 100%;
            }
        }

        .main-title .logo-title-section .title-text {
            p {
                font-size: 2rem;
            }
        }

        .main-title .center-section {
            width: 100vw;
            border-radius: 0;
            padding: 3rem;
            margin-top: 5rem;
        }

        .partner-notice-section {
            flex-direction: column-reverse;
            margin-top: 2rem;
        }

        .partner-notice-section .partner-section {
            margin-top: 2.5rem;
            justify-content: center;
            width: 100%;
        }

        .partner-notice-section .notice-section {
            width: 100%;
            margin-top: 1rem;
            max-width: none;
        }

        .partner-notice-section .notice-section .notice-list {
            max-width: none;
            width: 80%;
        }
    }
`;

const SecondTab = styled.div`
    width: 100%;
    background-color: #eef0f1;
    display: flex;
    justify-content: center;
    padding: 6rem;

    .content {
        width: 90%;
        max-width: 1500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    .second-tab-icon {
        width: 24rem;
        height: 24rem;
    }

    .second-tab-icon-opacity {
        width: 24rem;
        height: 24rem;
        display: none;
    }

    .static-start-section {
        margin-top: 6.5rem;
        display: flex;
        flex-direction: column;
    }

    .static-start-section .static-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
    }

    .static-start-section .static-section .statics {
        div {
            height: 3px;
            width: 5.5rem;
            background-color: #e2ff9b;
        }
    }

    @media screen and (max-width: 1024px) {
        position: relative;
        padding: 6rem 1rem;

        .content {
            position: relative;
            z-index: 99;
            align-items: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .content .title-text {
            width: 100%;
            text-align: center;
        }

        .second-tab-icon {
            display: none;
        }

        .second-tab-icon-opacity {
            display: block;
            position: absolute !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }

        .static-start-section {
            margin-top: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

const CustomBtn = styled(Link)`
    width: fit-content;
    margin-top: 2.5rem;
    background-color: #e2ff9b !important;
    border-radius: 5px;
    height: auto;

    padding: 0.6rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    border-color: #e2ff9b !important;
`;

const TrainDataShare = styled.div`
    width: 90%;
    padding: 230px 0px 200px 0px;
    max-width: 1500px;

    .trainDataSection {
        display: flex;
        margin-top: 200px;
    }

    .topRightSection {
        min-width: 30rem;
        margin: 30px 0px 0px 60px;
    }

    .bottomLeftSection {
        min-width: 30rem;

        margin: 30px 0px 0px 0px;
        padding-right: 60px;
        width: 40%;
    }

    .bottomRightSection {
        width: 60%;
    }

    @media screen and (max-width: 1024px) {
        padding: 150px 0px 130px 0px;

        .trainDataSection {
            display: flex;
            flex-direction: column;
            margin-top: 150px;
        }

        .trainDataSection.reverse {
            flex-direction: column-reverse;
        }

        .topRightSection {
            margin: 0px 0px 40px 0px;
        }

        .bottomLeftSection {
            margin: 0px 0px 40px 0px;
            width: 100%;
        }

        .bottomRightSection {
            width: 100%;
        }
    }
`;
const AlgorithmSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 250px 0px 200px 0px;

    /* .scroll-change-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        left: 50%;
        top: 50%;
        max-height: 100vh;
        max-width: 100vw;
        transform: translate(-50%, -50%);
    } */

    @media screen and (max-width: 1024px) {
        padding: 150px 0px 100px 0px;
    }
`;

const AlgorithmName = styled(ScrollContainer)`
    width: 90%;
    max-width: 1500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10rem;
    margin-top: 200px;

    flex-wrap: nowrap;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        margin-top: 150px;
        justify-content: normal;
        gap: 20px;
    }
`;

// const ValidationVideo = forwardRef( (props, ref) => {
//     return <ReactPlayer ref={ref} {...props} />;
// });

function HomeMain(props) {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState(0);
    const navigate = useNavigate();

    const animatedItem1 = useScrollFadeIn(1);
    const animatedItem2 = useScrollFadeIn(2);
    const animatedItem3 = useScrollFadeIn(3);
    const animatedItem4 = useScrollFadeIn(4);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [mainStatic, setMainStatic] = useState({
        user: 0,
        project: 0,
        media: 0,
        model: 0,
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const { data } = useQuery(
        ["main", "static"],
        async () => {
            const data = GetMainStaticApi();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                setMainStatic(res.data);
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "사이트 정보 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const { data: mainNotice, isLoading } = useQuery(
        ["main", "notice"],
        async () => {
            const data = GetMainNoticeListApi();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            // onSuccess: (res) => {
            //     setMainStatic(res.data);
            // },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "사이트 정보 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <HomeLayout>
            <Wrap>
                <Top>
                    <div className="empty-section" />
                    <div className="main-title">
                        <div className="logo-title-section">
                            <div className="title-text">
                                <CustomText fs="3.5rem" fw="600">
                                    항공영상 공유
                                </CustomText>
                                <CustomText fs="3.5rem" fw="600">
                                    인공지능 학습 플랫폼
                                </CustomText>
                            </div>
                            <Logo width="23rem" height="4rem" />
                            <Link to="/document" style={{ width: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
                                <CustomText fs="1.5rem" fw="500" cl="#E2FF9B">
                                    더 알아보기
                                </CustomText>
                                <RightArrowIcon width="1.3rem" height="1.3rem" stroke="#E2FF9B" />
                            </Link>
                        </div>
                        {/* <Link className="center-section" to="/data-center"> */}
                        <div className="center-section">
                            <DataCenterIcon width="10rem" height="10rem" />
                            <CustomText ta="center" mg="1.7rem 0 1rem 0">
                                오픈된 데이터로 프로젝트를
                                <br />
                                시작해보세요.
                            </CustomText>
                            <div
                                style={{ width: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}
                                onClick={() => {
                                    navigate("data-center");
                                }}
                            >
                                <CustomText fs="1.5rem" fw="500">
                                    데이터 센터
                                </CustomText>
                                <RightArrowIcon width="1.3rem" height="1.3rem" stroke="#fff" />
                            </div>
                        </div>
                    </div>
                    <div className="partner-notice-section">
                        <div className="partner-section">
                            <MOLIT width="11rem" height="3.9rem" />
                            <CustomText fs="1.5rem" fw="600">
                                드론기술개발지원센터
                            </CustomText>
                        </div>
                        <div className="notice-section">
                            <CustomText fs="1.25rem">공지사항</CustomText>
                            <div className="notice-list">
                                {isLoading ? (
                                    <div style={{ width: "100%" }}>
                                        <MainNewDataSkeleton />
                                    </div>
                                ) : mainNotice?.data.length === 0 ? (
                                    <CustomText fs="1.125rem" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        새로운 공지사항이 없습니다
                                    </CustomText>
                                ) : (
                                    <>
                                        <Link key={mainNotice?.data[0].id} to={`/notice/${mainNotice?.data[0].id}`} style={{ width: "95%" }}>
                                            <CustomText fs="1.25rem" fw={600} cl="#E2FF9B" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <span style={{ fontSize: "1.6rem", fontWeight: "1000", marginRight: "0.8rem", color: "#ffffff" }}>·</span>
                                                {mainNotice?.data[0].title}
                                            </CustomText>
                                        </Link>
                                        <ArrowRightCircle stroke="white" width="1.2rem" height="1.2rem" />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Top>

                <SecondTab>
                    <div className="content">
                        <div className="second-tab-main-contents">
                            <CustomText className="title-text" fs="2.5rem" fw="600" cl="#000">
                                언제 어디에서든 학습을
                            </CustomText>
                            <CustomText className="title-text" fs="2.5rem" fw="600" cl="#000" mg="0 0 1.5rem 0">
                                진행해보세요.
                            </CustomText>
                            <CustomText className="sub-title-text" fs="1.25rem" fw="500" cl="#000">
                                프로젝트에 참여해 팀원들과 학습에 필요한 이미지,
                            </CustomText>
                            <CustomText className="sub-title-text" fs="1.25rem" fw="500" cl="#000">
                                라벨링 파일을 공유하고 데이터셋 버전을 나눌 수 있습니다.
                            </CustomText>
                            <div className="static-start-section">
                                <div className="static-section">
                                    <div className="statics">
                                        <CountUp
                                            end={mainStatic.project >= 1000000 ? (mainStatic.project / 1000000.0).toFixed(2) : mainStatic.project >= 1000 ? (mainStatic.project / 1000.0).toFixed(2) : mainStatic.project}
                                            suffix={mainStatic.project >= 1000000 ? " M" : mainStatic.project >= 1000 ? " K" : ""}
                                            decimals={mainStatic.project >= 1000000 ? 2 : mainStatic.project >= 1000 ? 2 : 0}
                                            duration={0.5}
                                            style={{ color: "#000", fontSize: "1.6rem", fontWeight: 600, fontFamily: "Pretendard" }}
                                        />
                                        <div />
                                        <CustomText cl="#000">생성 프로젝트</CustomText>
                                    </div>
                                    <div className="statics">
                                        <CountUp
                                            end={mainStatic.media >= 1000000 ? (mainStatic.media / 1000000.0).toFixed(2) : mainStatic.media >= 1000 ? (mainStatic.media / 1000.0).toFixed(2) : mainStatic.media}
                                            suffix={mainStatic.media >= 1000000 ? " M" : mainStatic.media >= 1000 ? " K" : ""}
                                            decimals={mainStatic.media >= 1000000 ? 2 : mainStatic.media >= 1000 ? 2 : 0}
                                            duration={0.5}
                                            style={{ color: "#000", fontSize: "1.6rem", fontWeight: 600, fontFamily: "Pretendard" }}
                                        />
                                        <div />
                                        <CustomText cl="#000">공개 이미지</CustomText>
                                        {/* <CustomText cl="#000">업로드 데이터</CustomText> */}
                                    </div>
                                    <div className="statics">
                                        <CountUp
                                            end={mainStatic.model >= 1000000 ? (mainStatic.model / 1000000.0).toFixed(2) : mainStatic.model >= 1000 ? (mainStatic.model / 1000.0).toFixed(2) : mainStatic.model}
                                            suffix={mainStatic.model >= 1000000 ? " M" : mainStatic.model >= 1000 ? " K" : ""}
                                            decimals={mainStatic.model >= 1000000 ? 2 : mainStatic.model >= 1000 ? 2 : 0}
                                            duration={0.5}
                                            style={{ color: "#000", fontSize: "1.6rem", fontWeight: 600, fontFamily: "Pretendard" }}
                                        />
                                        <div />
                                        <CustomText cl="#000">학습 완료 모델</CustomText>
                                    </div>
                                </div>

                                <CustomBtn to="/project/list">
                                    <CustomText fw="500" cl="#000">
                                        시작하기
                                    </CustomText>
                                </CustomBtn>
                            </div>
                        </div>
                        <SecondTabIcon className="second-tab-icon" />
                        <SecondTabIconOpacity className="second-tab-icon-opacity" />
                    </div>
                </SecondTab>

                <TrainDataShare>
                    <CustomText fw={700} fs="3.3rem" style={{ whiteSpace: "pre-wrap", lineHeight: "5rem" }}>
                        학습 데이터를 {"\n"}공유하세요
                    </CustomText>
                    <CustomText fs={windowSize.width > 1024 ? "1.4rem" : "1.3rem"} mg="30px 0px 0px 0px" style={{ whiteSpace: "pre-wrap" }}>
                        {windowSize.width > 1024 ? (
                            <>학습에 필요한 데이터셋을 팀원과 공유하세요. {"\n"}팀원들과 함께 데이터를 모아 더욱 정확한 AI 모델을 만들 수 있습니다.</>
                        ) : (
                            <>
                                학습에 필요한 이미지와 데이터셋을 팀원과 공유하세요. {"\n"}팀원들과 함께 데이터를 모아 더욱 정확한 {"\n"}AI 모델을 만들 수 있습니다.
                            </>
                        )}
                    </CustomText>
                    <div className="trainDataSection reverse">
                        <Controller>
                            <Scene triggerElement="#add_team">
                                {(progress, event) => {
                                    if (document.getElementById("add_team_video") !== null) {
                                        if (progress === 0) {
                                            document.getElementById("add_team_video").currentTime = 0;
                                            document.getElementById("add_team_video").pause();
                                        } else {
                                            document.getElementById("add_team_video").play();
                                            document.getElementById("add_team_video").playbackRate = 3.0;
                                        }
                                    }

                                    return (
                                        <video id="add_team_video" width="100%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                                            <source src="videos/main/main_add_team.mp4" />
                                        </video>
                                    );
                                }}
                            </Scene>
                        </Controller>
                        <div id="add_team" className="topRightSection">
                            <CustomText fs="1.7rem" mg="0px 0px 20px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                협업
                            </CustomText>
                            <CustomText fw={600} fs="2rem" cl="#8C8C8C">
                                프로젝트에 팀원을 초대하세요
                            </CustomText>
                            <CustomText fw={600} fs="2rem" style={{ whiteSpace: "pre-wrap" }}>
                                프로젝트의 팀원들과 데이터를 {"\n"}공유할 수 있습니다
                            </CustomText>
                        </div>
                    </div>
                    <div id="upload" className="trainDataSection">
                        <div className="bottomLeftSection">
                            <CustomText fs="1.7rem" mg="0px 0px 20px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                                데이터셋 관리
                            </CustomText>
                            <CustomText fw={600} fs="2rem" cl="#8C8C8C" style={{ whiteSpace: "pre-wrap" }}>
                                데이터셋을 생성하거나 복사하고 {"\n"}이미지를 업로드하세요
                            </CustomText>
                            <CustomText fw={600} fs="2rem" style={{ whiteSpace: "pre-wrap" }}>
                                학습 데이터를 버전을 나누어 {"\n"}관리할 수 있습니다
                            </CustomText>
                        </div>
                        <Controller>
                            <Scene triggerElement="#upload">
                                {(progress, event) => {
                                    if (document.getElementById("upload_video") !== null) {
                                        if (progress === 0) {
                                            document.getElementById("upload_video").currentTime = 0;
                                            document.getElementById("upload_video").pause();
                                        } else {
                                            document.getElementById("upload_video").play();
                                            document.getElementById("upload_video").playbackRate = 3.0;
                                        }
                                    }

                                    return (
                                        <video id="upload_video" width="100%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                                            <source src="videos/main/main_upload.mp4" />
                                        </video>
                                    );
                                }}
                            </Scene>
                        </Controller>
                    </div>
                </TrainDataShare>

                <AlgorithmSection style={{ width: "100%", backgroundColor: "#fff" }}>
                    <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1500px" }}>
                        <CustomText fw={700} fs="3.3rem" cl="#000" ta="center" style={{ whiteSpace: "pre-wrap", lineHeight: "5rem" }}>
                            <span style={{ color: "#8C8C8C" }}>다양한 학습을 위한</span> {"\n"}알고리즘을 제공합니다
                        </CustomText>
                        <div style={{ height: "11px", width: "35rem", background: "linear-gradient(90deg, #2CF2FF 0%, #BCFB6C 69.5%, #FBFF2C 100%)" }} />
                    </div>
                    <AlgorithmName>
                        <CustomText
                            fw={600}
                            fs="2rem"
                            ta="center"
                            cl={selectedAlgorithm === 0 ? "#000" : "#CECECE"}
                            style={{ borderBottom: selectedAlgorithm === 0 && "4px solid #000", padding: "0px 1rem", cursor: "pointer", whiteSpace: "nowrap" }}
                            onClick={() => {
                                setSelectedAlgorithm(0);
                            }}
                        >
                            Object Detection Algorithm
                        </CustomText>
                        <CustomText
                            fw={600}
                            fs="2rem"
                            ta="center"
                            cl={selectedAlgorithm === 1 ? "#000" : "#CECECE"}
                            style={{ borderBottom: selectedAlgorithm === 1 && "4px solid #000", padding: "0px 1rem", cursor: "pointer", whiteSpace: "nowrap" }}
                            onClick={() => {
                                setSelectedAlgorithm(1);
                            }}
                        >
                            Instance Segmentation Algorithm
                        </CustomText>
                    </AlgorithmName>
                    <Algorithm selectedAlgorithm={selectedAlgorithm} windowWidth={windowSize.width} />
                </AlgorithmSection>

                <AlgorithmSection id="preprocess" style={{ width: "80%", maxWidth: "1500px" }} {...animatedItem1}>
                    <CustomText fs="2rem" mg="0px 0px 50px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                        학습데이터 가공
                    </CustomText>
                    <CustomText fw={700} fs="3.3rem" ta="center">
                        회전, 확대, 축소, 자르기, 그레이스케일을 한번에
                    </CustomText>
                    <CustomText fs={windowSize.width > 1024 ? "1.4rem" : "1.3rem"} mg="40px 0px 0px 0px" ta="center" style={{ whiteSpace: "pre-wrap" }}>
                        학습 데이터를 가공하여 다양한 상태의 이미지를 만들어보세요 {"\n"}더욱 많은 학습 데이터를 만들 수 있습니다
                    </CustomText>
                    <Controller>
                        <Scene triggerElement="#preprocess">
                            {(progress, event) => {
                                if (document.getElementById("preprocess-video") !== null) {
                                    if (progress === 0) {
                                        document.getElementById("preprocess-video").currentTime = 0;
                                        document.getElementById("preprocess-video").pause();
                                    } else {
                                        document.getElementById("preprocess-video").play();
                                        document.getElementById("preprocess-video").playbackRate = 3.0;
                                    }
                                }

                                return (
                                    <video id="preprocess-video" width="90%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", marginTop: 100, maxWidth: 1500, overflow: "hidden" }}>
                                        <source src="videos/main/main_preprocessing.mp4" />
                                    </video>
                                );
                            }}
                        </Scene>
                    </Controller>
                </AlgorithmSection>

                <AlgorithmSection id="labeling" style={{ width: "80%", maxWidth: "1500px" }} {...animatedItem2}>
                    <CustomText fs="2rem" mg="0px 0px 50px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                        이미지 라벨링
                    </CustomText>
                    <CustomText fw={700} fs="3.3rem" ta="center">
                        라벨을 생성하고 이미지에 라벨링 작업을 해보세요
                    </CustomText>
                    <CustomText fs={windowSize.width > 1024 ? "1.4rem" : "1.3rem"} mg="40px 0px 0px 0px" ta="center" style={{ whiteSpace: "pre-wrap" }}>
                        AI가 감지할 객체를 학습할 수 있도록 정보를 입력해주세요{"\n"}NewLearn에서 쉽고 빠르게 진행할 수 있습니다
                    </CustomText>
                    <Controller>
                        <Scene triggerElement="#labeling">
                            {(progress, event) => {
                                if (document.getElementById("labeling-video") !== null) {
                                    if (progress === 0) {
                                        document.getElementById("labeling-video").currentTime = 0;
                                        document.getElementById("labeling-video").pause();
                                    } else {
                                        document.getElementById("labeling-video").play();
                                        document.getElementById("labeling-video").playbackRate = 3.0;
                                    }
                                }

                                return (
                                    <video id="labeling-video" width="90%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", marginTop: 100, maxWidth: 1500, overflow: "hidden" }}>
                                        <source src="videos/main/main_labeling.mp4" />
                                    </video>
                                );
                            }}
                        </Scene>
                    </Controller>
                </AlgorithmSection>

                <AlgorithmSection id="train" style={{ width: "80%", maxWidth: "1500px" }} {...animatedItem3}>
                    <CustomText fs="2rem" mg="0px 0px 50px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                        모델 학습
                    </CustomText>
                    <CustomText fw={700} fs="3.3rem" ta="center">
                        빠른 모델학습 진행
                    </CustomText>
                    <CustomText fs={windowSize.width > 1024 ? "1.4rem" : "1.3rem"} mg="40px 0px 0px 0px" ta="center" style={{ whiteSpace: "pre-wrap" }}>
                        Tensor 코어 GPU 기반 5 페타플롭스를 지원하는 장비로{"\n"}더욱 빠르게 학습할 수 있습니다
                    </CustomText>
                    <Controller>
                        <Scene triggerElement="#train">
                            {(progress, event) => {
                                if (document.getElementById("training-video") !== null) {
                                    if (progress === 0) {
                                        document.getElementById("training-video").currentTime = 0;
                                        document.getElementById("training-video").pause();
                                    } else {
                                        document.getElementById("training-video").play();
                                        document.getElementById("training-video").playbackRate = 3.0;
                                    }
                                }

                                return (
                                    <video id="training-video" width="90%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", marginTop: 100, maxWidth: 1500, overflow: "hidden" }}>
                                        <source src="videos/main/main_train.mp4" />
                                    </video>
                                );
                            }}
                        </Scene>
                    </Controller>
                </AlgorithmSection>

                <AlgorithmSection id="validation" style={{ width: "80%", maxWidth: "1500px" }} {...animatedItem4}>
                    <CustomText fs="2rem" mg="0px 0px 50px 0px" fw={600} style={{ background: "linear-gradient(115.61deg, #2CF2FF 0%, #FBFF2C 100%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>
                        모델 검증
                    </CustomText>
                    <CustomText fw={700} fs="3.3rem" ta="center">
                        학습 완료된 모델을 검증기능으로 바로 확인하세요
                    </CustomText>
                    <CustomText fs={windowSize.width > 1024 ? "1.4rem" : "1.3rem"} mg="40px 0px 0px 0px" ta="center" style={{ whiteSpace: "pre-wrap" }}>
                        학습이 완료된 후 결과를 확인하고 싶으신가요?{"\n"}NewLearn의 모델 검증 기능으로 바로 확인해보세요
                    </CustomText>
                    <Controller>
                        <Scene triggerElement="#validation">
                            {(progress, event) => {
                                if (document.getElementById("validation-video") !== null) {
                                    if (progress === 0) {
                                        document.getElementById("validation-video").currentTime = 0;
                                        document.getElementById("validation-video").pause();
                                    } else {
                                        document.getElementById("validation-video").play();
                                        document.getElementById("validation-video").playbackRate = 3.0;
                                    }
                                }

                                return (
                                    <video id="validation-video" width="90%" height="100%" autoPlay muted playsInline style={{ borderRadius: 30, border: "1px solid rgba(255,255,255,0.1)", marginTop: 100, maxWidth: 1500, overflow: "hidden" }}>
                                        <source src="videos/main/main_validation.mp4" />
                                    </video>
                                );
                            }}
                        </Scene>
                    </Controller>
                </AlgorithmSection>
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </HomeLayout>
    );
}

export default HomeMain;
