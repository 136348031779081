import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import axios from "axios";
import TrainResultLineChart from "./TrainResultLineChart";
import {useSelector} from "react-redux";
import _ from "lodash";
import {GetTrainGraph} from "../../../services/train-services";
import {useMutation} from "react-query";
import AlertModal from "../../modal/AlertModal";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    .train-chart {
        text-align: center;
        width: 50%;
        justify-content: center;
        @media screen and (max-width: 768px) {
            width: 90%;
        }
    }
`;

function TrainingResult(props) {
    const {SelectedTrainModel} = useSelector(state => state);
    const [box, setBox] = useState([]);
    const [classification, setClassification] = useState([]);
    const [fasterRCNNBox, setFasterRCNNBox] = useState([]);
    const [fasterRCNNClassification, setFasterRCNNClassification] = useState([]);
    const [fasterRCNNObjectness, setFasterRCNNObjectness] = useState([]);
    const [objectness, setObjectness] = useState([]);
    const [precision, setPrecision] = useState([]);
    const [recall, setRecall] = useState([]);
    const [map, setMap] = useState([]);

    const [total_loss, setTotalLoss] = useState([]);
    const [segmentation_loss, setSegmentationLoss] = useState([]);
    const [mask_loss, setMaskLoss] = useState([]);
    const [normalized_total_loss, setNormalizedTotalLoss] = useState([]);
    const [advanceMap, setAdvanceMap] = useState([]);
    const [valBox, setValBox] = useState([]);
    const [valClassification, setValClassification] = useState([]);
    const [valObjectness, setValObjectness] = useState([]);
    const [regularizationLoss, setRegularizationLoss] = useState([]);

    const getTrainGraph = useMutation(GetTrainGraph);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(() => {
        if (!_.isEmpty(SelectedTrainModel.data) !== undefined) {
            getTrainResultImages(SelectedTrainModel);
        }
    }, [SelectedTrainModel.data]);

    const getTrainResultImages = useCallback(
        async SelectedTrainModel => {
            //setModelPath(props.trainedModelOptions.filter((data) => data.id === value)[0].file_path);

            const formData = new FormData();
            formData.append("trained_model_id", SelectedTrainModel.data.id);
            formData.append("training_algorithm", SelectedTrainModel.data.training_algorithm.id);

            await getTrainGraph.mutateAsync(
                {formData: formData},
                {
                    onSuccess: res => {
                        if (
                            SelectedTrainModel.data.training_algorithm.id === 1 ||
                            SelectedTrainModel.data.training_algorithm.id === 7
                        ) {
                            let box = res.data.Box.split("||");
                            let objectness = res.data.Objectness.split("||");
                            let classification = res.data.Classification.split("||");
                            let precision = res.data.Precision.split("||");
                            let recall = res.data.Recall.split("||");
                            let mAP_v1 = res.data.mAP_v1.split("||");
                            let mAP_v2 = res.data.mAP_v2.split("||");
                            let val_box = res.data.val_Box.split("||");
                            let val_classification = res.data.val_Classification.split("||");
                            let val_objectness = res.data.val_Objectness.split("||");

                            const box_data = [];
                            const objectness_data = [];
                            const classification_data = [];
                            const precision_data = [];
                            const recall_data = [];
                            const map_v1_data = [];
                            const map_v2_data = [];
                            const val_box_data = [];
                            const val_classification_data = [];
                            const val_objectness_data = [];

                            for (let i = 0; i < box.length; i++) {
                                if (box[i] !== "") {
                                    box_data.push({
                                        steps: i + 1,
                                        "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                    });
                                    objectness_data.push({
                                        steps: i + 1,
                                        "Objectness Loss": parseFloat(objectness[i]).toFixed(4),
                                    });
                                    classification_data.push({
                                        steps: i + 1,
                                        "Classification Loss": parseFloat(
                                            classification[i]
                                        ).toFixed(4),
                                    });
                                    precision_data.push({
                                        steps: i + 1,
                                        Precision: parseFloat(precision[i]).toFixed(4),
                                    });
                                    recall_data.push({
                                        steps: i + 1,
                                        Recall: parseFloat(recall[i]).toFixed(4),
                                    });
                                    map_v1_data.push({
                                        steps: i + 1,
                                        mAP: parseFloat(mAP_v1[i]).toFixed(4),
                                    });
                                    map_v2_data.push({
                                        steps: i + 1,
                                        mAPv2: parseFloat(mAP_v2[i]).toFixed(4),
                                    });
                                    val_box_data.push({
                                        steps: i + 1,
                                        "Validation Bounding Box Loss": parseFloat(
                                            val_box[i]
                                        ).toFixed(4),
                                    });
                                    val_classification_data.push({
                                        steps: i + 1,
                                        "Validation Classification Loss": parseFloat(
                                            val_classification[i]
                                        ).toFixed(4),
                                    });
                                    val_objectness_data.push({
                                        steps: i + 1,
                                        "Validation Objectness Loss": parseFloat(
                                            val_objectness[i]
                                        ).toFixed(4),
                                    });
                                }
                            }

                            setBox(box_data);
                            setClassification(classification_data);
                            setObjectness(objectness_data);
                            setPrecision(precision_data);
                            setRecall(recall_data);
                            setValBox(val_box_data);
                            setValClassification(val_classification_data);
                            setValObjectness(val_objectness_data);
                            setMap(map_v1_data);
                            setAdvanceMap(map_v2_data);
                        } else if (SelectedTrainModel.data.training_algorithm.id === 2) {
                            let box = res.data.Box.split("||");
                            let objectness = res.data.Objectness.split("||");
                            let classification = res.data.Classification.split("||");
                            let mask_loss = res.data.mask_loss.split("||");
                            let total_loss = res.data.total_loss.split("||");

                            const box_data = [];
                            const objectness_data = [];
                            const classification_data = [];
                            const mask_loss_data = [];
                            const total_loss_data = [];

                            for (let i = 0; i < box.length; i++) {
                                if (box[i] !== "") {
                                    box_data.push({
                                        steps: i + 1,
                                        "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                    });
                                    objectness_data.push({
                                        steps: i + 1,
                                        "Objectness Loss": parseFloat(objectness[i]).toFixed(4),
                                    });
                                    classification_data.push({
                                        steps: i + 1,
                                        "Classification Loss": parseFloat(
                                            classification[i]
                                        ).toFixed(4),
                                    });
                                    mask_loss_data.push({
                                        steps: i + 1,
                                        "Mask Loss": parseFloat(mask_loss[i]).toFixed(4),
                                    });
                                    total_loss_data.push({
                                        steps: i + 1,
                                        "Total Loss": parseFloat(total_loss[i]).toFixed(4),
                                    });
                                }
                            }

                            setBox(box_data);
                            setClassification(classification_data);
                            setObjectness(objectness_data);
                            setTotalLoss(total_loss_data);
                            setMaskLoss(mask_loss_data);
                        } else if (SelectedTrainModel.data.training_algorithm.id === 3) {
                            let box = res.data.Box.split("||");
                            let objectness = res.data.Objectness.split("||");
                            let classification = res.data.Classification.split("||");
                            let total_loss = res.data.total_loss.split("||");
                            let Normalized_total_loss = res.data.Normalized_total_loss.split("||");
                            let map_v1 = res.data.map_v1.split("||");
                            let map_v2 = res.data.map_v2.split("||");

                            const box_data = [];
                            const objectness_data = [];
                            const classification_data = [];
                            const normalized_total_loss_data = [];
                            const total_loss_data = [];

                            const map_v1_data = [];
                            const map_v2_data = [];

                            if (Normalized_total_loss.length > 1) {
                                for (let i = 0; i < box.length; i++) {
                                    box_data.push({
                                        steps: i + 1,
                                        "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                    });
                                    objectness_data.push({
                                        steps: i + 1,
                                        "Objectness Loss": parseFloat(objectness[i]).toFixed(4),
                                    });
                                    classification_data.push({
                                        steps: i + 1,
                                        "Classification Loss": parseFloat(
                                            classification[i]
                                        ).toFixed(4),
                                    });
                                    normalized_total_loss_data.push({
                                        steps: i + 1,
                                        "Normalized Total Loss": parseFloat(
                                            Normalized_total_loss[i]
                                        ).toFixed(4),
                                    });
                                    total_loss_data.push({
                                        steps: i + 1,
                                        "Total Loss": parseFloat(total_loss[i]).toFixed(4),
                                    });
                                }
                            } else {
                                for (let i = 0; i < box.length; i++) {
                                    if (box[i] !== "") {
                                        box_data.push({
                                            steps: i + 1,
                                            "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                        });
                                        objectness_data.push({
                                            steps: i + 1,
                                            "Objectness Loss": parseFloat(objectness[i]).toFixed(4),
                                        });
                                        classification_data.push({
                                            steps: i + 1,
                                            "Classification Loss": parseFloat(
                                                classification[i]
                                            ).toFixed(4),
                                        });
                                        map_v1_data.push({
                                            steps: i + 1,
                                            "mAP@0.5IOU": parseFloat(map_v1[i]).toFixed(4),
                                        });
                                        map_v2_data.push({
                                            steps: i + 1,
                                            "mAP@0.75IOU": parseFloat(map_v2[i]).toFixed(4),
                                        });
                                        total_loss_data.push({
                                            steps: i + 1,
                                            "Total Loss": parseFloat(total_loss[i]).toFixed(4),
                                        });
                                    }
                                }
                            }

                            setFasterRCNNBox(box_data);
                            setFasterRCNNClassification(classification_data);
                            setFasterRCNNObjectness(objectness_data);
                            setTotalLoss(total_loss_data);
                            setNormalizedTotalLoss(normalized_total_loss_data);
                            setMap(map_v1_data);
                            setAdvanceMap(map_v2_data);
                        } else if (SelectedTrainModel.data.training_algorithm.id === 4) {
                            let box = res.data.Box.split("||");
                            let Regularization_loss = res.data.Regularization_loss.split("||");
                            let classification = res.data.Classification.split("||");
                            let total_loss = res.data.total_loss.split("||");
                            let Normalized_total_loss = res.data.Normalized_total_loss.split("||");
                            let map_v1 = res.data.map_v1.split("||");
                            let map_v2 = res.data.map_v2.split("||");

                            const box_data = [];
                            const regularization_loss_data = [];
                            const classification_data = [];

                            const normalized_total_loss_data = [];
                            const total_loss_data = [];

                            const map_v1_data = [];
                            const map_v2_data = [];

                            if (Normalized_total_loss.length > 1) {
                                for (let i = 0; i < box.length; i++) {
                                    if (box[i] !== "") {
                                        box_data.push({
                                            steps: i + 1,
                                            "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                        });
                                        regularization_loss_data.push({
                                            steps: i + 1,
                                            "Regularization Loss": parseFloat(
                                                Regularization_loss[i]
                                            ).toFixed(4),
                                        });
                                        classification_data.push({
                                            steps: i + 1,
                                            "Classification Loss": parseFloat(
                                                classification[i]
                                            ).toFixed(4),
                                        });
                                        normalized_total_loss_data.push({
                                            steps: i + 1,
                                            "Normalized Total Loss": parseFloat(
                                                Normalized_total_loss[i]
                                            ).toFixed(4),
                                        });
                                        total_loss_data.push({
                                            steps: i + 1,
                                            "Total Loss": parseFloat(total_loss[i]).toFixed(4),
                                        });
                                    }
                                }
                            } else {
                                for (let i = 0; i < box.length; i++) {
                                    if (box[i] !== "") {
                                        box_data.push({
                                            steps: i + 1,
                                            "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                        });
                                        regularization_loss_data.push({
                                            steps: i + 1,
                                            "Regularization Loss": parseFloat(
                                                Regularization_loss[i]
                                            ).toFixed(4),
                                        });
                                        classification_data.push({
                                            steps: i + 1,
                                            "Classification Loss": parseFloat(
                                                classification[i]
                                            ).toFixed(4),
                                        });
                                        map_v1_data.push({
                                            steps: i + 1,
                                            "mAP@0.5IOU": parseFloat(map_v1[i]).toFixed(4),
                                        });
                                        map_v2_data.push({
                                            steps: i + 1,
                                            "mAP@0.75IOU": parseFloat(map_v2[i]).toFixed(4),
                                        });
                                        total_loss_data.push({
                                            steps: i + 1,
                                            "Total Loss": parseFloat(total_loss[i]).toFixed(4),
                                        });
                                    }
                                }
                            }
                            setFasterRCNNBox(box_data);
                            setFasterRCNNClassification(classification_data);
                            setRegularizationLoss(regularization_loss_data);
                            setTotalLoss(total_loss_data);
                            setNormalizedTotalLoss(normalized_total_loss_data);
                            setMap(map_v1_data);
                            setAdvanceMap(map_v2_data);
                        } else if (SelectedTrainModel.data.training_algorithm.id === 5) {
                            let box = res.data.Box.split("||");
                            let objectness = res.data.Objectness.split("||");
                            let classification = res.data.Classification.split("||");
                            let precision = res.data.Precision.split("||");
                            let recall = res.data.Recall.split("||");
                            let mAP_v1 = res.data.mAP_v1.split("||");
                            let mAP_v2 = res.data.mAP_v2.split("||");
                            let val_box = res.data.val_Box.split("||");
                            let val_classification = res.data.val_Classification.split("||");
                            let val_objectness = res.data.val_Objectness.split("||");

                            const box_data = [];
                            const objectness_data = [];
                            const classification_data = [];
                            const precision_data = [];
                            const recall_data = [];
                            const map_v1_data = [];
                            const map_v2_data = [];
                            const val_box_data = [];
                            const val_classification_data = [];
                            const val_objectness_data = [];

                            for (let i = 0; i < box.length; i++) {
                                if (box[i] !== "") {
                                    box_data.push({
                                        steps: i + 1,
                                        "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                    });
                                    objectness_data.push({
                                        steps: i + 1,
                                        "Objectness Loss": parseFloat(objectness[i]).toFixed(4),
                                    });
                                    classification_data.push({
                                        steps: i + 1,
                                        "Classification Loss": parseFloat(
                                            classification[i]
                                        ).toFixed(4),
                                    });
                                    precision_data.push({
                                        steps: i + 1,
                                        Precision: parseFloat(precision[i]).toFixed(4),
                                    });
                                    recall_data.push({
                                        steps: i + 1,
                                        Recall: parseFloat(recall[i]).toFixed(4),
                                    });
                                    map_v1_data.push({
                                        steps: i + 1,
                                        mAP: parseFloat(mAP_v1[i]).toFixed(4),
                                    });
                                    map_v2_data.push({
                                        steps: i + 1,
                                        mAPv2: parseFloat(mAP_v2[i]).toFixed(4),
                                    });
                                    val_box_data.push({
                                        steps: i + 1,
                                        "Validation Bounding Box Loss": parseFloat(
                                            val_box[i]
                                        ).toFixed(4),
                                    });
                                    val_classification_data.push({
                                        steps: i + 1,
                                        "Validation Classification Loss": parseFloat(
                                            val_classification[i]
                                        ).toFixed(4),
                                    });
                                    val_objectness_data.push({
                                        steps: i + 1,
                                        "Validation Objectness Loss": parseFloat(
                                            val_objectness[i]
                                        ).toFixed(4),
                                    });
                                }
                            }

                            setBox(box_data);
                            setClassification(classification_data);
                            setObjectness(objectness_data);
                            setPrecision(precision_data);
                            setRecall(recall_data);
                            setValBox(val_box_data);
                            setValClassification(val_classification_data);
                            setValObjectness(val_objectness_data);
                            setMap(map_v1_data);
                            setAdvanceMap(map_v2_data);
                        } else if (SelectedTrainModel.data.training_algorithm.id === 6) {
                            let box = res.data.Box.split("||");
                            let mask_loss = res.data.mask_loss.split("||");
                            let classification = res.data.Classification.split("||");
                            let segmentation_loss = res.data.segmentation_loss.split("||");

                            let total_loss = res.data.total_Loss.split("||");

                            const box_data = [];
                            const mask_loss_data = [];
                            const classification_data = [];
                            const total_loss_data = [];
                            const segmentation_loss_data = [];

                            for (let i = 0; i < box.length; i++) {
                                if (box[i] !== "") {
                                    box_data.push({
                                        steps: i + 1,
                                        "Bounding Box Loss": parseFloat(box[i]).toFixed(4),
                                    });
                                    segmentation_loss_data.push({
                                        steps: i + 1,
                                        "Segmentation Loss": parseFloat(
                                            segmentation_loss[i]
                                        ).toFixed(4),
                                    });
                                    classification_data.push({
                                        steps: i + 1,
                                        "Classification Loss": parseFloat(
                                            classification[i]
                                        ).toFixed(4),
                                    });
                                    mask_loss_data.push({
                                        steps: i + 1,
                                        "Mask Loss": parseFloat(mask_loss[i]).toFixed(4),
                                    });
                                    total_loss_data.push({
                                        steps: i + 1,
                                        "Total Loss": parseFloat(
                                            Math.log(total_loss[i]).toFixed(4)
                                        ),
                                    });
                                }
                            }

                            setBox(box_data);
                            setClassification(classification_data);
                            setSegmentationLoss(segmentation_loss_data);
                            setTotalLoss(total_loss_data);
                            setMaskLoss(mask_loss_data);
                        }
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        },
        [SelectedTrainModel]
    );

    return (
        <Wrapper>
            {!_.isEmpty(SelectedTrainModel.data) !== undefined && (
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        paddingRight: "20px",
                    }}>
                    {SelectedTrainModel.data.training_algorithm.id === 1 && (
                        <>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={box}
                                    dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={classification}
                                    dataKey={"Classification Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={objectness}
                                    dataKey={"Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={precision}
                                    dataKey={"Precision"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Precision
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={recall}
                                    dataKey={"Recall"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Recall
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valBox}
                                    dataKey={"Validation Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valClassification}
                                    dataKey={
                                        "Validation Classification Loss"
                                    }></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valObjectness}
                                    dataKey={"Validation Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={map}
                                    dataKey={"mAP"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={advanceMap}
                                    dataKey={"mAPv2"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5:0.95
                                </p>
                            </div>
                        </>
                    )}

                    {SelectedTrainModel.data.training_algorithm.id === 2 && (
                        <>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={box}
                                    dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Bounding Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={classification}
                                    dataKey={"Classification Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={objectness}
                                    dataKey={"Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={mask_loss}
                                    dataKey={"Mask Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Mask Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={total_loss}
                                    dataKey={"Total Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Total Loss
                                </p>
                            </div>
                        </>
                    )}
                    {SelectedTrainModel.data.training_algorithm.id === 3 && (
                        <>
                            {map.length > 0 ? (
                                <>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNBox}
                                            dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Bounding Box Regression Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNClassification}
                                            dataKey={"Classification Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Classification Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNObjectness}
                                            dataKey={"Objectness Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Objectness Loss
                                        </p>
                                    </div>

                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={total_loss}
                                            dataKey={"Total Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Total Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={map}
                                            dataKey={"mAP@0.5IOU"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            mAP@0.5IOU
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={advanceMap}
                                            dataKey={"mAP@0.75IOU"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            mAP@0.75IOU
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNBox}
                                            dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Bounding Box Regression Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNClassification}
                                            dataKey={"Classification Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Classification Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNObjectness}
                                            dataKey={"Objectness Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Objectness Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={normalized_total_loss}
                                            dataKey={
                                                "Normalized Total Loss"
                                            }></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Normalized Total Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={total_loss}
                                            dataKey={"Total Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Total Loss
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {SelectedTrainModel.data.training_algorithm.id === 4 && (
                        <>
                            {map.length > 0 ? (
                                <>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNBox}
                                            dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Bounding Box Regression Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNClassification}
                                            dataKey={"Classification Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Classification Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={regularizationLoss}
                                            dataKey={"Regularization Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Regularization Loss
                                        </p>
                                    </div>

                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={total_loss}
                                            dataKey={"Total Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Total Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={map}
                                            dataKey={"mAP@0.5IOU"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            mAP@0.5IOU
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={advanceMap}
                                            dataKey={"mAP@0.75IOU"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            mAP@0.75IOU
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNBox}
                                            dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Bounding Box Regression Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={fasterRCNNClassification}
                                            dataKey={"Classification Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Classification Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={regularizationLoss}
                                            dataKey={"Regularization Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Regularization Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={normalized_total_loss}
                                            dataKey={
                                                "Normalized Total Loss"
                                            }></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Normalized Total Loss
                                        </p>
                                    </div>
                                    <div className="train-chart">
                                        <TrainResultLineChart
                                            selectedAlgorithm={
                                                SelectedTrainModel.data.training_algorithm.id
                                            }
                                            data={total_loss}
                                            dataKey={"Total Loss"}></TrainResultLineChart>
                                        <p
                                            style={{
                                                marginLeft: "50px",
                                                fontWeight: "normal",
                                                color: "#fff",
                                                fontFamily: "Pretendard",
                                            }}>
                                            Total Loss
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {SelectedTrainModel.data.training_algorithm.id === 5 && (
                        <>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={box}
                                    dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={classification}
                                    dataKey={"Classification Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={objectness}
                                    dataKey={"Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={precision}
                                    dataKey={"Precision"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Precision
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={recall}
                                    dataKey={"Recall"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Recall
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valBox}
                                    dataKey={"Validation Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valClassification}
                                    dataKey={
                                        "Validation Classification Loss"
                                    }></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valObjectness}
                                    dataKey={"Validation Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={map}
                                    dataKey={"mAP"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={advanceMap}
                                    dataKey={"mAPv2"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5:0.95
                                </p>
                            </div>
                        </>
                    )}
                    {SelectedTrainModel.data.training_algorithm.id === 6 && (
                        <>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={box}
                                    dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Bounding Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={classification}
                                    dataKey={"Classification Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={segmentation_loss}
                                    dataKey={"Segmentation Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Segmentation Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={mask_loss}
                                    dataKey={"Mask Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Mask Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={total_loss}
                                    dataKey={"Total Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Total Loss
                                </p>
                            </div>
                        </>
                    )}
                    {SelectedTrainModel.data.training_algorithm.id === 7 && (
                        <>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={box}
                                    dataKey={"Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={classification}
                                    dataKey={"Classification Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={objectness}
                                    dataKey={"Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={precision}
                                    dataKey={"Precision"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Precision
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={recall}
                                    dataKey={"Recall"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Recall
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valBox}
                                    dataKey={"Validation Bounding Box Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Box Regression Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valClassification}
                                    dataKey={
                                        "Validation Classification Loss"
                                    }></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Classification Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={valObjectness}
                                    dataKey={"Validation Objectness Loss"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    Val Objectness Loss
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={map}
                                    dataKey={"mAP"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5
                                </p>
                            </div>
                            <div className="train-chart">
                                <TrainResultLineChart
                                    selectedAlgorithm={
                                        SelectedTrainModel.data.training_algorithm.id
                                    }
                                    data={advanceMap}
                                    dataKey={"mAPv2"}></TrainResultLineChart>
                                <p
                                    style={{
                                        marginLeft: "50px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        fontFamily: "Pretendard",
                                    }}>
                                    mAP@0.5:0.95
                                </p>
                            </div>
                        </>
                    )}
                </div>
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrapper>
    );
}

export default TrainingResult;
