import React, {useEffect} from "react";
import {Image, Layer} from "react-konva";
import useImage from "use-image";
import useStore from "./Store";

function BaseImage({
    image_url,
    datasetId,
    annotationData,
    getAnnotationData,
    initStage,
    setRectWidth,
}) {
    const IMAGE_URL = image_url;
    // const [image] = useImage(IMAGE_URL, "Anonymous");
    const [image] = useImage(IMAGE_URL);

    const setImageSize = useStore(state => state.setImageSize);
    const setScale = useStore(state => state.setScale);
    const setSize = useStore(state => state.setSize);
    const width = useStore(state => state.width);
    const height = useStore(state => state.height);

    const setImage = useStore(state => state.setImage);
    const labelOptions = useStore(state => state.labelOptions);

    const {brightness} = useStore();

    useEffect(() => {
        if (!image) {
            return;
        }
        setImage(image);
        const scale = Math.min(width / image.width, height / image.height);
        setScale(scale);

        setImageSize({width: image.width, height: image.height});
        initStage(image);
        const ratio = image.width / image.height;
        setRectWidth(5 * (image.width / width));
        setSize({
            width: width,
            height: width / ratio,
        });
    }, [image, width, height, setScale, setImageSize, setSize]);

    const layerRef = React.useRef(null);

    useEffect(() => {
        const canvas = layerRef.current.getCanvas()._canvas;
        canvas.style.filter = `brightness(${(brightness + 1) * 100}%)`;
    }, [brightness]);

    useEffect(() => {
        if (image_url !== null && image_url !== undefined) {
            getAnnotationData(labelOptions, annotationData);
        }
    }, [image, image_url, datasetId, annotationData]);

    return (
        <Layer ref={layerRef}>
            <Image
                style={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
                image={image}
            />
        </Layer>
    );
}

export default BaseImage;
