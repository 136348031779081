import React from "react";
import styled from "styled-components";
import { CustomText } from "./CustomStyle";
import { Button, Radio } from "antd";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import { ReactComponent as PrivateIcon } from "../../assets/project/private.svg";
import ScrollContainer from "react-indiana-drag-scroll";


const SkeletonWrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }
    /* padding: 1.4rem 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */

    overflow: hidden;
    /* padding: 1rem 0; */

    display: flex;
    flex-direction: column;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 1.1rem; */
        gap:7px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #21272e;
        border-radius: 5px;
        border: 1px solid #707070;
        padding: 11.5px 1.5rem;
        margin-top: 10px;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 100%;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            height: 1.9rem;
        }
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 40%;
        height: 1rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            width: 100%;
    }
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-content {
        border-radius: 5px;
        width: 100%;
        /* height: 5rem; */
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
        min-height: 9rem;

        @media screen and (max-width: 1024px) {
            height: 18.2rem;
        }
    }

    .skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-items {
        border-radius: 5px;
        width: 3.125rem;
        height: 1.414rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }
    
    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-profile {
        border-radius: 100px;
        width: 2rem;
        height: 2rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
        
        @media screen and (max-width: 1024px) {
            width: 2.5rem;
            height: 2.2rem;
        }
        
    }

    .info-profile::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
    `;
    
const Section = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 0.5rem;
    margin-top: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;

    padding: 0.1rem 1.125rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 1rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        /* gap: 1rem; */
    }
`;

const ListWrap = styled(ScrollContainer)`
    /* width: 100%; */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem 7rem;
    margin: 2rem 0 1rem 0;
    height: 7rem;

    .member {
        width: 219px;
        display: flex;
    }

    @media screen and (max-width: 1024px) {
        height: 10rem;
        gap: 1.2rem;
        .member {
            justify-content: space-between;
            width: 100%;
        }
    }
`;

const StyledRadisGroup = styled(Radio.Group)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
    justify-content: center;
    gap: 10rem;
    margin-top: 2rem;

    .ant-radio-wrapper {
        margin: 0;
    }

    .ant-radio-inner {
        background-color: transparent;
        border-color: #b1b1b1;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #707070 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #e2ff9b;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #ffffff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio-checked:after {
        border-color: #707070;
    }

    .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 13px;
    }

    @media screen and (max-width: 1024px) {
        .icon {
            gap: 9px;
        }
    }
`;

function SettingSkeleton() {
    return (
    <SkeletonWrap>
        <Section style={{marginTop:"1.3rem"}}>
            <CustomText fw={600} fs="1.125rem">
                프로젝트 이름
            </CustomText>
            <CustomBtn
                disabled={true}
                bc={"#B1B1B1"}
            >
                <CustomText cl={"#B1B1B1" }>변경</CustomText>
            </CustomBtn>
        </Section>
        <div className="title-section" >
            <div className="skeleton-title" />
        </div>
        <Section >
            <CustomText fw={600} fs="1.125rem">
                프로젝트 설명
            </CustomText>
            <CustomBtn
                disabled={true}
                bc={"#B1B1B1" }
            >
                <CustomText cl={"#B1B1B1"}>변경</CustomText>
            </CustomBtn>
        </Section>
        <div className="title-section" >
            <div className="skeleton-content" />
        </div>
        {/* <Wrap> */}
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        대표 라벨링 데이터 변경
                    </CustomText>
                    <CustomBtn
                        disabled={true}
                        bc={"#B1B1B1"}
                    >
                        <CustomText cl={"#B1B1B1"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <div className="title-section" >
                    <div className="skeleton-title" />
                </div>
            </div>
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        프로젝트 관리자 변경
                    </CustomText>
                    <CustomBtn disabled={true} bc={"#B1B1B1"}>
                        <CustomText cl={"#B1B1B1"}>변경</CustomText>
                    </CustomBtn>
                </Section>
                <div className="title-section" >
                    <div className="skeleton-title" />
                </div>
            </div>
        {/* </Wrap>
        <Wrap> */}
            <div style={{ width: "100%" }}>
                <Section>
                    <CustomText fw={600} fs="1.125rem">
                        팀원
                    </CustomText>
                    <CustomBtn bc="#E2FF9B">
                        <CustomText cl="#E2FF9B">초대</CustomText>
                    </CustomBtn>
                </Section>
                <ListWrap>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                    <div className="member">
                        <div style={{ display: "flex", alignItems: "center", gap:"15px", width:"100%" }}>
                            <div className="info-profile" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
                </ListWrap>
            </div>
            <div style={{ width: "100%", marginBottom: "10rem" }}>
                    <Section>
                        <CustomText fw={600} fs="1.125rem">
                            프로젝트 공개 설정
                        </CustomText>
                        <CustomBtn
                            disabled={true}
                            bc={"#B1B1B1"}
                        >
                            <CustomText cl={"#B1B1B1"}>변경</CustomText>
                        </CustomBtn>
                    </Section>
                    <StyledRadisGroup
                        value={true}
                    >
                        <div className="icon">
                            <PublicIcon fill="#ffffff" />

                            <CustomText>공개</CustomText>
                            <Radio value={true} />
                        </div>
                        <div className="icon">
                            <PrivateIcon fill="#707070" />

                            <CustomText>비공개</CustomText>
                            <Radio value={false} />
                        </div>
                    </StyledRadisGroup>
                </div>
        {/* </Wrap> */}
    </SkeletonWrap>
);
}

export default SettingSkeleton;