import styled from "styled-components";

export const CustomText = styled.p`
    letter-spacing: 0em;

    width: ${(props) => (props.wd ? props.wd : "fit-content")};
    text-align: ${(props) => (props.ta ? props.ta : "left")};

    color: ${(props) => (props.cl ? props.cl : "#fff")};
    font-family: ${(props) => (props.ff ? props.ff : "Pretendard")};
    font-size: ${(props) => (props.fs ? props.fs : "1rem")};
    font-weight: ${(props) => (props.fw ? props.fw : 400)};
    margin: ${(props) => (props.mg ? props.mg : 0)};

    transition: color 1s;
`;

export const CircleText = ({ children, backgroundColor, borderColor }) => {
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "1px 1.1rem", borderWidth: 1, borderStyle: "solid", borderRadius: 50, backgroundColor: backgroundColor, borderColor: borderColor }}>{children}</div>;
};
