import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Lottie from "react-lottie";
import { CustomText, CircleText } from "../../styles/theme/CustomStyle";
import { useQuery } from "react-query";
import { GetCurrentTrain } from "../../services/train-services";
import * as TrainingData from "../../assets/project/model-train/Training.json";

import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import AlertModal from "../../components/modal/AlertModal";

const TrainIconSection = styled.div`
    width: 8.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TrainInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const TrainInfoDetail = styled.div`
    flex: 1;
    flex-direction: column;
    padding-right: 1rem;
    padding-left: 1rem;

    .first-line {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .second-line {
        width: 100%;
    }

    .third-line {
        display: flex;
        flex-direction: row;
        margin-top: 17px;
        width: 100%;
        justify-content: space-between;
    }

    .dataset {
        display: flex;
        flex-direction: row;
    }

    .training-parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
    }
`;

const ProgressWrap = styled.div`
    margin-top: 17px;
    background-color: #303a45;
    width: 100%;

    @media screen and (max-width: 1024px) {
        margin-left: 0px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
`;

const CustomProgress = styled.div`
    background-color: #bfffa2;
    width: ${(props) => props.wd + "%"};
    height: 100%;
    @media screen and (max-width: 1024px) {
        height: 2rem;
    }
`;

function ProgressTrain({ totalCount }) {
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const lottieDefauiltOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: TrainingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            refetch();
        }, 60000);
    }, [totalCount]);

    const {
        data: TrainSchdule,
        isLoading: loading,
        refetch,
    } = useQuery(
        ["project", "train"],
        async (e) => {
            const data = await GetCurrentTrain();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return TrainSchdule && TrainSchdule.data.length === 0 ? (
        <CustomText style={{ padding: "3rem 0", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>진행중인 학습이 없습니다.</CustomText>
    ) : (
        <TrainInfoSection>
            <TrainIconSection>
                <Lottie options={lottieDefauiltOptions} width="8rem" height="6rem" />
            </TrainIconSection>
            <TrainInfoDetail>
                <div className="first-line">
                    <div className="title">
                        {TrainSchdule?.data[0].data_set.project.is_public ? <PublicIcon fill="white" width="1.125rem" height="1.125rem" /> : <PrivateIcon fill="white" width="1.125rem" height="1.125rem" />}

                        <CustomText fs="1rem" fw="500" mg="0 0 0 10px">
                            {TrainSchdule?.data[0].data_set.project.title}
                        </CustomText>
                        <CustomText fs="1rem" fw="500" cl="#707070" mg="0rem 1.5rem">
                            |
                        </CustomText>
                        <CustomText fs="1rem" fw="500" style={{ marginRight: "1rem" }}>
                            {TrainSchdule?.data[0].model_name}
                        </CustomText>
                        <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B" mg="0 0 0 0.875rem">
                            <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                {TrainSchdule?.data[0].training_algorithm.algorithm_name}
                            </CustomText>
                        </CircleText>
                    </div>
                </div>
                <div className="second-line">
                    <ProgressWrap>
                        <CustomProgress wd={(TrainSchdule?.data[0].current_epoch / TrainSchdule?.data[0].epoch) * 100}>
                            {(TrainSchdule?.data[0].current_epoch / TrainSchdule?.data[0].epoch) * 100 === 0 ? (
                                <CustomText fs="0.8rem" fw="500" cl="#fff" style={{ padding: "5px", paddingLeft: "10px" }}>
                                    {(TrainSchdule?.data[0].current_epoch / TrainSchdule?.data[0].epoch) * 100}%
                                </CustomText>
                            ) : (
                                <CustomText fs="0.8rem" fw="500" cl="#000000" style={{ padding: "5px", paddingLeft: "10px" }}>
                                    {Math.floor((TrainSchdule?.data[0].current_epoch / TrainSchdule?.data[0].epoch) * 100)}%
                                </CustomText>
                            )}
                        </CustomProgress>
                    </ProgressWrap>
                </div>
                <div className="third-line">
                    <div className="dataset">
                        <CustomText fs="1.125rem" mg="0 1rem 0 0">
                            학습데이터
                        </CustomText>
                        <CustomText fs="1.125rem" fw={500}>
                            작업 라벨링 데이터
                        </CustomText>
                    </div>

                    <div className="training-parameter">
                        <CustomText fs="0.875rem" fw="400">
                            에포크 <span style={{ fontWeight: 500, marginLeft: 3 }}>{TrainSchdule?.data[0].epoch}</span>
                        </CustomText>
                        <CustomText fs="1rem" fw="500" cl="#707070" mg="0rem 0.7rem">
                            |
                        </CustomText>
                        <CustomText fs="0.875rem" fw="400">
                            배치사이즈 <span style={{ fontWeight: 500, marginLeft: 5 }}>{TrainSchdule?.data[0].batch_size}</span>
                        </CustomText>
                        <CustomText fs="1rem" fw="500" cl="#707070" mg="0rem 0.7rem">
                            |
                        </CustomText>
                        <CustomText fs="0.875rem" fw="400">
                            학습률 <span style={{ fontWeight: 500, marginLeft: 5 }}>{TrainSchdule?.data[0].learning_rate}</span>
                        </CustomText>
                    </div>
                </div>
            </TrainInfoDetail>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </TrainInfoSection>
    );
}

export default ProgressTrain;
