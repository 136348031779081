import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {Header} from "antd/lib/layout/layout";
import {slide as SlideMenu} from "react-burger-menu";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {Avatar, Dropdown, Menu} from "antd";
import {useMutation} from "react-query";
import {UserOutlined} from "@ant-design/icons";

import {ReactComponent as Logo} from "../../assets/logo.svg";
import {ReactComponent as HamburgerIcon} from "../../assets/header/hamburger.svg";
import {ReactComponent as DownArrow} from "../../assets/project/down-arrow.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CustomText} from "../../styles/theme/CustomStyle";
import {deleteUserInfoAction, updateUserInfoAction} from "../../store/reducer/UserInfo";
import {deleteTokenAction} from "../../store/reducer/Token";
import {GetUserInfo, VerifyToken} from "../../services/auth-services";
import {Colors} from "../../styles/theme/Colors";
import AlertModal from "../modal/AlertModal";

const Wrap = styled(Header)`
    background-color: #161b22;
    height: 62px;
    padding: 22px 0px;
    width: 100%;
    /* padding: 22px 24px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LogoSection = styled.div`
    /* width: 200px; */
    margin: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.jc};
`;

const ProfileSection = styled.div`
    margin: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }
`;

const StyledDropDown = styled(Dropdown)`
    background-color: rgba(0, 0, 0, 0);
`;

const DropDownMenu = styled(Menu)`
    width: 200px;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const DesktopContents = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-items {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;

        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 55px;
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const MobileContents = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 20px;

    .logo-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 22px;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const StyledMenu = styled(SlideMenu)`
    position: absolute;
    top: 0px;
    left: 0px;
    background: white;
    overflow: hidden;
    width: 90% !important;
    max-width: 500px;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .bm-item {
        text-align: center;
        /* padding: 1rem; */
        font-size: 1.5rem;
        font-family: Pretendard-Medium;
        cursor: pointer;
        color: #747474;
    }

    .bm-item:hover {
        color: black;
    }
`;

const logoLinkStyle = {display: "flex", flexDirection: "column", alignItems: "center"};

const menuStyle = {
    bmBurgerButton: {
        position: "relative",
        fontSize: "1.5rem",
        color: "white",
    },
    bmCrossButton: {
        height: "24px",
        width: "24px",
    },
    bmCross: {
        background: "#bdc3c7",
    },
    bmMenuWrap: {
        backgroundColor: "#000",

        // display: "flex",
        // flexDirection: "row",
    },
    bmItemList: {
        color: "red",
        padding: "0 0 0 2rem",
    },
};

function HamburgerMenu(props) {
    return (
        <StyledMenu customBurgerIcon={<HamburgerIcon />} styles={menuStyle}>
            <Logo width="10rem" height="2rem" style={{marginBottom: "2rem"}} />
            <Link to="/project/list">
                <CustomText fs="1.3rem" fw="600">
                    프로젝트
                </CustomText>
            </Link>
            <Link to="/data-center">
                <CustomText fs="1.3rem" fw="600">
                    데이터센터
                </CustomText>
            </Link>
            <Link to="/notice">
                <CustomText fs="1.3rem" fw="600">
                    공지사항
                </CustomText>
            </Link>
            <Link to="/document">
                <CustomText fs="1.3rem" fw="600">
                    가이드
                </CustomText>
            </Link>
        </StyledMenu>
    );
}

function AppHeader(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // const { innerRef } = useOnOutsideClick(() => setVisible(false));

    const {UserInfo, Token} = useSelector(state => state);

    const [dropdownVisible, setVisible] = useState(false);

    const tokenVerifyMutation = useMutation(VerifyToken);
    const userInfoMutation = useMutation(GetUserInfo);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    useEffect(async () => {
        if (!_.isEmpty(Token.data)) {
            const formData = new FormData();
            formData.append("token", Token.data.access);

            await tokenVerifyMutation.mutateAsync(formData, {
                onError: error => {
                    dispatch(deleteUserInfoAction());
                    dispatch(deleteTokenAction());
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "회원 인증 오류",
                        text: "만료된 인증입니다.\n관리자에게 문의하세요.",
                        afterFunction: () => {
                            navigate("/");
                        },
                    });

                    return;
                },
            });

            await userInfoMutation.mutateAsync(Token.data.access, {
                onSuccess: data => {
                    if (data.data.company !== null) {
                        if (!data.data.company.is_approve) {
                            setAlertModalOpen({
                                open: true,
                                iconType: "warning",
                                title: "관리자 기업 승인 필요",
                                text: "관리자 기업 승인 후 이용 가능합니다.",
                                afterFunction: null,
                            });
                            handleLogoutBtnClick();
                            return;
                        } else if (data.data.company.is_del) {
                            setAlertModalOpen({
                                open: true,
                                iconType: "error",
                                title: "회원 인증 오류",
                                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                                afterFunction: null,
                            });
                            handleLogoutBtnClick();
                            return;
                        }
                    }
                    if (data.data.is_del) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "등록되지 않은 회원",
                            text: "등록되지 않은 회원입니다.\n회원가입을 진행해주세요.",
                            afterFunction: null,
                        });
                        handleLogoutBtnClick();
                        return;
                    } else if (!data.data.is_mail_approve) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "이메일 인증 필요",
                            text: "이메일 인증 후 이용 가능합니다.",
                            afterFunction: null,
                        });
                        handleLogoutBtnClick();
                        return;
                    } else if (!data.data.is_manager_approve) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "warning",
                            title: "소속 기업 담당자 승인 필요",
                            text: "소속 기업 담당자 승인 후 이용 가능합니다.",
                            afterFunction: null,
                        });
                        handleLogoutBtnClick();
                        return;
                    }

                    dispatch(updateUserInfoAction(data.data));
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "회원 인증 오류",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    handleLogoutBtnClick();
                },
            });
        }
    }, [Token]);

    const handleLogoutBtnClick = useCallback(() => {
        dispatch(deleteUserInfoAction());
        dispatch(deleteTokenAction());
    }, []);

    const logoPathTo = useMemo(() => {
        if (!_.isEmpty(UserInfo.data)) {
            return "/dashboard";
        } else {
            return "/";
        }
    }, [location, UserInfo]);

    const profileDropdownItems = useMemo(
        () => (
            <DropDownMenu>
                <div style={{padding: "1rem", borderBottom: "1px solid #30363D"}}>
                    <CustomText
                        fs="1.125rem"
                        fw="600"
                        wd="100%"
                        style={{
                            whiteSpace: "nowrap",
                            wordBreak: "break-all",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                        {UserInfo.data.login_id}
                    </CustomText>
                </div>
                <Menu.Item key="0" className="menu-item">
                    <Link to={`/mypage?user=${UserInfo.data.login_id}&tab=memberInfo&type=info`}>
                        <CustomText
                            fs="1rem"
                            style={{
                                flex: 1,
                                whiteSpace: "nowrap",
                                wordBreak: "break-all",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "10px 0",
                            }}>
                            마이페이지
                        </CustomText>
                    </Link>
                </Menu.Item>
                <Menu.Item key="1" className="menu-item" style={{borderTop: "1px solid #30363D"}}>
                    <Link to="/" onClick={handleLogoutBtnClick}>
                        <CustomText
                            fs="1rem"
                            style={{
                                flex: 1,
                                whiteSpace: "nowrap",
                                wordBreak: "break-all",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "10px 0",
                            }}>
                            로그아웃
                        </CustomText>
                    </Link>
                </Menu.Item>
            </DropDownMenu>
        ),
        []
    );

    const handleProfileSectionClick = useCallback(() => {
        if (!_.isEmpty(UserInfo.data) && !_.isEmpty(Token.data)) {
            setVisible(!dropdownVisible);
        }
    }, [dropdownVisible]);

    return (
        <Wrap>
            <DesktopContents>
                <LogoSection jc="center">
                    <Link to={logoPathTo} style={logoLinkStyle}>
                        <Logo width="10rem" height="100%" />
                    </Link>
                </LogoSection>
                <div className="menu-items">
                    <div>
                        <Link to="/project/list">
                            <CustomText
                                fs="1rem"
                                fw={location.pathname.includes("/project") ? 700 : 400}
                                cl={location.pathname.includes("/project") ? "#BFFFA1" : "#fff"}>
                                프로젝트
                            </CustomText>
                        </Link>
                        <Link to="/notice">
                            <CustomText
                                fs="1rem"
                                fw={location.pathname.includes("/notice") ? 700 : 400}
                                cl={location.pathname.includes("/notice") ? "#BFFFA1" : "#fff"}>
                                공지사항
                            </CustomText>
                        </Link>
                        <Link to="/document">
                            <CustomText
                                fs="1rem"
                                fw={location.pathname.includes("/document") ? 700 : 400}
                                cl={location.pathname.includes("/document") ? "#BFFFA1" : "#fff"}>
                                가이드
                            </CustomText>
                        </Link>
                        <Link to="/data-center">
                            <CustomText
                                fs="1rem"
                                fw={location.pathname.includes("/data-center") ? 700 : 400}
                                cl={
                                    location.pathname.includes("/data-center") ? "#BFFFA1" : "#fff"
                                }>
                                데이터센터
                            </CustomText>
                        </Link>
                    </div>
                </div>

                <ProfileSection onClick={handleProfileSectionClick}>
                    {!_.isEmpty(UserInfo.data) && !_.isEmpty(Token.data) ? (
                        <>
                            <Avatar
                                size="default"
                                src={
                                    UserInfo.data.image_path
                                        ? process.env.REACT_APP_END_POINT + UserInfo.data.image_path
                                        : ""
                                }
                                icon={<UserOutlined />}
                                style={{backgroundColor: Colors[UserInfo.data.id % 10]}}
                            />
                            <StyledDropDown
                                visible={dropdownVisible}
                                trigger="click"
                                overlay={profileDropdownItems}>
                                <div>
                                    <DownArrow width="0.6rem" height="0.6rem" />
                                </div>
                            </StyledDropDown>
                        </>
                    ) : (
                        <Link to="/login">
                            <CustomText fs="1rem">로그인</CustomText>
                        </Link>
                    )}
                </ProfileSection>
            </DesktopContents>

            <MobileContents>
                <div className="logo-section">
                    <HamburgerMenu />
                    <Link to={logoPathTo} style={logoLinkStyle}>
                        <Logo width="10rem" height="100%" />
                    </Link>
                </div>
                <ProfileSection onClick={handleProfileSectionClick}>
                    {!_.isEmpty(UserInfo.data) && !_.isEmpty(Token.data) ? (
                        <>
                            <Avatar
                                size="default"
                                src={
                                    UserInfo.data.image_path
                                        ? process.env.REACT_APP_END_POINT + UserInfo.data.image_path
                                        : ""
                                }
                                icon={<UserOutlined />}
                                style={{backgroundColor: Colors[UserInfo.data.id % 10]}}
                            />
                            <StyledDropDown
                                visible={dropdownVisible}
                                trigger="click"
                                overlay={profileDropdownItems}>
                                <div>
                                    <DownArrow width="0.6rem" height="0.6rem" />
                                </div>
                            </StyledDropDown>
                        </>
                    ) : (
                        <Link to="/login">
                            <CustomText fs="1rem">로그인</CustomText>
                        </Link>
                    )}
                </ProfileSection>
            </MobileContents>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default AppHeader;
