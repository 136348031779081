import React, { useEffect, useState, useCallback } from "react";
import { Button } from "antd";
import queryString from "query-string";

import { useLocation, useNavigate } from "react-router-dom";
import IssueDetail from "./IssueDetail";
import IssueRegistration from "./IssueRegistration";
import IssueList from "./IssueList";

function IssueMain(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const crntTab = queryString.parse(location.search).tab;
    const crntPage = queryString.parse(location.search).page;
    const crntType = queryString.parse(location.search).type;
    const crntIssueId = queryString.parse(location.search).id;

    // useEffect(() => {
    //     if (crntType === undefined) {
    //         if (crntPage === undefined) {
    //             navigate({
    //                 search: `?tab=issue&page=1`,
    //             });
    //         } else {
    //             navigate({
    //                 search: `?tab=issue&page=${crntPage}`,
    //             });
    //         }
    //     }
    // }, [crntType]);

    const pageSelected = useCallback((page) => {
        if (crntType === "registration") {
            return <IssueRegistration />;
        } else if (crntIssueId) {
            return <IssueDetail issueId={crntIssueId} />;
        } else {
            return <IssueList page={crntPage === undefined ? 1 : crntPage} />;
        }
    });

    return pageSelected(crntTab);
}

export default IssueMain;
