import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PrivateIcon } from "../../../assets/project/private-icon.svg";
import { ReactComponent as PublicIcon } from "../../../assets/project/public-icon.svg";
import UserDashboardLayout from "../../../components/layout/UserDashboardLayout";
import AlertModal from "../../../components/modal/AlertModal";
import TrainState from "../../../components/project/model-train/TrainState";
import { GetTrainSchedule } from "../../../services/train-services";
import { CircleText, CustomText } from "../../../styles/theme/CustomStyle";

const Wrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
        width: 90vw;
        padding-bottom: 10rem;
    }
`;

const ScheduleWrapper = styled.div`
    width: 100%;

    .title-section {
        box-shadow: inset 0 -2px 0 0 #575757;
        padding-bottom: 10px;
        margin-bottom: 1.2rem;
    }
`;

const ScheduleList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
`;

const ScheduleItem = styled.div`
    width: 100%;
    border-bottom: 1px solid #707070;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 3rem;

    .reserve-status {
        display: flex;
        flex-direction: column;
        padding-top: 0.2rem;
        gap: 1rem;
    }

    .schedule-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.65rem;
    }

    .schedule-info .project-title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .project-public {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            overflow: hidden;

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .schedule-info .dataset-parameter {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .model-name-section {
            span {
                color: #d4d4d4;
                margin-right: 10px;
            }
        }

        .train-parameters {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 1.25rem;

            span {
                font-weight: 500;
                margin-left: 7px;
            }

            div {
                height: 0.875rem;
                border-right: 1px solid #707070;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 0;

        .reserve-status {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }
        }

        .schedule-info .project-title-section {
            margin-bottom: 0.2rem;
        }

        .schedule-info .dataset-parameter {
            flex-direction: column;
            align-items: flex-start;

            .model-name-section {
                margin-bottom: 1rem;
            }
        }
    }
`;

function ScheduleMain(props) {
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [isGraph, setIsGraph] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: trainScheduleData, isFetching: isLoading } = useQuery(
        ["trainschedule", page],
        async () => {
            const data = await GetTrainSchedule(page);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                setTotalPage(Math.ceil(res.data.count / 10));
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <UserDashboardLayout>
            <Wrap>
                <TrainState isGraph={isGraph} setIsGraph={setIsGraph} />

                <ScheduleWrapper>
                    <div className="title-section">
                        <CustomText fs="1.125rem" fw="600">
                            예약된 학습
                        </CustomText>
                    </div>

                    <ScheduleList>
                        {isLoading ? (
                            <CustomText>Loading</CustomText>
                        ) : (
                            trainScheduleData.data.results.map((schedule, index) => {
                                return (
                                    <ScheduleItem key={schedule.id}>
                                        <div className="reserve-status">
                                            <CustomText fs="1rem">대기 {index + 1}</CustomText>
                                            <div>
                                                <CustomText fs="1rem" cl="#b1b1b1">
                                                    {dayjs(schedule.created_at).locale("ko").format("YYYY.MM.DD")}
                                                </CustomText>
                                                <CustomText fs="1rem" cl="#b1b1b1">
                                                    {dayjs(schedule.created_at).locale("ko").format("A h:mm")}
                                                </CustomText>
                                            </div>
                                        </div>
                                        <div className="schedule-info">
                                            <div className="project-title-section">
                                                <div className="project-public">
                                                    {schedule.data_set.project.is_public ? (
                                                        <Link to={`/project/${schedule.data_set.project.id}?tab=dataset`}>
                                                            <PublicIcon fill="white" width="1.5rem" height="1.5rem" />
                                                            <CustomText fs="1.25rem" fw="500" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {schedule.data_set.project.title}
                                                            </CustomText>
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <PrivateIcon fill="white" width="1rem" height="1rem" />
                                                            <CustomText fs="1.25rem" fw="500" style={{ width: "100%", whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {schedule.data_set.project.title}
                                                            </CustomText>
                                                        </>
                                                    )}
                                                </div>
                                                <CircleText backgroundColor="#393939" borderColor="#393939">
                                                    <CustomText fs="0.875rem" cl="#d4d4d4">
                                                        {schedule.training_algorithm.algorithm_name}
                                                    </CustomText>
                                                </CircleText>
                                            </div>
                                            <div className="dataset-parameter">
                                                <div className="model-name-section">
                                                    <CustomText>{schedule.model_name}</CustomText>
                                                    <CustomText fw="500">
                                                        <span>학습 데이터</span>
                                                        {schedule.data_set.data_name}
                                                    </CustomText>
                                                </div>
                                                <div className="train-parameters">
                                                    <CustomText fs="0.875rem">
                                                        에포크<span>{schedule.epoch}</span>
                                                    </CustomText>
                                                    <div />
                                                    <CustomText fs="0.875rem">
                                                        배치사이즈<span>{schedule.batch_size}</span>
                                                    </CustomText>
                                                    <div />
                                                    <CustomText fs="0.875rem">
                                                        학습률<span>{schedule.learning_rate}</span>
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    </ScheduleItem>
                                );
                            })
                        )}
                    </ScheduleList>
                </ScheduleWrapper>
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </UserDashboardLayout>
    );
}

export default ScheduleMain;
