import Canvas from "./Canvas";
import styled from "styled-components";

const CanvasWrap = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .right-panel {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 14px;
    }

    @media screen and (max-width: 280px) {
        width: 265px;
        height: 100%;
    }

    @media screen and (min-width: 281px) and (max-width: 370px) {
        width: 290px;
        height: 100%;
    }
    @media screen and (min-width: 350px) and (max-width: 370px) {
        width: 330px;
        height: 100%;
    }
    @media screen and (min-width: 371px) and (max-width: 380px) {
        width: 340px;
        height: 100%;
    }

    @media screen and (min-width: 381px) and (max-width: 499px) {
        width: 376px;
        height: 100%;
    }

    @media screen and (min-width: 500px) and (max-width: 550px) {
        width: 500px;
        height: 100%;
    }

    @media screen and (min-width: 551px) and (max-width: 699px) {
        width: 650px;
        height: 100%;
    }

    @media screen and (min-width: 700px) and (max-width: 899px) {
        width: 700px;
        height: 100%;
    }

    @media screen and (min-width: 900px) and (max-width: 1024px) {
        width: 830px;
        height: 100%;
    }
`;

function Annotator({ annotationPk, imageId, points, bbox, image_url, datasetId, polygonButtonClick }) {
    return (
        <CanvasWrap>
            {annotationPk !== "" && (
                <div className="right-panel">
                    <Canvas annotationPk={annotationPk} imageId={imageId} points={points} bbox={bbox} image_url={image_url} datasetId={datasetId} polygonButtonClick={polygonButtonClick} />
                </div>
            )}
        </CanvasWrap>
    );
}

export default Annotator;
