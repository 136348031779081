import React, { useMemo } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import HomeLayout from "../../components/layout/HomeLayout";

import * as pageNotFoundAnimationData from "../../assets/main/page-not-found-icon.json";
import { CustomText } from "../../styles/theme/CustomStyle";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    padding: 5rem 0px;
    min-height: inherit;
    background-color: #161b22;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 3rem 0rem 5rem 0rem;
    }
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

function PageNotFound(props) {
    const navigate = useNavigate();

    const lottieDefauiltOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: pageNotFoundAnimationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    return (
        <HomeLayout>
            <Wrap>
                <Lottie options={lottieDefauiltOptions} width="70%" height="70%" style={{ maxWidth: 500, marginBottom: "4rem" }} />
                <CustomText fs="1.5rem" fw="500">
                    요청하신 페이지를 찾을 수 없습니다
                </CustomText>
                <CustomBtn bc="#b2b1b1" onClick={() => navigate("/")}>
                    <CustomText fs="1rem" cl="#b2b1b1" fw="500">
                        홈페이지로 이동
                    </CustomText>
                </CustomBtn>
            </Wrap>
        </HomeLayout>
    );
}

export default PageNotFound;
