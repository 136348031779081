import { Button, Avatar } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import AlertModal from "../../components/modal/AlertModal";
import { GetNoticeDetailApi } from "../../services/board-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import { UserOutlined } from "@ant-design/icons";
import { Colors } from "../../styles/theme/Colors";
import NoticeDetailSkeleton from "../../styles/theme/NoticeDetailSkeleton";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
    background-color: #161b22;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.div`
    margin-top: 1.3rem;
    width: 90%;
    max-width: 1100px;
`;

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    /* flex: 1; */

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

function NoticeDetail(props) {
    const params = useParams();
    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: noticeData, isLoading } = useQuery(
        ["notice", "detail", params.id],
        async () => {
            const data = await GetNoticeDetailApi(params.id);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "Network 오류",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: () => {
                        navigate("/notice?page=1");
                    },
                });
            },
        }
    );

    return (
        <HomeLayout>
            <Wrap>
                <Content>
                    <CustomText fs="2rem" fw="600" wd="100%" ta="center" mg="0 0 2rem 0">
                        공지사항
                    </CustomText>

                    {isLoading ? (
                        <NoticeDetailSkeleton />
                    ) : (
                        <>
                            <div style={{ borderBottom: "1px solid #707070", paddingBottom: "0.8rem", marginTop: "1.5rem" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <CustomText fw={600} fs="1.563rem">
                                        {noticeData.data.title}
                                    </CustomText>
                                    <CustomText>{dayjs(noticeData.data.created_at).locale("ko").format(`YYYY.MM.DD A h:mm`)}</CustomText>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "7px" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Avatar size={24} src={noticeData.data.writer.image_path ? noticeData.data.writer.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[noticeData.data.writer.id % 10] }} />
                                        <CustomText fs="0.875rem" mg="0 0 0 10px">
                                            {noticeData.data.writer.name}
                                        </CustomText>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%", minHeight: "30rem", backgroundColor: "#21272E", borderRadius: "5px", padding: "1.3rem 1.5rem", border: "1px solid #707070", marginTop: "1rem" }}>
                                <CustomText cl="#D4D4D4" style={{ wordBreak: "break-all", whiteSpace: "pre-line" }}>
                                    {noticeData.data.content}
                                </CustomText>
                            </div>
                        </>
                    )}
                    {/* <TitleWrapper>
                            <CustomText fs="1.125rem" fw="600">
                                {noticeData.data.title}
                            </CustomText>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20, marginTop: 5 }}>
                                <CustomText>작성일 : {dayjs(noticeData.data.created_at).format("YYYY-MM-DD")}</CustomText>
                                <CustomText>작성자 : {noticeData.data.writer.name}</CustomText>
                            </div>
                        </TitleWrapper> */}
                    {/* <div style={{ width: "100%", height: 1, backgroundColor: "#707070" }} /> */}
                    {/* <div style={{ padding: "1.5rem", whiteSpace: "pre-line", borderBottom: "1px solid #707070", fontFamily: "Pretendard", fontSize: "1rem", color: "#fff" }} dangerouslySetInnerHTML={{ __html: noticeData.data.content }} /> */}
                </Content>

                <CustomBtn
                    bc="#b2b1b1"
                    onClick={() => {
                        navigate("/notice");
                    }}
                >
                    <CustomText fs="0.9rem" cl="#b2b1b1">
                        목록
                    </CustomText>
                </CustomBtn>
                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </HomeLayout>
    );
}

export default NoticeDetail;
