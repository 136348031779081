import create from "zustand";

const [useStore] = create(set => ({
    width: 1,
    height: 1,

    setSize: ({width, height}) => set({width, height}),

    imageWidth: 100,
    imageHeight: 100,

    setImageSize: size => set(() => ({imageWidth: size.width, imageHeight: size.height})),

    scale: 1,
    setScale: scale => set({scale}),

    scaleX: 1,
    setScaleX: scaleX => set({scaleX}),
    scaleY: 1,
    setScaleY: scaleY => set({scaleY}),

    mediaPk: "",
    setMediaPk: mediaPk => set(state => ({mediaPk})),
    image_url: "",
    setImageURL: image_url => set(state => ({image_url})),

    annotationPk: "",
    setAnnotationPk: annotationPk => set(state => ({annotationPk})),

    bbox: "",
    setBBox: bbox => set(state => ({bbox})),

    points: "",
    setPoints: points => set(state => ({points})),

    isDrawing: false,
    toggleIsDrawing: isDrawing => set({isDrawing}),

    isDrag: false,
    setIsDrag: isDrag => set({isDrag}),

    clickDrawBox: false,
    setClickDrawBox: clickDrawBox => set({clickDrawBox}),
    clickModifiy: false,
    setClickModifiy: clickModifiy => set({clickModifiy}),

    regions: [],
    setRegions: regions => set(state => ({regions})),

    selectedRigionId: null,
    selectRegion: selectedRigionId => set({selectedRigionId}),

    brightness: 0,
    setBrightness: brightness => set({brightness}),

    labelOptions: [],
    setLabelOptions: labelOptions => set(state => ({labelOptions})),

    annotationList: [],
    setAnnotationList: annotationList => set(state => ({annotationList})),

    image: null,
    setImage: image => set(state => ({image})),

    polygons: [],

    setPolygons: polygons => set(state => ({polygons})),

    curPolygon: {},
    setCurPolygon: curPolygon => set(state => ({curPolygon})),

    selectedPolygonId: null,
    selectPolygon: selectedPolygonId => set({selectedPolygonId}),

    isDel: false,
    setiIsDel: isDel => set({isDel}),

    imageInputIndex: 1,
    setImageInputIndex: imageInputIndex => set({imageInputIndex}),

    polygonButtonClick: false,
    setPolygonButtonClick: polygonButtonClick => set({polygonButtonClick}),

    editPolygon: false,
    setEditPolygon: editPolygon => set({editPolygon}),

    isMosaic: false,
    setIsMosaic: isMosaic => set({isMosaic}),

    mosaics: [],
    setMosaics: mosaics => set(state => ({mosaics})),

    isEdit: false,
    setIsEdit: isEdit => set({isEdit}),

    labelModal: false,
    setLabelModal: labelModal => set({labelModal}),

    pointIndex: "",
    setPointIndex: pointIndex => set({pointIndex}),

    isInsert: false,
    setIsInsert: isInsert => set({isInsert}),

    isLabelPage: false,
    setIsLabelPage: isLabelPage => set({isLabelPage}),

    isInputIndex: false,
    setIsInputIndex: isInputIndex => set({isInputIndex}),

    filename: "",
    setFileName: filename => set({filename}),
    isOpen: false,
    setModalOpen: isOpen => set({isOpen}),
}));

export default useStore;
