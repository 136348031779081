import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .image-section {
        width: 45;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function DataLabeling() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/terminology-guide" style={{ cursor: "pointer" }}>
                    <CustomSpan cl="#58A6FF">&nbsp;기능 및 용어 안내&nbsp;</CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;데이터 라벨링</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    데이터 라벨링
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    NewLearn에서는 이미지 위에 객체의 위치와 클래스를 표시하도록하는 라벨링 기능을 제공합니다.
                    <br />
                    <br />
                    이미지 객체인식에서 객체의 위치를 표시하는 방법은 <CustomSpan cl="#58A6FF">Bounding Box, Polygon</CustomSpan>이 있으며 상세 내용은 다음과 같습니다.
                    <br />
                    <br />
                    1) Bounding Box
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>객체 인식에서 박스 형태로 위치를 표시하며 x, y, width(너비), height(높이) 값이 데이터로 저장</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>YOLOv4, YOlOv5, Faster R-CNN, SSD-MobileNet은 대표적으로 Bounding Box 라벨링 데이터를 학습데이터로 사용하는 알고리즘</li>
                    <br />
                    1) Polygon
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Polygon 방식은 이미지 데이터 속에서 객체를 보다 정교하게 라벨링하며 객체 경계의 Point값을 저장</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Mask R-CNN, YOLACT은 대표적으로 Polygon 라벨링 데이터를 학습데이터로 사용하는 알고리즘</li>
                    <br />
                    NewLearn에서는 Bounding Box, Polygon 라벨링을 손쉽게 할 수 있는 툴을 제공합니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>

            <Link to="/document/terminology-guide?terminology=6">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        모델 학습
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default DataLabeling;
