import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(100%);
        }
    }
    overflow: hidden;
    border-bottom: 1px solid #707070;
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.1rem;
    }

    .contents-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 0.8rem;
        gap: 1rem;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 100%;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 100%;
        height: 1rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-content {
        border-radius: 5px;
        height: 5rem;
        flex: 0.3;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
    
    .mobile-skeleton-content {
        display: none;
        border-radius: 5px;
        flex: auto;
        height: 5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .mobile-skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-item-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .info-items {
        border-radius: 5px;
        width: 7rem;
        height: 1.414rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    @media screen and (max-width: 1024px) {
        .contents-wrapper {
            flex-direction: column;
        }

        .skeleton-content {
            display: none;
        }
        
        .mobile-skeleton-content {
            display: block;
        }
    }
`;

function DataCenterListSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-info">
                <div className="title-section">
                    <div className="skeleton-title" />
                </div>
            </div>
            <div className="contents-wrapper">
                {/* <div className="skeleton-item-box"> */}
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 5, flex:0.95, justifyContent:"center" }}>
                        <div className="skeleton-explanation" />
                        <div className="skeleton-explanation" />
                    </div>
                    <div className="skeleton-content" />

                {/* </div> */}
            </div>
            <div className="info-items" />
            <div className="mobile-skeleton-content" />

        </Wrap>
    );
}

export default DataCenterListSkeleton;
