import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import { Progress } from "antd";
import dayjs from "dayjs";
import { useQuery, useMutation } from "react-query";

import * as stydyingAnimationData from "../../assets/user-dashboard/studying.json";
import { CircleText, CustomText } from "../../styles/theme/CustomStyle";
import { GetTrainSchedule } from "../../services/train-services";
import { GetCurrentTrain } from "../../services/train-services";

import AlertModal from "../modal/AlertModal";
import { DeleteSchedule } from "../../services/train-services";
import { useSelector } from "react-redux";
import ConfirmModal from "../modal/ConfirmModal";

const Wrap = styled.div`
    display: flex;
    flex-direction: column;

    gap: 1rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 2rem;
    /* background-color: blue; */

    .data-section {
        width: 100%;
        height: auto;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const CrntTrainingItem = styled.div`
    /* flex: 1; */

    background-color: #21272e;
    border-radius: 5px;
    border: 1px solid #707070;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    .data-value {
        width: 100%;
    }

    .date-percentage-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .status-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.875rem;
        margin-right: 1rem;
    }

    .training-parameter {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.3rem;
        padding: 1.5rem;
    }
`;

const CustomBtn = styled.div`
    width: 89px;
    height: 26px;

    background: ${(props) => props.bc};
    border-radius: 2px;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

function TrainingProgress(props) {
    const { Token, UserInfo } = useSelector((state) => state);
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [progress, setProgress] = useState(0);
    const deleteSchedule = useMutation(DeleteSchedule);

    const lottieDefauiltOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: stydyingAnimationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    const onScheduleDeleteClick = useCallback(async (id) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));
        await deleteSchedule.mutateAsync(
            { token: Token.data.access, pk: id },
            {
                onSuccess: (res) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "학습 취소 완료",
                        text: "학습이 취소되었습니다",
                        afterFunction: null,
                    });
                    getCurrentTrain();
                },
                onError: (error) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    const {
        refetch: getCurrentTrain,
        data: TrainSchdule,
        isLoading: loading,
    } = useQuery(
        ["project", "train"],
        async (e) => {
            const data = await GetCurrentTrain();
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                setProgress(parseInt(Math.floor((res.data[0].current_epoch / res.data[0].epoch) * 100)));
            },
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );
    return (
        <Wrap>
            <CustomText fs="1.125rem" fw="600">
                현재 진행중인 학습
            </CustomText>
            <Content>
                <div>
                    <Lottie options={lottieDefauiltOptions} width="14rem" height="14rem" />
                </div>
                <div className="data-section">
                    {TrainSchdule !== undefined && TrainSchdule.data.length !== 0 ? (
                        <CrntTrainingItem>
                            <div className="status-section">
                                <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#E2FF9B">
                                    <CustomText fs="0.875rem" fw="400" cl="#E2FF9B">
                                        {TrainSchdule.data[0].training_algorithm.algorithm_name}
                                    </CustomText>
                                </CircleText>
                                {TrainSchdule.data[0].type === "train" ? (
                                    <CircleText backgroundColor="#fff" borderColor="#fff">
                                        <CustomText fs="0.875rem" fw="400" cl="#000">
                                            학습중
                                        </CustomText>
                                    </CircleText>
                                ) : (
                                    <CircleText backgroundColor="#fff" borderColor="#fff">
                                        <CustomText fs="0.875rem" fw="400" cl="#000">
                                            오토 라벨링중
                                        </CustomText>
                                    </CircleText>
                                )}
                                {UserInfo.data.id === TrainSchdule.data[0].trainer && (
                                    <CustomBtn
                                        bc="#707070"
                                        onClick={() =>
                                            setConfirmModalOpen({
                                                open: true,
                                                title: "학습 취소",
                                                text: `학습을 취소하시겠습니까?`,
                                                afterFunction: () => {
                                                    onScheduleDeleteClick(TrainSchdule.data[0].id);
                                                },
                                            })
                                        }
                                    >
                                        <CustomText fs="0.8rem" fw="400">
                                            학습 취소
                                        </CustomText>
                                    </CustomBtn>
                                )}
                            </div>
                            <div className="data-value">
                                <div className="date-percentage-section">
                                    <CustomText fs="1rem" fw="600">
                                        {progress}%
                                    </CustomText>
                                    <CustomText fs="1rem" fw="400">
                                        {dayjs(TrainSchdule.data[0].created_at).locale("ko").format("YYYY.MM.DD A h:mm")}
                                    </CustomText>
                                </div>
                                <Progress percent={progress} status="active" showInfo={false} trailColor="#5A5A5A" strokeColor="#C4C4C4" />
                            </div>
                            {TrainSchdule.data[0].type === "train" && (
                                <div className="training-parameter">
                                    <div>
                                        <CustomText fs="0.875rem" fw="400">
                                            에포크 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].epoch}</span>
                                        </CustomText>
                                    </div>
                                    <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                    <div>
                                        <CustomText fs="0.875rem" fw="400">
                                            배치사이즈 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].batch_size}</span>
                                        </CustomText>
                                    </div>
                                    <span style={{ fontWeight: 100, color: "#707070", margin: "0 1rem" }}>|</span>
                                    <div>
                                        <CustomText fs="0.875rem" fw="400">
                                            학습률 <span style={{ fontWeight: 600, marginLeft: 5 }}>{TrainSchdule.data[0].learning_rate}</span>
                                        </CustomText>
                                    </div>
                                </div>
                            )}
                        </CrntTrainingItem>
                    ) : (
                        <CrntTrainingItem style={{ display: "flex", justifyContent: "center", padding: "30px" }}>
                            {" "}
                            <CustomText fs="0.875rem" fw="400" cl="#fff">
                                진행중인 학습 내역이 없습니다.
                            </CustomText>
                        </CrntTrainingItem>
                    )}
                    {/* 
                    <ReserveWrapper>
                        <div className="reserve-title">
                            <CustomText fs="1rem" fw="400">
                                예약 내역
                            </CustomText>
                            <Link to="/schedule" style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4 }}>
                                <CustomText fs="1rem" fw="400">
                                    더보기
                                </CustomText>
                                <ArrowRightCircle stroke="#fff" width="1rem" height="1rem" />
                            </Link>
                        </div>
                        <div className="reserve-list">
                            {trainScheduleData?.data?.results?.slice(0, 3).map((schedule, index) => {
                                return (
                                    <ReserveItem key={schedule.id}>
                                        <div className="name-section">
                                            <div>
                                                <CustomText fs="1rem" fw="400" ta="center" wd="100%">
                                                    {index + 1}
                                                </CustomText>
                                            </div>
                                            <CustomText fs="1rem" fw="400">
                                                {schedule.model_name}
                                            </CustomText>
                                        </div>
                                        <CircleText backgroundColor="#393939" borderColor="#393939">
                                            <CustomText fs="0.875rem" fw="400" cl="#d4d4d4">
                                                {schedule.training_algorithm.algorithm_name}
                                            </CustomText>
                                        </CircleText>
                                    </ReserveItem>
                                );
                            })}
                        </div>
                    </ReserveWrapper> */}
                </div>
            </Content>
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default TrainingProgress;
