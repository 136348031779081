import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }
    /* padding: 1.4rem 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */

    overflow: hidden;

    width: 30%;
    margin-top: 5px;

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
        width: 45%;
    }

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 1.1rem; */
        gap:7px;
    }

    .text-section {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin-left: 1.125rem;
    }

    .skeleton-title {
        border-radius: 5px;
        width: 60%;
        height: 1rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 80%;
        height: 0.8rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-profile {
        border-radius: 100px;
        width: 3rem;
        height: 3rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-profile::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }
    `;

function MemberListSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-info">
                    <div style={{ display: "flex", flexDirection:"row", alignItems: "center", width:"100%"}}>
                        <div className="info-profile" />

                        <div className="text-section">
                            <div className="skeleton-title" />
                            <div className="skeleton-explanation" />
                            <div className="skeleton-explanation" />
                            <div className="skeleton-explanation" />
                        </div>
                    </div>
            </div>
        </Wrap>
    );
}

export default MemberListSkeleton;
