import axios from "axios";
export const GetValidationData = (projectId) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/storage/get/validation_data/${projectId}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const UploadValidationData = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/storage/upload/validation_data/`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const DeleteValidationData = ({ token, formData }) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/storage/delete/validation_data`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};
