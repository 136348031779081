import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { GetModelTrainingHistory } from "../../services/train-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import AlertModal from "../modal/AlertModal";
import DateWrap from "./train-history/DateWrap";
import TrainHistoryMain from "./train-history/TrainHistoryMain";

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 1rem;

    .reserve-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #707070;
        padding-bottom: 0.3rem;
    }

    .use-time-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
`;

const TrainListWrap = styled.div`
    display: flex;
    gap: 1.5rem;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const GradientText = styled(CustomText)`
    background: -webkit-linear-gradient(left, #2cf2ff, #fbff2c);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
    if (start.isAfter(end)) throw new Error("start must precede end");

    const next = dayjs(start).add(1, key).startOf(key);

    if (next.isAfter(end, key)) return arr;

    return getRangeOfDates(next, end, key, arr.concat(next));
}

function TrainingHistory(props) {
    const { UserInfo, Token } = useSelector((state) => state);

    const [selectedYear, setSelectedYear] = useState(dayjs().format("YYYY"));
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format("M"));

    const begin = dayjs(UserInfo.data.created_at).startOf("year");
    const end = dayjs();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const yearList = useMemo(() => {
        return getRangeOfDates(begin, end, "year")
            .map((data) => {
                return dayjs(data).format("YYYY");
            })
            .reverse();
    }, [UserInfo.data]);

    const monthList = useMemo(() => {
        return getRangeOfDates(dayjs(new Date(selectedYear, 0, 1)), end, "month")
            .map((data) => {
                return dayjs(data).format("M");
            })
            .reverse();
    }, [selectedYear]);

    const { data: historyData, isFetching: isLoading } = useQuery(
        ["training", "history", selectedYear, selectedMonth],
        async () => {
            const data = await GetModelTrainingHistory(Token.data.access, UserInfo.data.id, selectedYear, selectedMonth);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    return (
        <Wrap>
            <div className="reserve-title">
                <CustomText fs="1.125rem" fw="600">
                    학습 내역
                </CustomText>
                <div className="use-time-section">
                    <CustomText fs="1rem" fw="400">
                        {selectedYear}년 {selectedMonth}월 학습이용시간
                    </CustomText>
                    <GradientText fs="1rem" fw="600">
                        {historyData?.data.total_training_time}
                    </GradientText>
                </div>
            </div>

            <TrainListWrap>
                <DateWrap yearList={yearList} monthList={monthList} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} historyData={historyData} isLoading={isLoading} />
                <TrainHistoryMain yearList={yearList} monthList={monthList} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} historyData={historyData} isLoading={isLoading} />
            </TrainListWrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default TrainingHistory;
