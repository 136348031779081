import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined, GithubOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";
import fasterRCNN from "../../../assets/document/images/fasterRCNN.png";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 110px;
    border-radius: 5px;
    border: none;

    :hover {
        background: #58a6ff;
        transition: all 300ms;
    }
    .ant-btn-primary {
        background-color: #58a6ff;
    }
    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function FasterRCNN() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;Faster R-CNN</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    Faster R-CNN
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    Faster R-CNN은 Tensorflow Object Detection API가 기본적으로 제공하는 많은 모델 아키텍처 중 하나 입니다.
                    <br />
                    <br />
                    Faster R-CNN은 Fast R-CNN과 Region Proposal Network 합쳐진 구조입니다.
                    <br />
                    <br />
                    Faster R-CNN의 기본 프로세스는 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}> CNN을 통해 H x W x C 크기의 feature map을 추출합니다. 이때 H, W, C는 가로, 세로, 체널 수 입니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}> 생성된 Feature map을 Region Proposal Network로 전달합니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}> Region Proposal Network 지나면 서로 다른 크기의 region proposals이 나옵니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}> region proposals과 CNN을 통해 얻은 feature map에 RoI pooling을 수행하여 고정된 크기의 feature map을 얻습니다. </li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}> Fast R-CNN에 고정된 크기의 feature map을 입력하여 Classification과 Bounding Box Regression을 수행합니다. </li>
                    <br />
                    <br />
                    Faster R-CNN 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <img width="50%" height="100%" style={{ borderRadius: "10px" }} src={fasterRCNN} />
            </div>
            <div className="button-section">
                <a href="https://github.com/tensorflow/models/tree/master/research/object_detection" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined style={{ color: "#fff", fontSize: "1rem", marginRight: "10px" }} />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/document/algorithm-guide?algorithm=3">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            YOLOv4
                            <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default FasterRCNN;
