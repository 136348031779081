import DrawBBox from "./DrawBBox";
import DrawClickPloygon from "./DrawClickPloygon";
import { useSelector } from "react-redux";

function Canvas({ annotationPk, imageId, bbox, points, image_url, datasetId }) {
    const { SelectedDataset } = useSelector((state) => state);

    return (
        <>
            <>
                {SelectedDataset.data.annotation_type === "bbox" && <DrawBBox imageId={imageId} annotationData={bbox} image_url={image_url} datasetId={datasetId}></DrawBBox>}
                {SelectedDataset.data.annotation_type === "polygon" && <DrawClickPloygon imageId={imageId} annotationData={points} image_url={image_url} datasetId={datasetId}></DrawClickPloygon>}
            </>
        </>
    );
}

export default Canvas;
