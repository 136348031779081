import React, { useCallback, useState } from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled, { css } from "styled-components";

import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import MypageTab from "../mypage/MypageTab";
import { CustomText } from "../../styles/theme/CustomStyle";
import { ReactComponent as WarningIcon } from "../../assets/mypage/warning.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { DeleteUser } from "../../services/account-services";
import ConfirmModal from "../modal/ConfirmModal";
import AlertModal from "../modal/AlertModal";

const Wrap = styled(Layout)`
    min-height: 60vh;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const ContentWrap = styled(Layout)`
    background-color: #161b22;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4rem;
`;

const StyledContent = styled(Content)`
    min-height: 60vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.3rem;
    padding: 0 10rem;

    width: 100%;

    @media screen and (max-width: 1024px) {
        align-items: center;
        justify-content: flex-start;
        margin-left: 0px;
        padding-top: 0;
        /* width: 100%; */
    }

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }

    ${(props) =>
        props.tab === "dashboard" &&
        css`
            padding: 0;
        `}
`;

const BottomSectoin = styled.div`
    width: 30rem;
    border-top: 2px solid #575757;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 3rem 4rem 3rem;
`;

const SecessionButton = styled(Button)`
    background-color: #161b22 !important;
    /* border: 1px solid #707070; */
    border-radius: 5px;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.5rem;
    border-color: #70726e !important;
`;

function MypageDetailLayout({ children }) {
    const { UserInfo, Token } = useSelector((state) => state);
    const navigate = useNavigate();
    const location = useLocation();
    const crntTab = queryString.parse(location.search).tab;
    const crntType = queryString.parse(location.search).type;

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const SecessionOnClick = useCallback(async () => {
        // setConfirmModalOpen((prev) => ({
        //     ...prev,
        //     loading: true,
        // }));
        try {
            await DeleteUser(UserInfo.data.id, Token.data.access);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회원 탈퇴 완료",
                text: "회원 탈퇴가 완료되었습니다.\n이용해 주셔서 감사합니다.",
                afterFunction: () => {
                    navigate("/");
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회원 탈퇴 오류",
                text: "회원 탈퇴에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
        // setConfirmModalOpen((prev) => ({
        //     ...prev,
        //     loading: false,
        // }));
    }, []);

    return (
        <Wrap>
            <AppHeader />
            {/* <MyPageHeader /> */}
            <MypageTab />
            <ContentWrap>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", minWidth: 0 }}>
                    {crntTab === "dashboard" ? (
                        <StyledContent tab={crntTab}>{children}</StyledContent>
                    ) : (
                        <div style={{ display: "flex" }}>
                            <StyledContent>{children}</StyledContent>
                        </div>
                    )}
                </div>
            </ContentWrap>

            {crntType === "info" && (
                <>
                    <div style={{ backgroundColor: "#161B22", width: "100%", flex: 1, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "center" }}>
                        <BottomSectoin>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <CustomText fs="1.05rem" cl="#FF6F6F">
                                    회원탈퇴
                                </CustomText>
                                <WarningIcon />
                            </div>
                            <CustomText fs="0.95rem" ta="center" cl="#D4D4D4" mg="1rem 0px 2rem 0px" style={{ whiteSpace: "pre-wrap", lineHeight: "1.4rem" }}>
                                회원 탈퇴시 되돌릴 수 없으며{"\n"}프로젝트 관리자로 있는 프로젝트는 삭제처리됩니다.
                            </CustomText>
                            <SecessionButton
                                onClick={() => {
                                    setConfirmModalOpen({
                                        open: true,
                                        title: "회원 탈퇴",
                                        text: "정말 탈퇴하시겠습니까?",
                                        afterFunction: () => {
                                            SecessionOnClick();
                                        },
                                    });
                                }}
                            >
                                <CustomText fs="1.1rem">탈퇴</CustomText>
                                <ArrowRightCircle stroke="#ffffff" width="1.2rem" height="1.2rem" />
                            </SecessionButton>
                        </BottomSectoin>
                    </div>
                    <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
                    <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
                </>
            )}
            <AppFooter />
        </Wrap>
    );
}

export default MypageDetailLayout;
