import React, {useState, useCallback, useEffect} from "react";
import styled, {css} from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import {ReactComponent as AssentIcon} from "../../assets/signup/assent.svg";
import {ReactComponent as UserIcon} from "../../assets/signup/user.svg";
import {ReactComponent as UserInputIcon} from "../../assets/signup/userinput.svg";
import {ReactComponent as InputIcon} from "../../assets/signup/input.svg";
import {ReactComponent as CheckIcon} from "../../assets/signup/check.svg";
import {ReactComponent as PasswordCloseIcon} from "../../assets/signup/passwordclose.svg";
import {ReactComponent as PasswordOpenIcon} from "../../assets/signup/passwordopen.svg";
import {useQuery} from "react-query";
import {Button} from "antd";

import {CustomText} from "../../styles/theme/CustomStyle";
import SignupComplete from "./SignupComplete";
import {
    CompanyRegistrationNumberDupCheck,
    GetCompany,
    UserEmailDupCheck,
    UserIdDupCheck,
} from "../../services/account-services";

import Select from "react-select";
import AlertModal from "../../components/modal/AlertModal";

// const { Option } = Select;

const Wrap = styled.div`
    min-height: inherit;
    background-color: #0c1117;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    /* justify-content: center; */
    padding-bottom: 10rem;
`;

const SignUpTop = styled.div`
    display: flex;
    margin-top: 60px;
    width: 100%;
    width: 30rem;

    div {
        /* width: 100%;
        max-width: 10rem; */
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

const SignUpBox = styled.div`
    width: 30rem;
    background-color: #21272e;
    padding: 20px 30px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 20px;

    .textMargin {
        ${props =>
            props.iscomplete
                ? css`
                      margin-bottom: 6px;

                      @media screen and (max-width: 768px) {
                          margin-bottom: 0.55rem;
                      }
                  `
                : css`
                      margin-bottom: 0rem;
                  `}
    }

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

const InputBox = styled.div`
    background-color: #21272e;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 30px;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }

    /* ${props =>
        props.inputFocus
            ? css`
                  border-bottom: 1px dashed #b1b1b1;
              `
            : css`
                  border-bottom: none;
                  margin: 5px 0px;
              `} */
`;

const NextButton = styled(Button)`
    background-color: #0c1117 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #e2ff9b !important;
    height: 100%;
    margin-left: 10px;

    .ant-btn-loading-icon {
        color: #e2ff9b;
    }

    ${props =>
        props.nextFoucs
            ? css`
                  border-color: #e2ff9b !important;
                  .btn-text {
                      color: #e2ff9b;
                  }
              `
            : css`
                  border-color: #b1b1b1 !important;
                  .btn-text {
                      color: #b1b1b1;
                  }
              `}/* ${props =>
        props.inputFocus
            ? css`
                  display: flex;
              `
            : css`
                  display: none;
              `} */
`;

const JoinButton = styled(Button)`
    background-color: #0c1117 !important;
    /* border: 1px solid #707070; */
    border-radius: 5px;
    width: 100%;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.8rem;
    border-color: #70726e !important;
    margin-top: 25px;
    /* margin-bottom: 100px; */

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

const SelectCompanyDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props =>
        props.iscomplete
            ? css`
                  margin-bottom: 0.4rem;

                  @media screen and (max-width: 768px) {
                      margin-bottom: 0.5rem;
                  }
              `
            : css`
                  margin-bottom: 0rem;
              `}
`;

const StyledSelect = styled(Select)`
    width: 90%;
    background-color: #21272e !important;
    font-size: 1.05rem;
    font-family: Pretendard;
    font-weight: 400;
    color: white;

    input {
        color: white !important;
        font-size: 1.05rem !important;
        font-family: Pretendard !important;
        font-weight: 400 !important;
    }

    .css-1okebmr-indicatorSeparator {
        background: none;
    }
`;

function UserInfo({type}) {
    const [passwordType, setPasswordType] = useState("password");
    const [passwordCheckType, setPasswordCheckType] = useState("password");
    const [next, setNext] = useState(false);
    const [complete, setComplete] = useState(false);
    const [idCheck, setIdCheck] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [companyRegistrationNumberCheck, setCompanyRegistrationNumberCheck] = useState(false);
    const [inputFocus, setInputFocus] = useState("input1");
    const [companyList, setCompanyList] = useState([]);
    const [idCheckClick, setIdCheckClick] = useState(false);
    const [emailCheckClick, setEmailCheckClick] = useState(false);
    const [emailCheckNumber, setEmailCheckNumber] = useState(null);
    // const [emailCheckClick, setEmailCheckClick] = useState(false);
    const [companyRegistrationNumberCheckClick, setCompanyRegistrationNumberCheckClick] =
        useState(false);
    const [idErrormessage, setIdErrormessage] = useState("");
    const [pwErrormessage, setPwErrormessage] = useState("");
    const [pwcErrormessage, setPwcErrormessage] = useState("");
    const [nameErrormessage, setNameErrormessage] = useState("");
    const [phoneErrormessage, setPhoneErrormessage] = useState("");
    const [emailErrormessage, setEmailErrormessage] = useState("");
    const [companyNameErrormessage, setCompanyNameErrormessage] = useState("");
    const [companyNumErrormessage, setCompanyNumErrormessage] = useState("");
    const [joinCheck, setJoinCheck] = useState({
        id: false,
        password: false,
        passwordCheck: false,
        name: false,
        phone: false,
        email: false,
        companyName: false,
        companyNum: type === "company" ? false : true,
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    // let passwordPattern = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/i;
    let passwordPattern = /^(?=.*[a-zA-Z])(?=.*[!@#$%^])(?=.*[0-9]).{8,}$/;
    let emailPattern =
        /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;

    const selectCustom = {
        control: (base, state) => ({
            ...base,
            color: "white",
            background: "#21272e",
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                border: state.isFocused ? 0 : 0,
            },
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#e2ff9b" : "#fff",
            background: state.isSelected ? "#4C5158" : "#21272e",
            "&:hover": {
                background: state.isFocused ? "#2E3640" : "#21272e",
            },
        }),
        placeholder: base => {
            return {
                ...base,
                color: "#fff",
            };
        },
        singleValue: (base, state) => ({
            ...base,
            color: "#fff",
        }),
        menu: (base, state) => ({
            ...base,
            color: "#fff",
            background: "#21272e",
        }),
    };

    const companySelect = selectedCompany => {
        setInputs(prev => ({
            ...prev,
            ["companyId"]: selectedCompany.key,
            ["companyName"]: selectedCompany.value,
        }));
        setCompanyNameErrormessage("");
    };

    const [inputShow, setInputShow] = useState({
        input2: false,
        input3: false,
        input4: false,
        input5: false,
        input6: false,
        input7: false,
        input8: false,
    });

    const [inputs, setInputs] = useState({
        id: "",
        password: "",
        passwordCheck: "",
        name: "",
        phone: "",
        email: "",
        emailCheckNumber: "",
        companyId: 0,
        companyName: "",
        companyRegistrationNumber: "",
    });

    const InputsChange = useCallback(
        e => {
            const {name, value} = e.target;

            if (name === "id") {
                setIdCheck(false);
                setIdErrormessage("");

                setInputs(prev => ({
                    ...prev,
                    [name]: value.replace(/[^0-9a-zA-Z]/g, ""),
                }));
            } else if (name === "password") {
                setPwErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            } else if (name === "passwordCheck") {
                setPwcErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            } else if (name === "name") {
                setNameErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            } else if (name === "phone") {
                setPhoneErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/g, ""),
                }));
            } else if (name === "email") {
                setEmailCheck(false);
                setEmailErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            } else if (name === "companyRegistrationNumber") {
                setCompanyRegistrationNumberCheck(false);
                setCompanyNumErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/g, ""),
                }));
            } else if (name === "companyName") {
                setCompanyNameErrormessage("");
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            } else if (name === "emailCheckNumber") {
                setInputs(prev => ({
                    ...prev,
                    [name]: value,
                }));
            }
        },
        [inputs]
    );

    useEffect(() => {
        setInputShow(prev => ({...prev, [inputFocus]: true}));
        setPasswordType("password");
    }, [inputFocus]);

    const {refetch} = useQuery(
        ["idCheck"],
        async e => {
            const data = await UserIdDupCheck(inputs.id);
            return data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: inputs.id !== "" && idCheckClick === true,
            retry: false,
            onSuccess: res => {
                setIdCheck(true);
                setInputFocus("input2");
                setIdCheckClick(false);
                setIdErrormessage("");
            },
            onError: err => {
                setIdCheck(false);
                setIdCheckClick(false);
                setIdErrormessage("중복된 아이디가 있습니다.");
            },
        }
    );

    const {refetch: emailRefetch, isFetching: emailLoading} = useQuery(
        ["emailCheck"],
        async e => {
            const data = await UserEmailDupCheck(inputs.email);
            return data;
        },
        {
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: inputs.email !== "" && emailCheckClick === true,
            retry: false,
            onSuccess: res => {
                setEmailCheckNumber(res.data.check_number);
                setEmailErrormessage("");
            },
            onError: err => {
                setEmailCheck(false);
                setEmailCheckClick(false);
                if (err.response.status === 406) {
                    setEmailErrormessage("중복된 이메일이 있습니다.");
                }
            },
        }
    );

    const {refetch: companyRegistrationNumberRefetch} = useQuery(
        ["companyRegistrationNumberRefetchCheck"],
        async e => {
            const data = await CompanyRegistrationNumberDupCheck(
                inputs.companyRegistrationNumber.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3")
            );
            return data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: inputs.email !== "" && companyRegistrationNumberCheckClick === true,
            retry: false,
            onSuccess: res => {
                setCompanyRegistrationNumberCheck(true);
                setInputFocus("0");
                setComplete(true);
                setCompanyRegistrationNumberCheckClick(false);
                setCompanyNumErrormessage("");
            },
            onError: err => {
                setCompanyRegistrationNumberCheck(false);
                setCompanyRegistrationNumberCheckClick(false);
                setCompanyNumErrormessage("중복된 사업자 등록번호가 있습니다.");
            },
        }
    );

    useEffect(() => {
        if (inputs.phone.length === 10) {
            setInputs(prev => ({
                ...prev,
                ["phone"]: inputs.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
            }));
        }
        if (inputs.phone.length === 11) {
            setInputs(prev => ({
                ...prev,
                ["phone"]: inputs.phone
                    .replace(/-/g, "")
                    .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
            }));
        }
    }, [inputs.phone]);

    useEffect(() => {
        if (inputs.companyRegistrationNumber.length === 10) {
            setInputs(prev => ({
                ...prev,
                ["companyRegistrationNumber"]: inputs.companyRegistrationNumber.replace(
                    /(\d{3})(\d{2})(\d{5})/,
                    "$1-$2-$3"
                ),
            }));
        }
    }, [inputs.companyRegistrationNumber]);

    const passwordShow = useCallback(() => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    });

    const passwordCheckShow = useCallback(() => {
        if (passwordCheckType === "password") {
            setPasswordCheckType("text");
        } else {
            setPasswordCheckType("password");
        }
    });

    const nextClick = useCallback(() => {
        // if ((joinCheck.id === false) | (joinCheck.password === false) | (joinCheck.passwordCheck === false) | (joinCheck.name === false) | (joinCheck.phone === false) | (joinCheck.email === false) | (joinCheck.companyName === false) | (joinCheck.companyNum === false)) {
        if (inputs.id === "") {
            setIdErrormessage("아이디를 입력해주세요.");
            return;
        } else if (idCheck === false) {
            setIdErrormessage("중복되는 아이디가 있는지 확인해주세요.");
            return;
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["id"]: true,
            }));
        }

        if (inputs.password === "") {
            setPwErrormessage("비밀번호를 입력해주세요.");
            return;
        } else if (!passwordPattern.test(inputs.password)) {
            setPwErrormessage(
                "비밀번호는 영문자, 숫자, 특수문자를 포함하여 8자리 이상 작성해주세요."
            );
            return;
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["password"]: true,
            }));
        }

        if (inputs.passwordCheck === "") {
            setPwcErrormessage("비밀번호를 확인해주세요.");
            return;
        } else if (inputs.password !== inputs.passwordCheck) {
            setPwcErrormessage("비밀번호가 일치하지 않습니다.");
            return;
        } else {
            setPwcErrormessage("");

            setJoinCheck(prev => ({
                ...prev,
                ["passwordCheck"]: true,
            }));
        }

        if (inputs.name === "") {
            setNameErrormessage("이름을 입력해주세요.");
            return;
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["name"]: true,
            }));
        }

        if (inputs.phone === "") {
            setPhoneErrormessage("연락처를 입력해주세요.");
        } else if (inputs.phone.length < 11) {
            setPhoneErrormessage("연락처는 10자리 이상 입력해주세요.");
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["phone"]: true,
            }));
        }

        if (inputs.email === "") {
            setEmailErrormessage("이메일을 입력해주세요.");
            return;
        } else if (!emailPattern.test(inputs.email)) {
            setEmailErrormessage("이메일 형식에 맞게 입력해주세요.");
            return;
        } else if (emailCheck === false) {
            // setEmailErrormessage("중복된 이메일이 있는지 확인해주세요.");
            setEmailErrormessage("메일인증을 진행해주세요.");
            return;
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["email"]: true,
            }));
        }

        if (inputs.companyName === "") {
            if (type === "company") {
                setCompanyNameErrormessage("기업 명을 입력해주세요.");
                return;
            } else {
                setCompanyNameErrormessage("소속 기업을 선택해주세요.");
                return;
            }
        } else {
            setJoinCheck(prev => ({
                ...prev,
                ["companyName"]: true,
            }));
        }
        if (type === "company") {
            if (inputs.companyRegistrationNumber === "") {
                setCompanyNumErrormessage("사업자등록번호를 입력해주세요.");
                return;
            } else if (companyRegistrationNumberCheck === false) {
                setCompanyNumErrormessage("중복된 사업자등록번호가 있는지 확인해주세요.");
                return;
            } else {
                setJoinCheck(prev => ({
                    ...prev,
                    ["companyNum"]: true,
                }));
            }
        }
        setNext(true);
    }, [joinCheck, inputs, idCheck, emailCheck, companyRegistrationNumberCheck]);

    const {data, isLoading, error} = useQuery(
        ["companys"],
        async e => {
            const data = await GetCompany();
            return data;
        },
        {
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: res => {
                setCompanyList(res.data);
            },
            onError: err => {
                if (err.response.statusText === "Not Found") {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    return;
                }
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const handleEmailCheckNumberConfirmBtnClick = useCallback(() => {
        if (inputs.emailCheckNumber === emailCheckNumber.toString()) {
            setEmailCheck(true);
            setEmailCheckNumber(null);
            setInputs(prev => ({
                ...prev,
                emailCheckNumber: "",
            }));
            setEmailErrormessage("");
        } else {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "잘못된 인증번호입니다.\n인증번호를 다시 확인해주세요.",
                afterFunction: null,
            });
        }
    }, [inputs, emailCheckNumber]);

    const onKeyPress = useCallback(e => {
        if (e.key === "Enter") {
            if (type === "company") {
                if (e.target.name === "id") {
                    if (inputs.id !== "") {
                        setIdCheckClick(true);
                        refetch();
                    }
                } else if (e.target.name === "password") {
                    if ((inputs.password !== "") & passwordPattern.test(inputs.password)) {
                        setInputFocus("input3");
                    }
                } else if (e.target.name === "passwordCheck") {
                    if (
                        (inputs.passwordCheck !== "") &
                        (inputs.password === inputs.passwordCheck)
                    ) {
                        setInputFocus("input4");
                    }
                } else if (e.target.name === "name") {
                    if (inputs.name !== "") {
                        setInputFocus("input5");
                    }
                } else if (e.target.name === "phone") {
                    if ((inputs.phone !== "") & !(inputs.phone.length < 11)) {
                        setInputFocus("input6");
                    }
                } else if (e.target.name === "email") {
                    if ((inputs.email !== "") & emailPattern.test(inputs.email)) {
                        setEmailCheckClick(true);
                        emailRefetch();
                    }
                } else if (e.target.name === "emailCheckNumber") {
                    handleEmailCheckNumberConfirmBtnClick();
                } else if (e.target.name === "companyName") {
                    if (inputs.companyName !== "") {
                        setInputFocus("input8");
                    }
                } else {
                    if (
                        (inputs.companyRegistrationNumber !== "") &
                        !(inputs.companyRegistrationNumber.length < 11)
                    ) {
                        setCompanyRegistrationNumberCheckClick(true);
                        companyRegistrationNumberRefetch();
                    }
                }
            } else {
                if (e.target.name === "id") {
                    if (inputs.id !== "") {
                        setIdCheckClick(true);
                        refetch();
                    }
                } else if (e.target.name === "password") {
                    if ((inputs.password !== "") & passwordPattern.test(inputs.password)) {
                        setInputFocus("input3");
                    }
                } else if (e.target.name === "passwordCheck") {
                    if (
                        (inputs.passwordCheck !== "") &
                        (inputs.password === inputs.passwordCheck)
                    ) {
                        setInputFocus("input4");
                    }
                } else if (e.target.name === "name") {
                    if (inputs.name !== "") {
                        setInputFocus("input5");
                    }
                } else if (e.target.name === "phone") {
                    if ((inputs.phone !== "") & !(inputs.phone.length < 11)) {
                        setInputFocus("input6");
                    }
                } else if (e.target.name === "email") {
                    if ((inputs.email !== "") & emailPattern.test(inputs.email)) {
                        setEmailCheckClick(true);
                        emailRefetch();
                    }
                }
            }
        }
    });

    return !next ? (
        <HomeLayout headerShown={false} signUpHeader={true}>
            <Wrap>
                <CustomText fw={500} fs="1.7rem">
                    {type === "company" ? "기업" : "개인"} 회원가입
                </CustomText>
                <CustomText fs="1.5rem" cl="#E1E1E1">
                    환영합니다!
                </CustomText>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}>
                    <SignUpTop>
                        <div style={{borderBottom: "3px solid #575757", paddingBottom: "15px"}}>
                            <AssentIcon fill="#575757" />
                            <CustomText fs="1.1rem" cl="#575757">
                                약관동의
                            </CustomText>
                        </div>
                        <div style={{borderBottom: "3px solid #575757", paddingBottom: "15px"}}>
                            <UserIcon fill="#575757" />
                            <CustomText fs="1.1rem" cl="#575757">
                                회원유형
                            </CustomText>
                        </div>
                        <div
                            style={{
                                borderBottom: "3px solid transparent",
                                backgroundImage:
                                    "linear-gradient(#0c1117, #0c1117), linear-gradient(90deg, #2cf2ff 0%, #fbff2c 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "content-box, border-box",
                            }}>
                            <UserInputIcon fill="white" />
                            <CustomText fs="1.1rem">회원정보입력</CustomText>
                        </div>
                    </SignUpTop>

                    <SignUpBox iscomplete={complete}>
                        <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                            아이디
                        </CustomText>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            <InputBox
                                inputFocus={inputFocus === "input1" ? true : false}
                                style={{width: "74%"}}>
                                {/* {inputFocus === "input1" ? (
                                    <> */}
                                {(inputs.id === "") | (idCheck === false) ? (
                                    <InputIcon className="prefix" />
                                ) : (
                                    <CheckIcon className="prefix" />
                                )}
                                <input
                                    name="id"
                                    value={inputs.id}
                                    onChange={InputsChange}
                                    onKeyPress={onKeyPress}
                                    autoComplete="off"
                                />
                                {/* </>
                                ) : (
                                    <>
                                        <CheckIcon className="prefix" />
                                        <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                            {inputs.id}
                                        </CustomText>
                                    </>
                                )} */}
                            </InputBox>
                            <NextButton
                                disabled={(inputs.id === "") | (idCheck === true) ? true : false}
                                onClick={() => {
                                    setIdCheckClick(true);
                                    refetch();
                                }}
                                nextFoucs={(inputs.id === "") | (idCheck === true) ? false : true}
                                // inputFocus={inputFocus === "input1" ? true : false}
                            >
                                <CustomText className="btn-text" fs="1.05rem">
                                    중복체크
                                </CustomText>
                            </NextButton>
                        </div>
                        {idErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {idErrormessage}
                            </CustomText>
                        )}
                        {/* {inputShow.input2 && ( */}
                        <div style={{paddingTop: "20px"}}>
                            <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                비밀번호 (숫자 및 특수문자 포함 8자리 이상 작성하세요. 사용가능
                                특수문자 : !@#$%^)
                            </CustomText>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <InputBox inputFocus={inputFocus === "input2" ? true : false}>
                                    {/* {inputFocus === "input2" ? (
                                            <> */}
                                    {(inputs.password === "") |
                                    (passwordPattern.test(inputs.password) === false) ? (
                                        <InputIcon className="prefix" />
                                    ) : (
                                        <CheckIcon className="prefix" />
                                    )}
                                    <div
                                        style={{
                                            width: "90%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            paddingRight: "10px",
                                        }}>
                                        <input
                                            type={passwordType}
                                            name="password"
                                            value={inputs.password}
                                            onChange={InputsChange}
                                            onKeyPress={onKeyPress}
                                            autoComplete="off"
                                        />
                                        {passwordType === "password" ? (
                                            <PasswordCloseIcon
                                                style={{cursor: "pointer"}}
                                                onClick={passwordShow}
                                            />
                                        ) : (
                                            <PasswordOpenIcon
                                                style={{cursor: "pointer"}}
                                                onClick={passwordShow}
                                            />
                                        )}
                                    </div>
                                    {/* </>
                                        ) : (
                                            <>
                                                <CheckIcon className="prefix" />
                                                <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                    {"•".repeat(inputs.password.length)}
                                                </CustomText>
                                            </>
                                        )} */}
                                </InputBox>
                                {/* <NextButton
                                    disabled={(inputs.password === "") | (passwordPattern.test(inputs.password) === false) ? true : false}
                                    onClick={() => {
                                        setInputFocus("input3");
                                    }}
                                    nextFoucs={(inputs.password === "") | (passwordPattern.test(inputs.password) === false) ? false : true}
                                    inputFocus={inputFocus === "input2" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                            </div>
                        </div>
                        {pwErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {pwErrormessage}
                            </CustomText>
                        )}
                        {/* )} */}
                        {/* {inputShow.input3 && ( */}
                        <div style={{paddingTop: "20px"}}>
                            <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                비밀번호 재입력
                            </CustomText>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <InputBox inputFocus={inputFocus === "input3" ? true : false}>
                                    {/* {inputFocus === "input3" ? (
                                            <> */}
                                    {(inputs.passwordCheck === "") |
                                    (inputs.password !== inputs.passwordCheck) ? (
                                        <InputIcon className="prefix" />
                                    ) : (
                                        <CheckIcon className="prefix" />
                                    )}
                                    <div
                                        style={{
                                            width: "90%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            paddingRight: "10px",
                                        }}>
                                        <input
                                            type={passwordCheckType}
                                            name="passwordCheck"
                                            value={inputs.passwordCheck}
                                            onChange={InputsChange}
                                            onKeyPress={onKeyPress}
                                            autoComplete="off"
                                        />
                                        {passwordCheckType === "password" ? (
                                            <PasswordCloseIcon
                                                style={{cursor: "pointer"}}
                                                onClick={passwordCheckShow}
                                            />
                                        ) : (
                                            <PasswordOpenIcon
                                                style={{cursor: "pointer"}}
                                                onClick={passwordCheckShow}
                                            />
                                        )}
                                    </div>
                                    {/* </>
                                        ) : (
                                            <>
                                                <CheckIcon className="prefix" />
                                                <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                    {"•".repeat(inputs.passwordCheck.length)}
                                                </CustomText>
                                            </>
                                        )} */}
                                </InputBox>
                                {/* <NextButton
                                    disabled={(inputs.passwordCheck === "") | (inputs.password !== inputs.passwordCheck) ? true : false}
                                    onClick={() => {
                                        setInputFocus("input4");
                                    }}
                                    nextFoucs={(inputs.passwordCheck === "") | (inputs.password !== inputs.passwordCheck) ? false : true}
                                    inputFocus={inputFocus === "input3" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                            </div>
                        </div>
                        {pwcErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {pwcErrormessage}
                            </CustomText>
                        )}
                        {/* )} */}
                        {/* {inputShow.input4 && ( */}
                        <div style={{paddingTop: "20px"}}>
                            <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                이름
                            </CustomText>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <InputBox inputFocus={inputFocus === "input4" ? true : false}>
                                    {/* {inputFocus === "input4" ? (
                                            <> */}
                                    {inputs.name === "" ? (
                                        <InputIcon className="prefix" />
                                    ) : (
                                        <CheckIcon className="prefix" />
                                    )}
                                    <input
                                        name="name"
                                        value={inputs.name}
                                        onChange={InputsChange}
                                        onKeyPress={onKeyPress}
                                        autoComplete="off"
                                    />
                                    {/* </>
                                        ) : (
                                            <>
                                                <CheckIcon className="prefix" />
                                                <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                    {inputs.name}
                                                </CustomText>
                                            </>
                                        )} */}
                                </InputBox>
                                {/* <NextButton
                                    disabled={inputs.name === "" ? true : false}
                                    onClick={() => {
                                        setInputFocus("input5");
                                    }}
                                    nextFoucs={inputs.name === "" ? false : true}
                                    inputFocus={inputFocus === "input4" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                            </div>
                        </div>
                        {nameErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {nameErrormessage}
                            </CustomText>
                        )}
                        {/* )} */}
                        {/* {inputShow.input5 && ( */}
                        <div style={{paddingTop: "20px"}}>
                            <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                연락처 (하이픈(-)없이 입력해주세요)
                            </CustomText>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <InputBox inputFocus={inputFocus === "input5" ? true : false}>
                                    {/* {inputFocus === "input5" ? (
                                            <> */}
                                    {(inputs.phone === "") | (inputs.phone.length < 11) ? (
                                        <InputIcon className="prefix" />
                                    ) : (
                                        <CheckIcon className="prefix" />
                                    )}
                                    <input
                                        name="phone"
                                        value={inputs.phone}
                                        onChange={InputsChange}
                                        onKeyPress={onKeyPress}
                                        maxLength="13"
                                        autoComplete="off"
                                    />
                                    {/* </>
                                        ) : (
                                            <>
                                                <CheckIcon className="prefix" />
                                                <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                    {inputs.phone}
                                                </CustomText>
                                            </>
                                        )} */}
                                </InputBox>
                                {/* <NextButton
                                    disabled={(inputs.phone === "") | (inputs.phone.length < 11) ? true : false}
                                    onClick={() => {
                                        setInputFocus("input6");
                                    }}
                                    nextFoucs={(inputs.phone === "") | (inputs.phone.length < 11) ? false : true}
                                    inputFocus={inputFocus === "input5" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        Enter
                                    </CustomText>
                                </NextButton> */}
                            </div>
                        </div>
                        {phoneErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {phoneErrormessage}
                            </CustomText>
                        )}
                        {/* )} */}
                        {/* {inputShow.input6 && ( */}
                        <div style={{paddingTop: "20px"}}>
                            <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                이메일 (메일인증이 가능한 이메일로 입력하세요)
                            </CustomText>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <InputBox
                                    inputFocus={inputFocus === "input6" ? true : false}
                                    style={{width: "74%"}}>
                                    {/* {emailCheck ? } */}
                                    {(inputs.email === "") |
                                    (emailCheck === false) |
                                    (emailPattern.test(inputs.email) === false) ? (
                                        <InputIcon className="prefix" />
                                    ) : (
                                        <CheckIcon className="prefix" />
                                    )}
                                    <input
                                        name="email"
                                        value={inputs.email}
                                        onChange={InputsChange}
                                        onKeyPress={onKeyPress}
                                        autoComplete="off"
                                    />
                                </InputBox>
                                <NextButton
                                    disabled={
                                        (inputs.email === "") |
                                        (emailPattern.test(inputs.email) === false) |
                                        (emailCheck === true)
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        setEmailCheckClick(true);
                                        emailRefetch();
                                    }}
                                    nextFoucs={
                                        (inputs.email === "") |
                                        (emailPattern.test(inputs.email) === false) |
                                        (emailCheck === true)
                                            ? false
                                            : true
                                    }
                                    loading={emailLoading}
                                    // inputFocus={inputFocus === "input6" ? true : false}
                                >
                                    <CustomText fs="1.05rem" className="btn-text">
                                        {emailCheck ? "인증완료" : "메일인증"}
                                    </CustomText>
                                </NextButton>
                            </div>
                            {!!emailCheckNumber && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginTop: "0.3rem",
                                    }}>
                                    <InputBox style={{width: "74%"}}>
                                        <InputIcon className="prefix" />
                                        <input
                                            name="emailCheckNumber"
                                            value={inputs.emailCheckNumber}
                                            onChange={InputsChange}
                                            onKeyPress={onKeyPress}
                                            autoComplete="off"
                                            maxLength={5}
                                        />
                                    </InputBox>
                                    <NextButton
                                        onClick={handleEmailCheckNumberConfirmBtnClick}
                                        nextFoucs={
                                            inputs.emailCheckNumber.length === 5 ? true : false
                                        }>
                                        <CustomText fs="1.05rem" className="btn-text">
                                            인증하기
                                        </CustomText>
                                    </NextButton>
                                </div>
                            )}
                        </div>
                        {emailErrormessage !== "" && (
                            <CustomText
                                ta="center"
                                fs="0.875rem"
                                cl="#FF6F6F"
                                mg="7px 0 0 0"
                                style={{width: "100%"}}>
                                {emailErrormessage}
                            </CustomText>
                        )}
                        {/* )} */}
                        {type === "company" ? (
                            <>
                                {/* {inputShow.input7 && ( */}
                                <div style={{paddingTop: "20px"}}>
                                    <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                        기업 명
                                    </CustomText>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}>
                                        <InputBox
                                            inputFocus={inputFocus === "input7" ? true : false}>
                                            {/* {inputFocus === "input7" ? (
                                                    <> */}
                                            {inputs.companyName === "" ? (
                                                <InputIcon className="prefix" />
                                            ) : (
                                                <CheckIcon className="prefix" />
                                            )}
                                            <input
                                                name="companyName"
                                                value={inputs.companyName}
                                                onChange={InputsChange}
                                                onKeyPress={onKeyPress}
                                                autoComplete="off"
                                            />
                                            {/* </>
                                                ) : (
                                                    <>
                                                        <CheckIcon className="prefix" />
                                                        <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                            {inputs.companyName}
                                                        </CustomText>
                                                    </>
                                                )} */}
                                        </InputBox>
                                        {/* <NextButton
                                            disabled={inputs.companyName === "" ? true : false}
                                            onClick={() => {
                                                setInputFocus("input8");
                                            }}
                                            nextFoucs={inputs.companyName === "" ? false : true}
                                            inputFocus={inputFocus === "input7" ? true : false}
                                        >
                                            <CustomText fs="1.05rem" className="btn-text">
                                                Enter
                                            </CustomText>
                                        </NextButton> */}
                                    </div>
                                </div>
                                {companyNameErrormessage !== "" && (
                                    <CustomText
                                        ta="center"
                                        fs="0.875rem"
                                        cl="#FF6F6F"
                                        mg="7px 0 0 0"
                                        style={{width: "100%"}}>
                                        {companyNameErrormessage}
                                    </CustomText>
                                )}
                                {/* )} */}
                                {/* {inputShow.input8 && ( */}
                                <div style={{paddingTop: "20px"}}>
                                    <CustomText fs="1" mg="0px 0px 5px 0px" cl="#D4D4D4">
                                        사업자 등록번호
                                    </CustomText>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}>
                                        {/* {!complete ? (
                                            <> */}
                                        <InputBox
                                            inputFocus={inputFocus === "input8" ? true : false}
                                            style={{width: "74%"}}>
                                            {(inputs.companyRegistrationNumber === "") |
                                            (inputs.companyRegistrationNumber.length < 11) |
                                            (companyRegistrationNumberCheck === false) ? (
                                                <InputIcon className="prefix" />
                                            ) : (
                                                <CheckIcon className="prefix" />
                                            )}
                                            <input
                                                name="companyRegistrationNumber"
                                                value={inputs.companyRegistrationNumber}
                                                onChange={InputsChange}
                                                onKeyPress={onKeyPress}
                                                maxLength={12}
                                                autoComplete="off"
                                            />
                                        </InputBox>

                                        <NextButton
                                            disabled={
                                                (inputs.companyRegistrationNumber === "") |
                                                (inputs.companyRegistrationNumber.length < 11) |
                                                (companyRegistrationNumberCheck === true)
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setCompanyRegistrationNumberCheckClick(true);
                                                companyRegistrationNumberRefetch();
                                            }}
                                            nextFoucs={
                                                (inputs.companyRegistrationNumber === "") |
                                                (inputs.companyRegistrationNumber.length < 11) |
                                                (companyRegistrationNumberCheck === true)
                                                    ? false
                                                    : true
                                            }
                                            inputFocus={inputFocus === "input8" ? true : false}>
                                            <CustomText fs="1.05rem" className="btn-text">
                                                중복체크
                                            </CustomText>
                                        </NextButton>
                                        {/* </>
                                        ) : (
                                            <>
                                                <InputBox>
                                                    <CheckIcon className="prefix" />
                                                    <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                        {inputs.companyRegistrationNumber}
                                                    </CustomText>
                                                </InputBox>

                                                <NextButton
                                                    disabled={false}
                                                    onClick={nextClick}
                                                    nextFoucs={true}
                                                    inputFocus={true}
                                                    // style={{ padding: "4px 15px" }}
                                                >
                                                    <CustomText fs="1.05rem" className="btn-text">
                                                        Join
                                                    </CustomText>
                                                </NextButton>
                                            </>
                                        )} */}
                                    </div>
                                </div>
                                {companyNumErrormessage !== "" && (
                                    <CustomText
                                        ta="center"
                                        fs="0.875rem"
                                        cl="#FF6F6F"
                                        mg="7px 0 0 0"
                                        style={{width: "100%"}}>
                                        {companyNumErrormessage}
                                    </CustomText>
                                )}
                                {/* )} */}
                            </>
                        ) : (
                            <>
                                {/* {inputShow.input7 && ( */}
                                <div style={{paddingTop: "20px"}}>
                                    <CustomText className="textMargin" fs="1" cl="#D4D4D4">
                                        소속 기업
                                    </CustomText>
                                    <SelectCompanyDiv iscomplete={complete}>
                                        {/* {!complete ? (
                                            <> */}
                                        <InputBox>
                                            {inputs.companyName === "" ? (
                                                <InputIcon className="prefix" />
                                            ) : (
                                                <CheckIcon className="prefix" />
                                            )}
                                            <StyledSelect
                                                options={companyList.map(company => ({
                                                    key: company.id,
                                                    value: company.company_name,
                                                    label: company.company_name,
                                                }))}
                                                placeholder={"기업을 선택해주세요"}
                                                clearable={false}
                                                onChange={companySelect}
                                                styles={selectCustom}
                                            />
                                        </InputBox>

                                        {/* <NextButton
                                                    disabled={false}
                                                    onClick={() => {
                                                        setComplete(true);
                                                    }}
                                                    nextFoucs={true}
                                                    inputFocus={true}
                                                >
                                                    <CustomText fs="1.05rem" className="btn-text">
                                                        Enter
                                                    </CustomText>
                                                </NextButton> */}
                                        {/* </>
                                        ) : (
                                            <>
                                                <InputBox>
                                                    <CheckIcon className="prefix" />
                                                    <CustomText fs="1.05rem" mg="0px 0px 0px 10px">
                                                        {inputs.companyName}
                                                    </CustomText>
                                                </InputBox>

                                                 <NextButton disabled={inputs.companyName === "" ? true : false} onClick={nextClick} nextFoucs={inputs.companyName === "" ? false : true} inputFocus={inputFocus === "input7" ? true : false}>
                                                    <CustomText fs="1.05rem" className="btn-text">
                                                        Join
                                                    </CustomText>
                                                </NextButton> 
                                            </>
                                        )} */}
                                    </SelectCompanyDiv>
                                </div>
                                {companyNameErrormessage !== "" && (
                                    <CustomText
                                        ta="center"
                                        fs="0.875rem"
                                        cl="#FF6F6F"
                                        mg="7px 0 0 0"
                                        style={{width: "100%"}}>
                                        {companyNameErrormessage}
                                    </CustomText>
                                )}
                                {/* )} */}
                            </>
                        )}
                    </SignUpBox>
                    <JoinButton onClick={nextClick}>
                        <CustomText fs="1.05rem" cl="#E2FF9B">
                            Join
                        </CustomText>
                    </JoinButton>
                </div>
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </HomeLayout>
    ) : (
        <SignupComplete type={type} inputs={inputs} />
    );
}

export default UserInfo;
