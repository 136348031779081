import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import styled from "styled-components";
// import EmptyLeftSide from "../use-dashboard/EmptyLeftSide";
// import SideDataCenterList from "../use-dashboard/SideDataCenterList";
// import SideProjectList from "../use-dashboard/SideProjectList";
import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";

const Wrap = styled(Layout)`
    min-height: 100vh;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const SideContentWrap = styled(Layout)`
    background-color: #161b22;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    /* gap: 1rem; */
`;

const StyledContent = styled(Content)`
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;
    padding-bottom: 4rem;

    @media screen and (max-height: 600px) {
        min-height: max-content;
        height: 100%;
    }
`;

function ProjectListLayout({ children }) {
    return (
        <Wrap>
            <AppHeader />
            <SideContentWrap>
                {/* <EmptyLeftSide /> */}
                <StyledContent>{children}</StyledContent>
                {/* <SideDataCenterList /> */}
                {/* <SideDataCenterList page="projectList" /> */}
            </SideContentWrap>
            <AppFooter />
        </Wrap>
    );
}

export default ProjectListLayout;
