export const initialState = {
    data: {
        crnt: 1,
        max: 1,
        list: [],
    },
};

export const loadCropIndexAction = (data) => {
    return {
        type: "LOAD_CROP_INDEX",
        data,
    };
};

export const updateCropIndexAction = (data) => {
    return {
        type: "UPDATE_CROP_INDEX",
        data,
    };
};

export const deleteCropIndexAction = () => {
    return {
        type: "DELETE_CROP_INDEX",
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_CROP_INDEX":
            return {
                ...state,
                data: action.data,
            };

        case "UPDATE_CROP_INDEX":
            return {
                data: {
                    ...state.data,
                    crnt: action.data,
                },
            };

        case "DELETE_CROP_INDEX":
            return {
                ...state,
                data: initialState,
            };

        default:
            return state;
    }
};

export default reducer;
