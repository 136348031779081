import { Button, Dropdown, Layout, Menu, Radio, Carousel } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as PrivateIcon } from "../../assets/project/private-icon.svg";
import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";
import { ReactComponent as PublicIcon } from "../../assets/project/public-icon.svg";
import { ReactComponent as NewProjectIcon } from "../../assets/datacenter/newProject-icon.svg";
import { ReactComponent as AddProjectIcon } from "../../assets/datacenter/addProject-icon.svg";
import { ReactComponent as AddDataSetIcon } from "../../assets/datacenter/addDataSet-icon.svg";
import { ReactComponent as BlackNewProjectIcon } from "../../assets/datacenter/black-newproject-icon.svg";
import { ReactComponent as BlackAddProjectIcon } from "../../assets/datacenter/black-addproject-icon.svg";
import { ReactComponent as BlackAddDataSetIcon } from "../../assets/datacenter/black-adddataset-icon.svg";
import { ReactComponent as ImageIcon } from "../../assets/project/image-icon.svg";
import { ReactComponent as LabelingIcon } from "../../assets/project/labeling-icon.svg";

import { CustomText } from "../../styles/theme/CustomStyle";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetDataCenterProjectFork } from "../../services/project-services";
import AlertModal from "../../components/modal/AlertModal";
import DataCenterDetailLayout from "../../components/layout/DataCenterDetailLayout";
import Lottie from "react-lottie";
import * as LoadingData from "../../assets/loading/loaindg-lottie.json";

import InfiniteScroll from "react-infinite-scroll-component";

import DataDropDown from "../../components/datacenter/DataDropDown";
import { ForkDataSetAddDataSet, ForkDataSetAddProject, ForkDataSetNewProject } from "../../services/datacenter-services";
import { updateSelectedDatasetAction } from "../../store/reducer/SelectedDataset";

const Wrap = styled(Layout)`
    min-height: 100vh;
    background-color: #161b22;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0rem 0px 4rem 0; */
    width: 100%;

    @media screen and (max-height: 600px) {
        min-height: max-content;
    }
`;

const Contents = styled(Content)`
    width: 90%;
    max-width: 1100px;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10rem;
`;

const TitleSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -2px 0 0 #575757;
    padding-bottom: 10px;
    margin-top: 2.3rem;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
    }
`;

const TitleInput = styled.input`
    width: 100%;
    background-color: #21272e !important;
    border-radius: 5px;
    padding: 10px 10px;
    color: #fff;
    font-size: 1rem;
    font-family: "Pretendard";
    font-weight: 400;

    border: 1px solid #707070;

    :focus {
        outline: none;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #000;
        }
        to {
            border: 1px solid #e2ff9b;
        }
    }
`;

const StyledDropDown = styled(Dropdown)`
    background-color: #21272e;
    border-radius: 5px;
    padding: 0.45rem 1.2rem;
    border: 1px solid #707070;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0.6rem 1.2rem;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const DataSetWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        div {
            width: 100%;
        }
    }
`;

const StyledRadisGroup = styled(Radio.Group)`
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
    justify-content: center;
    gap: 3rem;
    padding: 0 3rem;

    .ant-radio-wrapper {
        margin: 0;
    }

    .ant-radio-inner {
        background-color: transparent;
        border-color: #b1b1b1;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #707070 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #e2ff9b;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #ffffff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio-checked:after {
        border-color: #707070;
    }

    .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 13px;
    }

    @media screen and (max-width: 1024px) {
        .icon {
            gap: 9px;
        }
    }
`;

const PublicWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 3rem; */

    .toggle-section {
        /* flex: 1; */
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
    }

    .public-explanation {
        flex: 1;
        border-radius: 5px;
        background-color: #303a45;
        padding: 1.2rem 3rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding-left: 0;
        padding-top: 2rem;
        gap: 3rem;

        .toggle-section {
            flex-direction: column;
            gap: 2rem;
        }

        .public-explanation {
            padding: 1.5rem 4.2rem;

            p {
                text-align: center;
            }

            p > br {
                display: none;
            }
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: ${(props) => props.backcolor} !important;
    border-radius: 5px;
    height: auto;
    min-height: 0;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    gap: 10px;

    border-color: ${(props) => props.bc} !important;
`;

const CustomCarousel = styled(Carousel)`
    /* background-color: red;
    width: 90%;
    max-width: 1100px; */

    .item {
        padding: 0 10px;
    }
`;

const ValidationModel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.bc};

    box-sizing: border-box;
    border-radius: 5px;
    width: 30%;
    padding: 30px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: 38px;
    }
`;
const WebSection = styled.div`
    display: flex;
    width: 90%;
    max-width: 1100px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const ProjectListSection = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 1.2rem; */
    width: 100%;

    /* flex-wrap: wrap; */
    overflow-y: auto;
    height: 25rem;

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const MobileSection = styled.div`
    margin-top: 1.3rem;
    width: 90%;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const CustomTextArea = styled.textarea`
    width: 100%;
    background-color: #21272e;
    padding: 10px 10px 10px 1rem;
    border-radius: 5px !important;
    border: 1px solid #707070 !important;
    color: #fff;
    font-size: 1rem;
    font-family: Pretendard;
    font-weight: 400;
    min-height: 7rem;
    word-break: break-all;
    resize: none;

    :focus {
        outline: none !important;
        animation-name: border-focus;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        background-color: #21272e;
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #707070;
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    @keyframes border-focus {
        from {
            border: 1px solid #707070 !important;
        }
        to {
            border: 1px solid #707070 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        min-height: 20rem;
    }
`;

const ProjectList = styled(InfiniteScroll)`
    width: 100%;
    max-height: inherit;
`;

const ProjectItem = styled.div`
    /* overflow: hidden; */
    border-bottom: 1px solid #707070;
    padding: 1rem 10px 1rem 0;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between;
    cursor: pointer;

    .title-section {
        /* width: 100%; */
        flex: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .contents-section {
        width: 100%;
        background-color: #21272e;
        flex: 1.3;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        gap: 1rem;
    }

    .recent-model {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.125rem;
    }

    .image-labeling-count {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .image-labeling-count .count-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .representative-section {
        width: 100%;
        border-radius: 5px;
        padding: 0.5rem 0.7rem;
        gap: 5px;
    }

    .representative-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        gap: 10px;

        .representative {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 0.8rem;

        .title-section {
            gap: 0.7rem;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .contents-section {
            flex-direction: column;
        }
    }
`;

function ForkData(props) {
    const { Token, SelectedDataCenterDataset } = useSelector((state) => state);

    const [isMenu, setIsMenu] = useState("newProject");
    const params = useParams();
    const [addProjectSelectedProject, setAddProjectSelectedProject] = useState("");
    const [addDataSetSelectedProject, setAddDataSetSelectedProject] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const scrollParentRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [forkProjectLoading, setForkProjectLoading] = useState(false);
    const [forkProjectList, setForkProjectList] = useState({
        count: 0,
        crntPage: 1,
        hasMore: false,
        scrollY: 0,
        data: [],
    });

    const navigate = useNavigate();
    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [inputs, setInputs] = useState({
        title: "",
        dataset: "",
        explanation: "",
    });
    const [errors, setErrors] = useState({
        title: "",
        dataset: "",
    });
    const [datasetType, setDatasetType] = useState({
        text: "Bounding Box",
        value: "bbox",
    });
    const [isPublic, setIsPublic] = useState(true);

    const createMutation = useMutation(ForkDataSetNewProject);
    const newDatasetMutation = useMutation(ForkDataSetAddProject);

    const handleInputChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            if (name === "title" && errors.title !== "") {
                setErrors((prev) => ({
                    ...prev,
                    title: "",
                }));
            } else if (name === "dataset" && errors.dataset !== "") {
                setErrors((prev) => ({
                    ...prev,
                    dataset: "",
                }));
            }

            setInputs((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [inputs, errors]
    );

    useEffect(() => {
        fetchProjectList(1, true);
    }, []);

    const fetchProjectList = useCallback(
        async (page, refresh) => {
            if (page === null) {
                return;
            }

            try {
                setForkProjectLoading(true);
                const listRes = await GetDataCenterProjectFork(Token.data.access, page);

                setForkProjectList((prev) => ({
                    ...prev,
                    count: listRes.data.count,
                    hasMore: listRes.data.next !== null ? true : false,
                    crntPage: listRes.data.next !== null ? page + 1 : null,
                    scrollY: page !== 1 && forkProjectList.scrollY + 1080,
                    data: refresh ? listRes.data.results : [...prev.data, ...listRes.data.results],
                }));
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            } finally {
                setForkProjectLoading(false);
            }
        },
        [Token]
    );

    const newProjectImport = useCallback(async () => {
        setIsLoading(true);
        if (inputs.title === "") {
            setErrors((prev) => ({
                ...prev,
                title: "프로젝트 이름을 입력해주세요",
            }));
            window.scrollTo(0, 0);
            return;
        }

        const formData = new FormData();
        formData.append("title", inputs.title);
        formData.append("explanation", inputs.explanation);
        formData.append("dataset", inputs.dataset);
        formData.append("is_public", isPublic);
        formData.append("annotation_type", datasetType.value);
        formData.append("fork_dataset_id", params.id);

        await createMutation.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onSuccess: (res) => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "프로젝트 생성 완료",
                        text: "프로젝트 생성이 완료되었습니다.\n학습 데이터 업로드 후 학습을 진행해보세요.",
                        afterFunction: () => {
                            navigate(`/project/${res.data.id}?tab=dataset`, {
                                replace: true,
                            });
                        },
                    });
                    setIsLoading(false);
                },
                onError: (error) => {
                    if (error.response.status === 406) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "프로젝트 생성 오류",
                            text: "이미 존재하는 프로젝트 이름입니다.\n다른 프로젝트 이름을 입력해주세요.",
                            afterFunction: null,
                        });
                        setErrors((prev) => ({
                            ...prev,
                            title: "이미 존재하는 프로젝트 이름입니다",
                        }));
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "프로젝트 생성 오류",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요",
                        afterFunction: null,
                    });
                    setIsLoading(false);
                },
            }
        );
    }, [Token, inputs, isPublic, datasetType]);

    const addProjectImport = useCallback(async () => {
        setIsLoading(true);

        if (inputs.dataset === "") {
            setErrors((prev) => ({
                ...prev,
                dataset: "대표 데이터셋 이름을 입력해주세요",
            }));
            window.scrollTo(0, 0);
            return;
        }

        const formData = new FormData();
        formData.append("dataset_name", inputs.dataset);
        formData.append("annotation_type", datasetType.value);
        formData.append("project_id", addProjectSelectedProject);
        formData.append("fork_dataset_id", params.id); // 가져오기하는 데이터센터의 데이터셋 id

        await newDatasetMutation.mutateAsync(
            { token: Token.data.access, formData: formData },
            {
                onSuccess: (res) => {
                    updateSelectedDatasetAction(res.data);

                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "프로젝트 생성 완료",
                        text: "프로젝트 생성이 완료되었습니다.\n학습 데이터 업로드 후 학습을 진행해보세요.",
                        afterFunction: () => {
                            navigate(`/project/${addProjectSelectedProject}?tab=dataset`, {
                                replace: true,
                            });
                        },
                    });
                    setIsLoading(false);
                },
                onError: (error) => {
                    if (error.response.status === 409) {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "데이터셋 생성 오류",
                            text: "이미 존재하는 데이터셋 이름입니다.\n다른 데이터셋 이름을 입력해주세요.",
                            afterFunction: null,
                        });
                        setErrors((prev) => ({
                            ...prev,
                            dataset: "이미 존재하는 데이터셋 이름입니다",
                        }));
                        return;
                    }
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "데이터셋 생성 오류",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요",
                        afterFunction: null,
                    });
                    setIsLoading(false);
                },
            }
        );
    }, [Token, inputs, isPublic, datasetType, addProjectSelectedProject]);

    const addDataSetImport = useCallback(async () => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append("project_id", addDataSetSelectedProject);
        formData.append("project_dataset_id", SelectedDataCenterDataset.data?.id);
        formData.append("fork_dataset_id", params.id); // 가져오기하는 데이터센터의 데이터셋 id

        try {
            const res = await ForkDataSetAddDataSet({ token: Token.data.access, formData: formData });
            updateSelectedDatasetAction(res.data);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "프로젝트 생성 완료",
                text: "프로젝트 생성이 완료되었습니다.\n학습 데이터 업로드 후 학습을 진행해보세요.",
                afterFunction: () => {
                    navigate(`/project/${addDataSetSelectedProject}?tab=dataset`, {
                        replace: true,
                    });
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "데이터셋 생성 오류",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요",
                afterFunction: null,
            });
        }
        setIsLoading(false);
    }, [Token, addDataSetSelectedProject, SelectedDataCenterDataset]);

    const handleImportBtnClick = useCallback(() => {
        if (isMenu === "newProject") {
            newProjectImport();
        } else if (isMenu === "addProject") {
            addProjectImport();
        } else {
            addDataSetImport();
        }
    });

    const DatasetTypeList = useMemo(
        () => (
            <StyledMenu>
                <Menu.Item
                    className="menu-item"
                    onClick={() =>
                        setDatasetType({
                            text: "Bounding Box",
                            value: "bbox",
                        })
                    }
                >
                    <MenuItem>
                        <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            Bounding Box
                        </CustomText>
                    </MenuItem>
                </Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() =>
                        setDatasetType({
                            text: "Polygon",
                            value: "polygon",
                        })
                    }
                >
                    <MenuItem>
                        <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            Polygon
                        </CustomText>
                    </MenuItem>
                </Menu.Item>
            </StyledMenu>
        ),
        []
    );

    const LottieLoadingOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: LoadingData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
    }, []);

    return (
        <DataCenterDetailLayout>
            <Wrap>
                <WebSection>
                    <ValidationModel
                        bc={isMenu === "newProject" ? "#ffffff" : "#21272E"}
                        onClick={() => {
                            setIsMenu("newProject");
                        }}
                    >
                        {isMenu === "newProject" ? <NewProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackNewProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                        <CustomText fs="1rem" cl={isMenu === "newProject" ? "#000000" : "#ffffff"} fw="400">
                            새 프로젝트로 가져오기
                        </CustomText>
                    </ValidationModel>
                    <ValidationModel
                        bc={isMenu === "addProject" ? "#ffffff" : "#21272E"}
                        onClick={() => {
                            setIsMenu("addProject");
                        }}
                    >
                        {isMenu === "addProject" ? <AddProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackAddProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                        <CustomText fs="1rem" cl={isMenu === "addProject" ? "#000000" : "#ffffff"} fw="400">
                            기존 프로젝트에 추가
                        </CustomText>
                    </ValidationModel>
                    <ValidationModel
                        bc={isMenu === "addDataSet" ? "#ffffff" : "#21272E"}
                        onClick={() => {
                            setIsMenu("addDataSet");
                        }}
                    >
                        {isMenu === "addDataSet" ? <AddDataSetIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackAddDataSetIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                        <CustomText fs="1rem" cl={isMenu === "addDataSet" ? "#000000" : "#ffffff"} fw="400">
                            기존 데이터셋에 추가
                        </CustomText>
                    </ValidationModel>
                </WebSection>
                <MobileSection>
                    <CustomCarousel centerMode={true} draggable {...settings}>
                        <div className="item">
                            <ValidationModel
                                bc={isMenu === "newProject" ? "#ffffff" : "#21272E"}
                                onClick={() => {
                                    setIsMenu("newProject");
                                }}
                            >
                                {isMenu === "newProject" ? <NewProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackNewProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                                <CustomText fs="1rem" cl={isMenu === "newProject" ? "#000000" : "#ffffff"} fw="400">
                                    새 프로젝트로 가져오기
                                </CustomText>
                            </ValidationModel>
                        </div>
                        <div className="item">
                            <ValidationModel
                                bc={isMenu === "addProject" ? "#ffffff" : "#21272E"}
                                onClick={() => {
                                    setIsMenu("addProject");
                                }}
                            >
                                {isMenu === "addProject" ? <AddProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackAddProjectIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                                <CustomText fs="1rem" cl={isMenu === "addProject" ? "#000000" : "#ffffff"} fw="400">
                                    기존 프로젝트에 추가
                                </CustomText>
                            </ValidationModel>
                        </div>
                        <div className="item">
                            <ValidationModel
                                bc={isMenu === "addDataSet" ? "#ffffff" : "#21272E"}
                                onClick={() => {
                                    setIsMenu("addDataSet");
                                }}
                            >
                                {isMenu === "addDataSet" ? <AddDataSetIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} /> : <BlackAddDataSetIcon width="2.5rem" height="2.5rem" style={{ marginBottom: "15px" }} />}
                                <CustomText fs="1rem" cl={isMenu === "addDataSet" ? "#000000" : "#ffffff"} fw="400">
                                    기존 데이터셋에 추가
                                </CustomText>
                            </ValidationModel>
                        </div>
                    </CustomCarousel>
                </MobileSection>

                <div style={{ backgroundColor: "#303A45", width: "90%", padding: "0.8rem 0", margin: "1rem", borderRadius: "5px" }}>
                    <CustomText fs="0.825rem" ta="center" wd="100%">
                        {isMenu === "newProject" ? "선택된 공개 데이터로 새로운 프로젝트를 생성합니다" : isMenu === "addProject" ? "기존의 참여중인 프로젝트에 선택된 공개 데이터로 새로운 데이터셋을 생성합니다" : "기존의 참여중인 프로젝트에 있는 데이터셋에 공개 데이터를 추가합니다"}
                    </CustomText>
                </div>
                <Contents>
                    {isMenu === "newProject" ? (
                        <>
                            <TitleSection style={{ marginTop: "1.3rem" }}>
                                <CustomText fs="1.25rem" fw="600">
                                    프로젝트 이름
                                </CustomText>
                            </TitleSection>
                            <TitleInput name="title" value={inputs.title} maxLength="20" onChange={handleInputChange} autoComplete="off" placeholder="프로젝트 이름을 입력해주세요" />

                            {errors.title !== "" && (
                                <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A" wd="100%">
                                    {errors.title}
                                </CustomText>
                            )}
                            <TitleSection>
                                <CustomText fs="1.25rem" fw="600">
                                    프로젝트 설명 (선택)
                                </CustomText>
                            </TitleSection>
                            <CustomTextArea name="explanation" rows={5} value={inputs.explanation} onChange={handleInputChange} autoComplete="off" placeholder="(선택) 프로젝트 설명을 입력해주세요" />
                            <TitleSection>
                                <CustomText fs="1.25rem" fw="600">
                                    데이터셋 생성
                                </CustomText>
                            </TitleSection>
                            <DataSetWrapper>
                                <div style={{ height: "100%" }}>
                                    <StyledDropDown overlay={DatasetTypeList} placement="bottom" trigger={["click"]}>
                                        <MenuItem>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                                                <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {datasetType.text}
                                                </CustomText>
                                                <DownArrow width="1.125rem" height="1.125rem" />
                                            </div>
                                        </MenuItem>
                                    </StyledDropDown>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TitleInput name="dataset" value={inputs.dataset} maxLength="20" onChange={handleInputChange} autoComplete="off" placeholder="대표 데이터셋 이름을 입력해주세요" />
                                    {errors.dataset !== "" && (
                                        <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A" wd="100%">
                                            {errors.dataset}
                                        </CustomText>
                                    )}
                                </div>
                            </DataSetWrapper>
                            <TitleSection>
                                <CustomText fs="1.25rem" fw="600">
                                    프로젝트 공개 설정
                                </CustomText>
                            </TitleSection>
                            <PublicWrapper>
                                <StyledRadisGroup
                                    onChange={(e) => {
                                        setIsPublic(e.target.value);
                                    }}
                                    value={isPublic}
                                >
                                    <div className="icon">
                                        <PublicIcon width="2rem" height="2rem" fill={isPublic === true ? "#ffffff" : "#707070"} />

                                        <CustomText cl={isPublic === true ? "#ffffff" : "#707070"}>공개</CustomText>
                                        <Radio value={true} />
                                    </div>
                                    <div className="icon">
                                        <PrivateIcon width="2rem" height="2rem" fill={isPublic === false ? "#ffffff" : "#707070"} />

                                        <CustomText cl={isPublic === false ? "#ffffff" : "#707070"}>비공개</CustomText>
                                        <Radio value={false} />
                                    </div>
                                </StyledRadisGroup>
                                <div className="public-explanation">
                                    <CustomText fs="0.875rem" mg="0 0 1rem 0">
                                        프로젝트 공개 설정시 프로젝트가 데이터센터에 노출되며 다른 이용자들이 해당 프로젝트를 <br /> 열람 및 복사가 가능합니다.
                                    </CustomText>
                                    <CustomText fs="0.875rem">단, 현재 생성한 프로젝트의 팀원이 아닌 경우 업로드, 학습 예약은 불가능합니다.</CustomText>
                                </div>
                            </PublicWrapper>
                        </>
                    ) : isMenu === "addProject" ? (
                        <>
                            <TitleSection style={{ marginTop: "1.3rem", marginBottom: 0 }}>
                                <CustomText fs="1.25rem" fw="600">
                                    프로젝트 선택
                                </CustomText>
                            </TitleSection>
                            <ProjectListSection id="scrollParentRef">
                                {forkProjectLoading ? (
                                    <Lottie options={LottieLoadingOptions} width="10rem" height="10rem" />
                                ) : forkProjectList.data.length === 0 ? (
                                    <CustomText ta="center" wd="100%">
                                        프로젝트가 없습니다. <br />
                                        프로젝트를 생성 후 진행해주세요.
                                    </CustomText>
                                ) : (
                                    <ProjectList dataLength={forkProjectList.count} next={() => fetchProjectList(forkProjectList.crntPage, false)} useWindow={false} hasMore={forkProjectList.hasMore} scrollableTarget="scrollParentRef" initialScrollY={forkProjectList.scrollY}>
                                        {forkProjectList.data.map((project) => {
                                            return (
                                                <ProjectItem
                                                    key={project.id}
                                                    onClick={() => {
                                                        setAddProjectSelectedProject(project.id);
                                                    }}
                                                    style={{ backgroundColor: addProjectSelectedProject === project.id && "#303A45" }}
                                                >
                                                    <div className="title-section">
                                                        <div to={`/project/${project.id}?tab=dataset`} style={{ overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                                            {project.is_public ? <PublicIcon fill="white" width="1.5rem" height="1.5rem" /> : <PrivateIcon fill="white" width="1.5rem" height="1.5rem" />}
                                                            <CustomText fs="1.25rem" fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {project.title}
                                                            </CustomText>
                                                        </div>
                                                    </div>

                                                    <div className="contents-section">
                                                        <div className="representative-section">
                                                            <div className="representative-data">
                                                                <div className="representative">
                                                                    <CustomText fs="0.875rem">대표 라벨링 데이터</CustomText>
                                                                    <CustomText fs="0.875rem" fw="500" cl="#E2FF9B" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                        {project.representative_data?.data_name}
                                                                    </CustomText>
                                                                </div>
                                                            </div>
                                                            <div className="image-labeling-count">
                                                                <div className="count-item">
                                                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                                        이미지
                                                                    </CustomText>
                                                                    <CustomText fs="0.875rem" fw="500">
                                                                        {project.representative_data?.total_image}
                                                                    </CustomText>
                                                                </div>
                                                                <CustomText cl="#707070">|</CustomText>
                                                                <div className="count-item">
                                                                    <LabelingIcon width="0.875rem" height="0.875rem" />
                                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                                        라벨링데이터
                                                                    </CustomText>
                                                                    <CustomText fs="0.875rem" fw="500">
                                                                        {project.representative_data?.annotation_count}
                                                                    </CustomText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ProjectItem>
                                            );
                                        })}
                                    </ProjectList>
                                )}
                            </ProjectListSection>
                            <TitleSection>
                                <CustomText fs="1.25rem" fw="600">
                                    데이터셋 생성
                                </CustomText>
                            </TitleSection>
                            <DataSetWrapper>
                                <div style={{ height: "100%" }}>
                                    <StyledDropDown overlay={DatasetTypeList} placement="bottom" trigger={["click"]}>
                                        <MenuItem>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                                                <CustomText fs="1.125rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {datasetType.text}
                                                </CustomText>
                                                <DownArrow width="1.125rem" height="1.125rem" />
                                            </div>
                                        </MenuItem>
                                    </StyledDropDown>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TitleInput name="dataset" value={inputs.dataset} maxLength="20" onChange={handleInputChange} autoComplete="off" placeholder="대표 데이터셋 이름을 입력해주세요" />
                                    {errors.dataset !== "" && (
                                        <CustomText fs="1.125rem" mg="2px 0px 5px 0px" cl="#F8514A" wd="100%">
                                            {errors.dataset}
                                        </CustomText>
                                    )}
                                </div>
                            </DataSetWrapper>
                        </>
                    ) : (
                        <>
                            <TitleSection style={{ marginTop: "1.3rem", marginBottom: 0 }}>
                                <CustomText fs="1.25rem" fw="600">
                                    프로젝트 선택
                                </CustomText>
                            </TitleSection>
                            <ProjectListSection id="scrollParentRef">
                                {forkProjectLoading ? (
                                    <Lottie options={LottieLoadingOptions} width="10rem" height="10rem" />
                                ) : forkProjectList.data.length === 0 ? (
                                    <CustomText ta="center" wd="100%">
                                        프로젝트가 없습니다. <br />
                                        프로젝트를 생성 후 진행해주세요.
                                    </CustomText>
                                ) : (
                                    <ProjectList dataLength={forkProjectList.count} next={() => fetchProjectList(forkProjectList.crntPage, false)} useWindow={false} hasMore={forkProjectList.hasMore} scrollableTarget="scrollParentRef" initialScrollY={forkProjectList.scrollY}>
                                        {forkProjectList.data.map((project) => {
                                            return (
                                                <ProjectItem
                                                    key={project.id}
                                                    onClick={() => {
                                                        setAddDataSetSelectedProject(project.id);
                                                    }}
                                                    style={{ backgroundColor: addDataSetSelectedProject === project.id && "#303A45" }}
                                                >
                                                    <div className="title-section">
                                                        <div to={`/project/${project.id}?tab=dataset`} style={{ overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                                            {project.is_public ? <PublicIcon fill="white" width="1.5rem" height="1.5rem" /> : <PrivateIcon fill="white" width="1.5rem" height="1.5rem" />}
                                                            <CustomText fs="1.25rem" fw="500" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                {project.title}
                                                            </CustomText>
                                                        </div>
                                                    </div>

                                                    <div className="contents-section">
                                                        <div className="representative-section">
                                                            <div className="representative-data">
                                                                <div className="representative">
                                                                    <CustomText fs="0.875rem">대표 라벨링 데이터</CustomText>
                                                                    <CustomText fs="0.875rem" fw="500" cl="#E2FF9B" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                        {project.representative_data?.data_name}
                                                                    </CustomText>
                                                                </div>
                                                            </div>
                                                            <div className="image-labeling-count">
                                                                <div className="count-item">
                                                                    <ImageIcon width="0.875rem" height="0.875rem" />
                                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                                        이미지
                                                                    </CustomText>
                                                                    <CustomText fs="0.875rem" fw="500">
                                                                        {project.representative_data?.total_image}
                                                                    </CustomText>
                                                                </div>
                                                                <CustomText cl="#707070">|</CustomText>
                                                                <div className="count-item">
                                                                    <LabelingIcon width="0.875rem" height="0.875rem" />
                                                                    <CustomText fs="0.875rem" mg="0px 8px 0px 5px">
                                                                        라벨링데이터
                                                                    </CustomText>
                                                                    <CustomText fs="0.875rem" fw="500">
                                                                        {project.representative_data?.annotation_count}
                                                                    </CustomText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ProjectItem>
                                            );
                                        })}
                                    </ProjectList>
                                )}
                            </ProjectListSection>
                            <TitleSection>
                                <CustomText fs="1.25rem" fw="600">
                                    데이터셋 선택
                                </CustomText>
                            </TitleSection>
                            <DataDropDown projectId={addDataSetSelectedProject} />
                        </>
                    )}
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "10px", maxWidth: "700px" }}>
                        <CustomBtn
                            backcolor="rgba(0,0,0,0)"
                            bc="#707070"
                            onClick={() => {
                                navigate(`/data-center/${params.id}`);
                            }}
                            style={{ marginTop: "5rem", width: "50%", padding: "0.8rem 0" }}
                        >
                            <CustomText fs="1rem" cl="#fff">
                                취소
                            </CustomText>
                        </CustomBtn>
                        <CustomBtn backcolor="rgba(0,0,0,0)" bc="#b2b1b1" onClick={handleImportBtnClick} style={{ marginTop: "5rem", width: "50%", padding: "0.8rem 0" }} loading={isLoading}>
                            <CustomText fs="1rem" cl="#fff">
                                가져오기
                            </CustomText>
                        </CustomBtn>
                    </div>
                </Contents>

                <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            </Wrap>
        </DataCenterDetailLayout>
    );
}

export default ForkData;
