import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    @keyframes loading {
        0% {
            transform: translateX(0);
        }
        50%,
        100% {
            transform: translateX(460px);
        }
    }

    overflow: hidden;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    /* gap: 1.1rem; */
    gap: 7px;

    .skeleton-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 1.1rem; */
        gap: 7px;
    }

    .title-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */
        gap: 10px;
    }

    .skeleton-no {
        border-radius: 5px;
        flex: 0.05;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-no::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-title {
        border-radius: 5px;
        flex: 1;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-title::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-explanation {
        border-radius: 5px;
        width: 40%;
        height: 0.8rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-explanation::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .skeleton-content {
        border-radius: 5px;
        width: 100%;
        height: 5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .skeleton-content::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-items {
        border-radius: 5px;
        width: 3.125rem;
        height: 1.414rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-items::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    .info-profile {
        border-radius: 100px;
        width: 1.5rem;
        height: 1.5rem;
        background: #9b9b9b;
        position: relative;
        overflow: hidden;
    }

    .info-profile::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        animation: loading 2s infinite linear;
    }

    @media screen and (max-width: 1024px) {
        .skeleton-no {
            display: none;
        }
    }
`;

function NoticeListSkeleton(props) {
    return (
        <Wrap>
            <div className="skeleton-info">
                <div className="title-section">
                    <div className="skeleton-no" />
                    <div className="skeleton-title" />
                    <div className="skeleton-title" style={{ flex: 0.2 }} />
                </div>
            </div>
        </Wrap>
    );
}

export default NoticeListSkeleton;
