import React, {useEffect, useState, useCallback, useMemo} from "react";
import {Stage} from "react-konva";

import ClickPolygons from "./ClickPolygons";
import PolygonBaseImage from "./PolygonBaseImage";
import {Menu, Button} from "antd";
import useStore from "./Store";

import AnnotationModal from "../modal/AnnotationModal";

import {CustomText} from "../../styles/theme/CustomStyle";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useMutation} from "react-query";
import {UpdateAnnotation} from "../../services/labeling-services";
import AlertModal from "../modal/AlertModal";
import ConfirmModal from "../modal/ConfirmModal";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

const SettingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
`;

const ZoomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 10px;
    flex-direction: row;
    gap: 5px;
`;

function getRelativePointerPosition(node) {
    const transform = node.getAbsoluteTransform().copy();

    transform.invert();

    const pos = node.getStage().getPointerPosition();

    return transform.point(pos);
}

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${props => props.bc} !important;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

function limitAttributes(stage, newAttrs) {
    const box = stage.findOne("Image").getClientRect();
    const minX = -box.width + stage.width() / 2;
    const maxX = stage.width() / 2;

    const x = Math.max(minX, Math.min(newAttrs.x, maxX));

    const minY = -box.height + stage.height() / 2;
    const maxY = stage.height() / 2;

    const y = Math.max(minY, Math.min(newAttrs.y, maxY));

    const scale = Math.max(0.05, newAttrs.scale);

    return {x, y, scale};
}

function DrawClickPloygon({imageId, annotationData, image_url, datasetId}) {
    const {width, height} = useStore(s => ({
        width: s.width,
        height: s.height,
    }));

    const stageRef = React.useRef();
    const {Token, UserInfo, SelectedDataset} = useSelector(state => state);

    const IMAGE_URL = image_url;
    const image = useStore(state => state.image);
    const annotationList = useStore(state => state.annotationList);
    const setAnnotationList = useStore(state => state.setAnnotationList);

    const setSize = useStore(s => s.setSize);
    const scale = useStore(state => state.scale);
    const toggleDrawing = useStore(state => state.toggleIsDrawing);
    const annotationPk = useStore(state => state.annotationPk);

    const setIsDrag = useStore(state => state.setIsDrag);
    const isDrag = useStore(state => state.isDrag);

    const setIsInsert = useStore(state => state.setIsInsert);
    const [selectedLabelId, setDropDownSelected] = useState("");
    const [selectedLabelName, setSelectedLabelName] = useState("");
    const isOpen = useStore(s => s.isOpen);
    const setModalOpen = useStore(s => s.setModalOpen);

    const labelOptions = useStore(state => state.labelOptions);

    const polygons = useStore(state => state.polygons);
    const setPolygons = useStore(state => state.setPolygons);

    const selectedPolygonId = useStore(state => state.selectedPolygonId);
    const selectPolygon = useStore(state => state.selectPolygon);
    const updateAnnotation = useMutation(UpdateAnnotation);
    const polygonButtonClick = useStore(state => state.polygonButtonClick);
    const editPolygon = useStore(state => state.editPolygon);
    const setPolygonButtonClick = useStore(state => state.setPolygonButtonClick);
    const isMosaic = useStore(s => s.isMosaic);
    const setMosaics = useStore(s => s.setMosaics);
    const mosaics = useStore(s => s.mosaics);

    const isDrawing = useStore(state => state.isDrawing);
    const isEdit = useStore(state => state.isEdit);
    const [newAnnotation, setNewAnnotation] = useState([]);
    const [xaxis, setXaxis] = useState([0, 0, 0, 0]);
    const [yaxis, setYaxis] = useState([0, 0, 0, 0]);
    const [annotations, setAnnotations] = useState([]);
    const setIsEdit = useStore(state => state.setIsEdit);
    const setIsMosaic = useStore(state => state.setIsMosaic);
    const setEditPolygon = useStore(state => state.setEditPolygon);
    const labelModal = useStore(state => state.labelModal);
    const setLabelModal = useStore(state => state.setLabelModal);

    const isInsert = useStore(state => state.isInsert);
    const pointIndex = useStore(state => state.pointIndex);
    const setPointIndex = useStore(state => state.setPointIndex);

    const imageWidth = useStore(state => state.imageWidth);
    const imageHeight = useStore(state => state.imageHeight);

    const location = useLocation();
    const crntTab = queryString.parse(location.search).pg;

    const isLabelPage = useStore(state => state.isLabelPage);
    const setIsLabelPage = useStore(state => state.setIsLabelPage);
    const isInputIndex = useStore(state => state.isInputIndex);
    const [opacity, setOpacity] = useState(0.5);

    const [rectWidth, setRectWidth] = useState(0);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const zoomStage = (stage, scaleBy) => {
        if (isInputIndex === true) {
            return;
        }
        const oldScale = stage.scaleX();

        const pos = {
            x: stage.width() / 2,
            y: stage.height() / 2,
        };
        const mousePointTo = {
            x: pos.x / oldScale - stage.x() / oldScale,
            y: pos.y / oldScale - stage.y() / oldScale,
        };

        const newScale = Math.max(0.05, oldScale * scaleBy);

        const newPos = {
            x: -(mousePointTo.x - pos.x / newScale) * newScale,
            y: -(mousePointTo.y - pos.y / newScale) * newScale,
        };

        const newAttrs = limitAttributes(stage, {...newPos, scale: newScale});
        setRectWidth(prev => prev / scaleBy);
        stage.to({
            x: newAttrs.x,
            y: newAttrs.y,
            scaleX: newAttrs.scale,
            scaleY: newAttrs.scale,
            duration: 0.1,
        });
        if (polygonButtonClick === true) {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "pointer";
        }
    };

    const initStage = image => {
        if (image !== null) {
            const scale = Math.min(width / image.width, height / image.height);
            setRectWidth(5 * (image.width / width));
            stageRef.current.to({
                x: 0,
                y: 0,
                scaleX: scale,
                scaleY: scale,
                duration: 0.1,
            });
        }
    };

    const initPolygon = polygonList => {
        let id = 0;

        if (polygonList.length > 0) {
            id = polygonList.length - 1;
        } else {
            id = 0;
        }

        setPolygons(polygonList);
        selectPolygon(null);
    };

    const undoPolygon = (selectedPolygonId, polygonIndex) => {
        let temp = polygons;
        let polygonId = temp.findIndex(data => data.id === selectedPolygonId);
        temp[polygonId].points.splice(polygonIndex, 1);
        const flattenedPoints = temp[polygonId].points.reduce((a, b) => a.concat(b), []);
        temp[polygonId].flattenedPoints = flattenedPoints;
        setPolygons(temp.concat());
        setConfirmModalOpen({
            open: false,
            title: null,
            text: null,
            afterFunction: null,
        });
        setPointIndex("");
        return;
    };

    const addpolygon = () => {
        if (editPolygon === true) {
            return;
        }

        if (isMosaic === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }
        if (isInputIndex === true) {
            return;
        }
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "pointer";
        let id = 0;
        let temp = polygons;
        if (editPolygon === true) {
            if (selectedPolygonId !== null) {
                setAlertModalOpen({
                    open: true,
                    iconType: "warning",
                    title: "폴리곤 수정 저장 오류",
                    text: "저장 버튼을 누른후 그리기 버튼을 눌러 주세요",
                    afterFunction: null,
                });
                return;
            }
        }
        setLabelModal(false);
        setPolygonButtonClick(true);
        setIsMosaic(false);
        setEditPolygon(false);

        let index = temp.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            return;
        }

        if (selectedPolygonId !== null) {
            return;
        }

        if (polygons.length > 0) {
            temp.sort((a, b) => a.id - b.id);
            id = temp[temp.length - 1].id + 1;
        } else {
            id = 0;
        }

        const polygon = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: false,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        setPolygons(temp.concat([polygon]));
        selectPolygon(id);
    };

    const nextPolygon = polygons => {
        let id = 0;
        let index = polygons.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            return;
        }
        if (polygons.length > 0) {
            id = polygons[polygons.length - 1].id + 1;
        } else {
            id = 0;
        }

        const polygon = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: false,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        setPolygons(polygons.concat([polygon]));
        selectPolygon(id);
    };

    const editPoygon = () => {
        if (polygonButtonClick === true) {
            return;
        }

        if (isMosaic === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }
        if (isInputIndex === true) {
            return;
        }

        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";

        setLabelModal(false);
        setEditPolygon(true);
        setPolygonButtonClick(false);
        setIsMosaic(false);
        selectPolygon(null);

        let index = polygons.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            polygons.splice(index, 1);
            setPolygons(polygons.concat());
            selectPolygon(null);
            return;
        } else {
            return;
        }
    };

    const clickBoxMosaic = () => {
        if (polygonButtonClick === true) {
            return;
        }

        if (editPolygon === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }
        if (isInputIndex === true) {
            return;
        }

        if (SelectedDataset.data.annotation_type === "polygon") {
            toggleDrawing(false);
        }
        setLabelModal(false);
        setIsMosaic(true);
        setPolygonButtonClick(false);
        setEditPolygon(false);
    };

    const labelModalOpen = () => {
        if (polygonButtonClick === true) {
            return;
        }
        if (isInputIndex === true) {
            return;
        }

        if (editPolygon === true) {
            return;
        }

        if (isMosaic === true) {
            return;
        }

        setLabelModal(true);
        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsMosaic(false);
        setMosaics([]);
    };

    const getMousePos = stage => {
        return [stage.x, stage.y];
    };

    const handleMouseDown = event => {
        if (isDrag === true) {
            return;
        }

        if (isMosaic === false) {
            if (labelOptions.length < 1) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨 오류",
                    text: "등록된 라벨이 없습니다.\n라벨을 등록해주세요.",
                    afterFunction: null,
                });

                return;
            }

            const stage = getRelativePointerPosition(event.target.getStage());
            const mousePos = getMousePos(stage);

            if (
                mousePos[0] < 0 ||
                mousePos[1] < 0 ||
                imageWidth < mousePos[0] ||
                imageHeight < mousePos[1]
            ) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨링 영역 오류",
                    text: "라벨링 이미지 영역 밖입니다.",
                    afterFunction: null,
                });
                return;
            }
            //let selectedPolygonId = polygons.length - 1;
            let polygonId = polygons.findIndex(data => data.id === selectedPolygonId);

            if (polygonButtonClick === false) {
                return;
            }

            if (polygons.length === 0) {
                return;
            }

            if (selectedPolygonId === null) {
                return;
            }

            if (polygons[polygonId].isFinished) {
                return;
            }

            if (polygons.length === 0) {
                return;
            }

            if (selectedPolygonId === null) {
                return;
            }

            if (polygons[polygonId].isFinished) {
                return;
            }

            let temp = polygons;
            let temparr = [...temp[polygonId].points, mousePos];
            temp[polygonId].points = temparr;
            setPolygons(temp);
        } else {
            toggleDrawing(true);
            setYaxis([0, 0, 0, 0]);
            setXaxis([0, 0, 0, 0]);
            const point = getRelativePointerPosition(event.target.getStage());

            if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨링 영역 오류",
                    text: "라벨링 이미지 영역 밖입니다.",
                    afterFunction: null,
                });
                return;
            }

            const annotationToAdd = {x: point.x, y: point.y, width: 0, height: 0, key: "0"};
            setNewAnnotation([annotationToAdd]);
            let id = 0;

            if (mosaics.length > 0) {
                id = mosaics[mosaics.length - 1].id + 1;
            } else {
                id = 0;
            }
            let color = "";
            color = "white";
            const mosaic = {
                id: id++,
                points: annotationToAdd,
                color: color,
            };
            setMosaics(mosaics.concat([mosaic]));
        }
    };

    const handleMouseMove = event => {
        if (isMosaic === false) {
            const stage = getRelativePointerPosition(event.target.getStage());
            const mousePos = getMousePos(stage);
            let polygonId = polygons.findIndex(i => i.id == selectedPolygonId);
            let temp = polygons;
            let temparr = [mousePos];

            if (temp[polygonId] !== undefined) {
                temp[polygonId].curMousePos = temparr;
                const flattenedPoints = polygons[polygonId].points
                    .concat(polygons[polygonId].isFinished ? [] : polygons[polygonId].curMousePos)
                    .reduce((a, b) => a.concat(b), []);
                temp[polygonId].flattenedPoints = flattenedPoints;
            } else {
                return;
            }
            setPolygons(temp);
        } else {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "crosshair";
            const point = getRelativePointerPosition(event.target.getStage());
            if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
                setYaxis([0, 0, 0, 0]);
                setXaxis([0, 0, 0, 0]);

                return;
            }
            if (!isDrawing && scale != 1) {
                const container = document.querySelector("canvas");
                const point = getRelativePointerPosition(event.target.getStage());
                //alert(p.x);
                setYaxis([point.x, 0, point.x, imageHeight]);
                setXaxis([0, point.y, imageWidth, point.y]);
                return;
            }

            const sx = newAnnotation[0].x;
            const sy = newAnnotation[0].y;

            let lastRegion = {...mosaics[mosaics.length - 1]};

            const annotationToAdd = {
                x: sx,
                y: sy,
                width: point.x - sx,
                height: point.y - sy,
                annotation: "",
                key: annotations.length + 1,
            };

            setNewAnnotation([annotationToAdd]);
            lastRegion.points = annotationToAdd;
            mosaics.splice(mosaics.length - 1, 1);
            //selectRegion(null);
            setMosaics(mosaics.concat([lastRegion]));
        }
    };

    const handleMouseUp = event => {
        if (!isDrawing) {
            return;
        }

        if (isMosaic === false) {
            return;
        } else {
            const point = getRelativePointerPosition(event.target.getStage());
            if (point.x < 0 || point.y < 0 || imageWidth < point.x || imageHeight < point.y) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨링 영역 오류",
                    text: "라벨링 이미지 영역 밖입니다.",
                    afterFunction: null,
                });
                return;
            }
            const lastRegion = mosaics[mosaics.length - 1];

            if (
                Math.abs(lastRegion.points.width) === 0 &&
                Math.abs(lastRegion.points.height === 0)
            ) {
                mosaics.splice(mosaics.length - 1, 1);
                setMosaics(mosaics.concat());
                toggleDrawing(false);
                return;
            }

            if (Math.abs(lastRegion.points.width) < 4 && Math.abs(lastRegion.points.height < 4)) {
                mosaics.splice(mosaics.length - 1, 1);
                setMosaics(mosaics.concat());
                toggleDrawing(false);
                return;
            }
            toggleDrawing(false);
        }
    };

    const handleTouchStart = event => {
        const stage = getRelativePointerPosition(event.target.getStage());
        const mousePos = getMousePos(stage);

        if (
            mousePos[0] < 0 ||
            mousePos[1] < 0 ||
            imageWidth < mousePos[0] ||
            imageHeight < mousePos[1]
        ) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨링 영역 오류",
                text: "라벨링 이미지 영역 밖입니다.",
                afterFunction: null,
            });
            return;
        }

        if (isMosaic === false) {
            let polygonId = polygons.findIndex(i => i.id == selectedPolygonId);

            if (polygonButtonClick === false) {
                return;
            }

            if (polygons.length === 0) {
                return;
            }

            if (selectedPolygonId === null) {
                return;
            }

            if (polygons[polygonId].isFinished) {
                return;
            }

            if (polygons[polygonId].isMouseOverStartPoint) {
                let temp = polygons;
                let tempval = {...temp[polygonId], isFinished: true};
                temp[polygonId] = tempval;
                const flattenedPoints = polygons[polygonId].points
                    .concat(polygons[polygonId].isFinished ? [] : polygons[polygonId].curMousePos)
                    .reduce((a, b) => a.concat(b), []);
                temp[polygonId].flattenedPoints = flattenedPoints;
                setPolygons(temp);

                if (labelOptions.length < 1) {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "라벨 오류",
                        text: "등록된 라벨이 없습니다.\n라벨을 등록해주세요.",
                        afterFunction: null,
                    });

                    polygons.splice(polygonId, 1);
                    setAnnotationData(
                        polygons.concat().filter(data => data.isFinished === true),
                        false,
                        annotationPk
                    );
                    setPolygons(polygons.concat());
                    setModalOpen(false);
                    return;
                }
                setModalOpen(true);
            } else {
                let temp = polygons;

                let temparr = [...temp[polygonId].points, mousePos];
                temp[polygonId].points = temparr;
                const flattenedPoints = temp[polygonId].points.reduce((a, b) => a.concat(b), []);
                temp[polygonId].flattenedPoints = flattenedPoints;
                temp[polygonId].curMousePos = [mousePos];

                setPolygons(temp);
            }
        } else {
            if (isDrawing) {
                setModalOpen(true);
                return;
            }

            toggleDrawing(true);

            const point = getRelativePointerPosition(event.target.getStage());
            const annotationToAdd = {x: point.x, y: point.y, width: 0, height: 0, key: "0"};
            setNewAnnotation([annotationToAdd]);
            let id = 0;

            if (mosaics.length > 0) {
                id = mosaics[mosaics.length - 1].id + 1;
            } else {
                id = 0;
            }

            let color = "";
            color = "white";
            const mosaic = {
                id: id++,
                points: annotationToAdd,
                color: color,
            };

            setMosaics(mosaics.concat([mosaic]));
        }
    };

    const completePolygon = () => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";

        if (polygons.length > 0) {
            let selRegion = polygons.filter(data => data.id === selectedPolygonId);
            let index = polygons.findIndex(data => data.id === selectedPolygonId);

            if (selectedLabelId !== "") {
                let label_name = labelOptions.filter(data => data.key === selectedLabelId)[0].text;
                let color = labelOptions.filter(data => data.key === selectedLabelId)[0].color;

                let changePolygon = "";

                changePolygon = selRegion[0];
                changePolygon.color = color;
                changePolygon.annotation = label_name;
                polygons.splice(index, 1, changePolygon);

                setPolygons(polygons.concat());
                toggleDrawing(false);
            }
        }

        setAnnotationData(
            polygons.concat().filter(data => data.isFinished === true),
            false,
            annotationPk
        );

        let index = polygons.findIndex(data => data.isFinished === false);
        setModalOpen(false);
        setEditPolygon(false);
        setPolygonButtonClick(false);
        setIsMosaic(false);
        selectPolygon(null);
        setIsEdit(false);
        setIsInsert(false);

        if (index >= 0) {
            polygons.splice(index, 1);
            setPolygons(polygons.concat());

            return;
        } else {
            return;
        }
    };

    const enterKeyPress = () => {
        let polygonId = polygons.findIndex(i => i.id == selectedPolygonId);
        if (selectedPolygonId !== null) {
            let temp = polygons;
            if (temp[polygonId].points.length <= 2) {
                return;
            }

            let tempval = {...temp[polygonId], isFinished: true};
            temp[polygonId] = tempval;
            const flattenedPoints = temp[polygonId].points
                .concat(temp[polygonId].isFinished ? [] : temp[polygonId].curMousePos)
                .reduce((a, b) => a.concat(b), []);

            temp[polygonId].flattenedPoints = flattenedPoints;

            if (labelOptions.length < 1) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "라벨 오류",
                    text: "등록된 라벨이 없습니다.\n라벨을 등록해주세요.",
                    afterFunction: null,
                });
                polygons.splice(polygonId, 1);
                setAnnotationData(
                    polygons.concat().filter(data => data.isFinished === true),
                    false,
                    annotationPk
                );
                setPolygons(polygons.concat());
                setModalOpen(false);
                return;
            } else {
                setPolygons(temp);
                if (isInsert === false) {
                    setModalOpen(true);
                }
            }
        } else {
            return;
        }
    };

    const handleMouseOverStartPoint = event => {
        let selectedPolygonId = polygons.length - 1;
        if (polygons[selectedPolygonId].isFinished || polygons[selectedPolygonId].points.length < 3)
            return;

        event.target.scale({x: 1.3, y: 1.3});

        let temp = polygons;
        let tempobj = {
            ...temp[selectedPolygonId],
            isMouseOverStartPoint: true,
        };

        temp[selectedPolygonId] = tempobj;
        setPolygons(temp);
    };

    const handleMouseOutStartPoint = event => {
        let selectedPolygonId = polygons.length - 1;
        event.target.scale({x: 1, y: 1});

        let temp = polygons;
        let tempobj = {
            ...temp[selectedPolygonId],
            isMouseOverStartPoint: false,
        };
        temp[selectedPolygonId] = tempobj;
        setPolygons(temp);
    };

    const handleCancleClick = () => {
        let index = polygons.findIndex(data => data.id === selectedPolygonId);
        polygons.splice(index, 1);

        //setAnnotationData(
        //    polygons.concat().filter((data) => data.isFinished === true),
        //    true,
        //    annotationPk
        //);

        setPolygonButtonClick(false);
        setPolygons(polygons.concat());
        setModalOpen(false);
        selectPolygon(null);
    };

    const handleAnnotationStateChange = () => {
        if (selectedLabelId === "") {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "라벨 선택 오류",
                text: "선택된 라벨이 없습니다.\n라벨을 선택해주세요.",
                afterFunction: null,
            });
            setDropDownSelected("");
            return;
        }

        if (polygons.length > 0) {
            let selRegion = polygons.filter(data => data.id === selectedPolygonId);

            let index = polygons.findIndex(data => data.id === selectedPolygonId);

            let label_name = labelOptions.filter(data => data.key === selectedLabelId)[0].text;

            let color = labelOptions.filter(data => data.key === selectedLabelId)[0].color;

            let changePolygon = "";

            changePolygon = selRegion[0];
            changePolygon.color = color;
            changePolygon.annotation = label_name;

            polygons.splice(index, 1, changePolygon);

            setAnnotationData(
                polygons.concat().filter(data => data.isFinished === true),
                false,
                annotationPk
            );

            setPolygons(polygons.concat());
            toggleDrawing(false);
        }
    };

    const setAnnotationData = useCallback(
        (polygons, cancle, annotationPk) => {
            let annotationData = "";

            for (let i = 0; i < polygons.length; i++) {
                annotationData += labelOptions.filter(
                    data => data.text === polygons[i].annotation
                )[0].key;
                for (let j = 0; j < polygons[i].points.length; j++) {
                    annotationData +=
                        "," +
                        Math.floor(polygons[i].points[j][0] * 100) / 100 +
                        "/" +
                        Math.floor(polygons[i].points[j][1] * 100) / 100;
                }

                annotationData +=
                    "," +
                    Math.floor(polygons[i].points[0][0] * 100) / 100 +
                    "/" +
                    Math.floor(polygons[i].points[0][1] * 100) / 100;
                annotationData += "||";
            }

            const formData = new FormData();

            formData.append("update_user", UserInfo.data.id);
            formData.append("polygon_annotation_data", annotationData);

            updateAnnotation.mutateAsync(
                {token: Token.data.access, pk: parseInt(annotationPk), formData: formData},
                {
                    onSuccess: res => {
                        let index = annotationList.findIndex(data => data.id == annotationPk);
                        annotationList[index] = res.data;
                        setAnnotationList(annotationList);
                        setModalOpen(false);
                        selectPolygon(null);
                        if (cancle === false) {
                            nextPolygon(polygons);
                        }
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        },
        [annotationPk, labelOptions]
    );

    const getAnnotationData = (labelOptions, annotation) => {
        let id = 0;
        let annotationData = [];
        let annotationToAdd = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: true,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        let polygonList = [];
        let key = 0;
        let filterData = [];
        let annotationName = "";
        let color = "";

        if (annotation !== null) {
            annotationData = annotation.split("||");
        } else {
            initPolygon(polygonList);
            return;
        }

        if (labelOptions.length > 0) {
            for (let i = 0; i < annotationData.length - 1; i++) {
                annotationToAdd["id"] = id++;
                annotationToAdd["points"] = [];

                let parseData = annotationData[i].split(",");
                key = parseInt(parseData[0]);

                filterData = labelOptions.filter(data => data.key === key);
                annotationName = filterData[0].text;
                color = filterData[0].color;

                for (let j = 1; j < parseData.length - 1; j++) {
                    let point = [
                        Math.floor(parseData[j].split("/")[0] * 100) / 100,
                        Math.floor(parseData[j].split("/")[1] * 100) / 100,
                    ];

                    annotationToAdd["points"].push(point);
                }

                annotationToAdd["curMousePos"] = [
                    [
                        Math.floor(parseData[parseData.length - 1].split("/")[0] * 100) / 100,
                        Math.floor(parseData[parseData.length - 1].split("/")[1] * 100) / 100,
                    ],
                ];
                annotationToAdd["color"] = color;
                annotationToAdd["annotation"] = annotationName;

                annotationToAdd["flattenedPoints"] = annotationToAdd.points.reduce(
                    (a, b) => a.concat(b),
                    []
                );
                polygonList.push(annotationToAdd);

                annotationToAdd = {
                    points: [],
                    curMousePos: [0, 0],
                    isMouseOverStartPoint: false,
                    isFinished: true,
                    id: id,
                    flattenedPoints: [],
                    annotation: "",
                    color: "",
                };
            }
            initPolygon(polygonList);
        }
    };

    const handleMouseOut = event => {
        setYaxis([0, 0, 0, 0]);
        setXaxis([0, 0, 0, 0]);
    };

    //arrow key로 이미지를 넘길수 있게 입젠트 추가
    document.onkeyup = checkKey;

    function checkKey(e) {
        e = e || window.event;
        if (e.keyCode === 16) {
            setIsDrag(false);
        }
        if (polygonButtonClick === true) {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "pointer";
        } else if (editPolygon === true) {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "default";
        } else if (isMosaic === true) {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "crosshair";
        } else {
            let canvas = document.querySelector(".canvas");
            canvas.style.cursor = "default";
        }

        if (isLabelPage === true) {
            if (isOpen === true) {
                if (e.keyCode === 49) {
                    pressKeyLabel(0);
                } else if (e.keyCode === 50) {
                    pressKeyLabel(1);
                } else if (e.keyCode === 51) {
                    pressKeyLabel(2);
                } else if (e.keyCode === 52) {
                    pressKeyLabel(3);
                } else if (e.keyCode === 53) {
                    pressKeyLabel(4);
                } else if (e.keyCode === 54) {
                    pressKeyLabel(5);
                } else if (e.keyCode === 55) {
                    pressKeyLabel(6);
                } else if (e.keyCode === 56) {
                    pressKeyLabel(7);
                } else if (e.keyCode === 57) {
                    pressKeyLabel(8);
                } else if (e.keyCode === 48) {
                    pressKeyLabel(9);
                }
            } else {
                if (e.keyCode === 49) {
                    labelModalOpen();
                    return;
                } else if (e.keyCode === 50) {
                    clickBoxMosaic();
                    return;
                } else if (e.keyCode === 51) {
                    addpolygon();
                    return;
                } else if (e.keyCode === 52) {
                    editPoygon();
                    return;
                }
            }

            if (e.keyCode === 187) {
                zoomStage(stageRef.current, 1.2);
            }
            if (e.keyCode === 189) {
                zoomStage(stageRef.current, 0.8);
            }

            if (e.keyCode === 13) {
                if (polygonButtonClick === true) {
                    enterKeyPress();
                }

                if (isOpen === true && editPolygon === false) {
                    handleAnnotationStateChange();
                }

                if (pointIndex !== "" && isOpen === false) {
                    undoPolygon(selectedPolygonId, pointIndex);
                }
            }

            if (e.keyCode === 16) {
                setIsDrag(false);
                if (polygonButtonClick === true) {
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "pointer";
                } else if (editPolygon === true) {
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "default";
                } else if (isMosaic === true) {
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "crosshair";
                } else {
                    let canvas = document.querySelector(".canvas");
                    canvas.style.cursor = "default";
                }
            }
        } else {
            if (e.keyCode === 16) {
                setIsDrag(false);
            }
        }
        return;
    }

    const onLabelMenuClick = useCallback(
        e => {
            let label = labelOptions.filter(data => data.key === parseInt(e.key))[0];
            setDropDownSelected(label.key);
            setSelectedLabelName(label.text);
        },
        [labelOptions]
    );

    const pressKeyLabel = key => {
        let index = labelOptions.findIndex(data => data.key === key);
        if (index >= 0) {
            let label = labelOptions.filter(data => data.key === parseInt(key))[0];
            setDropDownSelected(label.key);
            setSelectedLabelName(label.text);
        }
    };

    const labelItems = useMemo(
        () => (
            <StyledMenu onClick={onLabelMenuClick}>
                {labelOptions.map(label => {
                    return (
                        <Menu.Item className="menu-item" key={label.key}>
                            <CustomText ff="Pretendard" fw="400" fs="0.8rem" cl="#fff">
                                {label.text}
                            </CustomText>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
        ),
        [labelOptions]
    );

    const isCancleClick = () => {
        if (selectPolygon === null) {
            polygons.splice(polygons.length - 1, 1);
            setAnnotationData(
                polygons.concat().filter(data => data.isFinished === false),
                true,
                annotationPk
            );
            setPolygons(polygons.concat());
            setModalOpen(false);
            toggleDrawing(false);
        } else {
            setModalOpen(false);
            toggleDrawing(false);
            selectPolygon(null);
        }
    };

    const movePolygon = () => {
        if (polygons.length > 0) {
            let selRegion = polygons.filter(data => data.id === selectedPolygonId);
            let index = polygons.findIndex(data => data.id === selectedPolygonId);

            if (selectedLabelId !== "") {
                let label_name = labelOptions.filter(data => data.key === selectedLabelId)[0].text;
                let color = labelOptions.filter(data => data.key === selectedLabelId)[0].color;

                let changePolygon = "";

                changePolygon = selRegion[0];
                changePolygon.color = color;
                changePolygon.annotation = label_name;
                polygons.splice(index, 1, changePolygon);

                setPolygons(polygons.concat());
                toggleDrawing(false);
            }
        }
        setIsEdit(true);
        setModalOpen(false);
    };

    useEffect(() => {
        function checkSize() {
            const container = document.querySelector(".right-panel");
            setSize({
                width: container.offsetWidth,
                height,
            });
        }

        checkSize();
        window.addEventListener("resize", checkSize);

        return () => window.removeEventListener("resize", checkSize);
    }, [imageId]);

    useEffect(() => {
        if (crntTab === "labeling") {
            setIsLabelPage(true);
        }
        return () => {
            setIsLabelPage(false);
            document.onkeyup = checkKey;
        };
    }, [crntTab]);

    return (
        <>
            <SettingContainer>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        paddingBottom: "10px",
                    }}>
                    <CustomText cl="#FFFFFF">라벨 투명도 조절</CustomText>
                    <input
                        id="slider"
                        type="range"
                        min="0.15"
                        max="1"
                        step="0.05"
                        defaultValue="0.5"
                        onChange={e => {
                            setOpacity(parseFloat(e.target.value));
                        }}></input>
                </div>

                <ZoomContainer>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{cursor: "zoom-in", paddingLeft: "10px", paddingRight: "10px"}}
                        onClick={() => {
                            zoomStage(stageRef.current, 1.2);
                        }}>
                        <CustomText cl="#FFFFFF">+</CustomText>
                    </CustomBtn>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{paddingLeft: "12px", paddingRight: "12px", cursor: "zoom-out"}}
                        onClick={() => {
                            zoomStage(stageRef.current, 0.8);
                        }}>
                        <CustomText cl="#FFFFFF">-</CustomText>
                    </CustomBtn>
                    <CustomBtn
                        bc="#b1b1b1"
                        style={{paddingLeft: "8px", paddingRight: "8px", cursor: "pointer"}}
                        onClick={() => {
                            initStage(image);
                        }}>
                        <CustomText cl="#FFFFFF">reset</CustomText>
                    </CustomBtn>
                </ZoomContainer>
            </SettingContainer>

            <Stage
                style={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
                ref={stageRef}
                width={width}
                height={height}
                className="canvas"
                scaleX={scale}
                scaleY={scale}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchmove={handleMouseMove}
                onDragMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                draggable={isDrag}
                opacity={1}>
                <PolygonBaseImage
                    image_url={IMAGE_URL}
                    branchId={datasetId}
                    annotationData={annotationData}
                    getAnnotationData={getAnnotationData}
                    initStage={initStage}
                    setRectWidth={setRectWidth}
                />
                {image !== null && (
                    <ClickPolygons
                        isEdit={isEdit}
                        annotationPk={annotationPk}
                        xaxis={xaxis}
                        yaxis={yaxis}
                        handleMouseOverStartPoint={handleMouseOverStartPoint}
                        handleMouseOutStartPoint={handleMouseOutStartPoint}
                        setAnnotationData={setAnnotationData}
                        setModalOpen={setModalOpen}
                        polygonButtonClick={polygonButtonClick}
                        editPolygon={editPolygon}
                        setAlertModalOpen={setAlertModalOpen}
                        isInsert={isInsert}
                        undoPolygon={undoPolygon}
                        setConfirmModalOpen={setConfirmModalOpen}
                        setSelectedLabelName={setSelectedLabelName}
                        setDropDownSelected={setDropDownSelected}
                        rectWidth={rectWidth}
                        opacity={opacity}
                    />
                )}
            </Stage>

            {labelOptions !== undefined && (
                <AnnotationModal
                    isOpen={isOpen}
                    labelItems={labelItems}
                    selectedLabelName={selectedLabelName}
                    handleAnnotationStateChange={handleAnnotationStateChange}
                    handleCancleClick={handleCancleClick}
                    isCancleClick={isCancleClick}
                    movePolygon={movePolygon}
                    setModalOpen={setModalOpen}
                    annotationType={"polygon"}
                    completePolygon={completePolygon}
                />
            )}
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
            <ConfirmModal
                confirmModalOpen={confirmModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
            />
        </>
    );
}

export default DrawClickPloygon;
