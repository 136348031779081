import axios from "axios";

export const GetIssue = (id, page, title, token) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/board/issue/${id}/${title}?page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetIssueDetail = (id, token) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/board/issue/${id}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const GetComment = (issue_id, page, token) => {
    return axios.get(
        process.env.REACT_APP_END_POINT + `/api/board/comment/${issue_id}?page=${page}`
        // {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     },
        // }
    );
};

export const RegistrationComment = (formData, token) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/board/comment", formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const RegistrationIssue = (formData, token) => {
    return axios.post(process.env.REACT_APP_END_POINT + "/api/board/issue", formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateIssue = (id, formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/board/issue/edit/${id}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const UpdateComment = (id, formData, token) => {
    return axios.put(process.env.REACT_APP_END_POINT + `/api/board/comment/edit/${id}`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const CreateNotice = (formData, token) => {
    return axios.post(process.env.REACT_APP_END_POINT + `/api/board/notice/add`, formData, {
        headers: {
            Authorization: `JWT ${token}`,
        },
    });
};

export const GetNoticeListApi = (page) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/board/notice/list?page=${page}`);
};

export const GetNoticeDetailApi = (id) => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/board/notice/${id}`);
};

export const GetMainNoticeListApi = () => {
    return axios.get(process.env.REACT_APP_END_POINT + `/api/board/notice`);
};
