import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Rate } from "antd";
import styled from "styled-components";
import Lottie from "react-lottie";

import * as successLottie from "../../assets/modal/success-lottie.json";
import { ReactComponent as ModelGradeIcon } from "../../assets/modal/model-grade-icon.svg";
import { CustomText } from "../../styles/theme/CustomStyle";
import { useMutation, useQuery } from "react-query";
import { CreateTrainedModelGradeApi, GetTrainedModelGradeApi, UpdateTrainedModelGradeApi } from "../../services/train-services";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const CustomBtn = styled(Button)`
    margin-top: 2.5rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    /* flex: 1; */

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;

    border-color: ${(props) => props.bc} !important;
`;

Modal.setAppElement("#root");

function ModelGradeModal({ modalIsOpen, setIsOpen, refetch }) {
    const { UserInfo } = useSelector((state) => state);

    const [crntModelGrade, setCrntModelGrade] = useState(null);
    const [rateValue, setRateValue] = useState(0);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    function closeModal() {
        setIsOpen({
            open: false,
            userId: null,
            modelId: null,
        });
        setRateValue(0);
        setCrntModelGrade(null);
    }

    useEffect(() => {
        if (modalIsOpen.userId !== null) {
            getTrainedModelGrade(modalIsOpen.modelId);
        } else {
            setCrntModelGrade(null);
        }
    }, [modalIsOpen]);

    const getTrainedModelGrade = useCallback(
        async (modelId) => {
            try {
                const { data: data } = await GetTrainedModelGradeApi(modelId, UserInfo.data.id);
                setRateValue(parseFloat(data.grade));
                setCrntModelGrade(data);
            } catch (e) {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        },
        [UserInfo]
    );

    const updateTrainedModelGrade = useCallback(async () => {
        if (crntModelGrade !== null) {
            try {
                const formData = new FormData();
                formData.append("grade", rateValue);

                const { data: data } = await UpdateTrainedModelGradeApi(crntModelGrade.id, formData);
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "별점 부여 완료",
                    text: "별점 부여가 완료되었습니다.",
                    afterFunction: () => {
                        closeModal();
                    },
                });
                refetch();
            } catch (e) {
                console.log("error =>", e);
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("trained_model", modalIsOpen.modelId);
                formData.append("user", UserInfo.data.id);
                formData.append("grade", rateValue);

                const { data: data } = await CreateTrainedModelGradeApi(formData);
                setAlertModalOpen({
                    open: true,
                    iconType: "success",
                    title: "별점 부여 완료",
                    text: "별점 부여가 완료되었습니다.",
                    afterFunction: () => {
                        refetch();
                        closeModal();
                    },
                });
            } catch (e) {
                console.log("error =>", e);
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        }
    }, [UserInfo, crntModelGrade, rateValue, refetch]);

    return (
        <Modal isOpen={modalIsOpen.open} onRequestClose={closeModal} style={customStyles} contentLabel="Model Grade">
            <ModelGradeIcon width="2rem" height="2rem" style={{ marginBottom: "0.8rem" }} />
            <CustomText fs="1.125rem" fw="600" mg="0 0 0.8rem 0">
                학습 모델 별점 부여
            </CustomText>
            <CustomText fs="0.875rem" cl="#d4d4d4" ta="center" mg="0 0 1rem 0">
                해당 학습 모델 데이터가 도움이 됐다면
                <br />
                별점을 남겨주세요.
            </CustomText>

            <Rate allowHalf value={rateValue} onChange={(e) => setRateValue(e)} />

            <CustomBtn bc={rateValue === 0 ? "#b2b1b1" : "#e2ff9b"} onClick={updateTrainedModelGrade} disabled={rateValue === 0}>
                <CustomText fs="0.9rem" cl={rateValue === 0 ? "#b2b1b1" : "#e2ff9b"}>
                    확인
                </CustomText>
            </CustomBtn>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Modal>
    );
}

export default ModelGradeModal;
