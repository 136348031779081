import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        width: 45;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function ProjectGuide() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>

                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/getting-started">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;시작하기&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;프로젝트 생성</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    프로젝트 생성
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    프로젝트 페이지로 이동 후 프로젝트 생성 버튼을 클릭합니다.
                    <br />
                    <br />
                    학습을 진행할 프로젝트의 이름과 처음 생성할 데이터셋 이름을 입력 후 프로젝트를 생성합니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <video width="100%" height="100%" controls muted playsInline autoPlay={false} style={{ borderRadius: 10, maxWidth: 1000, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                    <source src="/videos/main/create_project.mp4" type="video/mp4" />
                </video>
            </div>
            <Link to="/document/getting-started?step=2">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        다음 단계
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default ProjectGuide;
