import React from "react";
import styled from "styled-components";
import {Button} from "antd";
import {ArrowRightOutlined, GithubOutlined} from "@ant-design/icons";
import {CustomText} from "../../../styles/theme/CustomStyle";
import {CustomSpan} from "../main";
import {ReactComponent as YOLOv7SVG} from "../../../assets/document/yolov7.svg";
import {Link} from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 110px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    .ant-btn-primary {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function YOLOv7() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{cursor: "pointer"}}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{cursor: "pointer"}}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;YOLOv7</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    YOLOv7
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    YOLOv7은 WongKinYiu와 AlexeyAB에 의해 2022년 7월에 출시되었습니다.
                    <br />
                    <br />
                    YOLOv7은 속도와 정확도 측면에서 현재까지 나온 모든 Object Detector의 성능을
                    능가한다고 합니다.
                    <br />
                    <br />
                    실시간 개체 감지를 위해 MS COCO 데이터 세트에서 훈련된 알고리즘 중 V100 GPU에서
                    30 FPS 이상의 현재까지 알려진 Real-time Detector 중 가장 높은 정확도인 56.8%
                    AP를 달성한다고 합니다.
                    <br />
                    <br />
                    YOLOv7-E6는 Transformer 기반 검출기인 SWIN-L Cascade-Mask R-CNN 보다 속도는
                    509%, 정확도 2%를 능가하며, Convolution 기반 검출기인 ConvNeXt-XL Cascade-Mask
                    R-CNN 보다 속도는 551%, 정확도는 0.7% 앞선다고 합니다.
                    <br />
                    <br />
                    NewLearn에서는 YOLOv7를 커스텀으로 훈련할수 있는{" "}
                    <a
                        href="https://github.com/viasofts/newlearn-algorithm/tree/YOLOv7"
                        target="_blank">
                        소스코드
                    </a>
                    를 제공 합니다.
                    <br />
                    <br />
                    YOLOv7 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                </CustomSpan>

                <br />
            </div>
            <div className="image-section">
                <YOLOv7SVG
                    style={{width: "60%", height: "auto", borderRadius: 10, cursor: "pointer"}}
                />
            </div>

            <div className="button-section">
                <a href="https://github.com/WongKinYiu/yolov7" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined
                                style={{color: "#fff", fontSize: "1rem", marginRight: "10px"}}
                            />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/document/algorithm-guide?algorithm=5">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            Mask-RCNN
                            <ArrowRightOutlined
                                style={{color: "#fff", fontSize: "1rem", marginLeft: "10px"}}
                            />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default YOLOv7;
