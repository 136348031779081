import React, {useCallback, useState} from "react";
import styled, {css} from "styled-components";
import {Dropdown, Button} from "antd";
import {ReactComponent as LabelIcon} from "../../assets/modal/label-modal-icon.svg";

import {CustomText} from "../../styles/theme/CustomStyle";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";
import useStore from "../../components/annotator/Store";
import Modal from "react-modal";

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 5px;
    height: auto;
    color: #e2ff9b !important;

    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    width: 100%;

    border-color: ${props => props.bc} !important;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #575757;
    width: 100%;
`;

const DrawWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 21px;
    padding-top: 1rem;
    width: 100%;
`;

const CompleteWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 21px;
    padding-top: 1rem;
    width: 100%;
`;

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        zIndex: 1,
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#21272E",
        border: "1px solid #707070",
        borderRadius: 5,
        width: "90%",
        maxWidth: 450,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justiryContent: "center",
        padding: "2.3rem",
    },
};

const StyledDropDown = styled(Dropdown)`
    background-color: #303a45;
    border-radius: 3px;
    width: 100%;
    margin-top: 10px;
    border: none;
    /* width: 100%; */
    padding: 0.7rem 1rem 0.7rem 0rem;
    flex: 1;
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    cursor: pointer;
`;

function AnnotationModal({
    isOpen,
    labelItems,
    selectedLabelName,
    handleAnnotationStateChange,
    handleCancleClick,
    movePolygon,
    setModalOpen,
    annotationType,
    completePolygon,
}) {
    const editPolygon = useStore(state => state.editPolygon);
    const selectPolygon = useStore(state => state.selectPolygon);
    const selectRegion = useStore(state => state.selectRegion);
    const selectedPolygonId = useStore(state => state.selectedPolygonId);
    const polygons = useStore(state => state.polygons);
    const setPolygons = useStore(state => state.setPolygons);
    const [buttonColor, setButtonColor] = useState("#b1b1b1");
    const [cancleColor, setCancleColor] = useState("#b1b1b1");
    const [completColor, setCompleteColor] = useState("#b1b1b1");
    const cancelConfirmModal = useCallback(() => {
        setModalOpen(false);

        if (annotationType === "polygon") {
            if (editPolygon === true) {
                selectPolygon(null);
            } else {
                let polygonId = polygons.findIndex(i => i.id == selectedPolygonId);
                let temp = polygons;
                if (temp.length > 0) {
                    let index = temp[polygonId].points.length - 1;
                    temp[polygonId].points.splice(index, 1);
                    temp[polygonId].isFinished = false;
                    setPolygons(temp);
                }
            }
        } else {
            selectRegion(null);
        }
    }, [isOpen]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={cancelConfirmModal}
                style={customStyles}
                contentLabel="Alert Modal">
                <div style={{color: "#fff", textAlign: "center", marginBottom: "15px"}}>
                    <LabelIcon />
                </div>
                <CustomText fs="1.1rem" wd="100%" fw="600" ta="center" mg="0 0 5px 0">
                    라벨 선택
                </CustomText>
                <CustomText fs="0.9rem" wd="100%" fw="400" ta="center" cl="#D4D4D4" mg="0 0 5px 0">
                    라벨 선택 후 라벨링이 가능합니다.
                </CustomText>

                <StyledDropDown
                    overlay={labelItems}
                    placement="bottomCenter"
                    trigger={["click"]}
                    overlayStyle={{overflowY: "scroll", maxHeight: 250}}>
                    <MenuItem>
                        {selectedLabelName === "" && (
                            <CustomText
                                ff="Pretendard"
                                fw="400"
                                fs="0.9rem"
                                cl="#FFFFFF"
                                ta="center"
                                style={{flex: 1}}>
                                라벨 선택
                            </CustomText>
                        )}
                        {selectedLabelName !== "" && (
                            <CustomText
                                ff="Pretendard"
                                fw="400"
                                fs="0.9rem"
                                cl="#FFFFFF"
                                ta="center"
                                wd="25%"
                                style={{flex: 1}}>
                                {selectedLabelName}
                            </CustomText>
                        )}
                        <DownOutlined style={{color: "#fff", fontSize: "0.6rem"}} />
                    </MenuItem>
                </StyledDropDown>

                {editPolygon === false && (
                    <DrawWrapper>
                        <CustomBtn
                            bc={completColor}
                            className="complete"
                            onClick={handleAnnotationStateChange}
                            onMouseOver={() => setCompleteColor("#b1b1b1")}
                            onMouseOut={() => setCompleteColor("#b1b1b1")}>
                            <CustomText
                                fs="0.9rem"
                                fw="400"
                                wd="100%"
                                ta="center"
                                cl={completColor}>
                                완료
                            </CustomText>
                        </CustomBtn>
                        <CustomBtn
                            bc={cancleColor}
                            className="delete"
                            onClick={handleCancleClick}
                            onMouseOver={() => setCancleColor("#b1b1b1")}
                            onMouseOut={() => setCancleColor("#b1b1b1")}>
                            <CustomText fs="0.9rem" fw="400" wd="100%" ta="center" cl={cancleColor}>
                                삭제
                            </CustomText>
                        </CustomBtn>
                    </DrawWrapper>
                )}
                {editPolygon === true && (
                    <>
                        <BtnWrapper>
                            <CustomBtn
                                bc={completColor}
                                className="delete"
                                onClick={movePolygon}
                                onMouseOver={() => setCompleteColor("#b1b1b1")}
                                onMouseOut={() => setCompleteColor("#b1b1b1")}>
                                <CustomText
                                    fs="0.9rem"
                                    fw="400"
                                    wd="100%"
                                    ta="center"
                                    cl={completColor}>
                                    폴리곤 수정
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn
                                bc={cancleColor}
                                className="complete"
                                onClick={handleCancleClick}
                                onMouseOver={() => setCancleColor("#b1b1b1")}
                                onMouseOut={() => setCancleColor("#b1b1b1")}>
                                <CustomText
                                    fs="0.9rem"
                                    fw="400"
                                    wd="100%"
                                    ta="center"
                                    cl={cancleColor}>
                                    삭제
                                </CustomText>
                            </CustomBtn>
                        </BtnWrapper>
                        <CompleteWrapper>
                            <CustomBtn
                                bc={buttonColor}
                                className="complete"
                                onClick={completePolygon}
                                onMouseOver={() => setButtonColor("#b1b1b1")}
                                onMouseOut={() => setButtonColor("#b1b1b1")}>
                                <CustomText
                                    fs="0.9rem"
                                    fw="400"
                                    wd="100%"
                                    ta="center"
                                    cl={buttonColor}>
                                    완료
                                </CustomText>
                            </CustomBtn>
                        </CompleteWrapper>
                    </>
                )}
            </Modal>
        </>
    );
}

export default AnnotationModal;
