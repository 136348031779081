import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";
const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function DataPreprocessingGuide() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>

                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/getting-started">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;시작하기&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;데이터 전처리</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    데이터 전처리
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    데이터셋 탭의 전처리 버튼을 클릭하여 데이터 전처리를 진행할 수 있습니다.
                    <br />
                    <br />
                    NewLearn에서 지원하는 데이터 전처리는 다음과 같습니다.
                    <br />
                    <li style={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>회전</li>
                    <li style={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>축소</li>
                    <li style={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>확대</li>
                    <li style={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>GrayScale</li>
                    <li style={{ color: "#FFFFFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>자르기</li>
                    <br />
                    이미지 선택 후 전처리 기법을 적용한뒤 데이터를 저장합니다
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <video width="100%" height="100%" controls muted playsInline autoPlay={false} style={{ borderRadius: 10, maxWidth: 1000, border: "1px solid rgba(255,255,255,0.1)", overflow: "hidden" }}>
                    <source src="/videos/main/main_preprocessing.mp4" type="video/mp4" />
                </video>
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF" pd="20px 0px 0px 0px">
                    전처리 완료된 데이터를 확인합니다.
                    <br />
                </CustomSpan>
            </div>
            <Link to="/document/getting-started?step=4">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        다음 단계
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default DataPreprocessingGuide;
