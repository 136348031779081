import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

import { CustomText } from "../../styles/theme/CustomStyle";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 85%;
        margin-top: 40px;
        padding: 10px;
    }
    .menu-section {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 80px;
        margin-bottom: 100px;
        padding: 10px;
    }

    .menu-items {
        display: flex;
        flex-direction: column;
    }

    .second-items {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1450px) {
            margin-left: 0;
            margin-top: 80px;
        }
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 20px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

export const CustomSpan = styled.span`
    letter-spacing: 0em;

    width: ${(props) => (props.wd ? props.wd : "fit-content")};
    text-align: ${(props) => (props.ta ? props.ta : "left")};

    color: ${(props) => (props.cl ? props.cl : "#fff")};
    font-family: ${(props) => (props.ff ? props.ff : "Roboto")};
    font-size: ${(props) => (props.fs ? props.fs : "1rem")};
    font-weight: ${(props) => (props.fw ? props.fw : 500)};
    line-height: ${(props) => props.lh};
    margin: ${(props) => (props.mg ? props.mg : 0)};
    display: ${(props) => (props.dp ? props.dp : "inline")};
    padding: ${(props) => (props.pd ? props.pd : "0px 0px 0px 0px")};

    span {
        width: calc(fit-content);
        padding-bottom: 5px;
    }

    .selected {
        background-size: 100% 100%;
    }
`;

function DocumentationMain() {
    return (
        <Wrap>
            <div className="top-section">
                <CustomSpan cl="#58A6FF">가이드&nbsp;</CustomSpan>
                <CustomSpan>{">>"}</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF">
                    NewLearn 가이드
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    NewLearn은 인공지능 공유 학습을 위한 플랫폼을 갖춘 서비스입니다.
                    <br />
                    <br />
                    NewLearn은 친숙한 워크플로를 바탕으로 인공지능 학습을 위한 데이터 축적, 전처리, 라벨링, 버전관리 및 검증을 관리하고 실행되는 인프라를 관리 하는 더 나은 방법을 제공하는 것을 목표로합니다.
                    <br />
                    <br />
                    NewLearn에 공유된 학습데이터 및 학습 모델은 데이터 내보내기를 통해 개인 로컬 저장소에 저장할 수 있으며 개인 로컬 저장소에서 작업한 데이터 또한 업로드 가능 합니다
                    <br />
                    <br />
                    프로젝트 안에서 데이터셋을 여러 가닥으로 나누고 학습을 진행하여 업로드된 이미지의 상태에 따라 다양한 학습 모델을 생성할 수 있습니다.
                </CustomSpan>
            </div>
            <div className="menu-section">
                <div className="menu-items">
                    <CustomSpan fs="1.5rem" cl="#FFFFFF">
                        시작하기
                    </CustomSpan>
                    <CustomSpan fs="1rem" cl="#FFFFFF" pd="10px 0px 0px 0px">
                        처음 시작하는 분들을 위한 실습 소개입니다.
                    </CustomSpan>
                    <Link to="/document/getting-started">
                        <StyledButton>
                            <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                                시작 하기
                                <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                            </CustomText>
                        </StyledButton>
                    </Link>
                </div>
                <div className="second-items">
                    <CustomSpan fs="1.5rem" cl="#FFFFFF">
                        기능 및 용어 안내
                    </CustomSpan>
                    <CustomSpan fs="1rem" cl="#FFFFFF" pd="10px 0px 0px 0px">
                        NewLearn에서 이용할 수 있는 기능과 용어에 대한 설명입니다.
                    </CustomSpan>
                    <Link to="/document/terminology-guide">
                        <StyledButton>
                            <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                                기능 및 용어 안내
                                <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                            </CustomText>
                        </StyledButton>
                    </Link>
                </div>
            </div>
            <div className="menu-section">
                <div className="menu-items">
                    <CustomSpan fs="1.5rem" cl="#FFFFFF">
                        알고리즘 안내
                    </CustomSpan>
                    <CustomSpan fs="1rem" cl="#FFFFFF" pd="10px 0px 0px 0px">
                        학슴할때 사용되는 알고리즘에 대한 설명서입니다.
                    </CustomSpan>
                    <Link to="/document/algorithm-guide">
                        <StyledButton>
                            <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                                알고리즘 안내
                                <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                            </CustomText>
                        </StyledButton>
                    </Link>
                </div>
            </div>
            <div className="menu-section">
                <div className="menu-items">
                    <CustomSpan fs="1.5rem" cl="#FFFFFF">
                        문의하기
                    </CustomSpan>
                    <CustomSpan fs="1rem" cl="#FFFFFF" pd="10px 0px 0px 0px">
                        플랫폼이 요구 사항에 적합한지 여부를 결정하기 위해 NewLearn과 그 기능에 대해 더 많이 알아야 하나요? <br />
                        아래 메일로 궁금한 점을 남겨주세요.
                    </CustomSpan>
                    <CopyToClipboard text="newlearn@viasoft.ai">
                        <StyledButton>
                            <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                                newlearn@viasoft.ai &nbsp;<span style={{ fontSize: "0.9rem" }}>복사</span>
                            </CustomText>
                        </StyledButton>
                    </CopyToClipboard>
                </div>
            </div>
        </Wrap>
    );
}

export default DocumentationMain;
