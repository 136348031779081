import React, { useState, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import { ReactComponent as InputIcon } from "../../assets/signup/input.svg";
import { ReactComponent as CheckIcon } from "../../assets/signup/check.svg";
import { Button } from "antd";

import { CustomText } from "../../styles/theme/CustomStyle";
import { EditPassword } from "../../services/account-services";
import AlertModal from "../../components/modal/AlertModal";
import { useNavigate } from "react-router-dom";

// const { Option } = Select;

const Wrap = styled.div`
    min-height: inherit;
    background-color: #0c1117;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    /* justify-content: center; */
    padding-bottom: 10rem;
`;

const SignUpBox = styled.div`
    background-color: #21272e;
    width: 30rem;
    padding: 20px 30px 30px 30px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 40px;
`;

const InputBox = styled.div`
    background-color: #21272e;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #b1b1b1;

    .prefix {
        width: 10%;
    }

    input {
        width: 90%;
        background-color: #21272e;
        padding: 5px 10px;
        border: none;
        color: #fff;
        font-size: 1.05rem;
        font-family: Pretendard;
        font-weight: 400;

        :focus {
            outline: none !important;
            animation-name: border-focus;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        @keyframes border-focus {
            from {
                border: none;
            }
            to {
                border: none;
            }
        }
    }
`;

const CompleteButton = styled(Button)`
    background-color: #161b22 !important;
    margin-top: 1.2rem;
    border-radius: 5px;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.5rem;
    border-color: #70726e !important;
    color: #e2ff9b !important;
`;

function PasswordSearch() {
    const emailRef = React.useRef(null);
    const idRef = React.useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    let emailPattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;

    const [inputs, setInputs] = useState({
        id: "",
        email: "",
    });

    const InputsChange = useCallback(
        (e) => {
            const { name, value } = e.target;

            setInputs((prev) => ({
                ...prev,
                [name]: value,
            }));
        },
        [inputs]
    );

    useEffect(() => {
        idRef.current.focus();
    }, []);

    const onKeyPress = useCallback((e) => {
        if (e.key === "Enter") {
            if (e.target.name === "id") {
                emailRef.current.focus();
            } else if (e.target.name === "email") {
                changePassword();
            }
        }
    });

    const changePassword = useCallback(async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();

            formData.append("login_id", inputs.id);
            formData.append("email", inputs.email);

            const data = await EditPassword(formData);

            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "임시 비밀번호 발급 완료",
                text: "임시 비밀번호 발급이 완료되었습니다.\n이메일을 확인해주세요.",
                afterFunction: () => {
                    navigate("/login");
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "임시 비밀번호 발급 오류",
                text: "임시 비밀번호 발급에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: () => {
                    setInputs({ id: "", email: "" });
                },
            });
        }
        setIsLoading(false);
    });

    return (
        <HomeLayout headerShown={false} signUpHeader={true}>
            <Wrap>
                <CustomText fw={500} fs="1.7rem">
                    비밀번호 찾기
                </CustomText>
                <CustomText fs="1.3rem" cl="#E1E1E1" ta="center" mg="10px 0 0 0" style={{ whiteSpace: "pre-wrap", lineHeight: "1.6rem" }}>
                    아래의 정보를 입력하시면 입력하신 {"\n"}이메일로 임시 비밀번호를 발급해드립니다.
                </CustomText>
                <div>
                    <SignUpBox>
                        <CustomText fs="1rem" mg="0px 0px 0px 0px" cl="#D4D4D4">
                            아이디
                        </CustomText>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <InputBox>
                                {inputs.id === "" ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                <input ref={idRef} name="id" value={inputs.id} onChange={InputsChange} onKeyPress={onKeyPress} autoComplete="off" autoFocus />
                            </InputBox>
                        </div>
                        <div style={{ paddingTop: "30px" }}>
                            <CustomText fs="1rem" mg="0px 0px 0px 0px" cl="#D4D4D4">
                                이메일 (회원가입에 사용한 이메일을 입력해주세요)
                            </CustomText>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <InputBox>
                                    {(inputs.email === "") | (emailPattern.test(inputs.email) === false) ? <InputIcon className="prefix" /> : <CheckIcon className="prefix" />}
                                    <input ref={emailRef} name="email" value={inputs.email} onChange={InputsChange} onKeyPress={onKeyPress} autoComplete="off" autoFocus />
                                </InputBox>
                            </div>
                        </div>
                    </SignUpBox>
                </div>
                <CompleteButton onClick={changePassword} disabled={(inputs.id === "") | (inputs.email === "") | (emailPattern.test(inputs.email) === false) ? true : false} loading={isLoading}>
                    <CustomText fs="1.25rem" cl={(inputs.id === "") | (inputs.email === "") | (emailPattern.test(inputs.email) === false) ? "#B1B1B1" : "#E2FF9B"} className="btn-text">
                        임시 비밀번호 발급
                    </CustomText>
                </CompleteButton>
            </Wrap>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </HomeLayout>
    );
}

export default PasswordSearch;
