import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import HomeLayout from "../../components/layout/HomeLayout";
import DocumentationMain from "../../components/document/main";
import GuideLayout from "../../components/layout/GuideLayout";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    min-height: inherit;
`;

const ContentWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #0c1117;
`;

function Documentatioin() {
    const { name } = useParams();

    return (
        <HomeLayout>
            <Wrap>
                <GuideLayout />
                <ContentWrap>
                    <DocumentationMain />
                </ContentWrap>
            </Wrap>
        </HomeLayout>
    );
}

export default Documentatioin;
