import React, {useEffect, useState, useCallback} from "react";
import {Layout} from "antd";
import styled from "styled-components";
import {CustomText} from "../../../styles/theme/CustomStyle";
import {Button, Input, Form} from "antd";
import {ReactComponent as LabelIcon} from "../../../assets/project/label-icon.svg";
import {RightOutlined, LeftOutlined} from "@ant-design/icons";
import CreatLabelModal from "../../modal/CreatLabelModal";
import {useMutation} from "react-query";
import {useSelector} from "react-redux";

import {GetLabelList, UpdateLabel, UpdateMosaic} from "../../../services/labeling-services";
import useStore from "../../../components/annotator/Store";
import {
    EditOutlined,
    HighlightOutlined,
    SaveOutlined,
    TableOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";

import AlertModal from "../../modal/AlertModal";
import {UpdateAnnotation} from "../../../services/labeling-services";
import ConfirmModal from "../../modal/ConfirmModal";

const Wrap = styled(Layout)`
    background-color: rgba(0, 0, 0, 0);

    max-width: 360px;
    min-width: 360px;
    background-color: #21272e;
    /* @media screen and (min-width: 1920px) {
        max-width: 400px;
    } */
    display: flex;
    flex-direction: column;
    padding: 40px;
    .text {
        width: 300px;
        text-align: center;
    }
    .filename {
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const CustomBtn = styled(Button)`
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    align-self: stretch;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: ${props => props.bc} !important;

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
        /* transition: box-shadow 300ms;
        box-shadow: inset 0px 0px 1px 1px #e2ff9b; */
    }
`;

const LabelList = styled.div`
    background-color: #303a45;
    margin-top: 20px;
    min-height: 389px;
    height: 40%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(196, 196, 196, 0.2);
        padding: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c4c4c4;
    }

    .label-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .edit-button {
        background: #707070;
        border-radius: 2px;
        width: 3rem;
        height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
`;

const InputWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .annotation-index-form {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        input {
            flex: 1;
            background-color: rgba(0, 0, 0, 0);
            border: 1px solid #9c9c9c;
            color: #fff;
            font-size: 0.9rem;
            text-align: center;
        }
        p {
            flex: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`;

const EditLabelInput = styled(Input)`
    font-family: "Pretendard";
    text-align: left;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #636363;
    color: #fff;
    width: 60%;
    height: 80%;
    font-size: 0.9rem;
    ::placeholder {
        color: #9c9c9c;
    }
`;

function LabelingLeftSide(props) {
    const color = [
        "red",
        "#1E90FF",
        "#9932CC",
        "#228B22",
        "#FF8C00",
        "#483D8B",
        "#DEB887",
        "#C71585",
        "#00CED1",
        "#8FBC8F",
        "#FF69B4",
        "#008080",
        "#BC8F8F",
        "#DAA520",
        "#191970",
        "#EEE8AA",
        "#8B4513",
        "#2E8B57",
        "#708090",
        "#00FF7F",
        "#9ACD32",
        "#EE82EE",
        "#D8BFD8",
        "#4682B4",
        "#708090",
        "#FA8072",
        "#F4A460",
        "#BC8F8F",
        "#FF4500",
        "#808000",
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [labelList, setLabelList] = useState([]);
    const {Token, SelectedDataset, UserInfo} = useSelector(state => state);

    const annotationList = useStore(state => state.annotationList);
    const [selectedLabelId, setSelectedLabelId] = useState("");
    const [editLabel, setEditLabel] = useState(false);
    const [modifiyLabelName, setModifyLabelName] = useState("");
    const [annotationType, setAnnotationType] = useState("");
    const setLabelOptions = useStore(state => state.setLabelOptions);

    const setPolygons = useStore(state => state.setPolygons);
    const selectPolygon = useStore(state => state.selectPolygon);
    const polygons = useStore(state => state.polygons);
    const setImageInputIndex = useStore(state => state.setImageInputIndex);
    const labelOptions = useStore(state => state.labelOptions);

    const imageInputIndex = useStore(state => state.imageInputIndex);
    const updateLabel = useMutation(UpdateLabel);
    const setPolygonButtonClick = useStore(state => state.setPolygonButtonClick);
    const polygonButtonClick = useStore(state => state.polygonButtonClick);

    const setEditPolygon = useStore(state => state.setEditPolygon);
    const editPolygon = useStore(state => state.editPolygon);
    const annotationPk = useStore(state => state.annotationPk);

    const setAnnotationPk = useStore(state => state.setAnnotationPk);
    const setMediaPk = useStore(state => state.setMediaPk);
    const setBBox = useStore(state => state.setBBox);
    const setPoints = useStore(state => state.setPoints);
    const setImageURL = useStore(state => state.setImageURL);
    const selectedPolygonId = useStore(state => state.selectedPolygonId);

    const setAnnotationList = useStore(state => state.setAnnotationList);
    const toggleDrawing = useStore(state => state.toggleIsDrawing);

    const setIsMosaic = useStore(state => state.setIsMosaic);
    const isMosaic = useStore(state => state.isMosaic);
    const mosaics = useStore(state => state.mosaics);
    const setMosaics = useStore(state => state.setMosaics);
    const updateAnnotation = useMutation(UpdateAnnotation);
    const setIsEdit = useStore(state => state.setIsEdit);
    const labelModal = useStore(state => state.labelModal);
    const setLabelModal = useStore(state => state.setLabelModal);
    const setIsInsert = useStore(state => state.setIsInsert);

    const [buttonColor, setButtonColor] = useState("#b1b1b1");
    const [cancleColor, setCancleColor] = useState("#b1b1b1");
    const setIsInputIndex = useStore(state => state.setIsInputIndex);
    const setFileName = useStore(state => state.setFileName);
    const filename = useStore(state => state.filename);
    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const updateMosaic = useMutation(UpdateMosaic);

    useEffect(() => {
        if (SelectedDataset.data.id !== undefined) {
            getLabelList(SelectedDataset.data);
            if (SelectedDataset.data.annotation_type === "bbox") {
                setAnnotationType("Bounding Box");
            } else {
                setAnnotationType("Polygon");
            }

            setImageInputIndex(1);
            setPolygonButtonClick(false);
            setIsMosaic(false);
            setEditPolygon(false);
        }
    }, [SelectedDataset, annotationList]);

    const getLabelList = useCallback(
        async dataset => {
            try {
                const res = await GetLabelList(
                    Token.data.access,
                    parseInt(dataset.id),
                    dataset.annotation_type
                );
                setLabelList(res.data);
                let labelOptions = [];
                let i = 0;
                if (res.data.length > 0) {
                    res.data.map(info =>
                        labelOptions.push({
                            key: info.label_id,
                            value: info.label_id,
                            text: info.label_name,
                            id: info.id,
                            color: color[i++],
                        })
                    );
                }
                setLabelOptions(labelOptions);
            } catch {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            }
        },
        [Token, SelectedDataset]
    );

    const updateLabelData = useCallback(async (id, modifiyLabelName, labelName) => {
        const formData = new FormData();
        formData.append("id", parseInt(id));
        formData.append("data_set", parseInt(SelectedDataset.data.id));
        formData.append("annotation_type", SelectedDataset.data.annotation_type);
        formData.append("label_name", modifiyLabelName);

        let pattenr_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자

        if (pattenr_kor.test(modifiyLabelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "라벨 이름 오류",
                text: "한글은 등록할 수 없습니다.",
                afterFunction: null,
            });
            return;
        }

        if (pattern_spc.test(modifiyLabelName)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "라벨 이름 오류",
                text: "특수 문자를 제거 해주세요.",
                afterFunction: null,
            });
            return;
        }

        await updateLabel.mutateAsync(
            {token: Token.data.access, pk: parseInt(id), formData: formData},
            {
                onSuccess: res => {
                    getLabelList(SelectedDataset.data);
                    setModifyLabelName("");
                    setSelectedLabelId("");
                },
                onError: error => {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                },
            }
        );
    }, []);

    const handleClickLabel = id => {
        setSelectedLabelId(id);
        setEditLabel(true);
    };

    const handleNextIndex = e => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "crosshair";
        if (imageInputIndex < annotationList.length) {
            setAnnotationPk(annotationList[parseInt(imageInputIndex)].id);
            setMediaPk(annotationList[parseInt(imageInputIndex)].media_pk);

            setFileName(annotationList[parseInt(imageInputIndex)].media_path.split("/")[3]);
            if (SelectedDataset.data.annotation_type === "bbox") {
                setBBox(annotationList[parseInt(imageInputIndex)].annotation_data);
                setIsMosaic(false);
                setMosaics([]);
            } else {
                setPoints(annotationList[parseInt(imageInputIndex)].polygon_annotation_data);
                setPolygonButtonClick(false);
                setEditPolygon(false);
                setIsMosaic(false);
                setMosaics([]);
            }
            setImageURL(
                process.env.REACT_APP_END_POINT +
                    "/" +
                    annotationList[parseInt(imageInputIndex)].media_path
            );
            setImageInputIndex(parseInt(imageInputIndex) + 1);
        }
    };

    const handlePreIndex = e => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "crosshair";
        if (imageInputIndex - 1 > 0) {
            setAnnotationPk(annotationList[parseInt(imageInputIndex) - 2].id);
            setMediaPk(annotationList[parseInt(imageInputIndex) - 2].media_pk);
            setFileName(annotationList[parseInt(imageInputIndex) - 2].media_path.split("/")[3]);
            if (SelectedDataset.data.annotation_type === "bbox") {
                setBBox(annotationList[parseInt(imageInputIndex) - 2].annotation_data);
                setIsMosaic(false);
                setMosaics([]);
            } else {
                setPoints(annotationList[parseInt(imageInputIndex) - 2].polygon_annotation_data);
                setPolygonButtonClick(false);
                setEditPolygon(false);
                setIsMosaic(false);
                setMosaics([]);
            }
            setImageInputIndex(parseInt(imageInputIndex) - 1);
            setImageURL(
                process.env.REACT_APP_END_POINT +
                    "/" +
                    annotationList[imageInputIndex - 2].media_path
            );
        }
    };

    const imageInputChange = e => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "crosshair";
        setIsInputIndex(true);
        setImageInputIndex(e.target.value);
        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsMosaic(false);
        setMosaics([]);
    };

    const changeCurrentIndex = e => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";
        let special_pattern = /[`~!@#$%^&*|\\\'\";:\/?<>+-,=]/gi;
        if (special_pattern.test(imageInputIndex)) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "입력 오류",
                text: "특수문자는 입력 할수 없습니다.",
                afterFunction: null,
            });
            return;
        }
        if (parseInt(imageInputIndex) > annotationList.length || parseInt(imageInputIndex) < 1) {
            setAlertModalOpen({
                open: true,
                iconType: "warning",
                title: "범위 오류",
                text: "이미지 범위 내에서 입력해주세요.",
                afterFunction: null,
            });
            setIsInputIndex(false);

            return;
        }

        setAnnotationPk(annotationList[parseInt(imageInputIndex) - 1].id);
        setMediaPk(annotationList[parseInt(imageInputIndex) - 1].media_pk);
        setFileName(annotationList[parseInt(imageInputIndex) - 1].media_path.split("/")[3]);
        if (SelectedDataset.data.annotation_type === "bbox") {
            setBBox(annotationList[parseInt(imageInputIndex) - 1].annotation_data);
            setIsMosaic(false);
            setMosaics([]);
        } else {
            setPoints(annotationList[parseInt(imageInputIndex) - 1].polygon_annotation_data);
            setPolygonButtonClick(false);
            setEditPolygon(false);
            setIsMosaic(false);
            setMosaics([]);
        }
        setIsInputIndex(false);
        setImageURL(
            process.env.REACT_APP_END_POINT +
                "/" +
                annotationList[parseInt(imageInputIndex) - 1].media_path
        );
    };

    const addpolygon = () => {
        if (editPolygon === true) {
            return;
        }

        if (isMosaic === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }

        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "pointer";

        let id = 0;
        let temp = polygons;
        if (editPolygon === true) {
            if (selectedPolygonId !== null) {
                setAlertModalOpen({
                    open: true,
                    iconType: "warning",
                    title: "폴리곤 수정 저장 오류",
                    text: "저장 버튼을 누른후 그리기 버튼을 눌러 주세요",
                    afterFunction: null,
                });
                return;
            }
        }

        setPolygonButtonClick(true);
        setIsMosaic(false);
        setEditPolygon(false);
        setIsInsert(false);
        setMosaics([]);

        let index = temp.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            return;
        }

        if (selectedPolygonId !== null) {
            return;
        }

        if (polygons.length > 0) {
            temp.sort((a, b) => a.id - b.id);
            id = temp[temp.length - 1].id + 1;
        } else {
            id = 0;
        }

        const polygon = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: false,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        setPolygons(temp.concat([polygon]));
        selectPolygon(id);
    };

    const editPoygon = () => {
        if (polygonButtonClick === true) {
            return;
        }

        if (isMosaic === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }

        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";
        setEditPolygon(true);
        setPolygonButtonClick(false);
        setIsMosaic(false);
        selectPolygon(null);
        setIsInsert(false);
        setMosaics([]);

        let index = polygons.findIndex(data => data.isFinished === false);

        if (index >= 0) {
            polygons.splice(index, 1);
            setPolygons(polygons.concat());
            selectPolygon(null);
            return;
        } else {
            return;
        }
    };

    const getAnnotationData = (labelOptions, annotation) => {
        let id = 0;
        let annotationData = [];
        let annotationToAdd = {
            points: [],
            curMousePos: [0, 0],
            isMouseOverStartPoint: false,
            isFinished: true,
            id: id,
            flattenedPoints: [],
            annotation: "",
            color: "",
        };

        let polygonList = [];
        let key = 0;
        let filterData = [];
        let annotationName = "";
        let color = "";

        if (annotation !== null) {
            annotationData = annotation.split("||");
        } else {
            initPolygon(polygonList);
            return;
        }

        if (labelOptions.length > 0) {
            for (let i = 0; i < annotationData.length - 1; i++) {
                annotationToAdd["id"] = id++;
                annotationToAdd["points"] = [];

                let parseData = annotationData[i].split(",");
                key = parseInt(parseData[0]);

                filterData = labelOptions.filter(data => data.key === key);
                annotationName = filterData[0].text;
                color = filterData[0].color;

                for (let j = 1; j < parseData.length - 1; j++) {
                    let point = [
                        Math.floor(parseData[j].split("/")[0] * 100) / 100,
                        Math.floor(parseData[j].split("/")[1] * 100) / 100,
                    ];

                    annotationToAdd["points"].push(point);
                }

                annotationToAdd["curMousePos"] = [
                    [
                        Math.floor(parseData[parseData.length - 1].split("/")[0] * 100) / 100,
                        Math.floor(parseData[parseData.length - 1].split("/")[1] * 100) / 100,
                    ],
                ];
                annotationToAdd["color"] = color;
                annotationToAdd["annotation"] = annotationName;

                annotationToAdd["flattenedPoints"] = annotationToAdd.points.reduce(
                    (a, b) => a.concat(b),
                    []
                );
                polygonList.push(annotationToAdd);

                annotationToAdd = {
                    points: [],
                    curMousePos: [0, 0],
                    isMouseOverStartPoint: false,
                    isFinished: true,
                    id: id,
                    flattenedPoints: [],
                    annotation: "",
                    color: "",
                };
            }
            initPolygon(polygonList);
        }
    };

    const initPolygon = polygonList => {
        let id = 0;

        if (polygonList.length > 0) {
            id = polygonList.length - 1;
        } else {
            id = 0;
        }

        setPolygons(polygonList);
        selectPolygon(null);
    };

    const completePoygon = () => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";
        setButtonColor("#b1b1b1");
        setAlertModalOpen({
            open: true,
            title: "폴리곤 라벨링 저장",
            iconType: "success",
            text: "폴리곤 라벨링 저장이 완료되었습니다.",
            afterFunction: () => {
                setAnnotationData(
                    polygons.concat().filter(data => data.isFinished === true),
                    false,
                    annotationPk
                );
            },
        });

        let index = polygons.findIndex(data => data.isFinished === false);
        setEditPolygon(false);
        setPolygonButtonClick(false);
        setIsMosaic(false);
        selectPolygon(null);
        setIsEdit(false);
        setIsInsert(false);

        if (index >= 0) {
            polygons.splice(index, 1);
            setPolygons(polygons.concat());

            return;
        } else {
            return;
        }
    };

    const resetPolygon = () => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";

        let index = polygons.findIndex(data => data.isFinished === false);
        setPolygonButtonClick(false);

        setEditPolygon(false);
        setIsMosaic(false);
        setIsInsert(false);

        if (index >= 0) {
            polygons.splice(index, 1);
            setPolygons(polygons.concat());
            selectPolygon(null);
            return;
        } else {
            return;
        }
    };

    const clickBoxMosaic = () => {
        if (polygonButtonClick === true) {
            return;
        }

        if (editPolygon === true) {
            return;
        }

        if (labelModal === true) {
            return;
        }
        if (editPolygon === true) {
            if (selectedPolygonId !== null) {
                setAlertModalOpen({
                    open: true,
                    iconType: "warning",
                    title: "폴리곤 수정 저장 오류",
                    text: "저장 버튼을 누른후 그리기 버튼을 눌러 주세요",
                    afterFunction: null,
                });
                return;
            }
        }
        if (SelectedDataset.data.annotation_type === "polygon") {
            toggleDrawing(false);
        }
        setIsMosaic(true);
        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsInsert(false);
        setMosaics([]);
    };

    const resetBoxMosaic = () => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";
        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsInsert(false);
        setConfirmModalOpen({
            open: true,
            title: "이미지 모자이크",
            text: "선택된 영역을 모자이크 하시겠습니까?",
            afterFunction: () => {
                setIsMosaic(false);
                if (mosaics.length > 0) {
                    setMosaicData(mosaics, annotationPk);
                } else {
                    setAlertModalOpen({
                        open: true,
                        iconType: "success",
                        title: "모자이크 저장 완료",
                        text: "모자이크 이미지 저장이 완료되었습니다.",

                        afterFunction: () => {
                            setMosaics([]);
                            setConfirmModalOpen({
                                open: false,
                                title: null,
                                text: null,
                                afterFunction: null,
                            });
                        },
                    });
                }
            },
        });
    };

    const cancleBoxMosaic = () => {
        let canvas = document.querySelector(".canvas");
        canvas.style.cursor = "default";

        if (SelectedDataset.data.annotation_type === "polygon") {
            getAnnotationData(
                labelOptions,
                annotationList[parseInt(imageInputIndex) - 1].polygon_annotation_data
            );
        }

        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsInsert(false);
        setIsMosaic(false);
        setIsEdit(false);
        selectPolygon(null);
        setMosaics([]);
    };

    const setMosaicData = useCallback(
        async (mosaics, annotationPk) => {
            setConfirmModalOpen(prev => ({
                ...prev,
                loading: true,
            }));

            let annotationData = "";

            for (let i = 0; i < mosaics.length; i++) {
                let x = mosaics[i].points.x;
                let y = mosaics[i].points.y;
                let width = mosaics[i].points.width;
                let height = mosaics[i].points.height;

                if (width > 0 && height > 0) {
                    x = x;
                    y = y;
                } else if (width > 0 && height < 0) {
                    x = x;
                    y = y + height;
                } else if (width < 0 && height < 0) {
                    x = x + width;
                    y = y + height;
                } else if (width < 0 && height > 0) {
                    x = x + width;
                    y = y;
                }
                annotationData +=
                    parseInt(x) +
                    "," +
                    parseInt(y) +
                    "," +
                    parseInt(Math.abs(mosaics[i].points.width)) +
                    "," +
                    parseInt(Math.abs(mosaics[i].points.height)) +
                    "||";
            }

            const formData = new FormData();
            formData.append("update_user", UserInfo.data.id);
            formData.append("mosaics", annotationData);

            await updateMosaic.mutateAsync(
                {token: Token.data.access, pk: parseInt(annotationPk), formData: formData},
                {
                    onSuccess: res => {
                        let index = annotationList.findIndex(data => data.id == annotationPk);
                        annotationList[index] = res.data;
                        setImageURL(
                            process.env.REACT_APP_END_POINT + "/" + annotationList[index].media_path
                        );

                        if (SelectedDataset.data.annotation_type === "bbox") {
                            setBBox(annotationList[index].annotation_data);
                        } else {
                            setPoints(annotationList[index].polygon_annotation_data);
                            setPolygonButtonClick(false);
                            setEditPolygon(false);
                        }

                        setAnnotationList(annotationList);
                        setAlertModalOpen({
                            open: true,
                            iconType: "success",
                            title: "모자이크 저장 완료",
                            text: "모자이크 이미지 저장이 완료되었습니다.",

                            afterFunction: () => {
                                setMosaics([]);
                                setConfirmModalOpen({
                                    open: false,
                                    title: null,
                                    text: null,
                                    afterFunction: null,
                                });
                            },
                        });
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                        setMosaics([]);
                    },
                }
            );
        },
        [annotationPk]
    );

    const setAnnotationData = useCallback(
        (polygons, cancle, annotationPk) => {
            let annotationData = "";

            for (let i = 0; i < polygons.length; i++) {
                annotationData += labelOptions.filter(
                    data => data.text === polygons[i].annotation
                )[0].key;

                for (let j = 0; j < polygons[i].points.length; j++) {
                    annotationData +=
                        "," +
                        parseInt(polygons[i].points[j][0]) +
                        "/" +
                        parseInt(polygons[i].points[j][1]);
                }

                annotationData +=
                    "," +
                    parseInt(polygons[i].points[0][0]) +
                    "/" +
                    parseInt(polygons[i].points[0][1]);
                annotationData += "||";
            }

            const formData = new FormData();

            formData.append("update_user", UserInfo.data.id);
            formData.append("polygon_annotation_data", annotationData);

            updateAnnotation.mutateAsync(
                {token: Token.data.access, pk: parseInt(annotationPk), formData: formData},
                {
                    onSuccess: res => {
                        let index = annotationList.findIndex(data => data.id == annotationPk);
                        annotationList[index] = res.data;
                        setAnnotationList(annotationList);
                        setConfirmModalOpen({
                            open: false,
                            title: null,
                            text: null,
                            afterFunction: null,
                        });
                    },
                    onError: error => {
                        setAlertModalOpen({
                            open: true,
                            iconType: "error",
                            title: "",
                            text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                            afterFunction: null,
                        });
                    },
                }
            );
        },
        [annotationPk, labelOptions]
    );

    const labelModalOpen = () => {
        if (polygonButtonClick === true) {
            return;
        }

        if (editPolygon === true) {
            return;
        }
        if (isMosaic === true) {
            return;
        }
        setLabelModal(true);
        setPolygonButtonClick(false);
        setEditPolygon(false);
        setIsMosaic(false);
        setIsInsert(false);
        setMosaics([]);
    };

    return (
        <Wrap onClick={() => setIsInputIndex(false)}>
            <CustomText fs="0.9rem" cl="#fff" fw="400">
                {annotationType}
            </CustomText>

            {annotationType === "Bounding Box" ? (
                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                    {labelModal === false ? (
                        <CustomBtn
                            style={{width: "100%"}}
                            bc="#b1b1b1"
                            onClick={() => labelModalOpen()}>
                            <LabelIcon style={{marginRight: "5px", paddingRight: "5px"}} />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                라벨생성(1)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <CustomBtn style={{width: "100%"}} bc="#E2FF9B">
                            <LabelIcon style={{marginRight: "5px", paddingRight: "5px"}} />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                라벨생성(1)
                            </CustomText>
                        </CustomBtn>
                    )}

                    {isMosaic === false ? (
                        <CustomBtn
                            style={{width: "100%", marginLeft: "5px"}}
                            bc="#b1b1b1"
                            onClick={clickBoxMosaic}>
                            <EditOutlined
                                style={{
                                    color: "#fff",
                                    marginRight: "5px",
                                    paddingRight: "5px",
                                    fontSize: "1.2rem",
                                }}
                            />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                모자이크(2)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={buttonColor}
                                onClick={resetBoxMosaic}
                                onMouseOver={() => setButtonColor("#E2FF9B")}
                                onMouseOut={() => setButtonColor("#b1b1b1")}>
                                <SaveOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    저장
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={cancleColor}
                                onClick={cancleBoxMosaic}
                                onMouseOver={() => setCancleColor("#E2FF9B")}
                                onMouseOut={() => setCancleColor("#b1b1b1")}>
                                <SaveOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    취소
                                </CustomText>
                            </CustomBtn>
                        </div>
                    )}
                </div>
            ) : (
                <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                    {labelModal === false ? (
                        <CustomBtn
                            style={{width: "100%", marginLeft: "5px"}}
                            bc="#b1b1b1"
                            onClick={() => labelModalOpen()}>
                            <LabelIcon style={{marginRight: "5px", paddingRight: "5px"}} />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                라벨생성(1)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <CustomBtn style={{width: "100%"}} bc="#E2FF9B">
                            <LabelIcon style={{marginRight: "5px", paddingRight: "5px"}} />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                라벨생성(1)
                            </CustomText>
                        </CustomBtn>
                    )}
                    {isMosaic === false ? (
                        <CustomBtn
                            style={{width: "100%", marginLeft: "5px"}}
                            bc="#b1b1b1"
                            onClick={clickBoxMosaic}>
                            <TableOutlined
                                style={{
                                    color: "#fff",
                                    marginRight: "5px",
                                    paddingRight: "5px",
                                    fontSize: "1.2rem",
                                }}
                            />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                모자이크(2)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={buttonColor}
                                onClick={resetBoxMosaic}
                                onMouseOver={() => setButtonColor("#E2FF9B")}
                                onMouseOut={() => setButtonColor("#b1b1b1")}>
                                <SaveOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    저장
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={cancleColor}
                                onClick={cancleBoxMosaic}
                                onMouseOver={() => setCancleColor("#E2FF9B")}
                                onMouseOut={() => setCancleColor("#b1b1b1")}>
                                <CloseCircleOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    취소
                                </CustomText>
                            </CustomBtn>
                        </div>
                    )}

                    {polygonButtonClick === false ? (
                        <CustomBtn
                            style={{width: "100%", marginLeft: "5px"}}
                            bc="#b1b1b1"
                            onClick={addpolygon}>
                            <EditOutlined
                                style={{
                                    color: "#fff",
                                    marginRight: "5px",
                                    paddingRight: "5px",
                                    fontSize: "1.2rem",
                                }}
                            />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                그리기(3)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={cancleColor}
                                onClick={resetPolygon}
                                onMouseOver={() => setCancleColor("#E2FF9B")}
                                onMouseOut={() => setCancleColor("#b1b1b1")}>
                                <CloseCircleOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    그리기 종료
                                </CustomText>
                            </CustomBtn>
                        </div>
                    )}
                    {editPolygon === false ? (
                        <CustomBtn
                            style={{width: "100%", marginLeft: "5px"}}
                            bc="#b1b1b1"
                            onClick={editPoygon}>
                            <HighlightOutlined
                                style={{
                                    color: "#fff",
                                    marginRight: "5px",
                                    paddingRight: "5px",
                                    fontSize: "1.2rem",
                                }}
                            />
                            <CustomText fs="0.875rem" cl="#FFFFFF">
                                편집(4)
                            </CustomText>
                        </CustomBtn>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={buttonColor}
                                onClick={completePoygon}
                                onMouseOver={() => setButtonColor("#E2FF9B")}
                                onMouseOut={() => setButtonColor("#b1b1b1")}>
                                <SaveOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    저장
                                </CustomText>
                            </CustomBtn>
                            <CustomBtn
                                style={{width: "100%", marginLeft: "5px"}}
                                bc={cancleColor}
                                onClick={cancleBoxMosaic}
                                onMouseOver={() => setCancleColor("#E2FF9B")}
                                onMouseOut={() => setCancleColor("#b1b1b1")}>
                                <SaveOutlined
                                    style={{
                                        color: "#fff",
                                        marginRight: "5px",
                                        paddingRight: "5px",
                                        fontSize: "1.2rem",
                                    }}
                                />
                                <CustomText fs="0.875rem" cl="#FFFFFF">
                                    취소
                                </CustomText>
                            </CustomBtn>
                        </div>
                    )}
                </div>
            )}
            <LabelList>
                {labelList.length === 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}>
                        <CustomText fs="0.9rem" cl="#fff" fw="400">
                            등록된 라벨이 없음
                        </CustomText>
                    </div>
                ) : (
                    <>
                        {labelList.map(info => {
                            return (
                                <div className="label-items">
                                    {selectedLabelId === info.id && editLabel == true ? (
                                        <EditLabelInput
                                            autoFocus
                                            onFocus={e => setModifyLabelName(e.target.value)}
                                            defaultValue={info.label_name}
                                            onChange={e => {
                                                setModifyLabelName(e.target.value);
                                            }}
                                        />
                                    ) : (
                                        <CustomText fs="0.9rem" cl="#fff" fw="400">
                                            {info.label_name}
                                        </CustomText>
                                    )}
                                    <div className="edit-button">
                                        {selectedLabelId === info.id && editLabel === true ? (
                                            <CustomText
                                                fs="0.8rem"
                                                cl="#fff"
                                                fw="400"
                                                onClick={() =>
                                                    updateLabelData(
                                                        info.id,
                                                        modifiyLabelName,
                                                        info.label_name
                                                    )
                                                }>
                                                변경
                                            </CustomText>
                                        ) : (
                                            <CustomText
                                                fs="0.8rem"
                                                cl="#fff"
                                                fw="400"
                                                onClick={() => handleClickLabel(info.id)}>
                                                수정
                                            </CustomText>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </LabelList>

            <InputWrap>
                <LeftOutlined
                    style={{cursor: "pointer", color: "#FFFFFF", fontWeight: "400px"}}
                    size="large"
                    name="angle left"
                    onClick={handlePreIndex}
                />
                <Form className="annotation-index-form" onFinish={changeCurrentIndex}>
                    <Input type="text" value={imageInputIndex} onChange={imageInputChange} />
                    <CustomText fs="1rem" cl="#fff" ta="center" wd="100%" fw="400">
                        /&nbsp;&nbsp; {annotationList.length}
                    </CustomText>
                </Form>
                <RightOutlined
                    style={{cursor: "pointer", color: "#FFFFFF", fontWeight: "400px"}}
                    size="large"
                    name="angle right"
                    onClick={handleNextIndex}
                />
            </InputWrap>

            <CreatLabelModal
                isOpen={labelModal}
                setIsOpen={setLabelModal}
                getLabelList={getLabelList}
            />
            <ConfirmModal
                confirmModalOpen={confirmModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
            />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default LabelingLeftSide;
