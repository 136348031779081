import React, { useState, useCallback } from "react";
import styled from "styled-components";
import HomeLayout from "../../components/layout/HomeLayout";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as AssentIcon } from "../../assets/signup/assent.svg";
import { ReactComponent as UserIcon } from "../../assets/signup/user.svg";
import { ReactComponent as UserInputIcon } from "../../assets/signup/userinput.svg";
import { ReactComponent as IndividualIcon } from "../../assets/signup/individual.svg";
import { ReactComponent as CompanyIcon } from "../../assets/signup/company.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/login/arrow-right-circle.svg";

import { Link } from "react-router-dom";
import { CustomText } from "../../styles/theme/CustomStyle";
import { Button } from "antd";
import UserInfo from "./UserInfo";

const Wrap = styled.div`
    min-height: inherit;
    background-color: #0c1117;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    /* justify-content: center; */
    padding-bottom: 10rem;
`;

const SignUpTop = styled.div`
    display: flex;
    margin-top: 60px;
    width: 100%;
    width: 30rem;

    div {
        /* width: 100%;
        max-width: 10rem; */
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

const SignUpBox = styled.div`
    /* width: 100%; */
    width: 30rem;
    background-color: #21272e;
    padding: 30px;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .assentSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .scrollDiv {
        background-color: #181f27;
        overflow: auto;
        width: 100%;
        border-radius: 5px;
        height: 10rem;
        padding: 1rem;

        ::-webkit-scrollbar {
            background-color: #181f27;
            width: 20px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #707070;
            background-clip: padding-box;
            border: 9px solid transparent;
        }
    }

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

const SelectType = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    width: 100%;

    .select-menu {
        background-color: #181f27;
        border-radius: 5px;
        padding: 25px;
        /* width: 100%; */
        height: 13rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        flex: 1;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
`;

const NextButton = styled(Button)`
    background-color: #0c1117 !important;
    /* border: 1px solid #707070; */
    border-radius: 5px;
    width: 100%;
    width: 30rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.8rem;
    border-color: #70726e !important;

    @media screen and (max-width: 499px) {
        width: 90%;
    }
`;

function UserType() {
    const [selectedIndividual, setSelectedIndividual] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(false);
    const [next, setNext] = useState(false);

    const individualClick = useCallback(() => {
        setSelectedIndividual(true);
        setSelectedCompany(false);
    });

    const companyClick = useCallback(() => {
        setSelectedIndividual(false);
        setSelectedCompany(true);
    });

    const nextClick = useCallback(() => {
        if (selectedIndividual | selectedCompany) {
            setNext(true);
        }
    });

    return !next ? (
        <HomeLayout headerShown={false} signUpHeader={true}>
            <Wrap>
                <Link to="/">
                    <Logo width="16rem" height="100%" />
                </Link>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                    <SignUpTop>
                        <div style={{ borderBottom: "3px solid #575757", paddingBottom: "15px" }}>
                            <AssentIcon fill="#575757" />
                            <CustomText fs="1.1rem" cl="#575757">
                                약관동의
                            </CustomText>
                        </div>
                        <div style={{ borderBottom: "3px solid transparent", backgroundImage: "linear-gradient(#0c1117, #0c1117), linear-gradient(90deg, #2cf2ff 0%, #fbff2c 100%)", backgroundOrigin: "border-box", backgroundClip: "content-box, border-box" }}>
                            <UserIcon fill="white" />
                            <CustomText fs="1.1rem">회원유형</CustomText>
                        </div>
                        <div style={{ borderBottom: "3px solid #575757", paddingBottom: "15px" }}>
                            <UserInputIcon fill="#575757" />
                            <CustomText fs="1.1rem" cl="#575757">
                                회원정보입력
                            </CustomText>
                        </div>
                    </SignUpTop>

                    <SignUpBox>
                        <CustomText>가입 하고자하는 회원유형을 선택하세요</CustomText>
                        <SelectType>
                            <div className="select-menu" onClick={individualClick} style={{ border: selectedIndividual ? "1px solid #C4C4C4" : "1px solid #181f27" }}>
                                <div>
                                    <IndividualIcon />
                                    <CustomText fs="1.05rem">개인회원</CustomText>
                                </div>

                                <CustomText cl="#D4D4D4">기업 직원</CustomText>
                            </div>
                            <div className="select-menu" onClick={companyClick} style={{ border: selectedCompany ? "1px solid #C4C4C4" : "1px solid #181f27" }}>
                                <div>
                                    <CompanyIcon />
                                    <CustomText fs="1.05rem" ta="center">
                                        기업회원
                                    </CustomText>
                                </div>

                                <CustomText cl="#D4D4D4" ta="center">
                                    기업 등록 기업 담당자
                                </CustomText>
                            </div>
                        </SelectType>
                    </SignUpBox>

                    <NextButton htmlType="submit" color="red" onClick={nextClick}>
                        <CustomText fs="1.1rem" cl="#E2FF9B">
                            회원정보 입력
                        </CustomText>
                        <ArrowRightCircle stroke="#E2FF9B" width="1.2rem" height="1.2rem" />
                    </NextButton>
                </div>
            </Wrap>
        </HomeLayout>
    ) : (
        <UserInfo type={selectedIndividual ? "individual" : "company"} />
    );
}

export default UserType;
