import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { ReactComponent as DownArrow } from "../../assets/project/down-arrow.svg";

import { CircleText, CustomText } from "../../styles/theme/CustomStyle";
import { useParams } from "react-router-dom";
import { GetProjectDataSetList } from "../../services/project-services";
import AlertModal from "./AlertModal";

const StyledDropDown = styled(Dropdown)`
    background-color: rgba(0, 0, 0, 0);
    border-radius: 3px;
    padding: 1rem 1.2rem;
    border: 1px solid #b1b1b1;
    margin-top: 2rem;
    /* width: 30px; */

    :hover {
        background-color: #303a45;
        transition: background-color 300ms;
    }
    .ant-dropdown {
        position: relative;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    border-radius: 3px;
    background-color: #21272e;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        :hover {
            background-color: #303a45;
            transition: background-color 300ms;
        }
    }
`;

function MergeDatasetDropDown({ copyDataset, setCopyDataset }) {
    const { Token, SelectedDataset } = useSelector((state) => state);
    const params = useParams();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const { data: datasetList, isLoading: loading } = useQuery(
        ["project", "copy-dataset", params.id],
        async (e) => {
            const data = await GetProjectDataSetList(Token.data.access, params.id);
            return data;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,

            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const branchItems = useMemo(
        () => (
            <StyledMenu>
                {datasetList?.data.map((dataset, index) => {
                    if (SelectedDataset.data.id !== dataset.id) {
                        return (
                            <Menu.Item key={dataset.id} className="menu-item" onClick={() => setCopyDataset(dataset)}>
                                <MenuItem>
                                    <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {dataset.data_name}
                                    </CustomText>

                                    <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#fff">
                                        <CustomText fs="1rem" fw="400" cl="#fff" mg="0">
                                            {dataset.annotation_type}
                                        </CustomText>
                                    </CircleText>
                                </MenuItem>
                            </Menu.Item>
                        );
                    }
                })}
            </StyledMenu>
        ),
        [datasetList]
    );

    return (
        <>
            <StyledDropDown overlay={branchItems} placement="bottom" trigger={["click"]} getPopupContainer={(trigger) => trigger.parentNode}>
                <MenuItem>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
                        {copyDataset?.is_representative && (
                            <CircleText backgroundColor="rgba(0,0,0,0)" borderColor="#fff">
                                <CustomText fs="1rem" fw="400" cl="#fff" mg="0">
                                    대표
                                </CustomText>
                            </CircleText>
                        )}
                        <CustomText fs="1rem" style={{ flex: 1, whiteSpace: "nowrap", wordBreak: "break-all", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {copyDataset?.data_name}
                        </CustomText>
                    </div>
                    <DownArrow width="1rem" height="1rem" />
                </MenuItem>
            </StyledDropDown>
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </>
    );
}

export default MergeDatasetDropDown;
