import React from "react";
import { Layout } from "antd";
import styled from "styled-components";

const Wrap = styled(Layout)`
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    max-width: 360px;

    /* @media screen and (min-width: 1920px) {
        max-width: 400px;
    } */

    @media screen and (max-width: 1700px) {
        display: none;
    }
`;

function EmptyLeftSide({ tab }) {
    return tab !== "setting" ? <Wrap /> : null;
}

export default EmptyLeftSide;
