import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;
    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
    }
    .image-section {
        width: 45;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function TrainModel() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/terminology-guide" style={{ cursor: "pointer" }}>
                    <CustomSpan cl="#58A6FF">&nbsp;기능 및 용어 안내&nbsp;</CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;모델 학습</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    모델 학습
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    NewLearn에서는 GPU 기반 5페타플롭스 속도의 모델 학습 시스템을 제공 합니다.
                    <br />
                    <br />
                    학습 예약을 통해 앞서 예약한 사람 부터 순차적으로 학습이 진행됩니다.
                    <br />
                    <br />
                    학습을 진행하기전 <CustomSpan cl="#58A6FF">학습 파라미터</CustomSpan>를 설정하는데 상세 내용은 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>학습 모델 이름을 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>학습하고자하는 데이터셋을 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>학습 서버는 NVIDA DGX A100 서버로 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>객체 인식 알고리즘을 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>에포크는 전체 이미지를 몇 번 학습할 것인지를 설정하는 것이며 50이상 200이하으로 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>배치사이즈는 한번 학습할 때 학습 하는 이미지 개수를 설정하는 것이며 32이하 8의 배수로 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>학습률은 한 번의 학습으로 얼마만큼 학습해야 할지를 설정하는 것이며 0.001이하 0.0001이상으로 설정</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>학습 및 테스트 데이터 비율을 설정해야하며 일반적으로 학습데이터 70%, 테스트 데이터 30%로 설정 </li>
                    <br />
                    학습이 시작되면 현재 학습 상태를 실시간으로 모니터링 할 수 있습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>

            <Link to="/document/terminology-guide?terminology=7">
                <StyledButton>
                    <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                        오토 라벨링
                        <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                    </CustomText>
                </StyledButton>
            </Link>
        </Wrap>
    );
}

export default TrainModel;
