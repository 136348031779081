import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GetCompany, GetCompanyInfo, GetCompanyMember, UpdateUserInfo } from "../../services/account-services";
import { CustomText } from "../../styles/theme/CustomStyle";
import ScrollContainer from "react-indiana-drag-scroll";
import { Avatar } from "antd";
import TrainHistoryMain from "../../components/use-dashboard/train-history/TrainHistoryMain";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { GetModelTrainingHistory } from "../../services/train-services";
import { Colors } from "../../styles/theme/Colors";
import { UserOutlined } from "@ant-design/icons";
import MemberListSkeleton from "../../styles/theme/MemberListSkeleton";
import AlertModal from "../../components/modal/AlertModal";
import ConfirmModal from "../../components/modal/ConfirmModal";
import EditMemberModal from "../../components/modal/EditMemberModal";
import Select from "react-select";
import { ReactComponent as NoWaitMemberIcon } from "../../assets/mypage/nowaitmember.svg";
import { ReactComponent as NoMemberIcon } from "../../assets/mypage/nomember.svg";
import { ReactComponent as NoTrainIcon } from "../../assets/mypage/notrain.svg";

const Wrap = styled.div`
    flex: 1;
    min-height: inherit;
    background-color: #161b22;
    padding: 1.5rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    .bottomMargin {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        margin-bottom: 10rem;
        width: 62rem;

        @media screen and (max-width: 1024px) {
            gap: 0.8rem;
            width: 95%;
        }
        @media screen and (max-height: 1000px) {
            /* margin-bottom: 12rem; */
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0.5rem 0px;
    }
`;

const Section = styled.div`
    border-bottom: 1px solid #707070;
    padding-bottom: 0.5rem;
    /* margin-top: 0.5rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ListWrap = styled(ScrollContainer)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 3rem;
    margin: 1rem 0;
    height: 17rem;
    max-height: 16rem;
    overflow-y: auto;

    .member {
        display: flex;
        width: 30%;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
        /* gap: 1.125rem; */
        margin: 1rem 0;

        .member {
            justify-content: space-between;
            width: 45%;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #161b22;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b1b1b1;
    }
`;

const GradientText = styled(CustomText)`
    background: -webkit-linear-gradient(left, #2cf2ff, #fbff2c);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const TrainTimeWrap = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 1rem;

    .member {
        min-width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.625rem;
        background-color: #21272e;
        border-radius: 5px;
        min-height: 18rem;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const StyledSelect = styled(Select)`
    width: ${(props) => (props.wd ? props.wd : "49%")};
    background-color: #21272e !important;
    font-size: 1.05rem;
    font-family: Pretendard;
    font-weight: 400;
    color: white;
    border-radius: 5px;

    input {
        color: white !important;
        font-size: 1.05rem !important;
        font-family: Pretendard !important;
        font-weight: 400 !important;
    }

    .css-1okebmr-indicatorSeparator {
        background: none;
    }
`;

const NoDataWrap = styled.div`
    background-color: #21272e;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
    if (start.isAfter(end)) throw new Error("start must precede end");

    const next = dayjs(start).add(1, key).startOf(key);

    if (next.isAfter(end, key)) return arr;

    return getRangeOfDates(next, end, key, arr.concat(next));
}

function MemberManagement() {
    const { UserInfo, Token } = useSelector((state) => state);
    const [company, setCompany] = useState("");
    const [selectMember, setSelectMember] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [companyMemberList, setCompanyMemberList] = useState("");
    const [trainData, setTrainData] = useState(undefined);
    const [selectCompany, setSelectCompany] = useState(UserInfo.data.company.id);

    const [selectedYear, setSelectedYear] = useState(dayjs().format("YYYY"));
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format("M"));

    const [begin, setBegin] = useState(dayjs(UserInfo.data.created_at).startOf("year"));
    const end = dayjs();

    const [alertModalOpen, setAlertModalOpen] = useState({
        open: false,
        iconType: null,
        title: null,
        text: null,
        afterFunction: null,
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState({
        open: false,
        title: null,
        text: null,
        afterFunction: null,
        loading: false,
    });

    const [editMemberModalOpen, setEditMemberModalOpen] = useState(false);

    const selectCustom = {
        control: (base, state) => ({
            ...base,
            color: "white",
            background: "#21272e",
            textAlign: "center",
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                border: state.isFocused ? 0 : 0,
            },
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#e2ff9b" : "#fff",
            background: state.isSelected ? "#4C5158" : "#21272e",
            "&:hover": {
                background: state.isFocused ? "#2E3640" : "#21272e",
            },
        }),
        placeholder: (base) => {
            return {
                ...base,
                color: "#fff",
            };
        },
        singleValue: (base, state) => ({
            ...base,
            color: "#fff",
        }),
        menu: (base, state) => ({
            ...base,
            color: "#fff",
            background: "#21272e",
        }),
    };

    const yearList = useMemo(() => {
        return getRangeOfDates(begin, end, "year")
            .map((data) => {
                return dayjs(data).format("YYYY");
            })
            .reverse();
    }, [selectMember]);

    const monthList = useMemo(() => {
        // return getRangeOfDates(dayjs(new Date(selectedYear, 0, 1)), end, "month")
        return getRangeOfDates(dayjs(new Date(selectedYear, 0, 1)), end, "month")
            .map((data) => {
                return dayjs(data).format("M");
            })
            .reverse();
    }, [selectMember]);

    const {
        data: trainHistoryData,
        isFetching: isLoading,
        refetch,
    } = useQuery(
        ["trainData"],
        async () => {
            const data = await GetModelTrainingHistory(Token.data.access, selectMember.id, selectedYear, selectedMonth);
            setTrainData(data);
            return data;
        },
        {
            enabled: selectMember !== "",
            retry: false,
            refetchOnWindowFocus: false,
            onError: (err) => {
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    useEffect(() => {
        if (selectMember !== "") {
            refetch();
        }
    }, [selectMember, selectedYear, selectedMonth]);

    useEffect(async () => {
        GetData();
    }, []);

    useEffect(async () => {
        GetCompanyMemberData();
    }, [selectCompany]);

    const GetData = useCallback(async () => {
        try {
            const data = await GetCompanyInfo(UserInfo.data.id, Token.data.access);
            setCompany(data.data[0]);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const GetCompanyMemberData = useCallback(async () => {
        try {
            const data = await GetCompanyMember(selectCompany, Token.data.access);
            setCompanyMemberList(data.data);
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "",
                text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }
    });

    const { data } = useQuery(
        ["companys"],
        async (e) => {
            const data = await GetCompany();
            return data;
        },
        {
            retry: false,
            onSuccess: (res) => {
                setCompanyList(res.data);
            },
            onError: (err) => {
                if (err.response.statusText === "Not Found") {
                    setAlertModalOpen({
                        open: true,
                        iconType: "error",
                        title: "",
                        text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                        afterFunction: null,
                    });
                    return;
                }
                setAlertModalOpen({
                    open: true,
                    iconType: "error",
                    title: "",
                    text: "오류가 발생했습니다.\n잠시 후 다시 시도해주세요.",
                    afterFunction: null,
                });
            },
        }
    );

    const companySelect = (selectedCompany) => {
        setSelectCompany(selectedCompany.key);
    };

    const yearSelect = (selectedYear) => {
        setSelectedYear(selectedYear.key);
    };

    const monthSelect = (selectedMonth) => {
        setSelectedMonth(selectedMonth.key);
    };

    const ApproveMember = useCallback(async (approveSelected) => {
        setConfirmModalOpen((prev) => ({
            ...prev,
            loading: true,
        }));

        try {
            const formData = new FormData();

            formData.append("is_manager_approve", true);
            formData.append("login_id", approveSelected.login_id);
            formData.append("name", approveSelected.name);
            formData.append("email", approveSelected.email);
            formData.append("phone_number", approveSelected.phone_number);

            const data = await UpdateUserInfo(approveSelected.id, formData, Token.data.access);
            GetData();
            setAlertModalOpen({
                open: true,
                iconType: "success",
                title: "회원 가입 승인 완료",
                text: "회원 가입 승인이 완료되었습니다.",
                afterFunction: () => {
                    GetCompanyMemberData();
                },
            });
        } catch (err) {
            setAlertModalOpen({
                open: true,
                iconType: "error",
                title: "회원 가입 승인 오류",
                text: "회원 가입 승인에 실패하였습니다.\n잠시 후 다시 시도해주세요.",
                afterFunction: null,
            });
        }

        setConfirmModalOpen((prev) => ({
            ...prev,
            open: false,
            loading: false,
        }));
    });

    return (
        <Wrap>
            <div className="bottomMargin">
                <div>
                    <Section style={{ marginTop: "1.3rem" }}>
                        <CustomText fw={600} fs="1.12rem">
                            기업승인 대기 회원
                        </CustomText>
                        <div style={{ display: "flex", gap: "3px" }}>
                            <CustomText fs="1.12rem" fw={600} cl="#E2FF9B">
                                {company.waiting_count ? company.waiting_count : <span style={{ color: "#ffffff" }}>-&nbsp;</span>}
                            </CustomText>
                            <CustomText fs="1.12rem">명</CustomText>
                        </div>
                    </Section>
                    <ListWrap hideScrollbars={false}>
                        {!company ? (
                            <>
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                            </>
                        ) : company.waiting_count === 0 ? (
                            <NoDataWrap>
                                <NoWaitMemberIcon />
                                <CustomText>가입 승인 요청 회원이 없습니다</CustomText>
                            </NoDataWrap>
                        ) : (
                            company.waiting_member.map((member) => {
                                return (
                                    <>
                                        <div
                                            className="member"
                                            onClick={() => {
                                                setConfirmModalOpen({
                                                    open: true,
                                                    title: "회원 가입 승인",
                                                    text: `${member.name} 님의 가입을 승인할까요?`,
                                                    afterFunction: () => {
                                                        ApproveMember(member);
                                                    },
                                                });
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: Colors[member.id % 10] }} />

                                                <div style={{ marginLeft: "1.125rem" }}>
                                                    <div style={{ display: "flex", gap: "7px" }}>
                                                        <CustomText fw={500}>{member.name}</CustomText>
                                                        <CustomText>|</CustomText>
                                                        <CustomText>{member.authorization_code === "97" ? "라벨링" : member.authorization_code === "98" ? "스토리지" : "트레이닝"} 회원</CustomText>
                                                    </div>
                                                    <CustomText cl="#B1B1B1" mg="5px 0 0 0" fs="0.875rem">
                                                        {member.phone_number}
                                                    </CustomText>
                                                    <CustomText cl="#B1B1B1" fs="0.875rem">
                                                        {member.email}
                                                    </CustomText>
                                                    <CustomText cl="#B1B1B1" fs="0.875rem">
                                                        {member.company.company_name}
                                                    </CustomText>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                        )}
                    </ListWrap>
                </div>
                <div>
                    <Section>
                        <div style={{ display: "flex", width: "17rem", alignItems: "center", justifyContent: "space-between" }}>
                            <CustomText fw={600} fs="1.12rem">
                                소속 회원
                            </CustomText>
                            {UserInfo.data.is_admin === true && <StyledSelect wd="70%" options={companyList.map((company) => ({ key: company.id, value: company.company_name, label: company.company_name }))} defaultValue={{ value: UserInfo.data.company.company_name, label: UserInfo.data.company.company_name }} clearable={false} onChange={companySelect} styles={selectCustom} />}
                        </div>
                        <div style={{ display: "flex", gap: "3px" }}>
                            <CustomText fs="1.12rem" fw={600} cl="#E2FF9B">
                                {companyMemberList.member_count ? companyMemberList.member_count : <span style={{ color: "#ffffff" }}>-&nbsp;</span>}
                            </CustomText>
                            <CustomText fs="1.12rem">명</CustomText>
                        </div>
                    </Section>
                    <ListWrap hideScrollbars={false}>
                        {!companyMemberList ? (
                            <>
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                                <MemberListSkeleton />
                            </>
                        ) : companyMemberList.member_count === 0 ? (
                            <NoDataWrap>
                                <NoMemberIcon />
                                <CustomText>현재 소속 회원이 없습니다</CustomText>
                            </NoDataWrap>
                        ) : (
                            companyMemberList.member.map((member) => {
                                return (
                                    <div
                                        className="member"
                                        onClick={() => {
                                            setSelectMember(member);
                                            setBegin(dayjs(member.created_at).startOf("year"));
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Avatar size={48} src={member.image_path ? process.env.REACT_APP_END_POINT + member.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[member.id % 10], border: selectMember.id === member.id && "1px solid #FFFFFF" }} />

                                            <div style={{ marginLeft: "1.125rem" }}>
                                                <div style={{ display: "flex", gap: "7px" }}>
                                                    <CustomText fw={500}>{member.name}</CustomText>
                                                    <CustomText>|</CustomText>
                                                    <CustomText>{member.authorization_code === "97" ? "라벨링" : member.authorization_code === "98" ? "스토리지" : "트레이닝"} 회원</CustomText>
                                                </div>
                                                <CustomText cl="#B1B1B1" fs="0.875rem">
                                                    {member.login_id}
                                                </CustomText>
                                                <CustomText cl="#B1B1B1" mg="5px 0 0 0" fs="0.875rem">
                                                    {member.phone_number}
                                                </CustomText>
                                                {/* <CustomText cl="#B1B1B1" fs="0.875rem">
                                                    {member.email}
                                                </CustomText> */}
                                                <CustomText cl="#B1B1B1" fs="0.875rem">
                                                    {member.company.company_name}
                                                </CustomText>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </ListWrap>
                </div>
                <div>
                    <Section>
                        <CustomText fw={600} fs="1.12rem">
                            학습 이용시간
                        </CustomText>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <CustomText fs="1rem" fw="400">
                                {selectedYear}년 {selectedMonth}월 학습이용시간
                            </CustomText>
                            <GradientText fs="1rem" fw="600">
                                {trainData?.data.total_training_time}
                            </GradientText>
                        </div>
                    </Section>
                    {/* <DateWrap yearList={yearList} monthList={monthList} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} historyData={historyData} isLoading={isLoading} /> */}

                    <TrainTimeWrap>
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <StyledSelect options={yearList.map((year, index) => ({ key: year, value: year, label: year }))} defaultValue={{ value: dayjs(end).format("YYYY"), label: dayjs(end).format("YYYY") }} onChange={yearSelect} clearable={false} styles={selectCustom} />
                                <StyledSelect options={monthList.map((month, index) => ({ key: month, value: `${month}월`, label: `${month}월` }))} defaultValue={{ value: `${dayjs(end).format("M")}월`, label: `${dayjs(end).format("M")}월` }} onChange={monthSelect} clearable={false} styles={selectCustom} />
                            </div>
                            <div className="member">
                                {selectMember !== "" ? (
                                    <>
                                        <Avatar size={48} src={selectMember.image_path ? process.env.REACT_APP_END_POINT + selectMember.image_path : ""} icon={<UserOutlined />} style={{ backgroundColor: Colors[selectMember.id % 10] }} />

                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div style={{ display: "flex", gap: "7px" }}>
                                                <CustomText fw={500} fs="1.125rem">
                                                    {selectMember.name}
                                                </CustomText>
                                                <CustomText fs="1.125rem">|</CustomText>
                                                <CustomText>{selectMember.authorization_code === "97" ? "라벨링" : selectMember.authorization_code === "98" ? "스토리지" : "트레이닝"} 회원</CustomText>
                                            </div>
                                            <CustomText cl="#B1B1B1" mg="10px 0 0 0">
                                                {selectMember.company.company_name}
                                            </CustomText>
                                            <CustomText cl="#B1B1B1" style={{ lineHeight: "1.3rem" }}>
                                                {selectMember.phone_number}
                                            </CustomText>
                                            <CustomText cl="#B1B1B1">{selectMember.email}</CustomText>
                                        </div>
                                        <CustomText
                                            cl="#B1B1B1"
                                            fs="0.875rem"
                                            onClick={() => {
                                                setEditMemberModalOpen(true);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            수정
                                        </CustomText>
                                    </>
                                ) : (
                                    <CustomText>회원을 선택해주세요</CustomText>
                                )}
                            </div>
                        </div>
                        {/* <div style={{ flex: 2.7 }}>
                            <TrainTimeGraph />
                        </div> */}
                        {(trainData?.data.data.length === undefined) | (trainData?.data.data.length === 0) ? (
                            <NoDataWrap style={{ height: "21.2rem" }}>
                                <NoTrainIcon />
                                <CustomText>학습 이용시간이 존재하지 않습니다</CustomText>
                            </NoDataWrap>
                        ) : (
                            <TrainHistoryMain yearList={yearList} monthList={monthList} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} historyData={trainData} isLoading={isLoading} />
                        )}
                        {/* <DataGraph /> */}
                    </TrainTimeWrap>
                </div>
            </div>

            {selectMember !== "" && <EditMemberModal editMemberModalOpen={editMemberModalOpen} setEditMemberModalOpen={setEditMemberModalOpen} member={selectMember} />}
            <ConfirmModal confirmModalOpen={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
            <AlertModal alertModalOpen={alertModalOpen} setAlertModalOpen={setAlertModalOpen} />
        </Wrap>
    );
}

export default MemberManagement;
