import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ArrowRightOutlined, GithubOutlined } from "@ant-design/icons";
import { CustomText } from "../../../styles/theme/CustomStyle";
import { CustomSpan } from "../main";
import { ReactComponent as YOLOv4SVG } from "../../../assets/document/yolov4.svg";
import { Link } from "react-router-dom";

const Wrap = styled.div`
    width: 100%;
    flex-direction: row;
    min-height: inherit;
    padding: 35px 50px;

    .top-section {
        width: 100%;
    }
    .title-section {
        width: 100%;
        margin-top: 20px;
    }
    .intro-section {
        width: 100%;
        margin-top: 40px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        flex-direction: column;
    }
    .button-section {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;

const StyledButton = styled(Button)`
    font-family: "Roboto";
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background: #58a6ff;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 5px;
    border: none;

    :hover {
        background-color: #58a6ff;
        transition: all 300ms;
    }
    .ant-btn-primary {
        background-color: #58a6ff;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        background: #58a6ff;
        border-radius: 5px;
    }
`;

function YOLOv4() {
    return (
        <Wrap>
            <div className="top-section">
                <Link to="/document">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        가이드&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <Link to="/document/algorithm-guide">
                    <CustomSpan cl="#58A6FF" style={{ cursor: "pointer" }}>
                        &nbsp;알고리즘 안내&nbsp;
                    </CustomSpan>
                </Link>
                <CustomSpan>{">>"}</CustomSpan>
                <CustomSpan>&nbsp;YOLOv4</CustomSpan>
            </div>
            <div className="title-section">
                <CustomSpan fs="2rem" cl="#FFFFFF" fw="medium">
                    YOLOv4
                </CustomSpan>
            </div>
            <div className="intro-section">
                <CustomSpan fw="normal" fs="1rem" cl="#FFFFFF">
                    YOLOv4는 2020년 4월에 발표된 실시간 객체 감지 알고리즘으로 COCO 데이터 세트에서 최첨단 성능을 달성했습니다.
                    <br />
                    <br />
                    YOLOv4의 기본 구조는 Backbone, Neck, Head(Dense Prediction)으로 구성된 One-Stage Detector이며 상세내용은 다음과 같습니다.
                    <br />
                    <br />
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Backbone은 Input image를 Feature map으로 변형하는 부분입니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Neck은 Backbone과 Head 사이를 이어주는 연결부로서 Feature map을 정제, 재구성해 줍니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>Head는 Backbone에서 추출한 Feature map의 location 작업이 이뤄지는 곳으로 Classification, Bounding Boxes 작업이 이뤄집니다.</li>
                    <li style={{ color: "#FFF", fontSize: "1rem", fontWeight: "normal", paddingLeft: "5px", paddingTop: "2px" }}>YOLOv4 논문에 따르면 Backbone에서는 CSPDarknet53, Neck은 SPP와 PANet, Head은 YOLOv3(anchor based)을 사용합니다.</li>
                    <br />
                    <br />
                    YOLOv4 아키텍쳐 구조는 아래 이미지와 같습니다.
                    <br />
                    <br />
                </CustomSpan>
            </div>
            <div className="image-section">
                <YOLOv4SVG style={{ width: "50%", height: "auto", borderRadius: 10, cursor: "pointer" }} />
            </div>
            <div className="button-section">
                <a href="https://github.com/Tianxiaomo/pytorch-YOLOv4" target="_blank">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            <GithubOutlined style={{ color: "#fff", fontSize: "1rem", marginRight: "10px" }} />
                            Repo
                        </CustomText>
                    </StyledButton>
                </a>
                <Link to="/document/algorithm-guide?algorithm=4">
                    <StyledButton type="primary">
                        <CustomText fs="1rem" fw="600" wd="100%" ta="center">
                            YOLOv5
                            <ArrowRightOutlined style={{ color: "#fff", fontSize: "1rem", marginLeft: "10px" }} />
                        </CustomText>
                    </StyledButton>
                </Link>
            </div>
        </Wrap>
    );
}

export default YOLOv4;
